import { PreviewVariable } from '@/components/email-builder/PreviewTemplate';
import { dataAxiosInstance } from '@/lib/axios';
import { IEmailRequest } from '@/types/email.types';

export const getEmailWebviewContents = async ({ mailAccessToken }: { mailAccessToken: string }) => {
  const result = await dataAxiosInstance.get(`/email/getMailById/${mailAccessToken}`);
  return result.data;
};

export const postSendEmailPreview = async ({
  templateId,
  variables,
  to,
  from,
  cc,
}: {
  templateId: string;
  variables: PreviewVariable[];
  to: string[];
  from: string;
  cc: string[];
}) => {
  const result = await dataAxiosInstance.post(`/email/preview/send/${templateId}`, {
    variables: variables.map((item) => ({
      name: item.name,
      modelType: item.modelType,
      identifiers: item.name.includes('invoices')
        ? item.identifiers
            .split(',')
            .map((identifier) => identifier.trim())
            .filter((item) => !!item)
        : item.identifiers,
    })),
    to: to,
    fromEmail: from,
    cc: cc,
  });
  return result.data;
};

export const postSendEmail = async ({ ...email }: IEmailRequest) => {
  const result = await dataAxiosInstance.post('/email/send', {
    ...email,
  });
  return result.data;
};
