import { useUserContext } from '@/contexts/UserContext';
import { useGetBankAccountsForCompany } from '@/hooks/api-hooks/useBankAccountQuery';
import { useCallback, useMemo } from 'react';

export const useGetBankName = () => {
  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { data: banks, isLoading: isLoadingBanks } = useGetBankAccountsForCompany({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const banksMap = useMemo(() => {
    return (
      banks?.data.bankAccounts.reduce(
        (acc, bank) => {
          acc[bank.id] = bank.name;
          return acc;
        },
        {} as Record<string, string>,
      ) || {}
    );
  }, [banks]);

  const getBankNameById = useCallback(
    (bankId: string) => {
      if (isLoadingBanks) {
        return bankId;
      }

      if (!banksMap) {
        return bankId;
      }

      return banksMap[bankId] ?? bankId;
    },
    [isLoadingBanks, banksMap],
  );

  return { getBankNameById };
};
