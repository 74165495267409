import { ITax } from '@/types/invoices.types';
import { formatCurrencyByUnit } from './formatNumberByUnit';

export const generateTaxString = (tax: ITax, currencyType: string): string => {
  const taxObject = tax.gstTax ? tax.gstTax : tax;

  return Object.entries(taxObject)
    .filter(([key, entry]) => !key.includes('total') && !key.includes('_rate') && entry !== 0)
    .map(([key, entry]) => {
      const formattedValue = formatCurrencyByUnit(entry, 'actual', currencyType).trim();
      const hasSingleEntry = Object.entries(taxObject).filter(([k, v]) => k !== 'total' && v !== 0).length === 1;

      if (hasSingleEntry) {
        return `${key.toUpperCase()}`;
      } else {
        return key !== 'tds' ? `${key.toUpperCase()} ${formattedValue}` : `${key.toUpperCase()} -${formattedValue}`;
      }
    })
    .join(', ');
};
