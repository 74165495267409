'use client';

import { CalendarIcon } from '@radix-ui/react-icons';
import { addDays, format } from 'date-fns';
import * as React from 'react';
import { DateRange } from 'react-day-picker';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { RefCallBack } from 'react-hook-form';

interface IDateRangePickerProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value?: DateRange;
  onChange: (_: DateRange | undefined) => void;
  buttonClass?: string;
  placeholder?: string;
  ref?: RefCallBack;
}

export function DatePickerWithRange({ onChange, value, placeholder, buttonClass, ref }: IDateRangePickerProps) {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: new Date(2022, 0, 20),
    to: addDays(new Date(2022, 0, 20), 20),
  });

  React.useEffect(() => {
    setDate(value);
  }, [value, setDate]);

  const handleChange = (value: DateRange | undefined) => {
    setDate(value);
    onChange(value);
  };

  return (
    <Popover>
      <PopoverTrigger ref={ref} asChild>
        <Button
          id="date"
          ref={ref}
          variant={'outline'}
          className={cn(
            'font-normal flex gap-2 items-center justify-start',
            !date && 'text-muted-foreground',
            buttonClass,
          )}
        >
          <CalendarIcon className="h-4 w-4 " />
          {date?.from ? (
            date.to ? (
              <span>
                {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
              </span>
            ) : (
              <span>{format(date.from, 'LLL dd, y')}</span>
            )
          ) : (
            <span className="text-muted-foreground">{placeholder?.trim() ? placeholder : 'Pick a date'}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          initialFocus
          mode="range"
          defaultMonth={date?.from}
          selected={date}
          onSelect={handleChange}
          numberOfMonths={2}
        />
      </PopoverContent>
    </Popover>
  );
}
