'use client';

import { useCallback, useMemo } from 'react';
interface IOption {
  label: string;
  value: string;
}

import { useGetContactRoleSuggestions } from '@/hooks/api-hooks/useContactsQuery';
import { cn } from '@/lib/utils';
import { IOptions } from '@/types/common.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import debounce from 'lodash.debounce';
import { GroupBase, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';

const components = {
  DropdownIndicator: null,
};

export const CustomerRoleMultiSelect = ({
  onChange,
  value,
  placeholder,
  className,
}: {
  placeholder: string;
  onChange: (_: readonly IOption[] | undefined) => void;
  value?: IOption[];
  className?: string;
}) => {
  const { mutateAsync: fetchCustomerRoles } = useGetContactRoleSuggestions({});

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, IOptions>();
  }, []);

  const loadRoles = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IOptions, GroupBase<IOptions>>) => void) => {
      if (inputValue.length < 3) {
        callback([]);
        return;
      }

      fetchCustomerRoles({
        query: inputValue.trim(),
      }).then((result) => {
        const options = result.data.suggestions.map((item) => ({
          value: item.role,
          label: item.role,
        }));

        callback(options);
      });
      return;
    },
    [fetchCustomerRoles],
  );

  const debouncedLoadOwnerRoles = useMemo(() => {
    return debounce(loadRoles, 500);
  }, [loadRoles]);

  return (
    <AsyncSelect
      components={components}
      isMulti={true}
      onChange={(newValue) => {
        if (newValue === null) {
          onChange(undefined);
          return;
        }

        onChange(newValue);
      }}
      className={cn(' text-sm min-w-[200px] max-w-[200px] truncate text-ellipsis', className)}
      placeholder={placeholder}
      value={value ?? null}
      loadOptions={debouncedLoadOwnerRoles}
      styles={selectStyles}
      classNames={selectClasses}
      menuPortalTarget={document.body}
    />
  );
};
