import { Button } from '@/components/ui/button';
import SelectComponent from '@/components/ui/select-component';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import {
  useGetActionById,
  useGetStateById,
  usePostUpdateActionOnlyState,
  usePostUpdateConditionalState,
  usePostUpdateGotoAction,
  usePostUpdateTriggerState,
  usePostUpdateWaitState,
} from '@/hooks/api-hooks/useWorkflowQuery';
import { useWorkflowPropertiesStore } from '@/stores/workflow/state-properties.store';
import { IGotoActionProperties, IState, StateTypes } from '@/types/workflow.type';
import { Loader2Icon, XIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNodes } from 'reactflow';
import PropertiesLayout from '../Layout';
import StateLabelInput from '../StateLabel';

interface IEditGotoActionProperties {
  toState: string;
}

const validateGotoProperties = (data: Partial<IEditGotoActionProperties>) => {
  const errors: Record<keyof Omit<IEditGotoActionProperties, 'clientId'>, string> = {
    toState: '',
  };
  let isValid = true;

  if (!data.toState) {
    errors.toState = 'To state is required';
    isValid = false;
  }

  return {
    isValid,
    errors,
  };
};

export const GotoActionProperties = ({ actionId }: { actionId: string }) => {
  const { selectedProperties, setSelectedProperties } = useWorkflowPropertiesStore();

  const { data: stateResponse } = useGetStateById({
    stateId: selectedProperties?.nodeId || '',
    customConfig: {
      enabled: !!selectedProperties?.nodeId,
    },
  });

  const { data: actionResponse, isLoading } = useGetActionById({
    actionId,
    customConfig: {
      enabled: !!actionId,
    },
  });

  const { toast } = useToast();

  const { mutate: updateActionOnlyState, isPending: isUpdateStatePending } = usePostUpdateActionOnlyState({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update label. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateTriggerState, isPending: isTriggerStateUpdateLoading } = usePostUpdateTriggerState({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update trigger state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateConditionalState, isPending: isConditionalStateUpdateLoading } = usePostUpdateConditionalState({
    workflowId: stateResponse?.data.workflowId || '',
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update conditional state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateWaitState, isPending: isWaitStateUpdateLoading } = usePostUpdateWaitState({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update wait state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateAction, isPending: isUpdateActionPending } = usePostUpdateGotoAction({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update goto action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const isPending = useMemo(
    () =>
      isUpdateActionPending ||
      isUpdateStatePending ||
      isTriggerStateUpdateLoading ||
      isConditionalStateUpdateLoading ||
      isWaitStateUpdateLoading,
    [
      isUpdateActionPending,
      isUpdateStatePending,
      isTriggerStateUpdateLoading,
      isConditionalStateUpdateLoading,
      isWaitStateUpdateLoading,
    ],
  );

  const {
    register,
    watch,
    formState: { errors: formErrors },
  } = useForm<{
    label: string;
  }>({
    values: {
      label: stateResponse?.data.label || '',
    },
    mode: 'onChange',
  });

  const nodes = useNodes<IState<StateTypes>>();

  const toStateOptions = useMemo(() => {
    if (!stateResponse) {
      return [];
    }

    return nodes
      .filter((node) => node.id !== stateResponse.data.id)
      .map((node) => {
        return {
          label: node.data.label,
          value: node.id,
        };
      });
  }, [stateResponse, nodes]);

  const action = useMemo(() => {
    if (!actionResponse) {
      return null;
    }

    return actionResponse.data;
  }, [actionResponse]);

  const [errors, setErrors] = useState<Record<keyof Omit<IEditGotoActionProperties, 'clientId'>, string>>({
    toState: '',
  });

  const [gotoProperties, setGotoProperties] = useState<Omit<IEditGotoActionProperties, 'clientId'>>({
    toState: '',
  });

  useEffect(() => {
    const actionDescription = action?.description as Partial<IGotoActionProperties>;

    if (!actionDescription) {
      return;
    }

    setGotoProperties({
      toState: actionDescription.toState || '',
    });
  }, [action]);

  if (!selectedProperties) return null;

  if (!action) return null;

  const handleToStateSelect = (value: string) => {
    setErrors((prev) => {
      return {
        ...prev,
        toState: '',
      };
    });

    setGotoProperties((prev) => ({
      ...prev,
      toState: value,
    }));
  };

  const handleSave = () => {
    const { isValid, errors: validationErrors } = validateGotoProperties(gotoProperties);

    if (!isValid) {
      setErrors(validationErrors);
      return;
    }

    updateAction({
      actionId,
      fields: ['toState'],
      values: {
        toState: gotoProperties.toState,
      },
    });

    if (selectedProperties.type === 'ACTION_ONLY') {
      updateActionOnlyState({
        stateId: selectedProperties.nodeId,
        fields: ['label'],
        values: {
          label: watch('label') || '',
        },
      });

      return;
    }

    if (selectedProperties.type === 'CONDITIONAL') {
      updateConditionalState({
        stateId: selectedProperties.nodeId,
        fields: ['label'],
        values: {
          label: watch('label') || '',
        },
      });

      return;
    }

    if (selectedProperties.type === 'WAIT') {
      updateWaitState({
        stateId: selectedProperties.nodeId,
        fields: ['label'],
        values: {
          label: watch('label') || '',
        },
      });

      return;
    }

    if (selectedProperties.type === 'TRIGGER') {
      updateTriggerState({
        stateId: selectedProperties.nodeId,
        fields: ['label'],
        values: {
          label: watch('label') || '',
        },
      });
    }
  };

  const handleClose = () => {
    setSelectedProperties(undefined);
  };

  if (isLoading) {
    return (
      <div>
        <Skeleton className="w-28 h-8" />
      </div>
    );
  }

  return (
    <PropertiesLayout>
      <div className=" flex h-full flex-col ">
        <div className=" flex justify-end ">
          <Button onClick={handleClose} variant="outline" size="icon">
            <XIcon className="w-4 h-4" />
          </Button>
        </div>
        <StateLabelInput register={register} errors={formErrors} />
        <h2 className=" font-semibold text-sm capitalize ">
          {action.type.toLowerCase().split('_').join(' ')} Properties
        </h2>
        <div className="flex flex-col gap-2 py-4 flex-1">
          <div>
            <div>Where</div>
            <SelectComponent
              className=" w-full "
              onChange={handleToStateSelect}
              value={gotoProperties.toState}
              options={toStateOptions}
              placeholder="Select to state"
            />
            <p className="text-destructive text-xs h-6 pt-1">{errors.toState}</p>
          </div>
        </div>
        <div className=" py-2 flex items-center gap-4 justify-end ">
          <Button onClick={handleSave} disabled={isPending} className=" flex items-center gap-2 ">
            {isPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
            Save
          </Button>
        </div>
      </div>
    </PropertiesLayout>
  );
};
