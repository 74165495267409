import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import SignUpForm from '@/components/user/SignUpForm';
import WithTopNav from '@/components/wrappers/WithTopNavBar';
import { AuthQueryKeys, useAuthToken, useGetPanDetailsMutation, usePostMutationSignUp, useSwitchCompany } from '@/hooks/api-hooks/useAuth';
import { useGetInviteValidation } from '@/hooks/api-hooks/useInvitationQuery';
import { cn } from '@/lib/utils';
import { IUserDetails } from '@/types/auth.types';
import { useQueryClient } from '@tanstack/react-query';
import Lottie from 'lottie-react';
import { ArrowLeftIcon, EyeIcon, EyeOffIcon, Loader2Icon } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import heroAnimationData from '../assets/animation_llay8o41.json';

interface IPanForm {
  pan: string;
}

interface IPassWordForm {
  password: string;
}

const SignUpPanForm = ({ handleNext, inviteCode }: { handleNext: (_: IUserDetails) => void; inviteCode: string }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<IPanForm>();

  const { toast } = useToast();

  const {
    mutate: getPanDetails,
    isError,
    error,
    isPending,
  } = useGetPanDetailsMutation({
    customConfig: {
      onSuccess(data) {
        if (data.data.firstName || data.data.lastName) {
          handleNext({ ...data.data, pan: getValues('pan') });
        } else {
          toast({
            title: 'No details found with the pan',
            description: 'Please check the entered PAN',
            variant: 'destructive',
          });
        }
      },
      onError() {
        toast({
          title: 'Pan cannot be verified',
          variant: 'destructive',
        });
      },
    },
  });

  const onSubmit = (data: IPanForm) => {
    getPanDetails({
      ...data,
      inviteCode,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className=" w-full flex flex-col items-start ">
      <h2 className=" text-lg sm:text-2xl font-semibold mb-8">PAN Verification</h2>
      <div className=" mt-4 mb-2 w-full ">
        <p className=" text-sm text-muted-foreground  ">Please enter your PAN</p>
        <Input
          autoFocus
          placeholder="Eg: ABEPW1234Q"
          className=" w-full my-1 "
          {...register('pan', {
            required: {
              value: true,
              message: 'Pan is required',
            },
            pattern: {
              value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i,
              message: 'Entered pan is invalid',
            },
          })}
        />
        {isError && (
          <p className=" text-xs text-red-500 h-6 p-0.5 ">
            {error.response?.data.message && error.response.data.message}
          </p>
        )}
        {!isError && <p className=" text-xs text-red-500 h-6 p-0.5 ">{errors.pan?.message && errors.pan.message}</p>}
      </div>
      <Button disabled={isPending} type="submit" className=" w-full flex items-center gap-4 ">
        Next
        {isPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
      </Button>
    </form>
  );
};

const PassWordForm = ({
  userDetails,
  inviteCode,
  handlePrev,
}: {
  userDetails: IUserDetails;
  inviteCode: string;
  handlePrev: () => void;
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IPassWordForm>();
  const [showPassword, setShowPassword] = useState(false);

  const { toast } = useToast();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { mutate: signUp, isPending } = usePostMutationSignUp({
    customConfig: {
      onSuccess: (data) => {
        navigate('/add-company');
        queryClient.setQueryData(AuthQueryKeys.getAuthenticationStatus(), {
          ...data,
          message: 'Authenticated user',
        });
        queryClient.invalidateQueries({ queryKey: ['companies'] });
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message,
          title: 'Some error occurred',
        });
      },
    },
  });

  const onSubmit = (data: IPassWordForm) => {
    signUp({
      ...data,
      pan: userDetails.pan,
      invitationCode: inviteCode,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className=" w-full flex flex-col items-start ">
      <div className="flex justify-between items-center mb-8 w-full">
        <h2 className=" text-lg sm:text-2xl font-semibold">Set Password</h2>
        <Button onClick={handlePrev} variant="secondary" className="flex items-center gap-4">
          <ArrowLeftIcon className=" w-4 h-4  " /> Back
        </Button>
      </div>
      <div className=" mt-4 w-full ">
        <p className=" text-sm text-muted-foreground ">Your name as per PAN {userDetails.pan.toUpperCase()}</p>
        <p className=" text-sm text-muted-foreground "> If this is not you please edit your pan</p>
        <Input
          placeholder="Eg: ABEPW1234Q"
          disabled
          className=" w-full my-1 "
          value={`${userDetails.firstName} ${userDetails.lastName}`.trim()}
        />
      </div>
      <div className="my-4 w-full ">
        <p className=" text-sm text-muted-foreground  ">Please set a password</p>
        <div className=" flex items-center w-full my-1 gap-4 ">
          <Input
            autoFocus
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter password"
            className="flex-1"
            {...register('password', {
              required: {
                value: true,
                message: 'Password is required',
              },
              minLength: {
                value: 8,
                message: 'Password must be atleast 8 characters',
              },
            })}
          />
          <Button type="button" onClick={() => setShowPassword((prev) => !prev)} size="icon" variant="ghost">
            {showPassword ? <EyeIcon /> : <EyeOffIcon />}
          </Button>
        </div>
        <p className=" text-xs text-red-500 h-6 p-0.5 ">{errors.password?.message && errors.password.message}</p>
      </div>
      <Button disabled={isPending} type="submit" className=" w-full flex items-center gap-4 ">
        Next
        {isPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
      </Button>
    </form>
  );
};

const SignUpFormWrapper = ({ inviteCode }: { inviteCode: string }) => {
  const [step, setStep] = useState(0);
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    pan: '',
  });

  const handleNext = (data: IUserDetails) => {
    setUserDetails(data);
    setStep(1);
  };

  const handlePrev = () => {
    setStep(0);
  };

  return (
    <>
      {step === 0 && <SignUpPanForm inviteCode={inviteCode} handleNext={handleNext} />}
      {step === 1 && <PassWordForm handlePrev={handlePrev} inviteCode={inviteCode} userDetails={userDetails} />}
    </>
  );
};

const AlreadyHaveAnAccountSignIn = () => {
  return (
    <div className={`mt-4`}>
      <span className="text-gray-500">
        Already have an account?{' '}
        <Link className="text-blue-700 underline" to="/signin">
          Sign In
        </Link>
      </span>
    </div>
  );
};

const NoInviteCode = () => {
  return (
    <div>
      <div className=" text-xl ">
        <span className="font-semibold ">Oops!</span> <span>Invite not found</span>
      </div>
      <AlreadyHaveAnAccountSignIn />
    </div>
  );
};

const InvalidInvite = ({ message }: { message: string }) => {
  return (
    <div>
      <div className=" text-xl ">
        <span className="font-semibold ">Oops!</span> <span>{message}</span>
      </div>
      <AlreadyHaveAnAccountSignIn />
    </div>
  );
};

const SignUpPageWrapper = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <WithTopNav>
      <div className={cn(className, 'flex-1')}>{children}</div>
    </WithTopNav>
  );
};

const SignUpPage = () => {
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [newlyMappedCompany, setNewlyMappedCompany] = useState<string>('');
  const [canSkipSignupChecking, setCanSkipSignupChecking] = useState<boolean>(true);
  const { isError } = useAuthToken();



  const inviteCode = useMemo(() => {
    return searchParams.get('inviteCode');
  }, [searchParams]);

  const {
    data: inviteValidationData,
    isPending: isInviteValidationLoading,
    error,
  } = useGetInviteValidation({
    invitationCode: inviteCode?.trim() || '',
    customConfig: {
      enabled: !!inviteCode?.trim(),
    },
  });
  const { mutate: switchCompany } = useSwitchCompany({
    customConfig: {
      onSuccess: () => {
        queryClient.resetQueries();
        navigate('/cashflow/summary');
      },
    },
  });
  useEffect(() => {
    if (inviteValidationData?.data.isValid && inviteValidationData.data.isCompanyAttached && inviteValidationData.data?.skipSignup?.canSkip === true) {
      if (isError) {
        navigate('/signin');
      }
      const companyId = inviteValidationData.data?.skipSignup?.companyId;
      if (companyId !== null) {
        setNewlyMappedCompany(companyId);
      }
      setCanSkipSignupChecking(false);
    }
    else {
      setCanSkipSignupChecking(false);
    }
    newlyMappedCompany != '' && switchCompany({ currentCompanyId: newlyMappedCompany })
  }, [newlyMappedCompany, inviteValidationData]);
  if (isInviteValidationLoading || canSkipSignupChecking) {
    return (
      <SignUpPageWrapper className=" flex justify-center items-center">
        <Loader2Icon className="w-10 h-10 animate-spin" />
      </SignUpPageWrapper>
    );
  }

  if (!inviteCode?.trim()) {
    return (
      <SignUpPageWrapper className=" flex justify-center items-center ">
        <NoInviteCode />
      </SignUpPageWrapper>
    );
  }

  if (error) {
    return (
      <SignUpPageWrapper className=" flex justify-center items-center ">
        <InvalidInvite message={error.response?.data.message || ''} />
      </SignUpPageWrapper>
    );
  }

  if (!inviteValidationData?.data.isValid) {
    return (
      <SignUpPageWrapper className=" flex justify-center items-center ">
        <InvalidInvite message={inviteValidationData?.message || ''} />
      </SignUpPageWrapper>
    );
  }

  if (inviteValidationData.data.isCompanyAttached) {
    return (
      <SignUpPageWrapper className="w-full flex-1 flex justify-center items-center">
        <div className=" flex flex-col gap-12 md:flex-row items-center justify-around sm:gap-8 max-w-6xl w-full px-4">
          <div className=" w-full sm:min-w-[400px] sm:max-w-sm  ">
            <Lottie animationData={heroAnimationData} loop={true} autoplay={true} />
          </div>
          <div className="w-full md:max-w-sm md:min-w-[400px]">
            <SignUpForm inviteCode={inviteCode} />
          </div>
        </div>
      </SignUpPageWrapper>
    );
  }

  return (
    <SignUpPageWrapper className="w-full flex-1 flex justify-center items-center">
      <div className=" flex flex-col gap-12 md:flex-row items-center justify-around sm:gap-8 max-w-6xl w-full px-4">
        <div className=" w-full sm:min-w-[400px] sm:max-w-sm  ">
          <Lottie animationData={heroAnimationData} loop={true} autoplay={true} />
        </div>
        <div className="w-full md:max-w-sm md:min-w-[400px] ">
          <SignUpFormWrapper inviteCode={inviteCode} />
        </div>
      </div>
    </SignUpPageWrapper>
  );
};

export default SignUpPage;
