import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useFetchAttachmentFile } from '@/hooks/api-hooks/useAttachmentsQuery';
import { IAttachmentsObject } from '@/types/attachments.types';
import { IOptions } from '@/types/common.types';
import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { useMemo, useState } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { useToast } from '../ui/use-toast';
import DeleteAttachment from './DeleteAttachment';

const ActionsCell = ({ attachmentId }: { attachmentId: string }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <>
      <DeleteAttachment
        isDeleteDialogOpen={isDeleteDialogOpen}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        attachmentId={attachmentId}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <DotsVerticalIcon className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setIsDeleteDialogOpen(true)}>Delete</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

const AttachmentRow = ({ attachmentDetails }: { attachmentDetails: IAttachmentsObject }) => {
  const { toast } = useToast();
  const DivCell = ({ children }: { children: React.ReactNode }) => {
    return <div className=" pt-4 py-2 min-w-[180px] w-full ">{children}</div>;
  };
  const downloadFile = (fileUrl: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const fetchAttachmentFile = useFetchAttachmentFile({});

  const handleDownload = (attachmentId: string, fileName: string) => {
    fetchAttachmentFile.mutate(
      { attachmentId },
      {
        onSuccess: (response) => {
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);
          downloadFile(url, fileName);
          window.URL.revokeObjectURL(url);

          toast({
            title: 'Success',
            description: 'Attachment downloaded successfully',
          });
        },
        onError: (error) => {
          console.error('Error fetching the file:', error);
          toast({
            title: 'Error',
            description: 'Error occured while downloading attachment',
            variant: 'destructive',
          });
        },
      },
    );
  };
  const purposeTypeOptions = useMemo<
    {
      label: string;
      value: string;
    }[]
  >(
    () => [
      {
        label: 'Invoice for buyer',
        value: 'INVOICE_FOR_BUYER',
      },
      {
        label: 'Invoice Supporting',
        value: 'INVOICE_SUPPORTING',
      },
      {
        label: 'Purchase Order',
        value: 'PURCHASE_ORDER',
      },
      {
        label: 'Sales Order',
        value: 'SALES_ORDER',
      },
      {
        label: 'Delivery Document',
        value: 'DELIVERY_DOCUMENT',
      },
      {
        label: 'Payment Proof',
        value: 'PAYMENT_PROOF',
      },
      {
        label: 'Dispute',
        value: 'DISPUTE',
      },
      {
        label: 'Dispute Resolution',
        value: 'DISPUTE_RESOLUTION',
      },
      {
        label: 'Enquiry',
        value: 'ENQUIRY',
      },
      {
        label: 'Enquiry Resolution',
        value: 'ENQUIRY_RESOLUTION',
      },
      {
        label: 'Tax Document',
        value: 'TAX_DOCUMENT',
      },
      {
        label: 'Commercial Agreement',
        value: 'COMMERCIAL_AGREEMENT',
      },
      {
        label: 'Commercial Agreement Supporting',
        value: 'COMMERCIAL_AGREEMENT_SUPPORTING',
      },
      {
        label: 'Legal',
        value: 'LEGAL',
      },
      {
        label: 'General',
        value: 'GENERAL',
      },
    ],
    [],
  );

  const attachmentPurpose = purposeTypeOptions.find(
    (option: IOptions) => option.value === attachmentDetails.purpose,
  )?.label;

  return (
    <div className=" flex justify-between ">
      <DivCell>
        <div
          className=" flex pl-6  gap-2 w-[240px]"
          onClick={() => {
            handleDownload(attachmentDetails.id, attachmentDetails.fileName);
          }}
        >
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="cursor-pointer text-[14px] text-ellipsis	overflow-hidden whitespace-nowrap	text-blue-700">
                {attachmentDetails.fileName ? attachmentDetails.fileName : 'Not available'}
              </TooltipTrigger>
              <TooltipContent>{attachmentDetails.fileName}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </DivCell>
      <DivCell>
        <div className=" flex pl-6 px-2  gap-2 ">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="cursor-text text-[14px] text-ellipsis	overflow-hidden">
                {attachmentPurpose ? attachmentPurpose : 'Not available'}
              </TooltipTrigger>
              <TooltipContent>{attachmentPurpose}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </DivCell>
      <DivCell>
        <div className="pl-6 px-2">
          {attachmentDetails.createdBy ? attachmentDetails.createdBy : 'Not available'} <br />
          <div className="muted text-[11px] font-normal	text-[##888888]-400">
            on {attachmentDetails.createdAt.toString()}
          </div>
        </div>
      </DivCell>
      <DivCell>
        <div className=" flex pl-6 px-2 gap-2 ">
          <div>
            <ActionsCell attachmentId={attachmentDetails.id} />
          </div>
        </div>
      </DivCell>
    </div>
  );
};
export default AttachmentRow;
