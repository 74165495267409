import { ActivityEventStatus, ActivityEventType, EmailServiceProviderEventType } from '@/types/activity.types';

import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import dayjs from 'dayjs';
import { CalendarCheck2Icon, CalendarIcon, CheckCheckIcon, InfoIcon, XCircleIcon } from 'lucide-react';
import { useMemo } from 'react';

const EventStatus = ({
  eventName,
  eventParticipants,
  eventStatus,
  showDetails = false,
}: {
  eventName: ActivityEventType | EmailServiceProviderEventType;
  eventParticipants: { email: string; timestamp: number; description?: string | null }[];
  eventStatus: ActivityEventStatus;
  showDetails?: boolean;
}) => {
  const notDeliveredEventNames = useMemo(() => ['bounce', 'dropped', 'deferred'], []);

  const eventStatusString = useMemo(() => {
    if (eventStatus === 'ALL') {
      if (notDeliveredEventNames.includes(eventName)) {
        return 'any recipient';
      }
      return 'all recipients';
    }
    if (eventStatus === 'PARTIAL') {
      return 'some recipients';
    }
    if (eventStatus === 'NONE') {
      return 'none';
    }

    return '';
  }, [eventStatus, eventName, notDeliveredEventNames]);

  const eventNameString = useMemo<Record<ActivityEventType | EmailServiceProviderEventType, string>>(() => {
    return {
      bounce: 'Not delivered to',
      delivered: 'Delivered to',
      open: 'Seen by',
      sent: 'Sent to',
      other: '',
      click: '',
      deferred: 'Not delivered to',
      dropped: 'Not delivered to',
      group_resubscribe: '',
      group_unsubscribe: '',
      processed: '',
      spam_report: '',
      unsubscribe: '',
    };
  }, []);

  const eventIcon = useMemo<Record<ActivityEventType | EmailServiceProviderEventType, React.ReactNode>>(() => {
    return {
      bounce: <XCircleIcon className="w-3 h-3 text-destructive" />,
      delivered: <CalendarCheck2Icon className="w-3 h-3" />,
      open: <CheckCheckIcon className="w-3 h-3" />,
      sent: <CalendarIcon className="w-3 h-3" />,
      other: '',
      click: '',
      deferred: <XCircleIcon className="w-3 h-3 text-destructive" />,
      dropped: <XCircleIcon className="w-3 h-3 text-destructive" />,
      group_resubscribe: '',
      group_unsubscribe: '',
      processed: '',
      spam_report: '',
      unsubscribe: '',
    };
  }, []);

  return (
    <AccordionItem value={eventName} className=" w-full border-none no-underline my-0 py-0">
      <AccordionTrigger className=" my-0 pb-4 pt-0 ">
        <div className="flex cursor-pointer items-center gap-2 text-xs text-muted-foreground no-underline justify-start text-left">
          <span>{eventIcon[eventName]}</span>
          {eventNameString[eventName]} {eventStatusString}
        </div>
      </AccordionTrigger>
      <AccordionContent className="w-full overflow-x-scroll max-w-60">
        <div className="flex flex-col gap-2">
          {eventParticipants
            .filter((participant) => participant.email !== 'updates@valyx.com')
            .sort((a, b) => a.email.localeCompare(b.email))
            .map((participant) =>
              showDetails && !!participant.description ? (
                <HoverCard key={participant.email}>
                  <div className=" flex items-center ">
                    <div
                      className=" text-xs  min-w-fit whitespace-nowrap rounded-full flex items-center gap-2 "
                      key={participant.email}
                    >
                      {participant.email}
                      {eventName !== 'bounce' &&
                        ` on ${dayjs.unix(participant.timestamp).format('DD MMM YYYY, HH:mm:ss a')}`}
                    </div>
                    <HoverCardTrigger asChild>
                      <Button variant="ghost" size="icon" className="  text-muted-foreground hover:text-primary">
                        <InfoIcon className="w-3 h-3" />
                      </Button>
                    </HoverCardTrigger>
                  </div>
                  <HoverCardContent className="w-60" align="end">
                    <div className="text-xs text-muted-foreground">{participant.description}</div>
                  </HoverCardContent>
                </HoverCard>
              ) : (
                <div
                  className=" text-xs  min-w-fit whitespace-nowrap rounded-full px-2 flex items-center gap-2 "
                  key={participant.email}
                >
                  {participant.email}{' '}
                  {eventName !== 'bounce' &&
                    `on ${dayjs.unix(participant.timestamp).format('DD MMM YYYY, HH:mm:ss a')}`}
                </div>
              ),
            )}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default EventStatus;
