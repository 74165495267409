import CreditNotesFilters from '@/components/credit-notes/CreditNoteFilters';
import BankIdToName from '@/components/shared/BankIdToName';
import CategoryIdToLabel from '@/components/shared/CategoryIdToLabel';
import Pagination from '@/components/shared/Pagination';
import { ColumnSelector } from '@/components/shared/ReactTableColumnSelector';
import { ResizableTable } from '@/components/Table/ResizableTable';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useUserContext } from '@/contexts/UserContext';
import { useGetFilteredCreditNotes } from '@/hooks/api-hooks/useCreditNotesQuery';
import {
  useGetCreditedBankTransactions,
  useGetPlaceholders,
  useGetPostedCreditsForInvoice,
} from '@/hooks/api-hooks/usePostingQuery';
import { useColumnOrder } from '@/hooks/table-hooks/useColumnOrder';
import { useColumnVisibility } from '@/hooks/table-hooks/useColumnVisibility';
import { cn } from '@/lib/utils';
import { AppliedInvoicesHoverCard } from '@/pages/credit-note/credit-note-list';
import {
  IAppliedInvoiceToCreditNote,
  ICreditNoteListItem,
  ICreditNotesFilters,
  ISingleCreditNote,
} from '@/types/credit-notes.types';
import { IInvoice } from '@/types/invoices.types';
import {
  ICredit,
  ICreditNotePosting,
  ICreditPosting,
  ICreditsFilters,
  IPostingFormDetails,
} from '@/types/posting.types';
import {
  POSTING_SELECTED_INVOICE_VIEW_CREDITS_COLUMN_ORDER_STORAGE_KEY,
  POSTING_SELECTED_INVOICE_VIEW_CREDITS_COLUMN_VISIBILITY_STORAGE_KEY,
} from '@/utils/constants/local-storage-keys';
import { formatCurrency } from '@/utils/formatCurrency';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { toFixed } from '@/utils/toFixed';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import {
  ColumnDef,
  getCoreRowModel,
  Table as ReactTable,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ChevronsLeftIcon, ChevronsRightIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { CreditFiltersDialog } from '../credits/CreditsFilters';
import SelectedItems from './SelectedItems';

const CreditsTable = ({ table, isLoading }: { table: ReactTable<ICredit>; isLoading: boolean }) => {
  return <ResizableTable columnScale={1.4} headersToExcludeResizing={['select']} isLoading={isLoading} table={table} />;
};

const CreditNotesTable = ({ table, isLoading }: { table: ReactTable<ICreditNoteListItem>; isLoading: boolean }) => {
  return <ResizableTable columnScale={1.4} headersToExcludeResizing={['select']} isLoading={isLoading} table={table} />;
};

const CreditsTableWrapper = ({
  invoiceId,
  handleToggleCredit,
  selectedCredits,
  listItemType,
  setListItemType,
  toggleSheetOpen,
  isPostingSheetOpen,
  postedCount,
}: {
  invoiceId: string;
  handleToggleCredit: (_: boolean | string, __: ICredit) => void;
  selectedCredits: ICreditPosting[];
  listItemType: 'credits' | 'credit-notes';
  setListItemType: React.Dispatch<React.SetStateAction<'credits' | 'credit-notes'>>;
  toggleSheetOpen: () => void;
  isPostingSheetOpen: boolean;
  postedCount: number;
}) => {
  const [creditFilters, setCreditFilters] = useState<ICreditsFilters>({
    bankAccountIds: [],
    postingStatus: ['PARTIALLY_POSTED', 'UNPOSTED'],
    categories: [],
  });
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    const selectedRows = selectedCredits.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.transactionId]: true,
      };
    }, {});
    setRowSelection(selectedRows);
  }, [selectedCredits]);

  const creditColumns = useMemo<ColumnDef<ICredit>[]>(() => {
    return [
      {
        id: 'select',
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => handleToggleCredit(value, row.original)}
            aria-label="Select row"
          />
        ),

        enableSorting: false,
        enableHiding: false,
        minSize: 50,
        size: 50,
        showGrips: false,
      },
      {
        header: 'Transaction Date',
        id: 'transactionDate',
        accessorKey: 'transactionDate',
        cell: ({ getValue }) => (
          <div className=" w-full ">
            <div className="w-full truncate text-ellipsis ">{dayjs(getValue() as string).format('DD MMM YYYY')}</div>
          </div>
        ),
        size: 180,
      },
      {
        header: 'Bank',
        id: 'bank',
        accessorKey: 'bankAccountId',
        size: 150,
        cell: ({ getValue }) => {
          return <BankIdToName bankId={getValue() as string} />;
        },
      },
      {
        header: 'Total Credit',
        id: 'totalCredit',
        accessorKey: 'creditAmount',
        size: 130,
        cell: ({ getValue }) => formatCurrencyByUnit(getValue() as number, 'actual'),
      },
      {
        header: 'Available For Posting',
        id: 'availableCredit',
        accessorKey: 'remainingAmount',
        size: 200,
        cell: ({ getValue }) => formatCurrencyByUnit(getValue() as number, 'actual'),
      },
      {
        header: 'Posting Status',
        id: 'postingStatus',
        accessorKey: 'postingStatus',
        cell: ({ getValue }) => (
          <Badge
            className={cn(
              getValue() === 'POSTED' && 'bg-green-500 hover:bg-green-500 hover:text-white text-white',
              getValue() === 'UNPOSTED' && 'bg-red-500 text-white hover:bg-red-500 hover:text-white',
              getValue() === 'PARTIALLY_POSTED' && 'bg-yellow-400 text-black hover:bg-yellow-400',
              'capitalize',
            )}
          >
            {(getValue() as string).split('_').join(' ').toLowerCase()}
          </Badge>
        ),
        size: 130,
      },
      {
        header: 'Narration',
        id: 'narration',
        accessorKey: 'narration',
        size: 240,
        cell: ({ getValue }) => <ToolTipCell value={getValue() as string} />,
      },
      {
        header: 'Category',
        id: 'category',
        accessorKey: 'category',
        size: 150,
        cell: ({ getValue }) => {
          return <CategoryIdToLabel categoryId={getValue() as string} />;
        },
      },
    ];
  }, [handleToggleCredit]);

  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { data: creditsData, isLoading } = useGetCreditedBankTransactions({
    ...creditFilters,
    notInInvoice: [invoiceId],
    page: page,
    limit: pageLimit,
    companyId: companiesOfUser[activeCompanyIndex]?.id || '',
    customConfig: {},
  });

  const handleSubmit = useCallback((filters: ICreditsFilters) => {
    setCreditFilters(filters);
  }, []);

  const credits = useMemo(() => {
    return creditsData?.data.docs || [];
  }, [creditsData]);

  const { columnOrder, setColumnOrder } = useColumnOrder({
    key: POSTING_SELECTED_INVOICE_VIEW_CREDITS_COLUMN_ORDER_STORAGE_KEY,
    columns: creditColumns,
  });

  const { columnVisibility, setColumnVisibility } = useColumnVisibility({
    columns: creditColumns,
    key: POSTING_SELECTED_INVOICE_VIEW_CREDITS_COLUMN_VISIBILITY_STORAGE_KEY,
  });

  const creditsTable = useReactTable({
    columns: creditColumns,
    data: credits,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
    columnResizeMode: 'onChange',
    onRowSelectionChange: setRowSelection,
    defaultColumn: {
      size: 100,
    },
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      rowSelection: {
        ...rowSelection,
        // ...(listType === 'posted' ? defaultRowSelection : {}),
      },
      columnVisibility,
      columnOrder,
    },
  });

  const creditTableColumns = useMemo(() => creditsTable.getAllColumns(), [creditsTable]);

  return (
    <div className=" flex flex-col flex-1 overflow-auto  ">
      <div
        className={cn(
          ' relative flex items-center justify-between gap-2 px-2 min-h-24 overflow-hidden ',
          isPostingSheetOpen && 'pr-8',
        )}
      >
        <div>
          <Tabs onValueChange={(value) => setListItemType(value as 'credits' | 'credit-notes')} value={listItemType}>
            <TabsList>
              <TabsTrigger value="credits">Credits</TabsTrigger>
              <TabsTrigger value="credit-notes">Credit Notes</TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        <div className=" flex-1 ">
          <CreditFiltersDialog filters={creditFilters} handleSubmit={handleSubmit} />
        </div>
        <ColumnSelector columns={creditTableColumns} columnOrder={columnOrder} setColumnOrder={setColumnOrder} />
        {!isPostingSheetOpen && (
          <Button
            icon={<ChevronsLeftIcon className={cn('w-4 h-4', isPostingSheetOpen && 'rotate-180')} />}
            iconPosition="left"
            onClick={toggleSheetOpen}
          >
            Posted({postedCount})
          </Button>
        )}
      </div>
      <CreditsTable isLoading={isLoading} table={creditsTable} />

      <Pagination
        hasNext={!!creditsData?.data.hasNext}
        hasPrev={!!creditsData?.data.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setPageLimit}
        pageNumber={page}
        rowsPerPage={pageLimit}
        totalPages={creditsData?.data.totalPages || 0}
      />
    </div>
  );
};

const CreditNotesTableWrapper = ({
  handleToggleCreditNote,
  selectedCreditNotes,
  listItemType,
  setListItemType,
  customerId,
  customerName,
  toggleSheetOpen,
  isPostingSheetOpen,
  postedCount,
}: {
  handleToggleCreditNote: (_: boolean | string, __: ICreditNoteListItem) => void;
  selectedCreditNotes: ICreditNotePosting[];
  listItemType: 'credits' | 'credit-notes';
  setListItemType: React.Dispatch<React.SetStateAction<'credits' | 'credit-notes'>>;
  customerId: string;
  customerName: string;
  toggleSheetOpen: () => void;
  isPostingSheetOpen: boolean;
  postedCount: number;
}) => {
  const [creditNoteFilters, setCreditNoteFilters] = useState<Partial<ICreditNotesFilters>>({
    status: ['PARTIALLY_APPLIED', 'NOT_APPLIED'],
  });
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    const selectedRows = selectedCreditNotes.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.identifierId]: true,
      };
    }, {});
    setRowSelection(selectedRows);
  }, [selectedCreditNotes]);

  const creditNoteColumns = useMemo<ColumnDef<ICreditNoteListItem>[]>(
    () => [
      {
        id: 'select',
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => handleToggleCreditNote(value, row.original)}
            aria-label="Select row"
          />
        ),

        enableSorting: false,
        enableHiding: false,
        minSize: 50,
        size: 50,
        showGrips: false,
      },
      {
        accessorKey: 'creditNoteNumber',
        header: 'Number',
        id: 'creditNoteNumber',
        cell: ({ getValue, row }) => (
          <div className=" w-full text-ellipsis truncate ">
            <Link className="text-blue-700 underline" to={`/credit-note/view/${row.original.id}`}>
              {getValue() as string}
            </Link>
            <p className=" text-ellipsis truncate text-muted-foreground text-xs my-1 ">
              {row.original.lineItems?.length ? `${row.original.lineItems[0].lineItemDetails.name}` : ''}
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'customerName',
        header: 'Customer Name',
        id: 'customerName',
        cell: ({ getValue, row }) => (
          <Link className="text-blue-700 underline" to={`/customer/view/${row.original.customerId}`}>
            {getValue() as string}
          </Link>
        ),
      },
      {
        accessorKey: 'creditNoteDate',
        header: 'Date',
        id: 'creditNoteDate',
        cell: ({ getValue }) => dayjs(getValue() as string).format('DD MMM YYYY'),
      },
      {
        accessorKey: 'creditNoteType',
        header: 'Type',
        id: 'creditNoteType',
        cell: ({ getValue }) => getValue() as string,
      },
      {
        accessorKey: 'creditNoteInvoicesReferences',
        label: 'Originating Invoices',
        header: () => (
          <TooltipProvider>
            <Tooltip>
              <div className=" flex items-center gap-2 ">
                Originating Invoices
                <TooltipTrigger>
                  <InfoCircledIcon className=" w-4 h-4 " />
                </TooltipTrigger>
                <TooltipContent>The invoice against which the credit note was created</TooltipContent>
              </div>
            </Tooltip>
          </TooltipProvider>
        ),
        id: 'creditNoteInvoicesReferences',
        cell: ({ getValue }) => {
          const invoices = getValue() as IAppliedInvoiceToCreditNote[] | null;

          if (!invoices) {
            return '-';
          }

          const length = invoices.length - 1;

          if (length < 0) {
            return '-';
          }

          if (length === 0) {
            return (
              <Link className=" text-blue-700 underline " to={`/invoice/view/${invoices[0].invoiceId}`}>
                {invoices[0].invoiceNumber}
              </Link>
            );
          }

          return (
            <span>
              <Link className=" text-blue-700 underline " to={`/invoice/view/${invoices[0].invoiceId}`}>
                {invoices[0].invoiceNumber}
              </Link>{' '}
              <AppliedInvoicesHoverCard invoices={invoices}>
                <Button variant="ghost">+ {length}</Button>
              </AppliedInvoicesHoverCard>
            </span>
          );
        },
      },
      {
        accessorKey: 'creditNoteAppliedInvoices',
        label: 'Applied Invoices',
        id: 'creditNoteAppliedInvoices',
        header: () => (
          <TooltipProvider>
            <Tooltip>
              <div className=" flex items-center gap-2 ">
                Applied Invoices
                <TooltipTrigger>
                  <InfoCircledIcon className=" w-4 h-4 " />
                </TooltipTrigger>
                <TooltipContent>The invoice(s) to which the credit note is applied</TooltipContent>
              </div>
            </Tooltip>
          </TooltipProvider>
        ),
        cell: ({ getValue }) => {
          const invoices = getValue() as IAppliedInvoiceToCreditNote[] | null;

          if (!invoices) {
            return '-';
          }

          const length = invoices.length - 1;

          if (length < 0) {
            return '-';
          }

          if (length === 0) {
            return (
              <Link className=" text-blue-700 underline " to={`/invoice/view/${invoices[0].invoiceId}`}>
                {invoices[0].invoiceNumber}
              </Link>
            );
          }

          return (
            <span>
              <Link className=" text-blue-700 underline " to={`/invoice/view/${invoices[0].invoiceId}`}>
                {invoices[0].invoiceNumber}
              </Link>{' '}
              <AppliedInvoicesHoverCard invoices={invoices}>
                <Button variant="ghost">+ {length}</Button>
              </AppliedInvoicesHoverCard>
            </span>
          );
        },
      },
      {
        accessorKey: 'creditNoteTotal',
        header: 'Amount',
        id: 'creditNoteTotal',
        cell: ({ getValue, row }) => (
          <div>
            <p>{formatCurrency(getValue() as number)}</p>
            <p className=" text-xs text-muted-foreground ">
              Avl. To Apply: {formatCurrency(row.original.creditNoteRemainingAmount)}
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'creditNoteStatus',
        header: 'Status',
        id: 'creditNoteStatus',
        cell: ({ getValue }) => (
          <Badge className=" capitalize mx-auto ">{(getValue() as string).split('_').join(' ').toLowerCase()}</Badge>
        ),
      },
    ],
    [handleToggleCreditNote],
  );

  const { data: creditNotesData, isLoading } = useGetFilteredCreditNotes({
    ...creditNoteFilters,
    customers: [
      {
        value: customerId,
        label: customerName,
      },
    ],
    page: page,
    limit: pageLimit,
    customConfig: {},
  });

  const handleSubmit = useCallback((filters: Partial<ICreditNotesFilters>) => {
    setCreditNoteFilters(filters);
  }, []);

  const creditNotes = useMemo(() => {
    return creditNotesData?.data.docs || [];
  }, [creditNotesData]);

  const { columnOrder, setColumnOrder } = useColumnOrder({
    key: POSTING_SELECTED_INVOICE_VIEW_CREDITS_COLUMN_ORDER_STORAGE_KEY,
    columns: creditNoteColumns,
  });

  const { columnVisibility, setColumnVisibility } = useColumnVisibility({
    columns: creditNoteColumns,
    key: POSTING_SELECTED_INVOICE_VIEW_CREDITS_COLUMN_VISIBILITY_STORAGE_KEY,
  });

  console.log({ selectedCreditNotes, rowSelection });

  const creditNotesTable = useReactTable({
    columns: creditNoteColumns,
    data: creditNotes,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
    columnResizeMode: 'onChange',
    onRowSelectionChange: setRowSelection,
    defaultColumn: {
      size: 100,
    },
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      rowSelection: {
        ...rowSelection,
        // ...(listType === 'posted' ? defaultRowSelection : {}),
      },
      columnVisibility,
      columnOrder,
    },
  });

  const creditNotesTableColumns = useMemo(() => creditNotesTable.getAllColumns(), [creditNotesTable]);

  return (
    <div className=" flex flex-col flex-1 overflow-auto  ">
      <div
        className={cn(
          ' relative flex items-center justify-between gap-2 px-2 min-h-24  overflow-hidden ',
          isPostingSheetOpen && 'pr-8',
        )}
      >
        <div>
          <Tabs onValueChange={(value) => setListItemType(value as 'credits' | 'credit-notes')} value={listItemType}>
            <TabsList>
              <TabsTrigger value="credits">Credits</TabsTrigger>
              <TabsTrigger value="credit-notes">Credit Notes</TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        <div className=" flex-1 ">
          <CreditNotesFilters
            showCustomerFilter={false}
            creditNotesFilters={creditNoteFilters}
            handleSubmit={handleSubmit}
          />
        </div>
        <ColumnSelector columns={creditNotesTableColumns} columnOrder={columnOrder} setColumnOrder={setColumnOrder} />
        {!isPostingSheetOpen && (
          <Button
            icon={<ChevronsLeftIcon className={cn('w-4 h-4', isPostingSheetOpen && 'rotate-180')} />}
            iconPosition="left"
            onClick={toggleSheetOpen}
          >
            Posted({postedCount})
          </Button>
        )}
      </div>
      <CreditNotesTable isLoading={isLoading} table={creditNotesTable} />
      <Pagination
        hasNext={!!creditNotesData?.data.hasNext}
        hasPrev={!!creditNotesData?.data.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setPageLimit}
        pageNumber={page}
        rowsPerPage={pageLimit}
        totalPages={creditNotesData?.data.totalPages || 0}
      />
    </div>
  );
};

const CreditsForInvoicePostingTableWrapper = ({ invoice }: { invoice: IInvoice }) => {
  const [listItemType, setListItemType] = useState<'credits' | 'credit-notes'>('credits');
  const [searchParams] = useSearchParams();
  const [isPostingSheetOpen, setIsPostingSheetOpen] = useState(false);

  const { data: postingsForInvoiceData, isLoading: isGetPostingsForInvoicesLoading } = useGetPostedCreditsForInvoice({
    invoiceId: invoice.id,
  });

  const { data: placeholderData } = useGetPlaceholders();

  const roundOffPlaceholder = useMemo(
    () => placeholderData?.data.find((item) => item.name === 'ROUND_OFF'),
    [placeholderData],
  );

  const roundOffPlaceholderPosting = useMemo(
    () =>
      postingsForInvoiceData?.data.find(
        (item) => !!item.placeholder.id && item.placeholder.placeholderName === 'ROUND_OFF',
      ),
    [postingsForInvoiceData],
  );

  const alreadyRoundOffAmount = roundOffPlaceholderPosting?.postedAmount || 0;

  const {
    register,
    control,
    handleSubmit: handleFormSubmit,
    getValues,
    formState: { errors, isDirty },
    setValue,
    watch,
    reset,
  } = useForm<IPostingFormDetails>({
    values: {
      credits:
        postingsForInvoiceData?.data
          .filter((item) => !!item?.credit?.id)
          .map((item) => {
            return {
              ...(item.credit as ICredit),
              transactionId: item.credit.id || '',
              postedAmount: item.postedAmount,
              alreadyPostedAmount: item.postedAmount,
              isDefaultSelected: true,
              postingId: item.postingId,
            };
          }) || [],
      creditNotes:
        postingsForInvoiceData?.data
          .filter((item) => !!item?.creditNote?.id)
          .map((item) => ({
            ...(item.creditNote as ISingleCreditNote),
            transactionId: item.creditNote.id || '',
            postedAmount: item.postedAmount,
            alreadyPostedAmount: item.postedAmount,
            isDefaultSelected: true,
            postingId: item.postingId,
            identifierId: item.creditNote.id || '',
          })) || [],
      isWriteOff: false,
      adjustedAmount: 0,
      writeOffComment: '',
      tdsAmount: isNaN(Number(invoice.invoiceTax.tds)) ? 0 : Math.abs(Number(invoice.invoiceTax.tds)),
      roundOffAmount: Math.abs(roundOffPlaceholderPosting?.postedAmount || 0),
    },
    mode: 'onChange',
  });

  const isTdsAlreadyPresent = useMemo(() => {
    return !isNaN(Number(invoice.invoiceTax.tds)) && Number(invoice.invoiceTax.tds) !== 0;
  }, [invoice]);

  const {
    fields: selectedCredits,
    append: appendCredit,
    remove: removeCredit,
  } = useFieldArray({
    name: 'credits',
    control,
    keyName: 'id',
  });

  const {
    fields: selectedCreditNotes,
    append: appendCreditNote,
    remove: removeCreditNote,
  } = useFieldArray({
    name: 'creditNotes',
    control,
    keyName: 'id',
  });

  const currentCredits = watch('credits');
  const currentCreditNotes = watch('creditNotes');

  const toggleSheetOpen = useCallback(() => {
    setIsPostingSheetOpen((prev) => !prev);
  }, []);
  useEffect(() => {
    searchParams.get('defaultListType') === 'posted' && setIsPostingSheetOpen(true);
  }, [searchParams, toggleSheetOpen]);

  const handleToggleCredit = useCallback(
    (value: boolean | string, credit: ICredit) => {
      setIsPostingSheetOpen(true);
      if (value) {
        const newlyPosted =
          currentCredits.reduce((acc, curr) => {
            if (curr.transactionId === credit.id) {
              return Number(acc);
            }
            return Number(acc) + Number(curr.postedAmount) - Number(curr.alreadyPostedAmount);
          }, 0) +
          currentCreditNotes.reduce((acc, curr) => {
            return Number(acc) + Number(curr.postedAmount) - Number(curr.alreadyPostedAmount);
          }, 0);

        const remaining = toFixed(invoice.invoiceOutstandingAmount - newlyPosted, 2);

        appendCredit({
          ...credit,
          postedAmount: Number(Math.min(remaining, credit.remainingAmount).toFixed(2)),
          alreadyPostedAmount: 0,
          transactionId: credit.id,
          postingId: '',
        });
      } else {
        const foundCredits = currentCredits.find((x) => x.transactionId === credit.id);
        if (foundCredits && !foundCredits.isDefaultSelected) {
          removeCredit(currentCredits.findIndex((x) => x.transactionId === credit.id));
        }
      }
    },
    [appendCredit, removeCredit, currentCreditNotes, invoice.invoiceOutstandingAmount, currentCredits],
  );

  const handleToggleCreditNote = useCallback(
    (value: boolean | string, creditNote: ISingleCreditNote) => {
      setIsPostingSheetOpen(true);
      if (value) {
        const newlyPosted =
          currentCreditNotes.reduce((acc, curr) => {
            if (curr.identifierId === creditNote.id) {
              return Number(acc);
            }
            return Number(acc) + Number(curr.postedAmount) - Number(curr.alreadyPostedAmount);
          }, 0) +
          currentCredits.reduce((acc, curr) => {
            return Number(acc) + Number(curr.postedAmount) - Number(curr.alreadyPostedAmount);
          }, 0);

        const remaining = toFixed(invoice.invoiceOutstandingAmount - newlyPosted, 2);

        appendCreditNote({
          ...creditNote,
          postedAmount: Number(Math.min(remaining, creditNote.creditNoteRemainingAmount).toFixed(2)),
          alreadyPostedAmount: 0,
          postingId: '',
          identifierId: creditNote.id,
        });
      } else {
        const foundCredits = currentCreditNotes.find((item) => item.identifierId === creditNote.id);
        if (foundCredits && !foundCredits.isDefaultSelected) {
          removeCreditNote(currentCreditNotes.findIndex((item) => item.identifierId === creditNote.id));
        }
      }
    },
    [appendCreditNote, removeCreditNote, currentCreditNotes, invoice.invoiceOutstandingAmount, currentCredits],
  );

  // TODO: here was code to suggest some filters based on invoice

  return (
    <div className=" text-sm  flex-1 flex overflow-hidden ">
      {listItemType === 'credits' && (
        <CreditsTableWrapper
          handleToggleCredit={handleToggleCredit}
          invoiceId={invoice.id}
          listItemType={listItemType}
          selectedCredits={selectedCredits}
          setListItemType={setListItemType}
          toggleSheetOpen={toggleSheetOpen}
          isPostingSheetOpen={isPostingSheetOpen}
          postedCount={postingsForInvoiceData?.data.length || 0}
        />
      )}
      {listItemType === 'credit-notes' && (
        <CreditNotesTableWrapper
          postedCount={postingsForInvoiceData?.data.length || 0}
          isPostingSheetOpen={isPostingSheetOpen}
          handleToggleCreditNote={handleToggleCreditNote}
          listItemType={listItemType}
          selectedCreditNotes={selectedCreditNotes}
          setListItemType={setListItemType}
          customerId={invoice.customerId}
          customerName={invoice.customerName}
          toggleSheetOpen={toggleSheetOpen}
        />
      )}
      <Sheet modal={false} open={isPostingSheetOpen}>
        <SheetContent side="right" className="p-0 border min-w-[415px] bg-none border-none shadow-none">
          <div className=" bg-white h-[90%] mt-[14.3%] border relative ">
            {isPostingSheetOpen && (
              <Button size="icon" className=" absolute top-[174px] -left-4 z-50 h-7 w-7 " onClick={toggleSheetOpen}>
                <ChevronsRightIcon className="w-4 h-4" />
              </Button>
            )}
            <SelectedItems
              alreadyRoundOffAmount={alreadyRoundOffAmount}
              isRoundOffAmountAlreadyPosted={!!roundOffPlaceholderPosting}
              roundOffPlaceholderPostingId={roundOffPlaceholderPosting?.postingId}
              roundOffPlaceholderId={roundOffPlaceholder?.id}
              isLoading={isGetPostingsForInvoicesLoading}
              alreadyPresentTds={Number(invoice.invoiceTax.tds)}
              postedCredits={selectedCredits}
              postedCreditNotes={selectedCreditNotes}
              isTdsAlreadyPresent={isTdsAlreadyPresent}
              errors={errors}
              getValues={getValues}
              handleFormSubmit={handleFormSubmit}
              isDirty={isDirty}
              register={register}
              setValue={setValue}
              watch={watch}
              reset={reset}
              invoice={invoice}
            />
          </div>
        </SheetContent>
      </Sheet>
      {isPostingSheetOpen && <div className="  min-w-[400px] max-w-[400px] flex-1 "></div>}
    </div>
  );
};

export default CreditsForInvoicePostingTableWrapper;
