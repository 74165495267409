import { Button } from '@/components/ui/button';
import { useDeleteAttachment } from '@/hooks/api-hooks/useAttachmentsQuery';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '../ui/dialog';
import { useToast } from '../ui/use-toast';

const DeleteAttachment = ({
  attachmentId,
  isDeleteDialogOpen,
  setIsDeleteDialogOpen,
}: {
  attachmentId: string;
  isDeleteDialogOpen: boolean;
  setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { toast } = useToast();

  const { mutate: deleteAttachment } = useDeleteAttachment({
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to delete attachment',
        });
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
      },
    },
  });

  return (
    isDeleteDialogOpen && (
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
          <DialogHeader className=" font-semibold ">Delete attachment</DialogHeader>
          <p className="text-sm">Are you sure you want to delete this attachment?</p>
          <DialogFooter>
            <Button onClick={() => setIsDeleteDialogOpen(false)} variant="outline">
              Cancel
            </Button>
            <Button
              onClick={() =>
                deleteAttachment({
                  attachmentId,
                })
              }
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  );
};

export default DeleteAttachment;
