import { useGetTransactionSummary } from '@/hooks/api-hooks/useCashflowQuery';
import { cn } from '@/lib/utils';
import { formatCurrency } from '@/utils/formatCurrency';
import dayjs from 'dayjs';

const BalanceSummary = ({
  dateFilter,
}: {
  dateFilter: {
    startDate: Date;
    endDate: Date;
  };
}) => {
  const { data: totals } = useGetTransactionSummary({
    endDate: dateFilter.endDate,
    startDate: dateFilter.startDate,
  });

  return (
    <div className="flex col-span-2 my-4 flex-col items-end gap-4 text-right">
      <div>
        <div className="text-2xl">{formatCurrency(Math.abs(totals?.data.openingBalance || 0))}</div>
        <div className="text-xs">
          Opening Balance <div>{dayjs(totals?.data.dateRange.startDate).format('DD MMM YYYY')}</div>
        </div>
      </div>
      <div>
        <div className={cn('text-green-700 text-2xl', !totals?.data.totalCredits && 'text-gray-500')}>
          {formatCurrency(Math.abs(totals?.data.totalCredits || 0))}
        </div>
        <div className="text-xs">
          Credits
          <div>
            {dayjs(totals?.data.dateRange.startDate).format('DD MMM YY')} -{' '}
            {dayjs(totals?.data.dateRange.endDate).format('DD MMM YY')}
          </div>
        </div>
      </div>
      <div>
        <div className={cn('text-red-500 text-2xl', !totals?.data.totalDebits && 'text-gray-500')}>
          {formatCurrency(Math.abs(totals?.data.totalDebits || 0))}
        </div>
        <div className="text-xs">
          Debits
          <div>
            {dayjs(totals?.data.dateRange.startDate).format('DD MMM YY')} -{' '}
            {dayjs(totals?.data.dateRange.endDate).format('DD MMM YY')}
          </div>
        </div>
      </div>
      <div>
        <div className="text-2xl">{formatCurrency(Math.abs(totals?.data.closingBalance || 0))}</div>
        <div className="text-xs">
          Closing Balance
          <div>{dayjs(totals?.data.dateRange.endDate).format('DD MMM YYYY')}</div>
        </div>
      </div>
    </div>
  );
};

export default BalanceSummary;
