'use client';

import { IReplaceRequest, Variable } from '@/types/email-templates.types';
import { Section, SectionTypes } from '@/utils/email-builder/sections';
import { getVariables } from '@/utils/getVariablesFromContent';
import { templateToJsonVariableMap } from '../constants/template-variable-to-programming-variable-map';
import { isValidUrl } from '../isValidUrl';

export const createIsCurrencyMap = (
  object: {
    [key: string]:
      | string
      | {
          [key: string]: string;
        };
  },
  parentKey: string,
): {
  isCurrencyVariableMap: {
    [key: string]: boolean | undefined;
  };
  currencyVariables: string[];
} => {
  return Object.entries(object).reduce(
    (prev, [key, value]) => {
      if (typeof value === 'string') {
        if (value === 'CURRENCY') {
          return {
            ...prev,
            isCurrencyVariableMap: {
              ...prev.isCurrencyVariableMap,
              [key]: true,
            },
            currencyVariables: [...prev.currencyVariables, parentKey ? `${parentKey}.${key}` : key],
          };
        }

        return {
          ...prev,
          isCurrencyVariableMap: {
            ...prev.isCurrencyVariableMap,
            [key]: false,
          },
        };
      }

      const { isCurrencyVariableMap, currencyVariables } = createIsCurrencyMap(
        value,
        parentKey ? `${parentKey}.${key}` : key,
      );

      return {
        ...prev,
        isCurrencyVariableMap: {
          ...prev.isCurrencyVariableMap,
          ...isCurrencyVariableMap,
        },
        currencyVariables: [...prev.currencyVariables, ...currencyVariables],
      };
    },
    {
      isCurrencyVariableMap: {},
      currencyVariables: [],
    } as {
      isCurrencyVariableMap: {
        [key: string]: boolean | undefined;
      };
      currencyVariables: string[];
    },
  );
};

const addCurrencyFormatting = (htmlContent: string, currencyVariables: string[]) => {
  let newHtmlContent = htmlContent;

  currencyVariables.forEach((currencyVariable) => {
    newHtmlContent = newHtmlContent.replaceAll(
      new RegExp(`{{[ ]*${currencyVariable}[ ]*}}`, 'g'),
      `{{${currencyVariable} | format_currency}}`,
    );
  });

  return newHtmlContent;
};

export const convertSectionsToHtml = ({
  sections,
  order,
  variableTypeMap,
}: {
  sections: Record<string, Section<SectionTypes>>;
  order: string[];
  variableTypeMap: {
    templateFields: Record<string, string | Record<string, string>>;
    availableAttributes: Record<string, string[]>;
  };
}) => {
  const { isCurrencyVariableMap: isCurrencyMap, currencyVariables } = createIsCurrencyMap(
    variableTypeMap.templateFields,
    '',
  );

  let htmlString = '';
  const variableOccurenceCount: {
    invoice: number;
    customer: number;
  } = {
    invoice: 0,
    customer: 0,
  };
  let variables: Variable[] = [] as Variable[];
  let replaceRequests = [] as IReplaceRequest[];

  order.forEach((sectionId) => {
    if (!sections[sectionId]) return;
    const currentSection = sections[sectionId];
    switch (currentSection.type) {
      case 'text': {
        const { variables: updatedVariables, replaceRequests: replaceRequestsWithinContent } = getVariables({
          content: currentSection.content as string,
          variables: variables,
          variableTypeMap: variableTypeMap,
        });

        variables = updatedVariables;
        replaceRequests = [...replaceRequests, ...replaceRequestsWithinContent];

        htmlString += `<div>
          ${currentSection.content}
          </div>`;
        return;
      }
      case 'button': {
        const { variables: updatedVariables, replaceRequests: replaceRequestsWithinContent } = getVariables({
          content: currentSection.content as string,
          variables: variables,
          variableTypeMap: variableTypeMap,
        });

        variables = updatedVariables;
        replaceRequests = [...replaceRequests, ...replaceRequestsWithinContent];
        const url = currentSection.properties.element.url;

        const alignMent = currentSection.properties.element.align;

        if (url?.trim() && isValidUrl(url)) {
          let formattedUrl = '';

          if (url.includes('http://') || url.includes('https://')) {
            formattedUrl = url;
          } else {
            formattedUrl = `https://${url}`;
          }

          const { variables: updatedVariables, replaceRequests: replaceRequestsWithinContent } = getVariables({
            content: formattedUrl,
            variables: variables,
            variableTypeMap: variableTypeMap,
          });

          variables = updatedVariables;
          replaceRequests = [...replaceRequests, ...replaceRequestsWithinContent];

          htmlString += `<div class="align-${alignMent}" >
          <a href="${formattedUrl}" target="_blank">
          <button class="template-button"
        style="padding-top: ${currentSection.properties.element.padding.top}px;
        padding-right: ${currentSection.properties.element.padding.right}px;
        padding-bottom: ${currentSection.properties.element.padding.bottom}px;
        padding-left: ${currentSection.properties.element.padding.left}px;"
       >${currentSection.content}</button></a></div>`;

          return;
        }

        htmlString += `<div class="align-${alignMent}" ><button class="template-button"
           style="padding-top: ${currentSection.properties.element.padding.top}px;
           padding-right: ${currentSection.properties.element.padding.right}px;
           padding-bottom: ${currentSection.properties.element.padding.bottom}px;
           padding-left: ${currentSection.properties.element.padding.left}px;"
          >${currentSection.content}</button></div>`;
        return;
      }
      case 'table': {
        const tableSection = currentSection as Section<'table'>;
        const columns = tableSection.content.content.columns;

        const row = tableSection.content.content.rows;
        const headerRow = `
            <tr>
            ${columns.reduce((acc, current) => {
              const { variables: updatedVariables, replaceRequests: replaceRequestsWithinContent } = getVariables({
                content: current.label,
                variables: variables,
                variableTypeMap: variableTypeMap,
              });

              variables = updatedVariables;
              replaceRequests = [...replaceRequests, ...replaceRequestsWithinContent];

              return `${acc} <th class="text-left" >${current.label}</th>`;
            }, '')}
            <tr>
            `;

        if (tableSection.content.content.variable === 'Manual') {
          htmlString += `
            <div class="overflow" >
            <br />
            <br />
              <table>
                <thead>
                  ${headerRow}
                </thead>
                <tbody>
                  ${row.reduce((acc, currentRow) => {
                    return `${acc} <tr>${columns.reduce((acc, currentColumn) => {
                      const { variables: updatedVariables, replaceRequests: replaceRequestsWithinContent } =
                        getVariables({
                          content: currentRow[currentColumn.fieldName] as string,
                          variables: variables,
                          variableTypeMap: variableTypeMap,
                        });

                      variables = updatedVariables;
                      replaceRequests = [...replaceRequests, ...replaceRequestsWithinContent];
                      return `${acc} <td class="text-left" >${currentRow[currentColumn.fieldName]}</td>`;
                    }, '')}</tr>`;
                  }, '')}
  
              </table>
                    ${
                      columns.length > 2
                        ? `
                    <div class="sub-text"> 
                      <i>
                        Note: Table may truncate on small devices. View in browser for full table.
                      </i>
                    </div>
                  `
                        : ''
                    }
                        <br />
                        <br />
              </div>
            `;
          return;
        }

        let operationsRow = '';
        const isOperationPresent = tableSection.content.content.columns.find((column) => column.operation !== 'none');

        if (isOperationPresent) {
          operationsRow += tableSection.content.content.columns
            .map((column) => {
              if (column.operation === 'sum') {
                if (column.fieldName.split('.').length > 1) {
                  const fieldName = column.fieldName.split('.')[1].replaceAll('{{', '').replaceAll('}}', '');
                  return `<td class="text-left" > ${
                    column.operationLabel
                  }: {{ ${tableSection.content.content.variable.toLowerCase()}s${
                    variableOccurenceCount.invoice === 0 ? '' : '_' + variableOccurenceCount.invoice
                  } | sum(attribute='${fieldName}') ${isCurrencyMap[fieldName as keyof typeof isCurrencyMap] ? '| format_currency' : ''} }}
                  </td>`;
                }
                return '<td></td>';
              }

              return '<td></td>';
            })
            .join('');
        }

        htmlString += `
            <div class="overflow"  >
            <br />
            <br />
              <table >
                <thead>
                  ${headerRow}
                </thead>
                <tbody>
                {% for ${tableSection.content.content.variable.toLowerCase()}${
                  variableOccurenceCount.invoice === 0 ? '' : '_' + variableOccurenceCount.invoice
                } in ${tableSection.content.content.variable.toLowerCase()}s${
                  variableOccurenceCount.invoice === 0 ? '' : '_' + variableOccurenceCount.invoice
                } %}
                  ${row.reduce((acc, currentRow) => {
                    return `${acc} <tr>${columns.reduce((acc, currentColumn) => {
                      return `${acc} 
                      <td class="text-left" >
                        ${currentRow[currentColumn.fieldName].replaceAll(
                          'invoice.',
                          `${tableSection.content.content.variable.toLowerCase()}${
                            variableOccurenceCount.invoice === 0 ? '' : '_' + variableOccurenceCount.invoice
                          }.`,
                        )}
                      </td>`;
                    }, '')}</tr>`;
                  }, '')}
                {% endfor %}
               ${
                 operationsRow &&
                 `<tr>
                ${operationsRow}
                </tr>`
               }
                </tbody>
              </table>
                  ${
                    columns.length > 2
                      ? `
                    <div class="sub-text"> 
                      <i>
                        Note: Table may truncate on small devices. View in browser for full table.
                      </i>
                    </div>
                  `
                      : ''
                  }
                       <br />
                       <br />
            </div>
            `;

        variables.push({
          name: `${tableSection.content.content.variable.toLowerCase()}s${
            variableOccurenceCount.invoice === 0 ? '' : '_' + variableOccurenceCount.invoice
          }`,
          type: 'ARRAY',
          elementType: {
            name: 'invoice',
            type: 'OBJECT',
            properties: columns.map((item) => {
              const propertyName = item.fieldName.replaceAll('{{', '').replaceAll('}}', '').split('.')[1];
              return {
                name: propertyName,
                type: templateToJsonVariableMap[
                  variableTypeMap.templateFields['invoice'][
                    propertyName as keyof (typeof variableTypeMap.templateFields)['invoice']
                  ] as 'STRING' | 'NUMBER' | 'DATE'
                ],
                modelType: propertyName,
              };
            }),
            modelType: 'invoice',
          },
          modelType: 'invoice',
        });

        variableOccurenceCount.invoice += 1;
        return;
      }
    }
  });

  // eslint-disable-next-line no-useless-escape
  htmlString = htmlString.replaceAll(/(style=")([a-zA-Z0-9:;\.\s\(\)\-\,]*)(")/gi, '');

  replaceRequests.forEach((replaceRequest) => {
    htmlString = htmlString.replaceAll(replaceRequest.from, replaceRequest.to);
  });

  return {
    htmlString: `<!DOCTYPE html>
      <html
        xmlns="http://www.w3.org/1999/xhtml"
        xmlns:v="urn:schemas-microsoft-com:vml"
        xmlns:o="urn:schemas-microsoft-com:office:office"
      >
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <style type="text/css">
            #outlook a {
              padding: 0;
            }
            body {
              margin: 0;
              padding: 0;
              -webkit-text-size-adjust: 100%;
              -ms-text-size-adjust: 100%;
            }
            table,
            td {
              border-collapse: collapse;
            }
            th {
              background-color: #e0e0e0;
            }
            img {
              border: 0;
              height: auto;
              line-height: 100%;
              outline: none;
              text-decoration: none;
              -ms-interpolation-mode: bicubic;
            }
            p {
              display: block;
              margin: 13px 0;
            }
          </style>
          <style>
            ul,
            ol {
              padding: 0 1rem;
            }
            ul {
              list-style-type: disc;
            }
            ol {
              list-style: decimal;
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              line-height: 1.1;
            }
            code {
              background-color: rgba(#616161, 0.1);
              color: #616161;
            }
            pre {
              background: #0d0d0d;
              color: #fff;
              font-family: "JetBrainsMono", monospace;
              padding: 0.75rem 1rem;
              border-radius: 0.5rem;
              code {
                color: inherit;
                padding: 0;
                background: none;
                font-size: 0.8rem;
              }
            }
            img {
              max-width: 100%;
              height: auto;
            }
            blockquote {
              padding-left: 1rem;
              border-left: 2px solid rgba(#0d0d0d, 0.1);
            }
            hr {
              border: none;
              border-top: 2px solid rgba(#0d0d0d, 0.1);
              margin: 2rem 0;
            }
            table {
              padding-bottom: 12px;
            }
            td {
              padding: 10px;
              border: 0.5px solid black;
            }
            th {
              text-align: left;
              padding: 10px;
              border: 0.5px solid black;
            }
            body {
              overflow: hidden !important;
            }
            .overflow {
              overflow: scroll;
              margin-bottom: 16px;
              margin-top: 16px;
            }
          .template-button {
                display: flex;
                gap: 0.5rem;
                justify-content: center;
                align-items: center;
                border-radius: 0.375rem;
                font-size: 0.875rem;
                line-height: 1.25rem;
                font-weight: 500;
                white-space: nowrap;
                transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 300ms;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
                background:  	#000000;
                color: white;
                height: 2.25rem;
                border: none;
            }
            .template-button a {
              color: white;
              text-decoration: none;
            }
          </style>
          <style type="text/css"></style>
          <style type="text/css">
            #outlook a {
              padding: 0;
            }
            body {
              margin: 0;
              padding: 0;
              -webkit-text-size-adjust: 100%;
              -ms-text-size-adjust: 100%;
            }

            td {
              padding: 4px 10px;
              border: 0.5px solid #616161;
              min-width: 150px;
            }
            th {
              padding: 4px 10px;
              border: 0.5px solid #616161;
              min-width: 150px;
            }
            img {
              border: 0;
              height: auto;
              line-height: 100%;
              outline: none;
              text-decoration: none;
              -ms-interpolation-mode: bicubic;
            }
            p {
              display: block;
              margin: 13px 0;
            }
            .align-center {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .align-left {
              display: flex;
              align-items: center;
              justify-content: start;
            }
            .align-right {
              display: flex;
              align-items: center;
              justify-content: end;
            }

            .text-left {
              text-align: left;
            }

            td p {
              margin: 0px;
              padding: 0px;
            }

            th p {
              margin: 0px;
              padding: 0px;
            }

            table {
              display: block;
              overflow-x: auto;
              margin: 0px;
            }

            .webview-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .web-view-link {
                color: #78716c;
            }
            
            .sub-text {
                font-size: 12px;
                  color: #78716c;
            }

              button p {
                  margin: 0px;
                  padding: 0px;
              }
          </style>
        </head>
        <body>
          {% if mail_access_token %}
            <div align="center" id="view-web-version" width="100%" style="width: 100%;" border="0" cellspacing="0" cellpadding="0">
              <a class="web-view-link" target="_blank" id="view-web-version" href="${import.meta.env.VITE_WEBSITE_DOMAIN}/archive/mail?token={{mail_access_token}}">View in browser</a> 
            </div>
          {% endif %}
          ${addCurrencyFormatting(htmlString, currencyVariables)}
        </body>
      </html>
      `,
    variableSet: variables,
  };
};
