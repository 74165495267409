import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import Empty from '@/components/ui/empty';
import Heading from '@/components/ui/heading';
import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useToast } from '@/components/ui/use-toast';
import {
  useDeleteSegmentById,
  useEvaluateSegmentQuery,
  useGetCustomerCountForSegments,
  useGetSegments,
} from '@/hooks/api-hooks/useSegmentQuery';
import { ISegment } from '@/types/segment.types';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

const DeleteSegmentDialog = ({
  segmentId,
  isDeleteDialogOpen,
  setIsDeleteDialogOpen,
}: {
  segmentId: string;
  isDeleteDialogOpen: boolean;
  setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { toast } = useToast();

  const { mutate: deleteSegment } = useDeleteSegmentById({
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to create term. Please try again.',
        });
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
      },
    },
  });

  return (
    isDeleteDialogOpen && (
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
          <DialogHeader className=" font-semibold ">Delete segment</DialogHeader>
          <p className="text-sm">Are you sure you want to delete this segment?</p>
          <DialogFooter>
            <Button onClick={() => setIsDeleteDialogOpen(false)} variant="outline">
              Cancel
            </Button>
            <Button
              onClick={() =>
                deleteSegment({
                  segmentId,
                })
              }
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  );
};

const ActionsCell = ({ id }: { id: string }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { toast } = useToast();

  const { mutate: evaluateSegment } = useEvaluateSegmentQuery({
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to evaluate segment',
        });
      },
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'Segment evaluated successfully',
        });
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
      },
    },
  });

  const handleEvaluteSegmentClick = () => {
    evaluateSegment({ segmentId: id });
  };

  return (
    <>
      <DeleteSegmentDialog
        segmentId={id}
        isDeleteDialogOpen={isDeleteDialogOpen}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <DotsHorizontalIcon className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setIsDeleteDialogOpen(true)}>Delete</DropdownMenuItem>
          <DropdownMenuItem onClick={handleEvaluteSegmentClick}>Refresh</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

interface ISegmentTable<TData> {
  data: TData[];
}

const CustomerCountCell = ({ segmentId }: { segmentId: string }) => {
  const { data: customerCount, isLoading } = useGetCustomerCountForSegments();

  if (isLoading) {
    return <Skeleton className="h-6 w-20" />;
  }

  if (!customerCount) {
    return 0;
  }

  return customerCount?.data.customerCounts[segmentId] || 0;
};

const SegmentsTable = ({ data }: ISegmentTable<ISegment>) => {
  const segmentColumns: ColumnDef<ISegment>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        id: 'name',
        header: 'Name',
        cell: ({ getValue, row }) => (
          <Link to={`/segments/view/${row.original.id}`} className=" underline text-blue-700 ">
            {getValue() as string}
          </Link>
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description',
        cell: ({ getValue }) => getValue() as string,
        size: 600,
        minSize: 600,
      },
      {
        accessorKey: 'customerCount',
        header: 'Customer Count',
        cell: ({ row }) => <CustomerCountCell segmentId={row.original.id} />,
      },
      {
        accessorKey: 'status',
        header: 'Actions',
        cell: (info) => {
          return <ActionsCell id={info.row.original.id} />;
        },
      },
    ],
    [],
  );

  const tableData = useMemo(() => {
    return data;
  }, [data]);

  const table = useReactTable({
    data: tableData,
    columns: segmentColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  return (
    <div>
      <Table>
        <TableHeader className=" bg-gray-100 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead className="px-6" key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell className="px-6" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={segmentColumns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const Segments = () => {
  const { data, isLoading } = useGetSegments({});

  if (isLoading) {
    return (
      <div>
        <Table>
          <TableHeader>
            <TableRow>
              {new Array(3).fill(0).map((_, i) => (
                <TableHead className="px-6" key={i + 'segment_header_rows'}>
                  <Skeleton className=" w-36  h-8" />
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {new Array(5).fill(0).map((_, i) => (
              <TableRow key={i + 'segment_table_rows'}>
                {new Array(3).fill(0).map((_, i) => (
                  <TableCell className="px-6" key={i + 'segment_body_rows'}>
                    <Skeleton className="w-36 h-8" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  if (!data?.data) {
    return <Empty title="No segments found" />;
  }

  return <SegmentsTable data={data.data.segments} />;
};

const SegmentsListPage = () => {
  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8">
      <Heading title="Segments" description="List of segments" />
      <Segments />
    </div>
  );
};

export default SegmentsListPage;
