import CashflowFilters from '@/components/cashflow/details/CashflowDetailsFilters';
import CashFlowDetailsView from '@/components/cashflow/details/CashflowDetailsView';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const CashFlowDetailsPage = () => {
  const year = useMemo(() => new Date().getFullYear(), []);
  const month = useMemo(() => new Date().getMonth(), []);

  const initialDateRange = useMemo(() => {
    return {
      startDate: new Date(year, month, 1),
      endDate: new Date(month === 11 ? year + 1 : year, (month + 1) % 12, 0),
    };
  }, [year, month]);

  const [dateFilter, setDateFilter] = useState<{
    startDate: Date;
    endDate: Date;
  }>(initialDateRange);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('startDate') && !searchParams.get('endDate')) {
      setSearchParams(
        {
          startDate: dayjs(initialDateRange.startDate).format('YYYY-MM-DD'),
          endDate: dayjs(initialDateRange.endDate).format('YYYY-MM-DD'),
        },
        {
          replace: true,
        },
      );
    }
  }, [initialDateRange, setSearchParams, searchParams]);

  const handleSubmit = useCallback(
    (value: { startDate: Date; endDate: Date }) => {
      setDateFilter(value);
    },
    [setDateFilter],
  );

  return (
    <div className="md:px-16 sm:px-8 px-4 flex-1 my-8">
      <CashflowFilters initialDateRange={initialDateRange} handleSubmit={handleSubmit} />
      <CashFlowDetailsView dateFilter={dateFilter} />
    </div>
  );
};

export default CashFlowDetailsPage;
