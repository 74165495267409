import ToolTipCell from '@/components/Table/ToolTipCell';
import { Skeleton } from '@/components/ui/skeleton';
import { useGetConditionById } from '@/hooks/api-hooks/useWorkflowQuery';
import { cn } from '@/lib/utils';
import { useWorkflowPropertiesStore } from '@/stores/workflow/state-properties.store';
import {
  ArithmeticOperators,
  ComparisonOperators,
  DateOperators,
  IConditionRule,
  IStatementCondition,
  StateTypes,
  StringOperators,
} from '@/types/workflow.type';
import { invoiceValueLabelMap } from '@/utils/type-map';
import dayjs from 'dayjs';
import { AlertTriangleIcon } from 'lucide-react';
import { Fragment, useMemo } from 'react';

const operatorToLabel: Record<
  DateOperators | ArithmeticOperators | ComparisonOperators | StringOperators | 'none',
  string
> = {
  '!=': 'Not equal to',
  '*': 'multiplied by',
  '+': 'plus',
  '-': 'minus',
  '/': 'divided by',
  '<': 'less than',
  '<=': 'less than equal to',
  '==': 'is equal to',
  '>': 'greater than',
  '>=': 'greater than equal to',
  days: 'days',
  months: 'months',
  weeks: 'weeks',
  years: 'years',
  none: '',
  STARTS_WITH: 'starts with',
  ENDS_WITH: 'ends with',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'not contains',
  NOT_ENDS_WITH: 'not ends with',
  NOT_STARTS_WITH: 'not starts with',
};

const Statement = ({ statement, nodeType }: { statement?: IStatementCondition; nodeType: StateTypes }) => {
  const lhs = useMemo(() => {
    if (statement?.lhs?.operator) {
      return statement?.lhs.leftStatement?.valuePlaceholder;
    }

    return statement?.lhs?.valuePlaceholder;
  }, [statement?.lhs]);

  const rhs = useMemo(() => {
    if (statement?.rhs?.operator) {
      return statement?.rhs.leftStatement?.valuePlaceholder;
    }

    if (statement?.rhs?.valuePlaceholder) {
      return statement?.rhs?.valuePlaceholder;
    }

    return statement?.rhs?.value;
  }, [statement?.rhs]);

  const dateOperator = useMemo(() => {
    if (statement?.lhs?.operator) {
      return '-';
    }

    if (statement?.rhs?.operator) {
      return '+';
    }

    return '';
  }, [statement]);

  const dateConditionString = useMemo(() => {
    if (statement?.lhs?.operator) {
      return `${statement?.lhs?.rightStatement?.value} day${(statement?.lhs?.rightStatement?.value as number) > 1 ? 's' : ''}`;
    }

    if (statement?.rhs?.operator) {
      return `${statement?.rhs?.rightStatement?.value} day${(statement?.rhs?.rightStatement?.value as number) > 1 ? 's' : ''}`;
    }

    return '';
  }, [statement]);

  const isDateCondition = useMemo(() => {
    if (statement?.lhs?.operator) {
      return true;
    }

    if (statement?.rhs?.operator) {
      return true;
    }

    return false;
  }, [statement]);

  const operator = statement?.operator || 'none';

  const relativeDateString = useMemo(() => {
    if (statement?.lhs?.operator) {
      return `${statement.lhs.rightStatement?.value} days ago`;
    }

    if (statement?.rhs?.operator) {
      return `${statement.rhs.rightStatement?.value} days later`;
    }

    return '';
  }, [statement]);

  const dateValue = useMemo(() => {
    if (statement?.lhs?.operator) {
      return dayjs()
        .subtract(statement?.lhs?.rightStatement?.value as number, 'days')
        .format('DD MMM YYYY');
    }

    if (statement?.rhs?.operator) {
      return dayjs()
        .add(statement?.rhs?.rightStatement?.value as number, 'days')
        .format('DD MMM YYYY');
    }

    return '';
  }, [statement]);

  if (isDateCondition && nodeType === 'CONDITIONAL') {
    if (dateOperator === '+') {
      return `${
        invoiceValueLabelMap[lhs?.split('.')[1] as keyof typeof invoiceValueLabelMap]
      } - current date is ${operatorToLabel[operator]} ${dateConditionString} `
        .split(' ')
        .filter((item) => !!item.trim())
        .join(' ');
    }

    return `current date - ${
      invoiceValueLabelMap[lhs?.split('.')[1] as keyof typeof invoiceValueLabelMap]
    } is ${operatorToLabel[operator]} ${dateConditionString} `
      .split(' ')
      .filter((item) => !!item.trim())
      .join(' ');
  }

  return `${invoiceValueLabelMap[lhs?.split('.')[1] as keyof typeof invoiceValueLabelMap]} ${
    operatorToLabel[operator]
  } ${dateValue} ${rhs === 'current_date' ? (relativeDateString.trim() ? '' : 'current date') : rhs} ${relativeDateString ? `(${relativeDateString})` : ''}`
    .split(' ')
    .filter((item) => !!item.trim())
    .join(' ');
};

const ConditionItem = ({
  condition,
  showBrackets = false,
  nodeType,
}: {
  condition: IConditionRule | IStatementCondition;
  showBrackets?: boolean;
  nodeType: StateTypes;
}) => {
  const operatorMap = {
    AND_TYPE: <span className=" font-semibold px-2 ">AND</span>,
    OR_TYPE: <span className=" font-semibold px-2 ">OR</span>,
  };

  if (condition.type === 'AND_TYPE' || condition.type === 'OR_TYPE') {
    return (
      <>
        {showBrackets && '('}
        {condition.childConditions.map((childCondition, index) => (
          <Fragment key={String(index) + 'condition-item'}>
            {childCondition.type === 'STATEMENT_TYPE' ? (
              <Statement nodeType={nodeType} statement={childCondition} />
            ) : (
              <ConditionItem nodeType={nodeType} showBrackets={true} condition={childCondition} />
            )}
            {index === condition.childConditions.length - 1 ? '' : operatorMap[condition.type]}
          </Fragment>
        ))}
        {showBrackets && ')'}
      </>
    );
  }

  return <Statement nodeType={nodeType} statement={condition as IStatementCondition} />;
};

const DisplayCondition = ({
  nodeId,
  nodeType,
  conditionId,
  catIndex,
}: {
  conditionId: string;
  nodeId: string;
  nodeType: StateTypes;
  catIndex: number;
}) => {
  const { setSelectedProperties, selectedProperties } = useWorkflowPropertiesStore();

  const { data: conditionResponse, isLoading } = useGetConditionById({
    conditionId,
    customConfig: {
      enabled: !!conditionId,
    },
  });

  const handleSelectClick = () => {
    setSelectedProperties({
      nodeId,
      type: nodeType,
      updateType: 'condition',
      catIndex,
      conditionId,
    });
  };

  const condition = useMemo(() => {
    if (!conditionResponse) {
      return null;
    }

    return conditionResponse.data;
  }, [conditionResponse]);

  const isConditionPresent = useMemo(() => {
    return (
      ((condition?.rule.type === 'AND_TYPE' || condition?.rule.type === 'OR_TYPE') &&
        condition.rule.childConditions.length) ||
      (condition?.rule.type === 'STATEMENT_TYPE' && condition.rule.lhs && condition.rule.operator && condition.rule.rhs)
    );
  }, [condition]);

  if (isLoading) {
    return (
      <div>
        <Skeleton className="w-28 h-8" />
      </div>
    );
  }

  if (!condition || !isConditionPresent) {
    return (
      <div
        onClick={handleSelectClick}
        className={cn(
          ' text-yellow-500 font-semibold  ',
          selectedProperties?.conditionId === conditionId &&
            selectedProperties?.updateType === 'condition' &&
            selectedProperties.nodeId === nodeId &&
            selectedProperties.catIndex === catIndex &&
            'ring-1',
          'hover:ring-1 w-full cursor-pointer',
        )}
      >
        <ToolTipCell
          additionalClasses=" absolute -left-11 text-destructive "
          value={<AlertTriangleIcon className=" w-4 h-4 " />}
        >
          <div className=" flex flex-col gap-1 ">1. No condition added</div>
        </ToolTipCell>
        Set conditions
      </div>
    );
  }

  return (
    <div
      className={cn(
        selectedProperties?.conditionId === conditionId && selectedProperties?.updateType === 'condition' && 'ring-1',
        'hover:ring-1 w-full cursor-pointer',
      )}
      onClick={handleSelectClick}
    >
      {!!conditionResponse?.warnings?.length && (
        <ToolTipCell
          additionalClasses=" absolute -left-11 text-destructive "
          value={<AlertTriangleIcon className=" w-4 h-4 " />}
        >
          <div className=" flex flex-col gap-1 ">
            {conditionResponse?.warnings?.map((w, index) => (
              <div key={w}>
                {index + 1}. {w}
              </div>
            ))}
          </div>
        </ToolTipCell>
      )}
      <ConditionItem
        nodeType={nodeType}
        key={conditionId}
        condition={condition.rule as IStatementCondition | IConditionRule}
      />
    </div>
  );
};

export default DisplayCondition;
