import { Skeleton } from '@/components/ui/skeleton';
import { useGetActionById } from '@/hooks/api-hooks/useWorkflowQuery';

import { Button } from '@/components/ui/button';
import PropertiesLayout from '../Layout';
import EmailProperties from './EmailProperties';
import { GotoActionProperties } from './GotoActionProperties';

export const ActionPropertiesSlot = ({ actionId }: { actionId: string }) => {
  const { data: actionResponse, isLoading } = useGetActionById({
    actionId,
    customConfig: {
      enabled: !!actionId,
    },
  });

  if (isLoading) {
    return (
      <PropertiesLayout>
        <div className="flex-1">
          <div className=" flex flex-col gap-2 py-4 border-b ">
            <Skeleton className="w-28 h-6" />
            <Skeleton className="w-full h-8" />
          </div>
          <div className=" py-4 flex flex-col gap-4 ">
            <div className=" flex flex-col gap-2">
              <Skeleton className="w-28 h-6" />
              <Skeleton className="w-full h-8" />
            </div>
            <div className=" flex flex-col gap-2">
              <Skeleton className="w-28 h-6" />
              <Skeleton className="w-full h-8" />
            </div>
            <div className=" flex flex-col gap-2">
              <Skeleton className="w-28 h-6" />
              <Skeleton className="w-full h-8" />
            </div>
          </div>
        </div>
        <div className="  border-t items-center justify-end flex py-4 ">
          <div>
            <Button disabled>Save</Button>
          </div>
        </div>
      </PropertiesLayout>
    );
  }

  if (actionResponse?.data.type === 'EMAIL_ACTION') {
    return <EmailProperties actionId={actionId} />;
  }

  return <GotoActionProperties actionId={actionId} />;
};
