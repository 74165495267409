import { dataAxiosInstance } from '@/lib/axios';
import { mockApiReturn } from './utils';

export const getPlansByCompanyId = async () => {
  const result = await dataAxiosInstance.get('/plan');
  return result.data;
};

export const finalizePlan = async ({ planId }: { planId: string }) => {
  return await mockApiReturn('', 1000, planId === 'plan_212312w12');
};

export const createPlan = async ({
  companyId,
  name,
  financialYear,
}: {
  companyId: string;
  name: string;
  financialYear: number;
}) => {
  const result = await dataAxiosInstance.post(`/plan/financialYear`, {
    companyId,
    name,
    financialYear,
  });

  return result.data;
};

export const getActivePlan = async ({ financialYear }: { financialYear: number }) => {
  const result = await dataAxiosInstance.get(`/plan/active?financialYear=${financialYear}`);
  return result.data;
};
