import { PreviewVariable } from '@/components/email-builder/PreviewTemplate';
import { dataAxiosInstance } from '@/lib/axios';
import { ICreateEmailTemplate, IUpdateEmailTemplate } from '@/types/email-templates.types';

export const getEmailTemplates = async ({ page, pageSize }: { page: number; pageSize: number }) => {
  const result = await dataAxiosInstance.get(`/emailTemplates?page=${page}&pageSize=${pageSize}`);

  return result.data;
};

export const getEmailTemplateById = async ({ templateId }: { templateId: string }) => {
  const result = await dataAxiosInstance.get(`/emailTemplates/${templateId}`);

  return result.data;
};

export const postCreateTemplate = async ({
  contentHtml,
  contentJson,
  description,
  subjectContentHtml,
  name,
  config,
  status,
}: ICreateEmailTemplate) => {
  const result = await dataAxiosInstance.post('/emailTemplates', {
    contentHtml,
    contentJson,
    description,
    subjectContentHtml,
    name,
    config,
    status,
  });

  return result.data;
};

export const postUpdateEmailTemplate = async ({
  contentHtml,
  contentJson,
  description,
  subjectContentHtml,
  name,
  config,
  templateId,
  status,
}: IUpdateEmailTemplate) => {
  const result = await dataAxiosInstance.post(`/emailTemplates/update/${templateId}`, {
    updates: {
      fields: ['name', 'description', 'contentHtml', 'subjectContentHtml', 'contentJson', 'config', 'status'],
      values: {
        name,
        description,
        contentHtml,
        subjectContentHtml,
        contentJson,
        config,
        status,
      },
    },
  });

  return result.data;
};

export const deleteTemplate = async ({ templateId }: { templateId: string }) => {
  const result = await dataAxiosInstance.delete(`/emailTemplates/${templateId}/delete`);
  return result.data;
};

export const postGetTemplatePreview = async ({
  templateId,
  variables,
}: {
  templateId: string;
  variables: PreviewVariable[];
}) => {
  const result = await dataAxiosInstance.post(`/emailTemplates/${templateId}/preview`, {
    variables: variables.map((item) => ({
      name: item.name,
      modelType: item.modelType,
      identifiers: item.name.includes('invoices')
        ? item.identifiers
            .split(',')
            .map((identifier) => identifier.trim())
            .filter((item) => !!item)
        : item.identifiers,
    })),
  });
  return result.data;
};

export const getEmailTemplateSuggestions = async (nameContains: string) => {
  const result = await dataAxiosInstance.get(`/emailTemplates/suggestions?nameContains=${nameContains}`);
  return result.data;
};

export const getEmailTemplateVariables = async () => {
  const result = await dataAxiosInstance.get('/emailTemplates/placeholders');

  return result.data;
};
