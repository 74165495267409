import { dataAxiosInstance } from '@/lib/axios';

export const getBudget = async ({ scope, planId }: { scope: 'monthly' | 'yearly'; planId: string }) => {
  if (scope === 'yearly') {
    const result = await dataAxiosInstance.get(`/budget/plan?planId=${planId}&frequency=financialYear`);
    return result.data;
  } else {
    const result = await dataAxiosInstance.get(`/budget/plan?planId=${planId}&frequency=month`);
    return result.data;
  }
};

export const updateBudget = async ({ budgetId, budgetValue }: { budgetId: string; budgetValue: number }) => {
  const result = await dataAxiosInstance.post(`/budget/category`, {
    categoryBudgetId: budgetId,
    value: budgetValue,
  });

  return result.data;
};
