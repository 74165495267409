import dayjs from 'dayjs';
import { InfoIcon } from 'lucide-react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';

const months = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'];
const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

const TableHeading = ({
  scope,
  showReceivables,
  financialYear,
}: {
  scope: 'monthly' | 'quarterly' | 'yearly';
  showReceivables?: boolean;
  financialYear: number;
}) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const cellToScroll = searchParams.get('month');

    if (cellToScroll) {
      const cell = document.querySelector(`[data-id="${cellToScroll}_plan_cell"]`);
      if (cell) {
        cell.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    }
  }, [searchParams]);

  return (
    <div className="flex gap-2 ">
      <div className="flex border-r-2 border-black absolute z-50 left-0 bg-gray-100">
        <div className="min-w-[340px] pl-6 border-b py-2 ">Category</div>
      </div>
      <div className="flex border-b bg-gray-100">
        {scope === 'monthly' &&
          months.map((month, index) => (
            <div
              data-id={`${month}-${
                index < 9
                  ? dayjs(new Date(financialYear - 1, index)).format('YY')
                  : +dayjs(new Date(financialYear, index)).format('YY')
              }_plan_cell`}
              className="min-w-[180px] border-r text-center py-2"
              key={month}
            >
              {month}{' '}
              {index < 9
                ? dayjs(new Date(financialYear - 1, index)).format('YY')
                : +dayjs(new Date(financialYear, index)).format('YY')}
            </div>
          ))}
        {scope === 'monthly' && (
          <div className="min-w-[180px] gap-1 text-center border-r py-2 flex justify-center items-center">
            <span className=" text-green-600 ">Allocate</span>{' '}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <InfoIcon className="w-3.5 relative top-[1px] h-3.5 cursor-pointer text-muted-foreground " />
                </TooltipTrigger>
                <TooltipContent className=" w-60 ">
                  <p>
                    Allocate implies the current financial year monthly plan for the category need to be adjusted
                    upwards to match the plan value for the yearly plan
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            {'  '}/ <span className="text-red-400">Reduce</span>{' '}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <InfoIcon className="w-3.5 relative top-[1px] h-3.5 cursor-pointer text-muted-foreground " />
                </TooltipTrigger>
                <TooltipContent className=" w-60">
                  <p>
                    Reduce implies the current financial year monthly plan for the category need to be adjusted
                    downwards to match the plan value for the yearly plan
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
        {scope === 'quarterly' &&
          quarters.map((quarter) => (
            <div className="min-w-[180px] text-center border-r py-2" key={quarter}>
              {quarter} {dayjs().format('YY')}
            </div>
          ))}
        {scope === 'yearly' && (
          <div className="min-w-[180px] text-center border-r py-2">
            FY {dayjs(new Date(financialYear, 0)).format('YYYY')}
          </div>
        )}
        {showReceivables && (
          <div className="min-w-[180px] justify-center border-r py-2 flex items-center gap-2">
            Receivable Days
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <InfoIcon className="w-3.5 relative top-[1px] h-3.5 cursor-pointer text-muted-foreground " />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Average days required to collect payment from the invoice issue date</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableHeading;
