'use client';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { IOptions } from '@/types/common.types';
import { IPlaceholder } from '@/types/posting.types';
import { ChevronDownIcon } from 'lucide-react';
import { useMemo, useState } from 'react';

export function PostAsDropDown({
  handleAddPlaceholder, // handleAddPlaceholder function passed as prop
  creditPlaceholders,
  className = '',
}: {
  handleAddPlaceholder: (_?: IPlaceholder) => void;
  creditPlaceholders?: IPlaceholder[];
  className?: string;
}) {
  const [open, setOpen] = useState(false);

  const handleSelect = (option: IOptions) => {
    handleAddPlaceholder(creditPlaceholders?.find((p) => p.id === option?.value));
    setOpen(false);
  };

  const options = useMemo<IOptions[]>(() => {
    // Map placeholder data into options if available
    return (
      creditPlaceholders?.map((placeholder) => ({
        label: placeholder.name,
        value: placeholder.id,
      })) || []
    );
  }, [creditPlaceholders]);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button className={`flex items-center gap-4 text-left ${className}`} variant="outline">
          {'Post As'} {/* Display selected option label or default text */}
          <ChevronDownIcon className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        onInteractOutside={() => setOpen(false)}
        onEscapeKeyDown={() => setOpen(false)}
        className="w-56"
      >
        <DropdownMenuLabel>Post As</DropdownMenuLabel>
        <DropdownMenuSeparator />

        {options.length > 0 &&
          options.map((option) => (
            <DropdownMenuItem
              className="normal-case"
              key={option.value}
              onClick={() => handleSelect(option)} // Pass option to the handler
            >
              {option.label}
            </DropdownMenuItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
