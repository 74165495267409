import { PreviewVariable } from '@/components/email-builder/PreviewTemplate';
import { create } from 'zustand';

type State = {
  subject: string;
  body: string;
  loadData: (_: { subject: string; body: string; variables: PreviewVariable[] }) => void;
  reset: () => void;
  variables: PreviewVariable[];
};

export const usePreviewStore = create<State>((set) => ({
  subject: '',
  body: '',
  variables: [],
  loadData(data) {
    set({ subject: data.subject, body: data.body, variables: data.variables });
  },

  reset() {
    set({ subject: '', body: '' });
  },
}));
