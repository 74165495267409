'use client';

import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons';
import * as React from 'react';

import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useUserContext } from '@/contexts/UserContext';
import { useSwitchCompany } from '@/hooks/api-hooks/useAuth';
import { cn, ifDataTypeNotUndefined } from '@/lib/utils';
import { Company } from '@/types/company.types';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getFirstNWords } from '../../utils/stringFormatterUtil';

export function CompanySelectBox({
  handleEditOpen,
  defaultOpen = false,
}: {
  handleEditOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  defaultOpen?: boolean;
}) {
  const [open, setOpen] = React.useState(defaultOpen);
  const [inputSearch, setInputSearch] = React.useState<string>('');
  const { companiesOfUser, activeCompanyIndex, setactiveCompanyIndex } = useUserContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();


  const { mutate: switchCompany } = useSwitchCompany({
    customConfig: {
      onSuccess: () => {
        queryClient.resetQueries();
        navigate('/cashflow/summary');
      },
    },
  });

  const handleSelect = (company: Company, selectedIndex: number) => () => {
    if (selectedIndex === activeCompanyIndex) {
      return;
    }
    setactiveCompanyIndex(selectedIndex);
    setOpen(false);
    switchCompany({ currentCompanyId: company.id });
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      handleEditOpen?.(false);
    }
  };
  //filteration of companies based on what user has input in searchh
  const filteredCompaniesOfUser = companiesOfUser.filter((element) => {
    return element.name.toLowerCase().includes(inputSearch.toLowerCase());
  });
  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="w-[220px] justify-between">
          {ifDataTypeNotUndefined(companiesOfUser) && companiesOfUser[activeCompanyIndex]?.name
            ? getFirstNWords(companiesOfUser[activeCompanyIndex]?.name, 2)
            : 'Select Company...'}
          {companiesOfUser.length > 1 && <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />}
        </Button>
      </PopoverTrigger>
      {companiesOfUser && companiesOfUser.length > 1 && (
        <PopoverContent className="w-[250px]  p-0">
          <Command>
            <CommandInput
              value={inputSearch}
              onValueChange={setInputSearch}
              placeholder="Search Company..."
              className="h-9"
            />
            <CommandEmpty>No Company found.</CommandEmpty>
            <CommandGroup className="h-[300px] overflow-y-scroll">
              {filteredCompaniesOfUser.map((company, index) => (
                <CommandItem key={company.id} value={company.name} onSelect={handleSelect(company, index)}>
                  <div className="flex items-center gap-2">
                    <CheckIcon className={cn('h-4 w-4', activeCompanyIndex === index ? 'opacity-100' : 'opacity-0')} />
                    {company.name}
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      )}
    </Popover>
  );
}
