import { ICustomerFilters } from '@/types/customer.types';
import { ISegmentSearchRules } from '@/types/segment.types';
import dayjs from 'dayjs';

export const convertCustomerFiltersToSegmentSearchQuery = (filters: ICustomerFilters): ISegmentSearchRules => {
  let query: ISegmentSearchRules = {
    companyId: '',
  };

  if (filters.dateType && filters.dateRange?.startDate && filters.dateRange?.endDate) {
    query = {
      ...query,
      dateFilter: {
        dateType: filters.dateType,
        startDate: dayjs(filters.dateRange?.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(filters.dateRange?.endDate).format('YYYY-MM-DD'),
      },
    };
  }

  if (
    filters.amountType &&
    filters.maxAmount !== undefined &&
    filters.maxAmount !== null &&
    filters.minAmount !== null &&
    filters.minAmount !== undefined
  ) {
    query = {
      ...query,
      amountFilter: {
        amountType: filters.amountType,
        minAmount: filters.minAmount,
        maxAmount: filters.maxAmount,
      },
    };
  }

  if (filters.invoiceStatus?.length) {
    query = {
      ...query,
      invoiceStatus: filters.invoiceStatus,
    };
  }

  if (filters.identifierType && filters.identifierValue) {
    query = {
      ...query,
      customerFilter: {
        identifierType: filters.identifierType,
        identifierValue: filters.identifierValue,
      },
    };
  }

  if (filters.tags?.length) {
    query = {
      ...query,
      tags: filters.tags.reduce<{ [key: string]: string[] }>((acc, tag) => {
        return {
          ...acc,
          [tag.tagName]: acc[tag.tagName] ? [...acc[tag.tagName], tag.tagValue] : [tag.tagValue],
        };
      }, {}),
    };
  }

  if (filters.paymentStatus?.length) {
    query = {
      ...query,
      paymentStatus: filters.paymentStatus,
    };
  }

  if (filters.ownerKeyType === 'role' && filters.ownerValue) {
    query = {
      ...query,
      ownerRole: filters.ownerValue,
    };
  }

  if (filters.ownerKeyType === 'name-email' && filters.ownerValue) {
    query = {
      ...query,
      ownerId: filters.ownerValue,
    };
  }

  return query;
};
