export const formatCurrencyByUnit = (
  value: number,
  unit: 'k' | 'l' | 'cr' | 'actual',
  currencyType = 'INR',
  abs = true,
) => {
  let adjustedValue = value;
  if (unit === 'k') {
    adjustedValue = value / 1000;
  } else if (unit === 'l') {
    adjustedValue = value / 100000;
  } else if (unit === 'cr') {
    adjustedValue = value / 10000000;
  }

  if (!abs) {
    return `${adjustedValue.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: currencyType,
    })}${unit === 'actual' ? '' : unit != 'cr' ? ` ${unit.toUpperCase()}` : ' Cr'}`;
  }

  return `${Math.abs(adjustedValue).toLocaleString('en-IN', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: currencyType,
  })}${unit === 'actual' ? '' : unit != 'cr' ? ` ${unit.toUpperCase()}` : ' Cr'}`;
};
