import Pagination from '@/components/shared/Pagination';
import TableSkeleton from '@/components/shared/TableSkeleton';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useToast } from '@/components/ui/use-toast';
import {
  useDeleteWorkflow,
  useDryRunWorkflow,
  useGetWorkflowsQuery,
  usePublishWorkflow,
  useTogglePauseWorkflow,
} from '@/hooks/api-hooks/useWorkflowQuery';
import { IWorkflowRow } from '@/types/workflow.type';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, Row, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Loader2Icon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import RunWorkflowDialog from '../components/RunWorkflowModal';

const WorkflowTableActions = ({ row }: { row: Row<IWorkflowRow> }) => {
  const { toast } = useToast();
  const [isRunWorkflowDialogOpen, setIsRunWorkflowDialogOpen] = useState(false);

  const { mutate: deleteWorkflow, isPending } = useDeleteWorkflow({
    workflowId: row.original.id,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message,
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: dryRunWorkflow, isPending: isDryRunWorklflowPending } = useDryRunWorkflow({
    workflowId: row.original.id,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message ?? 'Error occured in testing the workflow',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: togglePauseWorkflow, isPending: isTogglePauseWorklflowPending } = useTogglePauseWorkflow({
    workflowId: row.original.id,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message,
          variant: 'destructive',
        });
      },
      onSuccess() {
        toast({
          description: 'Workflow paused successfully',
        });
      },
    },
  });
  const { mutate: publish, isPending: isPublishPending } = usePublishWorkflow({
    workflowId: row.original.id as string,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Failed to publish workflow',
          variant: 'destructive',
        });
      },
      onSuccess() {
        toast({
          description: 'Workflow unpaused successfully',
        });
      },
    },
  });

  const toggleWorkflow = () => {
    if (row.original.status == 'ACTIVE') {
      togglePauseWorkflow();
    } else {
      publish();
    }
  };

  return (
    <div>
      <DropdownMenu>
        <RunWorkflowDialog
          isRunWorkflowDialogOpen={isRunWorkflowDialogOpen}
          setIsRunWorkflowDialogOpen={setIsRunWorkflowDialogOpen}
          workflowId={row.original.id}
        />
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <DotsHorizontalIcon className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem
            onClick={() => setIsRunWorkflowDialogOpen(true)}
            className=" flex items-center gap-2 "
            disabled={row.original.status.includes('DRAFT')}
          >
            Run Now
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => dryRunWorkflow()} className=" flex items-center gap-2 ">
            Test
            {isDryRunWorklflowPending && <Loader2Icon className="w-3 h-3" />}
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => toggleWorkflow()}
            className=" flex items-center gap-2 "
            disabled={row.original.status.includes('DRAFT')}
          >
            {row.original.status == 'PAUSED' ? 'Unpause' : 'Pause'}
            {isTogglePauseWorklflowPending || (isPublishPending && <Loader2Icon className="w-3 h-3" />)}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => deleteWorkflow()} className=" flex items-center gap-2 ">
            Delete
            {isPending && <Loader2Icon className="w-3 h-3" />}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

const WorkflowsTable = ({ data }: { data: IWorkflowRow[] }) => {
  const workflowColumns: ColumnDef<IWorkflowRow>[] = useMemo<ColumnDef<IWorkflowRow>[]>(
    () => [
      {
        accessorKey: 'name',
        id: 'name',
        header: 'Name',
        cell: ({ row, getValue }) => (
          <Link to={`/workflow/update/define/${row.original.id}`} className=" underline text-blue-700 ">
            {getValue() as string}
          </Link>
        ),
      },
      {
        accessorKey: 'workflowDescription',
        header: 'Description',
        cell: ({ getValue }) => getValue() as string,
        size: 600,
        minSize: 600,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: ({ getValue }) => (
          <div className="w-[100px]">
            <Badge className=" capitalize ">{(getValue() as string).toLowerCase()}</Badge>
          </div>
        ),
      },
      {
        accessorKey: 'lastUpdated',
        header: 'Last updated',
        cell: ({ getValue }) =>
          dayjs(getValue() as string)
            .add(330, 'minute')
            .format('DD MMM YY, HH:mm'),
      },
      {
        accessorKey: 'dryRun',
        header: 'Test Run',
        cell: ({ row }) =>
          row.original.dryRun?.name ? (
            <div
              className="cursor-pointer  text-blue-700"
              onClick={() => {
                window.open(`/workflow/dry-run/${row.original.id}`), '_blank';
              }}
            >
              <p className="underline"> {row.original.dryRun?.name}</p>
              <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
                On {dayjs(row.original.dryRun.initiatedAt).add(330, 'minute').format('DD MMM YY, HH:mm') ?? ''}
              </div>
            </div>
          ) : (
            ''
          ),
      },
      {
        header: 'Actions',
        cell: ({ row }) => {
          return <WorkflowTableActions row={row} />;
        },
      },
    ],
    [],
  );

  const tableData = useMemo(() => {
    return data;
  }, [data]);

  const table = useReactTable({
    data: tableData,
    columns: workflowColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  return (
    <div>
      <Table>
        <TableHeader className=" bg-gray-100 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead className="px-6" key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell className="px-6" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={workflowColumns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const WorkflowList = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data: workflowsResponse, isLoading } = useGetWorkflowsQuery({
    page: page,
    limit: rowsPerPage,
  });

  if (isLoading) {
    return <TableSkeleton columns={3} rows={10} />;
  }

  return (
    <>
      <WorkflowsTable data={workflowsResponse?.data.docs || []} />
      <Pagination
        hasNext={!!workflowsResponse?.data.hasNext}
        hasPrev={!!workflowsResponse?.data.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setRowsPerPage}
        pageNumber={page}
        rowsPerPage={rowsPerPage}
        totalPages={workflowsResponse?.data.totalPages || 0}
      />
    </>
  );
};

export default WorkflowList;
