import { Card, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import Heading from '@/components/ui/heading';
import { useMinGuaranteeTermContext, usePaymentTermContext } from '@/contexts/CreditTermContext';
import { CreditTermType, ICreditTerms } from '@/types/credit-term.types';
import { getAllMinGuranteeTemplates, getMinGuaranteeTemplateById } from '@/utils/getMinGuaranteeTermTemplates';
import { getAllTemplates, getTemplateById } from '@/utils/getPaymentTermTemplate';
import { PlusIcon } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

interface ICreditTermsTemplate extends ICreditTerms<CreditTermType> {
  templateId: string;
}

const TemplateCard = ({ term, type }: { term: ICreditTermsTemplate; type: CreditTermType }) => {
  const navigate = useNavigate();
  const { dispatch } = usePaymentTermContext();

  const { dispatch: dispatchMinGuaranteeTerm } = useMinGuaranteeTermContext();

  const handleClick = (id: string) => {
    if (type === 'PAYMENT_TERM') {
      dispatch({
        payload: getTemplateById(id),
        property: 'id',
        termIndex: -1,
        type: 'CREATE_NEW',
      });
      navigate(`/credit-term/view/add_payment_term`, {
        replace: true,
      });
    } else {
      dispatchMinGuaranteeTerm({
        payload: getMinGuaranteeTemplateById(id),
        property: 'id',
        termIndex: -1,
        type: 'CREATE_NEW',
      });
      navigate(`/credit-term/view/add_minimum_guarantee_term`, {
        replace: true,
      });
    }
  };

  return (
    <Card onClick={() => handleClick(term.templateId)} className=" w-80 h-36 hover:ring-1 cursor-pointer ">
      <CardHeader>
        <CardTitle>{term.title}</CardTitle>
        <CardDescription>{term.description}</CardDescription>
      </CardHeader>
    </Card>
  );
};

const CreateFromScratchCard = ({ type }: { type: CreditTermType }) => {
  const navigate = useNavigate();
  const { dispatch } = usePaymentTermContext();
  const { dispatch: dispatchMinGuaranteeTerm } = useMinGuaranteeTermContext();

  const handleClick = () => {
    if (type === 'PAYMENT_TERM') {
      dispatch({
        payload: getTemplateById('create_from_scratch'),
        property: 'id',
        termIndex: -1,
        type: 'CREATE_NEW',
      });
      navigate(`/credit-term/view/add_payment_term`, {
        replace: true,
      });
    } else {
      dispatchMinGuaranteeTerm({
        payload: getMinGuaranteeTemplateById('create_from_scratch'),
        property: 'id',
        termIndex: -1,
        type: 'CREATE_NEW',
      });
      navigate(`/credit-term/view/add_minimum_guarantee_term`, {
        replace: true,
      });
    }
  };

  return (
    <Card onClick={handleClick} className=" w-80 h-36 hover:ring-1 cursor-pointer ">
      <CardHeader className=" flex w-full h-full items-center justify-center gap-4 ">
        <CardTitle className=" flex items-center gap-2 ">
          Create from scratch <PlusIcon className="w-4 h-4" />{' '}
        </CardTitle>
      </CardHeader>
    </Card>
  );
};

const PaymentTermTemplates = () => {
  const templates = getAllTemplates();

  return (
    <div className=" flex gap-4 my-8  flex-wrap items-center ">
      {templates.map((template) => (
        <TemplateCard type="PAYMENT_TERM" key={template.id} term={template} />
      ))}
      <div>
        <CreateFromScratchCard type="PAYMENT_TERM" />
      </div>
    </div>
  );
};

const MinGuranteeTermTemplates = () => {
  const templates = getAllMinGuranteeTemplates();

  return (
    <div className=" flex gap-4 my-8  flex-wrap items-center ">
      {templates.map((template) => (
        <TemplateCard type="MIN_GUARANTEE_TERM" key={template.id} term={template} />
      ))}
      <CreateFromScratchCard type="MIN_GUARANTEE_TERM" />
    </div>
  );
};

const CreditTermTemplatesPage = () => {
  const { type } = useParams();

  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8">
      <Heading
        title={type === 'payment' ? 'Payment Term Templates' : 'Minimum Guarantee Term Template'}
        description={
          type === 'payment'
            ? 'Select a payment term template to get started'
            : 'Select a minimum guarantee term template to get started'
        }
      />
      {type === 'payment' ? <PaymentTermTemplates /> : <MinGuranteeTermTemplates />}
    </div>
  );
};

export default CreditTermTemplatesPage;
