import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { cn } from '@/lib/utils';
import { ILineItem } from '@/types/invoices.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';

function hasPostTaxDiscount(lineItems: ILineItem[] | undefined): boolean {
  return lineItems ? lineItems.some((item) => item.discount && item.discount.isPostTax) : false;
}

const InvoiceLineItemTable = ({
  lineItems,
  currencyType,
}: {
  lineItems?: ILineItem[];
  currencyType: string | undefined;
}) => {
  const columns: ColumnDef<ILineItem>[] = useMemo(
    () => [
      {
        id: 'itemName',
        header: 'Detail',
        accessorKey: 'lineItemDetails',
        cell: ({ getValue }) => {
          const details = getValue() as ILineItem['lineItemDetails'];
          return <div>{details.name}</div>;
        },
      },
      {
        id: 'hsnSac',
        header: 'HSN/SAC',
        accessorKey: 'lineItemDetails',
        cell: ({ getValue }) => {
          const details = getValue() as ILineItem['lineItemDetails'];
          return (
            <div className={cn(details.hsn_or_sac_code == null ? 'ml-6' : '')}>
              {' '}
              {details.hsn_or_sac_code ? parseInt(details.hsn_or_sac_code) : `-`}
            </div>
          );
        },
      },
      {
        id: 'preTaxAmount',
        header: 'Sub total',
        accessorKey: 'subTotal',
        cell: ({ getValue }) => formatCurrencyByUnit(getValue() as number, 'actual', currencyType),
      },
      {
        id: 'discount',
        header: `${hasPostTaxDiscount(lineItems) ? 'Post tax' : 'Pre tax'} Discount`,
        accessorKey: 'discount',
        cell: ({ row }) => {
          return (
            <div className="ml-6">
              {formatCurrencyByUnit(
                (row.original.discount && row.original.discount.amount) ?? 0,
                'actual',
                currencyType,
              )}
            </div>
          );
        },
      },
      {
        id: 'tax',
        header: 'Tax',
        accessorKey: 'lineItemDetails',
        cell: ({ getValue, row }) => {
          const total_tax = row.original.tax.total;
          const { taxRate } = getValue() as ILineItem['lineItemDetails'];
          return (
            <div className=" text-xs ">
              {taxRate ? (
                <div className=" text-sm mb-2 ">{`${formatCurrencyByUnit(
                  total_tax,
                  'actual',
                  currencyType,
                )} (@${taxRate}%)`}</div>
              ) : (
                <div className=" text-sm mb-2 ">{`${formatCurrencyByUnit(total_tax, 'actual', currencyType)} `}</div>
              )}
            </div>
          );
        },
      },
      {
        id: 'tds',
        header: 'TDS',
        accessorKey: 'tax.tds',
        cell: ({ getValue, row }) => {
          const subTotal = row.original?.subTotal;
          const tds = getValue() as number;
          let tds_rate = 0;
          if (tds > 0) {
            tds_rate = Math.round(((tds / subTotal) * 100 + Number.EPSILON) * 100) / 100;
          }
          return (
            <div className=" text-xs">
              {tds > 0 ? (
                <div className=" text-sm mb-2 ">{`${formatCurrencyByUnit(
                  tds,
                  'actual',
                  currencyType,
                )} (@${tds_rate}%)`}</div>
              ) : (
                <div className=" text-sm mb-2 pl-2">-</div>
              )}
            </div>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data: lineItems || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <Table>
        {(!lineItems || !lineItems?.length) && <TableCaption className="pb-10">No data found</TableCaption>}
        <TableHeader className=" bg-gray-100 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default InvoiceLineItemTable;
