'use client';

import DndProvider from '@/components/email-builder/dnd-provider';
import DraggableSectionPreview from '@/components/email-builder/draggable-section-preview';
import EditorCanvas from '@/components/email-builder/editor-canvas';
import EmailBuilderCreateNav from '@/components/email-builder/nav/CreateTemplateNav';
import EmailBuilderUpdateNav from '@/components/email-builder/nav/UpdateTemplateNav';
import PageSectionSortableOverlay from '@/components/email-builder/page-section-sortable-overlay';
import PropertiesEditor from '@/components/email-builder/properties-panel/PropertiesEditor';

import { SubjectEditor } from '@/components/email-builder/render-section';
import SectionPreviewOverlay from '@/components/email-builder/section-preview-overalay';
import { Button } from '@/components/ui/button';
import { useGetEmailTemplateById, useGetEmailTemplateVariablesQuery } from '@/hooks/api-hooks/useEmailTemplatesQuery';
import { useStore } from '@/stores/email-builder/page-sections.store';
import { templates } from '@/utils/email-builder/sections';
import { useMeasure } from '@uidotdev/usehooks';
import { Loader2Icon } from 'lucide-react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function Page() {
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const { loadData } = useStore((store) => ({ loadData: store.loadData }));
  const { templateId } = useParams();

  const { data: variablesData, isLoading: isVariableDataLoading } = useGetEmailTemplateVariablesQuery({});

  const { data: templateResponse, isLoading } = useGetEmailTemplateById({
    templateId: templateId as string,
    customConfig: {
      enabled: !!templateId,
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    const underScoreWithNumberRegex = /_[\d]+/g;

    if (templateResponse?.data) {
      loadData({
        description: templateResponse.data.description,
        name: templateResponse.data.name,
        pageSectionsOrder: templateResponse.data.contentJson.pageSectionsOrder,
        sections: templateResponse.data.contentJson.sections,
        subjectHtml: templateResponse.data.subjectContentHtml.replaceAll(underScoreWithNumberRegex, ''),
      });
    } else if (!isLoading) {
      loadData({
        description: '',
        name: '',
        pageSectionsOrder: [],
        sections: {},
        subjectHtml: '',
      });
    }
  }, [templateResponse, loadData, isLoading]);

  if (isVariableDataLoading) {
    return (
      <div className="h-screen flex items-center justify-center overflow-hidden">
        <div className="flex items-center px-2 sm:px-4 md:px-8">
          <Loader2Icon className="w-10 h-10 animate-spin" />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center overflow-hidden">
        <div className="flex items-center px-2 sm:px-4 md:px-8">
          <Loader2Icon className="w-10 h-10 animate-spin" />
        </div>
      </div>
    );
  }

  if (!variablesData?.data || Object.keys(variablesData.data).length === 0) {
    return (
      <div className="h-screen flex items-center justify-center overflow-hidden">
        <div className="flex items-center px-2 sm:px-4 md:px-8">
          <div className=" flex flex-col gap-1 ">
            <h1 className="text-3xl font-semibold">Unable to fetch template variables</h1>
            <p>Please try again later</p>
            <div>
              <Button onClick={() => navigate(-1)}>Back</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className=" flex h-screen overflow-hidden flex-col ">
      {!templateId && <EmailBuilderCreateNav />}
      {!!templateId && <EmailBuilderUpdateNav />}
      <DndProvider>
        <div className="flex-1 flex overflow-hidden">
          <div className="w-[240px] border-r p-3">
            {Object.values(templates).map((section) => {
              return (
                <div className="p-1" key={section.id}>
                  <DraggableSectionPreview section={section} />
                </div>
              );
            })}
          </div>
          <div className="flex-1 flex-col bg-muted p-4 overflow-scroll">
            <div className="space-y-2 rounded-md border bg-background my-2 px-2">
              <SubjectEditor />
            </div>
            <div className="flex-1 flex h-full flex-col space-y-2 rounded-md border bg-background p-2">
              <div className="flex-1 overflow-hidden py-2 px-4" ref={ref}>
                <EditorCanvas />
              </div>
            </div>
          </div>
          <div className="w-[240px] border-r p-3">
            <PropertiesEditor />
          </div>
        </div>
        <SectionPreviewOverlay />
        <PageSectionSortableOverlay width={width ?? 0} />
      </DndProvider>
    </div>
  );
}
