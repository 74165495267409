import {
  deleteOwner,
  getOwnerById,
  getOwnerRoleSuggestions,
  getOwnerSuggestions,
  getOwnerSuggestionsByEmail,
  postCreateOwnerForCustomer,
  postUpdateOwner,
} from '@/api-functions/owner.api';
import { IApiResponse } from '@/types/common.types';
import { IOwner } from '@/types/contacts.types';
import { IOwnerSuggestions } from '@/types/owner.types';
import { ICreateOwnerRequest, IOwnersRoleSuggestionsResponse } from '@/types/owners.types';
import { UseMutationOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CustomerQueryKeys } from './useCustomerQuery';

export const usePostCreateOwnersForCustomer = ({
  customerId,
  customConfig,
}: {
  customConfig?: UseMutationOptions<ICreateOwnerRequest, AxiosError<IApiResponse<null>>, IOwner>;
  customerId: string;
}) => {
  const queryClient = useQueryClient();
  const postCreateCustomerOwnerMutation = useMutation<ICreateOwnerRequest, AxiosError<IApiResponse<null>>, IOwner>({
    mutationFn: ({ ...owner }: IOwner) =>
      postCreateOwnerForCustomer({
        customerId,
        name: owner.name.trim(),
        primaryEmail: owner?.primaryEmail?.trim() ? owner.primaryEmail.trim() : null,
        primaryPhone: owner?.primaryPhone?.trim() ? owner.primaryPhone.trim() : null,
        role: owner.role.trim(),
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: CustomerQueryKeys.getCustomerDetailsById(customerId),
      });
    },
  });

  return postCreateCustomerOwnerMutation;
};

export const usePostUpdateOwner = ({
  customerId,
  customConfig,
}: {
  customConfig?: UseMutationOptions<ICreateOwnerRequest, AxiosError<ICreateOwnerRequest>, IOwner>;
  customerId: string;
}) => {
  const queryClient = useQueryClient();
  const postCreateCustomerOwnerMutation = useMutation<ICreateOwnerRequest, AxiosError<ICreateOwnerRequest>, IOwner>({
    mutationFn: ({ ...owner }: IOwner) =>
      postUpdateOwner({
        ownerId: owner.id,
        customerId: customerId,
        role: owner.role,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: CustomerQueryKeys.getCustomerDetailsById(customerId),
      });
    },
  });

  return postCreateCustomerOwnerMutation;
};

export const useDeleteOwner = ({
  customerId,
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<null>,
    AxiosError<IApiResponse<null>>,
    { ownerId: string; customerId: string }
  >;
  customerId: string;
}) => {
  const queryClient = useQueryClient();
  const deleteOwnerMutation = useMutation<
    IApiResponse<null>,
    AxiosError<IApiResponse<null>>,
    { ownerId: string; customerId: string }
  >({
    mutationFn: ({ ownerId }: { ownerId: string }) =>
      deleteOwner({
        ownerId,
        customerId,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: CustomerQueryKeys.getCustomerDetailsById(customerId),
      });
    },
  });

  return deleteOwnerMutation;
};

export const useGetOwnerRoleSuggestions = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<IOwnersRoleSuggestionsResponse, unknown, { query: string }>;
}) => {
  const getOwnerRolesSuggestionsQuery = useMutation<IOwnersRoleSuggestionsResponse, unknown, { query: string }>({
    mutationFn: ({ query }: { query: string }) => getOwnerRoleSuggestions(query),
    ...customConfig,
  });

  return getOwnerRolesSuggestionsQuery;
};

export const useGetOwnerSuggestions = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<IOwnerSuggestions, unknown, { emailString: string; nameString: string }>;
}) => {
  const getOwnerSuggestionsQuery = useMutation<IOwnerSuggestions, unknown, { emailString: string; nameString: string }>(
    {
      mutationFn: ({ emailString, nameString }: { emailString: string; nameString: string }) =>
        getOwnerSuggestions({
          emailString,
          nameString,
        }),
      ...customConfig,
    },
  );

  return getOwnerSuggestionsQuery;
};

export const useGetOwnerOrUserSuggestionsByEmail = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<IOwnerSuggestions, unknown, { emailString: string }>;
}) => {
  const getOwnerSuggestionsQuery = useMutation<IOwnerSuggestions, unknown, { emailString: string }>({
    mutationFn: ({ emailString }: { emailString: string }) =>
      getOwnerSuggestionsByEmail({
        emailString,
      }),
    ...customConfig,
  });

  return getOwnerSuggestionsQuery;
};

export const useGetOwnerById = ({ ownerId }: { ownerId: string }) => {
  const getOwnerByIdQuery = useQuery<IApiResponse<IOwner>, AxiosError<IApiResponse<null>>>({
    queryKey: ['ownerById', ownerId],
    queryFn: () => getOwnerById(ownerId),
    enabled: !!ownerId,
    staleTime: Infinity,
  });

  return getOwnerByIdQuery;
};
