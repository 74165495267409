import { useGetCategoriesByCompanyQuery } from '@/hooks/api-hooks/useCategoryQuery';
import { useMemo } from 'react';
import { Skeleton } from '../ui/skeleton';

const CategoryIdToLabel = ({ categoryId }: { categoryId: string }) => {
  const { data: categories, isLoading: isLoadingCategories } = useGetCategoriesByCompanyQuery({});

  const categoriesMap = useMemo(() => {
    return (
      categories?.data.reduce(
        (acc, category) => {
          acc[category.id] = category.value;
          return acc;
        },
        {} as Record<string, string>,
      ) || {}
    );
  }, [categories]);

  if (isLoadingCategories) {
    return <Skeleton className=" h-7 w-32 " />;
  }

  return categoriesMap[categoryId] ?? categoryId;
};

export default CategoryIdToLabel;
