import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import SelectComponent from '@/components/ui/select-component';
import { Section } from '@/utils/email-builder/sections';
import { propertiesMap } from '@/utils/type-map';
import { ArrowLeftIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import AddColumn from './AddColumn';
import Columns from './TableColumns';

const ManualTableProperties = ({
  handleEditFieldName,
  handleOperationChange,
  setSelectedField,
  tableField,
}: {
  handleEditFieldName: (_: string, __: string) => void;
  handleOperationChange: (_: string, __: string) => void;
  setSelectedField: (_: string) => void;
  tableField: Section<'table'>['content']['content']['columns'][0];
}) => {
  const div = document.createElement('div');
  div.innerHTML = tableField.label;

  const label = div.textContent || div.innerText || '';

  return (
    <div className=" py-4 text-sm flex flex-col gap-4 ">
      <div>
        <Button onClick={() => setSelectedField('')} variant="ghost" className=" flex items-center gap-2 ">
          <ArrowLeftIcon className=" w-4 h-4" />
          Back
        </Button>
      </div>
      <div className=" flex items-center gap-2 ">
        <h3 className=" font-semibold text-lg ">
          Edit table {'>'} <span className=" font-semibold ">{label}</span>
        </h3>
      </div>
      <div>
        <h3 className=" text-sm ">Field name</h3>
        <div className=" flex items-center gap-4">
          <Input
            placeholder="Column Field"
            className=" flex-1 "
            value={label}
            onChange={(e) => handleEditFieldName(e.target.value, tableField.columnId)}
          />
        </div>
      </div>

      {tableField?.fieldName.split('.').length > 1 &&
        propertiesMap.invoice?.[tableField?.fieldName?.split('.')[1].replaceAll('}', '')] === 'NUMBER' && (
          <div>
            <h3 className="  text-sm ">Operation</h3>
            <SelectComponent
              value={tableField.operation}
              options={[
                {
                  value: 'none',
                  label: 'None',
                },
                {
                  value: 'sum',
                  label: 'Sum',
                },
              ]}
              onChange={(value) => handleOperationChange(value, tableField.columnId)}
            />
          </div>
        )}
    </div>
  );
};

const VariableTableProperties = ({
  handleEditFieldName,
  setSelectedField,
  handleOperationChange,
  tableField,
  handleOperationLabelChange,
}: {
  handleEditFieldName: (_: string, __: string) => void;
  handleOperationChange: (_: string, __: string) => void;
  setSelectedField: (_: string) => void;
  tableField: Section<'table'>['content']['content']['columns'][0];
  handleOperationLabelChange: (_: string, __: string) => void;
}) => {
  return (
    <div className=" py-4 text-sm flex flex-col gap-4 ">
      <div>
        <Button onClick={() => setSelectedField('')} variant="ghost" className=" flex items-center gap-2 ">
          <ArrowLeftIcon className=" w-4 h-4" />
          Back
        </Button>
      </div>
      <div className=" flex items-center gap-2 ">
        <h3 className=" font-semibold text-lg ">
          Edit table {'>'} <span className=" font-semibold ">{tableField.fieldName}</span>
        </h3>
      </div>
      <div>
        <h3 className=" text-sm ">Field name</h3>
        <div className=" flex items-center gap-4">
          <Input
            placeholder="Column Field"
            className=" flex-1 "
            value={tableField.fieldName}
            onChange={(e) => handleEditFieldName(e.target.value, tableField.columnId)}
          />
        </div>
      </div>

      {tableField?.fieldName.split('.').length > 1 &&
        propertiesMap.invoice?.[tableField?.fieldName?.split('.')[1].replaceAll('}', '')] === 'NUMBER' && (
          <div>
            <h3 className="  text-sm ">Operation</h3>
            <SelectComponent
              value={tableField.operation}
              options={[
                {
                  value: 'none',
                  label: 'None',
                },
                {
                  value: 'sum',
                  label: 'Sum',
                },
              ]}
              onChange={(value) => handleOperationChange(value, tableField.columnId)}
            />
          </div>
        )}

      {tableField.operation !== 'none' && (
        <div>
          <h3 className="  text-sm ">Operation label</h3>
          <Input
            placeholder="Column Field"
            className=" flex-1 "
            value={tableField.operationLabel}
            onChange={(e) => handleOperationLabelChange(e.target.value, tableField.columnId)}
          />
        </div>
      )}
    </div>
  );
};

const TableProperties = ({
  handleEditFieldName,
  section,
  handleOperationChange,
  handleRemoveColumn,
  handleSourceChange,
  handleAddRow,
  selectedSection,
  handleOperationLabelChange,
}: {
  handleEditFieldName: (_: string, __: string) => void;
  handleOperationChange: (_: string, __: string) => void;
  section: Section<'table'> | undefined;
  handleRemoveColumn: (_: string) => void;
  handleSourceChange: (_: string) => void;
  handleAddRow: () => void;
  selectedSection: string | undefined;
  handleOperationLabelChange: (_: string, __: string) => void;
}) => {
  const [selectedField, setSelectedField] = useState('');

  const tableField = useMemo(() => {
    if (section?.type !== 'table') return null;
    const field = (section.content as Section<'table'>['content']).content.columns.find(
      (column) => column.columnId === selectedField,
    );

    if (field) return field;

    return null;
  }, [section, selectedField]);

  useEffect(() => {
    setSelectedField('');
  }, [selectedSection, setSelectedField]);

  if (selectedField?.trim() && !!tableField) {
    if (section?.content.content.variable === 'Manual') {
      return (
        <ManualTableProperties
          handleEditFieldName={handleEditFieldName}
          tableField={tableField}
          handleOperationChange={handleOperationChange}
          setSelectedField={setSelectedField}
        />
      );
    }

    return (
      <VariableTableProperties
        handleOperationLabelChange={handleOperationLabelChange}
        handleEditFieldName={handleEditFieldName}
        tableField={tableField}
        setSelectedField={setSelectedField}
        handleOperationChange={handleOperationChange}
      />
    );
  }

  return (
    <div className=" py-4 text-sm flex flex-col gap-4 ">
      <div>
        <h3 className=" font-semibold text-lg ">Properties</h3>
      </div>
      <div>
        <h3 className=" font-semibold text-base ">Source</h3>
        <SelectComponent
          value={(section as Section<'table'>).content.content.variable}
          options={[
            {
              value: 'Manual',
              label: 'Manual',
            },
            {
              value: 'Invoice',
              label: 'Invoice',
            },
          ]}
          onChange={handleSourceChange}
        />
      </div>
      <div>
        <h3 className=" font-semibold text-base ">Columns</h3>
        <Columns
          columns={(section as Section<'table'>).content.content.columns}
          handleRemoveColumn={handleRemoveColumn}
          setSelectedField={setSelectedField}
          type={(section as Section<'table'>).content.content.variable}
        />
        <AddColumn />
      </div>
      {(section as Section<'table'>).content.content.variable === 'Manual' && (
        <div>
          <h3 className=" font-semibold text-base ">Rows</h3>
          <div>
            <Button onClick={handleAddRow}>Add Row </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableProperties;
