import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom';
import { Toaster } from './components/ui/toaster';
import ErrorWrapper from './components/wrappers/ErrorWrapper';
import RequireAuth from './components/wrappers/RequireAuth';
import SkipIfAuthenticated from './components/wrappers/SkipIfAuthenticated';
import UserWrapper from './components/wrappers/UserWrapper';
import WithSidebarComponent from './components/wrappers/WithSidebar';
import CreditTermProvider from './contexts/CreditTermContext';
import PlanProvider from './contexts/PlanContext';
import ActivityListPage from './pages/activity/activity-list';
import ActivityDetailsPage from './pages/activity/view-activity';
import AddCompanyPage from './pages/add-company';
import AddPan from './pages/add-pan';
import BankTransactionsPage from './pages/bank-transactions';
import CashflowDetailsPage from './pages/cashflow-details';
import CashflowSummaryPage from './pages/cashflow-summary';
import CreditNoteListPage from './pages/credit-note/credit-note-list';
import ViewCreditNotePage from './pages/credit-note/view-credit-note';
import CreditTermTemplatesPage from './pages/credit-terms/credit-term-templates';
import ManageCreditTerms from './pages/credit-terms/manage-credit-terms';
import ViewCreditTerm from './pages/credit-terms/view-credit-term';
import CustomerPage from './pages/customer';
import CreatePlanView from './pages/edit-plan';
import EmailBuilderPage from './pages/email-builder/builder';
import PreviewPage from './pages/email-builder/preview';
import EmailTemplatesListView from './pages/email-builder/templates';
import IntegrationsPage from './pages/integrations';
import InvoicePage from './pages/invoices';
import NotFoundPage from './pages/not-found-page';
import PlanningListView from './pages/plan';
import CreditsPostingPage from './pages/posting/credits';
import InvoicesPostingPage from './pages/posting/invoices';
import LedgerCredit from './pages/posting/ledger-credit';
import LedgerInvoice from './pages/posting/ledger-invoice';
import SingleCreditPage from './pages/posting/single-credit-view';
import PostingInvoiceDetail from './pages/posting/view-invoice-posting';
import CreateSegmentPage from './pages/segments/create-segment';
import SegmentsListPage from './pages/segments/segments';
import ViewSegmentPage from './pages/segments/view-segment';
import SignInPage from './pages/signin';
import SignUpPageBody from './pages/signup';
import UPIQRPage from './pages/upi-qr';
import UserProfileAndSettings from './pages/user-profile';
import ViewCustomerPage from './pages/view-customer';
import ViewInvoicePage from './pages/view-invoice';
import PlanningView from './pages/view-plan';
import MailWebviewPage from './pages/webview/email';
import DefinePage from './pages/workflow/define';
import ExitCriteriaPage from './pages/workflow/exit-criteria';
import PlaygroundPage from './pages/workflow/playground';
import WorkflowDryRunListPage from './pages/workflow/workflow-dryrun-list';
import WorkflowListPage from './pages/workflow/workflow-list';
import ManageWriteOffsPage from './pages/write-off/manage-write-offs';
import './styles/App.css';

const CashflowRoutes = () => {
  const location = useLocation();

  if (location.pathname === '/cashflow' || location.pathname === '/cashflow/') {
    return <NotFoundPage />;
  }

  return (
    <RequireAuth>
      <UserWrapper>
        <WithSidebarComponent>
          <PlanProvider>
            <Outlet />
          </PlanProvider>
        </WithSidebarComponent>
      </UserWrapper>
    </RequireAuth>
  );
};

const TermCreateRoutes = () => {
  return (
    <RequireAuth>
      <UserWrapper>
        <CreditTermProvider>
          <WithSidebarComponent>
            <Outlet />
          </WithSidebarComponent>
        </CreditTermProvider>
      </UserWrapper>
    </RequireAuth>
  );
};

const DashboardPageWrapper = ({
  children,
  containerStyles,
}: {
  children: React.ReactNode;
  containerStyles?: string;
}) => {
  return (
    <RequireAuth>
      <UserWrapper>
        <WithSidebarComponent containerStyles={containerStyles}>{children}</WithSidebarComponent>
      </UserWrapper>
    </RequireAuth>
  );
};

const DashboardPageWithoutSidebarWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <ErrorWrapper>
      <RequireAuth>
        <UserWrapper>{children}</UserWrapper>
      </RequireAuth>
    </ErrorWrapper>
  );
};

function getRoutes() {
  return [
    {
      path: '/',
      element: (
        <>
          <Navigate to={'/signin'} replace />
        </>
      ),
    },
    {
      path: 'invite',
      element: <SignUpPageBody />,
    },
    {
      path: 'signin',
      element: (
        <SkipIfAuthenticated>
          <SignInPage />
        </SkipIfAuthenticated>
      ),
    },
    {
      path: 'add-company',
      element: (
        <RequireAuth>
          <AddCompanyPage />
        </RequireAuth>
      ),
    },
    {
      path: 'add-pan',
      element: (
        <RequireAuth>
          <AddPan />
        </RequireAuth>
      ),
    },
    {
      path: 'cashflow',
      element: <CashflowRoutes />,
      children: [
        {
          path: 'summary',
          element: <CashflowSummaryPage />,
        },
        {
          path: 'details',
          element: <CashflowDetailsPage />,
        },
      ],
    },
    {
      path: 'dashboard/bank-transactions',
      element: (
        <DashboardPageWrapper>
          <BankTransactionsPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'plan',
      element: (
        <DashboardPageWrapper>
          <PlanningListView />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'plan/view/:planId',
      element: (
        <DashboardPageWrapper>
          <PlanningView />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'plan/edit/:planId',
      element: (
        <DashboardPageWrapper>
          <CreatePlanView />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'invoices',
      element: (
        <DashboardPageWrapper>
          <InvoicePage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'invoice/view/:invoiceId',
      element: (
        <DashboardPageWrapper>
          <ViewInvoicePage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'customer/view/:customerId',
      element: (
        <DashboardPageWrapper>
          <ViewCustomerPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'customers',
      element: (
        <DashboardPageWrapper>
          <CustomerPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'credit-notes',
      element: (
        <DashboardPageWrapper>
          <CreditNoteListPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'credit-note/view/:creditNoteId',
      element: (
        <DashboardPageWrapper>
          <ViewCreditNotePage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'credit-terms',
      element: (
        <DashboardPageWrapper>
          <ManageCreditTerms />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'credit-term',
      element: <TermCreateRoutes />,
      children: [
        {
          path: 'view/:creditTermId',
          element: <ViewCreditTerm />,
        },
        {
          path: 'templates/:type',
          element: <CreditTermTemplatesPage />,
        },
      ],
    },
    {
      path: 'segments',
      element: (
        <DashboardPageWrapper>
          <SegmentsListPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'segments/create',
      element: (
        <DashboardPageWrapper>
          <CreateSegmentPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'segments/view/:segmentId',
      element: (
        <DashboardPageWrapper>
          <ViewSegmentPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'posting/credits',
      element: (
        <DashboardPageWrapper>
          <CreditsPostingPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'posting/credit/:transactionId',
      element: (
        <DashboardPageWithoutSidebarWrapper>
          <SingleCreditPage />
        </DashboardPageWithoutSidebarWrapper>
      ),
    },
    {
      path: 'posting/invoices',
      element: (
        <DashboardPageWrapper>
          <InvoicesPostingPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'posting/ledger/invoices',
      element: (
        <DashboardPageWrapper>
          <LedgerInvoice />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'posting/ledger/credits',
      element: (
        <DashboardPageWrapper>
          <LedgerCredit />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'posting/invoice/view/:invoiceId',
      element: (
        <DashboardPageWithoutSidebarWrapper>
          <PostingInvoiceDetail />
        </DashboardPageWithoutSidebarWrapper>
      ),
    },
    {
      path: '/posting/write-offs',
      element: (
        <DashboardPageWrapper>
          <ManageWriteOffsPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'profile',
      element: (
        <DashboardPageWrapper>
          <UserProfileAndSettings />
        </DashboardPageWrapper>
      ),
    },

    {
      path: 'templates',
      element: (
        <DashboardPageWrapper>
          <EmailTemplatesListView />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'email-builder',
      element: (
        <DashboardPageWithoutSidebarWrapper>
          <EmailBuilderPage />
        </DashboardPageWithoutSidebarWrapper>
      ),
    },
    {
      path: 'email-builder/:templateId/preview',
      element: (
        <DashboardPageWithoutSidebarWrapper>
          <PreviewPage />
        </DashboardPageWithoutSidebarWrapper>
      ),
    },
    {
      path: 'email-builder/:templateId',
      element: (
        <DashboardPageWithoutSidebarWrapper>
          <EmailBuilderPage />
        </DashboardPageWithoutSidebarWrapper>
      ),
    },
    {
      path: 'activities',
      element: (
        <DashboardPageWrapper containerStyles=" h-screen overflow-y-scroll">
          <ActivityListPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'activities/:activityId',
      element: (
        <DashboardPageWrapper containerStyles=" h-screen overflow-y-scroll">
          <ActivityDetailsPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'workflow',
      element: (
        <DashboardPageWrapper>
          <WorkflowListPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'workflow/dry-run/:workflowId',
      element: (
        <DashboardPageWrapper>
          <WorkflowDryRunListPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: 'workflow/create/define',
      element: (
        <DashboardPageWithoutSidebarWrapper>
          <DefinePage />
        </DashboardPageWithoutSidebarWrapper>
      ),
    },
    {
      path: 'workflow/update/define/:workflowId',
      element: (
        <DashboardPageWithoutSidebarWrapper>
          <DefinePage />
        </DashboardPageWithoutSidebarWrapper>
      ),
    },
    {
      path: 'workflow/playground/:workflowId',
      element: (
        <DashboardPageWithoutSidebarWrapper>
          <PlaygroundPage />
        </DashboardPageWithoutSidebarWrapper>
      ),
    },
    {
      path: 'workflow/create/exit-criteria',
      element: (
        <DashboardPageWithoutSidebarWrapper>
          <ExitCriteriaPage />
        </DashboardPageWithoutSidebarWrapper>
      ),
    },
    {
      path: 'workflow/create/exit-criteria/:workflowId',
      element: (
        <DashboardPageWithoutSidebarWrapper>
          <ExitCriteriaPage />
        </DashboardPageWithoutSidebarWrapper>
      ),
    },
    {
      path: 'archive/mail',
      element: (
        <ErrorWrapper>
          <MailWebviewPage />
        </ErrorWrapper>
      ),
    },
    {
      path: 'payment/upi',
      element: (
        <ErrorWrapper>
          <UPIQRPage />
        </ErrorWrapper>
      ),
    },
    {
      path: 'integrate',
      element: (
        <DashboardPageWrapper>
          <IntegrationsPage />
        </DashboardPageWrapper>
      ),
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ];
}

const App = () => {
  const element = useRoutes(getRoutes());

  return (
    <div className=" flex flex-col min-h-[100vh] ">
      {element}
      <Toaster />
    </div>
  );
};

export default App;
