import {
  deleteTagForCustomer,
  getCustomerTagNameSuggestions,
  getCustomerTagValueSuggestions,
  postCreateTagForCustomer,
} from '@/api-functions/tags.api';
import { IApiResponse } from '@/types/common.types';
import { ITagsSuggestionsResponse } from '@/types/tags.types';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CustomerQueryKeys } from './useCustomerQuery';

export const TagsQueryKeys = {
  getTagValueSuggestionsByKey: (key: string) => ['getTagValueSuggestionsByKey', key],
};

export const useGetCustomerTagNameSuggestions = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<ITagsSuggestionsResponse, unknown, { query: string }>;
}) => {
  const getCustomerTagNameSuggestionsQuery = useMutation<ITagsSuggestionsResponse, unknown, { query: string }>({
    mutationFn: ({ query }: { query: string }) => getCustomerTagNameSuggestions(query),
    ...customConfig,
  });

  return getCustomerTagNameSuggestionsQuery;
};

export const useGetTagValueSuggestionsForGivenKeyMutation = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<ITagsSuggestionsResponse, unknown, { query: string }>;
}) => {
  const getTagsValueSuggestionsMutation = useMutation<ITagsSuggestionsResponse, unknown, { query: string }>({
    mutationFn: ({ query }: { query: string }) => getCustomerTagValueSuggestions(query),
    ...customConfig,
  });

  return getTagsValueSuggestionsMutation;
};

export const useGetTagValueSuggestionsForGivenKey = ({
  key,
  customConfig,
}: {
  key: string;
  customConfig?: Omit<UseQueryOptions<ITagsSuggestionsResponse>, 'queryKey'>;
}) => {
  const getTagsValueSuggestionsQuery = useQuery<ITagsSuggestionsResponse>({
    queryKey: TagsQueryKeys.getTagValueSuggestionsByKey(key),
    queryFn: () => getCustomerTagValueSuggestions(key),
    ...customConfig,
  });

  return getTagsValueSuggestionsQuery;
};

export const usePostCreateTagForCustomer = ({
  customerId,
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<null>,
    AxiosError<IApiResponse<null>>,
    { tagKey: string; tagValue: string }
  >;
  customerId: string;
}) => {
  const queryClient = useQueryClient();
  const postCreateCustomerTagMutation = useMutation<
    IApiResponse<null>,
    AxiosError<IApiResponse<null>>,
    { tagKey: string; tagValue: string }
  >({
    mutationFn: ({ tagKey, tagValue }: { tagKey: string; tagValue: string }) =>
      postCreateTagForCustomer({
        customerId,
        tagKey: tagKey.trim(),
        tagValue: tagValue.trim(),
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: CustomerQueryKeys.getCustomerDetailsById(customerId),
      });
    },
  });

  return postCreateCustomerTagMutation;
};

export const useDeleteCustomerTag = ({
  customerId,
  customConfig,
}: {
  customConfig?: UseMutationOptions<IApiResponse<null>, unknown, { tagKey: string }>;
  customerId: string;
}) => {
  const queryClient = useQueryClient();
  const deleteCustomerTagMutation = useMutation<IApiResponse<null>, unknown, { tagKey: string }>({
    mutationFn: ({ tagKey }: { tagKey: string }) =>
      deleteTagForCustomer({
        customerId,
        tagKey,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: CustomerQueryKeys.getCustomerDetailsById(customerId),
      });
    },
  });

  return deleteCustomerTagMutation;
};
