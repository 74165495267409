'use client';

import { cn } from '@/lib/utils';

import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { useEffect, useState } from 'react';
import { Button } from '../ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { Input } from '../ui/input';
import SelectComponent from '../ui/select-component';
interface IOption {
  label: string;
  value: string;
}

export const validateInputs = ({
  from,
  to,
  max,
}: {
  from: string;
  to: string;
  max: number;
}): {
  errorString?: string;
  isError: boolean;
} => {
  if (isNaN(Number(from)) || isNaN(Number(to))) {
    return {
      isError: true,
      errorString: 'Invalid value',
    };
  }

  if (Number(from) > max || Number(to) > max) {
    return {
      isError: true,
      errorString: 'Value must be less than ' + max,
    };
  }

  if (Number(from) > Number(to)) {
    return {
      isError: true,
      errorString: 'From must be less than to',
    };
  }

  return {
    isError: false,
  };
};

export const NumberRangeInput = ({
  value,
  onValueChange,
  max,
  min,
}: {
  value: [number | undefined, number | undefined];
  onValueChange: (_: [number | undefined, number | undefined]) => void;
  max: number;
  min: number;
  containerClassName?: string;
  itemClassName?: string;
  showErrorsInline?: boolean;
}) => {
  const [firstInputValue, setFirstInputValue] = useState(() => (value[0] !== undefined ? value[0].toString() : ''));
  const [secondInputValue, setSecondInputValue] = useState(() => (value[1] !== undefined ? value[1].toString() : ''));
  const [errorString, setErrorString] = useState('');

  const onChange = (value: string, rangeValue: 'from' | 'to') => {
    if (rangeValue === 'from') {
      setFirstInputValue(value);
    } else {
      setSecondInputValue(value);
    }
  };

  useEffect(() => {
    const validate = validateInputs({
      from: firstInputValue,
      to: secondInputValue,
      max,
    });

    if (validate.isError) {
      setErrorString(validate.errorString ?? '');
    } else {
      setErrorString('');
      const firstInput = firstInputValue.trim();
      const secondInput = secondInputValue.trim();
      if (firstInput === '' || secondInput === '') {
        onValueChange([undefined, undefined]);
        return;
      }
      onValueChange([Number(firstInputValue), Number(secondInputValue)]);
    }
  }, [firstInputValue, secondInputValue, min, max, onValueChange]);

  return (
    <div>
      <div className=" flex flex-col gap-4 ">
        <div>
          <p className=" text-sm mb-0.5">From: </p>
          <Input autoFocus value={firstInputValue} onChange={(e) => onChange(e.target.value, 'from')} />
        </div>
        <div>
          <p className=" text-sm mb-0.5">To: </p>
          <Input value={secondInputValue} onChange={(e) => onChange(e.target.value, 'to')} />
        </div>
      </div>
      <p className="my-2 text-red-500 text-xs ">{errorString.trim()}</p>
    </div>
  );
};

export const NumberRangeInputWithoutValidation = ({
  value,
  onValueChange,
  max,
  min,
  containerClassName,
  itemClassName,
}: {
  value: [number | undefined, number | undefined];
  onValueChange: (_: [number | undefined, number | undefined]) => void;
  max: number;
  min: number;
  containerClassName?: string;
  itemClassName?: string;
}) => {
  const [firstInputValue, setFirstInputValue] = useState(() => (value[0] !== undefined ? value[0].toString() : ''));
  const [secondInputValue, setSecondInputValue] = useState(() => (value[1] !== undefined ? value[1].toString() : ''));

  const onChange = (value: string, rangeValue: 'from' | 'to') => {
    if (rangeValue === 'from') {
      setFirstInputValue(value);
    } else {
      setSecondInputValue(value);
    }
  };

  useEffect(() => {
    onValueChange([
      firstInputValue.trim() ? Number(firstInputValue) : undefined,
      secondInputValue.trim() ? Number(secondInputValue) : undefined,
    ]);
  }, [firstInputValue, secondInputValue, min, max, onValueChange]);

  return (
    <div>
      <div className={cn(' flex flex-col gap-4 ', containerClassName)}>
        <div className={cn(itemClassName)}>
          <p className=" text-sm mb-0.5">From: </p>
          <Input autoFocus value={firstInputValue} onChange={(e) => onChange(e.target.value, 'from')} />
        </div>
        <div className={itemClassName}>
          <p className=" text-sm mb-0.5">To: </p>
          <Input value={secondInputValue} onChange={(e) => onChange(e.target.value, 'to')} />
        </div>
      </div>
    </div>
  );
};

export const RangedInputWithLabel = ({
  label,
  value,
  onChange,
  className,
}: {
  label: string;
  onChange: (_: [number | undefined, number | undefined]) => void;
  value: [number | undefined, number | undefined];
  placeholder: string;
  className?: string;
}) => {
  const [focused, setFocused] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    setFocused(open);
  };

  return (
    <div
      className={cn(
        'flex border items-center rounded-md max-w-[400px] overflow-ellipsis',
        focused && 'ring-1 ring-ring',
        className,
      )}
    >
      <div className=" text-sm px-4 py-1 border-r-2 truncate min-w-[120px] ">{label}</div>
      <DropdownMenu open={open} onOpenChange={handleOpenChange}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className=" text-ellipsis truncate w-full  ">
            <span className={cn('capitalize text-ellipsis flex-1 truncate text-left')}>
              {value
                .map((value) =>
                  value !== undefined ? `${value < 0 ? '-' : ''}${formatCurrencyByUnit(value, 'actual')}` : '--|--',
                )
                .join(' to ')}
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className=" w-80 py-8 px-4">
          <NumberRangeInput
            max={Number.MAX_SAFE_INTEGER}
            min={0}
            onValueChange={(value) => onChange(value)}
            value={value}
          />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

const ClubbedSelectWithRangeInput = <OptionTypes,>({
  options,
  onChange,
  value,
  defaultSelected,
  placeholder,
  className,
}: {
  options: IOption[];
  onChange: (_: [number | undefined, number | undefined], __: OptionTypes | undefined) => void;
  placeholderMap: Record<OptionTypes extends string ? OptionTypes : string, string>;
  value: [number | undefined, number | undefined];
  defaultSelected?: OptionTypes;
  placeholder: string;
  className?: string;
}) => {
  // TODO: add input type
  const [selectedValue, setSelectedValue] = useState<OptionTypes | undefined>(defaultSelected);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedValue(defaultSelected);
  }, [defaultSelected]);

  const [focused, setFocused] = useState(false);

  const handleSelect = (value: string) => {
    const newValue = value as OptionTypes;
    setSelectedValue(newValue);
    onChange([undefined, undefined], newValue);
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    setFocused(open);
  };

  return (
    <div
      className={cn('flex border rounded-md max-w-[400px] overflow-ellipsis', focused && 'ring-1 ring-ring', className)}
    >
      <SelectComponent
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className=" border-0 ring-0 hover:ring-0 focus-within:ring-0 focus-visible:ring-0 focus:ring-0 "
        value={selectedValue ? (selectedValue as string) : undefined}
        options={options}
        onChange={handleSelect}
      />
      <DropdownMenu open={open} onOpenChange={handleOpenChange}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className=" text-ellipsis truncate w-full  ">
            <span className={cn('capitalize text-ellipsis flex-1 truncate text-left')}>
              {value
                .map((value) =>
                  value !== undefined ? `${value < 0 ? '-' : ''}${formatCurrencyByUnit(value, 'actual')}` : '--|--',
                )
                .join(' to ')}
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className=" w-80 py-8 px-4">
          <NumberRangeInput
            max={Number.MAX_SAFE_INTEGER}
            min={0}
            onValueChange={(value) => onChange(value, selectedValue)}
            value={value}
          />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default ClubbedSelectWithRangeInput;
