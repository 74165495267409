import { dataAxiosInstance } from '@/lib/axios';
import { ICreateContactsRequest } from './../types/contacts.types';

export const postCreateContactForCustomer = async ({ ...contact }: ICreateContactsRequest) => {
  const result = await dataAxiosInstance.post('/contacts', {
    ...contact,
  });
  return result.data;
};

export const postUpdateContact = async ({
  updateObject,
  contactId,
}: {
  updateObject: Omit<ICreateContactsRequest, 'customerId'>;
  contactId: string;
}) => {
  const result = await dataAxiosInstance.post('/contacts/update', {
    contactId,
    updates: {
      fields: ['name', 'primaryPhone', 'primaryEmail', 'role'],
      values: updateObject,
    },
  });

  return result.data;
};

export const deleteContact = async ({ contactId }: { contactId: string }) => {
  const result = await dataAxiosInstance.delete('/contacts/delete', {
    data: {
      contactId,
    },
  });
  return result.data;
};

export const getContactsRoleSuggestions = async (query: string) => {
  const result = await dataAxiosInstance.get(`/contacts/role/suggestions?roleContains=${query.trim()}`);
  return result.data;
};
