import { useGetBudgetsByCompany } from '@/hooks/api-hooks/useBudgetQuery';
import { cn } from '@/lib/utils';
import { useCallback, useEffect, useState } from 'react';
import Empty from '../ui/empty';
import { Skeleton } from '../ui/skeleton';
import { TableUnitsTabs } from '../ui/table-unit-tabs';
import { Tabs, TabsList, TabsTrigger } from '../ui/tabs';
import { CollapsibleRows } from './PlanTableRows';
import TableHeading from './TableHeading';

const SkeletonRows = ({ id, length, index }: { id: string; length: number; index: number }) => {
  return (
    <div className="flex" key={id}>
      <div
        className={cn(
          'flex min-w-[240px] max-w-[240px] items-center justify-center border-b',
          index === 0 && 'bg-gray-100',
        )}
      >
        <Skeleton className="h-5 w-[90%]" />
      </div>
      <div
        className={cn(
          'min-w-[240px] max-w-[240px] py-3 border-r border-b flex justify-center items-center',
          index === 0 && 'bg-gray-100',
        )}
      >
        <Skeleton className="h-5 w-[90%]" />
      </div>
      {[...Array(length)].map((budget: string) => (
        <div
          className={cn(
            'min-w-[180px] border-r border-b text-center  px-4 flex items-center justify-center',
            index === 0 && 'bg-gray-100',
          )}
          key={budget}
        >
          <Skeleton className="h-5 w-full" />
        </div>
      ))}
    </div>
  );
};

const PlanningTable = ({
  planId,
  isExpandable,
  defaultScope = 'monthly',
  hideScopeTabs = false,
  showReceivables = false,
  isDefaultExpanded = true,
}: {
  planId: string;
  isExpandable: boolean;
  defaultScope?: 'monthly' | 'yearly';
  hideScopeTabs?: boolean;
  showReceivables: boolean;
  isDefaultExpanded?: boolean;
}) => {
  const [scope, setScope] = useState<'monthly' | 'yearly'>(defaultScope);
  const [unit, setUnit] = useState<'k' | 'l' | 'cr' | 'actual'>('actual');

  useEffect(() => {
    if (defaultScope) {
      setScope(defaultScope);
    }
  }, [defaultScope, setScope]);

  const {
    isLoading,
    data,
    refetch: refetchMonthly,
  } = useGetBudgetsByCompany({
    frequency: scope,
    planId,
    customConfig: {
      refetchOnWindowFocus: false,
      enabled: !!scope && !!planId,
      staleTime: 0,
      gcTime: 0,
    },
  });

  const {
    isLoading: isYearlyDataLoading,
    data: yearlyData,
    refetch: refetchYearly,
  } = useGetBudgetsByCompany({
    frequency: 'yearly',
    planId,
    customConfig: {
      refetchOnWindowFocus: false,
      enabled: !!planId,
      staleTime: 0,
      gcTime: 0,
    },
  });

  const refetch = useCallback(async () => {
    await refetchMonthly();
    await refetchYearly();
  }, [refetchMonthly, refetchYearly]);

  const handleScopeChange = (value: string) => {
    const newScope = value as 'monthly' | 'yearly';
    setScope(newScope);
  };

  const handleUnitChange = (value: string) => {
    const newUnit = value as 'k' | 'l' | 'cr' | 'actual';
    setUnit(newUnit);
  };

  if (isLoading || !data || !yearlyData || isYearlyDataLoading) {
    return (
      <div className="my-4 text-sm">
        <div className="flex items-center justify-between">
          <div>
            <Skeleton className="h-5 w-72" />
          </div>
          <div className="flex justify-end gap-4">
            <TableUnitsTabs value={unit} onValueChange={handleUnitChange} />
            {!hideScopeTabs && (
              <Tabs value={scope} onValueChange={handleScopeChange}>
                <TabsList>
                  <TabsTrigger value="monthly">Monthly</TabsTrigger>
                  <TabsTrigger value="yearly">Yearly</TabsTrigger>
                </TabsList>
              </Tabs>
            )}
          </div>
        </div>
        <div className="relative">
          <div className="overflow-x-scroll py-6">
            {new Array(4).fill(0).map((_, index) => (
              <SkeletonRows key={index} id={index.toString()} length={scope === 'monthly' ? 12 : 1} index={index} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (!data?.data?.budgetsAndRelations && !data?.data?.budgetsAndRelations.length) {
    return <Empty title="No data" />;
  }

  const budgets = data.data.budgetsAndRelations;
  const yearlyBudgets = yearlyData.data.budgetsAndRelations;
  const year = data?.data?.financialYear;

  return (
    <div className="my-4 text-sm">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {!!data?.data?.planName && (
            <>
              <h3 className="text-lg font-semibold">{data.data.planName}</h3>
              <span className="text-xs">
                Apr {data.data.financialYear - 1} - Mar {data.data.financialYear}
              </span>
            </>
          )}
        </div>
        <div className="flex justify-end gap-4">
          <TableUnitsTabs value={unit} onValueChange={handleUnitChange} />
          {!hideScopeTabs && (
            <Tabs value={scope} onValueChange={handleScopeChange}>
              <TabsList>
                <TabsTrigger value="monthly">Monthly</TabsTrigger>
                <TabsTrigger value="yearly">Yearly</TabsTrigger>
              </TabsList>
            </Tabs>
          )}
        </div>
      </div>
      <div className="relative flex">
        <div className="h-full min-w-[340px] bg-red" />
        <div className=" overflow-x-scroll py-6">
          <TableHeading showReceivables={showReceivables} financialYear={data.data.financialYear} scope={scope} />
          <div>
            {budgets.map((category) => (
              <CollapsibleRows
                isDefaultExpanded={isDefaultExpanded}
                refetch={refetch}
                isExpandable={isExpandable}
                unit={unit}
                indentation={0}
                key={category.categoryId}
                categoryData={category}
                scope={scope}
                showReceivables={showReceivables}
                isReceivableEditable={false}
                yearlyData={yearlyBudgets.find((yearlyCategory) => yearlyCategory.categoryId === category.categoryId)}
                year={year}
                parent={null}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanningTable;
