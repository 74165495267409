'use client';

import { templates } from '@/utils/email-builder/sections';
import { DragOverlay, DropAnimation, defaultDropAnimationSideEffects, useDndContext } from '@dnd-kit/core';
import { useMemo } from 'react';
import SectionPreview from './section-preview';

const dropAnimation: DropAnimation = {
  duration: 100,
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

export default function SectionPreviewOverlay() {
  const { active } = useDndContext();

  const section = useMemo(() => {
    if (active && active.id.toString().startsWith('draggable-preview:')) {
      const sectionTemplateId = active.id.toString().replace('draggable-preview:', '');
      return templates[sectionTemplateId];
    }
  }, [active]);

  if (section) {
    return (
      <DragOverlay dropAnimation={dropAnimation}>
        <SectionPreview section={section} />
      </DragOverlay>
    );
  }

  return null;
}
