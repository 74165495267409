import { Layout } from '@/components/shared/Layout';
import Pagination from '@/components/shared/Pagination';
import { ColumnSelector } from '@/components/shared/ReactTableColumnSelector';
import { CustomTableBody, MemoizedTableBody } from '@/components/Table/MemoizedTableBody';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { DatePickerWithRange } from '@/components/ui/multi-datepicker';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import { useGetCustomerSuggestionsMutation } from '@/hooks/api-hooks/useCustomerQuery';
import {
  useApproveWriteOffMutation,
  useCancelWriteOffFromWriteOffPageMutation,
  useCheckIfUserIsApprover,
  useGetAllWriteOff,
  useGetAllWriteOffSummary,
  useGetRequesterSuggestionsMutation,
  useRejectWriteOffMutation,
  useSendApprovalMail,
} from '@/hooks/api-hooks/useWriteOffQuery';
import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { cn } from '@/lib/utils';
import { IApiResponse, IOptions } from '@/types/common.types';
import {
  IApproveRejectResponse,
  IGetAllWriteOffRequest,
  IWriteOff,
  IWriteOffActionList,
  IWriteOffComment,
  IWriteOffFilter,
  IWriteOffStatusOption,
  IWriteOffSummaryResponse,
} from '@/types/write-off.types';
import {
  WRITE_OFFS_TABLE_COLUMN_ORDER_KEY,
  WRITE_OFFS_TABLE_COLUMNS_VISIBILITY_KEY,
} from '@/utils/constants/local-storage-keys';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  RowSelectionState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ChevronDownIcon, GripVerticalIcon, Loader2Icon, LockKeyhole, XIcon } from 'lucide-react';
import { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { createPortal } from 'react-dom';
import { Link, useSearchParams } from 'react-router-dom';
import { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';

const ApprovalStatusDropDown = ({
  selected,
  handleSelect,
  className = '',
}: {
  selected: IWriteOffStatusOption[];
  handleSelect: (_: IWriteOffStatusOption) => () => void;
  className?: string;
}) => {
  const [open, setOpen] = useState(false);
  const options = useMemo<IWriteOffStatusOption[]>(
    () => [
      {
        label: 'Approved',
        value: 'APPROVED',
      },
      {
        label: 'Pending',
        value: 'PENDING',
      },
      {
        label: 'Rejected',
        value: 'REJECTED',
      },
      {
        label: 'Cancelled',
        value: 'CANCELLED',
      },
    ],
    [],
  );

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild>
        <Button
          className="flex items-center gap-4 text-left w-[300px] font-normal text-muted-foreground"
          onClick={() => setOpen((prev) => !prev)}
          variant="outline"
        >
          <span className={cn(' flex-1 truncate text-left w-[150px] capitalize overflow-ellipsis', className)}>
            {selected.length > 0 ? (
              selected.map((option) => option.label).join(', ')
            ) : (
              <span className=" transform-none normal-case ">Select Approval Status</span>
            )}
          </span>
          <ChevronDownIcon className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onInteractOutside={() => setOpen(false)}
        onEscapeKeyDown={() => setOpen(false)}
        onFocusOutside={() => setOpen(false)}
        onPointerDownOutside={() => setOpen(false)}
        className="w-80"
      >
        {options.map((option) => (
          <DropdownMenuCheckboxItem
            className=" normal-case pl-3 pr-3"
            key={option.value}
            checked={!!selected.find((item) => item.value === option.value)}
            onClick={handleSelect(option)}
          >
            {option.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const WriteOffFilters = ({
  filters,
  handleSubmit,
}: {
  filters: Partial<IWriteOffFilter>;
  handleSubmit: (_: Partial<IWriteOffFilter>) => void;
}) => {
  const [invoiceDateRange, setInvoiceDateRange] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });
  const [writeOffDateRange, setWriteOffDateRange] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });
  const [selectedCustomers, setSelectedCustomers] = useState<IOptions[]>([]);
  const [selectedRequesters, setSelectedRequesters] = useState<IOptions[]>([]);
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState<IWriteOffStatusOption[]>([]);

  useEffect(() => {
    setSelectedApprovalStatus(filters.selectedApprovalStatus ?? []);
  }, [filters]);

  const { mutateAsync: fetchCustomers } = useGetCustomerSuggestionsMutation({});
  const { mutateAsync: fetchRequesters } = useGetRequesterSuggestionsMutation({});

  const [searchParams, setSearchParams] = useSearchParams();

  const handleInvoiceDateRange = (value: DateRange | undefined) => {
    if (value) {
      setInvoiceDateRange(value);
    }
    setInvoiceDateRange({
      from: value?.from,
      to: value?.to,
    });
  };

  const handleWriteOffDateRange = (value: DateRange | undefined) => {
    if (value) {
      setWriteOffDateRange(value);
    }
    setWriteOffDateRange({
      from: value?.from,
      to: value?.to,
    });
  };

  const handleApprovalStatusSelect = (option: IWriteOffStatusOption) => () => {
    if (selectedApprovalStatus.find((item) => item.value === option.value)) {
      setSelectedApprovalStatus(selectedApprovalStatus.filter((item) => item.value !== option.value));
    } else {
      setSelectedApprovalStatus([
        ...selectedApprovalStatus,
        {
          label: option.label,
          value: option.value,
        },
      ]);
    }
  };

  const handleCustomerSelectChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((item) => ({ value: item.value, label: item.label }));
    setSelectedCustomers(newValue);
  };

  const handleRequesterSelectChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((item) => ({ value: item.value, label: item.label }));
    setSelectedRequesters(newValue);
  };

  const loadSelectOptionsForCustomers = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchCustomers({
        query: inputValue.trim(),
      });
      return result.data.suggestions.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    [fetchCustomers],
  );

  const loadSelectOptionsForRequesters = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchRequesters({
        query: inputValue.trim(),
      });
      return result.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    [fetchRequesters],
  );

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, IOptions>();
  }, []);

  useEffect(() => {
    const invoiceDateRangeStart = searchParams.get('invoiceDateRangeStart') || undefined;
    const invoiceDateRangeEnd = searchParams.get('invoiceDateRangeEnd') || undefined;
    const writeOffDateRangeStart = searchParams.get('writeOffDateRangeStart') || undefined;
    const writeOffDateRangeEnd = searchParams.get('writeOffDateRangeEnd') || undefined;
    const customers =
      searchParams
        .get('customers')
        ?.split(',')
        .filter((item) => !!item) || [];
    const requesters =
      searchParams
        .get('requesters')
        ?.split(',')
        .filter((item) => !!item) || [];
    const approvalStatuses =
      searchParams
        .get('approvalStatuses')
        ?.split(',')
        .filter((item) => !!item) || [];

    setInvoiceDateRange({
      from: invoiceDateRangeStart ? new Date(invoiceDateRangeStart) : undefined,
      to: invoiceDateRangeEnd ? new Date(invoiceDateRangeEnd) : undefined,
    });
    setWriteOffDateRange({
      from: writeOffDateRangeStart ? new Date(writeOffDateRangeStart) : undefined,
      to: writeOffDateRangeEnd ? new Date(writeOffDateRangeEnd) : undefined,
    });
    setSelectedCustomers(
      customers.map((customerString) => ({ value: customerString.split(':')[0], label: customerString.split(':')[1] })),
    );
    setSelectedRequesters(
      requesters.map((requesterString) => ({
        value: requesterString.split(':')[0],
        label: requesterString.split(':')[1],
      })),
    );
    setSelectedApprovalStatus(
      approvalStatuses.map((approvalStatusString) => ({
        label: approvalStatusString.split(':')[1],
        value: approvalStatusString.split(':')[0],
      })) as IWriteOffStatusOption[],
    );
  }, [searchParams]);

  const applyFiltersClickHandler = () => {
    const filters: IWriteOffFilter = {
      invoiceDateRange,
      writeOffDateRange,
      selectedCustomers,
      selectedRequesters,
      selectedApprovalStatus,
    };

    setSearchParams(
      {
        invoiceDateRangeStart: filters.invoiceDateRange.from
          ? dayjs(filters.invoiceDateRange.from).format('YYYY-MM-DD')
          : '',
        invoiceDateRangeEnd: filters.invoiceDateRange.to ? dayjs(filters.invoiceDateRange.to).format('YYYY-MM-DD') : '',
        writeOffDateRangeStart: filters.writeOffDateRange.from
          ? dayjs(filters.writeOffDateRange.from).format('YYYY-MM-DD')
          : '',
        writeOffDateRangeEnd: filters.writeOffDateRange.to
          ? dayjs(filters.writeOffDateRange.to).format('YYYY-MM-DD')
          : '',
        customers: filters.selectedCustomers.map((item) => `${item.value}:${item.label}`).join(','),
        requesters: filters.selectedRequesters.map((item) => `${item.value}:${item.label}`).join(','),
        approvalStatuses: filters.selectedApprovalStatus.map((item) => `${item.value}:${item.label}`).join(','),
      },
      {
        replace: true,
      },
    );

    handleSubmit(filters);
  };

  const resetClickHandler = () => {
    setInvoiceDateRange({
      from: undefined,
      to: undefined,
    });
    setWriteOffDateRange({
      from: undefined,
      to: undefined,
    });
    setSelectedApprovalStatus([]);
    setSelectedCustomers([]);
    setSelectedRequesters([]);
    handleSubmit({});
    setSearchParams(
      {},
      {
        replace: true,
      },
    );
  };

  return (
    <div className="mb-6 flex flex-col gap-4">
      <div className="flex gap-4 items-start flex-wrap">
        <DatePickerWithRange
          placeholder="Select Invoice Date"
          value={invoiceDateRange}
          onChange={handleInvoiceDateRange}
        />
        <DatePickerWithRange
          placeholder="Select Request Date"
          value={writeOffDateRange}
          onChange={handleWriteOffDateRange}
        />
        <ApprovalStatusDropDown handleSelect={handleApprovalStatusSelect} selected={selectedApprovalStatus} />
        <AsyncSelect
          isMulti
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          onChange={handleCustomerSelectChange}
          value={selectedCustomers}
          loadOptions={loadSelectOptionsForCustomers}
          className=" min-w-[300px] text-sm shadow-sm"
          styles={selectStyles}
          placeholder="Search By Customer Name"
          classNames={selectClasses}
        />
        <AsyncSelect
          isMulti
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          onChange={handleRequesterSelectChange}
          value={selectedRequesters}
          loadOptions={loadSelectOptionsForRequesters}
          className=" min-w-[300px] text-sm shadow-sm"
          styles={selectStyles}
          placeholder="Search By Requesters"
          classNames={selectClasses}
        />
      </div>
      <div className=" flex gap-4 ">
        <Button onClick={applyFiltersClickHandler}>Apply</Button>
        <Button variant="outline" onClick={resetClickHandler}>
          Reset
        </Button>
      </div>
    </div>
  );
};

const ApprovalStage = ({
  handleClose,
  selectedRows,
  allWriteOffRequestData,
  isApprover,
}: {
  handleClose: () => void;
  selectedRows: Row<IWriteOff>[];
  allWriteOffRequestData: IGetAllWriteOffRequest;
  isApprover: boolean | undefined;
}) => {
  const { toast } = useToast();
  const [isActionButtonsVisible, setIsActionButtonVisible] = useState(false);

  useEffect(() => {
    const isAnyWriteOffPending: boolean = selectedRows?.some((row) => row.original.approvalStatus === 'PENDING');
    setIsActionButtonVisible(isAnyWriteOffPending);
  }, [selectedRows]);

  const { mutate: approveWriteOff, isPending: isWriteOffApproving } = useApproveWriteOffMutation({
    requestData: allWriteOffRequestData,
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Some error occurred',
        });
      },
      onSettled: (response) => {
        if ((response as IApiResponse<IApproveRejectResponse>).data.failure === 1) {
          toast({
            variant: 'destructive',
            description: 'You have already given your inputs',
          });
        }
        handleClose();
      },
    },
  });

  const { mutate: rejectWriteOff, isPending: isWriteOffRejecting } = useRejectWriteOffMutation({
    requestData: allWriteOffRequestData,
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data?.message || 'Some error occurred',
        });
      },

      onSettled: (response) => {
        if ((response as IApiResponse<IApproveRejectResponse>).data.failure === 1) {
          toast({
            variant: 'destructive',
            description: 'You have already given your inputs',
          });
        }
        handleClose();
      },
    },
  });

  const { mutate: cancelWriteOff, isPending: isWriteOffCancelling } = useCancelWriteOffFromWriteOffPageMutation({
    requestData: allWriteOffRequestData,
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Some error occurred',
        });
      },
      onSettled: () => {
        handleClose();
      },
    },
  });

  // const { data: authData } = useAuthToken();

  const createRequestData = (): IWriteOffActionList[] => {
    const requestData: IWriteOffActionList[] = [];
    selectedRows.forEach((row) => {
      if (row.original.approvalStatus === 'PENDING') {
        requestData.push({
          id: row.original.requestId,
          comment: (document.getElementById(row.original.requestId) as HTMLTextAreaElement)?.value,
        });
      }
    });

    return requestData;
  };

  const handleApproveButtonClick = () => {
    const requestData = createRequestData();
    approveWriteOff({ requests: requestData });
  };

  const handleRejectButtonClick = () => {
    const requestData = createRequestData();
    rejectWriteOff({ requests: requestData });
  };

  const handleCancelButtonClick = () => {
    const requestData: IWriteOffActionList[] = [];
    selectedRows.forEach((row) => {
      if (row.original.approvalStatus === 'PENDING') {
        requestData.push({
          id: row.original.requestId,
          comment: '',
        });
      }
    });
    cancelWriteOff({ requests: requestData });
  };

  return (
    <div className="h-full">
      <div className="flex justify-between h-[6%]">
        <div>
          <h1 className="text-xl font-bold">Approval stage</h1>
        </div>
        <Button variant="ghost" onClick={handleClose} size="icon" disabled={isWriteOffRejecting || isWriteOffApproving}>
          <XIcon className="w-6 h-6" />
        </Button>
      </div>
      <div className="h-[86%] overflow-y-auto">
        {selectedRows.map((row) => (
          <div className="bg-white p-3 rounded-md mb-5" key={row.original.requestId}>
            <div className="text-md font-medium flex justify-between">
              <div className="flex items-center gap-3">
                {row.original.invoiceNumber}
                {row.original.approvalStatus !== 'PENDING' && (
                  <span className="w-6">
                    <ToolTipCell value={<LockKeyhole className="w-4 h-4 text-muted-foreground cursor-pointer" />}>
                      This request has been {row.original.approvalStatus.toLocaleLowerCase()} and is final (cannot be
                      changed).
                    </ToolTipCell>
                  </span>
                )}
              </div>
              <div>{formatCurrencyByUnit(row.original.writeOffAmount, 'actual')}</div>
            </div>
            <div className="text-sm flex justify-between mt-2">
              <div>Invoice Value: {formatCurrencyByUnit(row.original.invoiceTotal, 'actual')} </div>
              <div>{dayjs(row.original.invoiceDate).format('DD MMM YYYY')}</div>
            </div>
            {row.original.approvalStatus === 'PENDING' && isApprover && (
              <Textarea
                id={row.original.requestId}
                autoComplete="off"
                placeholder="Comment (optional)"
                className="mt-3"
              />
            )}
            <div className="mt-4">
              {/* Comment list */}
              {row.original.comment.map(
                (commentObj) =>
                  commentObj.verdict !== 'PENDING' && (
                    <div key={commentObj.userId} className="flex justify-between mb-2 text-sm">
                      {commentObj.verdict === 'APPROVED' && (
                        <>
                          <span className="w-4 h-4 rounded-full bg-green-700 mt-1"></span>
                          <div className="w-[88%]">
                            <div className="break-words">
                              <span className="font-medium">{commentObj.userName}</span> approved on{' '}
                              {dayjs(commentObj.timestamp * 1000).format('DD MMM YYYY')}
                            </div>
                            <em className="break-words block">
                              Comments: {commentObj.comment ? commentObj.comment : 'NA'}
                            </em>
                          </div>
                        </>
                      )}

                      {commentObj.verdict === 'REJECTED' && (
                        <>
                          <span className="w-4 h-4 rounded-full bg-destructive mt-1"></span>
                          <div className="w-[88%]">
                            <div className="break-words">
                              <span className="font-medium">{commentObj.userName}</span> rejected on{' '}
                              {dayjs(commentObj.timestamp * 1000).format('DD MMM YYYY')}
                            </div>
                            <em className="break-words block">
                              Comments: {commentObj.comment ? commentObj.comment : 'NA'}
                            </em>
                          </div>
                        </>
                      )}

                      {commentObj.verdict === 'CANCELLED' && (
                        <>
                          <span className="w-4 h-4 rounded-full bg-gray-500 mt-1"></span>
                          <div className="w-[88%]">
                            <div className="break-words">
                              <span className="font-medium">{commentObj.userName}</span> cancelled on{' '}
                              {dayjs(commentObj.timestamp * 1000).format('DD MMM YYYY')}
                            </div>
                            <em className="break-words block">
                              Comments: {commentObj.comment ? commentObj.comment : 'NA'}
                            </em>
                          </div>
                        </>
                      )}
                    </div>
                  ),
              )}

              {/* Pending */}
              {!isApprover && row.original.approvalStatus === 'PENDING' && (
                <div className="flex justify-between mb-2 text-sm">
                  <span className="w-4 h-4 rounded-full bg-yellow-400 mt-1"></span>
                  <div className="w-[88%]">
                    <div className="break-words">
                      <span className="font-medium">Pending</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="h-[8%] flex justify-end items-center gap-4">
        {isActionButtonsVisible && isApprover && (
          <>
            <Button
              className="bg-destructive text-white hover:bg-destructive flex items-center gap-2"
              disabled={isWriteOffRejecting || isWriteOffApproving}
              onClick={handleRejectButtonClick}
            >
              Reject
              {isWriteOffRejecting && <Loader2Icon className="w-4 h-4 animate-spin" />}
            </Button>
            <Button
              className="bg-green-700 text-white hover:bg-green-700 flex items-center gap-2"
              disabled={isWriteOffRejecting || isWriteOffApproving}
              onClick={handleApproveButtonClick}
            >
              Approve
              {isWriteOffApproving && <Loader2Icon className="w-4 h-4 animate-spin" />}
            </Button>
          </>
        )}

        {isActionButtonsVisible && !isApprover && (
          <>
            <Button
              className="bg-gray-500 text-white hover:bg-gray-500 flex items-center gap-2"
              disabled={isWriteOffCancelling}
              onClick={handleCancelButtonClick}
            >
              Cancel Request
              {isWriteOffCancelling && <Loader2Icon className="w-4 h-4 animate-spin" />}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const WriteOffsTable = ({
  filters,
  isApprover,
  divRef,
}: {
  filters: Partial<IWriteOffFilter>;
  isApprover: boolean | undefined;
  divRef: React.RefObject<HTMLDivElement>;
}) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const requestData: IGetAllWriteOffRequest = {
    page: page,
    pageSize: rowsPerPage,
    invoiceStartDate: filters.invoiceDateRange?.from,
    invoiceEndDate: filters.invoiceDateRange?.to,
    writeOffStartDate: filters.writeOffDateRange?.from,
    writeOffEndDate: filters.writeOffDateRange?.to,
    approvalStatusList: filters.selectedApprovalStatus?.map((item) => item.value),
    customerIdList: filters.selectedCustomers?.map((item) => item.value),
    requesterIdList: filters.selectedRequesters?.map((item) => item.value),
  };

  const { data: writeOffResponse, isLoading } = useGetAllWriteOff({
    requestData,
  });

  const tableData = useMemo(() => {
    return writeOffResponse?.data.docs || [];
  }, [writeOffResponse]);

  const columns: ColumnDef<IWriteOff>[] = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || table.getIsSomePageRowsSelected()}
            className={cn(table.getIsSomePageRowsSelected() && 'opacity-50')}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
        minSize: 50,
        size: 50,
        showGrips: false,
      },
      {
        id: 'invoiceNumber',
        header: 'Invoice Number',
        accessorKey: 'invoiceNumber',
        minSize: 40,
        cell: ({ row, getValue }) => {
          return (
            <div className=" w-full ">
              <Link to={`/invoice/view/${row.original.invoiceId}`}>
                <ToolTipCell value={getValue() as string} additionalClasses="pointer underline text-blue-700 " />
              </Link>
            </div>
          );
        },
      },

      {
        id: 'customer',
        header: 'Customer',
        accessorKey: 'customerName',
        minSize: 40,
        cell: ({ getValue }) => {
          return (
            <div className=" w-full ">
              <ToolTipCell value={getValue() as string} />
            </div>
          );
        },
      },
      {
        id: 'invoiceDate',
        header: 'Invoice Date',
        accessorKey: 'invoiceDate',
        minSize: 40,
        cell: ({ getValue }) => dayjs(getValue() as string).format('DD MMM YYYY'),
      },
      {
        id: 'writeOffAmount',
        header: 'Write Off Amount',
        accessorKey: 'writeOffAmount',
        minSize: 40,
        cell: ({ getValue, row }) => (
          <>
            <div>
              <div className=" w-full flex flex-col gap-1  capitalize ">
                {formatCurrencyByUnit(getValue() as number, 'actual')}
              </div>
              <div className="text-xs text-muted-foreground w-full">
                Invoice Value: {formatCurrencyByUnit(row.original.invoiceTotal, 'actual')}
              </div>
            </div>
          </>
        ),
      },
      {
        id: 'approvalStatus',
        header: 'Approval Status',
        accessorKey: 'approvalStatus',
        minSize: 40,
        cell: ({ getValue }) => (
          <div className=" w-full flex flex-col gap-1  ">
            <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
              <Badge
                className={cn(
                  getValue() === 'APPROVED' && 'bg-green-700 text-white hover:bg-green-700 hover:text-white',
                  getValue() === 'REJECTED' && 'bg-destructive text-white hover:bg-destructive hover:text-white',
                  getValue() === 'PENDING' && 'bg-yellow-400 text-black hover:bg-yellow-400 hover:text-black',
                  getValue() === 'CANCELLED' && 'bg-gray-500 text-white hover:bg-gray-500 hover:text-white',
                  'capitalize',
                )}
              >
                {getValue() as string}
              </Badge>
            </div>
          </div>
        ),
      },
      {
        id: 'requestedBy',
        header: 'Requested By',
        accessorKey: 'createdBy',
        minSize: 40,
        cell: ({ getValue, row }) => (
          <>
            <div>
              <div className=" w-full flex flex-col gap-1">{getValue() as string}</div>
              <div className="text-xs text-muted-foreground w-full">
                Requested On: {dayjs(row.original.createdAt).format('DD MMM YYYY')}
              </div>
            </div>
          </>
        ),
      },
      {
        id: 'requesterComment',
        header: 'Requester Comment',
        accessorKey: 'comment',
        size: 200,
        minSize: 40,
        cell: ({ getValue }) => {
          const pendingCommentObj = (getValue() as IWriteOffComment[]).find(
            (commentObj) => commentObj.verdict === 'PENDING',
          );
          return <ToolTipCell value={pendingCommentObj?.comment} />;
        },
      },
    ],
    [],
  );

  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);
  const defaultColumnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const { storedValue: columnVisibility, setValue: setColumnVisibility } = useLocalStorage(
    WRITE_OFFS_TABLE_COLUMNS_VISIBILITY_KEY,
    defaultColumnVisibility,
  );

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    WRITE_OFFS_TABLE_COLUMN_ORDER_KEY,
    defaultColumnOrder,
  );

  const table = useReactTable({
    data: tableData,
    columns,
    onRowSelectionChange: (value) => setRowSelection(value),
    getRowId: (row) => row.requestId,
    columnResizeMode: 'onChange',
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 150,
    },
    state: {
      rowSelection,
      columnVisibility,
      columnOrder,
    },
  });

  const sizes = table.getState().columnSizingInfo;

  const tableColumns = useMemo(() => table.getAllColumns(), [table]);

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    return headers.reduce(
      (acc, current) => {
        return {
          ...acc,
          [`--col-${current.column.id}-size`]: current.column.getSize(),
          [`--header-${current.id}-size`]: current.getSize(),
        };
      },
      {} as {
        [key: string]: number;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);

  const handleClose = () => {
    table.getSelectedRowModel().rows.forEach((row) => {
      if (row.getIsSelected()) {
        row.toggleSelected();
      }
    });
  };

  if (isLoading) {
    return (
      <div className="py-2">
        <Table>
          <TableHeader className=" bg-gray-100">
            {new Array(1).fill(0).map((_, i) => (
              <TableRow key={i}>
                {new Array(6).fill(0).map((_, i) => (
                  <TableHead className="px-6 min-w-[100px]" key={i}>
                    <Skeleton className="w-full h-4" />
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="py-4">
            {new Array(10).fill(0).map((_, i) => (
              <TableRow key={i}>
                {new Array(6).fill(0).map((_, i) => (
                  <TableCell className="px-6 text-center" key={i}>
                    <Skeleton className="w-full h-4" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <>
      {divRef.current &&
        createPortal(
          <ColumnSelector columns={tableColumns} columnOrder={columnOrder} setColumnOrder={setColumnOrder} />,
          divRef.current,
        )}
      <div className=" overflow-auto ">
        <Table
          style={{
            ...columnSizeVars,
            width: '100%',
          }}
          className="flex-1"
        >
          {writeOffResponse?.data.docs.length === 0 && <TableCaption className="pb-10">No data found</TableCaption>}
          <TableHeader className=" bg-gray-100 ">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className="flex items-center" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      style={{
                        width: `calc(var(--header-${header?.id}-size) * 1px)`,
                      }}
                      className="flex items-center justify-between"
                      key={header.id}
                    >
                      {!['actions', 'select'].includes(header.id) ? (
                        <div className="break-keep truncate text-ellipsis">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                      {!['actions', 'select'].includes(header.id) && (
                        <span className="w-4 h-4">
                          <GripVerticalIcon
                            {...{
                              onDoubleClick: () => header.column.resetSize(),
                              onMouseDown: header.getResizeHandler(),
                              onTouchStart: header.getResizeHandler(),
                              className: cn(
                                'w-4 h-4 cursor-ew-resize',
                                header.column.getIsResizing() ? 'opacity-100' : 'opacity-50',
                              ),
                            }}
                          />
                        </span>
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          {table.getState().columnSizingInfo.isResizingColumn ? (
            <MemoizedTableBody table={table} />
          ) : (
            <CustomTableBody table={table} />
          )}
        </Table>
        <Sheet modal={false} open={table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()}>
          <SheetContent className="bg-gray-100">
            <ApprovalStage
              allWriteOffRequestData={requestData}
              handleClose={handleClose}
              selectedRows={table.getSelectedRowModel().rows}
              isApprover={isApprover}
            />
          </SheetContent>
        </Sheet>
      </div>
      <Pagination
        hasNext={!!writeOffResponse?.data.hasNext}
        hasPrev={!!writeOffResponse?.data.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setRowsPerPage}
        pageNumber={page}
        rowsPerPage={rowsPerPage}
        totalPages={writeOffResponse?.data.totalPages || 0}
      />
    </>
  );
};

const ManageWriteOffsPage = () => {
  const [writeOffFilters, setWriteOffFilters] = useState<Partial<IWriteOffFilter>>({});

  const { toast } = useToast();

  const { data: checkIfUserIsApproverResponse, isLoading, error } = useCheckIfUserIsApprover({});
  const requestData: IGetAllWriteOffRequest = {
    page: 1,
    pageSize: 10,
    invoiceStartDate: writeOffFilters.invoiceDateRange?.from,
    invoiceEndDate: writeOffFilters.invoiceDateRange?.to,
    writeOffStartDate: writeOffFilters.writeOffDateRange?.from,
    writeOffEndDate: writeOffFilters.writeOffDateRange?.to,
    approvalStatusList: writeOffFilters.selectedApprovalStatus?.map((item) => item.value),
    customerIdList: writeOffFilters.selectedCustomers?.map((item) => item.value),
  };

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const invoiceDateRangeStart = searchParams.get('invoiceDateRangeStart') || undefined;
    const invoiceDateRangeEnd = searchParams.get('invoiceDateRangeEnd') || undefined;
    const writeOffDateRangeStart = searchParams.get('writeOffDateRangeStart') || undefined;
    const writeOffDateRangeEnd = searchParams.get('writeOffDateRangeEnd') || undefined;
    const customers =
      searchParams
        .get('customers')
        ?.split(',')
        .filter((item) => !!item) || [];
    const requesters =
      searchParams
        .get('requesters')
        ?.split(',')
        .filter((item) => !!item) || [];
    const approvalStatuses =
      searchParams
        .get('approvalStatuses')
        ?.split(',')
        .filter((item) => !!item) || [];

    setWriteOffFilters({
      invoiceDateRange: {
        from: invoiceDateRangeStart ? new Date(invoiceDateRangeStart) : undefined,
        to: invoiceDateRangeEnd ? new Date(invoiceDateRangeEnd) : undefined,
      },
      writeOffDateRange: {
        from: writeOffDateRangeStart ? new Date(writeOffDateRangeStart) : undefined,
        to: writeOffDateRangeEnd ? new Date(writeOffDateRangeEnd) : undefined,
      },
      selectedCustomers: customers.map((customerString) => ({
        value: customerString.split(':')[0],
        label: customerString.split(':')[1],
      })),
      selectedRequesters: requesters.map((requesterString) => ({
        value: requesterString.split(':')[0],
        label: requesterString.split(':')[1],
      })),
      selectedApprovalStatus:
        (
          approvalStatuses.map((approvalStatusString) => ({
            label: approvalStatusString.split(':')[1],
            value: approvalStatusString.split(':')[0],
          })) as IWriteOffStatusOption[]
        ).length > 0
          ? (approvalStatuses.map((approvalStatusString) => ({
              label: approvalStatusString.split(':')[1],
              value: approvalStatusString.split(':')[0],
            })) as IWriteOffStatusOption[])
          : [],
    });
  }, [searchParams]);

  const approvalStatuses = searchParams.get('approvalStatuses');

  useEffect(() => {
    if (checkIfUserIsApproverResponse && checkIfUserIsApproverResponse.data.isApprover && approvalStatuses === null) {
      setSearchParams(
        (prev) => ({
          ...prev,
          approvalStatuses: 'PENDING:Pending',
        }),
        {
          replace: true,
        },
      );
    }
  }, [setSearchParams, approvalStatuses, checkIfUserIsApproverResponse]);

  const detailsDivElementRef = useRef<HTMLDivElement>(null);

  const handleFilterSubmission = useCallback((filters: Partial<IWriteOffFilter>) => {
    setWriteOffFilters(filters);
  }, []);
  const { mutate: sendMail, isPending } = useSendApprovalMail({
    customConfig: {
      onSuccess: (data: IWriteOffSummaryResponse) => {
        if (parseInt(data?.invoiceCount) > 0) {
          toast({
            description: 'Sent mail successfully',
          });
        } else {
          toast({
            variant: 'destructive',
            description: 'No pending invoices to be approved',
          });
        }
      },
    },
  });
  if (isLoading) {
    return (
      <div className="w-full h-full flex flex-1 justify-center items-center">
        <Loader2Icon className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    toast({
      variant: 'destructive',
      description: error.message || 'Some error occurred',
    });
  }

  const WriteOffDetailSummary = () => {
    const { data: writeOffSummaryResponse } = useGetAllWriteOffSummary({
      requestData,
    });

    if (writeOffSummaryResponse) {
      return (
        <>
          {writeOffSummaryResponse && (
            <h2 className=" text-lg font-semibold   ">
              Write-Offs{' '}
              <span className=" text-sm font-normal ">
                (Count: {writeOffSummaryResponse?.data?.invoiceCount ?? 0}, Invoice Total:{' '}
                {formatCurrencyByUnit(writeOffSummaryResponse?.data?.invoiceAmount || 0, 'actual')}, Write-Off Total:{' '}
                {formatCurrencyByUnit(writeOffSummaryResponse?.data?.writeOffTotalAmount || 0, 'actual')})
              </span>
            </h2>
          )}
        </>
      );
    } else {
      return (
        <>
          <h2 className="   ">
            <div className="flex gap-2 mb-2">
              <span className=" text-lg font-semibold  ">Write-Offs </span>
              <Skeleton className=" h-4 w-[400px] mt-[6px]" />
            </div>
          </h2>
        </>
      );
    }
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    sendMail({ request: requestData });
  };

  return (
    <Layout className=" flex-1 flex flex-col ">
      <div className="flex justify-between border-b mb-6">
        <WriteOffDetailSummary />
        <div ref={detailsDivElementRef} className="flex gap-2 mb-2">
          <Button variant="outline" onClick={handleClick} disabled={isPending}>
            Send Mail
          </Button>
          {isPending && <Loader2Icon className="w-6 h-6 mt-[5px] animate-spin" />}
        </div>
      </div>
      <WriteOffFilters filters={writeOffFilters} handleSubmit={handleFilterSubmission} />
      <WriteOffsTable
        divRef={detailsDivElementRef}
        filters={writeOffFilters}
        isApprover={checkIfUserIsApproverResponse?.data.isApprover}
      />
    </Layout>
  );
};

export default ManageWriteOffsPage;
