import ComboboxComponent from '@/components/ui/combobox-component';
import { Input } from '@/components/ui/input';
import SelectComponent from '@/components/ui/select-component';
import { cn } from '@/lib/utils';
import { IDateCondition } from '@/types/workflow.type';
import { inputTypeMap, invoiceValueLabelMap } from '@/utils/type-map';
import { validatePositiveNumber } from '@/utils/validatePositiveNumber';
import { Dispatch, SetStateAction, useMemo } from 'react';

const DateValueInput = ({
  dateCondition,
  setDateCondition,
}: {
  dateCondition: IDateCondition;
  setDateCondition: Dispatch<SetStateAction<IDateCondition>>;
}) => {
  const handleRelativeDateChange = (value: string) => {
    setDateCondition((prev) => ({ ...prev, relativeDate: value as 'today' | 'nDaysAgo' | 'nDaysLater' }));
  };

  const relativeDate = useMemo(() => {
    return dateCondition.relativeDate;
  }, [dateCondition.relativeDate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateCondition({
      ...dateCondition,
      waitTillDelta: Number(e.target.value),
    });
  };

  const inputValue = useMemo(() => {
    return String(dateCondition.waitTillDelta);
  }, [dateCondition]);

  return (
    <div className=" flex items-center gap-2 flex-wrap ">
      {relativeDate !== 'today' && (
        <Input
          className=" max-w-[100px] "
          placeholder="Enter value"
          type="number"
          value={inputValue || ''}
          onChange={handleInputChange}
        />
      )}
      <div>
        <SelectComponent
          value={relativeDate}
          placeholder="Select relative date"
          onChange={handleRelativeDateChange}
          options={[
            {
              label: 'today',
              value: 'today',
            },
            {
              label: '"n" days ago',
              value: 'nDaysAgo',
            },
            {
              label: '"n" days later',
              value: 'nDaysLater',
            },
          ]}
        />
      </div>
    </div>
  );
};

const StatementItem = ({
  dateCondition,
  setDateCondition,
}: {
  dateCondition: IDateCondition;
  setDateCondition: React.Dispatch<React.SetStateAction<IDateCondition>>;
}) => {
  const variableOptions = useMemo(() => {
    return Object.entries(invoiceValueLabelMap)
      .filter(([value]) => inputTypeMap[value] === 'DATE')
      .map(([value, label]) => ({ value: `invoice.${value}`, label }));
  }, []);

  const handlePlaceHolderChange = (value: string) => {
    setDateCondition((prev) => ({ ...prev, waitTillPlaceholder: value }));
  };

  return (
    <div className={cn(' flex flex-col  gap-2  items-start ')}>
      <div>
        <ComboboxComponent
          className=" w-full min-w-[200px] flex-1 "
          options={variableOptions}
          placeholder="Select variable"
          onChange={handlePlaceHolderChange}
          value={{
            label:
              invoiceValueLabelMap[
                dateCondition.waitTillPlaceholder?.split('.')[1] as keyof typeof invoiceValueLabelMap
              ],
            value: dateCondition.waitTillPlaceholder || '',
          }}
        />
      </div>
      <div>
        <ComboboxComponent
          className=" w-full min-w-[200px] flex-1 "
          placeholder="Select operator"
          options={[
            {
              label: 'is',
              value: '==',
            },
          ]}
          value={{
            label: 'is',
            value: '==',
          }}
        />
      </div>
      <div>
        <DateValueInput dateCondition={dateCondition} setDateCondition={setDateCondition} />
      </div>
    </div>
  );
};

const EditDateCondition = ({
  dateCondition,
  setDateCondition,
}: {
  dateCondition: IDateCondition;
  setDateCondition: Dispatch<SetStateAction<IDateCondition>>;
}) => {
  const handleConditionTypeChange = (value: string) => {
    const conditionTypeValue = value as 'wait-for' | 'wait-till';

    if (conditionTypeValue === 'wait-for') {
      setDateCondition((prev) => ({
        ...prev,
        conditionType: conditionTypeValue,
        waitFor: 1,
        waitTillDelta: undefined,
        waitTillPlaceholder: undefined,
        waitTillValue: undefined,
      }));
    } else {
      setDateCondition((prev) => ({
        ...prev,
        conditionType: conditionTypeValue,
        waitFor: undefined,
        waitTillDelta: 0,
        waitTillPlaceholder: undefined,
        waitTillValue: undefined,
      }));
    }
  };

  return (
    <div className="  flex flex-col gap-4 flex-1 px-2 py-2">
      <SelectComponent
        value={dateCondition.conditionType}
        className="w-full"
        onChange={handleConditionTypeChange}
        options={[
          {
            label: 'Wait till condition',
            value: 'wait-till',
          },
          {
            label: 'Wait for condition',
            value: 'wait-for',
          },
        ]}
      />
      {dateCondition.conditionType === 'wait-till' && (
        <div className=" flex flex-col flex-1 ">
          <StatementItem dateCondition={dateCondition} setDateCondition={setDateCondition} />
        </div>
      )}
      {dateCondition.conditionType === 'wait-for' && (
        <div className=" flex flex-col gap-2 ">
          <h2>Wait for</h2>
          <div className=" flex items-center gap-2 ">
            <Input
              className=" w-[100px] "
              onChange={(e) =>
                setDateCondition((prev) => ({
                  ...prev,
                  waitFor: Number(e.target.value),
                }))
              }
              type="number"
              value={dateCondition.waitFor}
            />
            days
          </div>
          <p className=" text-xs text-destructive ">{validatePositiveNumber(dateCondition.waitFor || 0)}</p>
        </div>
      )}
    </div>
  );
};

export default EditDateCondition;
