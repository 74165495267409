import { Card, CardContent } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Handle, Position } from 'reactflow';

const LoadingBlock = () => {
  return (
    <>
      <div>
        <h3 className=" text-muted-foreground/80 text-sm mb-1 ">Loading...</h3>
        <Handle id="default" type="target" position={Position.Top} />
        <Card className=" rounded-md  ">
          <CardContent className=" py-4 w-[350px] text-sm ">
            <div className=" mb-4 ">
              {new Array(2).fill(0).map((_, i) => (
                <div key={i} className=" flex items-center gap-2 border-l-4 px-2 border-muted-foreground/20 ">
                  <div className=" bg-muted-foreground/10 p-2 rounded-md ">
                    <Skeleton className=" h-8 w-8 " />
                  </div>
                  <div className=" w-ful flex flex-col gap-1 flex-1 ">
                    <div className=" w-full flex items-end gap-2 ">
                      <Skeleton className=" w-[80px] h-4 " />
                      <Skeleton className="flex-1 h-4" />
                    </div>
                    <div className=" w-full flex items-start gap-2">
                      <Skeleton className=" w-[80px] h-4 " />
                      <Skeleton className="flex-1 h-4" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
        <Handle id="default" type="source" position={Position.Bottom} />
      </div>
    </>
  );
};

export default LoadingBlock;
