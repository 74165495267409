import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeaderWithCTA, CardTitle } from '@/components/ui/card';
import { useGetAttachmentsWithFilters } from '@/hooks/api-hooks/useAttachmentsQuery';
import { IAttachmentsFilter, IAttachmentsObject } from '@/types/attachments.types';
import dayjs from 'dayjs';
import { PlusIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Empty from '../ui/empty';
import FilterChip from '../ui/filterChip';
import AttachmentRow from './AttachmentRows';
import AttachmentFilters from './AttachmentsFilter';
import { AddAttachments } from './customer-search/AddAttachments';

const EditAttachments = ({ type, id }: { type: string; id: string }) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [attachmentsFilters, setAttachmentsFilters] = useState<Partial<IAttachmentsFilter>>({});
  const [attachmentRows, setAttachmentRows] = useState<IAttachmentsObject[]>();
  const [focusField, setFocusField] = useState<string>('');
  const [open, setOpen] = useState(false);

  const handleSubmit = useCallback(
    (filters: Partial<IAttachmentsFilter>) => {
      setAttachmentsFilters(filters);
    },
    [setAttachmentsFilters],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [__, setSearchParams] = useSearchParams();

  const handleSingleFilterReset = (filterType: string) => {
    const updatedFilters = { ...attachmentsFilters };

    if (filterType == 'filename') {
      updatedFilters.filename = '';
      setSearchParams(
        (prev) => {
          prev.delete('filename');
          return prev;
        },
        {
          replace: true,
        },
      );
    } else if (filterType == 'filetype') {
      updatedFilters.filetype = [];
      setSearchParams(
        (prev) => {
          prev.delete('filetype');
          return prev;
        },
        {
          replace: true,
        },
      );
    } else if (filterType == 'uploadedOn') {
      updatedFilters.uploadedOn = undefined;
      setSearchParams(
        (prev) => {
          prev.delete('uploadedOn');
          return prev;
        },
        {
          replace: true,
        },
      );
    } else if (filterType == 'uploadedBy') {
      updatedFilters.uploadedBy = [];
      setSearchParams(
        (prev) => {
          prev.delete('uploadedBy');
          return prev;
        },
        {
          replace: true,
        },
      );
    }

    setAttachmentsFilters(updatedFilters);
  };
  const handleSingleFilterClick = (filterType: string) => {
    if (['filename', 'filetype', 'uploadedOn', 'uploadedBy'].includes(filterType)) {
      setFocusField(filterType);
    }
    setIsFilterModalOpen(true);
  };

  const { data: customerAttachmentResponse, isLoading } = useGetAttachmentsWithFilters({
    ...attachmentsFilters,
    referenceObjectId: id,
    referenceObjectType: type,
  });

  useEffect(() => {
    customerAttachmentResponse && customerAttachmentResponse.data && setAttachmentRows(customerAttachmentResponse.data);
  }, [customerAttachmentResponse]);

  const handleFilters = () => {
    setIsFilterModalOpen(true);
  };
  const handleAddAttachments = () => {
    setIsUploadModalOpen(true);
    setOpen(true);
  };

  const DivHead = ({ children }: { children: React.ReactNode }) => {
    return <div className=" font-semibold  px-6 flex-1 min-w-[180px] py-2 bg-gray-100 ">{children}</div>;
  };
  if (isLoading || !attachmentRows) {
    return <Empty title="No attachments found" />;
  }
  const handleReset = () => {
    setAttachmentsFilters({});
    setSearchParams(
      (prev) => {
        prev.delete('filename');
        prev.delete('filetype');
        prev.delete('uploadedOn');
        prev.delete('uploadedBy');
        return prev;
      },
      {
        replace: true,
      },
    );
  };

  function getCountFiltersApplied(attachmentsFilters: Partial<IAttachmentsFilter>): number {
    let count = 0;

    if (attachmentsFilters.filename && attachmentsFilters.filename !== '') {
      count++;
    }

    if (attachmentsFilters.filetype && attachmentsFilters.filetype.length !== 0) {
      count++;
    }

    if (attachmentsFilters.uploadedBy && attachmentsFilters.uploadedBy.length !== 0) {
      count++;
    }

    if (attachmentsFilters.uploadedOn !== undefined) {
      count++;
    }
    return count;
  }
  const countFiltersApplied = getCountFiltersApplied(attachmentsFilters);
  const renderFilterChips = () => {
    return Object.keys(attachmentsFilters).map((key) => {
      let value = attachmentsFilters[key as keyof IAttachmentsFilter];

      if (value == undefined) {
        return <></>;
      }
      if (Array.isArray(value)) {
        value = value.map((item) => (item.label ? item.label : JSON.stringify(item))).join(', ');
      } else if (value instanceof Date) {
        value = dayjs(value).format('DD MMM YYYY');
      } else if (typeof value === 'object') {
        value = JSON.stringify(value);
      } else {
        value = String(value);
      }
      const filterLabel =
        key === 'filename'
          ? 'File'
          : key == 'uploadedBy'
            ? 'Uploaded by'
            : key == 'uploadedOn'
              ? 'Uploaded On'
              : key == 'filetype'
                ? 'Type'
                : '';
      const filterType = key;
      if (value != '' && value != undefined) {
        return (
          <FilterChip
            key={key}
            filterType={filterType}
            filterLabel={filterLabel}
            filtervalue={value}
            handleReset={handleSingleFilterReset}
            handleClick={handleSingleFilterClick}
          />
        );
      }
    });
  };
  return (
    <Card id="attachments" className=" my-8 ">
      <CardHeaderWithCTA
        className=" border-b "
        cardTitle={<CardTitle className=" underline font-semibold ">Attachments</CardTitle>}
        cta={
          <Button onClick={handleAddAttachments} className="flex gap-4 items-center">
            Add Attachments
            <PlusIcon className=" w-4 h-4 " />
          </Button>
        }
      />
      <CardContent className=" flex flex-col gap-4 p-6">
        <div className="flex flex-col gap-4 text-sm ">
          <div>
            <div>
              <div className="gap-4 flex  ml-2">
                <div className="flex">
                  <Button onClick={handleFilters} variant="outline">
                    Filters
                  </Button>
                  {countFiltersApplied > 0 && (
                    <div className="w-4 h-4 bg-black text-white rounded-xl ml-[-7px] mt-[-7px]">
                      <div className="text-center mb-auto mt-auto text-xs">{countFiltersApplied}</div>
                    </div>
                  )}
                </div>
                {((attachmentsFilters.filename && attachmentsFilters.filename != '') ||
                  (attachmentsFilters.filetype && attachmentsFilters.filetype?.length != 0) ||
                  (attachmentsFilters.uploadedBy && attachmentsFilters.uploadedBy?.length != 0) ||
                  attachmentsFilters.uploadedOn != undefined) && (
                  <Button onClick={handleReset} variant="ghost">
                    Reset
                  </Button>
                )}
                <div className="overflow-auto flex gap-4">{renderFilterChips()}</div>
              </div>

              <AttachmentFilters
                handleSubmit={handleSubmit}
                isFilterModalOpen={isFilterModalOpen}
                setIsFilterModalOpen={setIsFilterModalOpen}
                attachmentFilters={attachmentsFilters}
                focusField={focusField}
              />
            </div>
          </div>

          <AddAttachments
            isUploadModalOpen={isUploadModalOpen}
            open={open}
            setOpen={setOpen}
            referenceObjectId={id}
            referenceObjectType={type}
          />
          {attachmentRows && attachmentRows.length > 0 ? (
            <div className="  overflow-x-scroll ">
              <div className="  overflow-x-scroll ">
                <div className=" bg-gray-100 flex justify-between  ">
                  <DivHead>File</DivHead>
                  <DivHead>Purpose</DivHead>
                  <DivHead>Uploaded By</DivHead>
                  <DivHead>Action</DivHead>
                </div>
                {attachmentRows.map((attachment) => (
                  <AttachmentRow attachmentDetails={attachment} key={attachment.id} />
                ))}
              </div>
            </div>
          ) : (
            <div className="items-center ml-auto mr-auto ">No attachments found</div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default EditAttachments;
