import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import { IAdjustMents } from '@/types/adjustment.types';
import { CardContent } from '@mui/material';
import AdjustmentsTable from './AdjustmentsTable';

const Adjustments = ({
  data,
  showDelete = true,
  invoiceNumber,
}: {
  data: IAdjustMents[];
  showDelete?: boolean;
  invoiceNumber: string;
}) => {
  return (
    <div>
      <Card className=" my-8">
        <CardHeader className="border-b">
          <CardTitle className=" underline font-semibold ">Adjustments ({data.length})</CardTitle>
        </CardHeader>
        <CardContent>
          {data.length > 0 && <AdjustmentsTable showDelete={showDelete} data={data} invoiceNumber={invoiceNumber} />}
          {data.length === 0 && <p className=" py-4 text-muted-foreground text-sm text-center ">No postings</p>}
        </CardContent>
      </Card>
    </div>
  );
};

export default Adjustments;
