import InvoicesTable from '@/components/invoices/InvoicesTable';
import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import { IInvoicesFilter } from '@/types/invoices.types';
import { CardContent } from '@mui/material';

const InvoiceDetails = ({
  resetFilters,
  filterLabel,
  invoiceFilters,
}: {
  resetFilters: () => void;
  filterLabel: string;
  invoiceFilters: Partial<IInvoicesFilter>;
}) => {
  return (
    <Card className=" my-8 ">
      <CardHeader className=" border-b ">
        <CardTitle className=" underline font-semibold ">Invoice details</CardTitle>
      </CardHeader>
      <CardContent id="invoice-details" className=" flex flex-col gap-4 ">
        <InvoicesTable
          resetFilters={resetFilters}
          filterLabel={filterLabel}
          showInTableFilters
          filters={invoiceFilters}
          showSummary={false}
        />
      </CardContent>
    </Card>
  );
};

export default InvoiceDetails;
