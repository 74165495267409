import { EditCustomerAlias, EditEmail, EditPhoneNumber } from '@/components/customers/CustomerEditComponents';
import ContactDetails from '@/components/customers/EditContacts';
import OwnerDetails from '@/components/customers/EditOwners';
import TagsDetails from '@/components/customers/EditTagsComponent';
import InvoicesTable from '@/components/invoices/InvoicesTable';
import { LoadingLabelValueDescriptionLayout } from '@/components/shared/LabelValueDescLayout';
import { Card, CardHeader } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { useGetCustomerById, useGetInvoicesSummaryByCustomerId } from '@/hooks/api-hooks/useCustomerQuery';
import { IInvoicesFilter } from '@/types/invoices.types';
import { CardContent } from '@mui/material';
import { MailIcon, PhoneIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { CreditTermsDetails } from '@/components/customers/CreditTermsDetails';
import CustomerOtherDetails from '@/components/customers/CustomerOtherDetails';
import EditAttachments from '@/components/customers/EditAttachments';
import InvoiceDetails from '@/components/customers/InvoiceDetails';
import CustomerInvoicesAndCreditSummary from '@/components/customers/InvoiceSummary';
import ShareUpiQR from '@/components/upi-qr/ShareUpiQR';

const ViewCustomerPage = () => {
  const { customerId } = useParams();
  const [invoiceFilters, setInvoiceFilters] = useState<Partial<IInvoicesFilter>>({
    customerIds: customerId as string,
  });
  const [filterLabel, setFilterLabel] = useState<string>('');

  const {
    data: customerData,
    isError,
    isLoading,
  } = useGetCustomerById({
    customerId: customerId as string,
    customConfig: {
      enabled: !!customerId,
    },
  });

  const { data: invoiceSummary, isLoading: isInvoiceSummaryLoading } = useGetInvoicesSummaryByCustomerId({
    customerId: customerId as string,
    customConfig: {
      enabled: !!customerId,
    },
  });

  const setFiltersWithCustomerId = (filters: Partial<IInvoicesFilter>, filterLabel: string) => {
    setFilterLabel(filterLabel);
    setInvoiceFilters({
      ...filters,
      customerIds: customerId,
    });
  };

  const resetFilters = () => {
    setFiltersWithCustomerId({} as Partial<IInvoicesFilter>, '');
  };

  useEffect(() => {
    setInvoiceFilters((prev) => ({
      ...prev,
      customerIds: customerId as string,
    }));
  }, [customerId]);

  const customer = customerData?.data;

  const tags = useMemo(() => {
    if (customer?.tags) {
      return Object.entries(customer.tags).map((item) => ({
        name: item[0],
        value: item[1],
        id: item[0],
      }));
    }
    return [];
  }, [customer]);

  const contacts = useMemo(() => {
    if (customer?.contactsData) {
      return customer.contactsData;
    }
    return [];
  }, [customer]);

  const owners = useMemo(() => {
    if (customer?.ownersData) {
      return customer.ownersData;
    }
    return [];
  }, [customer]);

  if (isLoading) {
    return (
      <div className="sm:px-16 sm:py-8 px-4 py-8">
        <div className=" py-2 border-b my-2 ">
          <div className=" text-xl font-semibold mb-4 flex gap-8 items-center ">
            <Skeleton className=" w-96 h-6" />
          </div>
          <div className=" flex items-center gap-4 ">
            <Skeleton className="w-32 h-6" />
            <Skeleton className="w-32 h-6" />
          </div>
        </div>
        <CustomerInvoicesAndCreditSummary
          customerName={customer?.name || ''}
          setFilters={setFiltersWithCustomerId}
          customerId={customerId as string}
          invoiceSummary={invoiceSummary}
          isInvoiceSummaryLoading={isInvoiceSummaryLoading}
        />
        <Card className=" my-8 ">
          <CardHeader className=" border-b ">
            <Skeleton className="w-32 h-5" />
          </CardHeader>
          <CardContent className=" flex flex-col gap-4 ">
            <InvoicesTable
              filters={{
                customerIds: customerId as string,
              }}
            />
          </CardContent>
        </Card>
        <Card className=" text-sm my-8">
          <CardHeader className="border-b">
            <Skeleton className="w-32 h-5" />
          </CardHeader>
          <CardContent className=" flex flex-col gap-4">
            {new Array(4).fill(0).map((_, i) => (
              <LoadingLabelValueDescriptionLayout key={i} />
            ))}
          </CardContent>
        </Card>
      </div>
    );
  }

  if (isError || !customer || !customerId) {
    return <Navigate to="/404" replace />;
  }

  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8">
      <div className="  mb-8 border-b  pb-6">
        <EditCustomerAlias
          className=" font-semibold text-xl "
          customerId={customerId as string}
          defaultValue={customer.alias}
        />
        <div className=" flex justify-between text-sm gap-8 px-4">
          <div className="flex items-center gap-8">
            <div className=" flex items-center gap-2 ">
              <PhoneIcon className=" w-4 h-4 relative top-0.5 " />
              <div className=" h-7 pt-[3px] ">
                <EditPhoneNumber customerId={customerId} defaultValue={customer.primaryPhone} />
              </div>
            </div>
            <div>
              <div className=" flex items-center gap-2 ">
                <MailIcon className=" w-4 h-4 relative top-0.5 " />
                <div className=" h-7  pt-[2px]">
                  <EditEmail customerId={customerId} defaultValue={customer.primaryEmail} />
                </div>
              </div>
            </div>
          </div>
          <ShareUpiQR
            customer={customer}
            dueAmount={invoiceSummary?.data.due.amount}
            overdueAmount={invoiceSummary?.data.overdue.amount}
          />
        </div>
      </div>
      <CustomerInvoicesAndCreditSummary
        customerName={customer.name}
        setFilters={setFiltersWithCustomerId}
        customerId={customerId as string}
        invoiceSummary={invoiceSummary}
        isInvoiceSummaryLoading={isInvoiceSummaryLoading}
      />
      <EditAttachments id={customer?.id} type={'CUSTOMER'} />
      <CreditTermsDetails customerId={customerId as string} />
      <TagsDetails customerId={customerId as string} tagDetails={tags} />
      <ContactDetails customerId={customerId as string} contactDetails={contacts} />
      <OwnerDetails customerId={customerId as string} ownerDetails={owners} />
      <InvoiceDetails filterLabel={filterLabel} invoiceFilters={invoiceFilters} resetFilters={resetFilters} />
      <CustomerOtherDetails customer={customer} customerId={customerId} />
    </div>
  );
};

export default ViewCustomerPage;
