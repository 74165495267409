import { dataAxiosInstance } from '@/lib/axios';
import { IAuthResponse } from '@/types/auth.types';

export async function fetchAuth(): Promise<IAuthResponse> {
  const response = await dataAxiosInstance.get(`/user/authenticate`);
  return response.data;
}

export const signInWithPassword = async ({ email, password }: { email: string; password: string }) => {
  const result = await dataAxiosInstance.post(`/user/login`, {
    email,
    password,
  });

  return result.data;
};

export const signUp = async ({
  invitationCode,
  firstName,
  lastName,
  password,
  pan,
}: {
  invitationCode: string;
  firstName?: string;
  lastName?: string;
  password: string;
  pan?: string;
}) => {
  if (!pan && (!firstName || !lastName)) {
    throw new Error('Invalid data');
  }

  const result = await dataAxiosInstance.post('/user/register', {
    invitationCode,
    firstName,
    lastName,
    password,
    pan,
  });
  return result.data;
};

export const validateInvite = async (inviteCode: string) => {
  const result = await dataAxiosInstance.get(`/invitation/validate?inviteCode=${inviteCode}`);
  return result.data;
};

export const fetchProfileData = async () => {
  const result = await dataAxiosInstance.get('/user/details');
  return result.data;
};

export const signout = async () => {
  const result = await dataAxiosInstance.post('/user/logout', {});
  return result.data;
};

export const getPanDetails = async ({ pan, inviteCode }: { pan: string; inviteCode: string }) => {
  const result = await dataAxiosInstance.get(`/user/panDetails?pan=${pan}&invitationCode=${inviteCode}`);
  return result.data;
};
//api for switching company
export const switchCompany = async ({ companyId }: { companyId: string }) => {
  const result = await dataAxiosInstance.post('/user/changeCompany', {
    companyId,
  });
  return result.data;
};
