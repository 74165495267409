import { IState, StateTypes } from '@/types/workflow.type';
import Dagre from '@dagrejs/dagre';
import { Edge, Node } from 'reactflow';

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

export const getLayoutedElements = (
  nodes: Node<IState<StateTypes>>[],
  edges: Edge<unknown>[],
  options: { [key: string]: string },
) => {
  g.setGraph({ rankdir: options.direction, edgesep: 100, ranksep: 180 });

  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  // @ts-expect-error inconsistency in types
  nodes.forEach((node) => g.setNode(node.id, node));

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const position = g.node(node.id);

      const x = 100;

      let y = position.y;
      if (node.height) {
        y = y - node.height / 2;
      }

      return { ...node, position: { x, y } };
    }),
    edges,
  };
};

export const getLayoutedElementsOnClick = (
  nodes: Node<IState<StateTypes>>[],
  edges: Edge<unknown>[],
  options: { [key: string]: string },
) => {
  g.setGraph({ rankdir: options.direction, edgesep: 100, ranksep: 50 });

  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  // @ts-expect-error inconsistency in types
  nodes.forEach((node) => g.setNode(node.id, node));

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const position = g.node(node.id);

      const x = 100;

      let y = position.y;
      if (node.height) {
        y = y - node.height / 2;
      }

      return { ...node, position: { x, y } };
    }),
    edges,
  };
};
