import { getUserDetails, postAttachPanToUser } from '@/api-functions/user.api';
import { IAttachPanResponse, IProfileDataResponse } from '@/types/auth.types';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const UserQueryKeys = {
  getUserDetailsKey: () => ['getBankAccountQuery'],
};

export const useGetUserDetails = ({
  customConfig,
}: {
  customConfig?: Omit<UseQueryOptions<IProfileDataResponse>, 'queryKey'>;
}) => {
  const getActualDetailsQuery = useQuery<IProfileDataResponse>({
    queryKey: UserQueryKeys.getUserDetailsKey(),
    queryFn: getUserDetails,
    ...customConfig,
  });

  return getActualDetailsQuery;
};

export const usePostAttachPan = ({
  customConfig,
}: {
  customConfig: UseMutationOptions<IAttachPanResponse, AxiosError<IAttachPanResponse>, { pan: string }>;
}) => {
  const postAttachPanMutation = useMutation({
    mutationFn: ({ pan }: { pan: string }) => postAttachPanToUser({ pan }),
    ...customConfig,
  });

  return postAttachPanMutation;
};
