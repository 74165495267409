export const validatePositiveNumber = (value: number) => {
  if (isNaN(Number(value))) {
    return 'Invalid value';
  }

  if (value < 0) {
    return 'Days cannot be negative';
  }

  if (value === 0) {
    return 'Days cannot be zero';
  }

  return '';
};
