export const BANKTRANSACTIONS_TABLE_COLUMNS_VISIBILITY_KEY = 'transactionsColumnsVisibility';
export const BANKTRANSACTIONS_TABLE_COLUMN_ORDER_KEY = 'transactionsColumnOrder';
export const CREDITS_TABLE_COLUMNS_VISIBILITY_KEY = 'creditsColumnsVisibility';
export const CREDITS_TABLE_COLUMN_ORDER_KEY = 'creditsColumnOrder';
export const INVOICES_LEDGER_TABLE_COLUMNS_VISIBILITY_KEY = 'invoicesLedgerColumnsVisibility';
export const INVOICES_LEDGER_TABLE_COLUMN_ORDER_KEY = 'invoicesLedgerColumnOrder';
export const WRITE_OFFS_TABLE_COLUMNS_VISIBILITY_KEY = 'writeOffsColumnsVisibility';
export const WRITE_OFFS_TABLE_COLUMN_ORDER_KEY = 'writeOffsColumnOrder';
export const CUSTOMER_COLUMN_ORDER_STORAGE_KEY = 'customersColumnOrder';
export const CUSTOMER_COLUMN_VISIBILITY_STORAGE_KEY = 'customerColumnVisibility';
export const INVOICE_COLUMN_ORDER_STORAGE_KEY = 'invoicesColumnOrder';
export const WORKFLOW_DRY_RUN_COLUMN_ORDER_STORAGE_KEY = 'wfDryRunColumnOrder';
export const INVOICE_COLUMN_VISIBILITY_STORAGE_KEY = 'invoicesColumnVisibility';
export const WORKFLOW_DRY_COLUMN_VISIBILITY_STORAGE_KEY = 'wfDryRunColumnVisibility';
export const POSTING_ALL_INVOICES_COLUMN_ORDER_STORAGE_KEY = 'postingInvoiceColumnOrder';
export const POSTING_ALL_INVOICES_COLUMN_VISIBILITY_STORAGE_KEY = 'postingInvoiceColumnVisibility';
export const POSTING_SINGLE_CREDIT_VIEW_INVOICE_TABLE_COLUMN_ORDER_STORAGE_KEY = 'creditInvoiceColumnOrder';
export const POSTING_SINGLE_CREDIT_VIEW_INVOICE_TABLE_COLUMN_VISIBILITY_STORAGE_KEY = 'creditInvoiceColumnVisibility';
export const POSTING_ALL_CREDITS_COLUMN_ORDER_STORAGE_KEY = 'postingCreditColumnOrder';
export const POSTING_ALL_CREDITS_COLUMN_VISIBILITY_STORAGE_KEY = 'postingCreditColumnVisibility';
export const POSTING_SELECTED_INVOICE_VIEW_CREDITS_COLUMN_ORDER_STORAGE_KEY = 'selectedInvoiceCreditColumnOrder';
export const POSTING_SELECTED_INVOICE_VIEW_CREDITS_COLUMN_VISIBILITY_STORAGE_KEY =
  'selectedInvoiceCreditColumnVisibility';
export const CREDIT_NOTES_LIST_VIEW_COLUMN_ORDER_STORAGE_KEY = 'creditNotesListViewColumnOrder';
export const CREDIT_NOTES_LIST_VIEW_COLUMN_VISIBILITY_STORAGE_KEY = 'creditNotesListViewColumnVisibility';
