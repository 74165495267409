import {
  approveWriteOff,
  cancelWriteOff,
  checkIfUserIsApprover,
  createWriteOff,
  getAllWriteOffs,
  getAllWriteOffsSummary,
  getRequesterSuggestions,
  getWriteOffByInvoiceId,
  rejectWriteOff,
  sendMailForWriteOff,
} from '@/api-functions/write-off.api';
import { IApiResponse } from '@/types/common.types';
import {
  IAllWriteOffResponse,
  IApproveRejectResponse,
  IGetAllWriteOffRequest,
  IRequesterSuggestionsResponse,
  IWriteOff,
  IWriteOffActionList,
  IWriteOffApprover,
  IWriteOffSummaryResponse,
  WriteOffApprovalStatus,
} from '@/types/write-off.types';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

const writeOffQueryKeys = {
  getWriteOffByInvoiceId: (invoiceId: string, approvalStatusList: WriteOffApprovalStatus[]) => [
    'getWriteOffByInvoiceId',
    invoiceId,
    approvalStatusList,
  ],
  getAllWriteOff: (requestData: IGetAllWriteOffRequest) => ['getAllWriteOff', requestData],
  getAllWriteOffSummary: (requestData: IGetAllWriteOffRequest) => ['getAllWriteOffSummary', requestData],
  checkIfUserIsApprover: () => ['checkIfUserIsApprover'],
};

export const useGetWriteOffByInvoiceId = ({
  invoiceId,
  approvalStatusList,
  customConfig,
}: {
  invoiceId: string;
  approvalStatusList: WriteOffApprovalStatus[];
  customConfig?: Omit<UseQueryOptions<IApiResponse<IWriteOff[]>>, 'queryKey'>;
}) => {
  const getWriteOffByInvoiceIdQuery = useQuery<IApiResponse<IWriteOff[]>>({
    queryKey: writeOffQueryKeys.getWriteOffByInvoiceId(invoiceId, approvalStatusList),
    queryFn: () =>
      getWriteOffByInvoiceId({
        invoiceId,
        approvalStatusList,
      }),
    ...customConfig,
  });

  return getWriteOffByInvoiceIdQuery;
};

export const useCreateWriteOffMutation = ({
  invoiceId,
  approvalStatusList,
  customConfig,
}: {
  invoiceId: string;
  approvalStatusList: WriteOffApprovalStatus[];
  customConfig: UseMutationOptions<
    AxiosResponse<IApiResponse<null>>,
    AxiosError<IApiResponse<null>>,
    {
      amount: number;
      comment: string;
      invoiceId: string;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postCreateWriteOffMutation = useMutation<
    AxiosResponse<IApiResponse<null>>,
    AxiosError<IApiResponse<null>>,
    { amount: number; comment: string; invoiceId: string }
  >({
    mutationFn: createWriteOff,
    ...customConfig,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['getInvoiceById', invoiceId],
      });
    },
    onSettled(data, error, variables, context) {
      queryClient.invalidateQueries({
        queryKey: writeOffQueryKeys.getWriteOffByInvoiceId(invoiceId, approvalStatusList),
      });
      customConfig.onSettled?.(data, error, variables, context);
    },
  });

  return postCreateWriteOffMutation;
};

export const useCancelWriteOffFromInvoicePageMutation = ({
  invoiceId,
  approvalStatusList,
  customConfig,
}: {
  invoiceId: string;
  approvalStatusList: WriteOffApprovalStatus[];
  customConfig: UseMutationOptions<
    unknown,
    AxiosError<IApiResponse<null>>,
    {
      requests: IWriteOffActionList[];
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postCancelWriteOffMutation = useMutation<
    unknown,
    AxiosError<IApiResponse<null>>,
    { requests: IWriteOffActionList[] }
  >({
    mutationFn: cancelWriteOff,
    ...customConfig,
    onSettled(data, error, variables, context) {
      queryClient.invalidateQueries({
        queryKey: writeOffQueryKeys.getWriteOffByInvoiceId(invoiceId, approvalStatusList),
      });
      customConfig.onSettled?.(data, error, variables, context);
    },
  });

  return postCancelWriteOffMutation;
};

export const useGetAllWriteOff = ({
  requestData,
  customConfig,
}: {
  requestData: IGetAllWriteOffRequest;
  customConfig?: Omit<UseQueryOptions<IApiResponse<IAllWriteOffResponse>>, 'queryKey'>;
}) => {
  const getAllWriteOffQuery = useQuery<IApiResponse<IAllWriteOffResponse>>({
    queryKey: writeOffQueryKeys.getAllWriteOff(requestData),
    queryFn: () => getAllWriteOffs(requestData),
    ...customConfig,
  });

  return getAllWriteOffQuery;
};

export const useApproveWriteOffMutation = ({
  requestData,
  customConfig,
}: {
  requestData: IGetAllWriteOffRequest;
  customConfig: UseMutationOptions<
    unknown,
    AxiosError<IApiResponse<IApproveRejectResponse>>,
    {
      requests: IWriteOffActionList[];
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postApproveWriteOffMutation = useMutation<
    unknown,
    AxiosError<IApiResponse<IApproveRejectResponse>>,
    { requests: IWriteOffActionList[] }
  >({
    mutationFn: approveWriteOff,
    ...customConfig,
    onSettled(data, error, variables, context) {
      queryClient.invalidateQueries({
        queryKey: writeOffQueryKeys.getAllWriteOff(requestData),
      });
      customConfig.onSettled?.(data, error, variables, context);
    },
  });

  return postApproveWriteOffMutation;
};

export const useRejectWriteOffMutation = ({
  requestData,
  customConfig,
}: {
  requestData: IGetAllWriteOffRequest;
  customConfig: UseMutationOptions<
    unknown,
    AxiosError<IApiResponse<IApproveRejectResponse>>,
    {
      requests: IWriteOffActionList[];
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postRejectWriteOffMutation = useMutation<
    unknown,
    AxiosError<IApiResponse<IApproveRejectResponse>>,
    { requests: IWriteOffActionList[] }
  >({
    mutationFn: rejectWriteOff,
    ...customConfig,
    onSettled(data, error, variables, context) {
      queryClient.invalidateQueries({
        queryKey: writeOffQueryKeys.getAllWriteOff(requestData),
      });
      customConfig.onSettled?.(data, error, variables, context);
    },
  });

  return postRejectWriteOffMutation;
};

export const useCheckIfUserIsApprover = ({
  customConfig,
}: {
  customConfig?: Omit<UseQueryOptions<IApiResponse<IWriteOffApprover>>, 'queryKey'>;
}) => {
  const checkIfUserIsApproverQuery = useQuery<IApiResponse<IWriteOffApprover>>({
    queryKey: writeOffQueryKeys.checkIfUserIsApprover(),
    queryFn: () => checkIfUserIsApprover(),
    ...customConfig,
  });

  return checkIfUserIsApproverQuery;
};

export const useCancelWriteOffFromWriteOffPageMutation = ({
  requestData,
  customConfig,
}: {
  requestData: IGetAllWriteOffRequest;
  customConfig: UseMutationOptions<
    unknown,
    AxiosError<IApiResponse<null>>,
    {
      requests: IWriteOffActionList[];
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postRejectWriteOffMutation = useMutation<
    unknown,
    AxiosError<IApiResponse<null>>,
    { requests: IWriteOffActionList[] }
  >({
    mutationFn: cancelWriteOff,
    ...customConfig,
    onSettled(data, error, variables, context) {
      queryClient.invalidateQueries({
        queryKey: writeOffQueryKeys.getAllWriteOff(requestData),
      });
      customConfig.onSettled?.(data, error, variables, context);
    },
  });

  return postRejectWriteOffMutation;
};

export const useSendApprovalMail = ({
  customConfig = {},
}: {
  customConfig?: UseMutationOptions<
    IWriteOffSummaryResponse,
    AxiosError<IApiResponse<string>>,
    {
      request: IGetAllWriteOffRequest;
    }
  >;
} = {}) => {
  const mutation = useMutation({
    mutationFn: ({ request }: { request: IGetAllWriteOffRequest }) => sendMailForWriteOff(request),
    ...customConfig,
  });

  return mutation;
};

export const useGetAllWriteOffSummary = ({
  requestData,
  customConfig,
}: {
  requestData: IGetAllWriteOffRequest;
  customConfig?: Omit<UseQueryOptions<IApiResponse<IWriteOffSummaryResponse>>, 'queryKey'>;
}) => {
  const getAllWriteOffQuery = useQuery<IApiResponse<IWriteOffSummaryResponse>>({
    queryKey: writeOffQueryKeys.getAllWriteOffSummary(requestData),
    queryFn: () => getAllWriteOffsSummary(requestData),
    ...customConfig,
  });

  return getAllWriteOffQuery;
};

export const useGetRequesterSuggestionsMutation = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<IRequesterSuggestionsResponse, unknown, { query: string }>;
}) => {
  const getRequesterSuggestionsQuery = useMutation<IRequesterSuggestionsResponse, unknown, { query: string }>({
    mutationFn: ({ query }: { query: string }) => getRequesterSuggestions(query),
    ...customConfig,
  });

  return getRequesterSuggestionsQuery;
};
