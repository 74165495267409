import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../../ui/button';
import { DatePickerWithRange } from '../../ui/multi-datepicker';

const CashflowDetailsFilters = ({
  handleSubmit,
  initialDateRange,
}: {
  handleSubmit: (_: { startDate: Date; endDate: Date }) => void;
  initialDateRange: { startDate: Date; endDate: Date };
}) => {
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: initialDateRange?.startDate,
    to: initialDateRange?.endDate,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    if (startDate && endDate) {
      setDateRange({
        from: new Date(startDate),
        to: new Date(endDate),
      });
      handleSubmit({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });
    }
  }, [searchParams, handleSubmit]);

  const handleReset = useCallback(() => {
    setSearchParams(
      {
        startDate: dayjs(initialDateRange.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(initialDateRange.endDate).format('YYYY-MM-DD'),
      },
      {
        replace: true,
      },
    );
    setDateRange({
      from: initialDateRange?.startDate,
      to: initialDateRange?.endDate,
    });
  }, [initialDateRange, setSearchParams]);

  const onSubmit = useCallback(() => {
    if (dateRange?.from && dateRange?.to) {
      setSearchParams(
        {
          startDate: dayjs(dateRange.from).format('YYYY-MM-DD'),
          endDate: dayjs(dateRange.to).format('YYYY-MM-DD'),
        },
        {
          replace: true,
        },
      );
      handleSubmit({
        startDate: new Date(dateRange.from),
        endDate: new Date(dateRange.to),
      });
      return;
    }
  }, [dateRange, handleSubmit, setSearchParams]);

  return (
    <div className="flex gap-4 flex-wrap my-4">
      <DatePickerWithRange value={dateRange} onChange={setDateRange} />
      <Button variant="default" onClick={onSubmit}>
        Apply
      </Button>
      <Button onClick={handleReset} variant="outline">
        Reset
      </Button>
    </div>
  );
};

export default CashflowDetailsFilters;
