import InvoicesPostingTable from '@/components/posting/invoices/InvoicesTable';
import SwitchPostingFlow from '@/components/posting/shared/SwitchPostingFlow';
import { Layout } from '@/components/shared/Layout';

const InvoicesPostingPage = () => {
  return (
    <Layout>
      <SwitchPostingFlow current="available-invoices" />
      <InvoicesPostingTable />
    </Layout>
  );
};

export default InvoicesPostingPage;
