import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Layout } from '../shared/Layout';
import { Button } from '../ui/button';

const Nav = () => {
  return (
    <div className="   border-b ">
      <div className=" flex justify-between py-2 px-4 ">
        <Link to={'/workflow'}>
          <Button variant="ghost" className="flex items-center gap-2">
            <ArrowLeft className="w-4 h-4" />
          </Button>
        </Link>
      </div>
    </div>
  );
};

const WorkflowLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Layout className="sm:px-0 sm:py-0  h-screen overflow-hidden  flex flex-col">
      <Nav />
      {children}
    </Layout>
  );
};

export default WorkflowLayout;
