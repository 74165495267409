import ToolTipCell from '@/components/Table/ToolTipCell';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useDeleteActionById, useDeleteStateById } from '@/hooks/api-hooks/useWorkflowQuery';
import { cn } from '@/lib/utils';
import { useWorkflowPropertiesStore } from '@/stores/workflow/state-properties.store';
import { IGotoActionProperties, IState, StateTypes } from '@/types/workflow.type';
import { autoUpdate, useFloating } from '@floating-ui/react';
import { AlertTriangleIcon, Loader2Icon, Trash2Icon, WaypointsIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Handle, MarkerType, Position, useNodes, useReactFlow, useStore, useUpdateNodeInternals } from 'reactflow';

interface IGotoBlockProps {
  actionId: string;
  properties: IGotoActionProperties;
  stateId: string;
  workflowId: string;
  catIndex: number;
  nodeType: StateTypes;
  warnings: string[];
  setIsGotoActionPresent: React.Dispatch<React.SetStateAction<boolean>>;
}

const colorList = ['#FF005F', '#00C2AE', '#B2AC11', '#C5BE77'];

const GotoActionBlock = (props: IGotoBlockProps) => {
  const { setSelectedProperties, selectedActionId } = useWorkflowPropertiesStore((store) => ({
    setSelectedProperties: store.setSelectedProperties,
    selectedActionId: store.selectedProperties?.actionId,
  }));

  const nodes = useNodes<IState<StateTypes>>();

  const reactflow = useReactFlow();

  const updateNodeInternals = useUpdateNodeInternals();

  const nodeIdToLabelMap = useMemo(() => {
    return nodes.reduce(
      (acc, node) => {
        return {
          ...acc,
          [node.id]: node.data.label,
        };
      },
      {} as Record<string, string>,
    );
  }, [nodes]);

  const { toast } = useToast();

  const { mutate: deleteAction, isPending } = useDeleteActionById({
    actionId: props.actionId,
    stateId: props.stateId,
    isTransition: true,
    workflowId: props.workflowId,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Unable to delete action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: deleteSate } = useDeleteStateById({
    stateId: props.stateId,
    workflowId: props.workflowId,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Unable to delete state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const edges = useStore(
    (store) => store.edges,
    (prev, current) => {
      try {
        return JSON.stringify(prev) === JSON.stringify(current);
      } catch (e) {
        return false;
      }
    },
  );

  const { properties, actionId, stateId, catIndex, setIsGotoActionPresent } = props;

  useEffect(() => {
    setIsGotoActionPresent(true);

    reactflow.setEdges((edges) => {
      const currentEdges = edges.filter(
        (edge) => !(edge.source === stateId && edge.sourceHandle === `goto-source-${actionId}`),
      );

      return [
        ...currentEdges,
        {
          type: 'goto',
          id: `${stateId}-${actionId}`,
          source: stateId,
          target: properties.toState,
          sourceHandle: `goto-source-${actionId}`,
          targetHandle: 'goto-target',
          animated: true,
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          style: {
            stroke: colorList[catIndex % colorList.length],
            zIndex: 100,
          },
        },
      ];
    });

    updateNodeInternals(stateId);
  }, [properties.toState, actionId, stateId, updateNodeInternals, catIndex, reactflow, edges, setIsGotoActionPresent]);

  const handleDeleteAction = useCallback(() => {
    deleteAction();
    if (props.nodeType === 'ACTION_ONLY') {
      deleteSate();
    }
  }, [deleteAction, deleteSate, props.nodeType]);

  const timeout = useRef<number>();
  const [visible, setVisible] = useState(false);
  const { refs, floatingStyles } = useFloating({
    placement: 'right-start',
    whileElementsMounted: autoUpdate,
  });

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(true);
    }, 25);
  }, []);
  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(false);
    }, 25);
  }, []);

  const handleEmailSelect = () => {
    setSelectedProperties({
      type: props.nodeType,
      nodeId: props.stateId,
      updateType: 'action',
      actionId: props.actionId,
      catIndex: props.catIndex,
    });
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cn(
        ' items-center flex gap-2 text-sm relative  ',
        selectedActionId === props.actionId ? ' ring-2 ' : '  ',
      )}
      ref={refs.setReference}
    >
      <Handle
        style={{
          right: '-37px',
        }}
        type="source"
        position={Position.Right}
        id={`goto-source-${props.actionId}`}
      />
      {props.nodeType !== 'ACTION_ONLY' && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={cn(' flex justify-end text-center ', visible ? 'opacity-100' : 'opacity-0')}
        >
          <Button
            disabled={isPending}
            onClick={handleDeleteAction}
            className=" text-destructive relative right-8  bg-white "
            variant="outline"
            size="icon"
          >
            {isPending ? <Loader2Icon className="w-3 h-3 animate-spin" /> : <Trash2Icon className="w-3 h-3" />}
          </Button>
        </div>
      )}

      <div onClick={handleEmailSelect} className=" w-full flex items-center gap-2 cursor-pointer ">
        <div className=" bg-muted-foreground/10 p-2 rounded-md  ">
          <WaypointsIcon className=" h-8 w-8 " />
        </div>
        {props.warnings.length > 0 && (
          <div className=" absolute -left-20 text-destructive ">
            <ToolTipCell value={<AlertTriangleIcon className=" w-4 h-4 " />}>
              <div className=" flex flex-col gap-1 ">
                {props.warnings.map((w, index) => (
                  <div key={w}>
                    {index + 1}. {w}
                  </div>
                ))}
              </div>
            </ToolTipCell>
          </div>
        )}
        <div>
          <div>To: {nodeIdToLabelMap[(props.properties as IGotoActionProperties).toState]}</div>
        </div>
      </div>
    </div>
  );
};

export default GotoActionBlock;
