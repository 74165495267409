import { IOptions } from '@/types/common.types';
import { GroupBase, InputProps } from 'react-select';

export const Input = (
  props: InputProps<
    {
      value: string;
      label: string;
    },
    false,
    GroupBase<IOptions>
  >,
) => {
  return (
    <input
      placeholder={props.selectProps.placeholder as string}
      ref={props.innerRef}
      className="border-none outline-none bg-transparent "
      {...props}
    />
  );
};
