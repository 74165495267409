import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import {
  useDeleteContact,
  useGetContactRoleSuggestions,
  usePostCreateContactsForCustomer,
  usePostUpdateContact,
} from '@/hooks/api-hooks/useContactsQuery';
import { IOptions } from '@/types/common.types';
import { IContact } from '@/types/contacts.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';

import debounce from 'lodash.debounce';
import { EditIcon, InfoIcon, PlusIcon, Trash2Icon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { GroupBase, OptionsOrGroups } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Card, CardContent, CardHeaderWithCTA, CardTitle } from '../ui/card';
import { Input as ReactSelectInput } from '../ui/react-select/input';
import { useToast } from '../ui/use-toast';

const DivCell = ({ children }: { children: React.ReactNode }) => {
  return <div className=" px-6 min-w-[180px] w-full py-2 ">{children}</div>;
};

const DivHead = ({ children }: { children: React.ReactNode }) => {
  return <div className=" font-semibold  px-6 flex-1 min-w-[180px] py-2 bg-gray-100 ">{children}</div>;
};

const ContactRow = ({
  contactDetails,
  customerId,
  setIsCreate,
  isCreate,
}: {
  contactDetails: IContact;
  customerId: string;
  setIsCreate?: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate?: boolean;
}) => {
  const [isEditing, setIsEditing] = useState(() => !!isCreate);
  const [searchParams, setSearchParams] = useSearchParams();
  const { toast } = useToast();

  useEffect(() => {
    if (searchParams.get('contactKey') === contactDetails.id) {
      setIsEditing(true);
      setSearchParams(
        (prev) => {
          prev.delete('contactKey');
          return prev;
        },
        {
          replace: true,
        },
      );
    }
  }, [searchParams, contactDetails.id, setSearchParams]);

  const { mutate: createContact } = usePostCreateContactsForCustomer({
    customerId: customerId,
    customConfig: {
      onSuccess: () => {
        setIsEditing(false);
        setIsCreate?.(false);
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error?.response?.data?.message || 'Unable to create customer contact',
        });
      },
    },
  });

  const { mutate: updateContact } = usePostUpdateContact({
    customerId: customerId,
    customConfig: {
      onSuccess: () => {
        setIsEditing(false);
        setIsCreate?.(false);
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error?.response?.data?.message || 'Unable to update customer contact',
        });
      },
    },
  });

  const { mutate: deleteContact } = useDeleteContact({
    customerId: customerId,
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error?.response?.data?.message || 'Unable to delete customer contact',
        });
      },
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    control,

    watch,
    formState: { errors },
  } = useForm<IContact>({
    defaultValues: {
      ...contactDetails,
    },
  });

  const primaryEmail = watch('primaryEmail');
  const primaryPhone = watch('primaryPhone');

  const { mutateAsync: fetchRoles } = useGetContactRoleSuggestions({});

  const loadRoles = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IOptions, GroupBase<IOptions>>) => void) => {
      if (inputValue.length < 3) {
        callback([]);
        return;
      }

      fetchRoles({
        query: inputValue.trim(),
      }).then((result) => {
        const options = [...new Set(result.data.suggestions.map((item) => item.role || ''))]
          .map((item) => ({
            value: item,
            label: item,
          }))
          .filter((item) => Boolean(item.value));

        callback(options);
      });
      return;
    },
    [fetchRoles],
  );

  const debouncedLoadTagNames = useMemo(() => {
    return debounce(loadRoles, 500);
  }, [loadRoles]);

  const handleEdit = useCallback(
    (contactDetails: IContact) => () => {
      setIsEditing(true);
      reset(contactDetails);
    },
    [reset],
  );

  const handleCancel = useCallback(() => {
    reset({ id: '', name: '', primaryEmail: '', primaryPhone: '' });
    setIsCreate?.(false);
    setIsEditing(false);
  }, [reset, setIsCreate]);

  const onSubmit = (data: IContact) => {
    if (data.id) {
      updateContact({
        ...data,
        name: data.name.trim(),
        primaryEmail: data.primaryEmail ? data.primaryEmail.trim() : null,
        primaryPhone: data.primaryPhone ? data.primaryPhone.trim() : null,
        role: data.role.trim(),
      });
      return;
    }
    createContact({
      ...data,
      name: data.name.trim(),
      primaryEmail: data.primaryEmail?.trim() ? data.primaryEmail.trim() : null,
      primaryPhone: data.primaryPhone?.trim() ? data.primaryPhone.trim() : null,
      role: data.role.trim(),
    });
  };

  const handleDelete = useCallback(
    (id: string) => () => {
      deleteContact({ contactId: id });
    },
    [deleteContact],
  );

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false, IOptions>();
  }, []);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  return isEditing ? (
    <form className=" flex justify-between  " onSubmit={handleSubmit(onSubmit)}>
      <DivCell>
        <Input
          autoFocus
          {...register('primaryEmail', {
            pattern: {
              value: /^[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
              message: 'Invalid email address',
            },
            validate: {
              required: (value) => {
                if (!!value?.trim() || primaryPhone?.trim()) {
                  return true;
                }
                return 'Email or phone is required';
              },
            },
          })}
          placeholder="Enter email"
        />
        <p className="text-red-500 text-[10px]">{!!errors.primaryEmail?.message && errors.primaryEmail.message}</p>
      </DivCell>
      <DivCell>
        <Input
          {...register('name', {
            required: {
              value: true,
              message: 'Name is required',
            },
            validate: (value) => (value.trim() ? true : 'Name is required'),
            minLength: {
              value: 3,
              message: 'Name must be at least 3 characters',
            },
            maxLength: {
              value: 100,
              message: 'Name must be less than 100 characters',
            },
          })}
          placeholder="Enter name"
        />
        <p className="text-red-500 text-[10px]">{!!errors.name?.message && errors.name.message}</p>
      </DivCell>

      <DivCell>
        <Input
          placeholder="Enter phone"
          {...register('primaryPhone', {
            pattern: {
              value: /^[0-9]+$/i,
              message: 'Invalid phone number',
            },
            validate: {
              required: (value) => {
                if (!!value?.trim() || primaryEmail?.trim()) {
                  return true;
                }
                return 'Email or phone is required';
              },
            },
          })}
        />
        <p className="text-red-500 text-[10px]">{!!errors.primaryPhone?.message && errors.primaryPhone.message}</p>
      </DivCell>
      <DivCell>
        <Controller
          control={control}
          name="role"
          rules={{
            required: {
              value: true,
              message: 'Role is required',
            },
            validate: (value) => (value.trim() ? true : 'Role is required'),
            minLength: {
              value: 3,
              message: 'Role must be at least 3 characters',
            },
            maxLength: {
              value: 100,
              message: 'Role must be less than 100 characters',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <AsyncCreatableSelect
              isMulti={false}
              placeholder="Enter role"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Input: ReactSelectInput,
              }}
              onChange={(item) => {
                onChange(item?.value || '');
              }}
              inputValue={value}
              onInputChange={(newValue, action) => {
                if (action.action === 'input-change') {
                  onChange(newValue);
                }
              }}
              value={
                value?.trim()
                  ? {
                      value: value,
                      label: value,
                    }
                  : {
                      value: '',
                      label: '',
                    }
              }
              loadOptions={debouncedLoadTagNames}
              menuPortalTarget={document.body}
              className=" text-sm shadow-sm w-[180px]"
              styles={selectStyles}
              classNames={selectClasses}
            />
          )}
        />
        <p className="text-red-500 text-[10px]">{!!errors.role?.message && errors.role.message}</p>
      </DivCell>
      <DivCell>
        <div className="  flex gap-2  ">
          <Button onClick={handleCancel} variant="outline">
            Cancel
          </Button>
          <Button onSubmit={handleSubmit(onSubmit)} type="submit">
            Save
          </Button>
        </div>
      </DivCell>
    </form>
  ) : (
    <div className=" flex justify-between ">
      <DivCell>
        <div className=" flex items-center gap-2 ">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="cursor-pointer text-[14px] text-ellipsis	overflow-hidden">
                {contactDetails.primaryEmail ? contactDetails.primaryEmail : 'Not available'}
              </TooltipTrigger>
              <TooltipContent>{contactDetails.primaryEmail}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </DivCell>
      <DivCell>
        <div className=" flex items-center gap-2 ">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="cursor-pointer text-[14px] text-ellipsis	overflow-hidden">
                {contactDetails.name ? contactDetails.name : 'Not available'}
              </TooltipTrigger>
              <TooltipContent>{contactDetails.name}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </DivCell>
      <DivCell>{contactDetails.primaryPhone ? contactDetails.primaryPhone : 'Not available'}</DivCell>
      <DivCell>
        <div className=" flex items-center gap-2 ">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="text-[14px] text-ellipsis	overflow-hidden">
                {contactDetails.role ? contactDetails.role : 'Not available'}
              </TooltipTrigger>
              <TooltipContent>{contactDetails.role}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </DivCell>
      <DivCell>
        {isCreate ? (
          <div className=" flex gap-2 w-full ">
            <Button onClick={handleEdit(contactDetails)} variant="outline" className=" w-1/2 ">
              Save
            </Button>
            <Button onClick={handleCancel} className=" w-1/2 ">
              Cancel
            </Button>
          </div>
        ) : (
          <div className=" flex gap-2 ">
            <Button size="icon" onClick={handleEdit(contactDetails)} variant="outline">
              <EditIcon className="w-4 h-4" />
            </Button>
            <Button
              size="icon"
              onClick={handleDelete(contactDetails.id)}
              variant="outline"
              className=" text-destructive "
            >
              <Trash2Icon className="w-4 h-4" />
            </Button>
          </div>
        )}
      </DivCell>
    </div>
  );
};

const ContactDetails = ({ contactDetails, customerId }: { customerId: string; contactDetails: IContact[] }) => {
  const [isCreate, setIsCreate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('scrollTo') === 'contacts') {
      document.querySelector('#contacts')?.scrollIntoView({ behavior: 'smooth' });
      setSearchParams(
        (prev) => {
          prev.delete('scrollTo');
          return prev;
        },
        {
          replace: true,
        },
      );
    }
    if (searchParams.get('contactKey') === 'create_new') {
      setIsCreate(true);
      setSearchParams(
        (prev) => {
          prev.delete('contactKey');
          return prev;
        },
        {
          replace: true,
        },
      );
    }
  }, [searchParams, setSearchParams]);

  return (
    <Card id="contacts" className=" my-8 ">
      <CardHeaderWithCTA
        className=" border-b "
        cardTitle={
          <div className="flex gap-2">
            <CardTitle className=" underline font-semibold ">Customer Contacts</CardTitle>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <InfoIcon className=" w-4 h-4 " />
                </TooltipTrigger>
                <TooltipContent className="text-xs">
                  These are your customer&apos;s employees or contractors / partners.
                  <br /> Anybody at your customer/buyer end that you need to coordinate with for managing receivable
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        }
        cta={
          !isCreate && (
            <Button onClick={() => setIsCreate(true)} className="flex gap-4 items-center">
              Add Customer Contact
              <PlusIcon className=" w-4 h-4 " />
            </Button>
          )
        }
      />

      <CardContent className=" flex flex-col gap-4 p-6">
        <div className=" flex flex-col gap-4 text-sm ">
          <div className="  overflow-x-scroll ">
            <div className=" bg-gray-100 flex justify-between  ">
              <DivHead>Email</DivHead>
              <DivHead>Name</DivHead>
              <DivHead>Phone</DivHead>
              <DivHead>Role</DivHead>
              <DivHead>Actions</DivHead>
            </div>
            {contactDetails.map((contact) => (
              <ContactRow customerId={customerId} contactDetails={contact} key={contact.id} />
            ))}
            {isCreate && (
              <ContactRow
                setIsCreate={setIsCreate}
                isCreate={isCreate}
                customerId={customerId}
                contactDetails={{ id: '', name: '', primaryEmail: '', primaryPhone: '', role: '' }}
              />
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ContactDetails;
