import { StateTypes } from '@/types/workflow.type';
import { create } from 'zustand';

interface IPropertiesState {
  nodeId: string;
  type: StateTypes;
  updateType: 'condition' | 'action';
  conditionId?: string;
  actionId?: string;
  catIndex?: number;
}

type State = {
  selectedProperties?: IPropertiesState;
  setSelectedProperties: (_?: IPropertiesState) => void;
};

export const useWorkflowPropertiesStore = create<State>((set) => ({
  selectedProperties: undefined,
  setSelectedProperties(state?: IPropertiesState) {
    set({ selectedProperties: state });
  },
}));
