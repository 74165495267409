import { dataAxiosInstance } from '@/lib/axios';
import { ICreateOwnerRequest } from '@/types/owners.types';

export const postCreateOwnerForCustomer = async ({ ...owner }: ICreateOwnerRequest) => {
  const result = await dataAxiosInstance.post('/owner', {
    ...owner,
  });
  return result.data;
};
//commment
export const postUpdateOwner = async ({
  customerId,
  role,
  ownerId,
}: {
  customerId: string;
  ownerId: string;
  role: string;
}) => {
  const result = await dataAxiosInstance.post('/owner/update/role', {
    ownerId,
    customerId,
    role,
  });

  return result.data;
};

export const deleteOwner = async ({ ownerId, customerId }: { ownerId: string; customerId: string }) => {
  const result = await dataAxiosInstance.post('/owner/remove', {
    ownerId,
    customerId,
  });
  return result.data;
};

export const getOwnerRoleSuggestions = async (query: string) => {
  const result = await dataAxiosInstance.get(`/owner/role/suggestions?roleContains=${query.trim()}`);
  return result.data;
};

export const getOwnerSuggestions = async ({ emailString, nameString }: { emailString: string; nameString: string }) => {
  const result = await dataAxiosInstance.get(
    `/owner/suggestions?emailContains=${emailString.trim()}&nameContains=${nameString.trim()}`,
  );
  return result.data;
};

export const getOwnerSuggestionsByEmail = async ({ emailString }: { emailString: string }) => {
  const encodedEmailString = encodeURIComponent(emailString.trim());
  const result = await dataAxiosInstance.get(`/owner/existingUserOrOwnerByEmail?email=${encodedEmailString}`);
  return result.data;
};

export const getOwnerById = async (ownerId: string) => {
  const result = await dataAxiosInstance.get(`/owner?ownerId=${ownerId}`);
  return result.data;
};
