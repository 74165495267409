/* eslint-disable react/display-name */
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { UploadIcon } from 'lucide-react';
import React, { ChangeEvent, useRef } from 'react';

interface DropzoneProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  classNameWrapper?: string;
  className?: string;
  dropMessage: string;
  handleOnDrop: (_: FileList | null) => void;
}

const Dropzone = React.forwardRef<HTMLDivElement, DropzoneProps>(
  ({ className, classNameWrapper, dropMessage, handleOnDrop, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    // Function to handle drag over event
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handleOnDrop(null);
    };

    // Function to handle drop event
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const { files } = e.dataTransfer;
      if (inputRef.current) {
        inputRef.current.files = files;
        handleOnDrop(files);
      }
    };

    // Function to simulate a click on the file input element
    const handleButtonClick = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };
    return (
      <Card
        ref={ref}
        className={cn(
          `border-2 border-dashed bg-muted hover:cursor-pointer hover:border-muted-foreground/50 mt-4`,
          classNameWrapper,
        )}
      >
        <CardContent
          className="flex flex-col items-center justify-center space-y-2 px-2 py-4 text-xs h-80"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleButtonClick}
        >
          <div className="flex items-center justify-center gap-6 flex-col  text-muted-foreground">
            <div>
              <UploadIcon className="w-10 h-10" />
            </div>
            <div className=" text-center ">
              <p className="font-medium text-primary">{dropMessage}</p>
              <p className="font-medium text-muted-foreground">Supported file formats: CSV</p>
            </div>
            <Input
              {...props}
              value={undefined}
              ref={inputRef}
              type="file"
              accept=".csv"
              className={cn('hidden ', className)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnDrop(e.target.files)}
            />
          </div>
        </CardContent>
      </Card>
    );
  },
);

export default Dropzone;
