import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  ArithmeticOperators,
  ComparisonOperators,
  DateOperators,
  ICondition,
  IConditionRule,
  IStatement,
  IStatementCondition,
  ListOperators,
} from '@/types/workflow.type';
import { createId } from '@/utils/createId';
import { PlusIcon, Trash2Icon } from 'lucide-react';
import { Dispatch, SetStateAction, useMemo } from 'react';

import ComboboxComponent from '@/components/ui/combobox-component';
import SelectComponent from '@/components/ui/select-component';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';
import { inputTypeMap, invoiceValueLabelMap } from '@/utils/type-map';

const operatorToLabel: Record<ComparisonOperators, string> = {
  '!=': 'Not equal to',
  '<': 'less than',
  '<=': 'less than equal to',
  '==': 'is equal to',
  '>': 'greater than',
  '>=': 'greater than equal to',
};

const operatorToType: Record<
  DateOperators | ComparisonOperators | ListOperators,
  'NUMBER' | 'DATE' | 'STRING' | 'ALPHANUMERIC' | 'ALL' | 'LIST' | 'DIFF'
> = {
  '!=': 'ALL',
  '<': 'ALL',
  '<=': 'ALL',
  '==': 'ALL',
  '>': 'ALL',
  '>=': 'ALL',
  'not in': 'LIST',
  in: 'LIST',
  days: 'DIFF',
  months: 'DIFF',
  weeks: 'DIFF',
  years: 'DIFF',
};

const DateValueInput = ({
  lhs,
  rhs,
  onChange,
  valuePlaceholder,
}: {
  lhs?: IStatement;
  rhs?: IStatement;
  onChange: (_: { lhs: IStatement; rhs: IStatement }) => void;
  valuePlaceholder: string;
}) => {
  const relativeDate = useMemo<'today' | 'nDaysAgo' | 'nDaysLater' | undefined>(() => {
    if (rhs?.operator) {
      return 'nDaysLater';
    }

    if (lhs?.operator) {
      return 'nDaysAgo';
    }

    if (rhs?.valuePlaceholder && lhs?.valuePlaceholder) {
      return 'today';
    }

    return undefined;
  }, [rhs, lhs]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (relativeDate === 'nDaysAgo') {
      onChange({
        lhs: {
          _type: 'STATEMENT_TYPE',
          id: createId(),
          ...lhs,
          rightStatement: {
            id: createId(),
            _type: 'STATEMENT_TYPE',
            ...lhs?.rightStatement,
            value: Number(e.target.value),
          },
        },
        rhs: {
          _type: 'STATEMENT_TYPE',
          id: createId(),
          ...rhs,
        },
      });
    }

    if (relativeDate === 'nDaysLater') {
      onChange({
        lhs: {
          _type: 'STATEMENT_TYPE',
          id: createId(),
          ...lhs,
        },
        rhs: {
          _type: 'STATEMENT_TYPE',
          id: createId(),
          ...rhs,
          rightStatement: {
            id: createId(),
            _type: 'STATEMENT_TYPE',
            ...rhs?.rightStatement,
            value: Number(e.target.value),
          },
        },
      });
    }
  };

  const handleRelativeDateChange = (operator: string) => {
    const operatorValue = operator as 'today' | 'nDaysAgo' | 'nDaysLater';

    if (operatorValue === 'today') {
      onChange({
        lhs: {
          _type: 'STATEMENT_TYPE',
          id: createId(),
          ...lhs,
          rightStatement: undefined,
          operator: undefined,
          leftStatement: undefined,
          valuePlaceholder: valuePlaceholder,
          value: undefined,
        },
        rhs: {
          _type: 'STATEMENT_TYPE',
          id: createId(),
          ...rhs,
          valuePlaceholder: 'current_date',
          rightStatement: undefined,
          operator: undefined,
          leftStatement: undefined,
          value: undefined,
        },
      });

      return;
    }

    if (operatorValue === 'nDaysLater') {
      onChange({
        lhs: {
          _type: 'STATEMENT_TYPE',
          id: createId(),
          ...lhs,
          rightStatement: undefined,
          operator: undefined,
          leftStatement: undefined,
          valuePlaceholder: valuePlaceholder,
        },
        rhs: {
          _type: 'STATEMENT_TYPE',
          id: createId(),
          ...rhs,
          operator: 'days',
          leftStatement: {
            _type: 'STATEMENT_TYPE',
            id: createId(),
            ...rhs?.leftStatement,
            valuePlaceholder: 'current_date',
          },
          rightStatement: {
            _type: 'STATEMENT_TYPE',
            id: createId(),
            ...rhs?.rightStatement,
            value: undefined,
          },
          valuePlaceholder: undefined,
          value: undefined,
        },
      });

      return;
    }

    onChange({
      lhs: {
        _type: 'STATEMENT_TYPE',
        id: createId(),
        ...rhs,
        operator: 'days',
        leftStatement: {
          _type: 'STATEMENT_TYPE',
          id: createId(),
          ...rhs?.leftStatement,
          valuePlaceholder: valuePlaceholder,
        },
        rightStatement: {
          _type: 'STATEMENT_TYPE',
          id: createId(),
          ...rhs?.rightStatement,
          value: undefined,
        },
        valuePlaceholder: undefined,
        value: undefined,
      },
      rhs: {
        _type: 'STATEMENT_TYPE',
        id: createId(),
        ...rhs,
        valuePlaceholder: 'current_date',
        rightStatement: undefined,
        operator: undefined,
        leftStatement: undefined,
      },
    });
  };

  const inputValue = useMemo(() => {
    if (rhs?.operator) {
      return rhs.rightStatement?.value;
    }

    if (lhs?.operator) {
      return lhs.rightStatement?.value;
    }

    return undefined;
  }, [rhs, lhs]);

  return (
    <div className=" flex items-center gap-2 ">
      {relativeDate !== undefined && relativeDate !== 'today' && (
        <Input
          className=" max-w-[100px] "
          placeholder="Enter value"
          value={inputValue ? String(inputValue) : ''}
          onChange={handleInputChange}
        />
      )}
      <SelectComponent
        value={relativeDate || ''}
        placeholder="Select relative date"
        onChange={handleRelativeDateChange}
        options={[
          {
            label: 'today',
            value: 'today',
          },
          {
            label: '"n" days ago',
            value: 'nDaysAgo',
          },
          {
            label: '"n" days later',
            value: 'nDaysLater',
          },
        ]}
      />
    </div>
  );
};

const StatementItem = ({
  statement,
  setConditions,
  path,
  isExitCriteria,
}: {
  statement: IStatementCondition;
  setConditions: React.Dispatch<React.SetStateAction<ICondition[]>>;
  path: number[];
  isExitCriteria: boolean;
}) => {
  const lhs = useMemo(() => {
    if (statement.lhs?.operator) {
      return statement.lhs.leftStatement?.valuePlaceholder;
    }

    return statement.lhs?.valuePlaceholder;
  }, [statement.lhs]);

  const rhs = statement?.rhs?.value;

  const operator = statement?.operator || '';

  const operatorOptions = useMemo(() => {
    if (lhs && inputTypeMap[lhs.split('.')[1] as keyof typeof inputTypeMap] === 'DATE') {
      return Object.keys(operatorToLabel)
        .map((key) => ({
          label: operatorToLabel[key as keyof typeof operatorToLabel],
          value: key,
        }))
        .filter((item) => ['ALL', 'DATE'].includes(operatorToType[item.value as keyof typeof operatorToType]));
    }

    if (lhs && inputTypeMap[lhs.split('.')[1] as keyof typeof inputTypeMap] === 'STRING') {
      return [
        {
          value: '==',
          label: 'is equal to',
        },
        {
          value: '!=',
          label: 'is not equal to',
        },
        {
          value: 'CONTAINS',
          label: 'contains',
        },
        {
          value: 'STARTS_WITH',
          label: 'starts with',
        },
        {
          value: 'ENDS_WITH',
          label: 'ends with',
        },
        {
          value: 'NOT_CONTAINS',
          label: 'not contains',
        },
        {
          value: 'NOT_STARTS_WITH',
          label: 'not starts with',
        },
        {
          value: 'NOT_ENDS_WITH',
          label: 'not ends with',
        },
      ];
    }

    if (lhs && inputTypeMap[lhs.split('.')[1] as keyof typeof inputTypeMap].includes('DROPDOWN')) {
      return [
        {
          value: '==',
          label: 'is equal to',
        },
        {
          value: '!=',
          label: 'is not equal to',
        },
      ];
    }

    return Object.keys(operatorToLabel).map((key) => ({
      label: operatorToLabel[key as keyof typeof operatorToLabel],
      value: key,
    }));
  }, [lhs]);

  const operatorMap = useMemo(() => {
    return [...operatorOptions].reduce(
      (prev, current) => {
        return {
          ...prev,
          [current.value]: current.label,
        };
      },
      {} as Record<string, string>,
    );
  }, [operatorOptions]);

  const variableOptions = useMemo(() => {
    return Object.entries(invoiceValueLabelMap).map(([value, label]) => ({ value: `invoice.${value}`, label }));
  }, []);

  const handleLhsChange = (value: string) => {
    setConditions((prevConditions) => {
      const newConditions: ICondition[] = JSON.parse(JSON.stringify(prevConditions));

      let placeWhereToBeAdded: IConditionRule['childConditions'] | undefined;

      if (path.length < 1) {
        return prevConditions;
      }

      const initialCondition = newConditions[path[0]];

      if (initialCondition && (initialCondition.rule.type === 'AND_TYPE' || initialCondition.rule.type === 'OR_TYPE')) {
        placeWhereToBeAdded = initialCondition.rule.childConditions;
      }

      path.forEach((pathValue, index) => {
        if (index === 0) {
          return;
        }

        if (index === path.length - 1) {
          return;
        }

        if (placeWhereToBeAdded && (placeWhereToBeAdded as IConditionRule['childConditions']).length) {
          const currentCondition = (placeWhereToBeAdded as IConditionRule['childConditions'])[pathValue];

          if (currentCondition.type === 'AND_TYPE' || currentCondition.type === 'OR_TYPE') {
            placeWhereToBeAdded = currentCondition.childConditions;
          }
        }
      });

      if (placeWhereToBeAdded && placeWhereToBeAdded.length) {
        const updatedStatement: IStatementCondition = {
          ...statement,
          lhs: {
            ...(statement.lhs as IStatement),

            valuePlaceholder: value as keyof typeof invoiceValueLabelMap,
            leftStatement: undefined,
            operator: undefined,
            rightStatement: undefined,
          },

          rhs: undefined,
        };

        placeWhereToBeAdded[path[path.length - 1]] = updatedStatement;
      }

      return newConditions;
    });
  };

  const handleOperatorChange = (value: string) => {
    const operator = value as DateOperators | ArithmeticOperators | ComparisonOperators | undefined;

    setConditions((prevConditions) => {
      const newConditions: ICondition[] = JSON.parse(JSON.stringify(prevConditions));

      let placeWhereToBeAdded: IConditionRule['childConditions'] | undefined;

      if (path.length < 1) {
        return prevConditions;
      }

      const initialCondition = newConditions[path[0]];

      if (initialCondition && (initialCondition.rule.type === 'AND_TYPE' || initialCondition.rule.type === 'OR_TYPE')) {
        placeWhereToBeAdded = initialCondition.rule.childConditions;
      }

      path.forEach((pathValue, index) => {
        if (index === 0) {
          return;
        }

        if (index === path.length - 1) {
          return;
        }

        if (placeWhereToBeAdded && (placeWhereToBeAdded as IConditionRule['childConditions']).length) {
          const currentCondition = (placeWhereToBeAdded as IConditionRule['childConditions'])[pathValue];

          if (currentCondition.type === 'AND_TYPE' || currentCondition.type === 'OR_TYPE') {
            placeWhereToBeAdded = currentCondition.childConditions;
          }
        }
      });

      if (placeWhereToBeAdded && placeWhereToBeAdded.length) {
        const updatedStatement: IStatementCondition = {
          ...statement,
          operator: operator,
        };

        placeWhereToBeAdded[path[path.length - 1]] = updatedStatement;
      }

      return newConditions;
    });
  };

  const handleRhsChange = (value: string) => {
    setConditions((prevConditions) => {
      const newConditions: ICondition[] = JSON.parse(JSON.stringify(prevConditions));

      let placeWhereToBeAdded: IConditionRule['childConditions'] | undefined;

      if (path.length < 1) {
        return prevConditions;
      }

      const initialCondition = newConditions[path[0]];

      if (initialCondition && (initialCondition.rule.type === 'AND_TYPE' || initialCondition.rule.type === 'OR_TYPE')) {
        placeWhereToBeAdded = initialCondition.rule.childConditions;
      }

      path.forEach((pathValue, index) => {
        if (index === 0) {
          return;
        }

        if (index === path.length - 1) {
          return;
        }

        if (placeWhereToBeAdded && (placeWhereToBeAdded as IConditionRule['childConditions']).length) {
          const currentCondition = (placeWhereToBeAdded as IConditionRule['childConditions'])[pathValue];

          if (currentCondition.type === 'AND_TYPE' || currentCondition.type === 'OR_TYPE') {
            placeWhereToBeAdded = currentCondition.childConditions;
          }
        }
      });

      if (placeWhereToBeAdded && placeWhereToBeAdded.length) {
        const variablesArray = statement.lhs?.valuePlaceholder?.split('.');
        let variable;

        if (variablesArray?.length) {
          variable = variablesArray[variablesArray.length - 1];
        }

        if (variable) {
          const variableType = inputTypeMap[variable as keyof typeof inputTypeMap];

          const updatedStatement: IStatementCondition = {
            ...statement,
            rhs: {
              _type: 'STATEMENT_TYPE',
              id: createId(),
              value: variableType === 'NUMBER' ? (isNaN(parseInt(value)) ? value : parseInt(value)) : value,
            },
          };

          placeWhereToBeAdded[path[path.length - 1]] = updatedStatement;
        } else {
          const updatedStatement: IStatementCondition = {
            ...statement,
            rhs: {
              _type: 'STATEMENT_TYPE',
              id: createId(),
              value: value,
            },
          };

          placeWhereToBeAdded[path[path.length - 1]] = updatedStatement;
        }
      }

      return newConditions;
    });
  };

  const handleDateValueChange = ({ lhsValue, rhsValue }: { lhsValue?: IStatement; rhsValue?: IStatement }) => {
    setConditions((prevConditions) => {
      const newConditions: ICondition[] = JSON.parse(JSON.stringify(prevConditions));

      let placeWhereToBeAdded: IConditionRule['childConditions'] | undefined;

      if (path.length < 1) {
        return prevConditions;
      }

      const initialCondition = newConditions[path[0]];

      if (initialCondition && (initialCondition.rule.type === 'AND_TYPE' || initialCondition.rule.type === 'OR_TYPE')) {
        placeWhereToBeAdded = initialCondition.rule.childConditions;
      }

      path.forEach((pathValue, index) => {
        if (index === 0) {
          return;
        }

        if (index === path.length - 1) {
          return;
        }

        if (placeWhereToBeAdded && (placeWhereToBeAdded as IConditionRule['childConditions']).length) {
          const currentCondition = (placeWhereToBeAdded as IConditionRule['childConditions'])[pathValue];

          if (currentCondition.type === 'AND_TYPE' || currentCondition.type === 'OR_TYPE') {
            placeWhereToBeAdded = currentCondition.childConditions;
          }
        }
      });

      if (placeWhereToBeAdded && placeWhereToBeAdded.length) {
        const updatedStatement: IStatementCondition = {
          ...statement,
          rhs: rhsValue,
          lhs: lhsValue,
        };

        placeWhereToBeAdded[path[path.length - 1]] = updatedStatement;
      }

      return newConditions;
    });
  };

  const handleDeleteCondition = () => {
    const pathToParent = [...path].slice(0, path.length - 1);
    const childIndex = path[path.length - 1];

    setConditions((prevConditions) => {
      const newConditions: ICondition[] = JSON.parse(JSON.stringify(prevConditions));

      if (pathToParent.length < 1) {
        return prevConditions;
      }

      const initialCondition = newConditions[pathToParent[0]];

      let parentNode: IConditionRule['childConditions'] | undefined;
      if (initialCondition && (initialCondition.rule.type === 'AND_TYPE' || initialCondition.rule.type === 'OR_TYPE')) {
        parentNode = initialCondition.rule.childConditions;
      }

      path.forEach((pathValue, index) => {
        if (index === 0) {
          return;
        }

        if (index === path.length - 1) {
          return;
        }

        if (parentNode && (parentNode as IConditionRule['childConditions']).length) {
          const currentCondition = (parentNode as IConditionRule['childConditions'])[pathValue];

          if (currentCondition.type === 'AND_TYPE' || currentCondition.type === 'OR_TYPE') {
            parentNode = currentCondition.childConditions;
          }
        }
      });

      if (parentNode && parentNode.length) {
        parentNode.splice(childIndex, 1);
      }

      return newConditions;
    });
  };

  return (
    <div className={cn(' flex flex-col  gap-2  items-start relative ', isExitCriteria && 'flex-row items-center')}>
      <Button
        onClick={handleDeleteCondition}
        variant="ghost"
        size="icon"
        className=" text-destructive absolute right-0 top-0 "
      >
        <Trash2Icon className="w-4 h-4" />
      </Button>
      <div>
        <ComboboxComponent
          className=" w-full min-w-[200px] flex-1 "
          options={variableOptions}
          placeholder="Select variable"
          onChange={handleLhsChange}
          value={{
            label: invoiceValueLabelMap[lhs?.split('.')[1] as keyof typeof invoiceValueLabelMap],
            value: lhs || '',
          }}
        />
      </div>
      <div>
        <ComboboxComponent
          className=" w-full min-w-[200px] flex-1 "
          placeholder="Select operator"
          onChange={handleOperatorChange}
          options={operatorOptions}
          value={
            operator
              ? {
                  label: operatorMap[operator],
                  value: operator,
                }
              : undefined
          }
        />
      </div>
      <div>
        {lhs && inputTypeMap[lhs.split('.')[1] as keyof typeof inputTypeMap] === 'DATE' ? (
          <DateValueInput
            onChange={({ lhs, rhs }) =>
              handleDateValueChange({
                lhsValue: lhs,
                rhsValue: rhs,
              })
            }
            lhs={statement.lhs}
            rhs={statement.rhs}
            valuePlaceholder={lhs}
          />
        ) : lhs && inputTypeMap[lhs.split('.')[1] as keyof typeof inputTypeMap] === 'INVOICE-STATUS-DROPDOWN' ? (
          <SelectComponent
            value={rhs !== undefined && rhs !== null ? String(rhs) : ''}
            onChange={handleRhsChange}
            placeholder="Select invoice status"
            className=" w-[200px]"
            options={[
              {
                value: 'DUE',
                label: 'Due',
              },
              {
                value: 'OVERDUE',
                label: 'Overdue',
              },
              {
                value: 'NO_DUES',
                label: 'No dues',
              },
            ]}
          />
        ) : lhs && inputTypeMap[lhs.split('.')[1] as keyof typeof inputTypeMap] === 'PAYMENT-STATUS-DROPDOWN' ? (
          <SelectComponent
            value={rhs !== undefined && rhs !== null ? String(rhs) : ''}
            onChange={handleRhsChange}
            placeholder="Select payment status"
            className=" w-[200px]"
            options={[
              {
                value: 'PAID',
                label: 'Paid',
              },
              {
                value: 'UNPAID',
                label: 'Unpaid',
              },
              {
                value: 'PARTIALLY_PAID',
                label: 'Partially Paid',
              },
            ]}
          />
        ) : (
          <Input
            className=" w-[200px]"
            onChange={(e) => handleRhsChange(e.target.value)}
            placeholder="Enter value"
            value={rhs !== undefined && rhs !== null ? String(rhs) : ''}
          />
        )}
      </div>
    </div>
  );
};

const ConditionTypeTabs = ({
  value,
  onChange,
}: {
  value: 'AND_TYPE' | 'OR_TYPE' | 'STATEMENT_TYPE';
  onChange: (_: 'AND_TYPE' | 'OR_TYPE') => void;
}) => {
  const handleGraphTypeChange = (value: string) => {
    onChange(value as 'AND_TYPE' | 'OR_TYPE');
  };

  return (
    <Tabs className=" my-4 " onValueChange={handleGraphTypeChange} value={value}>
      <TabsList>
        <TabsTrigger value="AND_TYPE">And</TabsTrigger>
        <TabsTrigger value="OR_TYPE">Or</TabsTrigger>
      </TabsList>
    </Tabs>
  );
};

const ConditionItem = ({
  condition,
  setConditions,
  path,
  isExitCriteria,
}: {
  condition: IConditionRule | IStatementCondition;
  setConditions: React.Dispatch<React.SetStateAction<ICondition[]>>;
  path: number[];
  isExitCriteria: boolean;
}) => {
  const handleAddCondition = () => {
    setConditions((prevConditions) => {
      const newConditions: ICondition[] = JSON.parse(JSON.stringify(prevConditions));

      const elementToBeAdded: IStatementCondition = {
        type: 'STATEMENT_TYPE',
        lhs: {
          id: createId(),
          _type: 'STATEMENT_TYPE',
        },
        rhs: {
          id: createId(),
          _type: 'STATEMENT_TYPE',
        },
      };

      let placeWhereToBeAdded: IConditionRule['childConditions'] | undefined;

      if (path.length < 1) {
        return prevConditions;
      }

      const initialCondition = newConditions[path[0]];

      if (initialCondition && (initialCondition.rule.type === 'AND_TYPE' || initialCondition.rule.type === 'OR_TYPE')) {
        placeWhereToBeAdded = initialCondition.rule.childConditions;
      }

      path.forEach((pathValue, index) => {
        if (index === 0) {
          return;
        }

        if (
          placeWhereToBeAdded &&
          placeWhereToBeAdded[pathValue] &&
          (placeWhereToBeAdded[pathValue].type === 'AND_TYPE' || placeWhereToBeAdded[pathValue].type === 'OR_TYPE')
        ) {
          placeWhereToBeAdded = (placeWhereToBeAdded[pathValue] as IConditionRule).childConditions;
        }
      });

      if (placeWhereToBeAdded) {
        (placeWhereToBeAdded as IConditionRule['childConditions']).push(elementToBeAdded);
      }

      return newConditions;
    });
  };

  const handleAddConditionGroup = () => {
    setConditions((prevConditions) => {
      const newConditions: ICondition[] = JSON.parse(JSON.stringify(prevConditions));

      const elementToBeAdded: IConditionRule = {
        type: 'AND_TYPE',
        childConditions: [] as IConditionRule['childConditions'],
      };

      let placeWhereToBeAdded: IConditionRule['childConditions'] | undefined;

      if (path.length < 1) {
        return prevConditions;
      }

      const initialCondition = newConditions[path[0]];

      if (initialCondition && (initialCondition.rule.type === 'AND_TYPE' || initialCondition.rule.type === 'OR_TYPE')) {
        placeWhereToBeAdded = initialCondition.rule.childConditions;
      }
      path.forEach((pathValue, index) => {
        if (index === 0) {
          return;
        }

        if (
          placeWhereToBeAdded &&
          placeWhereToBeAdded[pathValue] &&
          (placeWhereToBeAdded[pathValue].type === 'AND_TYPE' || placeWhereToBeAdded[pathValue].type === 'OR_TYPE')
        ) {
          placeWhereToBeAdded = (placeWhereToBeAdded[pathValue] as IConditionRule).childConditions;
        }
      });

      if (placeWhereToBeAdded) {
        (placeWhereToBeAdded as IConditionRule['childConditions']).push(elementToBeAdded);
      }

      return newConditions;
    });
  };

  const handleConditionTypeChange = (value: 'AND_TYPE' | 'OR_TYPE') => {
    setConditions((prevConditions) => {
      const newConditions: ICondition[] = JSON.parse(JSON.stringify(prevConditions));

      let conditionToBeEdited: IConditionRule['childConditions'] | undefined;

      if (path.length < 1) {
        return prevConditions;
      }

      if (path.length === 1) {
        newConditions[path[0]].rule.type = value;
        return newConditions;
      }

      const initialCondition = newConditions[path[0]];

      if (initialCondition && (initialCondition.rule.type === 'AND_TYPE' || initialCondition.rule.type === 'OR_TYPE')) {
        conditionToBeEdited = initialCondition.rule.childConditions;
      }

      path.forEach((pathValue, index) => {
        if (index === 0) {
          return;
        }

        if (index === path.length - 1) {
          return;
        }

        if (
          conditionToBeEdited &&
          conditionToBeEdited[pathValue] &&
          (conditionToBeEdited[pathValue].type === 'AND_TYPE' || conditionToBeEdited[pathValue].type === 'OR_TYPE')
        ) {
          conditionToBeEdited = (conditionToBeEdited[pathValue] as IConditionRule).childConditions;
        }
      });

      if (conditionToBeEdited && path.length) {
        conditionToBeEdited[path[path.length - 1]].type = value;
      }

      return newConditions;
    });
  };

  const handleDeleteCondition = () => {
    const pathToParent = [...path].slice(0, path.length - 1);
    const childIndex = path[path.length - 1];

    setConditions((prevConditions) => {
      const newConditions: ICondition[] = JSON.parse(JSON.stringify(prevConditions));

      if (pathToParent.length < 1) {
        return prevConditions;
      }

      const initialCondition = newConditions[pathToParent[0]];

      let parentNode: IConditionRule['childConditions'] | undefined;
      if (initialCondition && (initialCondition.rule.type === 'AND_TYPE' || initialCondition.rule.type === 'OR_TYPE')) {
        parentNode = initialCondition.rule.childConditions;
      }

      path.forEach((pathValue, index) => {
        if (index === 0) {
          return;
        }

        if (index === path.length - 1) {
          return;
        }

        if (parentNode && (parentNode as IConditionRule['childConditions']).length) {
          const currentCondition = (parentNode as IConditionRule['childConditions'])[pathValue];

          if (currentCondition.type === 'AND_TYPE' || currentCondition.type === 'OR_TYPE') {
            parentNode = currentCondition.childConditions;
          }
        }
      });

      if (parentNode && parentNode.length) {
        parentNode.splice(childIndex, 1);
      }

      return newConditions;
    });
  };

  if (condition.type === 'AND_TYPE' || condition.type === 'OR_TYPE') {
    return (
      <>
        {path.length > 1 && (
          <div className=" flex justify-end ">
            <Button onClick={handleDeleteCondition} variant="ghost" size="icon" className=" text-destructive ">
              <Trash2Icon className="w-4 h-4" />
            </Button>
          </div>
        )}
        <div
          className={cn(
            ' border px-2 shadow-sm rounded-sm py-4 flex flex-col gap-2 relative',
            path.length === 2 && '  bg-gray-100 border-black ',
            path.length === 3 && ' bg-white border-black ',
          )}
        >
          {condition.childConditions.map((childCondition, index) => (
            <div key={index} className=" flex flex-col gap-2 ">
              {childCondition.type === 'STATEMENT_TYPE' ? (
                <StatementItem
                  isExitCriteria={isExitCriteria}
                  path={[...path, index]}
                  setConditions={setConditions}
                  statement={childCondition}
                />
              ) : (
                <ConditionItem
                  isExitCriteria={isExitCriteria}
                  path={[...path, index]}
                  setConditions={setConditions}
                  condition={childCondition}
                />
              )}
              {condition.childConditions.length - 1 === index ? (
                ''
              ) : (
                <ConditionTypeTabs value={condition.type} onChange={handleConditionTypeChange} />
              )}
            </div>
          ))}
          <div className={cn(' flex flex-col   gap-2 ', isExitCriteria && 'flex-row')}>
            <Button onClick={handleAddCondition} variant="outline" className=" text-blue-700 ">
              <PlusIcon className="w-4 h-4" /> Add condition
            </Button>
            {path.length < 2 && (
              <Button onClick={handleAddConditionGroup} variant="outline" className=" text-blue-700 ">
                <PlusIcon className="w-4 h-4" /> Add condition group
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <StatementItem
      isExitCriteria={isExitCriteria}
      setConditions={setConditions}
      path={[...path]}
      statement={condition as IStatementCondition}
    />
  );
};

export const ExitCriteriaConditions = ({
  conditions,
  setConditions,
}: {
  conditions: ICondition[];
  setConditions: Dispatch<SetStateAction<ICondition[]>>;
}) => {
  return (
    <div className="  flex flex-col gap-4">
      {conditions.map((conditionItem, index) => (
        <ConditionItem
          isExitCriteria
          key={index}
          path={[index]}
          condition={conditionItem.rule as IConditionRule | IStatementCondition}
          setConditions={setConditions}
        />
      ))}
    </div>
  );
};

const EditCondition = ({
  conditions,
  setConditions,
}: {
  conditions: ICondition[];
  setConditions: Dispatch<SetStateAction<ICondition[]>>;
}) => {
  return (
    <div className="  flex flex-col gap-4 flex-1">
      <div className=" flex flex-col flex-1 ">
        {conditions.map((conditionItem, index) => (
          <ConditionItem
            isExitCriteria={false}
            key={index}
            path={[index]}
            condition={conditionItem.rule as IConditionRule | IStatementCondition}
            setConditions={setConditions}
          />
        ))}
      </div>
    </div>
  );
};

export default EditCondition;
