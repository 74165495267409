import { ICashflowActualDetailsTableData, IExcelExportFormat } from '@/types/cashflow.types';
import dayjs from 'dayjs';
import { useExportExcel } from '../utils/useExportExcel';

const flattenArrayToObject = (arr: ICashflowActualDetailsTableData[], level: number): IExcelExportFormat[] => {
  return arr.reduce((prev, item) => {
    return [
      ...prev,
      {
        ['Name']: '       '.repeat(level) + item.name,
        ...item.actuals.reduce((acc, currentElement) => {
          return {
            ...acc,
            [currentElement.dateRange.startDate === currentElement.dateRange.endDate
              ? dayjs(currentElement.dateRange.startDate).format('DD MMM')
              : `${dayjs(currentElement.dateRange.startDate).format('DD MMM')} - ${dayjs(
                  currentElement.dateRange.endDate,
                ).format('DD MMM')}`]: currentElement.amount,
          };
        }, {} as IExcelExportFormat),
      },
      ...flattenArrayToObject(item.children || [], level + 1),
    ];
  }, [] as IExcelExportFormat[]);
};

export const useExportCashflowDetails = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (_: string) => void;
  onError?: () => void;
}) => {
  const { action, isLoading } = useExportExcel({
    onSuccess,
    onError,
  });

  return {
    exportExcel: (data: ICashflowActualDetailsTableData[]) => action(flattenArrayToObject(data, 0)),
    isLoading,
  };
};
