import { useUserContext } from '@/contexts/UserContext';
import { useGetBankAccountsForCompany } from '@/hooks/api-hooks/useBankAccountQuery';
import { useGetCategoriesByCompanyQuery } from '@/hooks/api-hooks/useCategoryQuery';
import { IBankTransactionsFilters } from '@/types/bank-transactions.types';
import { IOptions } from '@/types/common.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useSearchParams } from 'react-router-dom';
import Select, { MultiValue } from 'react-select';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { DatePickerWithRange } from '../ui/multi-datepicker';
import SelectCategoryDropdown from './SelectCategoryDropdown';

const BankTransactionsFilters = ({ handleSubmit }: { handleSubmit: (_: IBankTransactionsFilters) => void }) => {
  const [selectedBankAccounts, setSelectedBankAccounts] = useState<IOptions[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { companiesOfUser, activeCompanyIndex } = useUserContext();
  const [narration, setNarration] = useState<string>('');

  const { data: bankAccountsResponse } = useGetBankAccountsForCompany({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const { data: categoriesResponse } = useGetCategoriesByCompanyQuery({
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const banksMap = useMemo(() => {
    return bankAccountsResponse?.data.bankAccounts.reduce(
      (acc, bank) => {
        acc[bank.id] = bank.name;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [bankAccountsResponse]);

  const categoriesMap = useMemo(() => {
    return categoriesResponse?.data.reduce(
      (acc, category) => {
        acc[category.id] = category.value;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [categoriesResponse]);

  const [dateRange, setDateRange] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });

  useEffect(() => {
    if (!searchParams || !banksMap || !categoriesMap) {
      return;
    }

    const bankAccounts = searchParams.get('bankAccounts');
    const categories = searchParams.get('categories');
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const narration = searchParams.get('narration');

    setDateRange({
      from: startDate ? new Date(startDate) : undefined,
      to: endDate ? new Date(endDate) : undefined,
    });

    setSelectedBankAccounts(
      bankAccounts ? bankAccounts.split(',').map((account) => ({ value: account, label: banksMap[account] })) : [],
    );

    setSelectedCategories(categories ? categories.split(',') : []);

    setNarration(narration || '');
  }, [searchParams, setDateRange, setSelectedBankAccounts, setSelectedCategories, banksMap, categoriesMap]);

  const handleReset = () => {
    setDateRange({
      from: undefined,
      to: undefined,
    });
    setSelectedBankAccounts([]);
    setSelectedCategories([]);
    setNarration('');
    setSearchParams(
      {},
      {
        replace: true,
      },
    );
    handleSubmit({
      bankAccounts: [],
      categories: [],
      dateRange: {
        startDate: undefined,
        endDate: undefined,
      },
      narration: '',
    });
  };

  const handleDateRangeChange = (value: DateRange | undefined) => {
    if (value) {
      setDateRange(value);
    }
    setDateRange({
      from: value?.from,
      to: value?.to,
    });
  };

  const handleBankAccountsChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((account) => ({ value: account.value, label: account.label }));
    setSelectedBankAccounts(newValue);
  };

  const handleCategoriesChange = useCallback((value: string[]) => {
    const newValue = [...value];
    setSelectedCategories(newValue);
  }, []);

  const onSubmit = () => {
    setSearchParams(
      {
        bankAccounts: selectedBankAccounts.map((account) => account.value).join(','),
        categories: selectedCategories.join(','),
        startDate: dateRange?.from?.toISOString() || '',
        endDate: dateRange?.to?.toISOString() || '',
        narration: narration || '',
      },
      {
        replace: true,
      },
    );
    handleSubmit({
      bankAccounts: selectedBankAccounts.map((account) => account.value),
      categories: selectedCategories,
      dateRange: dateRange ? { startDate: dateRange.from, endDate: dateRange.to } : undefined,
      narration: narration || '',
    });
  };

  const defaultSelectedCategories = useMemo(() => {
    return searchParams.get('categories')?.split(',') || [];
  }, [searchParams]);

  const bankAccounts = useMemo(() => {
    return bankAccountsResponse?.data.bankAccounts || [];
  }, [bankAccountsResponse]);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, IOptions>();
  }, []);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  return (
    <div className="mb-6">
      <div className="flex gap-4 flex-wrap mb-2">
        <DatePickerWithRange value={dateRange} onChange={handleDateRangeChange} />
        <Select
          onChange={handleBankAccountsChange}
          value={selectedBankAccounts}
          className=" min-w-[200px] text-sm shadow-sm"
          classNames={selectClasses}
          isMulti={true}
          name="bank-accounts"
          options={bankAccounts.map((account) => ({ value: account.id, label: account.name }))}
          classNamePrefix="select"
          placeholder="Select bank accounts"
          styles={selectStyles}
        />
        <SelectCategoryDropdown
          defaultSelectedCategories={defaultSelectedCategories}
          selectedCategories={selectedCategories}
          onChange={handleCategoriesChange}
          categoriesMap={categoriesMap}
        />
        <Input
          value={narration}
          onChange={(e) => setNarration(e.target.value)}
          className="w-[400px] "
          placeholder="Search by narration"
        />

        <Button variant="default" onClick={onSubmit}>
          Apply
        </Button>
        <Button onClick={handleReset} variant="outline">
          Reset
        </Button>
      </div>
    </div>
  );
};

export default BankTransactionsFilters;
