import CashflowSummaryFilters from '@/components/cashflow/summary/CashflowSummaryFilters';
import SummaryData from '@/components/cashflow/summary/SummaryData';
import SummaryGraphWrapper from '@/components/cashflow/summary/SummaryGraph';
import { useCallback, useState } from 'react';

const CashflowSummary = ({
  dateFilter,
  selectedCategory,
  isCategory,
  graphType,
}: {
  dateFilter: {
    startDate: Date;
    endDate: Date;
  };
  selectedCategory: string;
  isCategory: boolean;
  graphType: string;
}) => {
  return (
    <>
      <div>
        <div className="w-full h-[400px]">
          <SummaryGraphWrapper
            isDaily={graphType === 'daily'}
            selectedCategory={selectedCategory}
            isCategory={isCategory}
            dateFilter={dateFilter}
          />
        </div>
      </div>
      <div className="my-4">
        <SummaryData selectedCategory={selectedCategory} isCategory={isCategory} dateFilter={dateFilter} />
      </div>
    </>
  );
};

const CashflowTrackingPage = () => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const [dateFilter, setDateFilter] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: new Date(year, month, 1),
    endDate: new Date(month === 11 ? year + 1 : year, (month + 1) % 12, 0),
  });
  const [selectedCategory, setSelectedCategory] = useState<string>('summary');
  const [isCategory, setIsCategory] = useState(false);
  const [graphType, setGraphType] = useState<'daily' | 'cumulative'>('daily');

  const handleSubmit = useCallback((value: { startDate: Date; endDate: Date }) => {
    setDateFilter({ ...value });
  }, []);

  return (
    <div className="md:px-16 sm:px-8 px-4 flex-1 mt-4">
      <CashflowSummaryFilters
        selectedCategory={selectedCategory}
        isCategory={isCategory}
        graphType={graphType}
        setGraphType={setGraphType}
        setIsCategory={setIsCategory}
        setSelectedCategory={setSelectedCategory}
        handleSubmit={handleSubmit}
      />
      <CashflowSummary
        graphType={graphType}
        selectedCategory={selectedCategory}
        isCategory={isCategory}
        dateFilter={dateFilter}
      />
    </div>
  );
};

export default CashflowTrackingPage;
