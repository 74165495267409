import { useGetFilteredActivities } from '@/hooks/api-hooks/useActivitiesQuery';
import { IActivity, IActivityFilters } from '@/types/activity.types';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../shared/Pagination';
import { Button } from '../ui/button';
import { Card, CardContent, CardTitle } from '../ui/card';
import Empty from '../ui/empty';
import { Skeleton } from '../ui/skeleton';
import ActivityFilters from './ActivityFilters';

import { ActivityCardIcon } from './ActivityIcon';
import { ActivityListCardAdditionalContent } from './events/EventDetails';

const ActivityCardSkeleton = () => {
  return (
    <Card className=" flex items-start p-4 gap-4 w-full  ">
      <ActivityCardIcon />
      <div className=" flex-1 flex flex-col gap-4 ">
        <CardTitle>
          <Skeleton className=" w-4/5 h-6 " />
        </CardTitle>
        <div className=" flex flex-col gap-2  ">
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-1/3 h-4 " />
        </div>
      </div>
      <div className=" px-4 flex flex-col gap-2 min-w-52 ">
        <Skeleton className=" w-full h-3 " />
        <Skeleton className=" w-full h-3 " />
        <Skeleton className=" w-full h-3 " />
      </div>
    </Card>
  );
};

const ActivityCardTitle = ({ activity }: { activity: IActivity }) => {
  if (activity.activityType === 'EMAIL') {
    return (
      <CardTitle className=" leading-5 ">
        <span className=" capitalize ">{activity.activityOriginType.toLowerCase()}</span>{' '}
        <span>{activity.activityOriginReferenceName ? `(${activity.activityOriginReferenceName})` : ''}</span>{' '}
        <span className=" text-muted-foreground font-normal ">sent</span>{' '}
        <Link className=" text-blue-700 underline" to={`/activities/${activity.activityReferenceId}`}>
          {activity.activityDescription.subject.replaceAll('<p>', ' ').replaceAll('</p>', ' ')}
        </Link>{' '}
        <span className=" text-muted-foreground font-normal ">to</span>{' '}
        <Link to={`/customer/view/${activity.customer.customerId}`} className=" text-blue-700 underline">
          {activity.customer.customerName}
        </Link>
      </CardTitle>
    );
  }

  if (activity.activityType === 'EMAIL_REPLY') {
    return (
      <CardTitle className=" flex items-center gap-1 leading-5 ">
        <Link className=" text-blue-700 underline" to={`/customer/view/${activity.customer.customerId}`}>
          {activity.customer.customerName}
        </Link>
        <span className=" text-muted-foreground font-normal">
          ({activity.activityDescription.fromEmail}) replied to{' '}
        </span>
        <Link className=" text-blue-700 underline " to={`/activities/${activity.activityReferenceId}`}>
          {activity.activityDescription.subject.replaceAll('<p>', ' ').replaceAll('</p>', ' ')}
        </Link>{' '}
      </CardTitle>
    );
  }

  return null;
};

const ActivityCardContent = ({ body }: { body: string }) => {
  return (
    <CardContent className="px-0 w-full">
      <div className=" w-full overflow-hidden ">
        <div
          className="text-sm max-h-16 overflow-hidden w-full"
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      </div>
    </CardContent>
  );
};

const ActivityCard = ({ activity }: { activity: IActivity }) => {
  const recipients = useMemo(() => {
    return [
      ...activity.activityDescription.recipients.to,
      ...activity.activityDescription.recipients.cc,
      ...activity.activityDescription.recipients.bcc,
    ];
  }, [
    activity.activityDescription.recipients.bcc,
    activity.activityDescription.recipients.cc,
    activity.activityDescription.recipients.to,
  ]);
  return (
    <Card className="h-[200px]">
      <div className=" flex items-start p-4 gap-4 w-full h-full ">
        <ActivityCardIcon
          hasAttachments={!!activity.activityDescription.attachments?.length}
          isReply={activity.activityType === 'EMAIL_REPLY'}
        />
        <div className=" flex-1 overflow-hidden  ">
          <ActivityCardTitle activity={activity} />
          <ActivityCardContent body={activity.activityDescription.body} />
          {activity.activityReferenceId && (
            <Link to={`/activities/${activity.activityReferenceId}`}>
              <Button variant="outline" className=" h-7 mt-2 ">
                <DotsHorizontalIcon />
              </Button>
            </Link>
          )}
        </div>

        <ActivityListCardAdditionalContent
          recipients={recipients}
          activityTimeStamp={activity.activityTimestamp}
          events={activity.activityEventDescription || []}
        />
      </div>
    </Card>
  );
};

const ActivityList = ({ activityFilters }: { activityFilters: IActivityFilters }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data: activitiesResponse, isLoading: isActivitiesDataLoading } = useGetFilteredActivities({
    page: page,
    pageSize: pageSize,
    ...activityFilters,
  });
  const activities = activitiesResponse?.data.docs;
  const activityCount = activitiesResponse?.data.totalDocs;

  if (isActivitiesDataLoading) {
    return (
      <>
        <div className=" flex flex-col gap-4 ">
          {new Array(10).fill(0).map((activity, index) => (
            <ActivityCardSkeleton key={index + activity} />
          ))}
        </div>
      </>
    );
  }

  if (!activities || activities.length < 1) {
    return <Empty title="No activities found" />;
  }

  return (
    <div className=" flex flex-col gap-4 ">
      <div
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        className="w-[500px] text-left font-light	"
      >
        <span className="text-sm font-normal">Count: {activityCount}</span>
      </div>
      {activities.map((activity) => (
        <ActivityCard key={activity.id} activity={activity} />
      ))}
      <Pagination
        hasNext={activitiesResponse?.data.hasNext}
        hasPrev={activitiesResponse?.data.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setPageSize}
        pageNumber={page}
        rowsPerPage={pageSize}
        totalPages={activitiesResponse?.data.totalPages}
      />
    </div>
  );
};

const ActivityListWrapper = () => {
  const [activityFilters, setActivityFilters] = useState<Partial<IActivityFilters>>({});

  const handleSubmit = useCallback((data: IActivityFilters) => {
    setActivityFilters(data);
  }, []);

  return (
    <div className="h-full overflow-scroll ">
      <ActivityFilters handleSubmit={handleSubmit} activityFilters={activityFilters} />
      <ActivityList activityFilters={activityFilters} />
    </div>
  );
};

export default ActivityListWrapper;
