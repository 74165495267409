import { dataAxiosInstance } from '@/lib/axios';
import {
  ICreateActionOnlyRequestObject,
  ICreateConditionalStateRequestObject,
  ICreateConditionObject,
  ICreateExitStateRequestObject,
  ICreateGotoRequestObject,
  ICreateTransitionRequestObject,
  ICreateTriggerStateRequestObject,
  ICreateWaitStateRequestObject,
  ICreateWorkflowRequestObject,
  IDateCondition,
  IEmailRequestObject,
} from '@/types/workflow.type';

const convertScheduleObjectToRequest = (
  schedule: ICreateWorkflowRequestObject['schedule'],
): ICreateWorkflowRequestObject['schedule'] => {
  if (schedule.frequency === 'DAY') {
    return {
      ...schedule,
      dayOfTheMonth: undefined,
      dayOfTheWeek: undefined,
    };
  }

  if (schedule.frequency === 'WEEK') {
    return {
      ...schedule,
      dayOfTheMonth: undefined,
    };
  }

  if (schedule.frequency === 'MONTH') {
    return {
      ...schedule,
      dayOfTheWeek: undefined,
    };
  }

  return {
    ...schedule,
  };
};

export const initiateWorkflow = async (
  workflowId: string,
  trigger: ICreateTriggerStateRequestObject,
  exit: ICreateExitStateRequestObject,
) => {
  const result = await dataAxiosInstance.post(`/workflow/initiate`, {
    workflowId,
    triggerState: trigger,
    exitState: exit,
  });

  return result.data;
};

export const createWorkflow = async (request: ICreateWorkflowRequestObject) => {
  const result = await dataAxiosInstance.post('/workflow/create', {
    ...request,
    customerSegments: request.customerSegments
      .filter((segment) => segment.value !== 'all')
      .map((segment) => segment.value),
    schedule: convertScheduleObjectToRequest(request.schedule),
  });

  return result.data;
};

export const createCondition = async (request: ICreateConditionObject) => {
  const result = await dataAxiosInstance.post('/workflow/condition', request);

  return result.data;
};

export const updateCondition = async (request: { conditionId: string; rule: ICreateConditionObject['rule'] }) => {
  const result = await dataAxiosInstance.post(`/workflow/condition/update/${request.conditionId}`, {
    ...request,
  });
  return result.data;
};

export const updateWaitCondition = async (request: { conditionId: string; rule: IDateCondition }) => {
  const result = await dataAxiosInstance.post(`/workflow/condition/wait/update/${request.conditionId}`, {
    ...request,
  });
  return result.data;
};

export const createTriggerState = async (request: ICreateTriggerStateRequestObject) => {
  const result = await dataAxiosInstance.post('/workflow/state/trigger', request);
  return result.data;
};

export const createExitState = async (request: ICreateExitStateRequestObject) => {
  const result = await dataAxiosInstance.post('/workflow/state/exit', request);
  return result.data;
};

export const createTransition = async (request: ICreateTransitionRequestObject) => {
  const result = await dataAxiosInstance.post('/workflow/action/transition', request);
  return result.data;
};

export const getWorkflow = async (workflowId: string) => {
  const result = await dataAxiosInstance.get(`/workflow/${workflowId}`);
  return result.data;
};

export const getTriggerStateById = async (triggerStateId: string) => {
  const result = await dataAxiosInstance.get(`/workflow/state/${triggerStateId}`);
  return result.data;
};

export const getConditionById = async (conditionId: string) => {
  const result = await dataAxiosInstance.get(`/workflow/condition/${conditionId}`);
  return result.data;
};

export const getActionById = async (actionId: string) => {
  const result = await dataAxiosInstance.get(`/workflow/action/${actionId}`);
  return result.data;
};

export const createEmailAction = async (request: IEmailRequestObject) => {
  const result = await dataAxiosInstance.post('/workflow/action/email', request);
  return result.data;
};

export const postUpdateTriggerState = async (request: {
  stateId: string;
  fields: (keyof ICreateTriggerStateRequestObject)[];
  values: Partial<ICreateTriggerStateRequestObject>;
}) => {
  const result = await dataAxiosInstance.post(`/workflow/state/trigger/update/${request.stateId}`, {
    updates: {
      ...request,
    },
  });
  return result.data;
};

export const postUpdateExitState = async (request: {
  stateId: string;
  fields: (keyof ICreateExitStateRequestObject)[];
  values: Partial<ICreateExitStateRequestObject>;
}) => {
  const result = await dataAxiosInstance.post(`/workflow/state/exit/update/${request.stateId}`, {
    updates: {
      ...request,
    },
  });
  return result.data;
};

export const postUpdateWaitState = async (request: {
  stateId: string;
  fields: (keyof ICreateWaitStateRequestObject)[];
  values: Partial<ICreateWaitStateRequestObject>;
}) => {
  const result = await dataAxiosInstance.post(`/workflow/state/wait/update/${request.stateId}`, {
    updates: {
      ...request,
    },
  });
  return result.data;
};

export const postUpdateActionOnly = async (request: {
  stateId: string;
  fields: (keyof ICreateActionOnlyRequestObject)[];
  values: Partial<ICreateActionOnlyRequestObject>;
}) => {
  const result = await dataAxiosInstance.post(`/workflow/state/action-only/update/${request.stateId}`, {
    updates: {
      ...request,
    },
  });
  return result.data;
};

export const postUpdateConditionalState = async (request: {
  stateId: string;
  fields: (keyof ICreateConditionalStateRequestObject)[];
  values: Partial<ICreateConditionalStateRequestObject>;
}) => {
  const result = await dataAxiosInstance.post(`/workflow/state/conditional/update/${request.stateId}`, {
    updates: {
      ...request,
    },
  });
  return result.data;
};

export const createActionOnlyState = async (request: ICreateActionOnlyRequestObject) => {
  const result = await dataAxiosInstance.post('/workflow/state/action-only', request);
  return result.data;
};

export const updateEmailAction = async (request: {
  actionId: string;
  fields: (keyof IEmailRequestObject)[];
  values: Partial<IEmailRequestObject>;
}) => {
  const result = await dataAxiosInstance.post(`/workflow/action/email/update/${request.actionId}`, {
    updates: {
      ...request,
    },
  });
  return result.data;
};

export const deleteAction = async (actionId: string) => {
  const result = await dataAxiosInstance.delete(`/workflow/action/delete/${actionId}`);
  return result.data;
};

export const deleteState = async (stateId: string) => {
  const result = await dataAxiosInstance.delete(`/workflow/state/delete/${stateId}`);
  return result.data;
};

export const createConditionalState = async (request: ICreateConditionalStateRequestObject) => {
  const result = await dataAxiosInstance.post('/workflow/state/conditional', request);
  return result.data;
};

export const createWaitState = async (request: ICreateWaitStateRequestObject) => {
  const result = await dataAxiosInstance.post('/workflow/state/wait', request);
  return result.data;
};

export const getWorkflows = async ({ page, limit }: { page: number; limit: number }) => {
  const result = await dataAxiosInstance.get(`workflow/search?page=${page}&pageSize=${limit}`);

  return result.data;
};

export const getWorkflowDryRunResults = async ({ workflowId }: { workflowId: string }) => {
  const result = await dataAxiosInstance.get(`/workflow/dryrun-result/${workflowId}`);

  return result.data;
};
export const postUpdateWorkflow = async (request: {
  fields: (keyof ICreateWorkflowRequestObject)[];
  values: ICreateWorkflowRequestObject;
  workflowId: string;
}) => {
  const result = await dataAxiosInstance.post(`workflow/update/${request.workflowId}`, {
    updates: {
      ...request,
      values: {
        ...request.values,
        customerSegments: request.values.customerSegments
          .filter((segment) => segment.value !== 'all')
          .map((segment) => segment.value),
      },
    },
  });

  return result.data;
};

export const publishWorkflow = async ({ workflowId }: { workflowId: string }) => {
  const result = await dataAxiosInstance.get(`/workflow/publish/${workflowId}`);

  return result.data;
};

export const deleteWorkflow = async ({ workflowId }: { workflowId: string }) => {
  const result = await dataAxiosInstance.delete(`/workflow/delete/${workflowId}`);

  return result.data;
};

export const runWorkflow = async ({ workflowId }: { workflowId: string }) => {
  const result = await dataAxiosInstance.get(`/workflow/execute/${workflowId}`);

  return result.data;
};

export const dryRunWorkflow = async ({ workflowId }: { workflowId: string }) => {
  const result = await dataAxiosInstance.post(`/workflow/dryrun/${workflowId}`);

  return result.data;
};

export const togglePauseWorkflow = async ({ workflowId }: { workflowId: string }) => {
  const result = await dataAxiosInstance.get(`/workflow/pause/${workflowId}`);

  return result.data;
};

export const createGotoAction = async (request: ICreateGotoRequestObject) => {
  const result = await dataAxiosInstance.post('/workflow/action/goto', {
    ...request,
    letSystemDecide: false,
  });
  return result.data;
};

export const updateGotoAction = async (request: {
  actionId: string;
  fields: (keyof ICreateGotoRequestObject)[];
  values: Partial<ICreateGotoRequestObject>;
}) => {
  const result = await dataAxiosInstance.post(`/workflow/action/goto/update/${request.actionId}`, {
    updates: {
      ...request,
    },
  });
  return result.data;
};
