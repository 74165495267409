import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import Empty from '@/components/ui/empty';
import Heading from '@/components/ui/heading';
import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { toast, useToast } from '@/components/ui/use-toast';
import {
  useDeleteCreditTerm,
  useGetCreditTerms,
  useGetCustomerExportCreditTerm,
  usePostMarkCreditTermAsDefault,
} from '@/hooks/api-hooks/useCreditTermsQuery';
import { cn } from '@/lib/utils';
import { CreditTermType, ICreditTerms } from '@/types/credit-term.types';

import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { CellContext, ColumnDef, flexRender, getCoreRowModel, RowData, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ChevronRightIcon, PlusIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

const MarkAsDefaultButton = ({ creditTermId }: { creditTermId: string }) => {
  const { toast } = useToast();

  const { mutate: markAsDefault } = usePostMarkCreditTermAsDefault({
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to create term. Please try again.',
        });
      },
    },
  });

  return (
    <DropdownMenuItem
      onClick={() =>
        markAsDefault({
          creditTermId,
        })
      }
    >
      Mark as default
    </DropdownMenuItem>
  );
};

const DeleteCreditTermDialog = ({
  creditTermId,
  isDefault,
  isDeleteDialogOpen,
  setIsDeleteDialogOpen,
  isCustomersPresent = false,
  isDefaultTermPresent = false,
}: {
  creditTermId: string;
  isDefault: boolean;
  isDeleteDialogOpen: boolean;
  setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCustomersPresent?: boolean;
  isDefaultTermPresent?: boolean;
}) => {
  const { toast } = useToast();

  const { mutate: deleteCreditTerm } = useDeleteCreditTerm({
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to create term. Please try again.',
        });
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
      },
    },
  });

  return (
    isDeleteDialogOpen && (
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
          <DialogHeader className=" font-semibold ">Delete term</DialogHeader>
          {isDefault ? (
            <>
              <p className=" text-sm ">
                You cannot delete the default term. Please mark another term as default, and try again.
              </p>
              <DialogFooter>
                <Button onClick={() => setIsDeleteDialogOpen(false)}>Close</Button>
              </DialogFooter>
            </>
          ) : (
            <>
              <p className="text-sm">
                {isDefaultTermPresent && isCustomersPresent && (
                  <>
                    All the customers associated to this term will be set to default term
                    <br />
                  </>
                )}
                {!isDefaultTermPresent && isCustomersPresent && (
                  <>
                    All the customers associated to this term will not have any term associated
                    <br />
                  </>
                )}
                Are you sure you want to delete this term?
              </p>
              <DialogFooter>
                <Button onClick={() => setIsDeleteDialogOpen(false)} variant="outline">
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    deleteCreditTerm({
                      creditTermId,
                    })
                  }
                >
                  Delete
                </Button>
              </DialogFooter>
            </>
          )}
        </DialogContent>
      </Dialog>
    )
  );
};

const ActionsCell = ({
  info,
  isDefaultTermPresent,
}: {
  info: CellContext<ICreditTerms<'MIN_GUARANTEE_TERM' | 'PAYMENT_TERM'>, unknown>;
  isDefaultTermPresent: boolean;
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { mutate: exportCreditTerm } = useGetCustomerExportCreditTerm({
    customConfig: {
      onSuccess: (data) => {
        const excelFile = data.data;
        const url = window.URL.createObjectURL(excelFile);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${info.row.original.title.split(' ').join('_').toLowerCase()}_customers_${dayjs().format(
          'DD-MM-YYYY-hh-mm',
        )}.xlsx`;
        a.click();
        toast({
          description: 'Customer details exported',
        });
      },

      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to download excel. Please try again.',
        });
      },
    },
  });

  return (
    <>
      <DeleteCreditTermDialog
        creditTermId={info.row.original.id}
        isDefault={info.row.original.isDefault}
        isDeleteDialogOpen={isDeleteDialogOpen}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        isCustomersPresent={!!info.row.original.customerCount}
        isDefaultTermPresent={isDefaultTermPresent}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <DotsHorizontalIcon className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem asChild>
            <Link to={`/credit-term/view/${info.row.original.id}`}>Edit term</Link>
          </DropdownMenuItem>
          {info.row.original.isDefault ? null : <MarkAsDefaultButton creditTermId={info.row.original.id} />}
          <DropdownMenuItem onClick={() => setIsDeleteDialogOpen(true)}>Delete</DropdownMenuItem>
          {!!info.row.original.customerCount && (
            <DropdownMenuItem onClick={() => exportCreditTerm({ creditTermId: info.row.original.id })}>
              Export Customers
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

declare module '@tanstack/react-table' {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    isDefaultTermPresent: {
      paymentTerm: boolean;
      guaranteeTerm: boolean;
    };
  }
}

const columns: ColumnDef<ICreditTerms<CreditTermType>>[] = [
  {
    accessorKey: 'title',
    header: 'Name',
    cell: ({ getValue, row }) => (row.original?.showTitle ? <div>{getValue() as string}</div> : null),
    size: 100,
  },
  {
    accessorKey: 'description',
    header: 'Legal Description',
    cell: ({ getValue, row }) => (
      <div className=" flex items-center gap-2 ">
        {row.original.isDefault ? <Badge>Default</Badge> : null}
        <Link to={`/credit-term/view/${row.original.id}`} className=" underline text-blue-700 ">
          {getValue() as string}
        </Link>
      </div>
    ),
    size: 500,
  },
  {
    accessorKey: 'customerCount',
    header: 'Customer Count',
    cell: ({ getValue }) => getValue() as number,
  },
  {
    accessorKey: 'status',
    header: 'Actions',
    cell: (info) => {
      return (
        <ActionsCell
          isDefaultTermPresent={
            info.row.original.type === 'PAYMENT_TERM'
              ? !!info.table.options.meta?.isDefaultTermPresent.paymentTerm
              : !!info.table.options.meta?.isDefaultTermPresent.guaranteeTerm
          }
          info={info}
        />
      );
    },
  },
];

const CreditTermsTable = ({
  data,
  isDefaultTermPresent,
}: {
  isDefaultTermPresent: {
    paymentTerm: boolean;
    guaranteeTerm: boolean;
  };
  data: {
    title: string;
    variants: ICreditTerms<CreditTermType>[];
  }[];
}) => {
  const tableData = useMemo(() => {
    return data.reduce((acc, curr) => {
      return [...acc, ...curr.variants];
    }, [] as ICreditTerms<CreditTermType>[]);
  }, [data]);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 50,
    },
    meta: {
      isDefaultTermPresent: {
        guaranteeTerm: isDefaultTermPresent.guaranteeTerm,
        paymentTerm: isDefaultTermPresent.paymentTerm,
      },
    },
  });
  return (
    <div>
      <Table>
        <TableHeader className=" bg-gray-100 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead {...header} className="px-6" key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                className={cn(' border-b-0 border-t ', !row.original.showTitle && 'border-none')}
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell width={cell.column.getSize()} {...cell} className="px-6" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const CreditTerms = () => {
  const { data, isLoading } = useGetCreditTerms({});

  const headingMap = useMemo<Record<CreditTermType, string>>(
    () => ({
      PAYMENT_TERM: 'Payment Terms',
      MIN_GUARANTEE_TERM: 'Minimum Guarantee Terms',
    }),
    [],
  );

  const isDefaultPresent = useMemo(() => {
    if (!data)
      return {
        paymentTerm: false,
        guaranteeTerm: false,
      };

    return {
      ...data.isDefaultPresent,
    };
  }, [data]);

  if (isLoading) {
    return (
      <div>
        <div>
          <Table>
            <TableHeader>
              <TableRow>
                {new Array(3).fill(0).map((_, i) => (
                  <TableHead className="px-6" key={i + 'credit_terms_header'}>
                    <Skeleton className=" w-36  h-6" />
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {new Array(5).fill(0).map((_, i) => (
                <TableRow key={i + 'credit_terms_cell'}>
                  {new Array(3).fill(0).map((_, i) => (
                    <TableCell className="px-6" key={i + 'plan_body_rows'}>
                      <Skeleton className="w-36 h-6" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div>
          <Table>
            <TableHeader>
              <TableRow>
                {new Array(3).fill(0).map((_, i) => (
                  <TableHead className="px-6" key={i + 'credit_terms_header'}>
                    <Skeleton className=" w-36  h-6" />
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {new Array(5).fill(0).map((_, i) => (
                <TableRow key={i + 'credit_terms_cell'}>
                  {new Array(3).fill(0).map((_, i) => (
                    <TableCell className="px-6" key={i + 'plan_body_rows'}>
                      <Skeleton className="w-36 h-6" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  if (!data?.data.length) {
    return <Empty title="No credit terms found" />;
  }

  return data.data.map((item) => (
    <div key={item.type} className=" mb-8">
      <h2 className=" font-semibold capitalize my-2  ">{headingMap[item.type]}</h2>
      <CreditTermsTable isDefaultTermPresent={isDefaultPresent} data={item.terms} />
    </div>
  ));
};

const CreateTermDialog = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button className=" flex items-center gap-4 " onClick={() => setIsModalOpen(true)}>
        Add Credit Term <PlusIcon className=" h-4 w-4 " />{' '}
      </Button>
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="bg-white p-5 max-w-lg ">
          <h2 className=" font-semibold ">Select type</h2>
          <Link to="/credit-term/templates/payment">
            <div className=" flex flex-col gap-4 text-sm border-secondary border px-2 py-4 rounded-md hover:bg-gray-50 hover:cursor-pointer ">
              <div>
                <h3 className=" font-semibold text-base flex items-center justify-between ">
                  Payment Term <ChevronRightIcon className="w-5 h-5" />
                </h3>
                <p>Defines when and how the payment has to be made</p>
              </div>
              <p className=" text-muted-foreground ">eg.- The customer has 90 days from the invoice date to pay.</p>
            </div>
          </Link>
          <Link to="/credit-term/templates/minimum_guarantee">
            <div className=" flex flex-col gap-4 text-sm border-secondary border px-2 py-4 rounded-md hover:bg-gray-50 hover:cursor-pointer ">
              <div>
                <h3 className=" font-semibold text-base flex items-center justify-between ">
                  Minimum Guarantee
                  <ChevronRightIcon className="w-5 h-5" />
                </h3>
                <p>Defines how much will be paid by the buyer irrespective of consumption/usage.</p>
              </div>
              <p className=" text-muted-foreground ">
                eg.- The customer will pay a minimum of INR 5,000 per month for the first 6 months.
              </p>
            </div>
          </Link>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ManageCreditTerms = () => {
  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8">
      <Heading title="Manage Credit Terms" cta={<CreateTermDialog />} />
      <CreditTerms />
    </div>
  );
};

export default ManageCreditTerms;
