import { useGetInvoiceSuggestionsByInvoiceNumber } from '@/hooks/api-hooks/useInvoiceQuery';
import { IOptions } from '@/types/common.types';
import { IInvoiceOption } from '@/types/invoices.types';
import { getClassNamesForSelectPurpose, getStylesForSelect } from '@/utils/getStylesForSelect';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GroupBase, MultiValue, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';

const MultiInvoiceNumberInput = ({
  value,
  onChange,
  placeholder,
}: {
  value: string[];
  onChange: (_: string[]) => void;
  placeholder?: string;
}) => {
  const [inputValue, setInputValue] = useState('');
  const [invoiceValue, setInvoiceValue] = useState<string[]>([]);

  const handleValueChange = (
    value: MultiValue<{
      label: string;
      value: string;
    }>,
  ) => {
    setInvoiceValue(value.map((v) => v.value));
    onChange(value.map((v) => v.value));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!inputValue) return;

    switch (e.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
      case ',':
        setInvoiceValue([...invoiceValue, inputValue]);
        onChange([...invoiceValue, inputValue]);

        setInputValue('');
        e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    // @ts-expect-error event error
    if (e.target?.id === 'multi-invoice-input') {
      const emails = e.clipboardData
        .getData('text/plain')
        .split(',')
        .map((invoiceNumber) => {
          return invoiceNumber.trim();
        })
        .filter(Boolean);

      setInvoiceValue([...invoiceValue, ...emails]);
      onChange([...invoiceValue, ...emails]);
    }

    e.preventDefault();
  };

  const selectClasses = useMemo(() => {
    return getClassNamesForSelectPurpose();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false | true, IOptions>();
  }, []);

  useEffect(() => {
    setInvoiceValue(value);
  }, [value]);

  return (
    <div onPaste={handlePaste}>
      <AsyncSelect
        id="multi-invoice-input"
        inputId="multi-invoice-input"
        className=" min-w-[150px] w-full text-sm shadow-sm"
        inputValue={inputValue}
        isMulti={true}
        onInputChange={(newValue, action) => {
          if (action.action === 'input-change') {
            setInputValue(newValue);
          }
        }}
        components={{
          DropdownIndicator: () => null,
        }}
        onChange={handleValueChange}
        menuIsOpen={false}
        onKeyDown={handleKeyDown}
        value={invoiceValue.map((v) => ({ label: v, value: v }))}
        placeholder={placeholder || 'Enter invoices number'}
        styles={selectStyles}
        classNames={selectClasses}
      />
    </div>
  );
};

export const MultiInvoiceNumberInputWithSuggestions = ({
  value,
  onChange,
  placeholder,
  className = '',
}: {
  value: string[];
  onChange: (_: string[]) => void;
  placeholder?: string;
  className?: string;
}) => {
  const INPUT_ID = 'multi-invoice-input-with-suggestions';
  const [invoiceValue, setInvoiceValue] = useState<string[]>([]);
  const { mutateAsync: fetchInvoices } = useGetInvoiceSuggestionsByInvoiceNumber({});

  const loadInvoices = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IOptions, GroupBase<IInvoiceOption>>) => void) => {
      fetchInvoices({
        invoiceNumber: inputValue.trim(),
      })
        .then((result) => {
          const options = result.data.docs
            .map((item) => ({
              value: item.invoiceNumber || '',
              label: item.invoiceNumber || '',
            }))
            .filter((item) => Boolean(item.value));

          callback(options);
        })
        .catch(() => callback([]));
      return;
    },
    [fetchInvoices],
  );

  const handleValueChange = (
    value: MultiValue<{
      label: string;
      value: string;
    }>,
  ) => {
    setInvoiceValue(value.map((v) => v.value));
    onChange(value.map((v) => v.value));
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    // @ts-expect-error event error
    if (e.target?.id === INPUT_ID) {
      const emails = e.clipboardData
        .getData('text/plain')
        .split(',')
        .map((invoiceNumber) => {
          return invoiceNumber.trim();
        })
        .filter(Boolean);

      setInvoiceValue([...invoiceValue, ...emails]);
      onChange([...invoiceValue, ...emails]);
    }

    e.preventDefault();
  };

  const selectClasses = useMemo(() => {
    return getClassNamesForSelectPurpose();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false | true, IOptions>();
  }, []);

  const debouncedInvoiceSuggestions = useMemo(() => {
    return debounce(loadInvoices, 500);
  }, [loadInvoices]);

  useEffect(() => {
    setInvoiceValue(value);
  }, [value]);

  return (
    <div onPaste={handlePaste}>
      <AsyncSelect
        id={INPUT_ID}
        inputId={INPUT_ID}
        className={className}
        isMulti={true}
        components={{
          DropdownIndicator: () => null,
        }}
        onChange={handleValueChange}
        loadOptions={debouncedInvoiceSuggestions}
        menuPortalTarget={document.body}
        value={invoiceValue.map((v) => ({ label: v, value: v }))}
        placeholder={placeholder || 'Enter invoices number'}
        styles={selectStyles}
        classNames={selectClasses}
      />
    </div>
  );
};

export default MultiInvoiceNumberInput;
