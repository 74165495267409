import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import { useGetCustomerSuggestionsMutation } from '@/hooks/api-hooks/useCustomerQuery';
import {
  useGetPlaceholders,
  usePostCreateBulkCreditPosting,
  usePostDeletePosting,
} from '@/hooks/api-hooks/usePostingQuery';
import { cn } from '@/lib/utils';
import { IOptions } from '@/types/common.types';
import {
  IInvoiceToBatPostingRequest,
  IInvoiceWithPosting,
  IPlaceholder,
  IPlaceholderToBatPostingRequest,
  IPlaceHolderWithPosting,
} from '@/types/posting.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import { toFixed } from '@/utils/toFixed';
import { Loader2Icon, Trash2Icon, XIcon } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFieldArrayRemove,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormStateReturn,
  UseFormWatch,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { PostAsDropDown } from './PostAsDropDown';

const InvoicePostingItem = ({
  invoice,
  register,
  index,
  error,
  totalCredit,
  watch,
  handleRemoveInvoice,
}: {
  invoice: IInvoiceWithPosting;
  register: UseFormRegister<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>;
  index: number;
  error?: Merge<FieldError, FieldErrorsImpl<IInvoiceWithPosting>>;
  totalCredit: number;
  watch: UseFormWatch<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>;
  handleRemoveInvoice: (_: string) => void;
}) => {
  const postedAmountString = watch(`invoices.${index}.postedAmount`);
  const postedAmount = Number(postedAmountString);
  const invoices = watch('invoices');
  const placeholders = watch('placeholders');

  const { toast } = useToast();

  const { transactionId } = useParams();

  const { mutate: deletePosting, isPending } = usePostDeletePosting({
    creditId: transactionId as string,
    customConfig: {
      onSuccess() {
        toast({
          description: 'Posting deleted successfully',
        });
      },
      onError(error) {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to delete posting',
        });
      },
    },
  });

  const remainingAmount = toFixed(
    totalCredit -
      invoices.reduce((acc, curr) => {
        if (curr.invoiceId === invoice.invoiceId) {
          return acc;
        }
        return acc + Number(curr.postedAmount) - Number(curr.alreadyPostedAmount);
      }, 0) -
      placeholders.reduce((acc, curr) => {
        return acc + curr.amount - curr.alreadyPostedAmount;
      }, 0),
    2,
  );

  return (
    <div className=" border-b pb-2 ">
      <div className=" flex items-center justify-between ">
        <div>
          <h3>
            {index + 1}. {invoice.invoiceNumber}
          </h3>
          <p className=" text-muted-foreground w-full truncate text-ellipsis">
            {invoice.lineItems?.at(0)?.lineItemDetails?.name}
          </p>
        </div>
        {!!invoice.isDefaultSelected && (
          <Button
            onClick={() => deletePosting({ postingId: invoice.postingId || '' })}
            variant="ghost"
            size="icon"
            className=" text-destructive "
          >
            {isPending ? <Loader2Icon className="w-4 h-4 animate-spin" /> : <Trash2Icon className="w-4 h-4" />}
          </Button>
        )}
        {!invoice.isDefaultSelected && (
          <Button onClick={() => handleRemoveInvoice(invoice.invoiceId || '')} variant="ghost" size="icon">
            <XIcon className="w-4 h-4" />
          </Button>
        )}
      </div>
      <div className=" flex items-center gap-2 ">
        <div className=" relative bottom-3 ">₹</div>
        <div className=" flex-1 ">
          <Input
            {...register(`invoices.${index}.postedAmount`, {
              required: {
                value: true,
                message: 'Amount is required',
              },
              pattern: {
                value: /^\d*\.?\d*$/i,
                message: 'Invalid amount',
              },
              min: {
                value: 1,
                message: `Amount must be greater than ${formatCurrencyByUnit(0, 'actual')}`,
              },
              max: {
                value: remainingAmount + invoice.alreadyPostedAmount,
                message: `Amount must be less than ${formatCurrencyByUnit(
                  remainingAmount + invoice.alreadyPostedAmount,
                  'actual',
                )}`,
              },
            })}
            className=" my-2 "
            placeholder={`Enter posting amount for ${invoice.invoiceNumber}`}
          />
          <p className=" text-xs text-destructive h-4 ">{error?.postedAmount?.message}</p>
        </div>
      </div>
      {postedAmount - invoice.invoiceOutstandingAmount - invoice.alreadyPostedAmount < 0 && (
        <div className=" text-sm text-muted-foreground items-center pl-0.5">
          <span>
            {formatCurrencyByUnit(
              invoice.invoiceOutstandingAmount - postedAmount + invoice.alreadyPostedAmount,
              'actual',
            )}
          </span>{' '}
          remaining
        </div>
      )}
      {postedAmount - invoice.invoiceOutstandingAmount - invoice.alreadyPostedAmount > 0 && (
        <div className=" text-muted-foreground flex items-center gap-2 ">
          <span>
            {formatCurrencyByUnit(
              postedAmount - invoice.invoiceOutstandingAmount - invoice.alreadyPostedAmount,
              'actual',
            )}
          </span>{' '}
          exceeded
        </div>
      )}
    </div>
  );
};

const PlaceholderPostingItem = ({
  register,
  index,
  error,
  totalCredit,
  watch,
  handleRemovePlaceholder,
  placeholder,
  control,
}: {
  register: UseFormRegister<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>;
  index: number;
  error?: Merge<FieldError, FieldErrorsImpl<IPlaceHolderWithPosting>>;
  totalCredit: number;
  watch: UseFormWatch<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>;
  handleRemovePlaceholder: () => void;
  placeholder: IPlaceHolderWithPosting;
  control: Control<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>;
}) => {
  const invoices = watch('invoices');
  const placeholders = watch('placeholders');
  const remainingAmount = toFixed(
    totalCredit -
      invoices.reduce((acc, curr) => {
        return acc + Number(curr.postedAmount) - Number(curr.alreadyPostedAmount);
      }, 0) -
      placeholders.reduce((acc, curr) => {
        if (curr.placeholderId === placeholder.placeholderId) {
          return acc;
        }

        return acc + curr.amount - curr.alreadyPostedAmount;
      }, 0),
    2,
  );

  const { data: allPlaceholders, isLoading } = useGetPlaceholders();

  const placeholderIdToNameMap = useMemo<Record<string, string>>(() => {
    return (
      allPlaceholders?.data.reduce(
        (acc, item) => {
          return {
            ...acc,
            [item.id]: item.name,
          };
        },
        {} as Record<string, string>,
      ) || {}
    );
  }, [allPlaceholders]);

  const { transactionId } = useParams();

  const { toast } = useToast();

  const { mutateAsync: fetchCustomers } = useGetCustomerSuggestionsMutation({});

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchCustomers({
        query: inputValue.trim(),
      });
      return result.data.suggestions.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    [fetchCustomers],
  );

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false, IOptions>();
  }, []);

  const { mutate: deletePosting, isPending } = usePostDeletePosting({
    creditId: transactionId as string,
    customConfig: {
      onSuccess() {
        handleRemovePlaceholder();
      },
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to delete posting',
          variant: 'destructive',
        });
      },
    },
  });

  return (
    <div className=" border-b pb-2 ">
      <div className=" flex items-center justify-between ">
        <h3 className="capitalize">
          {isLoading ? (
            <Skeleton className=" w-32 h-4 " />
          ) : (
            (placeholderIdToNameMap && placeholderIdToNameMap[placeholder.placeholderId]?.toLowerCase()) || ''
          )}
        </h3>
        {placeholder.isAlreadyPosted ? (
          <Button
            onClick={() =>
              deletePosting({
                postingId: placeholder.postingId || '',
              })
            }
            size="icon"
            className=" text-destructive "
            variant="ghost"
          >
            {!isPending && <Trash2Icon className=" h-4 w-4 " />}
            {isPending && <Loader2Icon className=" h-4 w-4 animate-spin " />}
          </Button>
        ) : (
          <Button onClick={handleRemovePlaceholder} size="icon" variant="ghost">
            <XIcon className=" h-4 w-4 " />
          </Button>
        )}
      </div>
      <div className=" flex items-center gap-2 w-full ">
        <div className=" bottom-3 ">₹</div>
        <div className=" flex-1 ">
          <Input
            {...register(`placeholders.${index}.amount`, {
              required: {
                value: true,
                message: 'Amount is required',
              },
              pattern: {
                value: /^\d*\.?\d*$/i,
                message: 'Invalid amount',
              },
              max: {
                value: remainingAmount + placeholder.alreadyPostedAmount,
                message: `Amount must be less than ${formatCurrencyByUnit(
                  remainingAmount + placeholder.alreadyPostedAmount,
                  'actual',
                )}`,
              },
              min: {
                value: 1,
                message: `Amount must be greater than ${formatCurrencyByUnit(0, 'actual')}`,
              },
            })}
            className=" my-2 flex-1 "
            placeholder={`Enter amount`}
          />
          <p className=" text-xs text-destructive ">{error?.amount?.message}</p>
        </div>
      </div>
      <div className=" w-full my-2 ">
        <div>
          <Controller
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Customer is required',
              },
              validate: {
                required: (value) => !!value?.value || 'Customer is required',
              },
            }}
            name={`placeholders.${index}.customer`}
            render={({ field }) => (
              <AsyncSelect
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                onChange={(value) => {
                  field.onChange(value);
                }}
                value={field.value}
                loadOptions={loadSelectOptions}
                className=" min-w-[200px] text-sm shadow-sm"
                styles={selectStyles}
                placeholder="Select customer"
                classNames={selectClasses}
              />
            )}
          />
        </div>
        <p className=" text-xs text-destructive ">{error?.customer?.message}</p>
      </div>
    </div>
  );
};

const SelectedRows = ({
  postedInvoices,
  postedPlaceholders,
  totalCredit,
  watch,
  control,
  handleSubmit,
  appendPlaceholder,
  removePlaceholder,
  handleRemoveInvoice,
  register,
  errors,
  isDirty,
  isLoading,
  creditPlaceholders,
}: {
  postedInvoices: IInvoiceWithPosting[];
  postedPlaceholders: IPlaceHolderWithPosting[];
  totalCredit: number;
  watch: UseFormWatch<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: UseFormHandleSubmit<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>;
  appendPlaceholder: (_: IPlaceHolderWithPosting) => void;
  removePlaceholder: UseFieldArrayRemove;
  register: UseFormRegister<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>;
  errors: UseFormStateReturn<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>['errors'];
  isDirty: boolean;
  reset: UseFormReset<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>;
  control: Control<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>;
  isLoading: boolean;
  handleRemoveInvoice: (_: string) => void;
  creditPlaceholders?: IPlaceholder[];
}) => {
  const creditsRemaining = toFixed(
    totalCredit -
      postedInvoices.reduce((acc, item) => acc + Number(item.postedAmount) - Number(item.alreadyPostedAmount), 0) -
      postedPlaceholders.reduce((acc, item) => acc + Number(item.amount) - Number(item.alreadyPostedAmount), 0),
    2,
  );

  const { toast } = useToast();

  const { transactionId } = useParams();

  const { mutate: createPosting, isPending } = usePostCreateBulkCreditPosting({
    batId: transactionId as string,
    customConfig: {
      onSuccess() {
        toast({
          description: 'Credit posting created successfully',
        });
      },
      onError(error) {
        toast({
          variant: 'destructive',
          description: error?.response?.data?.message || 'Unable to create credit posting',
        });
      },
    },
  });

  const handleAddPlaceholder = useCallback(
    (placeholder?: IPlaceholder) => {
      const placeholders = watch('placeholders');

      if (placeholder) {
        const newlyPostedInvoice = postedInvoices.reduce((acc, curr) => {
          return Number(acc) + Number(curr.postedAmount) - Number(curr.alreadyPostedAmount);
        }, 0);
        const postedPlaceholderAmount = placeholders.reduce((acc, curr) => {
          return Number(acc) + Number(curr.amount) - Number(curr.alreadyPostedAmount);
        }, 0);

        const remaining = toFixed(totalCredit - newlyPostedInvoice - postedPlaceholderAmount, 2);

        appendPlaceholder({
          amount: remaining,
          name: placeholder.name,
          placeholderId: placeholder.id,
          alreadyPostedAmount: 0,
          customer: undefined,
        });
      }
    },
    [appendPlaceholder, postedInvoices, totalCredit, watch],
  );

  const handleRemovePlaceholder = (index: number) => () => {
    removePlaceholder(index);
  };

  const onSubmit = (data: { invoices: IInvoiceWithPosting[]; placeholders: IPlaceHolderWithPosting[] }) => {
    const invoiceToBatPostings: IInvoiceToBatPostingRequest[] = data.invoices.map((item) => ({
      bankAccountTransactionId: transactionId as string,
      postedAmount: Number(item.postedAmount),
      invoiceId: item.invoiceId,
    }));

    const placeholderToBatPostings: IPlaceholderToBatPostingRequest[] = data.placeholders.map((item) => ({
      placeholderId: item.placeholderId,
      postedAmount: Number(item.amount),
      bankAccountTransactionId: transactionId as string,
      customerId: item.customer?.value || '',
    }));

    createPosting({
      request: {
        invoicePostings: invoiceToBatPostings,
        placeholderPostings: placeholderToBatPostings,
      },
    });
  };

  if (isLoading) {
    return (
      <div className=" flex flex-col text-sm h-full">
        <div className={cn(' py-2 px-4 flex justify-between border-b items-center  ')}>
          <h2 className=" font-semibold text-base"> Post Credits</h2>
        </div>
        <div className=" px-4 flex flex-col gap-3 py-4 ">
          {new Array(4).fill(0).map((_, index) => (
            <div key={index + 'skeleton-loading-selected-items'} className=" flex flex-col gap-1 ">
              <Skeleton className=" h-4 w-32 " />
              <Skeleton className=" h-4 w-full " />
              <Skeleton className=" h-5 w-full " />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className=" flex flex-col text-sm h-full">
      <h2 className=" py-2 px-4 font-semibold text-base flex justify-between border-b items-center ">Post Credit</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="posting-form"
        className=" px-4 my-2 flex flex-col gap-4 flex-1 overflow-scroll "
      >
        {postedInvoices.map((invoice, index) => (
          <InvoicePostingItem
            totalCredit={totalCredit}
            watch={watch}
            key={invoice.id}
            index={index}
            error={errors?.invoices?.[index]}
            register={register}
            invoice={invoice}
            handleRemoveInvoice={handleRemoveInvoice}
          />
        ))}
        <div>
          {postedPlaceholders.map((placeholder, index) => (
            <PlaceholderPostingItem
              control={control}
              placeholder={placeholder}
              handleRemovePlaceholder={handleRemovePlaceholder(index)}
              watch={watch}
              totalCredit={totalCredit}
              key={placeholder.placeholderId}
              index={index}
              error={errors?.placeholders?.[index]}
              register={register}
            />
          ))}
        </div>
        {creditPlaceholders && creditPlaceholders.length > 0 && (
          <div>
            <PostAsDropDown handleAddPlaceholder={handleAddPlaceholder} creditPlaceholders={creditPlaceholders} />
          </div>
        )}
      </form>
      <div className=" border-t px-4 py-2 flex justify-between gap-1  ">
        <div>
          <h4 className=" text-muted-foreground ">Credit available for posting</h4>
          <p className={cn(' font-semibold ', creditsRemaining < 0 && ' text-destructive')}>
            {formatCurrencyByUnit(creditsRemaining, 'actual')}
          </p>
        </div>
        <div className=" flex items-center justify-end ">
          <Button
            disabled={!isDirty || isPending}
            form="posting-form"
            type="submit"
            className=" flex items-center gap-2 "
          >
            Post
            {isPending && <Loader2Icon className="animate-spin h-4 w-4" />}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectedRows;
