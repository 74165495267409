import MinimumGuaranteeTerm from '@/components/credit-terms/minimum-gurantee-term/MinGuranteeTermComponent';
import PaymentTerm from '@/components/credit-terms/payment-term/PaymentTermComponent';
import { Button } from '@/components/ui/button';
import { Card, CardHeaderWithCTA, CardTitle } from '@/components/ui/card';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Section } from '@/components/ui/page-section-card';
import SelectComponent from '@/components/ui/select-component';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import {
  useGetCreditTermsByCustomerId,
  useGetCreditTermTitleSuggestions,
  useGetCreditTermVariantsByTitle,
  usePostAddCustomerTermRelationship,
  usePostRemoveCustomerTermRelationship,
} from '@/hooks/api-hooks/useCreditTermsQuery';
import { CreditTermType, ICreditTerms } from '@/types/credit-term.types';
import { CardContent } from '@mui/material';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { ChevronRightIcon, PlusIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';

const ChangeCustomerTermDialog = ({
  isChangeTermDialogOpen,
  setIsChangeTermDialogOpen,
  handleEdit,
  handleCancel,
}: {
  isChangeTermDialogOpen: boolean;
  setIsChangeTermDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleEdit: () => void;
  handleCancel: () => void;
}) => {
  return (
    isChangeTermDialogOpen && (
      <Dialog open={isChangeTermDialogOpen} onOpenChange={setIsChangeTermDialogOpen}>
        <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
          <DialogHeader className=" font-semibold ">Confirm Change Term</DialogHeader>
          <p className=" text-sm ">Are you sure you want to change term for this customer?</p>
          <DialogFooter>
            <Button onClick={handleCancel} variant="outline">
              Cancel
            </Button>
            <Button onClick={() => handleEdit()}>Continue</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  );
};

const RemoveCustomerTermRelation = ({
  creditTermId,
  isDefault,
  isDeleteDialogOpen,
  setIsDeleteDialogOpen,
  customerId,
}: {
  creditTermId: string;
  isDefault: boolean;
  isDeleteDialogOpen: boolean;
  setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customerId: string;
}) => {
  const { toast } = useToast();

  const { mutate: removeCustomerTermRelation } = usePostRemoveCustomerTermRelationship({
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to remove term. Please try again.',
        });
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
      },
    },
  });

  return (
    isDeleteDialogOpen && (
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
          <DialogHeader className=" font-semibold ">Delete term</DialogHeader>
          {isDefault ? (
            <>
              <p className=" text-sm ">
                You cannot delete the default term. Please mark another term as default, and try again.
              </p>
              <DialogFooter>
                <Button onClick={() => setIsDeleteDialogOpen(false)}>Close</Button>
              </DialogFooter>
            </>
          ) : (
            <>
              <p className="text-sm">Are you sure you want to delete this term for this customer?</p>
              <DialogFooter>
                <Button onClick={() => setIsDeleteDialogOpen(false)} variant="outline">
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    removeCustomerTermRelation({
                      creditTermId,
                      customerId,
                    })
                  }
                >
                  Delete
                </Button>
              </DialogFooter>
            </>
          )}
        </DialogContent>
      </Dialog>
    )
  );
};

const EditTermDialog = ({
  isEditTermDialogOpen,
  setIsEditTermDialogOpen,
  termType,
  termDetails,
  customerId,
}: {
  isEditTermDialogOpen: boolean;
  setIsEditTermDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  termType: CreditTermType;
  termDetails: ICreditTerms<CreditTermType>;
  customerId: string;
}) => {
  return (
    isEditTermDialogOpen && (
      <Dialog open={isEditTermDialogOpen}>
        <DialogContent className="min-w-[600px] bg-transparent shadow-none border-none p-4 border-2 rounded-md">
          {termType === 'PAYMENT_TERM' ? (
            <PaymentTerm
              isModal
              creditTermDetails={termDetails as ICreditTerms<'PAYMENT_TERM'>}
              creditTermId={termDetails.id}
              isNew={true}
              handleClose={() => setIsEditTermDialogOpen(false)}
              customerId={customerId}
            />
          ) : (
            <MinimumGuaranteeTerm
              isModal
              creditTermDetails={termDetails as ICreditTerms<'MIN_GUARANTEE_TERM'>}
              creditTermId={termDetails.id}
              isNew={true}
              handleClose={() => setIsEditTermDialogOpen(false)}
              customerId={customerId}
            />
          )}
        </DialogContent>
      </Dialog>
    )
  );
};

const ActionsCell = ({
  setIsEditTermDialogOpen,
  handleRemoveTerm,
}: {
  setIsEditTermDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleRemoveTerm: () => void;
}) => {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <DotsHorizontalIcon className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setIsEditTermDialogOpen(true)}>Edit Term</DropdownMenuItem>
          <DropdownMenuItem onClick={handleRemoveTerm}>Delete Term</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

const CustomerCreditTermRow = ({
  term,
  customerId,
  handleRemoveTerm,
  index,
}: {
  term: ICreditTerms<CreditTermType>;
  customerId: string;
  handleRemoveTerm: (_: number) => void;
  index: number;
}) => {
  const [selectedTitle, setSelectedTitle] = useState(term.title);
  const [selectedVariant, setSelectedVariant] = useState(term.description);
  const [isEditTermDialogOpen, setIsEditTermDialogOpen] = useState(false);
  const [isChangeTermDialogOpen, setIsChangeTermDialogOpen] = useState(false);
  const [isRemoveTermDialogOpen, setIsRemoveTermDialogOpen] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    setSelectedVariant(term.id);
  }, [term.id]);

  useEffect(() => {
    setSelectedTitle(term.title);
  }, [term.title]);

  const { data: titleSuggestions } = useGetCreditTermTitleSuggestions({
    type: term.type,
  });

  const { mutate: addCustomerTermRelation } = usePostAddCustomerTermRelationship({
    customConfig: {
      onSettled: () => {
        setIsChangeTermDialogOpen(false);
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to create term. Please try again.',
        });
      },
    },
  });

  const {
    data: creditTermVariants,
    isLoading,
    isRefetching,
  } = useGetCreditTermVariantsByTitle({
    title: selectedTitle,
    creditTermType: term.type,
    customConfig: {
      enabled: !!selectedTitle || !!term.type,
    },
  });

  const suggestionOptions = useMemo(() => {
    if (term.title) {
      return [
        {
          label: term.title,
          value: term.title,
        },
        ...(titleSuggestions?.data.suggestions
          .map((suggestion) => ({
            label: suggestion,
            value: suggestion,
          }))
          .filter((suggestion) => suggestion.value !== term.title) || []),
      ];
    }

    return (
      titleSuggestions?.data.suggestions
        .map((suggestion) => ({
          label: suggestion,
          value: suggestion,
        }))
        .filter((suggestion) => suggestion.value !== term.title) || []
    );
  }, [titleSuggestions?.data.suggestions, term.title]);

  const creditTermVariantSuggestions = useMemo(() => {
    if (term.id && term.title === selectedTitle) {
      return [
        {
          label: term.description,
          value: term.id,
          termValue: term,
        },
        ...(creditTermVariants?.data
          .map((creditTermVariant) => ({
            label: creditTermVariant.description,
            value: creditTermVariant.id,
            termValue: creditTermVariant,
          }))
          .filter((creditTermVariant) => creditTermVariant.value !== term.id) || []),
      ];
    }

    return [
      ...(creditTermVariants?.data.map((creditTermVariant) => ({
        label: creditTermVariant.description,
        value: creditTermVariant.id,
        termValue: creditTermVariant,
      })) || []),
    ];
  }, [creditTermVariants?.data, term, selectedTitle]);

  const isNew = useMemo(() => {
    return !term.id && !term.title;
  }, [term.id, term.title]);

  const handleEdit = () => {
    if (selectedTitle && selectedVariant) {
      addCustomerTermRelation({
        customerId,
        creditTermId: selectedVariant,
      });
    } else {
      toast({
        variant: 'destructive',
        description: 'Please select title and variant',
      });
    }
  };

  const handleDeleteNewTerm = () => {
    handleRemoveTerm(index);
  };

  const handleDeleteTerm = () => {
    if (isNew) {
      handleDeleteNewTerm();
      return;
    }

    if (term.id) {
      setIsRemoveTermDialogOpen(true);
      return;
    }
  };

  const handleTermChange = (value: string) => {
    setSelectedVariant(value);
    setIsChangeTermDialogOpen(true);
  };

  const handleTitleChange = (value: string) => {
    setSelectedTitle(value);
    setSelectedVariant('');
  };

  const handleCancel = () => {
    setIsChangeTermDialogOpen(false);
    setSelectedTitle(term.title);
    setSelectedVariant(term.id);
  };

  return (
    <>
      <div className=" flex items-center gap-4 ">
        <SelectComponent
          placeholder="Select term"
          options={suggestionOptions}
          value={selectedTitle}
          onChange={handleTitleChange}
        />
        <SelectComponent
          className="flex-1"
          options={creditTermVariantSuggestions}
          value={selectedVariant}
          onChange={handleTermChange}
          placeholder="Select term variant"
          viewPortClassName="max-w-[var(--radix-select-trigger-width)]"
          isLoading={isLoading || isRefetching}
          disabled={!selectedTitle.trim()}
        />
        <ActionsCell handleRemoveTerm={handleDeleteTerm} setIsEditTermDialogOpen={setIsEditTermDialogOpen} />
      </div>
      <EditTermDialog
        customerId={customerId}
        termDetails={term}
        termType={term.type}
        isEditTermDialogOpen={isEditTermDialogOpen}
        setIsEditTermDialogOpen={setIsEditTermDialogOpen}
      />
      <ChangeCustomerTermDialog
        handleEdit={handleEdit}
        handleCancel={handleCancel}
        isChangeTermDialogOpen={isChangeTermDialogOpen}
        setIsChangeTermDialogOpen={setIsChangeTermDialogOpen}
      />
      <RemoveCustomerTermRelation
        isDeleteDialogOpen={isRemoveTermDialogOpen}
        setIsDeleteDialogOpen={setIsRemoveTermDialogOpen}
        creditTermId={term.id}
        customerId={customerId}
        isDefault={term.isDefault}
      />
    </>
  );
};

const CustomerCreditTerms = ({
  creditTerms,
  customerId,
  handleRemoveTerm,
}: {
  creditTerms: ICreditTerms<CreditTermType>[];
  customerId: string;
  handleRemoveTerm: (_: number) => void;
}) => {
  if (!creditTerms.length) {
    return <p className=" text-sm text-center w-full ">No credit terms found</p>;
  }

  return creditTerms.map((creditTerm: ICreditTerms<CreditTermType>, index: number) => (
    <div key={creditTerm.type} className=" my-1">
      <h3 className=" capitalize text-sm font-semibold my-0.5 flex items-center gap-1 ">
        {creditTerm.type.toLowerCase().split('_').join(' ')}
        {creditTerm.isDefault && <span className=" text-sm font-normal italic ">(Default)</span>}
      </h3>
      <CustomerCreditTermRow
        handleRemoveTerm={handleRemoveTerm}
        customerId={customerId}
        key={index}
        index={index}
        term={creditTerm}
      />
    </div>
  ));
};

export const CreditTermsDetails = ({ customerId }: { customerId: string }) => {
  const { data: creditTerms, isLoading } = useGetCreditTermsByCustomerId({
    customerId,
    customConfig: {
      enabled: !!customerId,
    },
  });

  const [creditTermsArray, setCreditTermsArray] = useState<ICreditTerms<CreditTermType>[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (creditTerms) {
      setCreditTermsArray(creditTerms.data);
    }
  }, [creditTerms]);

  const handleAddTerm = (type: CreditTermType) => {
    setCreditTermsArray((prev) => {
      return [
        ...prev,
        {
          type,
          terms: {},
          description: '',
          id: '',
          isDefault: false,
          title: '',
          metaData: null,
        },
      ];
    });
  };

  const handleRemoveTerm = (index: number) => {
    setCreditTermsArray((prev) => {
      return prev.filter((_, i) => i !== index);
    });
  };

  const isMinGuranteeTermPresent = useMemo(() => {
    return creditTermsArray.some((term) => term.type === 'MIN_GUARANTEE_TERM');
  }, [creditTermsArray]);

  const isPaymentTermPresent = useMemo(() => {
    return creditTermsArray.some((term) => term.type === 'PAYMENT_TERM');
  }, [creditTermsArray]);

  const handleClick = (type: CreditTermType) => {
    handleAddTerm(type);
    setIsModalOpen(false);
  };

  if (isLoading) {
    return (
      <Section title="Credit terms">
        <div className=" py-8 flex flex-col gap-4 w-full ">
          <div className=" flex flex-col gap-1 ">
            <Skeleton className=" h-7 w-40 " />
            <div className=" flex items-center gap-4 ">
              <Skeleton className=" h-7 flex-1 " />
              <Skeleton className=" h-7 flex-1 " />
              <Skeleton className=" h-5 w-8 " />
            </div>
          </div>
          <div className=" flex flex-col gap-1 ">
            <Skeleton className=" h-7 w-40 " />
            <div className=" flex items-center gap-4 ">
              <Skeleton className=" h-7 flex-1 " />
              <Skeleton className=" h-7 flex-1 " />
              <Skeleton className=" h-5 w-8 " />
            </div>
          </div>
        </div>
      </Section>
    );
  }

  return (
    <Card className=" my-8 ">
      <CardHeaderWithCTA
        className=" border-b "
        cardTitle={<CardTitle className=" underline font-semibold ">Credit terms</CardTitle>}
        cta={
          !(isPaymentTermPresent && isMinGuranteeTermPresent) && (
            <Button className=" gap-4 text-sm " onClick={() => setIsModalOpen(true)}>
              Add Credit term <PlusIcon className=" h-4 w-4 " />{' '}
            </Button>
          )
        }
      />
      <CardContent className=" flex flex-col gap-4 ">
        <CustomerCreditTerms
          handleRemoveTerm={handleRemoveTerm}
          creditTerms={creditTermsArray}
          customerId={customerId}
        />
        <div className=" flex justify-end ">
          {!(isPaymentTermPresent && isMinGuranteeTermPresent) && (
            <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
              <DialogContent className="bg-white p-5 max-w-lg ">
                <h2 className=" font-semibold ">Select type</h2>
                {!isPaymentTermPresent && (
                  <div
                    onClick={() => handleClick('PAYMENT_TERM')}
                    className=" flex flex-col gap-4 text-sm border-secondary border px-2 py-4 rounded-md hover:bg-gray-50 hover:cursor-pointer "
                  >
                    <div>
                      <h3 className=" font-semibold text-base flex items-center justify-between ">
                        Payment Term <ChevronRightIcon className="w-5 h-5" />
                      </h3>
                      <p>Defines when and how the payment has to be made</p>
                    </div>
                    <p className=" text-muted-foreground ">
                      eg.- The customer has 90 days from the invoice date to pay.
                    </p>
                  </div>
                )}

                {!isMinGuranteeTermPresent && (
                  <div
                    onClick={() => handleClick('MIN_GUARANTEE_TERM')}
                    className=" flex flex-col gap-4 text-sm border-secondary border px-2 py-4 rounded-md hover:bg-gray-50 hover:cursor-pointer "
                  >
                    <div>
                      <h3 className=" font-semibold text-base flex items-center justify-between ">
                        Minimum Guarantee
                        <ChevronRightIcon className="w-5 h-5" />
                      </h3>
                      <p>Defines how much will be paid by the buyer irrespective of consumption/usage.</p>
                    </div>
                    <p className=" text-muted-foreground ">
                      eg.- The customer will pay a minimum of INR 5,000 per month for the first 6 months.
                    </p>
                  </div>
                )}
              </DialogContent>
            </Dialog>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
