import { dataAxiosInstance } from '@/lib/axios';
import { IAggregateBy } from '@/types/cashflow.types';
import dayjs from 'dayjs';

export const getCashflowSummaryCompany = async ({
  planId,
  startDate,
  endDate,
}: {
  planId: string;
  startDate: Date;
  endDate: Date;
}) => {
  const result = await dataAxiosInstance.get(
    `cashflow/summary/graph?planId=${planId}&periodStartDate=${dayjs(startDate).format(
      'YYYY-MM-DD',
    )}&periodEndDate=${dayjs(endDate).format('YYYY-MM-DD')}`,
  );

  return result.data;
};

export const getCashflowSummaryForCategory = async ({
  planId,
  startDate,
  endDate,
  categoryId,
}: {
  planId: string;
  startDate: Date;
  endDate: Date;
  categoryId: string;
}) => {
  const result = await dataAxiosInstance.get(
    `cashflow/category/graph?planId=${planId}&periodStartDate=${dayjs(startDate).format(
      'YYYY-MM-DD',
    )}&periodEndDate=${dayjs(endDate).format('YYYY-MM-DD')}&categoryId=${categoryId}`,
  );

  return result.data;
};

export const getCashflowSummaryDetailsByCompany = async ({
  planId,
  startDate,
  endDate,
}: {
  planId: string;
  startDate: Date;
  endDate: Date;
}) => {
  const result = await dataAxiosInstance.get(
    `cashflow/summary?planId=${planId}&periodStartDate=${dayjs(startDate).format('YYYY-MM-DD')}&periodEndDate=${dayjs(
      endDate,
    ).format('YYYY-MM-DD')}`,
  );

  return result.data;
};

export const getCashflowSummaryDetailsForCategory = async ({
  planId,
  startDate,
  endDate,
  categoryId,
}: {
  planId: string;
  startDate: Date;
  endDate: Date;
  categoryId: string;
}) => {
  const result = await dataAxiosInstance.get(
    `cashflow/summary?planId=${planId}&periodStartDate=${dayjs(startDate).format('YYYY-MM-DD')}&periodEndDate=${dayjs(
      endDate,
    ).format('YYYY-MM-DD')}&categoryId=${categoryId}`,
  );

  return result.data;
};

export const getActualDetailsForCompany = async ({
  companyId,
  startDate,
  endDate,
  aggregateBy,
}: {
  companyId: string;
  startDate: Date;
  endDate: Date;
  aggregateBy: IAggregateBy;
}) => {
  const result = await dataAxiosInstance.get(
    `cashflow/details?periodStartDate=${dayjs(startDate).format('YYYY-MM-DD')}&periodEndDate=${dayjs(endDate).format(
      'YYYY-MM-DD',
    )}&companyId=${companyId}&aggregateBy=${aggregateBy}`,
  );

  return result.data;
};

export const getClosingBalanceOnDate = async ({ companyId, date }: { companyId: string; date: Date }) => {
  const result = await dataAxiosInstance.get(
    `cashflow/closingBalance?companyId=${companyId}&date=${dayjs(date).format('YYYY-MM-DD')}`,
  );

  return result.data;
};

export const getTransactionSummary = async ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
  const result = await dataAxiosInstance.get(
    `cashflow/transactionSummary?periodStartDate=${dayjs(startDate).format('YYYY-MM-DD')}&periodEndDate=${dayjs(
      endDate,
    ).format('YYYY-MM-DD')}`,
  );

  return result.data;
};
