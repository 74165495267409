import { dataAxiosInstance } from '@/lib/axios';
import { IOptions } from '@/types/common.types';
import dayjs from 'dayjs';

export const getAttachmentsWithFilters = async ({
  referenceObjectId,
  filename,
  uploadedBy,
  uploadedOn,
  filetype,
  referenceObjectType,
}: {
  referenceObjectId: string;
  filename?: string;
  uploadedBy?: IOptions[];
  uploadedOn?: Date;
  filetype?: IOptions[];
  referenceObjectType: string;
}) => {
  let url = `/attachments/search?referenceType=${referenceObjectType}&referenceId=${referenceObjectId}`;

  if (filename?.trim()) {
    url += `&fileName=${filename}`;
  }

  if (uploadedOn) {
    url += `&uploadedAfter=${dayjs(uploadedOn).format('YYYY-MM-DD')}&uploadedBefore=${dayjs(uploadedOn)
      .add(1, 'day')
      .format('YYYY-MM-DD')}`;
  }
  if (uploadedBy?.length) {
    url += uploadedBy
      .map((item) => {
        return `&createdBy=${item.value}`;
      })
      .join('');
  }

  if (filetype?.length) {
    url += filetype
      .map((item) => {
        return `&fileType=${item.value}`;
      })
      .join('');
  }

  const result = await dataAxiosInstance.get(url);
  return result.data;
};

export const deleteAttachment = async ({ attachmentId }: { attachmentId: string }) => {
  const result = await dataAxiosInstance.delete(`/attachments/${attachmentId}`);
  return result.data;
};

export const getAttachmentFile = async (attachmentId: string) => {
  const result = await dataAxiosInstance.get(`/attachments/${attachmentId}/file`, {
    responseType: 'arraybuffer',
    withCredentials: true,
  });
  return result;
};

export const uploadAttachmentApi = async ({
  file,
  referenceObjectId,
  referenceObjectType,
  purpose,
}: {
  file: File;
  referenceObjectId: string;
  referenceObjectType: string;
  purpose: string;
}) => {
  if (!file) {
    throw new Error('Invalid Customer file');
  }
  if (!purpose) {
    throw new Error('Invalid purpose for uploaded file');
  }
  const formData = new FormData();

  formData.append('purpose', purpose);

  formData.append('file', file); // Append the file to the FormData object
  const result = await dataAxiosInstance.post(
    `${referenceObjectType.toLowerCase()}/${referenceObjectId}/attachment/upload`,
    formData,
  ); // Sending formData instead of file and isValidationOnly
  return result.data;
};
