import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { IInvoiceMetaDataEditHistoryModel } from '@/types/invoices.types';
import dayjs from 'dayjs';
import { FileClockIcon } from 'lucide-react';

const PromiseToPayDateHistory = ({ historyItems }: { historyItems: IInvoiceMetaDataEditHistoryModel[] }) => {
  const updatedElements = historyItems.filter(
    (history) =>
      history.newValue !== undefined &&
      history.oldValue !== undefined &&
      history.updatedAt &&
      history.newValue !== history.oldValue &&
      history.userName,
  );

  if (!updatedElements.length) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button size="icon" variant="outline">
          <FileClockIcon className=" w-4 h-4  " />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className=" px-2 py-4 text-sm ">
        <div className=" w-80 flex flex-col gap-4 max-h-80 overflow-y-scroll ">
          {updatedElements
            .toSorted((a, b) => dayjs(b.updatedAt).unix() - dayjs(a.updatedAt).unix())
            .map((history, index) => (
              <div key={index} className=" border border-gray-300 rounded-md p-2 bg-gray-100 ">
                <p>
                  Updated promise to pay date to <span>{dayjs(history.newValue).format('DD MMM YYYY')}</span> from{' '}
                  <span>{dayjs(history.oldValue).format('DD MMM YYYY')}</span>
                  <p className=" text-muted-foreground text-xs ">
                    <span className="  text-muted-foreground ">by {history.userName}</span> on{' '}
                    <span className="  ">
                      {dayjs(history.updatedAt).add(330, 'minutes').format('DD MMM YYYY hh:mm a')}
                    </span>
                  </p>
                </p>
                {!!history.comment && (
                  <>
                    <h4 className=" font-semibold mt-2 ">Reason:</h4>
                    <p>{history.comment}</p>
                  </>
                )}
              </div>
            ))}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default PromiseToPayDateHistory;
