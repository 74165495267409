import { IExcelExportFormat } from '@/types/cashflow.types';
import { proxy, wrap } from 'comlink';
import { useCallback, useState } from 'react';
import type { WorkerType } from '../../workers/export-excel.worker';
import Worker from '../../workers/export-excel.worker?worker';

export const excelWorker = wrap<WorkerType>(new Worker());
excelWorker.onProgress(proxy((info: unknown) => console.log(info)));

export const useExportExcel = ({
  onSuccess: onSuccess,
  onError,
}: {
  onSuccess?: (_: string) => void;
  onError?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const exportExcelUsingWorker = useCallback(
    async (data: IExcelExportFormat[]) => {
      setIsLoading(true);
      try {
        const result = await excelWorker.exportExcelFromWorker(data);
        onSuccess && onSuccess(result);
      } catch (error) {
        console.error(error);
        onError && onError();
      }
      setIsLoading(false);
    },
    [onSuccess, onError],
  );

  return { action: exportExcelUsingWorker, isLoading };
};
