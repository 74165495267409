import ToolTipCell from '@/components/Table/ToolTipCell';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useToast } from '@/components/ui/use-toast';
import { usePostUpdateCustomerSuggestions } from '@/hooks/api-hooks/useBankTransactionsQuery';
import { useGetCustomerSuggestionsMutation } from '@/hooks/api-hooks/useCustomerQuery';
import { cn } from '@/lib/utils';
import { IOptions } from '@/types/common.types';
import { ICredit } from '@/types/posting.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import { InfoIcon, ThumbsDownIcon, ThumbsUpIcon, XIcon } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

const CustomerSuggestionDialog = ({
  bankTransactionId,
  customerId,
  customerName,
  className = '',
  allCustomerSuggestions = [],
  postedCustomerIds,
}: {
  postedCustomerIds: string[];
  bankTransactionId: string;
  customerId?: string;
  customerName?: string;
  className?: string;
  allCustomerSuggestions: ICredit['customerSuggestions'];
}) => {
  const { toast } = useToast();
  const pathName = useLocation().pathname;
  const [isCustomerSuggestionUpdateModalOpen, setIsCustomerSuggestionUpdateModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<IOptions | null>(null);
  const { mutate: updateCustomerSuggestion, isPending } = usePostUpdateCustomerSuggestions({
    customConfig: {
      onSuccess: () => {
        setIsCustomerSuggestionUpdateModalOpen(false);
        toast({
          description: 'Customer suggestion updated successfully',
        });
      },
      onError: (error) => {
        toast({
          description: error.response?.data.message || 'Unable to update customer suggestion',
          variant: 'destructive',
        });
        setIsCustomerSuggestionUpdateModalOpen(false);
      },
    },
  });
  const { mutateAsync: fetchCustomers } = useGetCustomerSuggestionsMutation({});

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false, IOptions>();
  }, []);

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchCustomers({
        query: inputValue.trim(),
      });
      return result.data.suggestions
        .map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        })
        .filter((item) => !postedCustomerIds.includes(item.value));
    },
    [fetchCustomers, postedCustomerIds],
  );

  const onChange = (newValue: IOptions | null) => {
    if (!newValue) {
      return;
    }
    setSelectedCustomer(newValue);
  };

  const handleRemoveSuggestion = () => {
    const customerSuggestionsWithSameName = allCustomerSuggestions.filter((item) => item.name === customerName);

    updateCustomerSuggestion({
      bankTransactionId,
      positive: [],
      negative: [...(customerSuggestionsWithSameName.map((item) => item.id) || [])],
    });
  };

  const handleUpdateSuggestion = ({ newCustomerId }: { newCustomerId?: string }) => {
    if (!newCustomerId) {
      return;
    }

    if (newCustomerId === customerId) {
      return;
    }

    const customerSuggestionsWithSameName = allCustomerSuggestions.filter((item) => item.name === customerName);

    updateCustomerSuggestion({
      bankTransactionId,
      negative: [...(customerSuggestionsWithSameName.map((item) => item.id) || [])],
      positive: [...(selectedCustomer?.value ? [selectedCustomer.value] : [])],
    });
  };

  if (customerId) {
    return (
      <>
        <Popover
          modal={false}
          open={isCustomerSuggestionUpdateModalOpen}
          onOpenChange={setIsCustomerSuggestionUpdateModalOpen}
        >
          <div className={cn(' flex  items-center gap-2 justify-between max-w-fit ', className)}>
            <div className=" w-[40%] ">
              {pathName !== `/posting/credit/${bankTransactionId}` && (
                <Link
                  to={`/posting/credit/${bankTransactionId}?paymentStatus=PARTIALLY_PAID%3APartially+Paid%2CUNPAID%3AUnpaid`}
                >
                  <div>{customerName || customerId}</div>
                </Link>
              )}
            </div>
            <div className=" flex items-center gap-2 z-50 bg-white relative left-2 ">
              {pathName !== `/posting/credit/${bankTransactionId}` && (
                <Link
                  className=" p-1 bg-background border rounded-sm"
                  to={`/posting/credit/${bankTransactionId}?paymentStatus=PARTIALLY_PAID%3APartially+Paid%2CUNPAID%3AUnpaid`}
                >
                  <ToolTipCell
                    value={<ThumbsUpIcon className="h-4 w-4" />}
                    tooltipContentClassName=" max-w-24 text-center "
                  >
                    Accept
                  </ToolTipCell>
                </Link>
              )}
              <PopoverTrigger className="  p-1 bg-background border rounded-sm ">
                <ToolTipCell
                  value={<ThumbsDownIcon className="h-4 w-4" />}
                  tooltipContentClassName=" max-w-24 text-center "
                >
                  Reject
                </ToolTipCell>
              </PopoverTrigger>
            </div>
          </div>
          <PopoverContent className=" w-[410px] px-2 py-4">
            <div className="px-2 text-sm flex flex-col gap-4">
              <p className="  flex items-center gap-2 px-4 bg-gray-100 py-2 rounded-md">
                <InfoIcon className="h-4 w-4" />
                AI suggestions can be incorrect
              </p>
              <p>Input the customer correct name. Your input helps to improve future AI suggestions</p>
              <AsyncSelect
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                onChange={onChange}
                value={selectedCustomer}
                loadOptions={loadSelectOptions}
                className=" min-w-[200px] text-sm shadow-sm"
                styles={selectStyles}
                placeholder="Customer"
                classNames={selectClasses}
              />
              <div className=" flex items-center gap-2 justify-end ">
                <Button loading={isPending} onClick={handleRemoveSuggestion} variant="outline">
                  Remove Suggestion
                </Button>
                <Button
                  loading={isPending}
                  onClick={() => handleUpdateSuggestion({ newCustomerId: selectedCustomer?.value })}
                >
                  Update
                </Button>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </>
    );
  }

  return (
    <>
      <Popover
        modal={false}
        open={isCustomerSuggestionUpdateModalOpen}
        onOpenChange={setIsCustomerSuggestionUpdateModalOpen}
      >
        <PopoverTrigger className=" text-blue-700 underline  ">Click to suggest</PopoverTrigger>
        <PopoverContent className="px-2 py-4 w-[410px]">
          <div className="px-2 text-sm flex flex-col gap-4">
            <p className="  px-4 bg-gray-100 py-2 rounded-md">
              <InfoIcon className="h-4 w-4 inline relative bottom-[1px]" />
              &nbsp;We could not generate an AI customer suggestion
            </p>
            <p>Input the customer name. Your input helps to improve future AI suggestions</p>
            <AsyncSelect
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              onChange={onChange}
              value={selectedCustomer}
              loadOptions={loadSelectOptions}
              className=" min-w-[200px] text-sm shadow-sm"
              styles={selectStyles}
              placeholder="Customer"
              classNames={selectClasses}
            />
            <div className=" flex items-center gap-2 justify-end ">
              <Button
                loading={isPending}
                onClick={() => handleUpdateSuggestion({ newCustomerId: selectedCustomer?.value })}
              >
                Suggest
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};

const CustomerSuggestions = ({
  customerSuggestions,
  bankTransactionId,
  postedCustomerIds,
}: {
  postedCustomerIds: string[];
  bankTransactionId: string;
  customerSuggestions: ICredit['customerSuggestions'];
}) => {
  const [isCustomerSuggestionsPopoverOpen, setIsCustomerSuggestionsPopoverOpen] = useState(false);
  const totalCustomers = customerSuggestions.length;

  if (totalCustomers === 0) {
    return (
      <CustomerSuggestionDialog
        allCustomerSuggestions={customerSuggestions}
        bankTransactionId={bankTransactionId}
        postedCustomerIds={postedCustomerIds}
      />
    );
  }

  if (totalCustomers === 1) {
    return (
      <div>
        {customerSuggestions.map((customer) => (
          <CustomerSuggestionDialog
            postedCustomerIds={postedCustomerIds}
            allCustomerSuggestions={customerSuggestions}
            key={customer.id}
            bankTransactionId={bankTransactionId}
            customerId={customer.id}
            customerName={customer.name}
          />
        ))}
      </div>
    );
  }

  return (
    <div className=" relative w-full max-w-fit ">
      <Popover modal={false} open={isCustomerSuggestionsPopoverOpen} onOpenChange={setIsCustomerSuggestionsPopoverOpen}>
        <PopoverTrigger className="  p-1 pl-0 rounded-sm w-[90%] truncate text-left ">
          {customerSuggestions[0].name}
        </PopoverTrigger>
        <span onClick={() => setIsCustomerSuggestionsPopoverOpen(true)}> + {totalCustomers - 1}</span>
        <PopoverContent className="w-96 px-2 py-4">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsCustomerSuggestionsPopoverOpen(false)}
            className="absolute top-2 right-2"
          >
            <XIcon className="h-4 w-4" />
          </Button>
          <div className="px-2 text-sm flex flex-col gap-4 py-4 pt-6 max-h-80 overflow-y-scroll overflow-x-hidden">
            {customerSuggestions.map((customer) => (
              <div key={customer.id}>
                <CustomerSuggestionDialog
                  allCustomerSuggestions={customerSuggestions}
                  bankTransactionId={bankTransactionId}
                  customerId={customer.id}
                  customerName={customer.name}
                  postedCustomerIds={postedCustomerIds}
                />
              </div>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default CustomerSuggestions;
