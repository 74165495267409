import ContactDetails from '@/components/customers/customer-search/Contacts';
import OwnerDetails from '@/components/customers/customer-search/Owners';
import TagsDetails from '@/components/customers/customer-search/Tags';
import CriteriaItem from '@/components/segments/CriteriaItem';
import OwnerName from '@/components/segments/OwnerName';
import { CustomTableBody, MemoizedTableBody } from '@/components/Table/MemoizedTableBody';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import Empty from '@/components/ui/empty';
import { Input } from '@/components/ui/input';
import { Section } from '@/components/ui/page-section-card';
import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import { useGetOwnerById } from '@/hooks/api-hooks/useOwnersQuery';
import {
  useGetCustomerBySegmentId,
  useGetCustomerCountForSegments,
  useGetSegmentById,
  usePostUpdateSegmentMutation,
} from '@/hooks/api-hooks/useSegmentQuery';
import { useExportCustomerDetails } from '@/hooks/excel-export/useCustomersDetailsExport';
import { cn } from '@/lib/utils';
import { IContact, IOwner } from '@/types/contacts.types';
import { ICustomer } from '@/types/customer.types';
import { ISegmentSearchRules } from '@/types/segment.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { isTagsPresent } from '@/utils/isTagsPresent';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { EditIcon, GripVerticalIcon, Loader2Icon, XIcon } from 'lucide-react';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

const CustomerTable = ({ customers, segmentName }: { customers: ICustomer[]; segmentName: string }) => {
  const columns: ColumnDef<ICustomer>[] = useMemo(
    () => [
      {
        id: 'name',
        header: 'Customer Name',
        label: 'Customer Name',
        accessorKey: 'name',
        cell: ({ getValue, row }) => (
          <Link className=" text-blue-700 " to={`/customer/view/${row.original.id}`}>
            <ToolTipCell additionalClasses="underline" value={row.original.alias || (getValue() as string)} />
          </Link>
        ),
      },
      {
        id: 'gst',
        header: 'GST',
        label: 'GST',
        accessorKey: 'gstin',
        cell: ({ getValue }) => (
          <div style={{ cursor: 'pointer' }}>
            {' '}
            <ToolTipCell value={getValue() as string} additionalClasses="cursor-text" />
          </div>
        ),
      },
      {
        id: 'pan',
        header: 'PAN',
        label: 'PAN',
        accessorKey: 'pan',
        cell: ({ getValue }) => <ToolTipCell value={getValue() as string} additionalClasses="cursor-text" />,
      },
      {
        id: 'buyerId',
        header: 'Buyer Id',
        label: 'Buyer Id',
        accessorKey: 'buyerId',
        cell: ({ getValue }) => <ToolTipCell value={getValue() as string} additionalClasses="cursor-text" />,
      },
      {
        id: 'tags',
        header: 'Tags',
        label: 'Tags',
        accessorKey: 'tags',
        cell: ({ getValue, row }) => {
          const tags = getValue() as ICustomer['tags'];

          const mappedTags = tags
            ? Object.entries(tags).map((item) => ({
                name: item[0],
                value: item[1],
                id: item[0],
              }))
            : [];

          return <TagsDetails allowCreate={false} customerId={row.original.id} tagDetails={mappedTags} />;
        },
      },
      {
        id: 'contact',
        header: 'Customer Contacts',
        accessorKey: 'contactsData',
        cell: ({ getValue, row }) => (
          <ContactDetails
            allowCreate={false}
            customerId={row.original.id}
            contactDetails={getValue() ? (getValue() as IContact[]) : []}
          />
        ),
      },
      {
        id: 'owner',
        header: 'Internal Stakeholders',
        accessorKey: 'ownersData',
        cell: ({ getValue, row }) => (
          <OwnerDetails
            allowCreate={false}
            customerId={row.original.id}
            ownerDetails={getValue() ? (getValue() as IOwner[]) : []}
          />
        ),
      },
      {
        id: 'address',
        header: 'Address',
        label: 'Address',
        cell: ({ row }) =>
          row.original.registeredAddress && row.original.registeredAddress?.line1 ? (
            <ToolTipCell
              value={`
          ${row.original.registeredAddress.line1}
          ${row.original.registeredAddress.line2}
        `.trim()}
              additionalClasses="cursor-text"
            />
          ) : (
            'Not Available'
          ),
      },
    ],
    [],
  );

  const customerTable = useReactTable({
    data: customers,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
    defaultColumn: {
      minSize: 50,
    },
  });

  const { toast } = useToast();

  const { exportExcel, isLoading: isExportLoading } = useExportCustomerDetails({
    onSuccess: (url) => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `segment_${segmentName}_customers_${dayjs().format('YYYY-MM-DD-hh-mm')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      toast({
        description: 'Customer details exported',
      });
    },
    onError() {
      toast({
        variant: 'destructive',
        description: 'Failed to export customer details',
      });
    },
  });

  const sizes = customerTable.getState().columnSizingInfo;

  const columnSizeVars = useMemo(() => {
    const headers = customerTable.getFlatHeaders();
    return headers.reduce(
      (acc, current) => {
        return {
          ...acc,
          [`--col-${current.column.id}-size`]: current.column.getSize(),
          [`--header-${current.id}-size`]: current.getSize(),
        };
      },
      {} as {
        [key: string]: number;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);

  const handleExport = () => {
    exportExcel(customers);
  };

  return (
    <div className=" overflow-auto ">
      <Button
        variant="outline"
        onClick={handleExport}
        disabled={isExportLoading}
        className=" absolute top-3 right-6 flex gap-2 "
      >
        Export
        {isExportLoading && <Loader2Icon className="w-4 h-4 animate-spin" />}
      </Button>
      <Table
        style={{
          ...columnSizeVars,
          width: customerTable.getTotalSize(),
        }}
        className="flex-1"
      >
        {customers.length === 0 && <TableCaption className="pb-10">No data found</TableCaption>}
        <TableHeader className=" bg-gray-100 ">
          {customerTable.getHeaderGroups().map((headerGroup) => (
            <TableRow className="flex items-center" key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    style={{
                      width: `calc(var(--header-${header?.id}-size) * 1px)`,
                    }}
                    className="flex items-center justify-between"
                    key={header.id}
                  >
                    {!['actions', 'select'].includes(header.id) ? (
                      <div className="break-keep truncate text-ellipsis w-[90%]">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    ) : (
                      flexRender(header.column.columnDef.header, header.getContext())
                    )}
                    {!['actions', 'select'].includes(header.id) && (
                      <GripVerticalIcon
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: cn(
                            'w-4 h-4 cursor-ew-resize min-w-4 min-h-4',
                            header.column.getIsResizing() ? 'opacity-100' : 'opacity-50',
                          ),
                        }}
                      />
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        {customerTable.getState().columnSizingInfo.isResizingColumn ? (
          <MemoizedTableBody table={customerTable} />
        ) : (
          <CustomTableBody table={customerTable} />
        )}
      </Table>
    </div>
  );
};

const DisplayCriteria = ({ criteria }: { criteria: ISegmentSearchRules }) => {
  return (
    <div className=" py-2 flex-col flex gap-4 text-sm ">
      {criteria.customerFilter?.identifierValue && criteria.customerFilter?.identifierType && (
        <CriteriaItem
          label={`Customer ${
            criteria.customerFilter.identifierType === 'CUSTOMER_NAME'
              ? 'Name'
              : criteria.customerFilter.identifierType === 'BUYER_ID'
                ? 'Buyer Id'
                : criteria.customerFilter.identifierType
          }`}
          value={criteria.customerFilter?.identifierValue}
          operator={criteria.customerFilter.identifierType === 'CUSTOMER_NAME' ? 'Contains' : '='}
        />
      )}
      {criteria.dateFilter?.dateType && criteria.dateFilter?.startDate && criteria.dateFilter?.endDate && (
        <CriteriaItem
          label={criteria.dateFilter.dateType.split('_').join(' ')}
          value={`${dayjs(criteria.dateFilter.startDate).format('DD MMM YYYY')} - ${dayjs(
            criteria.dateFilter.endDate,
          ).format('DD MMM YYYY')}`}
          operator={`Between`}
        />
      )}
      {criteria.amountFilter?.amountType &&
        criteria.amountFilter?.maxAmount !== undefined &&
        criteria.amountFilter?.maxAmount !== null &&
        criteria.amountFilter?.minAmount !== null &&
        criteria.amountFilter?.minAmount !== undefined && (
          <CriteriaItem
            label={criteria.amountFilter.amountType}
            value={`${formatCurrencyByUnit(criteria.amountFilter.minAmount, 'actual')} - ${formatCurrencyByUnit(
              criteria.amountFilter.maxAmount,
              'actual',
            )}`}
            operator={`Between`}
          />
        )}
      {!!criteria.invoiceStatus?.length && (
        <CriteriaItem
          label="Invoice Status"
          operator="="
          value={criteria.invoiceStatus.map((status) => (
            <Badge
              key={status}
              className={cn(
                'capitalize  ',
                status === 'NO_DUES' &&
                  'bg-green-700 hover:bg-green-700 border border-green-700 hover:text-white text-white',
                status === 'OVERDUE' && 'bg-red-500 text-white hover:bg-red-500 border border-red-500 hover:text-white',
                status === 'DUE' && 'bg-yellow-400 border border-yellow-400 text-black hover:bg-yellow-400',
              )}
            >
              {status.split('_').join(' ')}
            </Badge>
          ))}
        />
      )}
      {!!criteria.tags && isTagsPresent(criteria.tags) && (
        <CriteriaItem
          label="Tags"
          operator="="
          value={Object.entries(criteria.tags).map((tag) => (
            <Badge
              key={tag[0] + tag[1]}
              className="flex items-center gap-2 bg-gray-300 hover:bg-gray-200 text-primary "
            >
              <span>{tag[0]}</span>: <span>{tag[1]}</span>
            </Badge>
          ))}
        />
      )}
      {!!criteria.paymentStatus?.length && (
        <CriteriaItem
          label="Payment Status"
          operator="="
          value={criteria.paymentStatus.map((status) => (
            <Badge
              key={status}
              className={cn(
                ' capitalize  ',
                status === 'PAID' &&
                  'bg-green-700 hover:bg-green-700 border border-green-700 hover:text-white text-white',
                status === 'UNPAID' && 'bg-red-500 text-white hover:bg-red-500 border border-red-500 hover:text-white',
                status === 'PARTIALLY_PAID' && 'bg-yellow-400 border border-yellow-400 text-black hover:bg-yellow-400',
              )}
            >
              {status.split('_').join(' ')}
            </Badge>
          ))}
        />
      )}
      {!!criteria.ownerId && (
        <CriteriaItem label={`Internal Stakeholder`} value={<OwnerName ownerId={criteria.ownerId} />} operator={`=`} />
      )}
      {!!criteria.ownerRole && (
        <CriteriaItem label={`Internal Stakeholder Role`} value={criteria.ownerRole} operator={`=`} />
      )}
    </div>
  );
};

const CustomerBySegment = ({ segmentName }: { segmentName: string }) => {
  const { segmentId } = useParams();

  const { data: customersBySegment, isLoading } = useGetCustomerBySegmentId({
    segmentId: segmentId as string,
    customConfig: {
      enabled: !!segmentId,
    },
  });

  if (isLoading) {
    return (
      <div className="py-2">
        <Table>
          <TableHeader className=" bg-gray-100">
            {new Array(1).fill(0).map((_, i) => (
              <TableRow key={i}>
                {new Array(6).fill(0).map((_, i) => (
                  <TableHead className="px-6 min-w-[100px]" key={i}>
                    <Skeleton className="w-full h-4" />
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="py-4">
            {new Array(10).fill(0).map((_, i) => (
              <TableRow key={i}>
                {new Array(6).fill(0).map((_, i) => (
                  <TableCell className="px-6 text-center" key={i}>
                    <Skeleton className="w-full h-4" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  if (!customersBySegment?.data.customers.length) {
    return <Empty title="Customers not found" />;
  }

  return <CustomerTable segmentName={segmentName} customers={customersBySegment.data.customers} />;
};

export const EditSegmentName = ({
  defaultValue,
  className,
  segmentId,
}: {
  defaultValue: string;
  className?: string;
  segmentId: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState('');
  const { toast } = useToast();

  const { mutate: updateSegment, isPending } = usePostUpdateSegmentMutation({
    segmentId,
    customConfig: {
      onError: (error) => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to update segment',
        });
        setValue(defaultValue);
      },
    },
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors('');
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (value.length < 3) {
      setErrors('Segment name must be more than 3 characters');
      return;
    }

    if (value === defaultValue) {
      setIsEditing(false);
      return;
    }

    setIsEditing(false);
    updateSegment({
      fields: ['name'],
      values: {
        name: value,
      },
    });
  };

  const handleBlur = () => {
    handleSave();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  };

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
    setValue(defaultValue);
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    } else {
      setErrors('');
    }
  }, [isEditing]);

  return isEditing ? (
    <div className=" flex items-start gap-4  ">
      <div>
        <Input
          className="my-0 text-xl font-semibold "
          onKeyDown={handleKeyDown}
          ref={inputRef}
          onBlur={handleBlur}
          value={value}
          onChange={handleChange}
        />
        <p className=" text-xs text-red-500 h-4 ">{!!errors.trim() && errors}</p>
      </div>
      <Button onClick={handleCancel} variant="outline" size="icon">
        <XIcon className=" h-4 w-4 " />
      </Button>
    </div>
  ) : (
    <div
      onClick={handleClick}
      className={cn(
        ' hover:ring-1 ring-primary cursor-pointer rounded-md py-1 px-1 flex max-w-fit items-center gap-4 ',
        className,
      )}
    >
      {value}
      {isPending && <Loader2Icon className=" animate-spin " />}
    </div>
  );
};

export const EditSegmentDescription = ({
  defaultValue,
  className,
  segmentId,
}: {
  defaultValue: string;
  className?: string;
  segmentId: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [errors, setErrors] = useState('');
  const { toast } = useToast();

  const { mutate: updateSegment, isPending } = usePostUpdateSegmentMutation({
    segmentId,
    customConfig: {
      onError: (error) => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to update segment',
        });
        setValue(defaultValue);
      },
    },
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setErrors('');
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (value.length < 3) {
      setErrors('Segment description must be more than 3 characters');
      return;
    }

    if (value === defaultValue) {
      setIsEditing(false);
      return;
    }

    setIsEditing(false);
    updateSegment({
      fields: ['description'],
      values: {
        description: value,
      },
    });
  };

  const handleBlur = () => {
    handleSave();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  };

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
    setValue(defaultValue);
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    } else {
      setErrors('');
    }
  }, [isEditing]);

  return isEditing ? (
    <div className=" flex items-start gap-4 w-full  ">
      <div className=" flex-1 ">
        <Textarea
          className="my-0 w-full "
          onKeyDown={handleKeyDown}
          ref={inputRef}
          onBlur={handleBlur}
          value={value}
          onChange={handleChange}
        />
        <p className=" text-xs text-red-500 h-4 ">{!!errors.trim() && errors}</p>
      </div>
      <Button onClick={handleCancel} variant="outline" size="icon">
        <XIcon className=" h-4 w-4 " />
      </Button>
    </div>
  ) : (
    <div
      onClick={handleClick}
      className={cn(
        ' hover:ring-1 ring-primary cursor-pointer rounded-md py-1 px-1 flex max-w-fit items-center gap-4 ',
        className,
      )}
    >
      {value}
      {isPending && <Loader2Icon className=" animate-spin " />}
    </div>
  );
};

const ViewSegmentPage = () => {
  const { segmentId } = useParams();

  const { data: customerCount } = useGetCustomerCountForSegments();

  const { data: segmentData, isLoading } = useGetSegmentById({
    segmentId: segmentId as string,
    customConfig: {
      enabled: !!segmentId,
    },
  });

  const { data: owner, isLoading: isLoadingOwner } = useGetOwnerById({
    ownerId: segmentData?.data.segment.rule.searchQuery.ownerId || '',
  });

  const navigate = useNavigate();

  if (isLoading || isLoadingOwner) {
    return (
      <div className="sm:px-16 sm:py-8 px-4 py-8">
        <div className=" flex flex-col gap-2 ">
          <Skeleton className=" h-6 w-80 " />
          <Skeleton className=" h-6 w-full " />
          <Skeleton className=" h-6 w-1/2 " />
        </div>
        <div>
          <Section title="Criteria">
            <div className=" flex flex-col gap-4 py-4 ">
              <div className=" flex items-center gap-4">
                <Skeleton className=" h-6 w-[150px] " />
                <Skeleton className=" h-6 w-[100px] " />
                <Skeleton className=" h-6 flex-1 " />
              </div>
              <div className=" flex items-center gap-4">
                <Skeleton className=" h-6 w-[150px] " />
                <Skeleton className=" h-6 w-[100px] " />
                <Skeleton className=" h-6 flex-1 " />
              </div>
              <div className=" flex items-center gap-4">
                <Skeleton className=" h-6 w-[150px] " />
                <Skeleton className=" h-6 w-[100px] " />
                <Skeleton className=" h-6 flex-1 " />
              </div>
              <div className=" flex items-center gap-4">
                <Skeleton className=" h-6 w-[150px] " />
                <Skeleton className=" h-6 w-[100px] " />
                <Skeleton className=" h-6 flex-1 " />
              </div>
            </div>
          </Section>
        </div>
        <div>
          <Section title="Customers">
            <div className=" flex flex-col gap-4 py-4 ">
              <div className=" flex items-center gap-4">
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
              </div>
              <div className=" flex items-center gap-4">
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
              </div>
              <div className=" flex items-center gap-4">
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
              </div>
              <div className=" flex items-center gap-4">
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
                <Skeleton className=" h-6 flex-1 " />
              </div>
            </div>
          </Section>
        </div>
      </div>
    );
  }

  if (!segmentData?.data) {
    return <Empty title="Segment not found" />;
  }

  const segment = segmentData.data.segment;

  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8">
      <EditSegmentName defaultValue={segment.name} segmentId={segment.id} className="text-xl font-semibold" />
      <EditSegmentDescription defaultValue={segment.description} segmentId={segment.id} className="text-sm " />
      <Section
        titleClassName=" no-underline "
        title={
          <div className=" flex items-center justify-between ">
            <span className=" flex items-center gap-2 underline ">Criteria</span>
            <Button
              onClick={() =>
                navigate(
                  '/customers?isSegmentUpdate=true&segmentId=' + segment.id + '&segmentName=' + segment.name + '&',
                  {
                    state: { ...segment.rule.searchQuery, ownerName: owner?.data.name },
                  },
                )
              }
              variant="outline"
              className=" flex gap-2 items-center no-underline "
            >
              Edit <EditIcon className="w-4 h-4" />
            </Button>
          </div>
        }
      >
        <DisplayCriteria criteria={segment.rule.searchQuery} />
      </Section>
      <Section
        titleClassName=" no-underline "
        title={
          <div className=" flex items-center justify-between ">
            <span className=" flex items-center gap-2 ">
              <span className=" underline ">Customers</span>
              {customerCount?.data?.customerCounts?.[segment.id]
                ? `(${customerCount.data.customerCounts[segment.id]})`
                : ''}
            </span>
          </div>
        }
      >
        <CustomerBySegment segmentName={segment.name} />
      </Section>
    </div>
  );
};

export default ViewSegmentPage;
