import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://backend.valyx.com',
});

export const dataAxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_BASE_URL,
  withCredentials: true,
});

export default axiosInstance;
