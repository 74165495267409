import { useUserContext } from '@/contexts/UserContext';
import { useGetBankAccountsForCompany } from '@/hooks/api-hooks/useBankAccountQuery';
import { useMemo } from 'react';
import { Skeleton } from '../ui/skeleton';

const BankIdToName = ({ bankId }: { bankId: string }) => {
  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { data: banks, isLoading: isLoadingBanks } = useGetBankAccountsForCompany({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const banksMap = useMemo(() => {
    return (
      banks?.data.bankAccounts.reduce(
        (acc, bank) => {
          acc[bank.id] = bank.name;
          return acc;
        },
        {} as Record<string, string>,
      ) || {}
    );
  }, [banks]);

  if (isLoadingBanks) {
    return <Skeleton className=" h-7 w-32 " />;
  }

  return banksMap[bankId] ?? bankId;
};

export default BankIdToName;
