export const formatCurrency = (currency: { valueOf: () => number }) => {
  // format currency based on indian currency
  let value = currency.valueOf();
  const isNegative = value < 0;
  value = Math.abs(value);
  if (value >= 10000000) {
    return `${isNegative ? '-' : ''}${
      new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value / 10000000) + 'Cr'
    }`;
  }
  if (value >= 100000) {
    return `${isNegative ? '-' : ''}${
      new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value / 100000) + 'L'
    }`;
  }
  if (value >= 1000) {
    return `${isNegative ? '-' : ''}${
      new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value / 1000) + 'K'
    }`;
  }
  return `${isNegative ? '-' : ''}${new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(
    value,
  )}`;
};
