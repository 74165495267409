import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { VisibilityState } from '@tanstack/react-table';
import { useMemo } from 'react';

export const useColumnVisibility = <T extends { id?: string }>({ key, columns }: { key: string; columns: T[] }) => {
  const defaultColumnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const { storedValue: columnVisibility, setValue: setColumnVisibility } = useLocalStorage(
    key,
    defaultColumnVisibility,
    (alreadyStoredValue, valueToSet) => Object.keys(alreadyStoredValue).length !== Object.keys(valueToSet).length,
  );

  return { columnVisibility, setColumnVisibility };
};
