import { cn } from '@/lib/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';

const Tabs = ({ isEdit = false, workflowId }: { isEdit?: boolean; workflowId?: string }) => {
  const path = useLocation().pathname;

  const navigate = useNavigate();

  const isDefineTabActive = path.includes('define') && path.includes('workflow');
  const isWorkflowPlaygroundTabActive = path.includes('workflow') && path.includes('playground');
  const isExitCriteriaTabActive = path.includes('workflow') && path.includes('exit-criteria');

  return (
    <div className=" w-full justify-center items-center flex border-b ">
      <div className=" pt-2 ">
        {isEdit && workflowId ? (
          <Button
            onClick={() => navigate(`/workflow/update/define/${workflowId}`)}
            variant="ghost"
            className={cn(isDefineTabActive && ' border-black border-b-2 rounded-b-none hover:bg-none')}
          >
            Define
          </Button>
        ) : (
          <Button
            variant="ghost"
            className={cn(isDefineTabActive && ' border-black border-b-2 rounded-b-none hover:bg-none')}
          >
            Define
          </Button>
        )}

        {isEdit && workflowId ? (
          <Button
            variant="ghost"
            onClick={() => navigate(`/workflow/playground/${workflowId}`)}
            className={cn(isWorkflowPlaygroundTabActive && ' border-black border-b-2 rounded-b-none hover:bg-none')}
          >
            Build
          </Button>
        ) : (
          <Button
            type="submit"
            form="workflow-define-form"
            variant="ghost"
            className={cn(isWorkflowPlaygroundTabActive && ' border-black border-b-2 rounded-b-none hover:bg-none')}
          >
            Build
          </Button>
        )}

        {isEdit && workflowId ? (
          <Button
            variant="ghost"
            onClick={() => navigate(`/workflow/create/exit-criteria/${workflowId}`)}
            className={cn(isExitCriteriaTabActive && ' border-black border-b-2 rounded-b-none', 'hover:bg-none')}
          >
            Exit
          </Button>
        ) : (
          <Button
            type="submit"
            form="workflow-define-form"
            variant="ghost"
            className={cn(isExitCriteriaTabActive && ' border-black border-b-2 rounded-b-none', 'hover:bg-none')}
          >
            Exit
          </Button>
        )}
      </div>
    </div>
  );
};

export default Tabs;
