export const validateNumber = (
  value: string,
  fieldName: string,
): {
  isValid: boolean;
  message?: string;
} => {
  if (isNaN(Number(value))) {
    return {
      isValid: false,
      message: `${fieldName} is invalid`,
    };
  }

  if (!isNaN(Number(value)) && Number(value) < 0) {
    return {
      isValid: false,
      message: `${fieldName} cannot be negative`,
    };
  }

  return {
    isValid: true,
  };
};
