import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useUserContext } from '@/contexts/UserContext';
import { useCreatePlan } from '@/hooks/api-hooks/usePlanQuery';
import { PlusIcon } from '@radix-ui/react-icons';
import { Loader2 } from 'lucide-react';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SelectComponent from '../ui/select-component';
import { useToast } from '../ui/use-toast';

interface FormValues {
  name: string;
  year: string;
}

export function CreatePlanDialog() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleModal = () => {
    setIsModalOpen(true);
  };
  const { toast } = useToast();

  const contextData = useUserContext();
  const { mutate: createPlan, isPending } = useCreatePlan({
    companyId: contextData.companiesOfUser?.[contextData?.activeCompanyIndex]?.id,
    customConfig: {
      onSuccess: (data) => {
        if (data.data.id) {
          setIsModalOpen(false);
          reset();
          navigate(`/plan/edit/${data.data.id}`);
        }
      },
      onError: (data: unknown) => {
        // @ts-expect-error no common error type
        if (data.response?.data?.error === 'PLAN_ALREADY_EXISTS') {
          toast({
            title: 'Error',
            description: 'Plan already exists',
            variant: 'destructive',
          });
          return;
        }
        toast({
          title: 'Error',
          description: 'Failed to create plan',
          variant: 'destructive',
        });
      },
    },
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    values: {
      name: '',
      year: new Date().getFullYear().toString(),
    },
  });

  const onSubmit = (data: FormValues) => {
    if (!contextData.companiesOfUser?.[contextData.activeCompanyIndex]?.id) {
      toast({
        title: 'Error',
        description: 'Failed to create plan',
        variant: 'destructive',
      });
      return;
    }
    createPlan({
      name: data.name,
      financialYear: parseInt(data.year),
    });
  };

  const options = useMemo(() => {
    return new Array(10).fill(0).map((_, i) => ({
      label: `${new Date().getFullYear() + i}`,
      value: `${new Date().getFullYear() + i}`,
    }));
  }, []);

  return (
    <>
      <Button onClick={handleModal} className=" gap-3">
        <PlusIcon width={12} height={12} /> Create Plan
      </Button>
      {isModalOpen && (
        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogContent className="sm:max-w-[425px]">
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogHeader>
                <DialogTitle>Create plan</DialogTitle>
                <DialogDescription>Create a new plan</DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <div>
                  <Label htmlFor="name" className="text-right">
                    Plan name
                  </Label>
                  <Input
                    {...register('name', {
                      required: {
                        value: true,
                        message: 'Plan name is required',
                      },
                      maxLength: {
                        value: 255,
                        message: 'Plan name must be less than 255 characters',
                      },
                    })}
                    id="name"
                    className="col-span-3"
                  />
                  {errors.name && <p className="text-red-500 text-xs">{errors.name.message}</p>}
                </div>
                <div>
                  <Label htmlFor="name" className="text-right">
                    Financial Year
                  </Label>
                  <Controller
                    name="year"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Financial year is required',
                      },
                    }}
                    render={({ field }) => (
                      <div className="flex items-center gap-2 text-sm">
                        <SelectComponent
                          value={field.value}
                          placeholder="Select Year"
                          options={options}
                          onChange={field.onChange}
                        />
                        Apr {parseInt(field.value) - 1} - Mar {field.value}
                      </div>
                    )}
                  />
                  {errors.year && <p className="text-red-500 text-xs">{errors.year.message}</p>}
                </div>
              </div>
              <DialogFooter>
                <Button disabled={isPending} type="submit" className="flex items-center gap-2">
                  Save changes
                  {isPending && <Loader2 className="animate-spin h-4 w-4" />}
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default CreatePlanDialog;
