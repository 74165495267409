import { GroupBase, StylesConfig } from 'react-select';

/**
 * Returns the styles configuration for the select component.
 *
 * @return {StylesConfig<IOptions, boolean, GroupBase<IOptions>>} styles configuration for the select component
 */
export const getStylesForSelect = <T extends boolean, K>(): StylesConfig<K, T, GroupBase<K>> => ({
  placeholder: (base) => ({
    ...base,
    color: 'hsl(var(--muted-foreground))',
    fontWeight: 400,
    padding: 0,
  }),
  control: (base, props) => ({
    ...base,
    borderColor: props.isFocused ? 'hsl(var(--ring))' : 'hsl(var(--input))',
    '&:hover': {
      borderColor: props.isFocused ? 'hsl(var(--ring))' : 'hsl(var(--input))',
    },
    outline: 'none',
    boxShadow: 'none',
    padding: 0,
    maxHeight: '7rem',
    overflowY: 'auto',
  }),
  input(base) {
    return {
      ...base,
      border: 'none',
      outline: 'none',
      padding: 0,
    };
  },
  singleValue(base) {
    return {
      ...base,
      padding: 0,
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'hsl(var(--accent))',
    };
  },
  menu(base) {
    return {
      ...base,
      backgroundColor: 'hsl(var(--card))',
    };
  },
  menuPortal(base) {
    return {
      ...base,
      zIndex: 100,
      pointerEvents: 'all',
    };
  },
  option(base, props) {
    return {
      ...base,
      '&:hover': {
        backgroundColor: 'hsl(var(--accent))',
      },
      backgroundColor: props.isFocused ? 'hsl(var(--accent))' : 'transparent',
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
      paddingLeft: '0.5rem',
      fontSize: '0.875rem',
      color: 'hsl(var(--primary))',
    };
  },
});

export const getClassNamesForSelect = () => ({
  menu: () => 'shadow-md text-sm z-100',
});

export const getClassNamesForSelectPurpose = () => ({
  menu: () => 'shadow-md text-sm z-100 h-[125px] overflow-hidden',
});

export const getStylesForCustomerTableSelect = <T extends boolean, K>(): StylesConfig<K, T, GroupBase<K>> => ({
  placeholder: (base) => ({
    ...base,
    color: 'hsl(var(--muted-foreground))',
    fontWeight: 400,
    padding: 0,
    fontSize: '12px',
  }),
  control: (base, props) => ({
    ...base,
    borderColor: props.isFocused ? 'hsl(var(--ring))' : 'hsl(var(--input))',
    '&:hover': {
      borderColor: props.isFocused ? 'hsl(var(--ring))' : 'hsl(var(--input))',
    },
    outline: 'none',
    boxShadow: 'none',
    height: '1.75rem',
    padding: 0,
    minHeight: '1.75rem',
    width: '100px',
    maxWidth: '100px',
  }),
  input(base) {
    return {
      ...base,
      border: 'none',
      outline: 'none',
      padding: 0,
      margin: 0,
      fontSize: '12px',
    };
  },
  singleValue(base) {
    return {
      ...base,
      padding: 0,
      fontSize: '12px',
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'hsl(var(--accent))',
    };
  },
  menu(base) {
    return {
      ...base,
      backgroundColor: 'hsl(var(--card))',
    };
  },
  option(base, props) {
    return {
      ...base,
      '&:hover': {
        backgroundColor: 'hsl(var(--accent))',
      },
      backgroundColor: props.isFocused ? 'hsl(var(--accent))' : 'transparent',
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
      paddingLeft: '0.5rem',
      fontSize: '0.875rem',
      color: 'hsl(var(--primary))',
    };
  },
});
