import { Accordion } from '@/components/ui/accordion';
import { ActivityEventStatus, IActivityEvent, IActivityServiceProviderEvents } from '@/types/activity.types';
import { unix } from 'dayjs';
import { useCallback, useMemo } from 'react';
import EventStatus from './ActivityEventStatus';

export const ActivityListCardAdditionalContent = ({
  events,
  activityTimeStamp,
  recipients,
}: {
  events: IActivityEvent[];
  activityTimeStamp?: number;
  recipients?: string[];
}) => {
  const seenEvent = events.find((event) => event.eventType === 'open');
  const deliveredEvent = events.find((event) => event.eventType === 'delivered');
  const sentEvent = events.find((event) => event.eventType === 'sent');

  function getNonDeliveredRecipients(
    emailRecipients: string[],
    deliveredRecipients: { email: string; timestamp: number; description?: string | null }[],
  ) {
    const deliveredEmails = deliveredRecipients?.map((recipient) => recipient.email);
    const undeliveredEmails = emailRecipients
      ? emailRecipients
          .filter((email) => !deliveredEmails?.includes(email.toLowerCase()))
          .filter((email) => email !== 'updates@valyx.com')
          .map((email) => ({
            email: email,
            timestamp: 0,
            description: '',
          }))
      : [];

    return undeliveredEmails;
  }

  const getNonDeliveredRecipientsStatus = useCallback(
    (
      emailRecipients: string[],
      deliveredRecipients: { email: string; timestamp: number; description?: string | null }[],
    ) => {
      const undeliveredEmails = getNonDeliveredRecipients(emailRecipients, deliveredRecipients);
      if (undeliveredEmails.length == emailRecipients?.length) {
        return 'ALL';
      } else if (undeliveredEmails.length == 0) {
        return 'NONE';
      } else {
        return 'PARTIAL';
      }
    },
    [],
  );

  const undeliveredEmails: { email: string; timestamp: number; description?: string | null }[] = useMemo(() => {
    return getNonDeliveredRecipients(recipients ?? [], deliveredEvent?.eventParticipants ?? []);
  }, [recipients, deliveredEvent?.eventParticipants]);
  const undeliveredEmailStatus: ActivityEventStatus = useMemo(() => {
    return getNonDeliveredRecipientsStatus(recipients ?? [], deliveredEvent?.eventParticipants ?? []);
  }, [recipients, deliveredEvent, getNonDeliveredRecipientsStatus]);

  return (
    <div className=" min-w-64 pl-2 text-muted-foreground text-xs flex h-full overflow-y-scroll overflow-x-hidden  pt-0 flex-col gap-2 ">
      {!!activityTimeStamp && (
        <div className=" text-xs text-muted-foreground font-medium ">
          Sent on{' '}
          <span className="font-semibold text-primary">
            {unix(activityTimeStamp).format('DD MMM YYYY, HH:mm:ss a')}
          </span>
        </div>
      )}
      <Accordion type="multiple" className=" pt-0 " defaultValue={['open', 'delivered', 'sent', 'bounce']}>
        {sentEvent && (
          <EventStatus
            key={sentEvent.eventType}
            eventName={sentEvent.eventType}
            eventParticipants={sentEvent.eventParticipants}
            eventStatus={sentEvent.eventStatus}
          />
        )}
        {deliveredEvent && (
          <EventStatus
            key={deliveredEvent.eventType}
            eventName={deliveredEvent.eventType}
            eventParticipants={deliveredEvent.eventParticipants}
            eventStatus={deliveredEvent.eventStatus}
          />
        )}
        {seenEvent && (
          <EventStatus
            key={seenEvent.eventType}
            eventName={seenEvent.eventType}
            eventParticipants={seenEvent.eventParticipants}
            eventStatus={seenEvent.eventStatus}
          />
        )}

        {recipients !== undefined && undeliveredEmails.length > 0 && (
          <EventStatus
            key={'bounce'}
            eventName={'bounce'}
            eventParticipants={undeliveredEmails}
            eventStatus={undeliveredEmailStatus}
          />
        )}
      </Accordion>
    </div>
  );
};

export const ActivityDetailsCardAdditionalContent = ({
  events,
  activityTimeStamp,
}: {
  events: IActivityServiceProviderEvents[];
  activityTimeStamp?: number;
}) => {
  const seenEvent = events.find((event) => event.eventType === 'open');
  const deliveredEvent = events.find((event) => event.eventType === 'delivered');
  const bounced = events.find((event) => event.eventType === 'bounce');
  const deferred = events.find((event) => event.eventType === 'deferred');
  const dropped = events.find((event) => event.eventType === 'dropped');

  const isAlreadyDelivered =
    deliveredEvent?.eventParticipants.reduce(
      (acc, participant) => {
        return {
          ...acc,
          [participant.email]: true,
        };
      },
      {} as Record<string, boolean>,
    ) || {};

  const notDeliveredStatus = useMemo(() => {
    const arrayOfStatus = [bounced?.eventStatus, deferred?.eventStatus, dropped?.eventStatus].filter(Boolean);

    if (arrayOfStatus.every((status) => status === 'NONE')) {
      return 'NONE';
    }

    if (arrayOfStatus.every((status) => status === 'ALL')) {
      return 'ALL';
    }

    return 'PARTIAL';
  }, [bounced?.eventStatus, deferred?.eventStatus, dropped?.eventStatus]);

  const notDelivered: IActivityServiceProviderEvents = {
    eventParticipants: [
      ...(bounced?.eventParticipants || []),
      ...(deferred?.eventParticipants || []),
      ...(dropped?.eventParticipants || []),
    ].filter((participant) => !isAlreadyDelivered[participant.email]),
    eventStatus: notDeliveredStatus,
    eventType: 'bounce',
    lastEventTimestamp: Math.max(
      bounced?.lastEventTimestamp || 0,
      deferred?.lastEventTimestamp || 0,
      dropped?.lastEventTimestamp || 0,
    ),
  };

  return (
    <div className=" min-w-64 pl-2 text-muted-foreground text-xs flex  pt-0 flex-col gap-2  ">
      {!!activityTimeStamp && (
        <div className=" text-xs text-muted-foreground font-medium ">
          Sent on{' '}
          <span className="font-semibold text-primary">
            {unix(activityTimeStamp).format('DD MMM YYYY, HH:mm:ss a')}
          </span>
        </div>
      )}
      <Accordion type="multiple" className=" pt-0 " defaultValue={['open', 'delivered', 'sent', 'bounce']}>
        {deliveredEvent && (
          <EventStatus
            key={deliveredEvent.eventType}
            eventName={deliveredEvent.eventType}
            eventParticipants={deliveredEvent.eventParticipants}
            eventStatus={deliveredEvent.eventStatus}
          />
        )}
        {seenEvent && (
          <EventStatus
            key={seenEvent.eventType}
            eventName={seenEvent.eventType}
            eventParticipants={seenEvent.eventParticipants}
            eventStatus={seenEvent.eventStatus}
          />
        )}
        {notDelivered && notDelivered.eventParticipants.length > 0 && (
          <EventStatus
            key={notDelivered.eventType}
            eventName={notDelivered.eventType}
            eventParticipants={notDelivered.eventParticipants}
            eventStatus={notDelivered.eventStatus}
            showDetails
          />
        )}
      </Accordion>
    </div>
  );
};
