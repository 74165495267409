import TableSkeleton from '@/components/shared/TableSkeleton';
import { Skeleton } from '@/components/ui/skeleton';
import { useGetEmailWebviewContentsByAccessToken } from '@/hooks/api-hooks/useEmailQuery';
import { Navigate, useSearchParams } from 'react-router-dom';

const MailWebviewPage = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const { data: emailData, isLoading } = useGetEmailWebviewContentsByAccessToken({
    mailAccessToken: token as string,
    customConfig: {
      enabled: !!token,
    },
  });

  if (isLoading) {
    return (
      <div className=" p-4 flex flex-col h-screen overflow-scroll  ">
        <div className=" flex justify-between">
          <h1 className=" text-2xl ">
            <Skeleton className=" w-60 h-8 " />
          </h1>
        </div>
        <div className=" my-4 flex flex-col flex-1  ">
          <div className=" flex flex-col gap-4 border p-2 rounded-md h-full flex-1 template-preview">
            <div className=" border-b pb-4 ">
              <h3 className=" font-semibold text-sm preview-title ">Subject:</h3>
              <div className=" flex flex-col gap-2 ">
                <Skeleton className=" w-full h-6   " />
                <Skeleton className=" w-32 h-6   " />
              </div>
            </div>
            <div className=" overflow-auto flex-1 flex flex-col gap-2   ">
              <Skeleton className=" w-full h-6   " />
              <Skeleton className=" w-full h-6   " />
              <Skeleton className=" w-full h-6   " />
              <Skeleton className=" w-full h-6   " />
              <div className="border my-4">
                <TableSkeleton rows={5} columns={5} />
              </div>
              <Skeleton className=" w-full h-6   " />
              <Skeleton className=" w-full h-6   " />
              <Skeleton className=" w-full h-6   " />
              <Skeleton className=" w-32 h-6   " />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!emailData) {
    return <Navigate to="/404" />;
  }

  return (
    <div className=" p-4 flex flex-col h-screen overflow-scroll  ">
      <div className=" flex justify-between">
        <h1 className=" text-2xl ">{emailData.data.company.companyName}</h1>
      </div>
      <div className=" my-4 flex flex-col flex-1  ">
        <div className=" flex flex-col gap-4 border p-2 rounded-md h-full flex-1 template-preview">
          <div className=" border-b pb-4 ">
            <h3 className=" font-semibold text-sm preview-title ">Subject:</h3>
            <div
              className=" w-full max-h-fit   "
              dangerouslySetInnerHTML={{ __html: emailData?.data.email.subject }}
              title="Preview subject"
            />
          </div>
          <div className=" overflow-auto flex-1   ">
            <div dangerouslySetInnerHTML={{ __html: emailData?.data.email.htmlContent }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailWebviewPage;
