import { EmailTemplateSelect } from '@/components/shared/EmailTemplateSelect';
import { CustomerRoleMultiSelect } from '@/components/shared/MultiSelectCustomerRole';
import { Button } from '@/components/ui/button';
import { Info } from '@/components/ui/info';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import {
  useGetActionById,
  useGetStateById,
  usePostUpdateActionOnlyState,
  usePostUpdateConditionalState,
  usePostUpdateEmailAction,
  usePostUpdateTriggerState,
  usePostUpdateWaitState,
} from '@/hooks/api-hooks/useWorkflowQuery';
import { useWorkflowPropertiesStore } from '@/stores/workflow/state-properties.store';
import { IOptions } from '@/types/common.types';
import { IEmailTemplate } from '@/types/email-templates.types';
import { IAttachmentDataMail, IEmailActionProperties } from '@/types/workflow.type';
import { getClassNamesForSelectPurpose, getStylesForSelect } from '@/utils/getStylesForSelect';
import { Loader2Icon, XIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import PropertiesLayout from '../Layout';
import StateLabelInput from '../StateLabel';
import CustomerRoleAndEmailMultiSelect from '../email-properties/CustomerRoleAndEmailMultiSelect';

interface IOption {
  label: string;
  value: string;
}

interface IEmailProperties {
  to?: readonly IOptions[];
  from?: string;
  template?: {
    name: string;
    id: string;
  };
  cc?: string[];
  bcc?: string[];
  subject?: string;
  attachmentData?: IAttachmentDataMail;
}

const validateEmailProperties = (data: Partial<IEmailProperties>) => {
  const errors: Record<keyof Omit<IEmailProperties, 'clientId'>, string> = {
    from: '',
    template: '',
    to: '',
    bcc: '',
    cc: '',
    subject: '',
    attachmentData: '',
  };
  let isValid = true;

  if (!data.from) {
    errors.from = 'From is required';
    isValid = false;
  } else {
    const emailRegex = /^[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    if (!emailRegex.test(data.from || '')) {
      errors.from = 'Invalid email';
      isValid = false;
    }
  }

  if (!data.template?.id) {
    errors.template = 'Template is required';
    isValid = false;
  }

  if (!data.to?.length) {
    errors.to = 'To is required';
    isValid = false;
  }

  return {
    isValid,
    errors,
  };
};

const EmailProperties = ({ actionId }: { actionId: string }) => {
  const { selectedProperties, setSelectedProperties } = useWorkflowPropertiesStore();
  const [selectedPurpose, setSelectedPurpose] = useState<IOptions[]>([]);

  const purposeTypeOptions = useMemo<
    {
      label: string;
      value: string;
    }[]
  >(
    () => [
      {
        label: 'Commercial Agreement',
        value: 'COMMERCIAL_AGREEMENT',
      },
      {
        label: 'Commercial Agreement Supporting',
        value: 'COMMERCIAL_AGREEMENT_SUPPORTING',
      },
      {
        label: 'Delivery Document',
        value: 'DELIVERY_DOCUMENT',
      },
      {
        label: 'Dispute',
        value: 'DISPUTE',
      },
      {
        label: 'Dispute Resolution',
        value: 'DISPUTE_RESOLUTION',
      },
      {
        label: 'Enquiry',
        value: 'ENQUIRY',
      },
      {
        label: 'Enquiry Resolution',
        value: 'ENQUIRY_RESOLUTION',
      },
      {
        label: 'General',
        value: 'GENERAL',
      },
      {
        label: 'Invoice for buyer',
        value: 'INVOICE_FOR_BUYER',
      },
      {
        label: 'Invoice Supporting',
        value: 'INVOICE_SUPPORTING',
      },
      {
        label: 'Legal',
        value: 'LEGAL',
      },
      {
        label: 'Payment Proof',
        value: 'PAYMENT_PROOF',
      },
      {
        label: 'Purchase Order',
        value: 'PURCHASE_ORDER',
      },

      {
        label: 'Sales Order',
        value: 'SALES_ORDER',
      },
      {
        label: 'Tax Document',
        value: 'TAX_DOCUMENT',
      },
    ],
    [],
  );

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.trim().length < 1) {
        return purposeTypeOptions;
      }
      return purposeTypeOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.trim().toLowerCase()),
      );
    },
    [purposeTypeOptions],
  );
  const { data: stateResponse } = useGetStateById({
    stateId: selectedProperties?.nodeId || '',
    customConfig: {
      enabled: !!selectedProperties?.nodeId,
    },
  });

  const { data: actionResponse, isLoading } = useGetActionById({
    actionId,
    customConfig: {
      enabled: !!actionId,
    },
  });

  const { toast } = useToast();

  const { mutate: updateActionOnlyState, isPending: isUpdateStatePending } = usePostUpdateActionOnlyState({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update label. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateTriggerState, isPending: isTriggerStateUpdateLoading } = usePostUpdateTriggerState({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update trigger state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateConditionalState, isPending: isConditionalStateUpdateLoading } = usePostUpdateConditionalState({
    workflowId: stateResponse?.data.workflowId || '',
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update conditional state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateWaitState, isPending: isWaitStateUpdateLoading } = usePostUpdateWaitState({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update wait state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateAction, isPending: isUpdateActionPending } = usePostUpdateEmailAction({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update email action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const isPending = useMemo(
    () =>
      isUpdateActionPending ||
      isUpdateStatePending ||
      isTriggerStateUpdateLoading ||
      isConditionalStateUpdateLoading ||
      isWaitStateUpdateLoading,
    [
      isUpdateActionPending,
      isUpdateStatePending,
      isTriggerStateUpdateLoading,
      isConditionalStateUpdateLoading,
      isWaitStateUpdateLoading,
    ],
  );

  const {
    register,
    watch,
    formState: { errors: formErrors },
  } = useForm<{
    label: string;
  }>({
    values: {
      label: stateResponse?.data.label || '',
    },
    mode: 'onChange',
  });

  const action = useMemo(() => {
    if (!actionResponse) {
      return null;
    }

    return actionResponse.data;
  }, [actionResponse]);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelectPurpose();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false | true, IOptions>();
  }, []);

  const [errors, setErrors] = useState<Record<keyof Omit<IEmailProperties, 'clientId'>, string>>({
    from: '',
    template: '',
    to: '',
    cc: '',
    bcc: '',
    subject: '',
    attachmentData: '',
  });

  const [emailProperties, setEmailProperties] = useState<Omit<IEmailProperties, 'clientId'>>({
    from: '',
    template: {
      id: '',
      name: '',
    },
    to: [],
    cc: [],
    bcc: [],
    attachmentData: {},
  });

  const [bccEmailValue, setBCCEmailValue] = useState<string>('');

  const bccSelectValue = useMemo(() => {
    return emailProperties.bcc?.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
  }, [emailProperties]);

  useEffect(() => {
    const actionDescription = action?.description as Partial<IEmailActionProperties>;

    if (!actionDescription) {
      return;
    }

    setEmailProperties({
      from: actionDescription.fromEmail ? actionDescription.fromEmail : '',
      template: actionDescription.templateId
        ? {
            id: actionDescription.templateId,
            name: actionDescription.templateData?.name || '',
          }
        : {
            id: '',
            name: '',
          },
      to: actionDescription.recipients?.to
        ? actionDescription.recipients.to.map((item) => ({
            label: item,
            value: item,
          }))
        : [],
      cc: actionDescription.recipients?.cc ? actionDescription.recipients.cc : [],
      bcc: actionDescription.recipients?.bcc ? actionDescription.recipients.bcc : [],
      subject: actionDescription.subject ? actionDescription.subject : '',
      attachmentData: actionDescription.attachmentData
        ? { invoice: actionDescription.attachmentData.invoice, customer: actionDescription.attachmentData.customer }
        : {},
    });
  }, [action]);

  useEffect(() => {
    if (emailProperties?.attachmentData?.invoice) {
      const invoiceValues = emailProperties?.attachmentData?.invoice ?? [];

      const modifiedAttachmentData = purposeTypeOptions.filter((option) => invoiceValues.includes(option.value));
      setSelectedPurpose(modifiedAttachmentData ?? []);
    }
  }, [emailProperties, purposeTypeOptions]);

  if (!selectedProperties) return null;

  if (!action) return null;

  const handleFromEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailRegex = /^[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;

    setErrors({
      ...errors,
      from: '',
    });

    if (!emailRegex.test(e.target.value)) {
      setErrors({
        ...errors,
        from: 'Invalid email address',
      });
    }

    setEmailProperties({
      ...emailProperties,
      from: e.target.value,
    });
  };

  const handleEmailTemplateChange = (
    value: { value: string; label: string; templateData?: IEmailTemplate } | undefined,
  ) => {
    setErrors({
      ...errors,
      template: '',
    });

    if (!value) {
      setEmailProperties({
        ...emailProperties,
        template: {
          id: '',
          name: '',
        },
        subject: '',
      });
      return;
    }

    setEmailProperties({
      ...emailProperties,
      template: {
        id: value.value,
        name: value.label,
      },
      subject: value.templateData?.subjectContentHtml || '',
    });
  };

  const handleRoleSelect = (value: readonly IOption[] | undefined) => {
    setErrors({
      ...errors,
      to: '',
    });

    setEmailProperties({
      ...emailProperties,
      to: value ?? [],
    });
  };

  const handleSave = () => {
    const { isValid, errors: validationErrors } = validateEmailProperties(emailProperties);

    if (!isValid) {
      setErrors(validationErrors);
      return;
    }

    updateAction({
      actionId,
      fields: ['fromEmail', 'recipients', 'templateId', 'templateData', 'subject', 'attachmentData'],
      values: {
        fromEmail: emailProperties.from,
        recipients: {
          bcc: emailProperties.bcc || [],
          cc: emailProperties.cc || [],
          to: emailProperties.to?.map((item) => item.value) || [],
        },
        templateId: emailProperties.template?.id || '',
        templateData: {
          name: emailProperties.template?.name || '',
        },
        subject: emailProperties.subject || '',
        attachmentData:
          emailProperties.attachmentData?.invoice || emailProperties.attachmentData?.customer
            ? emailProperties.attachmentData
            : {},
      },
    });

    if (selectedProperties.type === 'ACTION_ONLY') {
      updateActionOnlyState({
        stateId: selectedProperties.nodeId,
        fields: ['label'],
        values: {
          label: watch('label') || '',
        },
      });

      return;
    }

    if (selectedProperties.type === 'CONDITIONAL') {
      updateConditionalState({
        stateId: selectedProperties.nodeId,
        fields: ['label'],
        values: {
          label: watch('label') || '',
        },
      });

      return;
    }

    if (selectedProperties.type === 'WAIT') {
      updateWaitState({
        stateId: selectedProperties.nodeId,
        fields: ['label'],
        values: {
          label: watch('label') || '',
        },
      });

      return;
    }

    if (selectedProperties.type === 'TRIGGER') {
      updateTriggerState({
        stateId: selectedProperties.nodeId,
        fields: ['label'],
        values: {
          label: watch('label') || '',
        },
      });
    }
  };

  const handleCCChange = (value: string[]) => {
    setErrors((prev) => {
      return {
        ...prev,
        cc: '',
      };
    });

    setEmailProperties({
      ...emailProperties,
      cc: value,
    });
  };

  const handleBCCChange = (
    value: MultiValue<{
      label: string;
      value: string;
    }>,
  ) => {
    setErrors((prev) => {
      return {
        ...prev,
        bcc: '',
      };
    });

    setEmailProperties({
      ...emailProperties,
      bcc: value.map((v) => v.value),
    });
  };

  const handleBCCKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!bccEmailValue) return;

    setErrors((prev) => {
      return {
        ...prev,
        bcc: '',
      };
    });

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    switch (e.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
      case ',':
        if (!emailRegex.test(bccEmailValue)) {
          setErrors((prev) => {
            return {
              ...prev,
              bcc: 'Invalid email address',
            };
          });
          return;
        }

        if (emailProperties.bcc?.length) {
          setEmailProperties({
            ...emailProperties,
            bcc: [...emailProperties.bcc, bccEmailValue],
          });
        } else {
          setEmailProperties({
            ...emailProperties,
            bcc: [bccEmailValue],
          });
        }

        setBCCEmailValue('');
        e.preventDefault();
    }
  };

  const handleClose = () => {
    setSelectedProperties(undefined);
  };

  if (isLoading) {
    return (
      <div>
        <Skeleton className="w-28 h-8" />
      </div>
    );
  }

  const handleSelectedPurposeChange = (value: MultiValue<IOptions>) => {
    const selectedPurposes = value.map((option) => option.value);

    const selectedAttachmentsData: IAttachmentDataMail = {
      customer: selectedPurposes,
      invoice: selectedPurposes,
    };
    setEmailProperties({
      ...emailProperties,
      attachmentData: selectedAttachmentsData ?? {},
    });
    const newValue = value.map((account) => ({ value: account.value, label: account.label }));
    setSelectedPurpose(newValue);
  };

  return (
    <PropertiesLayout>
      <div className=" flex h-full flex-col ">
        <div className=" flex justify-end ">
          <Button onClick={handleClose} variant="outline" size="icon">
            <XIcon className="w-4 h-4" />
          </Button>
        </div>
        <StateLabelInput register={register} errors={formErrors} />
        <h2 className=" font-semibold text-sm capitalize ">
          {action.type.toLowerCase().split('_').join(' ')} Properties
        </h2>
        <div className="flex flex-col gap-2 py-4 flex-1">
          <div>
            <div>Template</div>
            <EmailTemplateSelect
              onChange={handleEmailTemplateChange}
              placeholder="Select template"
              value={{
                value: emailProperties.template?.id || '',
                label: emailProperties.template?.name || '',
              }}
            />
            <p className="text-destructive text-xs h-6">{errors.template}</p>
          </div>
          <div>
            <div>From</div>
            <Input onChange={handleFromEmailInputChange} value={emailProperties.from} />
            <p className="text-destructive text-xs h-6">{errors.from}</p>
          </div>
          <div>
            <div>To</div>
            <CustomerRoleMultiSelect
              placeholder="Select role"
              value={emailProperties.to as IOption[]}
              onChange={handleRoleSelect}
              className="w-full max-w-none"
            />
            <p className="text-destructive text-xs h-6">{errors.to}</p>
          </div>
          <div>
            <div>CC</div>
            <CustomerRoleAndEmailMultiSelect
              onChange={handleCCChange}
              value={emailProperties.cc || []}
              placeholder="Enter role or email"
              setError={(error) => {
                setErrors((prev) => ({
                  ...prev,
                  cc: error,
                }));
              }}
              className=" min-w-[150px] max-w-[360px] w-full text-sm shadow-sm"
            />
            <p className="text-destructive text-xs h-6">{errors.cc}</p>
          </div>
          <div>
            <div>BCC</div>
            <AsyncSelect
              className=" min-w-[150px] max-w-[360px] w-full text-sm shadow-sm"
              inputValue={bccEmailValue}
              isMulti={true}
              onInputChange={(newValue, action) => {
                if (action.action === 'input-change') {
                  setBCCEmailValue(newValue);
                }
              }}
              components={{
                DropdownIndicator: () => null,
              }}
              onChange={handleBCCChange}
              menuIsOpen={false}
              onKeyDown={handleBCCKeydown}
              value={bccSelectValue}
              placeholder="Enter identifiers"
              styles={selectStyles}
              classNames={selectClasses}
            />
            <p className="text-destructive text-xs h-6">{errors.bcc}</p>
          </div>
          <div>
            <div className="flex gap-2 p-2">
              <div>Attachments</div>
              <div className="pt-[2px]">
                <Info
                  message={
                    'Select purposes below. The latest invoice attachment for each purpose will be included in the email. Total size of all attachments must be less then 20MB '
                  }
                />
              </div>
            </div>

            <AsyncSelect
              isMulti
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              onChange={handleSelectedPurposeChange}
              value={selectedPurpose}
              defaultOptions={purposeTypeOptions}
              loadOptions={loadSelectOptions}
              className=" min-w-[150px] max-w-[360px]text-sm shadow-sm"
              styles={selectStyles}
              placeholder="Search by purpose"
              classNames={selectClasses}
            />
            <p className="text-destructive text-xs h-6">{errors.attachmentData}</p>
          </div>
        </div>
        <div className=" py-2 flex items-center gap-4 justify-end ">
          <Button onClick={handleSave} disabled={isPending} className=" flex items-center gap-2 ">
            {isPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
            Save
          </Button>
        </div>
      </div>
    </PropertiesLayout>
  );
};

export default EmailProperties;
