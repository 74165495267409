import { dataAxiosInstance } from '@/lib/axios';
import { IActivityFilters, IGetActivityById } from '@/types/activity.types';
import dayjs from 'dayjs';

interface IGetActivityFilters extends IActivityFilters {
  page: number;
  pageSize: number;
}

export const getActivitiesByFilters = async ({
  page,
  pageSize,
  activityOriginReferenceId,
  activityOriginType,
  activityType,
  customers,
  subject,
  dateRange,
  hasAttachments,
  invoiceNumber,
  email,
  deliveredStatus,
}: IGetActivityFilters) => {
  let url = `/activity/search?page=${page}&pageSize=${pageSize}`;

  if (activityOriginReferenceId) {
    url += `&activityOriginReferenceId=${activityOriginReferenceId}`;
  }

  if (activityOriginType) {
    url += `&activityOriginType=${activityOriginType}`;
  }

  if (activityType) {
    url += `&activityType=${activityType}`;
  }

  if (customers?.length) {
    url += customers.map((customer) => `&customerId=${customer.value}`).join('');
  }

  if (subject) {
    url += `&subject=${subject}`;
  }

  if (dateRange?.from && dateRange?.to) {
    url += `&activityAfter=${dayjs(dateRange.from).format('YYYY-MM-DD')}&activityBefore=${dayjs(dateRange.to).format(
      'YYYY-MM-DD',
    )}`;
  }

  const hasAttachmentValueMap = {
    YES: 1,
    NO: 0,
  };

  if (hasAttachments) {
    url += `&hasAttachments=${hasAttachmentValueMap[hasAttachments]}`;
  }

  if (invoiceNumber?.length) {
    url += invoiceNumber.map((invoice: string) => `&invoiceNumber=${invoice}`).join('');
  }

  if (deliveredStatus) {
    url += deliveredStatus.map((d) => `&deliveredStatus=${d}`).join('');
  }

  if (email?.length) {
    url += email.map((to: string) => `&emails=${to}`).join('');
  }

  const result = await dataAxiosInstance.get(url);

  return result.data;
};

export const getActivityById = async ({ activityId }: { activityId: string }) => {
  const result = await dataAxiosInstance.get<IGetActivityById>(`/email/${activityId}`);

  return result.data;
};
