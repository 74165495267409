import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { useMemo } from 'react';

export const useColumnOrder = <T extends { id?: string }>({ key, columns }: { key: string; columns: T[] }) => {
  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    key,
    defaultColumnOrder,
    (alreadyStoredValue, valueToSet) => alreadyStoredValue.length !== valueToSet.length,
  );

  return { columnOrder, setColumnOrder };
};
