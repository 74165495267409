import CreditsTable from '@/components/posting/credits/CreditsTable';
import SwitchPostingFlow from '@/components/posting/shared/SwitchPostingFlow';
import { Layout } from '@/components/shared/Layout';

const CreditsPostingPage = () => {
  return (
    <Layout className=" flex-1 flex flex-col ">
      <div>
        <SwitchPostingFlow current="available-credits" />
      </div>
      <CreditsTable />
    </Layout>
  );
};

export default CreditsPostingPage;
