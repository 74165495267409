import { dataAxiosInstance } from '@/lib/axios';

export const getTagsByCustomerId = async (customerId: string) => {
  const result = await dataAxiosInstance.get(`/customer/${customerId}/tags`);
  return result.data;
};

export const getCustomerTagNameSuggestions = async (query: string) => {
  const result = await dataAxiosInstance.get(`/customerTag/keys?like=${query.trim()}`);
  return result.data;
};

export const getCustomerTagValueSuggestions = async (query: string) => {
  const result = await dataAxiosInstance.get(`/customerTag/values?key=${query.trim()}`);
  return result.data;
};

export const postCreateTagForCustomer = async ({
  customerId,
  tagKey,
  tagValue,
}: {
  customerId: string;
  tagKey: string;
  tagValue: string;
}) => {
  const result = await dataAxiosInstance.post('/customerTag', {
    customerId,
    tagKey,
    tagValue,
  });
  return result.data;
};

export const deleteTagForCustomer = async ({ customerId, tagKey }: { customerId: string; tagKey: string }) => {
  const result = await dataAxiosInstance.delete(`/customerTag/delete?customerId=${customerId}&key=${tagKey}`);
  return result.data;
};
