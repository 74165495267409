import { Input } from '@/components/ui/input';
import SelectComponent from '@/components/ui/select-component';
import { useStore } from '@/stores/email-builder/page-sections.store';
import { createId } from '@/utils/createId';
import { Section } from '@/utils/email-builder/sections';
import { isValidUrl } from '@/utils/isValidUrl';
import { useMemo } from 'react';
import TableProperties from './TableProperties';

const PropertiesEditor = () => {
  const { selectedSection, sections, updateSection, updateTableHeaderLabel } = useStore();

  const section = useMemo(() => {
    if (!selectedSection) return null;
    return sections[selectedSection];
  }, [sections, selectedSection]);

  if (!section) return null;

  const handleChange = (field: 'left' | 'right' | 'top' | 'bottom') => (value: number) => {
    if (!section) return;
    updateSection({
      ...section,
      properties: {
        ...section.properties,
        element: {
          ...section.properties.element,
          padding: {
            ...section.properties.element.padding,
            [field]: value,
          },
        },
      },
    });
  };

  const handleOperationChange = (value: string, columnId: string) => {
    if (!section) return;
    const currentSection = section as Section<'table'>;
    updateSection({
      ...currentSection,
      content: {
        ...currentSection.content,
        content: {
          ...currentSection.content.content,
          columns: currentSection.content.content.columns.map((column) => {
            if (column.columnId === columnId) {
              return {
                ...column,
                operation: value as 'sum' | 'none',
                operationLabel: value === 'none' ? '' : value.toUpperCase(),
              };
            }
            return column;
          }),
        },
      },
    });
  };

  const handleOperationLabelChange = (value: string, columnId: string) => {
    if (!section) return;
    const currentSection = section as Section<'table'>;
    updateSection({
      ...currentSection,
      content: {
        ...currentSection.content,
        content: {
          ...currentSection.content.content,
          columns: currentSection.content.content.columns.map((column) => {
            if (column.columnId === columnId) {
              return {
                ...column,
                operationLabel: value,
              };
            }
            return column;
          }),
        },
      },
    });
  };

  const handleEditFieldName = (value: string, columnId: string) => {
    if (!section) return;

    const currentSection = section as Section<'table'>;

    if (currentSection.content.content.variable === 'Manual') {
      updateTableHeaderLabel({
        columnId,
        sectionId: currentSection.id,
        value: value,
      });
    } else {
      updateSection({
        ...currentSection,
        content: {
          ...currentSection.content,
          content: {
            ...currentSection.content.content,
            columns: currentSection.content.content.columns.map((column) => {
              if (column.columnId === columnId) {
                return {
                  ...column,
                  fieldName: value,
                };
              }
              return column;
            }),
          },
        },
      });
    }
  };

  const handleRemoveColumn = (fieldName: string) => {
    if (!section) return;
    const currentSection = section as Section<'table'>;
    updateSection({
      ...currentSection,
      content: {
        ...currentSection.content,
        content: {
          ...currentSection.content.content,
          columns: currentSection.content.content.columns.filter((column) => column.fieldName !== fieldName),
        },
      },
    });
  };

  const handleAddRow = () => {
    if (!section) return;
    const currentSection = section as Section<'table'>;

    const newRow = currentSection.content.content.columns.reduce(
      (acc, current) => {
        return {
          ...acc,
          [current.fieldName]: current.label,
        };
      },
      {
        id: createId(),
      } as {
        [key: string]: string;
        id: string;
      },
    );

    updateSection({
      ...currentSection,
      content: {
        ...currentSection.content,
        content: {
          ...currentSection.content.content,
          rows: currentSection.content.content.rows?.length
            ? [...currentSection.content.content.rows, newRow]
            : [newRow],
        },
      },
    });
  };

  const handleAlignmentChange = (value: string) => {
    const alignmentValue = value as 'start' | 'center' | 'end';
    if (!section) return;
    const currentSection = section as Section<'table'>;
    updateSection({
      ...currentSection,
      properties: {
        ...currentSection.properties,
        element: {
          ...currentSection.properties.element,
          align: alignmentValue,
        },
      },
    });
  };

  const handleUrlChange = (value: string) => {
    if (!section) return;
    const currentSection = section as Section<'button'>;
    updateSection({
      ...currentSection,
      properties: {
        ...currentSection.properties,
        element: {
          ...currentSection.properties.element,
          url: value,
        },
      },
    });
  };

  const handleSourceChange = (value: string) => {
    if (value === 'Invoice') {
      const invoiceColumns: {
        fieldName: string;
        label: string;
        isVariable: boolean;
        operation: 'sum' | 'none';
        columnId: string;
        operationLabel: string;
      }[] = [
        {
          columnId: createId(),
          fieldName: '{{invoice.invoiceNumber}}',
          label: 'Number',
          isVariable: true,
          operation: 'none',
          operationLabel: '',
        },
        {
          columnId: createId(),
          fieldName: '{{invoice.invoiceDate}}',
          label: 'Invoice date',
          isVariable: true,
          operation: 'none',
          operationLabel: '',
        },
        {
          columnId: createId(),
          fieldName: '{{invoice.invoiceTotal}}',
          label: 'Invoice amount',
          isVariable: true,
          operation: 'none',
          operationLabel: '',
        },
        {
          columnId: createId(),
          fieldName: '{{invoice.invoiceType}}',
          label: 'Type',
          isVariable: true,
          operation: 'none',
          operationLabel: '',
        },
        {
          columnId: createId(),
          fieldName: '{{invoice.customerName}}',
          label: 'Customer',
          isVariable: true,
          operation: 'none',
          operationLabel: '',
        },
        {
          columnId: createId(),
          fieldName: '{{invoice.invoiceDueDate}}',
          label: 'Due Date',
          isVariable: true,
          operation: 'none',
          operationLabel: '',
        },
        {
          columnId: createId(),
          fieldName: '{{invoice.promiseToPayDate}}',
          label: 'Promise To Pay Date',
          isVariable: true,
          operation: 'none',
          operationLabel: '',
        },
        {
          columnId: createId(),
          fieldName: '{{invoice.invoiceOutstandingAmount}}',
          label: 'Due/Overdue',
          isVariable: true,
          operation: 'none',
          operationLabel: '',
        },
        {
          columnId: createId(),
          fieldName: '{{invoice.invoiceStatus}}',
          label: 'Invoice Status',
          isVariable: true,
          operation: 'none',
          operationLabel: '',
        },
        {
          columnId: createId(),
          fieldName: '{{invoice.paymentStatus}}',
          label: 'Payment Status',
          isVariable: true,
          operation: 'none',
          operationLabel: '',
        },
      ];

      const row = invoiceColumns.reduce(
        (acc, current) => {
          return {
            ...acc,
            [current.fieldName]: current.fieldName,
          };
        },
        {
          id: createId(),
        },
      );

      updateSection({
        ...(section as Section<'table'>),
        content: {
          ...(section as Section<'table'>).content,
          content: {
            ...(section as Section<'table'>).content.content,
            columns: invoiceColumns,
            rows: [row],
            variable: 'Invoice',
          },
        },
      });

      return;
    }

    const manualColumns: {
      fieldName: string;
      label: string;
      isVariable: boolean;
      operation: 'sum' | 'none';
      columnId: string;
      operationLabel: string;
    }[] = [
      {
        columnId: createId(),
        fieldName: 'fieldName',
        label: 'First label',
        isVariable: false,
        operation: 'none',
        operationLabel: '',
      },
      {
        columnId: createId(),
        fieldName: 'something',
        label: 'Second label',
        isVariable: true,
        operation: 'none',
        operationLabel: '',
      },
    ];

    const row = manualColumns.reduce(
      (acc, current) => {
        return {
          ...acc,
          [current.fieldName]: current.label,
        };
      },
      {
        id: createId(),
      },
    );

    updateSection({
      ...(section as Section<'table'>),
      content: {
        ...(section as Section<'table'>).content,
        content: {
          ...(section as Section<'table'>).content.content,
          variable: value as 'Invoice' | 'Manual',
          columns: manualColumns,
          rows: [row],
        },
      },
    });
  };

  if (section.type === 'table') {
    return (
      <TableProperties
        handleOperationLabelChange={handleOperationLabelChange}
        handleAddRow={handleAddRow}
        handleEditFieldName={handleEditFieldName}
        handleOperationChange={handleOperationChange}
        handleRemoveColumn={handleRemoveColumn}
        handleSourceChange={handleSourceChange}
        section={section as Section<'table'> | undefined}
        selectedSection={selectedSection}
      />
    );
  }

  if (section.type === 'button') {
    return (
      <div className=" py-4 text-sm flex flex-col gap-4 ">
        <h3 className=" font-semibold text-lg ">Properties</h3>
        <p>
          <span className=" font-semibold ">Id:</span> {section.id}
        </p>
        <div>
          <h3 className=" font-semibold text-base">Padding</h3>
          <div>
            <p>Left</p>
            <Input
              onChange={(e) => handleChange('left')(Number(e.target.value))}
              value={section.properties.element.padding.left}
            />
          </div>
          <div>
            <p>Right</p>
            <Input
              onChange={(e) => handleChange('right')(Number(e.target.value))}
              value={section.properties.element.padding.right}
            />
          </div>
          <div>
            <p>Top</p>
            <Input
              onChange={(e) => handleChange('top')(Number(e.target.value))}
              value={section.properties.element.padding.top}
            />
          </div>
          <div>
            <p>Bottom</p>
            <Input
              onChange={(e) => handleChange('bottom')(Number(e.target.value))}
              value={section.properties.element.padding.bottom}
            />
          </div>
        </div>
        <div>
          <h3 className=" font-semibold text-base">Alignment</h3>
          <SelectComponent
            options={[
              {
                value: 'start',
                label: 'Start',
              },
              {
                value: 'center',
                label: 'Center',
              },
              {
                value: 'end',
                label: 'End',
              },
            ]}
            value={section.properties.element.align}
            onChange={handleAlignmentChange}
          />
        </div>
        <div>
          <h3 className=" font-semibold text-base">Url</h3>
          <Input onChange={(e) => handleUrlChange(e.target.value)} value={section.properties.element.url} />
          {section.properties.element.url?.trim() && !isValidUrl(section.properties.element.url) && (
            <div className=" text-xs text-red-500 ">Invalid url</div>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default PropertiesEditor;
