import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useGetInvoiceById } from '@/hooks/api-hooks/useInvoiceQuery';
import { IAppliedInvoiceToCreditNote } from '@/types/credit-notes.types';
import { formatCurrency } from '@/utils/formatCurrency';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { ColumnDef, flexRender, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from '../ui/skeleton';
import DeleteAppliedCreditNoteFromCNDetailsDialog from './DeleteAppliedCreditNoteDialog';

const AttachedInvoiceRow = ({ row }: { row: Row<IAppliedInvoiceToCreditNote> }) => {
  const { data: invoiceData, isLoading } = useGetInvoiceById({
    invoiceId: row.original.invoiceId,
    customConfig: {
      enabled: !!row.original.invoiceId,
    },
  });

  if (isLoading) {
    return (
      <TableRow>
        {new Array(6).fill(0).map((_, i) => (
          <TableCell className="text-center" key={i}>
            <Skeleton className="w-full h-4" />
          </TableCell>
        ))}
      </TableRow>
    );
  }

  if (!invoiceData?.data) {
    return null;
  }

  const actionCell = row.getVisibleCells()[row.getVisibleCells().length - 1];

  return (
    <TableRow className=" text-sm " key={row.id}>
      <TableCell>
        <Link className=" text-blue-700 underline " to={`/invoice/view/${invoiceData.data.id}`}>
          {invoiceData.data.invoiceNumber}
        </Link>
      </TableCell>
      <TableCell>{invoiceData.data.invoiceType}</TableCell>
      <TableCell>
        <Link className=" text-blue-700 underline " to={`/customer/view/${invoiceData.data.customerId}`}>
          {invoiceData.data.customerName}
        </Link>
      </TableCell>
      <TableCell>
        <div>{formatCurrency(invoiceData.data.invoiceOutstandingAmount)}</div>
        <div className="  text-xs text-muted-foreground">Total: {formatCurrency(invoiceData.data.invoiceTotal)}</div>
      </TableCell>
      <TableCell>
        <div>{dayjs(row.original.appliedOn).format('DD MMM YYYY')}</div>
        <div className="  text-muted-foreground text-xs">by {row.original.createdBy}</div>
      </TableCell>
      <TableCell>{formatCurrencyByUnit(row.original.appliedAmount, 'actual')}</TableCell>
      <TableCell>{flexRender(actionCell.column.columnDef.cell, actionCell.getContext())}</TableCell>
    </TableRow>
  );
};

const AttachedInvoices = ({
  invoices,
  showDelete = true,
  creditNoteId,
}: {
  invoices: IAppliedInvoiceToCreditNote[];
  showDelete?: boolean;
  creditNoteId: string;
}) => {
  const columns = useMemo<ColumnDef<IAppliedInvoiceToCreditNote>[]>(
    () => [
      {
        id: 'invoice',
        header: 'Invoice number',
      },
      {
        id: 'type',
        header: 'Type',
      },
      {
        id: 'customer',
        header: 'Customer',
      },
      {
        id: 'due',
        header: 'Due/Overdue Amount',
      },
      {
        id: 'date',
        header: 'Applied On',
      },
      {
        id: 'amount',
        header: 'Applied Amount',
      },
      {
        id: 'actions',
        cell({ row }) {
          return showDelete ? (
            <DeleteAppliedCreditNoteFromCNDetailsDialog
              creditNoteId={creditNoteId}
              invoice={row.original}
              postingId={row.original.postingId}
            />
          ) : null;
        },
      },
    ],
    [showDelete, creditNoteId],
  );

  const table = useReactTable({
    data: invoices || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!invoices || invoices.length === 0) {
    return (
      <div className="text-center text-muted-foreground">
        <p className="text-sm">No invoices attached</p>
      </div>
    );
  }

  return (
    <div>
      <Table>
        <TableHeader className=" bg-gray-100 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <AttachedInvoiceRow row={row} key={row.id} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AttachedInvoices;
