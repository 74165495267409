import ToolTipCell from '@/components/Table/ToolTipCell';
import { Card, CardContent } from '@/components/ui/card';
import { useGetStateById } from '@/hooks/api-hooks/useWorkflowQuery';
import { IState } from '@/types/workflow.type';
import { AlertTriangleIcon } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Handle, NodeProps, Position } from 'reactflow';

const ExitBlock = (props: NodeProps<IState<'EXIT'>>) => {
  const navigate = useNavigate();
  const { workflowId } = useParams();

  const { data: exitStateResponse } = useGetStateById({
    stateId: props.id,
    customConfig: {
      enabled: !!props.id,
    },
  });

  return (
    <>
      <Handle id="default" type="target" position={Position.Top} />

      <div
        onClick={() => navigate(`/workflow/create/exit-criteria/${workflowId}`)}
        className=" w-[350px] flex items-center justify-center "
      >
        {!!exitStateResponse?.warnings?.length && exitStateResponse.warnings.length > 0 && (
          <div className=" absolute left-0 text-destructive ">
            <ToolTipCell value={<AlertTriangleIcon className=" w-4 h-4 " />}>
              <div className=" flex flex-col gap-1 ">
                {exitStateResponse.warnings.map((w, index) => (
                  <div key={w}>
                    {index + 1}. {w}
                  </div>
                ))}
              </div>
            </ToolTipCell>
          </div>
        )}
        <Card className=" rounded-md  w-[150px] ">
          <CardContent className=" py-4  text-sm text-center  relative ">
            Exit
            <Handle id="goto-target" type="target" position={Position.Right} />
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default ExitBlock;
