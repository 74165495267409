import { useGetPlaceholderDetails } from '@/hooks/api-hooks/usePostingQuery';
import { IPostedToCreditObject } from '@/types/posting.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import DeletePosting from '../../shared/DeletePostingDialog';
import SubItem from '../SubItems';

export const PostedPlaceholder = ({
  placeholderId,
  postedBy,
  postedAmount,
  customerName,
  postingId,
  postedOn,
  creditId,
}: {
  creditId: string;
  postingId: string;
  postedOn?: string;
  placeholderId: string;
  postedBy: string;
  postedAmount: number;
  customerName?: string;
}) => {
  const { data: placeHolderDetails, isLoading } = useGetPlaceholderDetails({
    placeholderId: placeholderId || '',
  });

  const placeholder = useMemo(() => {
    return placeHolderDetails?.data || null;
  }, [placeHolderDetails]);

  if (isLoading) {
    return (
      <div className="flex justify-between gap-2">
        <Skeleton className=" w-[160px]" />
        <Skeleton className=" w-[440px]" />
        <Skeleton className=" w-[160px]" />
        <Skeleton className=" w-[160px]" />
      </div>
    );
  }

  if (!placeholder) return null;

  return (
    <div className="flex justify-between gap-2">
      <SubItem className=" min-w-[160px]" label="Label" value={placeholder.name} />
      <SubItem
        className=" min-w-[160px]"
        label={customerName?.trim() ? 'Customer Name' : ''}
        value={customerName?.trim() || ''}
      />
      <SubItem className=" min-w-[160px]" label="" value={''} />
      <SubItem className=" min-w-[160px]" label="" value={''} />
      <SubItem className=" min-w-[160px]" label="Posted By" value={postedBy} />
      <SubItem className=" min-w-[160px]" label="Amount" value={formatCurrencyByUnit(postedAmount, 'actual', 'INR')} />
      <DeletePosting
        type="Placeholder"
        descriptionTitle="Posted to"
        createdBy={postedBy}
        descriptionValue={placeholder.name}
        postingId={postingId}
        timestamp={postedOn}
        creditId={creditId}
      />
    </div>
  );
};

export const PostedInvoice = ({
  postedInvoice,
  creditId,
}: {
  postedInvoice: IPostedToCreditObject;
  creditId?: string;
}) => {
  return (
    <div className="flex justify-between gap-2">
      <SubItem
        className=" min-w-[160px]"
        label="Posted By"
        value={postedInvoice.createdBy}
        description={dayjs(postedInvoice.timestamp).add(330, 'minute').format('DD MMM YY, HH:mm A')}
      />
      <SubItem className=" min-w-[160px]" label="Invoice Number" value={postedInvoice.invoice.invoiceNumber} />
      <SubItem className=" min-w-[160px]" label="Customer Name" value={postedInvoice.invoice.customerName} />
      <SubItem
        className=" min-w-[160px]"
        label="Invoice Date"
        value={dayjs(postedInvoice.invoice.invoiceDate).format('DD MMM YY')}
      />
      <SubItem
        className=" min-w-[160px]"
        label="Invoice Value"
        value={formatCurrencyByUnit(postedInvoice.invoice.invoiceTotal, 'actual', 'INR')}
      />
      <SubItem
        className=" min-w-[160px]"
        label="Posted"
        value={formatCurrencyByUnit(postedInvoice.postedAmount, 'actual', 'INR')}
      />
      <DeletePosting
        createdBy={postedInvoice.createdBy}
        postingId={postedInvoice.postingId}
        timestamp={postedInvoice.timestamp || ''}
        type="Invoice"
        descriptionTitle={'Invoice Number'}
        descriptionValue={postedInvoice.invoice.invoiceNumber}
        creditId={creditId}
        invoiceId={postedInvoice.invoice.id}
      />
    </div>
  );
};

export const PostedToCreditItem = ({
  postedToCreditObject,
  creditId,
}: {
  postedToCreditObject: IPostedToCreditObject;
  creditId?: string;
}) => {
  if (postedToCreditObject.invoice.id) {
    return <PostedInvoice postedInvoice={postedToCreditObject} creditId={creditId} />;
  }

  if (postedToCreditObject.placeholderId) {
    return (
      <PostedPlaceholder
        creditId={postedToCreditObject.transactionId}
        postedAmount={postedToCreditObject.postedAmount}
        postedBy={postedToCreditObject.createdBy}
        postingId={postedToCreditObject.postingId}
        placeholderId={postedToCreditObject.placeholderId}
        customerName={postedToCreditObject.customerName}
      />
    );
  }

  return null;
};
