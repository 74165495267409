import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { usePostAttachPan } from '@/hooks/api-hooks/useUser';
import { useQueryClient } from '@tanstack/react-query';
import { Loader2Icon } from 'lucide-react';
import { useForm } from 'react-hook-form';

interface IPanForm {
  pan: string;
}

const AttachPan = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IPanForm>();

  const { toast } = useToast();

  const queryClient = useQueryClient();

  const {
    mutate: attachPan,
    isError,
    error,
    isPending,
  } = usePostAttachPan({
    customConfig: {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['auth'] });
      },
      onError() {
        toast({
          title: 'Pan cannot be verified',
          variant: 'destructive',
        });
      },
    },
  });

  const onSubmit = (data: IPanForm) => {
    attachPan({
      ...data,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className=" w-full flex flex-col items-start ">
        <h2 className=" text-lg sm:text-2xl font-semibold mb-8">PAN Verification</h2>
        <div className=" mt-4 mb-2 w-full ">
          <p className=" text-sm text-muted-foreground  ">Please enter your PAN</p>
          <Input
            autoFocus
            placeholder="Eg: ABEPW1234Q"
            className=" w-full my-1 "
            {...register('pan', {
              required: {
                value: true,
                message: 'Pan is required',
              },
              pattern: {
                value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i,
                message: 'Entered pan is invalid',
              },
            })}
          />
          {isError && (
            <p className=" text-xs text-red-500 h-6 p-0.5 ">
              {error.response?.data.message && error.response.data.message}
            </p>
          )}
          {!isError && <p className=" text-xs text-red-500 h-6 p-0.5 ">{errors.pan?.message && errors.pan.message}</p>}
        </div>
        <Button disabled={isPending} type="submit" className=" w-full flex items-center gap-4 ">
          Save
          {isPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
        </Button>
      </form>
    </>
  );
};

export default AttachPan;
