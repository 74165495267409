import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { useUserContext } from '@/contexts/UserContext';
import { useAddInviteMutation } from '@/hooks/api-hooks/useCompanyQuery';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Loading } from '../shared/Loading';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/form';
import { useToast } from '../ui/use-toast';

const emailRegex = /^[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
const invitationFormSchema = z.object({
  emails: z
    .string()
    .refine(
      (value) => {
        const emailArray = value.split(',').map((email) => email.trim());
        return emailArray.every((email) => emailRegex.test(email));
      },
      {
        message: 'Emails entered are not valid',
      },
    )
    .transform((value) => value.split(',').map((email) => email.trim())),
});

interface AddInviteDialogProps {
  isAddInviteModelOpen: boolean;
  setIsAddInviteModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AddInviteDialog({ isAddInviteModelOpen, setIsAddInviteModelOpen }: AddInviteDialogProps) {
  const form = useForm({
    resolver: zodResolver(invitationFormSchema),
    defaultValues: {
      emails: '',
    },
  });
  const contextData = useUserContext();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const { mutate: addNewInvite } = useAddInviteMutation({
    customConfig: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['invites'],
        });
      },
      onError: (error: unknown) => {
        // @ts-expect-error no common error type defined
        throw new Error(error?.response ? error.response.data.message : 'Something went wrong');
      },
    },
  });
  useEffect(() => {
    if (!isAddInviteModelOpen) {
      form.reset();
    }
  }, [isAddInviteModelOpen, form]);

  const onSubmit = async () => {
    const emailsEntered = form.getValues().emails;
    const emailsArray = emailsEntered.split(',').map((email) => email.trim());
    //ToDo Fix this later
    try {
      setLoading(true);
      await Promise.all(
        emailsArray.map((email) => addNewInvite({ email, companyId: contextData?.companiesOfUser?.[contextData?.activeCompanyIndex]?.id })),
      );
      setLoading(false);
      setIsAddInviteModelOpen(false);
      toast({
        title: 'Invites sent successfully !!!',
        duration: 5000,
      });
      form.reset();
    } catch (error: unknown) {
      form.setError('emails', {
        type: 'manual',
        // @ts-expect-error no common error type
        message: error.message,
      });
    }
  };
  if (loading) return <Loading />;
  return (
    <>
      {isAddInviteModelOpen && (
        <Dialog open={isAddInviteModelOpen} onOpenChange={setIsAddInviteModelOpen}>
          <DialogContent className="min-w-[350px] min-h-[200px] bg-white p-4 border-2 rounded-md">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <DialogHeader>
                  <DialogTitle>Invite members</DialogTitle>
                  <DialogDescription>Enter emails to invite to your company</DialogDescription>
                </DialogHeader>
                <FormField
                  control={form.control}
                  name="emails"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <div className="flex-row align-center space-y-3">
                        <FormLabel className="w-24">Emails</FormLabel>
                      </div>
                      <FormControl>
                        <Input
                          autoComplete="off"
                          placeholder="Add comma separated (eg: one@org.com, two@org.com)"
                          {...field}
                          required={true}
                        />
                      </FormControl>
                      {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                    </FormItem>
                  )}
                />
                <DialogFooter>
                  <Button type="submit" className="mt-5">
                    Send Invites
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
