import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import AttachPan from '@/components/user/AttachPan';
import WithTopNav from '@/components/wrappers/WithTopNavBar';
import { useAuthToken } from '@/hooks/api-hooks/useAuth';
import { usePostCreateCompany } from '@/hooks/api-hooks/useCompanyQuery';
import { useGetUserDetails } from '@/hooks/api-hooks/useUser';
import Lottie from 'lottie-react';
import { Loader2Icon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import heroAnimationData from '../assets/animation_llay8o41.json';

interface ICinform {
  cin: string;
}

const CinForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ICinform>();

  const { toast } = useToast();

  const navigate = useNavigate();

  const { data: userData } = useGetUserDetails({});

  const { mutate: addCompany, isPending } = usePostCreateCompany({
    customConfig: {
      onSuccess: () => {
        toast({
          title: `Welcome ${userData?.data?.firstName} ${userData?.data?.lastName}`,
          description: 'Company added successfully, redirecting to dashboard',
          variant: 'default',
        });
        navigate('/cashflow/summary');
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          title: 'Some error occurred',
          description: error.response?.data.message,
        });
      },
    },
  });

  const onSubmit = (data: ICinform) => {
    addCompany({
      cin: data.cin.toUpperCase(),
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className=" w-full flex flex-col items-start ">
      <h2 className=" text-lg sm:text-2xl font-semibold mb-8">Add Company</h2>
      <div className="my-4 w-full ">
        <p className=" text-sm text-muted-foreground  ">Enter your Corporate Identity Number (CIN)</p>
        <Input
          autoFocus
          placeholder="eg: L01631KA2010PTC091231"
          className=" w-full my-1"
          {...register('cin', {
            required: {
              value: true,
              message: 'CIN is required',
            },
            pattern: {
              value: /^([LU]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/i,
              message: 'Entered cin is invalid',
            },
          })}
        />
        <p className=" text-xs text-red-500 h-6 p-0.5 ">{errors.cin?.message && errors.cin.message}</p>
      </div>
      <Button disabled={isPending} type="submit" className=" w-full flex items-center gap-4 ">
        Finish Setup
        {isPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
      </Button>
    </form>
  );
};

const AttachPanDialog = () => {
  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          <Button>Attach Pan</Button>
        </DialogTrigger>
        <DialogContent>
          <AttachPan />
        </DialogContent>
      </Dialog>
    </>
  );
};

const AddCompanyPage = () => {
  const { data } = useAuthToken();

  if (data?.data.status === 'PAN_VERIFICATION_PENDING') {
    return (
      <WithTopNav>
        <div className="flex-1 flex flex-col gap-12 md:flex-row mx-auto items-center justify-around sm:gap-8 max-w-6xl w-full px-4">
          <div className=" flex flex-col gap-8 ">
            <h2 className="text-xl">Please verify your PAN first to continue</h2>
            <AttachPanDialog />
          </div>
        </div>
      </WithTopNav>
    );
  }

  return (
    <WithTopNav>
      <div className="flex-1 flex flex-col gap-12 md:flex-row items-center justify-around sm:gap-8 max-w-6xl w-full px-4">
        <div className=" w-full sm:min-w-[400px] sm:max-w-sm  ">
          <Lottie animationData={heroAnimationData} loop={true} autoplay={true} />
        </div>
        <div className="w-full md:max-w-sm md:min-w-[400px] ">
          <CinForm />
        </div>
      </div>
    </WithTopNav>
  );
};

export default AddCompanyPage;
