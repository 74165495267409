'use client';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Column, ColumnDef, ColumnOrderState } from '@tanstack/react-table';
import { GripIcon } from 'lucide-react';
import { useMemo, useState } from 'react';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Checkbox } from '../ui/checkbox';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

interface ICustomColumn<T> extends Column<T> {
  columnDef: ColumnDef<T> & { label?: string };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ColumnItem = ({ column }: { column: ICustomColumn<any> }) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({
    id: column.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <DropdownMenuItem
      ref={setNodeRef}
      style={style}
      className=" capitalize justify-between items-center "
      key={column.id}
    >
      <span onClick={column.getToggleVisibilityHandler()} className=" flex-1 flex gap-2 items-center ">
        <Checkbox checked={column.getIsVisible()} />
        {column.columnDef.label ? column.columnDef.label : column.columnDef.header?.toString()}
      </span>
      <Button ref={setActivatorNodeRef} {...listeners} {...attributes} variant="ghost" size="icon">
        <GripIcon className=" w-4 h-4 " />
      </Button>
    </DropdownMenuItem>
  );
};

export function ColumnSelector<T>({
  columns,
  setColumnOrder,
  columnOrder,
}: {
  columns: ICustomColumn<T>[];
  setColumnOrder: React.Dispatch<React.SetStateAction<ColumnOrderState>>;
  columnOrder: ColumnOrderState;
}) {
  const [open, setOpen] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const columnIdToDetailMap = useMemo(() => {
    return columns.reduce(
      (acc, column) => {
        acc[column.id] = column;
        return acc;
      },
      {} as Record<string, ICustomColumn<T>>,
    );
  }, [columns]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const newIndex = columnOrder.findIndex((columnId) => columnId === over?.id);
      const oldIndex = columnOrder.findIndex((columnId) => columnId === active.id);

      const newColumns = arrayMove(columnOrder, oldIndex, newIndex);

      setColumnOrder(newColumns);
    }
  };

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild>
        <Button onClick={() => setOpen((prev) => !prev)} variant="outline">
          Select columns
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onInteractOutside={() => setOpen(false)}
        onEscapeKeyDown={() => setOpen(false)}
        onFocusOutside={() => setOpen(false)}
        onPointerDownOutside={() => setOpen(false)}
        className="w-56 overflow-y-scroll h-60"
      >
        <DropdownMenuLabel className=" sticky -top-1 bg-white z-50 ">Hide/Show columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={columnOrder} strategy={verticalListSortingStrategy}>
            {columnOrder
              .filter((columnId) => !['actions', 'select', 'view'].includes(columnId))
              .filter((item) => !!item)
              .map(
                (columnId) =>
                  columnIdToDetailMap[columnId] && <ColumnItem key={columnId} column={columnIdToDetailMap[columnId]} />,
              )}
          </SortableContext>
        </DndContext>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
