import DeleteEmailTemplateDialog from '@/components/email-builder/DeleteTemplateDialog';
import { PreviewTemplateDialog } from '@/components/email-builder/PreviewTemplate';
import Pagination from '@/components/shared/Pagination';
import TableSkeleton from '@/components/shared/TableSkeleton';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import Empty from '@/components/ui/empty';
import Heading from '@/components/ui/heading';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useUserContext } from '@/contexts/UserContext';
import { useGetEmailTemplatesByCompanyId } from '@/hooks/api-hooks/useEmailTemplatesQuery';
import { IEmailTemplate } from '@/types/email-templates.types';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import {
  ColumnDef,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { PlusIcon } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const ActionsCell = ({ row }: { row: Row<IEmailTemplate> }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

  return (
    <>
      <DeleteEmailTemplateDialog
        isDeleteDialogOpen={isDeleteDialogOpen}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        templateId={row.original.id}
      />
      <PreviewTemplateDialog
        isPreviewDialogOpen={isPreviewDialogOpen}
        setIsPreviewDialogOpen={setIsPreviewDialogOpen}
        variables={row.original.config.variables}
        templateId={row.original.id}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <DotsHorizontalIcon className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem asChild>
            <Link to={`/email-builder/${row.original.id}`}>Edit template</Link>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setIsPreviewDialogOpen(true)}>Preview</DropdownMenuItem>
          <DropdownMenuItem onClick={() => setIsDeleteDialogOpen(true)}>Delete</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

const columns: ColumnDef<IEmailTemplate>[] = [
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Name',
    cell: ({ getValue }) => getValue(),
  },
  {
    accessorKey: 'lastUpdated',
    header: 'Last updated on',
    cell: ({ getValue }) => dayjs(getValue() as string).format('DD MMM YYYY'),
  },
  {
    accessorKey: 'updatedBy',
    header: 'Last updated by',
    cell: ({ getValue }) => getValue() as string,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ getValue }) => <Badge className=" capitalize ">{(getValue() as string).toLowerCase()}</Badge>,
  },
  {
    header: 'Actions',
    cell: ({ row }) => {
      return <ActionsCell row={row} />;
    },
  },
];

interface IEmailTemplatesTable<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

const EmailTemplatesTable = <TData, TValue>({ columns, data }: IEmailTemplatesTable<TData, TValue>) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });
  return (
    <div>
      <Table>
        <TableHeader className=" bg-gray-100 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead className="px-6" key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell className="px-6" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const EmailTemplates = () => {
  const { companiesOfUser, activeCompanyIndex } = useUserContext();
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const { isLoading, data: emailTemplateResponse } = useGetEmailTemplatesByCompanyId({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id,
    page,
    pageSize: pageLimit,
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  if (isLoading) {
    return <TableSkeleton rows={10} columns={5} />;
  }

  if (!emailTemplateResponse?.data) {
    return <Empty title="No plans found" />;
  }

  return (
    <div>
      <EmailTemplatesTable columns={columns} data={emailTemplateResponse.data.docs} />
      <Pagination
        hasNext={!!emailTemplateResponse?.data.hasNext}
        hasPrev={!!emailTemplateResponse?.data.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setPageLimit}
        pageNumber={page}
        rowsPerPage={pageLimit}
        totalPages={emailTemplateResponse?.data.totalPages || 0}
      />
    </div>
  );
};

const EmailTemplatesListView = () => {
  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8">
      <Heading
        title="Email Templates"
        description="Manage your email templates"
        cta={
          <Link to="/email-builder">
            <Button className=" flex items-center gap-2 ">
              <PlusIcon className="h-4 w-4" />
              Create
            </Button>
          </Link>
        }
      />
      <EmailTemplates />
    </div>
  );
};

export default EmailTemplatesListView;
