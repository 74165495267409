import { IExcelExportFormat } from '@/types/cashflow.types';
import { ICredit } from '@/types/posting.types';
import { useExportExcel } from '../utils/useExportExcel';

const flattenArrayToObject = (arr: ICredit[]): IExcelExportFormat[] => {
  return arr.map((item) =>
    Object.entries(item).reduce((acc, [key, value]) => {
      const formatted = key.replace(/([A-Z])/g, ' $1');

      if (key === 'bankAccountId') {
        return { ...acc };
      }

      return { ...acc, [formatted.charAt(0).toUpperCase() + formatted.slice(1)]: value };
    }, {}),
  );
};

export const useCreditsExport = ({ onSuccess, onError }: { onSuccess?: (_: string) => void; onError?: () => void }) => {
  const { action, isLoading } = useExportExcel({
    onSuccess,
    onError,
  });

  return {
    exportExcel: (data: ICredit[]) => action(flattenArrayToObject(data)),
    isLoading,
  };
};
