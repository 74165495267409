import { IBankTransactionsEditOptions } from '@/types/bank-transactions.types';
import { EditIcon, XIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { CategoryCombobox } from '../ui/category-combobox';
import { Skeleton } from '../ui/skeleton';

const CategoryComponent = ({
  categoriesMap,
  categoryId,
  transactionId,
  setTransactionsToEdit,
  defaultOpen = false,
  isRefetching = false,
}: {
  isRefetching?: boolean;
  categoriesMap?: Record<string, string>;
  categoryId: string;
  setTransactionsToEdit: React.Dispatch<React.SetStateAction<IBankTransactionsEditOptions[]>>;
  transactionId: string;
  defaultOpen?: boolean;
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(categoryId);

  useEffect(() => {
    setSelectedCategory(categoryId);
  }, [categoryId, isRefetching]);

  const handleCategoryChange = useCallback(
    (value: string) => {
      setSelectedCategory(value);
      setTransactionsToEdit((prev) => {
        const findIfAlreadyExists = prev.find((item) => item.bankAccountTransactionId === transactionId);
        if (findIfAlreadyExists) {
          return prev.map((item) => {
            if (item.bankAccountTransactionId === transactionId) {
              return {
                ...item,
                categoryId: value,
              };
            }
            return item;
          });
        }
        return [
          ...prev,
          {
            categoryId: value,
            bankAccountTransactionId: transactionId,
          },
        ];
      });
      setIsEdit(false);
    },
    [transactionId, setSelectedCategory, setTransactionsToEdit],
  );

  const handleEditClick = () => {
    setIsEdit((prev) => !prev);
  };

  if (isRefetching) {
    return <Skeleton className="h-4 w-36" />;
  }

  return (
    <span className="flex items-center gap-2">
      <span className=" min-w-fit">
        {isEdit ? (
          <XIcon className="w-4 h-4 cursor-pointer" onClick={handleEditClick} />
        ) : (
          <EditIcon className="w-3 h-3 cursor-pointer" onClick={handleEditClick} />
        )}
      </span>
      {isEdit ? (
        <CategoryCombobox
          hideSummary
          onlyLeaf
          handleEditOpen={setIsEdit}
          handleSelectChange={handleCategoryChange}
          selectedCategory={selectedCategory}
          defaultOpen={defaultOpen}
        />
      ) : categoriesMap ? (
        categoriesMap[selectedCategory]
      ) : (
        selectedCategory
      )}
    </span>
  );
};

export default CategoryComponent;
