import { usePlanContext } from '@/contexts/PlanContext';
import { useUserContext } from '@/contexts/UserContext';
import {
  useGetCashflowSummaryDetailsByPlan,
  useGetCashflowSummaryDetailsForCategory,
} from '@/hooks/api-hooks/useCashflowQuery';
import { cn } from '@/lib/utils';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card';
import Empty from '../../ui/empty';
import { Skeleton } from '../../ui/skeleton';
import BalanceSummary from './BalanceSummary';
import SummaryTable from './SummaryTable';

const ActualVsPlanned = ({
  dateFilter,
  selectedCategory,
  isCategory,
}: {
  dateFilter: {
    startDate: Date;
    endDate: Date;
  };
  selectedCategory: string;
  isCategory: boolean;
}) => {
  const { planId } = usePlanContext();

  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { data: summaryDetailsData, isLoading: isSummaryLoading } = useGetCashflowSummaryDetailsByPlan({
    planId: planId as string,
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
    customConfig: {
      enabled: !!dateFilter.startDate && !!dateFilter.endDate && !!companiesOfUser?.[activeCompanyIndex]?.id,
      refetchOnWindowFocus: false,
    },
  });

  const { data: summaryDetailsDataForCategory, isLoading: isLoadingForCategory } =
    useGetCashflowSummaryDetailsForCategory({
      planId: planId as string,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
      categoryId: selectedCategory,
      companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
      customConfig: {
        enabled:
          isCategory &&
          !!dateFilter.startDate &&
          !!dateFilter.endDate &&
          !!selectedCategory &&
          !!companiesOfUser?.[activeCompanyIndex]?.id,
        refetchOnWindowFocus: false,
      },
    });

  const startDate = dateFilter.startDate;
  const endDate = dateFilter.endDate;

  const isLoading = useMemo(() => {
    return isSummaryLoading || isLoadingForCategory;
  }, [isSummaryLoading, isLoadingForCategory]);

  const details = useMemo(() => {
    return isCategory ? summaryDetailsDataForCategory : summaryDetailsData;
  }, [isCategory, summaryDetailsData, summaryDetailsDataForCategory]);

  const tableData = useMemo(
    () =>
      details?.data.map((data) => {
        return {
          label: data.label,
          planned: data.plannedValue,
          actual: data.actualValue,
          avgForecast: data.averages.requiredAverage,
          avgActual: data.averages.currentAverage,
          isDebit: data.isDebit,
          diff: data.actualValue - data.plannedValue,
          startDate: dayjs(data.dateRange.startDate).toDate(),
          endDate: dayjs(data.dateRange.endDate).toDate(),
        };
      }) || [],

    [details],
  );

  if (isLoading) {
    return new Array(2).fill(0).map((_, index) => (
      <div key={index + 'actual_vs_planned_skeletons'} className="flex flex-col relative text-sm gap-6">
        <div className=" grid grid-cols-2 ">
          <div className="flex gap-2 w-full items-center">
            <div className=" min-w-[80px] text-ellipsis truncate ">
              <Skeleton className=" w-full h-5 " />
            </div>
            <div className="flex-1 flex flex-col gap-1">
              <div className="flex justify-between">
                <span></span>
                <span>
                  <Skeleton className=" w-10 h-4 " />
                </span>
                <span>
                  <Skeleton className=" w-10 h-4 " />
                </span>
              </div>
              <div>
                <Skeleton className=" w-full h-5 " />
              </div>
              <div className="flex justify-between">
                <span></span>
                <span>
                  <Skeleton className=" w-10 h-4 " />
                </span>
                <span>
                  <Skeleton className=" w-10 h-4 " />
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 justify-center text-sm px-4">
            <div className="flex justify-between gap-2 items-center">
              <Skeleton className=" w-full h-5 " />
              <span>
                <Skeleton className=" w-20 h-5 " />
              </span>
            </div>
            <div className="flex justify-between gap-2 items-center">
              <Skeleton className=" w-full h-5 " />
              <span>
                <Skeleton className=" w-20 h-5 " />
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
  }

  if (!details?.data?.length || !tableData?.length) {
    return <Empty title="No data available" />;
  }

  return (
    <div className="flex flex-col relative text-sm gap-6">
      <div className="grid grid-cols-10 w-full">
        <div className={cn(' col-span-8 flex flex-col justify-around text-sm', isCategory && 'col-span-10')}>
          <SummaryTable data={tableData} planEndDate={endDate} planStartDate={startDate} />
        </div>
        {!isCategory && (
          <BalanceSummary
            dateFilter={{
              endDate: endDate,
              startDate: startDate,
            }}
          />
        )}
      </div>
    </div>
  );
};

const SummaryData = ({
  dateFilter,
  selectedCategory,
  isCategory,
}: {
  dateFilter: {
    startDate: Date;
    endDate: Date;
  };
  selectedCategory: string;
  isCategory: boolean;
}) => {
  const { planId } = usePlanContext();

  return (
    <Card>
      <CardHeader className="border-b">
        <CardTitle className="flex gap-2 justify-between">
          {dayjs(dateFilter.startDate).format('DD MMM YY')} - {dayjs(dateFilter.endDate).format('DD MMM YY')}
          <div className="flex justify-end gap-4">
            {!!planId && (
              <Link
                className=" text-blue-700 text-sm font-normal underline "
                to={`/plan/view/${planId}?month=${dayjs(dateFilter.startDate).format('MMM-YY')}`}
              >
                <a href={`/plan/view/${planId}?month=${dayjs(dateFilter.startDate).format('MMM-YY')}`}>View Plan</a>
              </Link>
            )}
            <Link
              className=" text-blue-700 text-sm font-normal underline "
              to={`/cashflow/details?startDate=${dayjs(dateFilter.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(
                dateFilter.endDate,
              ).format('YYYY-MM-DD')}&category=${selectedCategory}`}
            >
              <a
                href={`/cashflow/details?startDate=${dayjs(dateFilter.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(
                  dateFilter.endDate,
                ).format('YYYY-MM-DD')}&category=${selectedCategory}`}
              >
                View Details
              </a>
            </Link>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="py-4 gap-4">
        <ActualVsPlanned selectedCategory={selectedCategory} isCategory={isCategory} dateFilter={dateFilter} />
      </CardContent>
    </Card>
  );
};

export default SummaryData;
