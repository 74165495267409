import { Label } from '@/components/ui/label';

const FilterRow = ({
  itemOneLabel,
  itemOneFilter,
  itemTwoLabel,
  itemTwoFilter,
}: {
  itemOneLabel: string;
  itemOneFilter: React.ReactNode;
  itemTwoLabel?: string;
  itemTwoFilter?: React.ReactNode;
}) => {
  return (
    <div className=" w-full flex gap-4  px-4 justify-between">
      <div className="min-w-[240px]  flex-1">
        <Label className="text-right">{itemOneLabel}</Label>
        <div>{itemOneFilter}</div>
      </div>
      {!!itemTwoLabel && !!itemTwoFilter && (
        <div className=" min-w-[240px] flex-1 ">
          <Label className="text-right">{itemTwoLabel}</Label>
          <div>{itemTwoFilter}</div>
        </div>
      )}
    </div>
  );
};

export default FilterRow;
