import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function ifDataTypeNotUndefined(inputData: unknown) {
  return typeof inputData != 'undefined';
}

export function sanitizeAttachmentsName(filename: string) {
  if (filename.indexOf('?') !== -1) {
    return filename.split('?')[0];
  } else {
    return filename;
  }
}
export function historyExists() {
  return window.history.length > 1;
}
