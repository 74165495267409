import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import { IPostedToInvoiceObject } from '@/types/posting.types';
import { CardContent } from '@mui/material';
import { PostedToInvoiceItem } from '../posting/ledger/invoices/posted-items';

const Postings = ({
  postedToInvoice,
  invoiceNumber,
}: {
  postedToInvoice: IPostedToInvoiceObject[];
  invoiceNumber: string;
}) => {
  return (
    <Card className=" my-8">
      <CardHeader className="border-b">
        <CardTitle className=" underline font-semibold ">Postings ({postedToInvoice.length})</CardTitle>
      </CardHeader>
      <CardContent>
        {postedToInvoice.map((postedToInvoiceObject) => (
          <div key={postedToInvoiceObject.postingId} className=" px-8 py-4 ">
            <PostedToInvoiceItem
              invoiceNumber={invoiceNumber}
              showDelete
              showNarration
              postedToInvoiceObject={postedToInvoiceObject}
            />
          </div>
        ))}
        {postedToInvoice.length === 0 && (
          <p className=" py-4 text-muted-foreground text-sm text-center ">No postings</p>
        )}
      </CardContent>
    </Card>
  );
};

export default Postings;
