import BankTransactionsFilters from '@/components/bank-transactions/BankTransactionsFilters';
import BankTransactionsTable from '@/components/bank-transactions/BankTransactionsTable';
import { IBankTransactionsFilters } from '@/types/bank-transactions.types';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const BankTransactionsPage = () => {
  const [filters, setFilters] = useState<IBankTransactionsFilters>({
    bankAccounts: [],
    categories: [],
    dateRange: {
      startDate: undefined,
      endDate: undefined,
    },
    pageNumber: 1,
    narration: '',
  });

  const [searchParams] = useSearchParams();

  const handleSubmit = (value: IBankTransactionsFilters) => {
    setFilters({ ...value, pageNumber: 1 });
  };

  useEffect(() => {
    const bankAccounts = searchParams.get('bankAccounts');
    const categories = searchParams.get('categories');
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const narration = searchParams.get('narration');
    setFilters({
      bankAccounts: bankAccounts ? bankAccounts.split(',') : [],
      categories: categories ? categories.split(',') : [],
      dateRange: {
        startDate: startDate ? new Date(startDate) : undefined,
        endDate: endDate ? new Date(endDate) : undefined,
      },
      narration: narration || '',
    });
  }, [searchParams, setFilters]);

  return (
    <div className="md:px-16 sm:px-8 px-4 flex-1 my-8">
      <BankTransactionsFilters handleSubmit={handleSubmit} />
      <BankTransactionsTable filters={filters} />
    </div>
  );
};

export default BankTransactionsPage;
