export const getCurrentFy = () => {
  const date = new Date();

  if (date.getMonth() < 4) {
    return date.getFullYear();
  } else {
    return date.getFullYear() + 1;
  }
};

export const getStartAndEndDateOfFy = () => {
  const currentFy = getCurrentFy();

  return {
    startDate: new Date(`${currentFy - 1}-04-01`),
    endDate: new Date(`${currentFy}-03-31`),
  };
};
