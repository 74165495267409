export const templateToJsonVariableMap: Record<
  'STRING' | 'NUMBER' | 'CURRENCY' | 'DATE' | 'OBJECT' | 'ARRAY',
  'STRING' | 'NUMBER' | 'DATE' | 'OBJECT' | 'ARRAY'
> = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  CURRENCY: 'NUMBER',
  DATE: 'DATE',
  OBJECT: 'OBJECT',
  ARRAY: 'ARRAY',
};
