import { useGetActivityById } from '@/hooks/api-hooks/useActivitiesQuery';
import { IEmailActivity } from '@/types/activity.types';

import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { ArrowLeftIcon, ChevronDownIcon } from 'lucide-react';
import { useMemo } from 'react';

import { sanitizeAttachmentsName } from '@/lib/utils';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';
import { Card, CardContent, CardTitle } from '../ui/card';
import { Skeleton } from '../ui/skeleton';

import { ActivityCardIcon } from './ActivityIcon';
import ShowAttachmentsModal from './AttachmentModal';
import { ActivityDetailsCardAdditionalContent } from './events/EventDetails';

const Recipients = ({ recipients }: { recipients: IEmailActivity['email']['recipients'] }) => {
  const cc = recipients.cc.filter((email) => email !== 'updates@valyx.com');
  const bcc = recipients.bcc.filter((email) => email !== 'updates@valyx.com');

  const shouldShowHoverCard = useMemo(() => {
    return cc.length > 0 || bcc.length > 0;
  }, [cc, bcc]);

  if (shouldShowHoverCard) {
    return (
      <HoverCard>
        <HoverCardTrigger asChild>
          <div className=" flex items-center gap-2 cursor-pointer text-sm text-muted-foreground ">
            To: {recipients.to.join(', ')}{' '}
            <ChevronDownIcon className=" relative top-0.5 " size={12} strokeWidth={3} absoluteStrokeWidth />
          </div>
        </HoverCardTrigger>
        <HoverCardContent align="start">
          <div className=" text-sm  ">
            <div>To: {recipients.to.join(', ')}</div>
            {cc.length > 0 && <div>Cc: {cc.join(', ')}</div>}
            {bcc.length > 0 && <div>Bcc: {bcc.join(', ')}</div>}
          </div>
        </HoverCardContent>
      </HoverCard>
    );
  }

  return <div className="text-sm text-muted-foreground ">To: {recipients.to.join(', ')} </div>;
};

const ActivityCardTitle = ({ activity }: { activity: IEmailActivity }) => {
  if (activity.email.emailRefId) {
    return (
      <div>
        <CardTitle className=" flex items-center gap-1 leading-5 ">
          <Link className=" text-blue-700 underline" to={`/customer/view/${activity.customer.customerId}`}>
            {activity.customer.customerName}
          </Link>
          <span className=" text-muted-foreground font-normal">({activity.email.fromEmail}) replied to </span>
          {activity.email.subject.replaceAll('<p>', ' ').replaceAll('</p>', ' ')}
        </CardTitle>
        <Recipients recipients={activity.email.recipients} />
      </div>
    );
  }

  return (
    <div className=" flex flex-col gap-2 ">
      <CardTitle className=" leading-5 ">
        <span className=" capitalize ">{activity.email.emailType.toLowerCase()}</span>{' '}
        <span>({activity.workflowName ? `${activity.workflowName}` : ''})</span>{' '}
        <span className=" text-muted-foreground font-normal ">sent</span>{' '}
        {activity.email.subject.replaceAll('<p>', '').replaceAll('</p>', '')}{' '}
        <span className=" text-muted-foreground font-normal "> to</span>{' '}
        <Link to={`/customer/view/${activity.customer.customerId}`} className=" text-blue-700 underline ">
          {activity.customer.customerName}
        </Link>
      </CardTitle>
      <div className=" text-sm text-muted-foreground ">From: {activity.email.fromEmail}</div>
      <Recipients recipients={activity.email.recipients} />
    </div>
  );
};

const ActivityCardContent = ({ activity }: { activity: IEmailActivity }) => {
  return (
    <>
      <CardContent className="px-0 w-full">
        <div className=" text-sm overflow-x-scroll " dangerouslySetInnerHTML={{ __html: activity.email.htmlContent }} />
      </CardContent>
      <div>
        {activity.email.attachments && activity.email.attachments.length > 5 ? (
          <ShowAttachmentsModal attachments={activity.email.attachments || []} />
        ) : (
          <div className=" flex flex-col gap-2 ">
            {activity.email.attachments &&
              activity.email.attachments.map((item) => (
                <div key={item.attachmentId} className=" text-blue-700 mb-2 text-sm">
                  <Link target="_blank" to={`https://data.valyx.com/attachments/${item.attachmentId}/file`}>
                    {sanitizeAttachmentsName(item.attachmentName)}
                  </Link>
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
};

const ActivityCardLoadingSkeleton = () => {
  return (
    <Card className=" flex items-start p-4 gap-4 ">
      <ActivityCardIcon />
      <div className=" flex-1 flex flex-col gap-4 ">
        <CardTitle className=" flex flex-col gap-1 ">
          <Skeleton className=" w-full h-6 " />
          <Skeleton className=" w-3/5 h-6 " />
          <Skeleton className=" w-2/5 h-4 " />
        </CardTitle>
        <div className=" flex flex-col gap-2  ">
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-full h-4 " />
          <Skeleton className=" w-1/3 h-4 " />
        </div>
      </div>
      <div className=" px-4 flex flex-col gap-2 min-w-52 ">
        <Skeleton className=" w-full h-3 " />
        <Skeleton className=" w-full h-3 " />
        <Skeleton className=" w-full h-3 " />
      </div>
    </Card>
  );
};

const ActivityDetail = ({ activityId, isMainContent = false }: { activityId: string; isMainContent?: boolean }) => {
  const { data: activityByIdResponse, isLoading } = useGetActivityById({
    activityId,
  });

  const navigate = useNavigate();

  if (isLoading) {
    return <ActivityCardLoadingSkeleton />;
  }

  if (!activityByIdResponse?.data) {
    if (isMainContent) {
      return <Navigate to="/404" replace />;
    }
    return null;
  }

  return (
    <>
      <div>
        <Button className="text-sm text-blue-700 flex gap-2" variant="ghost" onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="w-4 h-4" />
          Activities
        </Button>
      </div>
      <Card className=" flex items-start p-4 gap-4 h-full overflow-hidden ">
        <ActivityCardIcon
          hasAttachments={!!activityByIdResponse.data.email.attachments?.length}
          isReply={!!activityByIdResponse.data.email.emailRefId}
        />
        <div className=" flex-1 flex flex-col gap-4 overflow-hidden ">
          <ActivityCardTitle activity={activityByIdResponse.data} />
          <ActivityCardContent activity={activityByIdResponse.data} />
        </div>
        <div className="overflow-y-scroll h-96 ">
          <ActivityDetailsCardAdditionalContent
            events={activityByIdResponse.data.serviceProviderEvents || []}
            activityTimeStamp={activityByIdResponse.data.timestamp}
          />
        </div>
      </Card>
    </>
  );
};

export default ActivityDetail;
