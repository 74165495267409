import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Skeleton } from '../ui/skeleton';

const TableSkeleton = ({ rows, columns }: { rows: number; columns: number }) => {
  return (
    <div className="py-2">
      <Table>
        <TableHeader className=" bg-gray-100">
          {new Array(1).fill(0).map((_, i) => (
            <TableRow key={i}>
              {new Array(columns).fill(0).map((_, i) => (
                <TableHead className="px-6 min-w-[100px]" key={i}>
                  <Skeleton className="w-full h-4" />
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="py-4">
          {new Array(rows).fill(0).map((_, i) => (
            <TableRow key={i}>
              {new Array(columns).fill(0).map((_, i) => (
                <TableCell className="px-6 text-center" key={i}>
                  <Skeleton className="w-full h-4" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableSkeleton;
