import { dataAxiosInstance } from '@/lib/axios';
import {
  IBankAccountTransactionDateRangeResponse,
  IBankTransactionsEditOptions,
  IBankTransactionsResponse,
  ICustomerSuggestionUpdateRequest,
} from '@/types/bank-transactions.types';
import { IGetCreditById, PostingStatus } from '@/types/posting.types';
import dayjs from 'dayjs';

export const getFilteredBankTransactions = async ({
  page,
  limit,
  bankAccounts,
  categories,
  startDate,
  endDate,
  narration,
}: {
  page: number;
  limit: number;
  companyId: string;
  bankAccounts: string[];
  categories: string[];
  startDate?: Date;
  endDate?: Date;
  narration?: string;
}) => {
  let url = `/bankAccountTransaction/filter?page=${page}&pageSize=${limit}`;

  if (startDate && endDate) {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

    url += `&periodStartDate=${formattedStartDate}&periodEndDate=${formattedEndDate}`;
  }

  if (bankAccounts.length > 0) {
    url += `&bankAccountIds=${bankAccounts.join('&bankAccountIds=')}`;
  }

  if (categories.length > 0) {
    url += `&categories=${categories.join('&categories=')}`;
  }

  if (narration) url += `&narration=${narration}`;

  const result = await dataAxiosInstance.get<IBankTransactionsResponse>(url);

  return result.data;
};

export const updateCategoryForBankTransaction = async ({
  transactionsToEdit,
}: {
  transactionsToEdit: IBankTransactionsEditOptions[];
}) => {
  const result = await dataAxiosInstance.post(`/bankAccountTransaction/category`, {
    data: transactionsToEdit,
  });

  return result.data;
};

export const getDateRangeForBankTransactions = async () => {
  const result = await dataAxiosInstance.get<IBankAccountTransactionDateRangeResponse>(
    '/bankAccountTransaction/dateRange',
  );

  return result.data;
};

export const getCreditsForPosting = async ({
  page = 1,
  limit = 10,
  bankAccounts,
  placeholderId,
  isPostedToInvoice,
  startDate,
  endDate,
  postingStatus,
  categories,
  narration,
  notInInvoice,
  maxAmount,
  minAmount,
  customerIds,
}: {
  page: number;
  limit: number;
  bankAccounts: string[];
  startDate?: Date;
  endDate?: Date;
  placeholderId?: string;
  isPostedToInvoice?: boolean;
  postingStatus?: PostingStatus[];
  categories: string[];
  narration?: string;
  notInInvoice?: string[];
  maxAmount?: number;
  minAmount?: number;
  customerIds?: string;
}) => {
  let url = `/posting/credits?page=${page}&pageSize=${limit}`;

  if (bankAccounts.length > 0) {
    url += `&bankAccountIds=${bankAccounts.join('&bankAccountIds=')}`;
  }

  if (placeholderId) {
    url += `&placeholderId=${placeholderId}`;
  }

  if (isPostedToInvoice !== undefined) {
    url += `&isPostedToInvoice=${isPostedToInvoice}`;
  }

  if (startDate && endDate) {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

    url += `&periodStartDate=${formattedStartDate}&periodEndDate=${formattedEndDate}`;
  }

  if (postingStatus?.length) {
    url += postingStatus.map((status: string) => `&postingStatus=${status}`).join('');
  }

  if (categories.length) {
    url += `&categories=${categories.join('&categories=')}`;
  }

  if (narration?.trim()) {
    url += `&narrationLike=${narration.trim()}`;
  }

  if (notInInvoice?.length) {
    url += notInInvoice.map((status: string) => `&notInInvoice=${status}`).join('');
  }

  if (minAmount !== undefined && maxAmount !== undefined) {
    url += `&amountMax=${maxAmount}&amountMin=${minAmount}`;
  }
  if (customerIds !== undefined && customerIds !== '') {
    url += customerIds
      .split(',')
      .map((customerId: string) => `&customerId=${customerId}`)
      .join('');
  }

  const result = await dataAxiosInstance.get(url);

  return result.data;
};

export const getBankTransactionById = async (transactionId: string): Promise<IGetCreditById> => {
  const result = await dataAxiosInstance.get(`/posting/credit?id=${transactionId}`);
  return result.data;
};

export const updateCustomerSuggestion = async (customerSuggestionUpdateRequest: ICustomerSuggestionUpdateRequest) => {
  const result = await dataAxiosInstance.post(
    `/bankAccountTransaction/customerNameSuggestion/update/${customerSuggestionUpdateRequest.bankTransactionId}`,
    {
      ...customerSuggestionUpdateRequest,
    },
  );

  return result.data;
};
