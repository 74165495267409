import { PaperclipIcon } from 'lucide-react';
import ToolTipCell from '../Table/ToolTipCell';
import { EmailReceivedIcon } from '../ui/icons/email-received.icon';
import { EmailSentIcon } from '../ui/icons/email-sent.icon';

export const ActivityCardIcon = ({
  isReply = false,
  hasAttachments = false,
}: {
  isReply?: boolean;
  hasAttachments?: boolean;
}) => {
  return (
    <div className=" min-w-7 px-1 flex flex-col gap-2 ">
      {isReply ? <EmailReceivedIcon /> : <EmailSentIcon />}
      {hasAttachments && (
        <ToolTipCell value={<PaperclipIcon className="w-4 h-4" />}>This email has attachments</ToolTipCell>
      )}
    </div>
  );
};
