import { Tabs, TabsList, TabsTrigger } from './tabs';

export const TableUnitsTabs = ({ onValueChange, value }: { onValueChange: (_: string) => void; value: string }) => {
  return (
    <Tabs value={value} onValueChange={onValueChange}>
      <TabsList>
        <TabsTrigger value="actual">Actual</TabsTrigger>
        <TabsTrigger value="k">K (000s)</TabsTrigger>
        <TabsTrigger value="l">L</TabsTrigger>
        <TabsTrigger value="cr">Cr</TabsTrigger>
      </TabsList>
    </Tabs>
  );
};
