import { useUserContext } from '@/contexts/UserContext';
import { useGetBankAccountsForCompany } from '@/hooks/api-hooks/useBankAccountQuery';
import {
  useGetFilteredBankTransactions,
  usePostUpdateBankAccountTransactionCategory,
} from '@/hooks/api-hooks/useBankTransactionsQuery';
import { useGetCategoriesByCompanyQuery } from '@/hooks/api-hooks/useCategoryQuery';
import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { cn } from '@/lib/utils';
import {
  IBankTransaction,
  IBankTransactionsEditOptions,
  IBankTransactionsFilters,
} from '@/types/bank-transactions.types';
import {
  BANKTRANSACTIONS_TABLE_COLUMNS_VISIBILITY_KEY,
  BANKTRANSACTIONS_TABLE_COLUMN_ORDER_KEY,
} from '@/utils/constants/local-storage-keys';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { ColumnDef, VisibilityState, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeft,
  ChevronsRight,
  GripVerticalIcon,
  Loader2Icon,
} from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { CustomTableBody, MemoizedTableBody } from '../Table/MemoizedTableBody';
import { ColumnSelector } from '../shared/ReactTableColumnSelector';
import { Button } from '../ui/button';
import Empty from '../ui/empty';
import SelectComponent from '../ui/select-component';
import { Skeleton } from '../ui/skeleton';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import { TableUnitsTabs } from '../ui/table-unit-tabs';
import { useToast } from '../ui/use-toast';
import CategoryComponent from './CategoryEditComponent';

const BankTransactionsTable = ({ filters }: { filters: IBankTransactionsFilters }) => {
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(1);

  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { toast } = useToast();
  const [unit, setUnit] = useState<'k' | 'l' | 'cr' | 'actual'>('actual');
  const [transactionsToEdit, setTransactionsToEdit] = useState<IBankTransactionsEditOptions[]>([]);

  const {
    data: transactionsResponse,
    isLoading,
    refetch,
    isRefetching,
  } = useGetFilteredBankTransactions({
    page: page,
    limit: rowsPerPage,
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    bankAccounts: filters.bankAccounts,
    categories: filters.categories,
    startDate: filters?.dateRange?.startDate,
    endDate: filters?.dateRange?.endDate,
    narration: filters?.narration,
    customConfig: {
      enabled:
        !!companiesOfUser?.[activeCompanyIndex]?.id &&
        !!filters.bankAccounts &&
        !!filters.categories &&
        !!filters.dateRange,
    },
  });

  useEffect(() => {
    setPage(1);
  }, [filters?.pageNumber]);

  useEffect(() => {
    handleFirstPage();
  }, [filters]);

  const { mutate: updateCategories, isPending } = usePostUpdateBankAccountTransactionCategory({
    customConfig: {
      onSuccess: () => {
        setTransactionsToEdit([]);
        toast({
          title: 'Success',
          description: 'Categories updated successfully',
        });
        refetch();
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'Failed to update categories',
        });
      },
    },
  });

  const { data: banks, isLoading: isLoadingBanks } = useGetBankAccountsForCompany({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const { data: categories, isLoading: isLoadingCategories } = useGetCategoriesByCompanyQuery({
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const banksMap = useMemo(() => {
    return banks?.data.bankAccounts.reduce(
      (acc, bank) => {
        acc[bank.id] = bank.name;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [banks]);

  const categoriesMap = useMemo(() => {
    return categories?.data.reduce(
      (acc, category) => {
        acc[category.id] = category.value;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [categories]);

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    setPage((prev) => prev - 1);
  };

  const handleFirstPage = () => {
    setPage(1);
  };

  const handleLastPage = () => {
    setPage(transactionsResponse?.data.totalPages || 1);
  };

  const handleRowsPerPageChange = (value: string) => {
    setRowsPerPage(Number(value));
  };

  const handleUnitChange = (value: string) => {
    const newUnit = value as 'k' | 'l' | 'cr' | 'actual';
    setUnit(newUnit);
  };

  const columns: ColumnDef<IBankTransaction>[] = useMemo(
    () => [
      {
        id: 'date',
        header: 'Date',
        accessorKey: 'transactionDate',
      },
      {
        id: 'bank',
        header: 'Bank',
        accessorKey: 'bankAccountId',
        cell: ({ getValue }) => (banksMap ? banksMap[getValue() as string] : getValue()),
      },
      {
        id: 'category',
        header: 'Category',
        accessorKey: 'category',
        cell: ({ getValue, row }) => (
          <CategoryComponent
            isRefetching={isRefetching}
            transactionId={row.original.transactionId}
            setTransactionsToEdit={setTransactionsToEdit}
            categoriesMap={categoriesMap}
            categoryId={getValue() as string}
            defaultOpen
          />
        ),
      },
      {
        id: 'narration',
        header: 'Narration',
        accessorKey: 'narration',
      },
      {
        id: 'amount',
        header: 'Amount',
        accessorKey: 'amount',
        cell: ({ getValue, row }) => (
          <span className={cn('text-right', row.original.isCredit ? 'text-green-700' : 'text-red-500')}>
            {formatCurrencyByUnit(getValue() as number, unit)}
          </span>
        ),
      },
      {
        id: 'closingBalance',
        header: 'Closing Balance',
        accessorKey: 'closingBalance',
        cell: ({ getValue }) => (
          <span className={cn('text-right', (getValue() as number) < 0 ? 'text-red-500' : 'text-green-700')}>
            {formatCurrencyByUnit(getValue() as number, unit)}
          </span>
        ),
      },
    ],
    [banksMap, categoriesMap, unit, setTransactionsToEdit, isRefetching],
  );

  const tableData = useMemo(() => {
    return transactionsResponse?.data?.docs || [];
  }, [transactionsResponse]);

  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);
  const defaultColumnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const { storedValue: columnVisibility, setValue: setColumnVisibility } = useLocalStorage(
    BANKTRANSACTIONS_TABLE_COLUMNS_VISIBILITY_KEY,
    defaultColumnVisibility,
  );

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    BANKTRANSACTIONS_TABLE_COLUMN_ORDER_KEY,
    defaultColumnOrder,
  );

  const table = useReactTable({
    data: tableData,
    columns,
    columnResizeMode: 'onChange',
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
      columnOrder,
    },
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 50,
    },
  });

  const sizes = table.getState().columnSizingInfo;

  const tableColumns = useMemo(() => table.getAllColumns(), [table]);

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    return headers.reduce(
      (acc, current) => {
        return {
          ...acc,
          [`--col-${current.column.id}-size`]: current.column.getSize(),
          [`--header-${current.id}-size`]: current.getSize(),
        };
      },
      {} as {
        [key: string]: number;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);

  if (isLoading || isLoadingBanks || isLoadingCategories) {
    return (
      <div className="py-2">
        <div className="flex items-center justify-end my-4">
          <TableUnitsTabs value="actual" onValueChange={() => {}} />
        </div>
        <Table>
          <TableHeader className=" bg-gray-100">
            {new Array(1).fill(0).map((_, i) => (
              <TableRow key={i}>
                {new Array(6).fill(0).map((_, i) => (
                  <TableHead className="px-6 min-w-[100px]" key={i}>
                    <Skeleton className="w-full h-4" />
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="py-4">
            {new Array(10).fill(0).map((_, i) => (
              <TableRow key={i}>
                {new Array(6).fill(0).map((_, i) => (
                  <TableCell className="px-6 text-center" key={i}>
                    <Skeleton className="w-full h-4" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  if (!transactionsResponse) {
    return <Empty title="No data available" />;
  }

  const handleEditCategories = () => {
    updateCategories({ transactionsToEdit });
  };

  const handleReset = () => {
    refetch();
    setTransactionsToEdit([]);
  };

  return (
    <div>
      <div className="flex items-center justify-end my-4 gap-4">
        {transactionsToEdit.length > 0 && (
          <Button className="flex items-center gap-2" disabled={isPending} onClick={handleEditCategories}>
            Save
            {isPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
          </Button>
        )}
        {transactionsToEdit.length > 0 && (
          <Button className="flex items-center gap-2" variant="secondary" disabled={isRefetching} onClick={handleReset}>
            Reset
            {isRefetching && <Loader2Icon className="w-4 h-4 animate-spin" />}
          </Button>
        )}
        <ColumnSelector columns={tableColumns} setColumnOrder={setColumnOrder} columnOrder={columnOrder} />
        <TableUnitsTabs value={unit} onValueChange={handleUnitChange} />
      </div>
      <div className=" overflow-auto ">
        <Table
          style={{
            ...columnSizeVars,
            width: table.getTotalSize(),
          }}
          className="flex-1"
        >
          {transactionsResponse?.data?.docs?.length === 0 && (
            <TableCaption className="pb-10">No data found</TableCaption>
          )}
          <TableHeader className=" bg-gray-100 ">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className="flex items-center" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      style={{
                        width: `calc(var(--header-${header?.id}-size) * 1px)`,
                      }}
                      className="flex items-center justify-between"
                      key={header.id}
                    >
                      <div className="break-keep truncate text-ellipsis">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                      <GripVerticalIcon
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: cn(
                            'w-4 h-4 cursor-ew-resize min-w-4',
                            header.column.getIsResizing() ? 'opacity-100' : 'opacity-50',
                          ),
                        }}
                      />
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          {table.getState().columnSizingInfo.isResizingColumn ? (
            <MemoizedTableBody table={table} />
          ) : (
            <CustomTableBody table={table} />
          )}
        </Table>
      </div>
      {transactionsResponse.data.totalPages > 0 && (
        <div className="flex my-4 justify-between text-sm px-4">
          <div className="flex items-center gap-2">
            <Button
              disabled={!transactionsResponse.data.hasPrev}
              onClick={handleFirstPage}
              variant="outline"
              size="icon"
            >
              <ChevronsLeft className="w-4 h-4" />
            </Button>
            <Button
              disabled={!transactionsResponse.data.hasPrev}
              onClick={handlePreviousPage}
              variant="outline"
              size="icon"
            >
              <ChevronLeftIcon className="w-4 h-4" />
            </Button>
          </div>
          <span className="flex items-center gap-8">
            <span>
              Page{' '}
              <strong>
                {transactionsResponse.data.pageNumber} of {transactionsResponse.data.totalPages}
              </strong>{' '}
            </span>
            <span className="flex items-center gap-2">
              Rows per page{' '}
              <SelectComponent
                className="w-[80px] p-1"
                onChange={handleRowsPerPageChange}
                value={String(rowsPerPage)}
                options={[
                  {
                    label: '10',
                    value: '10',
                  },
                  {
                    label: '20',
                    value: '20',
                  },
                  {
                    label: '50',
                    value: '50',
                  },
                  {
                    label: '100',
                    value: '100',
                  },
                ]}
              />
            </span>
          </span>
          <div className="flex items-center gap-2">
            <Button
              disabled={!transactionsResponse.data.hasNext}
              onClick={handleNextPage}
              variant="outline"
              size="icon"
            >
              <ChevronRightIcon className="w-4 h-4" />
            </Button>
            <Button
              disabled={!transactionsResponse.data.hasNext}
              onClick={handleLastPage}
              variant="outline"
              size="icon"
            >
              <ChevronsRight className="w-4 h-4" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BankTransactionsTable;
