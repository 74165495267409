import { ICreditTerms } from '@/types/credit-term.types';
import { formatCurrencyByUnit } from './formatNumberByUnit';

interface ICreditTermTemplate extends ICreditTerms<'MIN_GUARANTEE_TERM'> {
  templateId: string;
}

const templates: ICreditTermTemplate[] = [
  {
    title: `Min ${formatCurrencyByUnit(5000, 'actual')} for 6 months`,
    description:
      'The customer has to pay per the agreed rate subject to a minimum of INR 5,000 per month for the first six months',
    type: 'MIN_GUARANTEE_TERM',
    terms: {
      minimumGuaranteeTerms: [
        {
          frequency: 'MONTH',
          number: 6,
          minGuaranteedAmount: 5000,
        },
      ],
    },
    id: '',
    templateId: 'template-3',
    isDefault: false,
    metaData: null,
  },
];

const emptyTemplate: ICreditTerms<'MIN_GUARANTEE_TERM'> = {
  title: '',
  description: '',
  type: 'MIN_GUARANTEE_TERM',
  terms: {
    minimumGuaranteeTerms: [
      {
        frequency: 'MONTH',
        number: 1,
        minGuaranteedAmount: 1,
      },
    ],
  },
  id: '',
  isDefault: false,
  metaData: null,
};

export const getAllMinGuranteeTemplates = () => {
  return templates;
};

export const getMinGuaranteeTemplateById = (id: string) => {
  const foundTemplate = templates.find((template) => template.templateId === id);

  if (foundTemplate) {
    return JSON.parse(JSON.stringify(foundTemplate));
  }

  return JSON.parse(JSON.stringify(emptyTemplate));
};
