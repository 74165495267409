import { Input } from '@/components/ui/input';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

const StateLabelInput = ({
  register,
  errors,
}: {
  register: UseFormRegister<{
    label: string;
  }>;
  errors: FieldErrors<{
    label: string;
  }>;
}) => {
  return (
    <div className=" flex flex-col gap-2 ">
      <h2>Label</h2>
      <Input {...register('label')} placeholder="Enter label" />
      <p className="text-destructive text-xs h-6">{errors.label?.message}</p>
    </div>
  );
};

export default StateLabelInput;
