'use client';

import { cn } from '@/lib/utils';
import { useState } from 'react';
import SelectComponent from '../ui/select-component';

interface IOption {
  label: string;
  value: string;
}

const AttachmentPurposeDropdown = ({
  options,
  onChange,
  placeholder,
  className,
}: {
  options: IOption[];
  onChange: (_: IOption | undefined) => void;
  placeholder: string;
  className?: string;
}) => {
  const [selectedValue, setSelectedValue] = useState<IOption | undefined>();
  const [focused, setFocused] = useState(false);

  const handleSelect = (value: string | undefined) => {
    if (value === undefined) {
      console.error('Undefined value passed');
    } else {
      const selectedOption = options.find((option) => option.value === value);
      setSelectedValue(selectedOption);
      onChange(selectedOption);
    }
  };

  return (
    <div
      className={cn('flex border rounded-md max-w-[400px] overflow-ellipsis', focused && 'ring-1 ring-ring', className)}
    >
      <SelectComponent
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className="border-0 ring-0 hover:ring-0 focus-within:ring-0 focus-visible:ring-0 focus:ring-0"
        value={selectedValue?.value} // Ensure value is the expected format
        options={options}
        onChange={(value) => {
          handleSelect(value);
        }}
        selectComponentClassName={'h-[125px]'}
      />
    </div>
  );
};

export default AttachmentPurposeDropdown;
