import { historyExists } from '@/lib/utils';
import { ArrowLeftIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const PostingCreditHeader = ({ isLoading }: { isLoading: boolean }) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      historyExists() && (
        <div className="text-sm text-blue-700 flex items-center gap-2 my-2 cursor-pointer" onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="w-4 h-4" />
          Credits
        </div>
      )
    );
  }

  return (
    <>
      {historyExists() && (
        <div className=" pb-2 border-b ">
          <div
            className="text-sm text-blue-700 flex items-center gap-2 my-2 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className="w-4 h-4" />
            Credits
          </div>
        </div>
      )}
    </>
  );
};

export default PostingCreditHeader;
