import { CreditFiltersDialog } from '@/components/posting/credits/CreditsFilters';
import CreditsForInvoicePostingTable from '@/components/posting/invoices/CreditsForInvoicePostingTable';
import PostingInvoiceDetailHeader from '@/components/posting/invoices/view-invoice-header';
import { Layout } from '@/components/shared/Layout';
import TableSkeleton from '@/components/shared/TableSkeleton';
import { Skeleton } from '@/components/ui/skeleton';
import { useGetInvoiceById } from '@/hooks/api-hooks/useInvoiceQuery';
import { historyExists } from '@/lib/utils';
import { ArrowLeftIcon } from 'lucide-react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

const PostingInvoiceDetail = () => {
  const { invoiceId } = useParams();

  const {
    data: invoiceData,
    isError,
    isLoading,
  } = useGetInvoiceById({
    invoiceId: invoiceId as string,
    customConfig: {
      enabled: !!invoiceId,
    },
  });
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Layout className="sm:px-4 sm:py-4 px-4 py-4 h-screen overflow-hidden flex flex-col text-sm">
        {historyExists() && (
          <div className=" pb-2 border-b ">
            <div
              className="text-sm text-blue-700 flex items-center gap-2 my-2 cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowLeftIcon className="w-4 h-4" />
              Invoices
            </div>
          </div>
        )}
        <div className="py-4 border-b">
          <div className="flex justify-between">
            <div>
              <h3 className=" text-muted-foreground">Due/Overdue</h3>
              <h1 className=" text-2xl font-semibold pb-4 ">
                <Skeleton className="w-32 h-6" />
              </h1>
            </div>
            <div>
              <Skeleton className="w-32 h-7" />
            </div>
          </div>
          <div className="flex justify-between gap-6 ">
            <div className=" flex flex-col gap-1 ">
              <Skeleton className="w-32 h-5" />
              <Skeleton className="w-32 h-5" />
            </div>
            <div className=" flex flex-col gap-1 ">
              <Skeleton className="w-32 h-5" />
              <Skeleton className="w-32 h-5" />
            </div>
            <div className=" flex flex-col gap-1 ">
              <Skeleton className="w-32 h-5" />
              <Skeleton className="w-32 h-5" />
            </div>
            <div className=" flex flex-col gap-1 ">
              <Skeleton className="w-32 h-5" />
              <Skeleton className="w-32 h-5" />
            </div>
            <div className=" flex flex-1 flex-col gap-1 ">
              <Skeleton className="w-32 h-5" />
              <Skeleton className="w-2/3 h-5" />
            </div>
          </div>
        </div>
        <div className=" text-sm py-4 ">
          <div>
            <CreditFiltersDialog
              handleSubmit={() => {}}
              filters={{
                bankAccountIds: [],
                categories: [],
                postingStatus: [],
              }}
            />
          </div>
          <TableSkeleton rows={13} columns={6} />
        </div>
      </Layout>
    );
  }
  const invoice = invoiceData?.data;
  if (isError || !invoice || !invoiceId) {
    return <Navigate to="/404" replace />;
  }

  return (
    <Layout className="sm:px-4 sm:py-4 px-4 py-4 h-screen overflow-hidden flex flex-col text-sm">
      {historyExists() && (
        <div className=" pb-2 border-b ">
          <div
            className="text-sm text-blue-700 flex items-center gap-2 my-2 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className="w-4 h-4" />
            Invoices
          </div>
        </div>
      )}
      <PostingInvoiceDetailHeader invoice={invoice} />
      <CreditsForInvoicePostingTable invoice={invoice} />
    </Layout>
  );
};

export default PostingInvoiceDetail;
