import ToolTipCell from '@/components/Table/ToolTipCell';
import { Skeleton } from '@/components/ui/skeleton';
import { useGetWriteOffByInvoiceId } from '@/hooks/api-hooks/useWriteOffQuery';
import { cn } from '@/lib/utils';
import { IInvoice } from '@/types/invoices.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { InfoIcon } from 'lucide-react';
import { Badge } from '../../ui/badge';

const InvoiceHeaderSubItem = ({ label, value, className }: { label: string; value: string; className?: string }) => {
  return (
    <div className={cn(' flex flex-col ', className)}>
      <div className=" text-muted-foreground text-sm">{label}</div>
      <div>{value}</div>
    </div>
  );
};

const WriteOffs = ({
  invoiceId,
  currencyType,
  outstandingAmount,
}: {
  invoiceId: string;
  currencyType?: string;
  outstandingAmount: number;
}) => {
  const { data: writeOffDetails, isLoading } = useGetWriteOffByInvoiceId({
    invoiceId: invoiceId,
    approvalStatusList: ['PENDING'],
  });

  if (isLoading) {
    return (
      <span className="text-yellow-600">
        <Skeleton className="w-32 h-4" />
      </span>
    );
  }

  if (!writeOffDetails?.data.length) return null;

  const writeOffList = writeOffDetails.data;

  return (
    <div className="flex items-center gap-2 flex-1 pb-4">
      <p className="text-yellow-600 ">
        (Write off requested: {formatCurrencyByUnit(writeOffList[0].writeOffAmount, 'actual', currencyType)})
      </p>
      <ToolTipCell
        additionalClasses=" max-w-[300px] "
        value={<InfoIcon className=" w-4 h-4 cursor-pointer text-muted-foreground hover:text-primary " />}
      >
        If the write-off request is approved, the Invoice Due/Overdue will reduce to INR{' '}
        {outstandingAmount - writeOffList[0].writeOffAmount}
      </ToolTipCell>
    </div>
  );
};

const PostingInvoiceDetailHeader = ({ invoice }: { invoice: IInvoice }) => {
  return (
    <div className="py-4 border-b">
      <div className="flex justify-between">
        <div className=" flex-1 ">
          <h3 className=" text-muted-foreground">Due/Overdue</h3>
          <div className=" flex items-center gap-1  ">
            <h1
              className={cn(
                ' text-2xl font-semibold pb-4 ',
                invoice.invoiceOutstandingAmount > 0 && 'text-destructive',
                invoice.invoiceOutstandingAmount < 0 && ' text-green-700 ',
              )}
            >
              {formatCurrencyByUnit(invoice.invoiceOutstandingAmount as number, 'actual', invoice.invoiceCurrency)}
            </h1>{' '}
            <WriteOffs
              outstandingAmount={invoice.invoiceOutstandingAmount}
              invoiceId={invoice.id}
              currencyType={invoice.invoiceCurrency}
            />
          </div>
        </div>
        <div>
          <Badge
            className={cn(
              invoice.paymentStatus === 'PAID' && 'bg-green-700 hover:bg-green-700 hover:text-white text-white',
              invoice.paymentStatus === 'UNPAID' && 'bg-red-500 text-white hover:bg-red-500 hover:text-white',
              invoice.paymentStatus === 'PARTIALLY_PAID' && 'bg-yellow-400 text-black hover:bg-yellow-400',
            )}
          >
            {invoice.paymentStatus.split('_').join(' ')}
          </Badge>
        </div>
      </div>
      <div className="flex justify-between gap-2 ">
        <InvoiceHeaderSubItem
          className=" min-w-[160px]"
          label="Invoice Value"
          value={formatCurrencyByUnit(invoice.invoiceTotal as number, 'actual', invoice.invoiceCurrency)}
        />
        <InvoiceHeaderSubItem className=" min-w-[160px]" label="Invoice Date" value={invoice.invoiceDate} />
        <InvoiceHeaderSubItem className=" min-w-[160px]" label="Invoice Due Date" value={invoice.invoiceDueDate} />
        <InvoiceHeaderSubItem className=" min-w-[160px]" label="Invoice Number" value={invoice.invoiceNumber} />
        <InvoiceHeaderSubItem className=" min-w-[160px]" label="Invoice Type" value={invoice.invoiceType as string} />
        <InvoiceHeaderSubItem className=" flex-1" label="Customer" value={invoice.customerName} />
      </div>
    </div>
  );
};

export default PostingInvoiceDetailHeader;
