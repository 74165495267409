import AttachPan from '@/components/user/AttachPan';
import WithTopNav from '@/components/wrappers/WithTopNavBar';
import Lottie from 'lottie-react';
import heroAnimationData from '../assets/animation_llay8o41.json';

const AddCompanyPage = () => {
  return (
    <WithTopNav>
      <div className="flex-1 flex flex-col gap-12 md:flex-row items-center justify-around sm:gap-8 max-w-6xl w-full px-4">
        <div className=" w-full sm:min-w-[400px] sm:max-w-sm  ">
          <Lottie animationData={heroAnimationData} loop={true} autoplay={true} />
        </div>
        <div className="w-full md:max-w-sm md:min-w-[400px] ">
          <AttachPan />
        </div>
      </div>
    </WithTopNav>
  );
};

export default AddCompanyPage;
