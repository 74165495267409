import { getEmailWebviewContents, postSendEmail, postSendEmailPreview } from '@/api-functions/email.api';
import { PreviewVariable } from '@/components/email-builder/PreviewTemplate';
import { IApiResponse } from '@/types/common.types';
import { IEmailRequest, IGetEmailContentsResponse } from '@/types/email.types';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const EmailQueryKeys = {
  getEmailById: (id: string) => ['getEmailById', id],
};

export const useGetEmailWebviewContentsByAccessToken = ({
  customConfig,
  mailAccessToken,
}: {
  customConfig?: Omit<UseQueryOptions<IGetEmailContentsResponse>, 'queryKey'>;
  mailAccessToken: string;
}) => {
  const getEmailWebviewContentsDetailsByIdQuery = useQuery<IGetEmailContentsResponse>({
    queryKey: EmailQueryKeys.getEmailById(mailAccessToken),
    queryFn: () => getEmailWebviewContents({ mailAccessToken }),
    ...customConfig,
  });

  return getEmailWebviewContentsDetailsByIdQuery;
};

export const usePostSendEmailPreview = ({
  templateId,
  customConfig,
}: {
  templateId: string;
  customConfig?: UseMutationOptions<
    IApiResponse<{
      emailId: string;
    }>,
    AxiosError<IApiResponse<string>>,
    {
      variables: PreviewVariable[];

      to: string[];
      from: string;
      cc: string[];
    }
  >;
}) => {
  const postSendMailPreviewMutation = useMutation({
    mutationFn: ({
      variables,
      from,
      to,
      cc,
    }: {
      variables: PreviewVariable[];
      to: string[];
      from: string;
      cc: string[];
    }) =>
      postSendEmailPreview({
        templateId: templateId,
        variables: variables,
        to,
        from,
        cc,
      }),
    ...customConfig,
  });

  return postSendMailPreviewMutation;
};
export const usePostSendMail = ({
  customConfig,
}: {
  customConfig?: Omit<
    UseMutationOptions<IApiResponse<void>, AxiosError<IApiResponse<void>>, IEmailRequest>,
    'mutationFn'
  >;
}) => {
  const sendMail = useMutation<IApiResponse<void>, AxiosError<IApiResponse<void>>, IEmailRequest>({
    mutationFn: (email: IEmailRequest) =>
      postSendEmail({
        subject: email.subject,
        body: email.body,
        to: email.to,
        templateId: email.templateId,
        cc: email.cc,
        fromEmail: email.fromEmail, // Ensure to include this field as well
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return sendMail;
};
