import {
  createCompany,
  fetchCompanies,
  fetchCompanyInvites,
  fetchCompanyLogo,
  fetchCompanyMembers,
  inviteEmail,
  revokeInvite,
} from '@/api-functions/company.api';
import { IApiResponse } from '@/types/common.types';
import {
  GetCompanyApiResponse,
  GetCompanyInvitesApiResponse,
  GetCompanyMembersApiResponse,
  ICompanyLogoResponse,
} from '@/types/company.types';
import { UseMutationOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useCompany = () => {
  return useQuery<GetCompanyApiResponse>({
    queryKey: ['companies'],
    queryFn: () => fetchCompanies(),
    retry: false,
    staleTime: Infinity,
  });
};

export const useCompanyInvites = (companyId: string) => {
  return useQuery<GetCompanyInvitesApiResponse>({
    queryKey: ['invites'],
    queryFn: () => fetchCompanyInvites(companyId),
    retry: false,
    enabled: !!companyId,
  });
};

export const useCompanyMembers = (companyId: string) => {
  return useQuery<GetCompanyMembersApiResponse>({
    queryKey: ['members'],
    queryFn: () => fetchCompanyMembers(companyId),
    retry: false,
    enabled: !!companyId,
  });
};

export const useAddInviteMutation = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    string,
    unknown,
    {
      email: string;
      companyId: string;
    }
  >;
}) => {
  return useMutation({
    mutationFn: ({ email, companyId }: { email: string; companyId: string }) => inviteEmail(email, companyId),
    ...customConfig,
  });
};

export const useRevokeInviteMutation = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    string,
    unknown,
    {
      invitationId: string;
    }
  >;
}) => {
  return useMutation({
    mutationFn: ({ invitationId }: { invitationId: string }) => revokeInvite(invitationId),
    ...customConfig,
  });
};

export const usePostCreateCompany = ({
  customConfig,
}: {
  customConfig: UseMutationOptions<
    string,
    AxiosError<IApiResponse<string>>,
    {
      cin: string;
    }
  >;
}) => {
  return useMutation({
    mutationFn: ({ cin }: { cin: string }) =>
      createCompany({
        cin,
      }),
    ...customConfig,
  });
};

export const useGetCompanyLogo = () => {
  return useQuery<ICompanyLogoResponse>({
    queryKey: ['companyLogo'],
    queryFn: () => fetchCompanyLogo(),
  });
};
