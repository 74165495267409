import { LabelValueDescriptionLayout } from '@/components/shared/LabelValueDescLayout';
import dayjs from 'dayjs';

const IRNDetails = ({
  irnNumber,
  irnGenerationDate,
  irnAckNumber,
}: {
  irnNumber: string | null;
  irnGenerationDate: string | null;
  irnAckNumber: string | null;
}) => {
  return (
    <LabelValueDescriptionLayout
      label="IRN:"
      value={<span className=" px-4 ">{irnNumber ?? 'Not available'}</span>}
      description={
        <div className=" w-full truncate text-ellipsis flex items-center gap-4 ">
          {irnGenerationDate && <span>IRN Date: {dayjs(irnGenerationDate).format('DD MMM YYYY, HH:MM P')}</span>}
          {irnAckNumber && <span>| Ack. No {irnAckNumber}</span>}
        </div>
      }
    />
  );
};

export default IRNDetails;
