import { usePlanContext } from '@/contexts/PlanContext';
import { useUserContext } from '@/contexts/UserContext';
import { useGetBankAccountTransactionDateRange } from '@/hooks/api-hooks/useBankTransactionsQuery';
import { useGetPlansByCompanyId } from '@/hooks/api-hooks/usePlanQuery';
import { HALF_YEAR_ARRAY, MONTH_ARRAY, QUARTER_ARRAY } from '@/utils/constants';
import { SelectGroup, SelectItem } from '@radix-ui/react-select';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CategoryCombobox } from '../../ui/category-combobox';
import { Select, SelectContent } from '../../ui/select';
import SelectComponent from '../../ui/select-component';
import { Tabs, TabsList, TabsTrigger } from '../../ui/tabs';

const CashflowSummaryFilters = ({
  handleSubmit,
  setGraphType,
  setIsCategory,
  setSelectedCategory,
  selectedCategory,
  graphType,
  isCategory,
}: {
  handleSubmit: (_: { startDate: Date; endDate: Date }) => void;
  isDetails?: boolean;
  setGraphType: React.Dispatch<React.SetStateAction<'daily' | 'cumulative'>>;
  setIsCategory: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
  selectedCategory: string;
  graphType: string;
  isCategory?: boolean;
}) => {
  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const [selectedYear, setSelectedYear] = useState<string>(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    if (currentMonth >= 3) {
      return `${currentYear}`;
    }

    return `${currentYear - 1}`;
  });
  const [selectedFrequency, setSelectedFrequency] = useState<'yearly' | 'quarterly' | 'monthly' | 'half_year'>(
    'monthly',
  );
  const [selectedValue, setSelectedValue] = useState<string>(new Date().getMonth().toString());
  const [searchParams, setSearchParams] = useSearchParams();
  const { setPlanId } = usePlanContext();
  const { data: plans } = useGetPlansByCompanyId({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    customConfig: { enabled: !!companiesOfUser?.[activeCompanyIndex]?.id },
  });
  const { data: transactionDateRange } = useGetBankAccountTransactionDateRange({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    customConfig: { enabled: !!companiesOfUser?.[activeCompanyIndex]?.id },
  });

  const frequencyOptions = useMemo(
    () => [
      {
        label: 'Yearly',
        value: 'yearly',
      },
      {
        label: 'Half-yearly',
        value: 'half_year',
      },
      {
        label: 'Quarterly',
        value: 'quarterly',
      },
      {
        label: 'Monthly',
        value: 'monthly',
      },
    ],
    [],
  );

  const yearOptions = useMemo(() => {
    const finalYear = transactionDateRange?.data.companyDateRange.endDateFinancialYear || 0;
    const initialYear = transactionDateRange?.data.companyDateRange.startDateFinancialYear || 0;

    return new Array(finalYear - initialYear + 1)
      .fill(0)
      .map((_, index) => ({
        label: `FY ${initialYear + index}`,
        value: `${initialYear + index - 1}`,
      }))
      .reverse();
  }, [transactionDateRange]);

  const monthOptions = useMemo(
    () =>
      MONTH_ARRAY.map((_, index) => ({
        label: dayjs(
          new Date(((index + 3) % 12) + 1 > 3 ? +selectedYear : +selectedYear + 1, ((index + 3) % 12) + 1, 0),
        ).format('MMM YY'),
        value: ((index + 3) % 12).toString(),
      })),
    [selectedYear],
  );

  const quarterArray = useMemo(
    () =>
      QUARTER_ARRAY.map((quarter, index) => ({
        label: quarter,
        value: index.toString(),
      })),
    [],
  );

  const halfYearArray = useMemo(
    () =>
      HALF_YEAR_ARRAY.map((halfYear, index) => ({
        label: halfYear,
        value: index.toString(),
      })),
    [],
  );

  const options = useMemo(() => {
    if (selectedFrequency === 'monthly') {
      return monthOptions;
    }
    if (selectedFrequency === 'quarterly') {
      return quarterArray;
    }
    if (selectedFrequency === 'half_year') {
      return halfYearArray;
    }
    return yearOptions;
  }, [selectedFrequency, halfYearArray, monthOptions, quarterArray, yearOptions]);

  const calculateDate = useCallback(
    (year: number, frequency: string, value: string): { startDate: Date; endDate: Date } => {
      const selectedIndex = parseInt(value);
      if (frequency === 'quarterly') {
        return {
          startDate: new Date(year, (selectedIndex + 1) * 3, 1),
          endDate: new Date(year, (selectedIndex + 1) * 3 + 3, 0),
        };
      }
      if (frequency === 'monthly') {
        if (selectedIndex < 3) {
          return {
            startDate: new Date(year + 1, selectedIndex, 1),
            endDate: new Date(year + 1, selectedIndex + 1, 0),
          };
        }
        return {
          startDate: new Date(year, selectedIndex, 1),
          endDate: new Date(year, selectedIndex + 1, 0),
        };
      }
      if (frequency === 'half_year') {
        return {
          startDate: new Date(year, selectedIndex * 6 + 3, 1),
          endDate: new Date(year, selectedIndex * 6 + 9, 0),
        };
      }
      return {
        startDate: new Date(year, 3, 1),
        endDate: new Date(year + 1, 3, 0),
      };
    },
    [],
  );

  const handleYearChange = useCallback(
    (value: string) => {
      setSelectedYear(value);
      const findPlan = plans?.data.plans.find((plan) => dayjs(plan.periodEndDate).format('YYYY') === value);
      if (findPlan) {
        setPlanId(findPlan.id);
      }
      setSearchParams((prev) => {
        prev.set('year', value);
        return prev;
      });
      handleSubmit(calculateDate(parseInt(value), selectedFrequency, selectedValue));
    },
    [setSelectedYear, handleSubmit, selectedFrequency, selectedValue, calculateDate, setSearchParams, plans, setPlanId],
  );

  const handleFrequencyChange = useCallback(
    (value: string) => {
      setSelectedFrequency(value as 'yearly' | 'quarterly' | 'monthly' | 'half_year');
      setSelectedValue('0');
      setSearchParams((prev) => {
        prev.set('frequency', value);
        prev.set('value', '0');
        prev.set('year', selectedYear);
        return prev;
      });
      handleSubmit(calculateDate(parseInt(selectedYear), value, '0'));
    },
    [setSelectedFrequency, handleSubmit, selectedYear, calculateDate, setSearchParams],
  );

  const handleChange = useCallback(
    (value: string) => {
      setSelectedValue(value);
      setSearchParams((prev) => {
        prev.set('value', value);
        prev.set('year', selectedYear);
        prev.set('frequency', selectedFrequency);
        return prev;
      });
      handleSubmit(calculateDate(parseInt(selectedYear), selectedFrequency, value));
    },
    [setSelectedValue, handleSubmit, selectedYear, selectedFrequency, calculateDate, setSearchParams],
  );

  const handleGraphTypeChange = useCallback(
    (value: string) => {
      const newValue = value as 'daily' | 'cumulative';
      setGraphType(newValue);
    },
    [setGraphType],
  );

  const handleSelectChange = useCallback(
    (value: string) => {
      if (value === 'summary') {
        setIsCategory(false);
        setGraphType('daily');
      } else {
        setIsCategory(true);
      }
      setSearchParams((prev) => {
        prev.set('category', value);
        prev.set('year', selectedYear);
        prev.set('frequency', selectedFrequency);
        prev.set('value', selectedValue);
        return prev;
      });
      setSelectedCategory(value);
    },
    [setIsCategory, setGraphType, setSelectedCategory, setSearchParams, selectedFrequency, selectedValue, selectedYear],
  );

  useEffect(() => {
    const year = searchParams.get('year');
    const frequency = searchParams.get('frequency');
    const value = searchParams.get('value');
    const category = searchParams.get('category');
    const currentYear = new Date().getFullYear();

    if (frequency && value) {
      setSelectedFrequency(frequency as 'yearly' | 'quarterly' | 'monthly' | 'half_year');
      setSelectedValue(value);
      if (year) {
        setSelectedYear(year);
      }
      handleSubmit(calculateDate(parseInt(year ? `${year}` : `${currentYear - 1}`), frequency, value));
    }

    if (category) {
      setIsCategory(category !== 'summary');
      setSelectedCategory(category);
    }
  }, [searchParams, handleSubmit, calculateDate, setIsCategory, setSelectedCategory]);

  return (
    <div className="flex gap-4 flex-wrap mt-4 justify-between">
      <div className="absolute top-5 ">
        <div className="relative left-12 md:left-0">
          <CategoryCombobox selectedCategory={selectedCategory} handleSelectChange={handleSelectChange} />
          <Select>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="summary">Summary</SelectItem>
              </SelectGroup>
              <SelectGroup>
                <SelectItem value="summary">Summary</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      <h2 className="text-xl font-semibold">Cashflow summary</h2>
      <div className="flex gap-4 items-center">
        {isCategory && (
          <Tabs onValueChange={handleGraphTypeChange} value={graphType}>
            <TabsList>
              <TabsTrigger value="daily">Day wise</TabsTrigger>
              <TabsTrigger value="cumulative">Cumulative</TabsTrigger>
            </TabsList>
          </Tabs>
        )}
        <SelectComponent
          value={selectedYear}
          options={yearOptions}
          placeholder="Select a year"
          onChange={handleYearChange}
        />
        <SelectComponent
          value={selectedFrequency}
          options={frequencyOptions}
          placeholder="Select a frequency"
          onChange={handleFrequencyChange}
        />
        {selectedFrequency !== 'yearly' && (
          <SelectComponent
            value={selectedValue}
            options={options}
            placeholder="Select a date range"
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  );
};

export default CashflowSummaryFilters;
