import SignInForm from '@/components/user/SignInForm';
import WithTopNav from '@/components/wrappers/WithTopNavBar';
import Lottie from 'lottie-react';
import heroAnimationData from '../assets/animation_llay8o41.json';

const SignInPage = () => {
  return (
    <WithTopNav>
      <div className="w-full flex-1 flex justify-center items-center">
        <div className=" flex flex-col gap-12 md:flex-row items-center justify-around sm:gap-8 max-w-6xl w-full px-4">
          <div className=" w-full sm:min-w-[400px] sm:max-w-sm  ">
            <Lottie animationData={heroAnimationData} loop={true} autoplay={true} />
          </div>
          <div className="w-full md:max-w-sm md:min-w-[400px] ">
            <SignInForm />
          </div>
        </div>
      </div>
    </WithTopNav>
  );
};

export default SignInPage;
