import React from 'react';
import AppBarComponent from '../shared/AppBar';

const WithTopNav = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className=" flex flex-col min-h-screen ">
      <AppBarComponent />
      <div className="flex-1 w-full flex flex-col">{children}</div>
    </div>
  );
};

export default WithTopNav;
