import { cn } from '@/lib/utils';
import { Card, CardContent, CardHeader } from './card';

export const Section = ({
  title,
  children,
  titleClassName,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
  titleClassName?: string;
}) => {
  return (
    <Card className=" my-8 relative ">
      <CardHeader className={cn(' border-b py-4 font-semibold underline ', titleClassName)}>{title}</CardHeader>
      <CardContent className=" flex flex-col gap-4 py-2 ">{children}</CardContent>
    </Card>
  );
};
