import { dataAxiosInstance } from '@/lib/axios';
import { ILeafCategoriesResponse } from '@/types/category.types';

export const getCategoryByCompany = async () => {
  const result = await dataAxiosInstance.get('/category/company');
  return result.data;
};

export const getCategoryRelationshipsForCompany = async () => {
  const result = await dataAxiosInstance.get('/category/relationships');
  return result.data;
};

export const getLeafCategoriesForCompany = async () => {
  const result = await dataAxiosInstance.get<ILeafCategoriesResponse>('/category/company/leafNodes');
  return result.data;
};

export const updateReceivableDays = async ({
  categoryId,
  receivableDays,
}: {
  categoryId: string;
  receivableDays: number;
}) => {
  const result = await dataAxiosInstance.post(`/category/receivableDays`, {
    categoryId,
    receivableDays,
  });
  return result.data;
};
