import TableSkeleton from '@/components/shared/TableSkeleton';
import { Table, TableCaption, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { cn } from '@/lib/utils';
import { flexRender, Table as ReactTable } from '@tanstack/react-table';
import { GripVerticalIcon } from 'lucide-react';
import { useMemo } from 'react';
import { CustomTableBody, MemoizedTableBody } from './MemoizedTableBody';

export const ResizableTable = <T,>({
  table,
  isLoading = false,
  headersToExcludeResizing,
  columnScale = 1,
}: {
  table: ReactTable<T>;
  isLoading?: boolean;
  headersToExcludeResizing: string[];
  columnScale?: number;
}) => {
  const sizes = table.getState().columnSizingInfo;

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    return headers.reduce(
      (acc, current) => {
        return {
          ...acc,
          [`--col-${current.column.id}-size`]: current.column.getSize(),
          [`--header-${current.id}-size`]: current.getSize(),
        };
      },
      {} as {
        [key: string]: number;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);

  if (isLoading) {
    return <TableSkeleton rows={10} columns={10} />;
  }

  return (
    <div className=" overflow-auto ">
      <Table
        style={{
          ...columnSizeVars,
          width: table.getTotalSize(),
        }}
      >
        {table.getRowModel().rows.length === 0 && <TableCaption className="pb-10">No data found</TableCaption>}
        <TableHeader className=" bg-gray-100">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className="flex items-center" key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    style={{
                      width: `calc(var(--header-${header?.id}-size) * ${columnScale}px)`,
                    }}
                    className="flex items-center justify-between"
                    key={header.id}
                  >
                    <div className="break-keep truncate text-ellipsis">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                    <div className="flex gap-2">
                      {!headersToExcludeResizing.includes(header.id) && (
                        <GripVerticalIcon
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: cn(
                              'w-4 h-4 cursor-ew-resize',
                              header.column.getIsResizing() ? 'opacity-100' : 'opacity-50',
                            ),
                          }}
                        />
                      )}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        {table.getState().columnSizingInfo.isResizingColumn ? (
          <MemoizedTableBody columnScale={columnScale} table={table} />
        ) : (
          <CustomTableBody columnScale={columnScale} table={table} />
        )}
      </Table>
    </div>
  );
};
