import {
  getActualDetailsForCompany,
  getCashflowSummaryCompany,
  getCashflowSummaryDetailsByCompany,
  getCashflowSummaryDetailsForCategory,
  getCashflowSummaryForCategory,
  getClosingBalanceOnDate,
  getTransactionSummary,
} from '@/api-functions/cashflow.api';
import {
  IAggregateBy,
  ICashflowActualDetailsResponse,
  ICashflowActualDetailsTableData,
  ICashflowCategoryGraphResponse,
  ICashflowClosingBalanceResponse,
  ICashflowGraphResponse,
  ICashflowSummaryDetailsResponse,
  ITransactionSummaryResponse,
} from '@/types/cashflow.types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

const CashflowQueryKeys = {
  getCashflowSummaryGraph: (planId: string, startDate: Date, endDate: Date, companyId: string) => {
    return [
      'getCashflowSummaryGraph',
      planId,
      dayjs(startDate).format('YYYY-MM-DD'),
      dayjs(endDate).format('YYYY-MM-DD'),
      companyId,
    ];
  },
  getCashflowSummaryDetails: (planId: string, startDate: Date, endDate: Date, companyId: string) => [
    'getCashflowSummaryCompanyDetails',
    planId,
    dayjs(startDate).format('YYYY-MM-DD'),
    dayjs(endDate).format('YYYY-MM-DD'),
    companyId,
  ],
  getCashflowTransactionSummary: (startDate: Date, endDate: Date) => [
    'getCashflowTransactionSummary',
    dayjs(startDate).format('YYYY-MM-DD'),
    dayjs(endDate).format('YYYY-MM-DD'),
  ],
  getCashflowSummaryForCategory: (
    planId: string,
    startDate: Date,
    endDate: Date,
    categoryId: string,
    companyId: string,
  ) => [
    'getCashflowSummaryForCategory',
    planId,
    dayjs(startDate).format('YYYY-MM-DD'),
    dayjs(endDate).format('YYYY-MM-DD'),
    categoryId,
    companyId,
  ],
  getCashflowSummaryDetailsForCategory: (
    planId: string,
    startDate: Date,
    endDate: Date,
    categoryId: string,
    companyId: string,
  ) => [
    'getCashflowSummaryDetailsForCategory',
    planId,
    dayjs(startDate).format('YYYY-MM-DD'),
    dayjs(endDate).format('YYYY-MM-DD'),
    categoryId,
    companyId,
  ],
  getActualDetailsForPlan: (planId: string, startDate: Date, endDate: Date, aggregateBy: IAggregateBy) => [
    'getActualDetailsForPlan',
    planId,
    dayjs(startDate).format('YYYY-MM-DD'),
    dayjs(endDate).format('YYYY-MM-DD'),
    aggregateBy,
  ],

  getClosingBalanceOnDate: (planId: string, date: Date) => [
    'getClosingBalanceOnDate',
    planId,
    dayjs(date).format('YYYY-MM-DD'),
  ],
};

export const useGetCashflowSummaryGraph = ({
  planId,
  startDate,
  endDate,
  companyId,
  customConfig,
}: {
  startDate: Date;
  endDate: Date;
  planId: string;
  companyId: string;
  customConfig?: Omit<UseQueryOptions<ICashflowGraphResponse>, 'queryKey'>;
}) => {
  const getCashflowSummaryGraphQuery = useQuery<ICashflowGraphResponse>({
    queryKey: CashflowQueryKeys.getCashflowSummaryGraph(planId, startDate, endDate, companyId),
    queryFn: () =>
      getCashflowSummaryCompany({
        planId,
        startDate,
        endDate,
      }),
    ...customConfig,
  });

  return getCashflowSummaryGraphQuery;
};

export const useGetCashflowCategoryGraph = ({
  planId,
  startDate,
  endDate,
  customConfig,
  categoryId,
  companyId,
}: {
  startDate: Date;
  endDate: Date;
  planId: string;
  categoryId: string;
  companyId: string;
  customConfig?: Omit<UseQueryOptions<ICashflowCategoryGraphResponse>, 'queryKey'>;
}) => {
  const getCashflowSummaryForCategoryQuery = useQuery<ICashflowCategoryGraphResponse>({
    queryKey: CashflowQueryKeys.getCashflowSummaryForCategory(planId, startDate, endDate, categoryId, companyId),
    queryFn: () =>
      getCashflowSummaryForCategory({
        planId,
        startDate,
        endDate,
        categoryId,
      }),
    ...customConfig,
  });

  return getCashflowSummaryForCategoryQuery;
};

export const useGetCashflowSummaryDetailsByPlan = ({
  planId,
  startDate,
  endDate,
  customConfig,
  companyId,
}: {
  planId: string;
  startDate: Date;
  endDate: Date;
  companyId: string;
  customConfig?: Omit<UseQueryOptions<ICashflowSummaryDetailsResponse>, 'queryKey'>;
}) => {
  const getCashflowSummaryDetailsQuery = useQuery<ICashflowSummaryDetailsResponse>({
    queryKey: CashflowQueryKeys.getCashflowSummaryDetails(planId, startDate, endDate, companyId),
    queryFn: () =>
      getCashflowSummaryDetailsByCompany({
        planId,
        startDate,
        endDate,
      }),
    ...customConfig,
    select(data) {
      return {
        ...data,
        data: data.data.reverse(),
      };
    },
  });

  return getCashflowSummaryDetailsQuery;
};

export const useGetCashflowSummaryDetailsForCategory = ({
  planId,
  startDate,
  endDate,
  categoryId,
  customConfig,
  companyId,
}: {
  planId: string;
  startDate: Date;
  endDate: Date;
  categoryId: string;
  companyId: string;
  customConfig?: Omit<UseQueryOptions<ICashflowSummaryDetailsResponse>, 'queryKey'>;
}) => {
  const getCashflowSummaryDetailsForCategoryQuery = useQuery<ICashflowSummaryDetailsResponse>({
    queryKey: CashflowQueryKeys.getCashflowSummaryDetailsForCategory(planId, startDate, endDate, categoryId, companyId),
    queryFn: () =>
      getCashflowSummaryDetailsForCategory({
        planId,
        startDate,
        endDate,
        categoryId,
      }),
    ...customConfig,
  });

  return getCashflowSummaryDetailsForCategoryQuery;
};

export const useGetCashflowActualDetails = ({
  companyId,
  startDate,
  endDate,
  aggregateBy,
  customConfig,
}: {
  companyId: string;
  startDate: Date;
  aggregateBy: IAggregateBy;
  endDate: Date;
  customConfig?: Omit<UseQueryOptions<ICashflowActualDetailsResponse>, 'queryKey'>;
}) => {
  const getActualDetailsQuery = useQuery<ICashflowActualDetailsResponse>({
    queryKey: CashflowQueryKeys.getActualDetailsForPlan(companyId, startDate, endDate, aggregateBy),
    queryFn: () => getActualDetailsForCompany({ companyId, startDate, endDate, aggregateBy }),
    ...customConfig,
    select(data) {
      const newData = [...data.data];
      const firstElementPopped = newData.shift();
      const lastElementPopped = newData.pop();
      let returningArray: ICashflowActualDetailsTableData[] = [];
      if (firstElementPopped) {
        returningArray = [firstElementPopped, ...newData.reverse()];
      }
      if (lastElementPopped) {
        returningArray = [...returningArray, lastElementPopped];
      }
      return {
        ...data,
        data: returningArray,
      };
    },
  });

  return getActualDetailsQuery;
};

export const useGetClosingBalanceOnDate = ({
  companyId,
  date,
  customConfig,
}: {
  companyId: string;
  date: Date;
  customConfig?: Omit<UseQueryOptions<ICashflowClosingBalanceResponse>, 'queryKey'>;
}) => {
  const getActualDetailsQuery = useQuery<ICashflowClosingBalanceResponse>({
    queryKey: CashflowQueryKeys.getClosingBalanceOnDate(companyId, date),
    queryFn: () => getClosingBalanceOnDate({ companyId, date }),
    ...customConfig,
  });

  return getActualDetailsQuery;
};

export const useGetTransactionSummary = ({
  startDate,
  endDate,
  customConfig,
}: {
  startDate: Date;
  endDate: Date;
  customConfig?: Omit<UseQueryOptions<ITransactionSummaryResponse>, 'queryKey'>;
}) => {
  const getCashflowSummaryDetailsForCategoryQuery = useQuery<ITransactionSummaryResponse>({
    queryKey: CashflowQueryKeys.getCashflowTransactionSummary(startDate, endDate),
    queryFn: () =>
      getTransactionSummary({
        startDate,
        endDate,
      }),
    ...customConfig,
  });

  return getCashflowSummaryDetailsForCategoryQuery;
};
