import { Skeleton } from '@/components/ui/skeleton';
import { useGetActionById } from '@/hooks/api-hooks/useWorkflowQuery';
import { IEmailActionProperties, IGotoActionProperties, StateTypes } from '@/types/workflow.type';
import { useMemo } from 'react';
import EmailBlock from './EmailBlock';
import GotoActionBlock from './GotoBlock';

export const ActionSlot = ({
  actionId,
  stateId,
  workflowId,
  catIndex,
  nodeType,
  setIsGotoActionPresent,
}: {
  actionId: string;
  stateId: string;
  workflowId: string;
  catIndex: number;
  nodeType: StateTypes;
  setIsGotoActionPresent?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data: actionResponse, isLoading } = useGetActionById({
    actionId,
    customConfig: {
      enabled: !!actionId,
    },
  });

  const action = useMemo(() => {
    if (!actionResponse) {
      return null;
    }

    return actionResponse.data;
  }, [actionResponse]);

  if (isLoading) {
    return (
      <div className=" flex items-center gap-2  border-muted-foreground/20 ">
        <div className=" bg-muted-foreground/10 p-2 rounded-md ">
          <Skeleton className=" h-8 w-8 " />
        </div>
        <div className=" w-ful flex flex-col gap-1 flex-1 ">
          <div className=" w-full flex items-end gap-2 ">
            <Skeleton className="flex-1 h-4" />
          </div>
          <div className=" w-full flex items-start gap-2">
            <Skeleton className="flex-1 h-4" />
          </div>
        </div>
      </div>
    );
  }

  if (!action) {
    return null;
  }

  if (action.type === 'EMAIL_ACTION') {
    return (
      <EmailBlock
        nodeType={nodeType}
        catIndex={catIndex}
        actionId={actionId}
        stateId={stateId}
        workflowId={workflowId}
        properties={action.description as IEmailActionProperties}
        warnings={actionResponse?.warnings || []}
      />
    );
  }

  if (action.type === 'GOTO_ACTION' && setIsGotoActionPresent) {
    return (
      <GotoActionBlock
        setIsGotoActionPresent={setIsGotoActionPresent}
        actionId={actionId}
        catIndex={catIndex}
        nodeType={nodeType}
        properties={action.description as IGotoActionProperties}
        stateId={stateId}
        warnings={actionResponse?.warnings || []}
        workflowId={workflowId}
      />
    );
  }

  return <div>Error rendering action</div>;
};
