import { useSignOut } from '@/hooks/api-hooks/useAuth';
import { cn } from '@/lib/utils';
import { MenuIcon, UserCircleIcon } from 'lucide-react';
import { Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';
import CompanyLogo from '../ui/company-logo';
import { CompanySelectBox } from '../ui/company-selectbox';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { Sheet, SheetContent } from '../ui/sheet';
import { useToast } from '../ui/use-toast';
import ErrorWrapper from './ErrorWrapper';

const SidebarOptions = [
  {
    name: 'Cashflow',
    href: '/cashflow/summary',
    values: ['/cashflow', '/cashflow/summary', '/cashflow/details'],
    options: [
      {
        name: 'Summary',
        href: '/cashflow/summary',
        values: ['/cashflow/summary'],
      },
      {
        name: 'Details',
        href: '/cashflow/details',
        values: ['/cashflow/details'],
      },
    ],
  },
  {
    name: 'Bank Transactions',
    href: '/dashboard/bank-transactions',
    values: ['/dashboard/bank-transactions'],
  },
  {
    name: 'Plan',
    href: '/plan',
    values: ['/plan'],
  },
  {
    name: 'Invoices',
    href: '/invoices',
    values: ['/invoices'],
  },
  {
    name: 'Credit Notes',
    href: '/credit-notes',
    values: ['/credit-notes'],
  },
  {
    name: 'Customers',
    href: '/customers',
    values: ['/customers'],
  },
  {
    name: 'Credit Terms',
    href: '/credit-terms',
    values: ['/credit-terms'],
  },
  {
    name: 'Segments',
    href: '/segments',
    values: ['/segments'],
  },
  {
    name: 'Posting',
    href: '/posting/invoices?paymentStatus=PARTIALLY_PAID%2CUNPAID',
    values: [
      '/posting/credits',
      '/posting/invoices',
      '/posting/ledger/invoices',
      '/posting/ledger/credits',
      '/posting/write-offs',
    ],
    options: [
      {
        name: 'Available to Post',
        href: '/posting/invoices?paymentStatus=PARTIALLY_PAID%2CUNPAID',
        values: ['/posting/invoices', '/posting/credits'],
      },
      {
        name: 'Posted Ledger',
        href: '/posting/ledger/invoices?paymentStatus=PAID%3APaid%2CPARTIALLY_PAID%3APartially+Paid',
        values: ['/posting/ledger/invoices', '/posting/ledger/credits'],
      },
      {
        name: 'Write-Offs',
        href: '/posting/write-offs',
        values: ['/posting/write-offs'],
      },
    ],
  },
  {
    name: 'Workflows',
    href: '/workflow',
    values: ['/workflow'],
  },
  {
    name: 'Templates',
    href: '/templates',
    values: ['/templates'],
  },
  {
    name: 'Activity',
    href: '/activities',
    values: ['/activities'],
  },
  {
    name: 'Integrations',
    href: '/integrate',
    values: ['/integrate'],
  },
];

const Sidebar = ({ className }: { className?: string }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleOptionClick = (href: string) => () => {
    navigate(href);
  };

  return (
    <div className={cn('min-w-[240px] max-w-[240px] flex flex-col text-white bg-[#212121] overflow-hidden', className)}>
      <div className="w-full flex justify-center items-center px-12 py-4">
        <CompanyLogo to="/cashflow/summary" />
      </div>
      <div className="py-4 gap-1 px-2 flex flex-col flex-1 items-end overflow-scroll">
        {SidebarOptions.map((option) => (
          <Fragment key={option.name}>
            <Button
              onClick={handleOptionClick(option.href)}
              className={cn(
                'w-full rounded-none py-6 text-left justify-start hover:bg-[#2e2e2e',
                option.name.includes('Razorpay') && 'border-t',
                option.values.includes(location.pathname) &&
                  !option.name.includes('Razorpay') &&
                  'font-bold hover:bg-[#474747]',
                option.values.includes(location.pathname) && !option.name.includes('Razorpay')
                  ? ' bg-[#474747] '
                  : 'bg-[#212121]',
              )}
            >
              {option.name}
            </Button>
            {option.values.includes(location.pathname) &&
              option.options?.map((subOption) => (
                <Button
                  key={subOption.name}
                  onClick={handleOptionClick(`${subOption.href}`)}
                  className={cn(
                    'rounded-none flex justify-between py-6 text-white w-full text-left hover:bg-[#2e2e2e] bg-transparent',
                  )}
                >
                  <span
                    className={cn(
                      ' relative left-6 ',
                      subOption.values.includes(location.pathname) && 'underline font-bold',
                    )}
                  >
                    {subOption.name}
                  </span>
                  <span
                    className={cn(
                      'text-6xl relative bottom-1.5',
                      subOption.values.includes(location.pathname) ? 'block' : 'hidden',
                    )}
                  >
                    &middot;
                  </span>
                </Button>
              ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

const NavBar = ({ setIsSidebarOpen }: { setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { mutate: signout } = useSignOut({
    customConfig: {
      onError: (error: unknown) => {
        toast({
          title: 'Error occured while logging out',
          // @ts-expect-error no common error type defined
          description: error?.response ? error?.response?.data.message : 'Network Failure',
          duration: 5000,
        });
      },
    },
  });
  const handleSignOut = () => {
    signout();
  };
  return (
    <div className="border-b px-6 py-4 w-full items-center flex justify-between md:justify-end">
      <div className="cursor-pointer p-2 rounded-full block md:hidden">
        <MenuIcon onClick={() => setIsSidebarOpen(true)} height={24} width={24} />
      </div>
      <div className="ml-auto flex items-center justify-between gap-2">
        <CompanySelectBox />
        <DropdownMenu>
          <DropdownMenuTrigger>
            <UserCircleIcon height={32} width={32} />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => {
                navigate('/profile');
              }}
            >
              Profile
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleSignOut}>Sign out</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

const WithSidebarComponent = ({
  children,
  containerStyles,
}: {
  children: React.ReactNode;
  containerStyles?: string;
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <>
      <div className="w-full min-h-screen flex">
        <div className="hidden md:block">
          <Sidebar className="sticky top-0 h-screen" />
        </div>
        <div className={cn('overflow-x-hidden w-full min-h-screen flex flex-col', containerStyles)}>
          <NavBar setIsSidebarOpen={setIsSidebarOpen} />
          <div className="flex-1 flex flex-col ">
            <ErrorWrapper>{children}</ErrorWrapper>
          </div>
        </div>
      </div>
      <Sheet open={isSidebarOpen} onOpenChange={setIsSidebarOpen}>
        <SheetContent side="left" className="p-0">
          <Sidebar className="h-screen" />
        </SheetContent>
      </Sheet>
    </>
  );
};

export default WithSidebarComponent;
