'use client';

import { useCallback, useMemo } from 'react';
interface IOption {
  label: string;
  value: string;
  templateData?: IEmailTemplate;
}

import { useGetEmailTemplateSuggestions } from '@/hooks/api-hooks/useEmailTemplatesQuery';
import { IOptions } from '@/types/common.types';
import { IEmailTemplate } from '@/types/email-templates.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import debounce from 'lodash.debounce';
import { GroupBase, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';

const components = {
  DropdownIndicator: null,
};

export const EmailTemplateSelect = ({
  onChange,
  value,
  placeholder,
}: {
  placeholder: string;
  onChange: (_: IOption | undefined) => void;
  value?: IOption;
}) => {
  const { mutateAsync: fetchTemplates } = useGetEmailTemplateSuggestions({});

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false, IOptions>();
  }, []);

  const loadRoles = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IOptions, GroupBase<IOptions>>) => void) => {
      if (inputValue.length < 3) {
        callback([]);
        return;
      }

      fetchTemplates({
        query: inputValue.trim(),
      }).then((result) => {
        const options = result.data.suggestions.map((item) => ({
          value: item.id,
          label: item.name,
          templateData: {
            ...item,
          },
        }));

        callback(options);
      });
      return;
    },
    [fetchTemplates],
  );

  const debouncedLoadOwnerRoles = useMemo(() => {
    return debounce(loadRoles, 500);
  }, [loadRoles]);

  return (
    <AsyncSelect
      components={components}
      isMulti={false}
      onChange={(newValue) => {
        if (newValue === null) {
          onChange(undefined);
          return;
        }

        onChange(newValue);
      }}
      className=" text-sm min-w-[200px]  truncate text-ellipsis"
      placeholder={placeholder}
      value={value ?? null}
      loadOptions={debouncedLoadOwnerRoles}
      styles={selectStyles}
      classNames={selectClasses}
      menuPortalTarget={document.body}
    />
  );
};
