import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useDeleteOwner } from '@/hooks/api-hooks/useOwnersQuery';
import { IOwner } from '@/types/contacts.types';
// import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@radix-ui/react-tooltip';

import Owner from '@/components/owner/Owner';
import { useQueryClient } from '@tanstack/react-query';
import { EditIcon, PlusIcon, Trash2Icon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const OwnerDetails = ({
  ownerDetails,
  customerId,
  allowCreate = true,
}: {
  ownerDetails: IOwner[];
  customerId: string;
  allowCreate?: boolean;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const { mutate: deleteOwner } = useDeleteOwner({
    customerId,
    customConfig: {
      onSuccess: () => {
        queryClient.refetchQueries({
          predicate(query) {
            return query.queryKey[0] === 'getCustomerByFilters';
          },
        });
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: error?.response?.data.message || 'Unable to create internal stakeholder',
        });
      },
    },
  });

  const handleEdit = (ownerDetails: IOwner) => () => {
    navigate(`/customer/view/${customerId}?scrollTo=owner&ownerKey=${ownerDetails.id}`);
  };

  const handleAddNew = () => {
    navigate(`/customer/view/${customerId}?scrollTo=owner&ownerKey=create_new`);
  };

  const handleDelete = (ownerId: string) => () => {
    deleteOwner({ ownerId, customerId: customerId });
  };

  return (
    <div className=" flex flex-col gap-4 max-h-28 overflow-y-scroll">
      {!allowCreate && ownerDetails.length === 0 && <p className="text-sm text-muted-foreground">No Owners Found</p>}
      {ownerDetails.map((owner, index) => (
        <div key={index} className="flex items-center justify-between gap-4">
          <Owner owner={owner} />
          {!!allowCreate && (
            <div className=" flex gap-2 items-center ">
              <Button
                onClick={handleEdit(owner)}
                variant="outline"
                size="icon"
                className="w-6 h-6 flex-1 min-w-[24px] max-w-[24px]"
              >
                <EditIcon className="w-4 h-4" />
              </Button>
              <Button
                onClick={handleDelete(owner.id)}
                variant="outline"
                size="icon"
                className="w-6 h-6 flex-1 min-w-[24px] max-w-[24px]"
              >
                <Trash2Icon className="w-4 h-4" />
              </Button>
            </div>
          )}
        </div>
      ))}
      {!!allowCreate && (
        <Button
          onClick={handleAddNew}
          variant="outline"
          size="icon"
          className="w-6 h-6 flex-1 min-w-[24px] max-w-[24px]"
        >
          <PlusIcon className=" w-4 h-4 " />
        </Button>
      )}
    </div>
  );
};

export default OwnerDetails;
