export function getRazorPayIntegrationUrl(companyId: string) {
  const apiHost = import.meta.env.VITE_BACKEND_BASE_URL;
  const razorPayurl = `https://auth.razorpay.com/authorize?response_type=code&client_id=Met8270stDxDaQ&redirect_uri=${apiHost}%2Frazorpay%2Fauth&scope=read_only&state=${companyId}`;
  return razorPayurl;
}

export function getZohoIntegratonUrl() {
  const apiHost = import.meta.env.VITE_BACKEND_BASE_URL;
  return `${apiHost}/erp/auth/ZOHO`;
}
