import { Link } from 'react-router-dom';
import logo from '../../assets/Logo_DarkMode-bg-removed.png';

const CompanyLogo = ({ to }: { to?: string }) => {
  return (
    <Link to={to ? to : '/'}>
      <img style={{ width: '8rem', objectFit: 'scale-down' }} src={logo} alt="logo" />
    </Link>
  );
};

export default CompanyLogo;
