import { IReplaceRequest, Variable } from '@/types/email-templates.types';

const getTypeFromObjectName = (modelType: string) => {
  if (modelType === 'customer') {
    return 'OBJECT';
  } else if (modelType === 'invoice') {
    return 'OBJECT';
  } else if (modelType === 'templateLevelCounter') {
    return 'NUMBER';
  } else if (modelType === 'invoiceLevelCounter') {
    return 'NUMBER';
  }
  return 'STRING';
};

interface IProperty {
  name: string;
  identifierName: string;
  type: Variable['type'];
}

export const populateVariableProperties = ({
  parentObjectType,
  property,
  modelType,
  childProperties,
  availablePropertiesMap,
}: {
  parentObjectType: Variable['type'];
  childProperties: IProperty[];
  property: IProperty;
  modelType: string;
  availablePropertiesMap: Record<string, string[]>;
}): Variable | undefined => {
  if (parentObjectType === 'STRING' || parentObjectType === 'NUMBER' || parentObjectType === 'ARRAY') {
    return undefined;
  }

  const [firstChildProperty, ...rest] = childProperties;

  if (!property) {
    return undefined;
  }

  if (parentObjectType === 'OBJECT') {
    const populatedChildProperties = populateVariableProperties({
      parentObjectType: property.type,
      property: firstChildProperty,
      modelType,
      childProperties: rest,
      availablePropertiesMap,
    });

    return {
      name: property.identifierName,
      modelType: property.name,
      type: property.type,
      properties: populatedChildProperties ? [populatedChildProperties] : undefined,
    };
  }

  if (
    parentObjectType === 'MONTH' ||
    parentObjectType === 'NEXT' ||
    parentObjectType === 'PREVIOUS' ||
    parentObjectType === 'YEAR'
  ) {
    if (!availablePropertiesMap['MONTH'].includes(property.name)) {
      throw new Error(`Invalid property ${property.name} in ${parentObjectType}.${modelType}`);
    }

    const populatedChildProperties = populateVariableProperties({
      parentObjectType: property.type,
      property: firstChildProperty,
      modelType: property.name,
      childProperties: rest,
      availablePropertiesMap,
    });

    return {
      name: property.identifierName,
      modelType: property.name,
      type: 'STRING',
      properties: populatedChildProperties ? [populatedChildProperties] : undefined,
    };
  }

  if (parentObjectType === 'DATE') {
    if (!availablePropertiesMap['DATE'].includes(property.name)) {
      throw new Error(`Invalid property ${property.name} in ${parentObjectType}.${modelType}`);
    }

    const populatedChildProperties = populateVariableProperties({
      parentObjectType: property.type,
      property: firstChildProperty,
      modelType: property.name,
      childProperties: rest,
      availablePropertiesMap,
    });

    return {
      name: property.identifierName,
      modelType: property.name,
      type: 'STRING',
      properties: populatedChildProperties ? [populatedChildProperties] : undefined,
    };
  }

  return undefined;
};

const flattenObjectTypes = (
  object: Record<string, string | Record<string, string>>,
): Record<string, Variable['type']> => {
  return Object.entries(object).reduce(
    (acc, [key, value]) => {
      if (typeof value === 'object') {
        return {
          ...acc,
          ...flattenObjectTypes(value),
          [key]: 'OBJECT',
        };
      }

      return {
        ...acc,
        [key]: value as Variable['type'],
      };
    },
    {} as Record<string, Variable['type']>,
  );
};

export const getVariables = ({
  content,
  variables,
  variableTypeMap,
}: {
  content: string | undefined;
  variables: Variable[];
  variableTypeMap: {
    templateFields: Record<string, string | Record<string, string>>;
    availableAttributes: Record<string, string[]>;
  };
}) => {
  const replaceRequests = [] as IReplaceRequest[];
  const propertyToTypeMap = flattenObjectTypes(variableTypeMap.templateFields);
  const availableAttributesMap = variableTypeMap.availableAttributes;
  const allProperties = Object.values(availableAttributesMap).reduce((previousValue, currentValue) => {
    return [...previousValue, ...currentValue];
  }, [] as string[]);

  const variableRegX = /{{[^}]+[^}]+}}/g;

  const result = content?.matchAll(variableRegX);

  let current = result?.next();

  while (current?.value !== undefined) {
    const value = current.value[0].replaceAll('{{', '').replaceAll('}}', '').split('.');

    if (value.length < 2) {
      if (!propertyToTypeMap[value[0]]) {
        throw new Error(`Invalid property ${value[0]}`);
      }

      variables.push({
        name: value[0],
        type: getTypeFromObjectName(value[0]),
        modelType: value[0],
      });
      current = result?.next();
      continue;
    }

    const [objectName, ...properties] = value;

    const index = variables.findIndex((item) => item.name === objectName);

    const propertiesWithType = properties.map((item: string) => {
      const propertyType = propertyToTypeMap[item];

      if (!propertyType) {
        if (!allProperties.includes(item)) {
          throw new Error(`Invalid property ${item}`);
        }
        return {
          identifierName: item,
          name: item,
          type: item.toUpperCase() as 'MONTH' | 'NEXT' | 'PREVIOUS' | 'YEAR',
        };
      }

      return {
        identifierName: item,
        name: item,
        type: propertyType,
      };
    });

    const [firstProperty, ...childProperties] = propertiesWithType;

    if (index === -1) {
      if (variableTypeMap.templateFields[objectName]) {
        variables.push({
          name: objectName,
          type: getTypeFromObjectName(objectName),
          properties: [
            populateVariableProperties({
              parentObjectType: getTypeFromObjectName(objectName),
              childProperties: childProperties,
              property: firstProperty,
              modelType: firstProperty.name,
              availablePropertiesMap: availableAttributesMap,
            }),
          ].filter(Boolean) as Variable[],
          modelType: objectName,
        });
      }
    } else {
      const findPropertyIndex = variables?.[index]?.properties?.findLastIndex((item) =>
        item.name.includes(firstProperty.name),
      );

      if (findPropertyIndex === -1) {
        const newProperty = populateVariableProperties({
          parentObjectType: getTypeFromObjectName(objectName),
          property: firstProperty,
          modelType: firstProperty.name,
          childProperties,
          availablePropertiesMap: availableAttributesMap,
        });

        if (newProperty) {
          variables?.[index]?.properties?.push(newProperty);
        }
      } else if (findPropertyIndex !== undefined) {
        //  if the same property exists in the same object does it have different other props?
        const property = variables?.[index]?.properties?.[findPropertyIndex];

        // compare the properties and update it
        const previousPropertyNameArray = property?.name.split('_');
        let variableNumber = 1;
        let variableName = firstProperty.name;
        if (
          previousPropertyNameArray?.length &&
          previousPropertyNameArray?.length > 1 &&
          previousPropertyNameArray[1].trim()
        ) {
          variableNumber = variableNumber + Number(previousPropertyNameArray[1]);
        }
        if (previousPropertyNameArray && previousPropertyNameArray[0]?.trim()) {
          variableName = previousPropertyNameArray[0].trim();
        }

        const newPropertyName = `${variableName}_${variableNumber}`;

        const newProperty = populateVariableProperties({
          parentObjectType: getTypeFromObjectName(objectName),
          property: {
            identifierName: newPropertyName,
            name: firstProperty.name,
            type: firstProperty.type,
          },
          modelType: firstProperty.name,
          childProperties,
          availablePropertiesMap: availableAttributesMap,
        });

        if (newProperty && JSON.stringify(property?.properties) !== JSON.stringify(newProperty.properties)) {
          if (childProperties.length > 0) {
            replaceRequests.push({
              from: `{{${objectName}.${firstProperty.name}.${childProperties.map((item: IProperty) => item.name).join('.')}}}`,
              to: `{{${objectName}.${newPropertyName}.${childProperties.map((item: IProperty) => item.name).join('.')}}}`,
            });
          } else {
            replaceRequests.push({
              from: `{{${objectName}.${firstProperty.name}}}`,
              to: `{{${objectName}.${newPropertyName}}}`,
            });
          }
          variables?.[index]?.properties?.push(newProperty);
        }
      }
    }

    current = result?.next();
  }

  return {
    variables,
    replaceRequests: replaceRequests,
  };
};
