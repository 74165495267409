import { useAuthToken, useSignOut } from '@/hooks/api-hooks/useAuth';
import { ArrowRightIcon, UserCircleIcon } from 'lucide-react';
import { useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Button } from '../ui/button';
import CompanyLogo from '../ui/company-logo';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { useToast } from '../ui/use-toast';

const AppBarComponent = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { mutate: signout } = useSignOut({
    customConfig: {
      onError: (error) => {
        toast({
          title: 'Error occured while logging out',
          // @ts-expect-error no common error type
          description: error.response ? error.response.data.message : 'Network Failure',
          duration: 5000,
        });
      },
    },
  });

  const { isError, isLoading } = useAuthToken();

  const isAuthenticated = useMemo(() => !isLoading && !isError, [isError, isLoading]);
  const pathname = useLocation().pathname;

  const isHomePage = useMemo(() => pathname === '/', [pathname]);
  const showCta = useMemo(
    () => pathname === '/signin' || pathname === '/signin/' || pathname === '/invite' || pathname === '/invite/',
    [pathname],
  );

  const handleSignOut = () => {
    signout();
  };

  return (
    <nav className=" text-white h-[8vh] md:h-[9vh] justify-between px-4 md:px-8 flex items-center bg-primary">
      <Link to={isAuthenticated ? '/cashflow/summary' : '/'}>
        <CompanyLogo />
      </Link>
      {!isLoading && isAuthenticated && !isHomePage && (
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button size="icon">
              <UserCircleIcon height={32} width={32} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => {
                navigate('/profile');
              }}
            >
              Profile
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleSignOut}>Sign out</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      {!isLoading && isAuthenticated && isHomePage && (
        <Button asChild variant="secondary">
          <Link to="/cashflow/summary" className="flex items-center gap-2">
            Dashboard <ArrowRightIcon className="w-4 h-4" />
          </Link>
        </Button>
      )}
      {!isLoading && !isAuthenticated && !showCta && (
        <Button asChild variant="secondary">
          <Link to="/signin" className="flex items-center gap-2">
            Sign In <ArrowRightIcon className="w-4 h-4" />
          </Link>
        </Button>
      )}
    </nav>
  );
};

export default AppBarComponent;
