import { Card, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import {
  useGetCreditNoteSummaryByCustomerId,
  useGetPostedPlaceholdersByCustomerId,
} from '@/hooks/api-hooks/useCustomerQuery';
import { useGetCreditPlaceholders } from '@/hooks/api-hooks/usePostingQuery';
import { cn } from '@/lib/utils';
import { ICustomerInvoicesSummaryResponse } from '@/types/customer.types';
import { IInvoicesFilter } from '@/types/invoices.types';
import { IGNORED_PLACEHOLDERS } from '@/utils/constants';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { getStartAndEndDateOfFy } from '@/utils/getCurrentFyDate';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Info } from '../ui/info';

const SummaryCard = ({
  count,
  amount,
  title,
  isZero,
  isDebit = false,
  onClick,
  message,
  isInvoice = true,
  isPlaceholder = false,
}: {
  count: number;
  amount: number;
  title: React.ReactNode;
  isZero: boolean;
  isDebit?: boolean;
  onClick?: () => void;
  message: string;
  isInvoice?: boolean;
  isPlaceholder?: boolean;
}) => {
  return (
    <Card onClick={onClick} className=" md:min-w-[45%] h-[130px] min-w-full lg:min-w-[180px] cursor-pointer  ">
      <CardHeader>
        <CardTitle
          className={cn('  relative ', isDebit ? ' text-destructive ' : 'text-green-700', isZero && ' text-primary ')}
        >
          <h3 className=" capitalize leading-5">{formatCurrencyByUnit(amount, 'actual')}</h3>
          <h3 className=" leading-5 ">
            <span className=" capitalize ">{title}</span>
            <span className=" absolute -top-4 -right-4 ">
              <Info message={message ?? ''} />
            </span>
          </h3>
        </CardTitle>
        <CardDescription>
          {isInvoice ? (
            <>
              {count} {count > 1 ? 'invoices' : 'invoice'}
            </>
          ) : isPlaceholder ? (
            <>
              {count} {count > 1 ? 'posting' : 'postings'}
            </>
          ) : (
            <>
              {count} {count > 1 ? 'credit notes' : 'credit note'}
            </>
          )}
        </CardDescription>
      </CardHeader>
    </Card>
  );
};

const SummaryCardLoader = () => {
  return (
    <Card className=" md:min-w-[45%] min-w-full lg:min-w-[180px]  ">
      <CardHeader>
        <CardTitle className=" gap-1 flex flex-col ">
          <Skeleton className="w-full h-5" />
        </CardTitle>
        <CardDescription>
          <Skeleton className="w-full h-4" />
        </CardDescription>
      </CardHeader>
    </Card>
  );
};

const CustomerInvoicesAndCreditSummary = ({
  customerId,
  setFilters,
  customerName,
  isInvoiceSummaryLoading,
  invoiceSummary,
}: {
  customerId: string;
  setFilters: (_: Partial<IInvoicesFilter>, __: string) => void;
  customerName: string;
  isInvoiceSummaryLoading: boolean;
  invoiceSummary?: ICustomerInvoicesSummaryResponse;
}) => {
  const { data: creditNoteSummaryResponse, isLoading: isCreditNoteSummaryLoading } =
    useGetCreditNoteSummaryByCustomerId({
      customerId,
      customConfig: {
        enabled: !!customerId,
      },
    });

  const { data: postedPlaceholderSummaryResponse, isLoading: isPostedPlaceholderSummaryLoading } =
    useGetPostedPlaceholdersByCustomerId({
      customerId,
      customConfig: {
        enabled: !!customerId,
      },
    });

  const { data: creditPlaceholders } = useGetCreditPlaceholders();

  const unPostedCreditPlaceholders = useMemo(
    () =>
      postedPlaceholderSummaryResponse?.data
        ? creditPlaceholders?.data.filter(
            (item) => !postedPlaceholderSummaryResponse?.data.some((post) => post.placeholderName === item.name),
          )
        : creditPlaceholders?.data,
    [creditPlaceholders?.data, postedPlaceholderSummaryResponse?.data],
  );

  const navigate = useNavigate();

  const isLoading = useMemo(() => {
    return isInvoiceSummaryLoading || isCreditNoteSummaryLoading || isPostedPlaceholderSummaryLoading;
  }, [isInvoiceSummaryLoading, isCreditNoteSummaryLoading, isPostedPlaceholderSummaryLoading]);

  const handleInvoiceSummaryCardClick = (type: string) => () => {
    const invoiceTableCard = document.getElementById('invoice-details');

    switch (type) {
      case 'due': {
        setFilters(
          {
            invoiceStatus: ['DUE'],
          },
          'Due',
        );
        break;
      }
      case 'overdue': {
        setFilters(
          {
            invoiceStatus: ['OVERDUE'],
          },
          'Overdue',
        );
        break;
      }
      case 'paid': {
        const { endDate, startDate } = getStartAndEndDateOfFy();
        setFilters(
          {
            dateType: 'INVOICE_DATE',
            dateRange: { startDate: startDate, endDate: endDate },
            amountType: 'PAID',
            paymentStatus: ['PAID', 'PARTIALLY_PAID'],
          },
          'Collected in current FY',
        );
        break;
      }
      case 'total': {
        const { endDate, startDate } = getStartAndEndDateOfFy();
        setFilters(
          {
            dateType: 'INVOICE_DATE',
            dateRange: { startDate: startDate, endDate: endDate },
            amountType: 'TOTAL',
          },
          'Billed in current FY',
        );
        break;
      }
      case 'adjusted': {
        const { endDate, startDate } = getStartAndEndDateOfFy();
        setFilters(
          {
            dateType: 'INVOICE_DATE',
            dateRange: { startDate: startDate, endDate: endDate },
            amountType: 'ADJUSTED',
            fetchOnlyNonZeroAmount: true,
          },
          'Adjusted in current FY',
        );
        break;
      }
      default:
        return;
    }
    if (invoiceTableCard) {
      invoiceTableCard.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCreditNoteSummaryCardClick = () => {
    const params = new URLSearchParams({
      customers: `${customerName}:${customerId}`,
      status: 'PARTIALLY_APPLIED,NOT_APPLIED',
    });
    navigate(`/credit-notes?${params.toString()}`);
  };
  const handlePostedPlaceholderSummaryCardClick = (placeholderId: string) => {
    navigate(`/posting/ledger/credits?customers=${customerId}:${customerName}&placeholderId=${placeholderId}`);
  };

  if (isLoading) {
    return (
      <div className=" flex items-center gap-4  justify-between ">
        {new Array(6).fill(0).map((_, i) => (
          <SummaryCardLoader key={i + 'skeleton_customer' + customerId.toString()} />
        ))}
      </div>
    );
  }

  return (
    <div className=" flex items-center gap-4 justify-between overflow-x-scroll overflow-y-hidden ">
      {invoiceSummary?.data && (
        <>
          <SummaryCard
            count={invoiceSummary.data.overdue.count}
            onClick={handleInvoiceSummaryCardClick('overdue')}
            key="overdue"
            amount={invoiceSummary.data.overdue.amount}
            title="Overdue"
            isDebit={invoiceSummary.data.overdue.amount > 0}
            isZero={invoiceSummary.data.overdue.amount === 0}
            message="Sum of dues for all invoices where due date has passed"
          />
          <SummaryCard
            count={invoiceSummary.data.due.count}
            onClick={handleInvoiceSummaryCardClick('due')}
            key="due"
            amount={invoiceSummary.data.due.amount}
            title="Due"
            isDebit={invoiceSummary.data.due.amount > 0}
            isZero={invoiceSummary.data.due.amount === 0}
            message="Sum of dues for all invoices where due date has not yet passed"
          />
          <SummaryCard
            count={invoiceSummary.data.total.count}
            onClick={handleInvoiceSummaryCardClick('total')}
            key="billed"
            amount={invoiceSummary.data.total.amount}
            title="Billed in current FY"
            isDebit={invoiceSummary.data.total.amount < 0}
            isZero={invoiceSummary.data.total.amount === 0}
            message="Sum of total payable for invoices created in current FY"
          />
          <SummaryCard
            count={invoiceSummary.data.paid.count}
            onClick={handleInvoiceSummaryCardClick('paid')}
            key="collected"
            amount={invoiceSummary.data.paid.amount}
            title="Collected in current FY"
            isDebit={invoiceSummary.data.paid.amount < 0}
            isZero={invoiceSummary.data.paid.amount === 0}
            message="Sum of all amounts received for invoices created in current FY"
          />
          <SummaryCard
            count={invoiceSummary.data.adjusted.count}
            onClick={handleInvoiceSummaryCardClick('adjusted')}
            key="adjusted"
            amount={invoiceSummary.data.adjusted.amount}
            title="Adjusted in current FY"
            isDebit={invoiceSummary.data.adjusted.amount < 0}
            isZero={invoiceSummary.data.adjusted.amount === 0}
            message="Sum of all adjustments for invoices created in current FY"
          />
        </>
      )}
      {creditNoteSummaryResponse?.data && (
        <SummaryCard
          count={creditNoteSummaryResponse.data.unappliedCreditNoteCountForCustomer}
          onClick={handleCreditNoteSummaryCardClick}
          key="credit"
          amount={creditNoteSummaryResponse.data.unappliedCreditNoteAmountForCustomer}
          title="Customer Credit"
          isDebit={false}
          isInvoice={false}
          isZero={creditNoteSummaryResponse.data.unappliedCreditNoteAmountForCustomer === 0}
          message="Unapplied credit notes for this customer"
        />
      )}
      {postedPlaceholderSummaryResponse?.data &&
        postedPlaceholderSummaryResponse.data
          .filter((item) => !IGNORED_PLACEHOLDERS.includes(item.placeholderName))
          .map((postingSummary) => {
            return (
              <SummaryCard
                count={postingSummary.countPostings}
                onClick={() => {
                  handlePostedPlaceholderSummaryCardClick(postingSummary.placeholderId);
                }}
                key="postedPlaceholder"
                amount={postingSummary.postedAmount}
                title={postingSummary.placeholderName}
                isDebit={false}
                isInvoice={false}
                isPlaceholder={true}
                isZero={postingSummary.countPostings === 0}
                message={`Posted to ${postingSummary.placeholderName}`}
              />
            );
          })}
      {unPostedCreditPlaceholders &&
        unPostedCreditPlaceholders
          .filter((item) => !IGNORED_PLACEHOLDERS.includes(item.name))
          .map((placeholder) => {
            return (
              <SummaryCard
                count={0}
                onClick={() => {
                  handlePostedPlaceholderSummaryCardClick(placeholder.id);
                }}
                key="postedPlaceholder"
                amount={0}
                title={placeholder.name}
                isDebit={false}
                isInvoice={false}
                isPlaceholder={true}
                isZero={true}
                message={`Posted to ${placeholder.name}`}
              />
            );
          })}
    </div>
  );
};

export default CustomerInvoicesAndCreditSummary;
