import InvoicesLedgerTable from '@/components/posting/ledger/invoices/ledger-invoice-table';
import SwitchLedgerFlow from '@/components/posting/shared/SwitchLedgerFlow';
import { Layout } from '@/components/shared/Layout';

const LedgerInvoice = () => {
  return (
    <Layout className=" flex-1 flex flex-col ">
      <SwitchLedgerFlow current="invoice-ledger" />
      <InvoicesLedgerTable />
    </Layout>
  );
};

export default LedgerInvoice;
