import { useGetCategoriesByCompanyQuery } from '@/hooks/api-hooks/useCategoryQuery';
import { useCallback, useMemo } from 'react';

export const useGetCategory = () => {
  const { data: categories, isLoading: isLoadingCategories } = useGetCategoriesByCompanyQuery({});

  const categoriesMap = useMemo(() => {
    return (
      categories?.data.reduce(
        (acc, category) => {
          acc[category.id] = category.value;
          return acc;
        },
        {} as Record<string, string>,
      ) || {}
    );
  }, [categories]);

  const getCategoryLabel = useCallback(
    (categoryId: string) => {
      if (isLoadingCategories) return categoryId;
      if (!categoriesMap) return categoryId;
      return categoriesMap[categoryId] ?? categoryId;
    },
    [isLoadingCategories, categoriesMap],
  );

  return { getCategoryLabel };
};
