import DetailsTable from './DetailsTable';

const CashFlowDetailsView = ({
  dateFilter,
}: {
  dateFilter: {
    startDate: Date;
    endDate: Date;
  };
}) => {
  return <DetailsTable dateFilter={dateFilter} />;
};

export default CashFlowDetailsView;
