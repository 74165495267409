import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import {
  useDeleteOwner,
  useGetOwnerOrUserSuggestionsByEmail,
  useGetOwnerRoleSuggestions,
  usePostCreateOwnersForCustomer,
  usePostUpdateOwner,
} from '@/hooks/api-hooks/useOwnersQuery';
import { IOptions } from '@/types/common.types';

import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';

import { IOwner } from '@/types/contacts.types';
import debounce from 'lodash.debounce';
import { EditIcon, InfoIcon, Loader2Icon, PlusIcon, Trash2Icon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { GroupBase, OptionsOrGroups } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import type Select from 'react-select/dist/declarations/src/Select';
import { Card, CardContent, CardHeaderWithCTA, CardTitle } from '../ui/card';
import { Input as ReactSelectInput } from '../ui/react-select/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { useToast } from '../ui/use-toast';

const DivCell = ({ children }: { children: React.ReactNode }) => {
  return <div className=" px-6 min-w-[180px] w-full py-2 ">{children}</div>;
};

const DivHead = ({ children }: { children: React.ReactNode }) => {
  return <div className=" font-semibold  px-6 flex-1 min-w-[180px] py-2 bg-gray-100 ">{children}</div>;
};

const OwnerRow = ({
  ownerDetails,
  customerId,
  setIsCreate,
  isCreate,
}: {
  ownerDetails: IOwner;
  customerId: string;
  setIsCreate?: React.Dispatch<React.SetStateAction<boolean>>;
  isCreate?: boolean;
}) => {
  const [isEditing, setIsEditing] = useState(() => !!isCreate);
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultOwner: IOwner = useMemo(
    () => ({
      id: '',
      name: '',
      primaryEmail: null,
      primaryPhone: null,
      role: '',
      isRegistered: false, // or you can omit this line if you want it to default to undefined
    }),
    [],
  );

  const [selectedOwner, setSelectedOwner] = useState<IOwner | null>(defaultOwner);
  const [suggestedOwners, setSuggestedOwners] = useState<IOwner[] | null>([defaultOwner]);

  const { toast } = useToast();

  useEffect(() => {
    if (searchParams.get('ownerKey') === ownerDetails.id) {
      setIsEditing(true);
      setSearchParams(
        (prev) => {
          prev.delete('ownerKey');
          return prev;
        },
        {
          replace: true,
        },
      );
    }
  }, [searchParams, ownerDetails.id, setSearchParams]);

  const { mutate: createOwner, isPending } = usePostCreateOwnersForCustomer({
    customerId: customerId,
    customConfig: {
      onSuccess: () => {
        setIsEditing(false);
        setIsCreate?.(false);
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error?.response?.data?.message || 'Unable to create internal stakeholder',
        });
      },
    },
  });

  const { mutate: updateOwner } = usePostUpdateOwner({
    customerId: customerId,
    customConfig: {
      onSuccess: () => {
        setIsEditing(false);
        setIsCreate?.(false);
      },
      onError: () => {
        toast({
          variant: 'destructive',
          description: 'Unable to update internal stakeholder',
        });
      },
    },
  });

  const { mutate: deleteOwner } = useDeleteOwner({
    customerId: customerId,
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error?.response?.data?.message || 'Unable to delete internal stakeholder',
        });
      },
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IOwner>({
    defaultValues: {
      ...ownerDetails,
    },
  });

  const primaryEmail = watch('primaryEmail');
  const roleRef = useRef<Select<{ value: string; label: string }, false, GroupBase<IOptions>>>(null);

  const { mutateAsync: fetchRoles } = useGetOwnerRoleSuggestions({});

  const loadRoles = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IOptions, GroupBase<IOptions>>) => void) => {
      if (inputValue.length < 3) {
        callback([]);
        return;
      }

      fetchRoles({
        query: inputValue.trim(),
      })
        .then((result) => {
          const options = result.data.suggestions
            .map((item) => ({
              value: item.role || '',
              label: item.role || '',
            }))
            .filter((item) => Boolean(item.value));

          callback(options);
        })
        .catch(() => callback([]));
      return;
    },
    [fetchRoles],
  );

  const debouncedLoadTagNames = useMemo(() => {
    return debounce(loadRoles, 500);
  }, [loadRoles]);

  const handleEdit = useCallback(
    (ownerDetails: IOwner) => () => {
      setIsEditing(true);
      reset(ownerDetails);
    },
    [reset],
  );

  const handleCancel = useCallback(() => {
    reset({ id: '', name: '', primaryEmail: '', primaryPhone: '' });
    setIsCreate?.(false);
    setIsEditing(false);
  }, [reset, setIsCreate]);

  const onSubmit = (data: IOwner) => {
    if (selectedOwner) {
      data.name === '' && (data.name = selectedOwner.name);
      data.primaryPhone === '' && (data.primaryPhone = selectedOwner.primaryPhone);
    }

    if (data.id) {
      updateOwner({
        ...data,
      });
      return;
    }
    createOwner({
      ...data,
    });
  };

  const { mutateAsync: fetchOwners } = useGetOwnerOrUserSuggestionsByEmail({});
  const loadOwners = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IOptions, GroupBase<IOptions>>) => void) => {
      setValue('name', '');
      setValue('primaryPhone', '');
      if (inputValue.length < 3) {
        callback([]);
        return;
      }
      setSuggestedOwners([defaultOwner]);
      setSelectedOwner(defaultOwner);

      fetchOwners({
        emailString: inputValue.trim(),
      })
        .then((result) => {
          setSuggestedOwners(result.data.length ? result.data : null);
          const options = result.data.map((item) => ({
            value: item.primaryEmail ?? '',
            label: (item.name !== '' ? item.name : item.primaryEmail) ?? '',
          }));

          callback(options);
        })
        .catch(() => callback([]));
      return;
    },
    [fetchOwners, setValue, defaultOwner],
  );

  useEffect(() => {
    if (primaryEmail === '') {
      setValue('name', '');
      setValue('primaryPhone', '');
    }
  }, [primaryEmail, setValue]);

  const debouncedLoadOwners = useMemo(() => {
    return debounce(loadOwners, 500);
  }, [loadOwners]);

  const handleDelete = useCallback(
    (id: string) => () => {
      deleteOwner({ ownerId: id, customerId: customerId });
    },
    [deleteOwner, customerId],
  );

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false, IOptions>();
  }, []);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);
  return isEditing ? (
    <form className=" flex justify-between  " onSubmit={handleSubmit(onSubmit)}>
      <DivCell>
        <Controller
          control={control}
          name="primaryEmail"
          rules={{
            required: {
              value: true,
              message: 'Email is required',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <AsyncCreatableSelect
              isMulti={false}
              placeholder="Enter email"
              isDisabled={isEditing === true && isCreate != true}
              autoFocus={isCreate === true}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Input: ReactSelectInput,
              }}
              onChange={(item) => {
                suggestedOwners &&
                  suggestedOwners?.length &&
                  setSelectedOwner(
                    (suggestedOwners?.find((obj) => obj['primaryEmail'] == item?.value) &&
                      suggestedOwners?.find((obj) => obj['primaryEmail'] == item?.value)) ??
                      defaultOwner,
                  );
                roleRef && roleRef.current && roleRef.current.focus();
                onChange(item?.value || '');
              }}
              inputValue={value ?? ''}
              onInputChange={(newValue, action) => {
                if (action.action === 'input-change') {
                  onChange(newValue);
                }
              }}
              value={
                value?.trim()
                  ? {
                      value: value,
                      label: value,
                    }
                  : {
                      value: '',
                      label: '',
                    }
              }
              loadOptions={debouncedLoadOwners}
              menuPortalTarget={document.body}
              className=" text-sm shadow-sm w-[180px]"
              styles={selectStyles}
              classNames={selectClasses}
            />
          )}
        />
        <p className="text-red-500 text-[10px]">{!!errors.primaryEmail?.message && errors.primaryEmail.message}</p>
      </DivCell>
      <DivCell>
        {selectedOwner && selectedOwner?.name != '' ? (
          <Input
            {...register('name', {
              required: {
                value: selectedOwner?.name === '',
                message: 'Name is required',
              },
              minLength: {
                value: 3,
                message: 'Name must be at least 3 characters',
              },
              maxLength: {
                value: 100,
                message: 'Name must be less than 100 characters',
              },
            })}
            placeholder="Enter name"
            value={selectedOwner?.name}
            disabled={
              (selectedOwner?.name && selectedOwner.name !== '') || (isEditing === true && isCreate !== true)
                ? true
                : false
            }
          />
        ) : (
          <Input
            {...register('name', {
              required: {
                value: true,
                message: 'Name is required',
              },
              validate: {
                required: (value) => {
                  if (!!value || primaryEmail) {
                    return true;
                  }
                  return 'Email is required for name';
                },
              },
              minLength: {
                value: 3,
                message: 'Name must be at least 3 characters',
              },
              maxLength: {
                value: 100,
                message: 'Name must be less than 100 characters',
              },
            })}
            placeholder="Enter name"
            disabled={isEditing === true && isCreate !== true}
          />
        )}
        <p className="text-red-500 text-[10px]">{!!errors.name?.message && errors.name.message}</p>
      </DivCell>

      <DivCell>
        {selectedOwner && selectedOwner?.primaryPhone != null && selectedOwner.primaryPhone != '' ? (
          <Input
            placeholder="Enter phone"
            disabled={
              (selectedOwner?.primaryPhone && selectedOwner.primaryPhone !== '') ||
              (isEditing === true && isCreate !== true)
            }
            value={selectedOwner?.primaryPhone && selectedOwner.primaryPhone !== '' ? selectedOwner?.primaryPhone : ''}
            {...register('primaryPhone', {
              pattern: {
                value: /^[0-9]+$/i,
                message: 'Invalid phone number',
              },
            })}
          />
        ) : (
          <Input
            placeholder="Enter phone"
            {...register('primaryPhone', {
              pattern: {
                value: /^[0-9]+$/i,
                message: 'Invalid phone number',
              },
            })}
          />
        )}
        <p className="text-red-500 text-[10px]">{!!errors.primaryPhone?.message && errors.primaryPhone.message}</p>
      </DivCell>
      <DivCell>
        <Controller
          control={control}
          name="role"
          rules={{
            required: {
              value: true,
              message: 'Role is required',
            },
            minLength: {
              value: 3,
              message: 'Role must be at least 3 characters',
            },
            maxLength: {
              value: 100,
              message: 'Role must be less than 100 characters',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <AsyncCreatableSelect
              ref={roleRef}
              isMulti={false}
              autoFocus={isEditing === true && isCreate !== true}
              placeholder="Enter role"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Input: ReactSelectInput,
              }}
              onChange={(item) => {
                onChange(item?.value || '');
              }}
              inputValue={value}
              onInputChange={(newValue, action) => {
                if (action.action === 'input-change') {
                  onChange(newValue);
                }
              }}
              value={
                value?.trim()
                  ? {
                      value: value,
                      label: value,
                    }
                  : {
                      value: '',
                      label: '',
                    }
              }
              loadOptions={debouncedLoadTagNames}
              menuPortalTarget={document.body}
              className=" text-sm shadow-sm w-[180px]"
              styles={selectStyles}
              classNames={selectClasses}
            />
          )}
        />
        <p className="text-red-500 text-[10px]">{!!errors.role?.message && errors.role.message}</p>
      </DivCell>
      <DivCell>
        <div className="  flex gap-2  ">
          <Button onClick={handleCancel} variant="outline">
            Cancel
          </Button>
          {isPending ? (
            <Loader2Icon className="w-8 h-8 mt-[3px] animate-spin" />
          ) : (
            <Button disabled={isPending} onSubmit={handleSubmit(onSubmit)} type="submit">
              Save
            </Button>
          )}
        </div>
      </DivCell>
    </form>
  ) : (
    <div className=" flex justify-between ">
      <DivCell>
        <div className=" flex items-center gap-2 ">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className=" cursor-pointer text-[14px] text-ellipsis	overflow-hidden	">
                {ownerDetails.primaryEmail ? ownerDetails.primaryEmail : 'Not available'}
              </TooltipTrigger>
              <TooltipContent>{ownerDetails.primaryEmail}</TooltipContent>
            </Tooltip>
            {!ownerDetails.isRegistered && (
              <Tooltip>
                <TooltipTrigger>
                  <InfoIcon className=" w-4 h-4 " />
                </TooltipTrigger>
                <TooltipContent>The user hasn&apos;t registered yet</TooltipContent>
              </Tooltip>
            )}
          </TooltipProvider>
        </div>
      </DivCell>
      <DivCell>
        <div className=" flex items-center gap-2 ">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="overflow-hidden text-ellipsis text-[14px]">
                {ownerDetails.name ? ownerDetails.name : 'Not available'}
              </TooltipTrigger>
              <TooltipContent>{ownerDetails.name}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </DivCell>
      <DivCell>{ownerDetails.primaryPhone ? ownerDetails.primaryPhone : 'Not available'}</DivCell>
      <DivCell>
        <div className=" flex items-center gap-2 ">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="overflow-hidden text-ellipsis no-wrap whitespace-nowrap	 text-[14px]">
                {ownerDetails.role ? ownerDetails.role : 'Not available'}
              </TooltipTrigger>
              <TooltipContent>{ownerDetails.role}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </DivCell>
      <DivCell>
        {isCreate ? (
          <div className=" flex gap-2 w-full ">
            <Button onClick={handleEdit(ownerDetails)} variant="outline" className=" w-1/2 ">
              Save
            </Button>
            <Button onClick={handleCancel} className=" w-1/2 ">
              Cancel
            </Button>
          </div>
        ) : (
          <div className=" flex gap-2 ">
            <Button size="icon" onClick={handleEdit(ownerDetails)} variant="outline">
              <EditIcon className="w-4 h-4" />
            </Button>
            <Button
              size="icon"
              onClick={handleDelete(ownerDetails.id)}
              variant="outline"
              className=" text-destructive "
            >
              <Trash2Icon className="w-4 h-4" />
            </Button>
          </div>
        )}
      </DivCell>
    </div>
  );
};

const OwnerDetails = ({ ownerDetails, customerId }: { customerId: string; ownerDetails: IOwner[] }) => {
  const [isCreate, setIsCreate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('scrollTo') === 'owners') {
      document.querySelector('#owners')?.scrollIntoView({ behavior: 'smooth' });
      setSearchParams(
        (prev) => {
          prev.delete('scrollTo');
          return prev;
        },
        {
          replace: true,
        },
      );
    }
    if (searchParams.get('ownerKey') === 'create_new') {
      setIsCreate(true);
      setSearchParams(
        (prev) => {
          prev.delete('ownerKey');
          return prev;
        },
        {
          replace: true,
        },
      );
    }
  }, [searchParams, setSearchParams]);

  return (
    <Card id="owners" className=" my-8 ">
      <CardHeaderWithCTA
        className=" border-b "
        cardTitle={
          <div className="flex gap-2">
            <CardTitle className=" underline font-semibold ">Internal Stakeholders</CardTitle>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <InfoIcon className=" w-4 h-4 " />
                </TooltipTrigger>
                <TooltipContent className="text-xs">
                  These are your employees, agencies or partners who work with customers to manage receivables
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        }
        cta={
          !isCreate && (
            <Button onClick={() => setIsCreate(true)} className="flex gap-4 items-center">
              Add Internal Stakeholder
              <PlusIcon className=" w-4 h-4 " />
            </Button>
          )
        }
      />
      <CardContent className=" flex flex-col gap-4 p-6 ">
        <div className=" flex flex-col gap-4 text-sm ">
          <div className="  overflow-x-scroll ">
            <div className=" bg-gray-100 flex justify-between  ">
              <DivHead>Email</DivHead>
              <DivHead>Name</DivHead>
              <DivHead>Phone</DivHead>
              <DivHead>Role</DivHead>
              <DivHead>Actions</DivHead>
            </div>
            {ownerDetails.map((owner) => (
              <OwnerRow customerId={customerId} ownerDetails={owner} key={owner.id} />
            ))}
            {isCreate && (
              <OwnerRow
                setIsCreate={setIsCreate}
                isCreate={isCreate}
                customerId={customerId}
                ownerDetails={{ id: '', name: '', primaryEmail: '', primaryPhone: '', role: '' }}
              />
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default OwnerDetails;
