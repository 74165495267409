import { getActivitiesByFilters, getActivityById } from '@/api-functions/activity.api';
import { IActivityFilters, IGetActivitiesByFiltersResponse, IGetActivityById } from '@/types/activity.types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

interface IGetActivityFilters extends IActivityFilters {
  page: number;
  pageSize: number;
  customConfig?: Omit<UseQueryOptions<IGetActivitiesByFiltersResponse>, 'queryKey'>;
}

const ActivitiesQueryKeys = {
  getFilteredActivities: ({
    page,
    pageSize,
    activityOriginReferenceId,
    activityOriginType,
    activityType,
    customers,
    subject,
    dateRange,
    hasAttachments,
    invoiceNumber,
    email,
    deliveredStatus,
  }: Omit<IGetActivityFilters, 'customConfig'>) => [
    'getFilteredActivities',
    page,
    pageSize,
    activityOriginReferenceId,
    activityOriginType,
    activityType,
    subject,
    hasAttachments,
    invoiceNumber,
    dateRange?.from ? dayjs(dateRange.from).format('YYYY-MM-DD') : '',
    dateRange?.to ? dayjs(dateRange.to).format('YYYY-MM-DD') : '',
    ...(customers?.map((customer) => `${customer.label}:${customer.value}`) || []),
    ...(email || []),
    deliveredStatus,
  ],
  getActivityById: (activityId: string) => ['getActivityById', activityId],
};

export const useGetFilteredActivities = ({
  customConfig,
  page,
  pageSize,
  activityOriginReferenceId,
  activityOriginType,
  activityType,
  customers,
  subject,
  dateRange,
  hasAttachments,
  invoiceNumber,
  email,
  deliveredStatus,
}: IGetActivityFilters) => {
  const getActivitiesByFiltersQuery = useQuery<IGetActivitiesByFiltersResponse>({
    queryKey: ActivitiesQueryKeys.getFilteredActivities({
      page,
      pageSize,
      activityOriginReferenceId,
      activityOriginType,
      activityType,
      customers,
      subject,
      dateRange,
      hasAttachments,
      invoiceNumber,
      email,
      deliveredStatus,
    }),
    queryFn: () =>
      getActivitiesByFilters({
        page,
        pageSize,
        activityOriginReferenceId,
        activityOriginType,
        activityType,
        customers,
        subject,
        dateRange,
        hasAttachments,
        invoiceNumber,
        email,
        deliveredStatus,
      }),
    ...customConfig,
  });

  return getActivitiesByFiltersQuery;
};

export const useGetActivityById = ({ activityId }: { activityId: string }) => {
  const getActivityByIdQuery = useQuery<IGetActivityById>({
    queryKey: ActivitiesQueryKeys.getActivityById(activityId),
    queryFn: () => getActivityById({ activityId }),
  });

  return getActivityByIdQuery;
};
