import CreditNotesFilters from '@/components/credit-notes/CreditNoteFilters';
import Pagination from '@/components/shared/Pagination';
import { ColumnSelector } from '@/components/shared/ReactTableColumnSelector';
import TableSkeleton from '@/components/shared/TableSkeleton';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import Empty from '@/components/ui/empty';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { TableUnitsTabs } from '@/components/ui/table-unit-tabs';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useGetFilteredCreditNotes } from '@/hooks/api-hooks/useCreditNotesQuery';
import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { cn } from '@/lib/utils';
import {
  IAppliedInvoiceToCreditNote,
  IAttachedInvoicesToCreditNote,
  ICreditNoteListItem,
  ICreditNotesFilters,
} from '@/types/credit-notes.types';
import {
  CREDIT_NOTES_LIST_VIEW_COLUMN_ORDER_STORAGE_KEY,
  CREDIT_NOTES_LIST_VIEW_COLUMN_VISIBILITY_STORAGE_KEY,
} from '@/utils/constants/local-storage-keys';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable, VisibilityState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { GripVerticalIcon, InfoIcon } from 'lucide-react';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { TightLayout } from '../../components/shared/Layout';

type IInvoicesForCreditNote = IAppliedInvoiceToCreditNote | IAttachedInvoicesToCreditNote;

export const AppliedInvoicesHoverCard = ({
  invoices,
  children,
}: {
  invoices: IInvoicesForCreditNote[];
  children: React.ReactNode;
}) => {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>{children}</HoverCardTrigger>
      <HoverCardContent align="start">
        <ol className=" text-sm list-decimal px-6 flex flex-col gap-2">
          {invoices.map((invoice) => (
            <li key={invoice.invoiceId}>
              <Link
                key={invoice.invoiceId}
                className=" text-blue-700 underline "
                to={`/invoice/view/${invoice.invoiceId}`}
              >
                {invoice.invoiceNumber}
              </Link>
            </li>
          ))}
        </ol>
      </HoverCardContent>
    </HoverCard>
  );
};

const CreditNoteTable = ({ filters }: { filters: Partial<ICreditNotesFilters> }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data: creditNotesResponse, isLoading } = useGetFilteredCreditNotes({
    ...filters,
    page,
    limit: pageSize,
    customConfig: {},
  });
  const [unit, setUnit] = useState<'k' | 'l' | 'cr' | 'actual'>('actual');

  const columns = useMemo<ColumnDef<ICreditNoteListItem>[]>(
    () => [
      {
        accessorKey: 'creditNoteNumber',
        header: 'Number',
        id: 'creditNoteNumber',
        cell: ({ getValue, row }) => (
          <div className=" w-full text-ellipsis truncate ">
            <Link className="text-blue-700 underline" to={`/credit-note/view/${row.original.id}`}>
              {getValue() as string}
            </Link>
            <p className=" text-ellipsis truncate text-muted-foreground text-xs my-1 ">
              {row.original.lineItems?.length ? `${row.original.lineItems[0].lineItemDetails.name}` : ''}
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'customerName',
        header: 'Customer Name',
        id: 'customerName',
        cell: ({ getValue, row }) => (
          <Link className="text-blue-700 underline" to={`/customer/view/${row.original.customerId}`}>
            {getValue() as string}
          </Link>
        ),
      },
      {
        accessorKey: 'creditNoteDate',
        header: 'Date',
        id: 'creditNoteDate',
        cell: ({ getValue }) => dayjs(getValue() as string).format('DD MMM YYYY'),
      },
      {
        accessorKey: 'creditNoteType',
        header: 'Type',
        id: 'creditNoteType',
        cell: ({ getValue }) => getValue() as string,
      },
      {
        accessorKey: 'creditNoteInvoicesReferences',
        label: 'Originating Invoices',
        header: () => (
          <TooltipProvider>
            <Tooltip>
              <div className=" flex items-center gap-2 ">
                Originating Invoices
                <TooltipTrigger>
                  <InfoIcon className=" w-4 h-4 " />
                </TooltipTrigger>
                <TooltipContent>The invoice against which the credit note was created</TooltipContent>
              </div>
            </Tooltip>
          </TooltipProvider>
        ),
        id: 'creditNoteInvoicesReferences',
        cell: ({ getValue }) => {
          const invoices = getValue() as IAppliedInvoiceToCreditNote[] | null;

          if (!invoices) {
            return '-';
          }

          const length = invoices.length - 1;

          if (length < 0) {
            return '-';
          }

          if (length === 0) {
            return (
              <Link className=" text-blue-700 underline " to={`/invoice/view/${invoices[0].invoiceId}`}>
                {invoices[0].invoiceNumber}
              </Link>
            );
          }

          return (
            <span>
              <Link className=" text-blue-700 underline " to={`/invoice/view/${invoices[0].invoiceId}`}>
                {invoices[0].invoiceNumber}
              </Link>{' '}
              <AppliedInvoicesHoverCard invoices={invoices}>
                <Button variant="ghost">+ {length}</Button>
              </AppliedInvoicesHoverCard>
            </span>
          );
        },
      },
      {
        accessorKey: 'creditNoteAppliedInvoices',
        label: 'Applied Invoices',
        id: 'creditNoteAppliedInvoices',
        header: () => (
          <TooltipProvider>
            <Tooltip>
              <div className=" flex items-center gap-2 ">
                Applied Invoices
                <TooltipTrigger>
                  <InfoIcon className=" w-4 h-4 " />
                </TooltipTrigger>
                <TooltipContent>The invoice(s) to which the credit note is applied</TooltipContent>
              </div>
            </Tooltip>
          </TooltipProvider>
        ),
        cell: ({ getValue }) => {
          const invoices = getValue() as IAppliedInvoiceToCreditNote[] | null;

          if (!invoices) {
            return '-';
          }

          const length = invoices.length - 1;

          if (length < 0) {
            return '-';
          }

          if (length === 0) {
            return (
              <Link className=" text-blue-700 underline " to={`/invoice/view/${invoices[0].invoiceId}`}>
                {invoices[0].invoiceNumber}
              </Link>
            );
          }

          return (
            <span>
              <Link className=" text-blue-700 underline " to={`/invoice/view/${invoices[0].invoiceId}`}>
                {invoices[0].invoiceNumber}
              </Link>{' '}
              <AppliedInvoicesHoverCard invoices={invoices}>
                <Button variant="ghost">+ {length}</Button>
              </AppliedInvoicesHoverCard>
            </span>
          );
        },
      },
      {
        accessorKey: 'creditNoteTotal',
        header: 'Amount',
        id: 'creditNoteTotal',
        cell: ({ getValue, row }) => (
          <div>
            <p>{formatCurrencyByUnit(getValue() as number, unit)}</p>
            <p className=" text-xs text-muted-foreground ">
              Avl. To Apply: {formatCurrencyByUnit(row.original.creditNoteRemainingAmount, unit)}
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'creditNoteStatus',
        header: 'Status',
        id: 'creditNoteStatus',
        cell: ({ getValue }) => (
          <Badge className=" capitalize mx-auto ">{(getValue() as string).split('_').join(' ').toLowerCase()}</Badge>
        ),
      },
    ],
    [unit],
  );

  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);
  const defaultColumnVisibility = useMemo(() => {
    const columnVisibility = columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);

    columnVisibility['creditNoteInvoicesReferences'] = false;

    return columnVisibility;
  }, [columns]);

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    CREDIT_NOTES_LIST_VIEW_COLUMN_ORDER_STORAGE_KEY,
    defaultColumnOrder,
  );

  const { storedValue: storedColumnVisibility, setValue: setColumnVisibilityValue } = useLocalStorage(
    CREDIT_NOTES_LIST_VIEW_COLUMN_VISIBILITY_STORAGE_KEY,
    defaultColumnVisibility,
  );

  const data = useMemo(() => creditNotesResponse?.data?.docs ?? [], [creditNotesResponse]);

  const table = useReactTable({
    data,
    columns,
    columnResizeMode: 'onChange',
    onColumnVisibilityChange: setColumnVisibilityValue,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 30,
    },
    state: {
      columnVisibility: {
        ...storedColumnVisibility,
      },
      columnOrder,
    },
  });

  const handleUnitChange = (value: string) => {
    const newUnit = value as 'k' | 'l' | 'cr' | 'actual';
    setUnit(newUnit);
  };

  const sizes = table.getState().columnSizingInfo;

  const tableColumns = useMemo(() => table.getAllColumns(), [table]);

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    return headers.reduce(
      (acc, current) => {
        return {
          ...acc,
          [`--col-${current.column.id}-size`]: current.column.getSize(),
          [`--header-${current.id}-size`]: current.getSize(),
        };
      },
      {} as {
        [key: string]: number;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);

  if (isLoading) {
    return (
      <>
        <div className="flex items-center justify-end gap-4 my-4">
          <ColumnSelector columns={tableColumns} setColumnOrder={setColumnOrder} columnOrder={columnOrder} />
        </div>
        <div className=" overflow-auto ">
          <TableSkeleton rows={10} columns={tableColumns.length} />
        </div>
      </>
    );
  }

  if (!creditNotesResponse) {
    return <Empty title="No credit notes found" />;
  }

  return (
    <div className=" relative ">
      <div className="flex items-center justify-between gap-4 my-4">
        <h3 className=" text-sm ">
          <span className=" font-semibold ">Count:</span> {creditNotesResponse.data.totalDocs}
        </h3>
        <div className=" flex items-center gap-4 ">
          <ColumnSelector columns={tableColumns} setColumnOrder={setColumnOrder} columnOrder={columnOrder} />
          <TableUnitsTabs value={unit} onValueChange={handleUnitChange} />
        </div>
      </div>
      <div className=" overflow-auto ">
        <Table
          style={{
            ...columnSizeVars,
            width: table.getTotalSize(),
          }}
          className="flex-1"
        >
          {data.length === 0 && <TableCaption className="pb-10">No data found</TableCaption>}
          <TableHeader className=" bg-gray-100 ">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className="flex items-center" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      style={{
                        width: `calc(var(--header-${header?.id}-size) * 1.4px)`,
                      }}
                      className="flex items-center justify-between"
                      key={header.id}
                    >
                      <div className="break-keep truncate text-ellipsis">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                      <div className="flex gap-2">
                        {!['view'].includes(header.id) && (
                          <GripVerticalIcon
                            {...{
                              onDoubleClick: () => header.column.resetSize(),
                              onMouseDown: header.getResizeHandler(),
                              onTouchStart: header.getResizeHandler(),
                              className: cn(
                                'w-4 h-4 cursor-ew-resize',
                                header.column.getIsResizing() ? 'opacity-100' : 'opacity-50',
                              ),
                            }}
                          />
                        )}
                      </div>
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="py-4">
            {table.getRowModel().rows.map((row) => (
              <TableRow className="flex items-center" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    style={{
                      width: `calc(var(--col-${cell.column.id}-size) * 1.4px)`,
                    }}
                    className="truncate whitespace-nowrap overflow-ellipsis"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Pagination
        hasNext={creditNotesResponse?.data.hasNext}
        hasPrev={creditNotesResponse?.data.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setPageSize}
        pageNumber={page}
        rowsPerPage={pageSize}
        totalPages={creditNotesResponse?.data.totalPages || 0}
      />
    </div>
  );
};

const CreditNotesWrapper = () => {
  const [creditNoteFilters, setCreditNoteFilters] = useState<Partial<ICreditNotesFilters>>({});

  const handleSubmit = useCallback((data: Partial<ICreditNotesFilters>) => {
    setCreditNoteFilters(data);
  }, []);

  return (
    <div className="h-full overflow-scroll py-2 ">
      <CreditNotesFilters handleSubmit={handleSubmit} creditNotesFilters={creditNoteFilters} />
      <CreditNoteTable filters={creditNoteFilters} />
    </div>
  );
};

const CreditNoteListPage = () => {
  return (
    <TightLayout className=" sm:py-2 pb-8 sm:pb-8 ">
      <h1 className=" text-base font-bold absolute top-5 ">Credit Notes</h1>
      <CreditNotesWrapper />
    </TightLayout>
  );
};

export default CreditNoteListPage;
