import {
  EditPromiseToPayDate,
  EditReceivedAmount,
  WriteOffDialog,
  WriteOffStatus,
} from '@/components/invoices/InvoiceEditComponents';
import PromiseToPayDateHistory from '@/components/invoices/PromiseToPayDateHistory';
import { LabelValueDescriptionLayout } from '@/components/shared/LabelValueDescLayout';
import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { IInvoice, ITax } from '@/types/invoices.types';
import { IWriteOff } from '@/types/write-off.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { generateTaxString } from '@/utils/tax';
import { CardContent } from '@mui/material';
import dayjs from 'dayjs';

const getTaxString = (taxObject?: ITax, currencyType?: string) => {
  if (!taxObject) {
    return '';
  }

  const taxString = generateTaxString(taxObject, currencyType ?? 'INR');
  return `${formatCurrencyByUnit(taxObject.total - (Number(taxObject.tds) ?? 0), 'actual', currencyType)}${
    taxString ? ` (${taxString})` : ' (Tax)'
  }`;
};

const PaymentDetails = ({
  invoice,
  invoiceId,
  writeOffList,
  postedCreditNotes,
}: {
  invoice: IInvoice;
  invoiceId: string;
  writeOffList: IWriteOff[] | undefined;
  postedCreditNotes: {
    appliedAmount: number;
    appliedBy: string;
    appliedOn: string;
    reference: {
      creditNoteId: string;
      creditNoteNumber: string;
    };
    type: string;
    id: string;
    postingId: string;
    invoiceId: string;
  }[];
}) => {
  const postedCreditNotesSum = postedCreditNotes.reduce((sum, item) => sum + item.appliedAmount, 0);

  return (
    <Card className=" my-8 ">
      <CardHeader className=" border-b ">
        <CardTitle className=" underline font-semibold ">Payment details</CardTitle>
      </CardHeader>
      <CardContent className=" flex flex-col gap-4 ">
        <LabelValueDescriptionLayout
          label={`${invoice.invoiceType ?? ''} Total post TDS:`}
          value={
            <span className={cn(' px-4 ', invoice.invoiceTotal < 0 ? 'text-red-500' : 'text-green-700')}>
              {formatCurrencyByUnit(invoice.invoiceTotal, 'actual', invoice.invoiceCurrency)}
            </span>
          }
          description={
            <div className="w-[80%] text-ellipsis	overflow-hidden whitespace-nowrap text-left">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger className="cursor-text text-[14px]  text-ellipsis	overflow-hidden whitespace-nowrap w-[100%] text-left">
                    {invoice.discount && invoice.discount != null && !invoice.discount.isPostTax
                      ? `${
                          invoice.invoiceSubtotal > 0
                            ? formatCurrencyByUnit(invoice.invoiceSubtotal, 'actual', invoice.invoiceCurrency)
                            : 0.0
                        } (Sub total)
                                    ${
                                      Math.round(invoice.discount.amount) > 0
                                        ? '- ' +
                                          formatCurrencyByUnit(
                                            invoice.discount.amount,
                                            'actual',
                                            invoice.invoiceCurrency,
                                          )
                                        : ''
                                    } ${
                                      Math.round(invoice.discount.amount) > 0
                                        ? `(${!invoice.discount.isPostTax ? 'Pre' : 'Post'} Tax Discount) `
                                        : ''
                                    } + ${getTaxString(invoice.invoiceTax, invoice.invoiceCurrency)}`
                      : `${
                          invoice.invoiceSubtotal > 0 &&
                          formatCurrencyByUnit(invoice.invoiceSubtotal, 'actual', invoice.invoiceCurrency)
                        }
                                    + ${getTaxString(invoice.invoiceTax, invoice.invoiceCurrency)} ${
                                      Math.round(
                                        invoice.discount != null && invoice.discount.amount
                                          ? invoice.discount.amount
                                          : 0,
                                      ) > 0
                                        ? '- ' +
                                          formatCurrencyByUnit(
                                            invoice?.discount?.amount ?? 0,
                                            'actual',
                                            invoice.invoiceCurrency,
                                          )
                                        : ''
                                    } ${
                                      Math.round(
                                        invoice.discount != null && invoice.discount.amount
                                          ? invoice.discount.amount
                                          : 0,
                                      ) > 0
                                        ? `(${!invoice?.discount?.isPostTax ? 'Pre' : 'Post'} Tax Discount) `
                                        : ''
                                    }`}
                  </TooltipTrigger>
                  <TooltipContent>
                    {invoice.discount && invoice.discount != null && !invoice.discount.isPostTax
                      ? `${
                          invoice.invoiceSubtotal > 0 &&
                          formatCurrencyByUnit(invoice.invoiceSubtotal, 'actual', invoice.invoiceCurrency)
                        } (Sub total)
                                    ${
                                      Math.round(invoice.discount.amount) > 0
                                        ? '- ' +
                                          formatCurrencyByUnit(
                                            invoice.discount.amount,
                                            'actual',
                                            invoice.invoiceCurrency,
                                          )
                                        : ''
                                    } ${
                                      Math.round(invoice.discount.amount) > 0
                                        ? `(${!invoice.discount.isPostTax ? 'Pre' : 'Post'} Tax Discount) `
                                        : ''
                                    } + ${getTaxString(invoice.invoiceTax, invoice.invoiceCurrency)}`
                      : `${
                          invoice.invoiceSubtotal > 0 &&
                          formatCurrencyByUnit(invoice.invoiceSubtotal, 'actual', invoice.invoiceCurrency)
                        }
                                    + ${getTaxString(invoice.invoiceTax, invoice.invoiceCurrency)} ${
                                      Math.round(
                                        invoice.discount != null && invoice.discount.amount
                                          ? invoice.discount.amount
                                          : 0,
                                      ) > 0
                                        ? '- ' +
                                          formatCurrencyByUnit(
                                            invoice?.discount?.amount ?? 0,
                                            'actual',
                                            invoice.invoiceCurrency,
                                          )
                                        : ''
                                    } ${
                                      Math.round(
                                        invoice.discount != null && invoice.discount.amount
                                          ? invoice.discount.amount
                                          : 0,
                                      ) > 0
                                        ? `(${!invoice?.discount?.isPostTax ? 'Pre' : 'Post'} Tax Discount) `
                                        : ''
                                    }`}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          }
        />
        <LabelValueDescriptionLayout
          label="Received:"
          value={
            <EditReceivedAmount
              invoiceId={invoiceId}
              defaultValue={invoice.invoicePaidAmount}
              currencyType={invoice.invoiceCurrency}
            />
          }
          description={''}
        />
        <LabelValueDescriptionLayout
          label="Adjusted:"
          value={
            <span
              className={cn(
                ' px-4 ',
                invoice.invoiceAdjustedAmount > 0 ? 'text-destructive' : ' text-green-700 ',
                invoice.invoiceAdjustedAmount === 0 && 'text-primary',
              )}
            >
              {formatCurrencyByUnit(Math.abs(invoice.invoiceAdjustedAmount), 'actual', invoice.invoiceCurrency, false)}
            </span>
          }
          description={
            <div className=" flex items-center gap-2">
              {writeOffList?.length !== 0 && <WriteOffStatus writeOffDetails={writeOffList} invoice={invoice} />}
              {!!postedCreditNotesSum && (
                <span>
                  {formatCurrencyByUnit(postedCreditNotesSum, 'actual', invoice.invoiceCurrency, false)} (Credit Notes)
                </span>
              )}
            </div>
          }
        />
        <LabelValueDescriptionLayout
          label={invoice.invoiceOutstandingAmount >= 0 ? 'Due:' : 'Excess:'}
          value={
            <span className={cn(' px-4 ', invoice.invoiceOutstandingAmount > 0 ? 'text-red-500' : 'text-green-700')}>
              {formatCurrencyByUnit(invoice.invoiceOutstandingAmount, 'actual', invoice.invoiceCurrency)}
            </span>
          }
          description={
            <div className=" flex items-center gap-2 ">
              {writeOffList?.length === 0 && <WriteOffDialog invoiceId={invoiceId} />}
              <span>{`${formatCurrencyByUnit(invoice.invoiceTotal, 'actual', invoice.invoiceCurrency, false)} (${invoice.invoiceType ?? ''} Total post TDS) - ${formatCurrencyByUnit(invoice.invoicePaidAmount, 'actual', invoice.invoiceCurrency, false)} (Received) ${invoice.invoiceAdjustedAmount > 0 ? '-' : '+'} ${formatCurrencyByUnit(Math.abs(invoice.invoiceAdjustedAmount), 'actual', invoice.invoiceCurrency, false)} (Adjusted)`}</span>
            </div>
          }
        />
        <LabelValueDescriptionLayout
          label="Promise to pay date:"
          value={<EditPromiseToPayDate invoiceId={invoiceId} defaultValue={dayjs(invoice.promiseToPayDate).toDate()} />}
          description={
            !!invoice.metaData?.promise_to_pay_date?.length && (
              <div className=" flex items-center gap-4 ">
                <PromiseToPayDateHistory historyItems={invoice.metaData?.promise_to_pay_date || []} />
                <span className=" text-xs ">
                  Last updated on:{' '}
                  {dayjs(
                    invoice.metaData?.promise_to_pay_date
                      ?.toSorted((a, b) => dayjs(a.updatedAt).unix() - dayjs(b.updatedAt).unix())
                      .at(-1)?.updatedAt,
                  ).format('DD MMM YYYY')}
                </span>
              </div>
            )
          }
        />
      </CardContent>
    </Card>
  );
};

export default PaymentDetails;
