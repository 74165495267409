import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Link } from 'react-router-dom';

const SwitchLedgerFlow = ({ current }: { current: 'invoice-ledger' | 'credits-ledger' }) => {
  return (
    <span className="text-sm flex items-center gap-2 absolute top-0">
      <Tabs className=" my-4 " value={current}>
        <TabsList>
          <Link to="/posting/ledger/invoices?paymentStatus=PARTIALLY_PAID%2CPAID">
            <TabsTrigger value="invoice-ledger">Posted Invoices</TabsTrigger>
          </Link>
          <Link to="/posting/ledger/credits?postingStatus=PARTIALLY_POSTED%2CPOSTED">
            <TabsTrigger value="credits-ledger">Posted Credits</TabsTrigger>
          </Link>
        </TabsList>
      </Tabs>
    </span>
  );
};

export default SwitchLedgerFlow;
