import { getBankTransactionById, getCreditsForPosting } from '@/api-functions/bank-transactions.api';
import {
  createBulkPostingForCredits,
  createBulkPostingForInvoice,
  getCreditPlaceholders,
  getCreditsExport,
  getCreditsSummary,
  getInvoicesByFilters,
  getInvoicesExport,
  getInvoicesSummary,
  getPlaceholderDetail,
  getPlaceholders,
  getPostedCreditsForInvoice,
  getPostedHistoryForCredit,
  getPostedHistoryForInvoice,
  getPostedInvoiceForCredit,
  markCreditAsReversed,
  markCreditAsUnreversed,
  postDeletePosting,
  roundOffInvoices,
} from '@/api-functions/posting.api';
import { IApiResponse } from '@/types/common.types';
import {
  IFilteredInvoiceResponse,
  IInvoicesFilter,
  IInvoicesFilterForPosting,
  IInvoiceSummaryResponse,
} from '@/types/invoices.types';
import {
  ICreateBulkPostingForCreditsRequest,
  ICreateBulkPostingForInvoiceRequest,
  ICreditsFilters,
  IGetCreditApiResponse,
  IGetCreditById,
  IGetCreditsSummaryResponse,
  IGetFilteredCredits,
  IPlaceholder,
  IPostedToCreditObject,
  IPostedToInvoiceObject,
  IPostingHistory,
} from '@/types/posting.types';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const PostingQueryKeys = {
  getInvoiceByFilters: ({
    limit,
    page,
    amountType,
    invoiceNumber,
    invoiceStatus,
    maxAmount,
    minAmount,
    dateRange,
    dateType,
    customerIds,
    paymentStatus,
    fetchOnlyNonZeroAmount,
    notInCredit,
  }: Partial<IInvoicesFilter> & { page: number; limit: number; notInCredit?: string[] }) => [
    'getInvoicesByFilterForPosting',
    limit,
    page,
    amountType,
    invoiceNumber,
    maxAmount,
    minAmount,
    dateRange?.startDate?.toISOString(),
    dateRange?.endDate?.toISOString(),
    dateType,
    customerIds,
    fetchOnlyNonZeroAmount,
    ...(invoiceStatus ? invoiceStatus : []),
    ...(paymentStatus ? paymentStatus : []),
    ...(notInCredit ? notInCredit : []),
  ],
  getInvoiceSummary: ({
    limit,
    page,
    amountType,
    invoiceNumber,
    invoiceStatus,
    maxAmount,
    minAmount,
    dateRange,
    dateType,
    customerIds,
    paymentStatus,
    fetchOnlyNonZeroAmount,
  }: Partial<IInvoicesFilter> & { page: number; limit: number }) => [
    'getInvoiceSummary',
    limit,
    page,
    amountType,
    invoiceNumber,
    maxAmount,
    minAmount,
    dateRange?.startDate?.toISOString(),
    dateRange?.endDate?.toISOString(),
    dateType,
    customerIds,
    fetchOnlyNonZeroAmount,
    ...(invoiceStatus ? invoiceStatus : []),
    ...(paymentStatus ? paymentStatus : []),
  ],
  getCredits: ({
    bankAccountIds,
    companyId,
    limit,
    page,
    dateRange,
    isPostedToInvoice,
    placeholderId,
    postingStatus,
    categories,
    narration,
    notInInvoice,
    minAmount,
    maxAmount,
    customers,
  }: IGetFilteredCredits) => [
    'getCredits',
    companyId,
    limit,
    page,
    isPostedToInvoice,
    placeholderId,
    ...bankAccountIds,
    dateRange?.startDate,
    dateRange?.endDate,
    ...(postingStatus ? postingStatus : []),
    ...(categories ? categories : []),
    narration,
    ...(notInInvoice ? notInInvoice : []),
    minAmount,
    maxAmount,
    ...(customers ? customers.map((customer) => customer.value) : []),
  ],
  getCreditsSummary: ({
    bankAccountIds,
    limit,
    page,
    dateRange,
    isPostedToInvoice,
    placeholderId,
    postingStatus,
    categories,
    narration,
    minAmount,
    maxAmount,
    customerIds,
  }: IGetCreditSummaryFilter) => [
    'getCreditsSummary',
    limit,
    page,
    isPostedToInvoice,
    placeholderId,
    ...bankAccountIds,
    dateRange?.startDate,
    dateRange?.endDate,
    ...(postingStatus ? postingStatus : []),
    ...(categories ? categories : []),
    narration,
    minAmount,
    maxAmount,
    customerIds,
  ],
  getCreditsExport: ({
    bankAccountIds,
    limit,
    page,
    dateRange,
    isPostedToInvoice,
    placeholderId,
    postingStatus,
    categories,
    narration,
    minAmount,
    maxAmount,
  }: IGetCreditSummaryFilter) => [
    'getCreditsExport',
    limit,
    page,
    isPostedToInvoice,
    placeholderId,
    ...bankAccountIds,
    dateRange?.startDate,
    dateRange?.endDate,
    ...(postingStatus ? postingStatus : []),
    ...(categories ? categories : []),
    narration,
    minAmount,
    maxAmount,
  ],
  getPostingsForInvoice: ({ invoiceId }: { invoiceId: string }) => ['postedCredits', invoiceId],
  getCreditById: ({ creditId }: { creditId: string }) => ['getCreditById', creditId],
};

interface IGetFilteredCreditsWithConfig extends IGetFilteredCredits {
  customConfig?: Omit<UseQueryOptions<IGetCreditApiResponse>, 'queryKey'>;
}

export const useGetCreditedBankTransactions = ({
  page,
  limit,
  bankAccountIds,
  dateRange,
  companyId,
  isPostedToInvoice,
  placeholderId,
  customConfig,
  postingStatus,
  categories,
  narration,
  notInInvoice,
  minAmount,
  maxAmount,
  customers,
}: IGetFilteredCreditsWithConfig) => {
  const getCreditsQuery = useQuery<IGetCreditApiResponse>({
    queryKey: PostingQueryKeys.getCredits({
      bankAccountIds,
      companyId,
      limit,
      page,
      dateRange,
      isPostedToInvoice,
      placeholderId,
      postingStatus,
      categories,
      narration,
      notInInvoice,
      minAmount,
      maxAmount,
      customers,
    }),
    queryFn: () =>
      getCreditsForPosting({
        bankAccounts: bankAccountIds ? bankAccountIds : [],
        startDate: dateRange?.startDate,
        isPostedToInvoice: isPostedToInvoice,
        endDate: dateRange?.endDate,
        page: page ? page : 1,
        limit: limit ? limit : 10,
        postingStatus: postingStatus,
        categories: categories,
        narration,
        notInInvoice,
        minAmount,
        maxAmount,
        placeholderId,
        customerIds: customers?.map((customer) => customer.value).join(',') || '',
      }),
    ...customConfig,
  });

  return getCreditsQuery;
};

interface IGetCreditSummaryFilter extends ICreditsFilters {
  page: number;
  limit: number;
  customerIds?: string;
}

interface IGetCreditsSummaryWithConfig extends IGetCreditSummaryFilter {
  customConfig?: Omit<UseQueryOptions<IGetCreditsSummaryResponse>, 'queryKey'>;
}

export const useGetCreditsSummary = ({
  page,
  limit,
  bankAccountIds,
  dateRange,
  isPostedToInvoice,
  placeholderId,
  customConfig,
  postingStatus,
  categories,
  narration,
  customers,
}: IGetCreditsSummaryWithConfig) => {
  const getCreditsQuery = useQuery<IGetCreditsSummaryResponse>({
    queryKey: PostingQueryKeys.getCreditsSummary({
      bankAccountIds,
      limit,
      page,
      dateRange,
      isPostedToInvoice,
      placeholderId,
      postingStatus,
      categories,
      narration,
      customerIds: customers?.map((customer) => customer.value).join(',') || '',
    }),
    queryFn: () =>
      getCreditsSummary({
        bankAccounts: bankAccountIds ? bankAccountIds : [],
        startDate: dateRange?.startDate,
        isPostedToInvoice: isPostedToInvoice,
        endDate: dateRange?.endDate,
        page: page ? page : 1,
        limit: limit ? limit : 10,
        postingStatus: postingStatus,
        categories: categories,
        narration,
        customerIds: customers?.map((customer) => customer.value).join(',') || '',
      }),
    ...customConfig,
  });

  return getCreditsQuery;
};

export const useGetCreditById = ({
  creditId,
  customConfig,
}: {
  creditId: string;
  customConfig?: Omit<UseQueryOptions<IGetCreditById>, 'queryKey'>;
}) => {
  const getCreditByIdQuery = useQuery<IGetCreditById>({
    queryKey: PostingQueryKeys.getCreditById({ creditId }),
    queryFn: () => getBankTransactionById(creditId),
    ...customConfig,
  });

  return getCreditByIdQuery;
};

export const useGetAdvancePlaceHolder = () => {
  const getAdvancePlaceHolderQuery = useQuery<IApiResponse<IPlaceholder[]>>({
    queryKey: ['getAdvancePlaceHolder'],
    queryFn: () => getPlaceholders(),
  });

  const { data, ...otherProps } = getAdvancePlaceHolderQuery;

  const advancePlaceholder = data?.data.find((item) => item.name === 'Advance');

  return {
    ...otherProps,
    data: {
      ...data,
      data: advancePlaceholder,
    },
  };
};

export const useGetPlaceholders = () => {
  const getAllPlaceHoldersQuery = useQuery<IApiResponse<IPlaceholder[]>>({
    queryKey: ['getAllPlaceholders'],
    queryFn: () => getPlaceholders(),
    staleTime: Infinity,
  });

  return getAllPlaceHoldersQuery;
};
export const useGetCreditPlaceholders = () => {
  const getAllCreditPlaceHoldersQuery = useQuery<IApiResponse<IPlaceholder[]>>({
    queryKey: ['getCreditPlaceholders'],
    queryFn: () => getCreditPlaceholders(),
    staleTime: Infinity,
  });

  return getAllCreditPlaceHoldersQuery;
};

export const usePostCreateBulkCreditPosting = ({
  customConfig,
  batId,
}: {
  customConfig?: UseMutationOptions<
    unknown,
    AxiosError<IApiResponse<string>>,
    { request: ICreateBulkPostingForCreditsRequest }
  >;
  batId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError<IApiResponse<string>>, { request: ICreateBulkPostingForCreditsRequest }>({
    mutationFn: ({ request }: { request: ICreateBulkPostingForCreditsRequest }) =>
      createBulkPostingForCredits({ request }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ['getCredits'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getCreditById', batId],
      });
      queryClient.invalidateQueries({
        queryKey: ['postedInvoices', batId],
      });

      variables.request.invoicePostings.forEach((item) => {
        queryClient.invalidateQueries({
          queryKey: PostingQueryKeys.getPostingsForInvoice({
            invoiceId: item.invoiceId || '',
          }),
        });
      });

      queryClient.invalidateQueries({
        queryKey: ['invoicePostingHistory', batId],
      });
      queryClient.invalidateQueries({
        queryKey: ['creditPostingHistory', batId],
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoicesByFilterForPosting'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoiceByFilters'),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });
};

export const usePostCreateBulkInvoicePosting = ({
  customConfig,
  invoiceId,
}: {
  customConfig?: UseMutationOptions<
    unknown,
    AxiosError<IApiResponse<string>>,
    { request: ICreateBulkPostingForInvoiceRequest }
  >;
  invoiceId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError<IApiResponse<string>>, { request: ICreateBulkPostingForInvoiceRequest }>({
    mutationFn: ({ request }: { request: ICreateBulkPostingForInvoiceRequest }) =>
      createBulkPostingForInvoice({ request }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getCredits'),
      });
      queryClient.invalidateQueries({
        queryKey: PostingQueryKeys.getPostingsForInvoice({
          invoiceId,
        }),
      });

      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('invoicePostingHistory');
        },
      });

      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('creditPostingHistory');
        },
      });

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('postedInvoices'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoiceById'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoiceByFilters'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoicesByFilterForPosting'),
      });
      queryClient.invalidateQueries({
        queryKey: ['getInvoiceById', invoiceId],
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetPostedCreditsForInvoice = ({ invoiceId }: { invoiceId: string }) => {
  const getPostedCreditsByInvoiceIdQuery = useQuery<
    IApiResponse<IPostedToInvoiceObject[]>,
    AxiosError<IApiResponse<null>>
  >({
    queryKey: PostingQueryKeys.getPostingsForInvoice({
      invoiceId,
    }),
    queryFn: () => getPostedCreditsForInvoice({ invoiceId }),
    enabled: !!invoiceId,
    staleTime: Infinity,
  });

  return getPostedCreditsByInvoiceIdQuery;
};

export const useGetPostingHistoryForInvoice = ({ invoiceId }: { invoiceId: string }) => {
  const getPostingHistoryForInvoice = useQuery<IApiResponse<IPostingHistory[]>, AxiosError<IApiResponse<null>>>({
    queryKey: ['invoicePostingHistory', invoiceId],
    queryFn: () => getPostedHistoryForInvoice({ invoiceId }),
    enabled: !!invoiceId,
    staleTime: Infinity,
  });

  return getPostingHistoryForInvoice;
};

export const useGetPostingHistoryForCredit = ({ bat }: { bat: string }) => {
  const getPostingHistoryForInvoice = useQuery<IApiResponse<IPostingHistory[]>, AxiosError<IApiResponse<null>>>({
    queryKey: ['creditPostingHistory', bat],
    queryFn: () => getPostedHistoryForCredit({ bat }),
    enabled: !!bat,
    staleTime: Infinity,
  });

  return getPostingHistoryForInvoice;
};

export const useGetPostedInvoicesForCredits = ({ bat, placeholderId }: { bat: string; placeholderId?: string }) => {
  const getPostedInvoicesForCreditsQuery = useQuery<
    IApiResponse<IPostedToCreditObject[]>,
    AxiosError<IApiResponse<null>>
  >({
    queryKey: ['postedInvoices', bat, placeholderId],
    queryFn: () => getPostedInvoiceForCredit({ bat, placeholderId }),
    enabled: !!bat,
  });

  return getPostedInvoicesForCreditsQuery;
};

export const usePostDeletePosting = ({
  customConfig,
  invoiceId,
  creditId,
}: {
  customConfig?: UseMutationOptions<unknown, AxiosError<IApiResponse<string>>, { postingId: string }>;
  creditId?: string;
  invoiceId?: string;
}) => {
  const queryClient = useQueryClient();
  const postDeletePostingMutation = useMutation<unknown, AxiosError<IApiResponse<string>>, { postingId: string }>({
    mutationFn: ({ postingId }: { postingId: string }) => postDeletePosting(postingId),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getCredits'),
      });

      queryClient.invalidateQueries({
        queryKey: PostingQueryKeys.getCreditById({
          creditId: creditId || '',
        }),
      });
      queryClient.refetchQueries({
        queryKey: ['postedInvoices', creditId],
      });
      queryClient.invalidateQueries({
        queryKey: ['invoicePostingHistory', creditId],
      });
      queryClient.invalidateQueries({
        queryKey: ['creditPostingHistory', creditId],
      });
      queryClient.invalidateQueries({
        queryKey: ['getInvoiceSummary', creditId],
      });
      queryClient.invalidateQueries({
        queryKey: ['getCreditsSummary', creditId],
      });

      queryClient.invalidateQueries({
        queryKey: ['getInvoicesByFilterForPosting'],
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoiceByFilters'),
      });

      if (invoiceId?.trim()) {
        queryClient.invalidateQueries({
          queryKey: ['getInvoiceById', invoiceId],
        });

        queryClient.invalidateQueries({
          queryKey: PostingQueryKeys.getPostingsForInvoice({
            invoiceId: invoiceId,
          }),
        });
      }
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postDeletePostingMutation;
};
export const useGetPlaceholderDetails = ({ placeholderId }: { placeholderId: string }) => {
  const getPostedInvoicesForCreditsQuery = useQuery<IApiResponse<IPlaceholder>, AxiosError<IApiResponse<null>>>({
    queryKey: ['placeholderDetails', placeholderId],
    queryFn: () => getPlaceholderDetail({ placeholderId }),
    enabled: !!placeholderId,
  });

  return getPostedInvoicesForCreditsQuery;
};

interface IUseGetInvoicesByFiltersParams extends Partial<IInvoicesFilterForPosting> {
  page: number;
  limit: number;
  notInCredit: string[];
  customConfig?: Omit<UseQueryOptions<IFilteredInvoiceResponse>, 'queryKey'>;
}

export const useGetFilteredInvoicesForPosting = ({
  customConfig,
  page,
  limit,
  amountType,
  invoiceNumber,
  maxAmount,
  minAmount,
  dateRange,
  invoiceStatus,
  dateType,
  customers,
  paymentStatus,
  fetchOnlyNonZeroAmount,
  notInCredit,
}: IUseGetInvoicesByFiltersParams) => {
  const getFilteredInvoicesQuery = useQuery<IFilteredInvoiceResponse>({
    queryKey: PostingQueryKeys.getInvoiceByFilters({
      limit,
      page,
      amountType,
      invoiceNumber,
      invoiceStatus,
      maxAmount,
      minAmount,
      dateRange,
      dateType,
      customerIds: customers?.map((customer) => customer.value).join(',') || '',
      paymentStatus,
      fetchOnlyNonZeroAmount,
      notInCredit,
    }),
    queryFn: () =>
      getInvoicesByFilters({
        limit,
        page,
        amountType,
        invoiceNumber,
        invoiceStatus,
        maxAmount,
        minAmount,
        dateRange,
        dateType,
        customerIds: customers?.map((customer) => customer.value).join(',') || '',
        paymentStatus,
        fetchOnlyNonZeroAmount,
        notInCredit,
      }),
    ...customConfig,
  });

  return getFilteredInvoicesQuery;
};

interface IUseGetInvoicesSummaryByFiltersParams extends Partial<IInvoicesFilterForPosting> {
  page: number;
  limit: number;
  customConfig?: Omit<UseQueryOptions<IInvoiceSummaryResponse>, 'queryKey'>;
}

export const useGetInvoicesSummary = ({
  customConfig,
  page,
  limit,
  amountType,
  invoiceNumber,
  maxAmount,
  minAmount,
  dateRange,
  invoiceStatus,
  dateType,
  customers,
  paymentStatus,
  fetchOnlyNonZeroAmount,
}: IUseGetInvoicesSummaryByFiltersParams) => {
  const getInvoicesSummaryQuery = useQuery<IInvoiceSummaryResponse>({
    queryKey: PostingQueryKeys.getInvoiceSummary({
      limit,
      page,
      amountType,
      invoiceNumber,
      invoiceStatus,
      maxAmount,
      minAmount,
      dateRange,
      dateType,
      customerIds: customers?.map((customer) => customer.value).join(',') || '',
      paymentStatus,
      fetchOnlyNonZeroAmount,
    }),
    queryFn: () =>
      getInvoicesSummary({
        limit,
        page,
        amountType,
        invoiceNumber,
        invoiceStatus,
        maxAmount,
        minAmount,
        dateRange,
        dateType,
        customerIds: customers?.map((customer) => customer.value).join(',') || '',
        paymentStatus,
        fetchOnlyNonZeroAmount,
      }),
    ...customConfig,
  });

  return getInvoicesSummaryQuery;
};

export const useExportInvoices = (): UseMutationResult<IApiResponse<string>, AxiosError, Partial<IInvoicesFilter>> => {
  return useMutation<IApiResponse<string>, AxiosError, Partial<IInvoicesFilter>>({
    mutationFn: getInvoicesExport,
  });
};

export const useExportCredit = (): UseMutationResult<IApiResponse<string>, AxiosError, ICreditsFilters> => {
  return useMutation<IApiResponse<string>, AxiosError, ICreditsFilters>({
    mutationFn: getCreditsExport,
  });
};

export const usePostMarkCreditAsReversed = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<unknown, AxiosError<IApiResponse<string>>, { creditIds: string[] }>;
}) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError<IApiResponse<string>>, { creditIds: string[] }>({
    mutationFn: ({ creditIds }: { creditIds: string[] }) =>
      markCreditAsReversed({
        creditIds,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        predicate: (query) => query.queryKey.includes('getCredits'),
      });
      queryClient.refetchQueries({
        predicate(query) {
          return query.queryKey.includes('postedInvoices');
        },
      });
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('invoicePostingHistory');
        },
      });
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('creditPostingHistory');
        },
      });
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getInvoiceSummary');
        },
      });
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getInvoiceById');
        },
      });
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getInvoicesByFilterForPosting');
        },
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoiceByFilters'),
      });

      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getInvoiceById');
        },
      });

      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('postedCredits');
        },
      });

      customConfig?.onSuccess?.(data, variables, context);
    },
  });
};

export const usePostMarkCreditAsUnReversed = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<unknown, AxiosError<IApiResponse<string>>, { creditIds: string[] }>;
}) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError<IApiResponse<string>>, { creditIds: string[] }>({
    mutationFn: ({ creditIds }: { creditIds: string[] }) =>
      markCreditAsUnreversed({
        creditIds,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getCredits'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getCreditsSummary'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getCreditById'),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });
};

export const usePostRoundOffInvoices = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      ids: string[];
    }>,
    AxiosError<IApiResponse<string>>,
    { invoiceIds: string[]; thresholdAmount: number }
  >;
}) => {
  const queryClient = useQueryClient();
  return useMutation<
    IApiResponse<{
      ids: string[];
    }>,
    AxiosError<IApiResponse<string>>,
    { invoiceIds: string[]; thresholdAmount: number }
  >({
    mutationFn: ({ invoiceIds, thresholdAmount }: { invoiceIds: string[]; thresholdAmount: number }) =>
      roundOffInvoices({
        invoiceIds,
        thresholdAmount,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        predicate: (query) => query.queryKey.includes('getCredits'),
      });
      queryClient.refetchQueries({
        predicate(query) {
          return query.queryKey.includes('postedInvoices');
        },
      });
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('invoicePostingHistory');
        },
      });
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('creditPostingHistory');
        },
      });
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getInvoiceSummary');
        },
      });
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getInvoiceById');
        },
      });
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getInvoicesByFilterForPosting');
        },
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoiceByFilters'),
      });

      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getInvoiceById');
        },
      });

      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('postedCredits');
        },
      });

      customConfig?.onSuccess?.(data, variables, context);
    },
  });
};
