import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import ComboboxComponent from '@/components/ui/combobox-component';
import { Input } from '@/components/ui/input';
import SelectComponent from '@/components/ui/select-component';
import { Skeleton } from '@/components/ui/skeleton';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import SelectSegments from '@/components/workflow/define/SelectSegments';
import WorkflowLayout from '@/components/workflow/Layout';
import Tabs from '@/components/workflow/Tabs';
import {
  useGetWorkflowById,
  usePostCreateWorkflow,
  usePostInitiateWorkflow,
  usePostUpdateWorkflow,
} from '@/hooks/api-hooks/useWorkflowQuery';
import { ICreateWorkflowRequestObject } from '@/types/workflow.type';
import { daysOfTheMonth } from '@/utils/constants/month-days-map';
import { Loader2Icon } from 'lucide-react';
import { useMemo } from 'react';
import { Control, Controller, FieldErrors, useForm, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

interface IDateOption {
  label: string;
  value: number | string;
}

interface ITimeOption {
  label: string;
  value: string;
}

const LabelInputLayout = ({
  label,
  input,
  error,
}: {
  label: React.ReactNode;
  input: React.ReactNode;
  error?: string;
}) => {
  return (
    <div className=" flex flex-col gap-1 ">
      <div>{label}</div>
      <div>{input}</div>
      {error && <p className=" text-destructive h-6 text-xs ">{error}</p>}
    </div>
  );
};

const ScheduleInput = ({
  control,
  errors,
  watch,
  setValue,
}: {
  control: Control<ICreateWorkflowRequestObject, unknown>;
  errors: FieldErrors<ICreateWorkflowRequestObject>;
  watch: UseFormWatch<ICreateWorkflowRequestObject>;
  setValue: UseFormSetValue<ICreateWorkflowRequestObject>;
}) => {
  const frequency = watch('schedule.frequency');

  const dayOfTheMonthOptions = useMemo<IDateOption[]>(() => {
    return daysOfTheMonth.map((day) => {
      return {
        ...day,
        label: `on the ${day.label}`,
      };
    });
  }, []);

  const dayOfTheWeekOptions = useMemo<IDateOption[]>(() => {
    return [
      {
        label: 'Monday',
        value: 'MONDAY',
      },
      {
        label: 'Tuesday',
        value: 'TUESDAY',
      },
      {
        label: 'Wednesday',
        value: 'WEDNESDAY',
      },
      {
        label: 'Thursday',
        value: 'THURSDAY',
      },
      {
        label: 'Friday',
        value: 'FRIDAY',
      },
      {
        label: 'Saturday',
        value: 'SATURDAY',
      },
      {
        label: 'Sunday',
        value: 'SUNDAY',
      },
    ];
  }, []);

  const timeOptions = useMemo<ITimeOption[]>(() => {
    const pmArray: ITimeOption[] = new Array(11).fill(0).reduce((prev, _, index) => {
      return [
        ...prev,
        ...new Array(4).fill(0).map((_, minuteIndex) => {
          return {
            label: `${index + 1}:${String(minuteIndex * 15).padEnd(2, '0')} PM`,
            value: `${index + 1}:${String(minuteIndex * 15).padEnd(2, '0')} PM`,
          };
        }),
      ];
    }, []);

    const amArray: ITimeOption[] = new Array(11).fill(0).reduce((prev, _, index) => {
      return [
        ...prev,
        ...new Array(4).fill(0).map((_, minuteIndex) => {
          return {
            label: `${index + 1}:${String(minuteIndex * 15).padEnd(2, '0')} AM`,
            value: `${index + 1}:${String(minuteIndex * 15).padEnd(2, '0')} AM`,
          };
        }),
      ];
    }, []);

    const firstHourArray = new Array(4).fill(0).map((_, index) => {
      return {
        label: `12:${String(index * 15).padEnd(2, '0')}`,
        value: `12:${String(index * 15).padEnd(2, '0')}`,
      };
    });

    return [
      ...firstHourArray.map((hour) => ({ ...hour, label: `${hour.label} AM`, value: `${hour.value} AM` })),
      ...amArray,
      ...firstHourArray.map((hour) => ({ ...hour, label: `${hour.label} PM`, value: `${hour.value} PM` })),
      ...pmArray,
    ];
  }, []);

  const hourOfTheDay = watch('schedule.hourOfTheDay');
  const minuteOfTheHour = watch('schedule.minuteOfTheHour');

  const timeValue = useMemo(() => {
    let hour = Number(hourOfTheDay);

    if (hour > 12) {
      hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }

    return `${hour}:${String(minuteOfTheHour).padEnd(2, '0')} ${Number(hourOfTheDay) >= 12 ? 'PM' : 'AM'}`;
  }, [hourOfTheDay, minuteOfTheHour]);

  const handleTimeChange = (value: string) => {
    const [time, amOrPm] = value.split(' ');

    const [hour, minute] = time.trim().split(':');

    const adjustedHour = Number(hour) + (amOrPm === 'PM' ? 12 : 0);

    if (adjustedHour === 24) {
      setValue('schedule.hourOfTheDay', 12);
      setValue('schedule.minuteOfTheHour', Number(minute));
      return;
    }

    if (adjustedHour === 12 && amOrPm === 'AM') {
      setValue('schedule.hourOfTheDay', 0);
      setValue('schedule.minuteOfTheHour', Number(minute));
      return;
    }

    setValue('schedule.hourOfTheDay', Number(adjustedHour));
    setValue('schedule.minuteOfTheHour', Number(minute));
  };

  return (
    <div className=" flex items-center gap-2 ">
      <div className=" flex-1 ">
        <Controller
          name="schedule.frequency"
          control={control}
          render={({ field }) => (
            <SelectComponent
              placeholder="Select schedule type"
              className=" w-full flex-1 "
              onChange={field.onChange}
              value={field.value}
              options={[
                {
                  label: 'Daily',
                  value: 'DAY',
                },
                {
                  label: 'Weekly',
                  value: 'WEEK',
                },
                {
                  label: 'Monthly',
                  value: 'MONTH',
                },
              ]}
            />
          )}
        />
        <p className=" text-xs text-destructive h-6 ">{errors.schedule?.frequency?.message}</p>
      </div>
      {frequency === 'WEEK' && (
        <div className=" flex-1">
          <Controller
            name="schedule.dayOfTheWeek"
            control={control}
            render={({ field }) => (
              <SelectComponent
                className=" w-full"
                onChange={field.onChange}
                value={field.value}
                options={dayOfTheWeekOptions}
              />
            )}
          />
          <p className=" text-xs text-destructive h-6 "></p>
        </div>
      )}

      {frequency === 'MONTH' && (
        <div className=" flex-1">
          <Controller
            name="schedule.dayOfTheMonth"
            control={control}
            render={({ field }) => (
              <SelectComponent
                className=" w-full"
                onChange={field.onChange}
                value={String(field.value)}
                options={dayOfTheMonthOptions}
              />
            )}
          />
          <p className=" text-xs text-destructive h-6 "></p>
        </div>
      )}

      {(frequency === 'DAY' || frequency === 'MONTH' || frequency === 'WEEK') && (
        <div className=" flex-1">
          <ComboboxComponent
            className=" w-full"
            onChange={handleTimeChange}
            value={{
              label: timeValue,
              value: timeValue,
            }}
            options={timeOptions}
          />
          <p className=" text-xs text-destructive h-6 "></p>
        </div>
      )}
    </div>
  );
};

const DefineWorkflowForm = ({
  defaultValues,
  isEdit = false,
  workflowId,
  status,
}: {
  defaultValues?: ICreateWorkflowRequestObject;
  isEdit?: boolean;
  workflowId?: string;
  status?: 'ACTIVE' | 'DRAFT' | 'INACTIVE' | 'PAUSED';
}) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<ICreateWorkflowRequestObject>({
    values: defaultValues ?? {
      customerSegments: [],
      name: '',
      schedule: {
        frequency: 'DAY',
        dayOfTheMonth: 1,
        dayOfTheWeek: 'SUNDAY',
        hourOfTheDay: 12,
        minuteOfTheHour: 0,
      },
      workflowDescription: '',
    },
  });

  const { toast } = useToast();

  const navigate = useNavigate();

  const {
    mutateAsync: createWorkflow,
    isPending,
    data: workflowData,
  } = usePostCreateWorkflow({
    customConfig: {},
  });

  const { mutate: initiateWorkflow } = usePostInitiateWorkflow({
    customConfig: {
      onSuccess() {
        navigate(`/workflow/playground/${workflowData?.data.id}`);
      },
    },
  });

  const { mutate: updateWorkflow, isPending: isUpdatePending } = usePostUpdateWorkflow({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update workflow. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const isLoading = useMemo(() => {
    return isPending;
  }, [isPending]);

  const onSubmit = (data: ICreateWorkflowRequestObject) => {
    if (isEdit && workflowId) {
      updateWorkflow({
        fields: ['customerSegments', 'schedule', 'name', 'workflowDescription'],
        values: {
          ...data,
          schedule: {
            ...data.schedule,
            dayOfTheMonth: data.schedule.dayOfTheMonth ? Number(data.schedule.dayOfTheMonth) : 1,
            hourOfTheDay: data.schedule.hourOfTheDay ? Number(data.schedule.hourOfTheDay) : 0,
            minuteOfTheHour: data.schedule.minuteOfTheHour ? Number(data.schedule.minuteOfTheHour) : 0,
            dayOfTheWeek: data.schedule.dayOfTheWeek ?? 'MONDAY',
          },
        },
        workflowId: workflowId,
      });

      return;
    }

    createWorkflow(data).then((data) => {
      initiateWorkflow({
        workflowId: data.data.id,
        trigger: {
          conditionActionTree: {
            actions: [],
          },
          label: 'Trigger',
          workflowId: data.data.id,
        },
        exit: {
          label: 'Exit',
          workflowId: data.data.id,
          conditionActionTree: {
            actions: [],
          },
        },
      });
    });
  };

  return (
    <form id="workflow-define-form" onSubmit={handleSubmit(onSubmit)}>
      <div className=" max-w-[800px] mx-auto my-12 ">
        <Card className="">
          <CardHeader>
            <CardTitle>Workflow Details</CardTitle>
          </CardHeader>
          <CardContent className=" text-sm flex flex-col gap-8 ">
            <div className=" flex flex-col gap-4 ">
              <LabelInputLayout
                input={
                  <Input
                    {...register('name', {
                      required: {
                        value: true,
                        message: 'Workflow name is required',
                      },
                    })}
                    placeholder="Workflow name"
                    className=" placeholder:text-sm "
                  />
                }
                label="Workflow name"
                error={errors.name?.message}
              />
              <LabelInputLayout
                input={
                  <Textarea
                    {...register('workflowDescription')}
                    placeholder="Enter description"
                    className=" placeholder:text-sm "
                  />
                }
                label="Description"
                error={errors.workflowDescription?.message}
              />
            </div>
            <div className=" flex flex-col gap-4 ">
              <CardTitle className=" text-base ">Schedule Details</CardTitle>
              <LabelInputLayout
                input={<ScheduleInput setValue={setValue} control={control} errors={errors} watch={watch} />}
                label="Run every"
              />
            </div>
            <div className=" flex flex-col gap-4 ">
              <CardTitle className=" text-base ">Who is this workflow for?</CardTitle>
              <LabelInputLayout
                input={
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: 'Customer segments are required',
                      },
                    }}
                    control={control}
                    name="customerSegments"
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    render={({ field: { ref: __, ...field } }) => <SelectSegments {...field} />}
                  />
                }
                label="Customer Segments"
                error={errors.customerSegments?.message}
              />
            </div>
          </CardContent>
        </Card>
      </div>
      {!isEdit && (
        <Button disabled={isLoading} type="submit" className=" absolute top-2 right-4  ">
          Next
          {isLoading ? <Loader2Icon className="w-4 h-4 animate-spin" /> : null}
        </Button>
      )}
      {isEdit && status === 'DRAFT' && (
        <Button disabled={isUpdatePending} type="submit" className=" absolute top-2 right-4 flex gap-2 items-center  ">
          Save
          {isUpdatePending ? <Loader2Icon className="w-4 h-4 animate-spin" /> : null}
        </Button>
      )}
    </form>
  );
};

const DefineWorkflowLoading = () => {
  return (
    <form>
      <div className=" max-w-[800px] mx-auto my-12 ">
        <Card className="">
          <CardHeader>
            <CardTitle>Workflow Details</CardTitle>
          </CardHeader>
          <CardContent className=" text-sm flex flex-col gap-8 ">
            <div className=" flex flex-col gap-4 ">
              <Skeleton className=" w-28 h-4 " />
              <Skeleton className=" w-full h-7 " />
            </div>
            <div className=" flex flex-col gap-4 ">
              <Skeleton className=" w-28 h-4 " />
              <Skeleton className=" w-full h-7 " />
            </div>

            <div className=" flex flex-col gap-4 ">
              <Skeleton className=" w-28 h-4 " />
              <Skeleton className=" w-full h-7 " />
            </div>

            <div className=" flex flex-col gap-4 ">
              <Skeleton className=" w-28 h-4 " />
              <Skeleton className=" w-full h-7 " />
            </div>
          </CardContent>
        </Card>
      </div>
    </form>
  );
};

const DefinePage = () => {
  const { workflowId } = useParams();

  const {
    data: workflowsResponse,
    isLoading,
    isSuccess,
  } = useGetWorkflowById({
    workflowId: workflowId as string,
    customConfig: {
      enabled: !!workflowId,
    },
  });

  const defaultValues = useMemo<ICreateWorkflowRequestObject | undefined>(() => {
    if (workflowsResponse?.data) {
      return {
        customerSegments:
          workflowsResponse.data.customerSegments.length > 0
            ? workflowsResponse.data.customerSegments.map((item) => ({
                value: item,
                label: item,
              }))
            : [
                {
                  value: 'all',
                  label: 'All',
                },
              ],
        name: workflowsResponse.data.name,
        schedule: {
          ...workflowsResponse.data.schedule,
        },
        workflowDescription: workflowsResponse.data.workflowDescription,
      };
    }

    return undefined;
  }, [workflowsResponse?.data]);

  if (isLoading) {
    return (
      <WorkflowLayout>
        <Tabs />
        <div className=" flex-1 ">
          <DefineWorkflowLoading />
        </div>
      </WorkflowLayout>
    );
  }

  return (
    <WorkflowLayout>
      <div className=" absolute top-3 left-16 font-semibold ">
        {`${
          workflowsResponse?.data.name ? workflowsResponse?.data.name + ' [' + workflowsResponse?.data.status + ']' : ''
        }`}
      </div>
      <Tabs isEdit={isSuccess} workflowId={workflowId} />
      <div className=" flex-1 ">
        <DefineWorkflowForm
          workflowId={workflowId}
          defaultValues={defaultValues}
          isEdit={isSuccess}
          status={workflowsResponse?.data.status}
        />
      </div>
    </WorkflowLayout>
  );
};

export default DefinePage;
