import { cn } from '@/lib/utils';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
type ToolTipProp = {
  value: React.ReactNode;
  additionalClasses?: string;
  tooltipContentClassName?: string;
  children?: React.ReactNode;
};
const ToolTipCell = (props: ToolTipProp) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger
          className={cn('w-full TooltipArrow  text-ellipsis truncate text-left', props.additionalClasses)}
        >
          {props.value}
        </TooltipTrigger>
        <TooltipContent className={cn(' w-64 whitespace-break-spaces ', props.tooltipContentClassName)}>
          <p>{props.children ? props.children : props.value}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default ToolTipCell;
