import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { usePostMutationSignInWithPassword } from '@/hooks/api-hooks/useAuth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Info } from 'lucide-react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useToast } from '../ui/use-toast';

const signInFormSchema = z.object({
  email: z.string().refine((value) => /^[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(value), {
    message: 'Email entered is not valid',
  }),
  password: z.string().min(8, {
    message: 'Password entered is not valid',
  }),
});

export default function SignInForm() {
  const { toast } = useToast();
  const { mutate: signInWithPassword } = usePostMutationSignInWithPassword({
    customConfig: {
      onError: (error) => {
        toast({
          title: 'Error occured while logging in',
          description: error.response ? error.response.data.message : 'Network Failure',
          duration: 5000,
        });
      },
    },
  });

  const form = useForm({
    resolver: zodResolver(signInFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = () => {
    signInWithPassword({
      email: form.getValues().email,
      password: form.getValues().password,
    });
  };
  return (
    <Form {...form}>
      <form className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
        <h2 className=" text-2xl font-semibold mb-8 ">Sign In</h2>
        <FormField
          control={form.control}
          name="email"
          render={({ field, fieldState }) => (
            <FormItem>
              <div className="flex items-center">
                <FormLabel className="w-10">Email</FormLabel>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info width={20} height={20} fill={''} stroke="white" />
                    </TooltipTrigger>
                    <TooltipContent>Your registered email address</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <FormControl>
                <Input {...field} required={true} />
              </FormControl>
              {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field, fieldState }) => (
            <FormItem>
              <div className="flex items-center">
                <FormLabel className="w-15">Password</FormLabel>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info width={20} height={20} fill={''} stroke="white" />
                    </TooltipTrigger>
                    <TooltipContent>Your password</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <FormControl>
                <Input {...field} required={true} type="password" />
              </FormControl>
              {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
            </FormItem>
          )}
        />
        <Button type="submit" className="mt-4 w-full">
          Submit
        </Button>
      </form>
    </Form>
  );
}
