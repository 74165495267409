import ExtensionKit from '@/components/email-builder/extensions/extension-kit';
import { SlashCommandGroup } from '@/types/email-templates.types';
import { Editor } from '@tiptap/react';
import { useMemo } from 'react';
import { useGetEmailTemplateVariablesQuery } from '../api-hooks/useEmailTemplatesQuery';

export const flattenObject = (
  object: {
    [key: string]:
      | string
      | {
          [key: string]: string;
        };
  },
  prefix: string,
): SlashCommandGroup['commands'] => {
  return Object.entries(object).reduce(
    (prev, [key, value]) => {
      if (typeof value === 'string') {
        return [
          ...prev,
          {
            name: prefix ? `{{${prefix}.${key}}}` : `{{${key}}}`,
            label: prefix ? `{{${prefix}.${key}}}` : `{{${key}}}`,
            action: (editor: Editor) => {
              editor
                .chain()
                .focus()
                .insertContent(prefix ? `{{${prefix}.${key}}}` : `{{${key}}}`)
                .run();
            },
          },
        ];
      }

      return [...prev, ...flattenObject(value, key)];
    },
    [] as SlashCommandGroup['commands'],
  );
};

export const useTextExtensions = () => {
  const { data: variables, isLoading } = useGetEmailTemplateVariablesQuery({});

  return useMemo(() => {
    if (isLoading) {
      return [
        ...ExtensionKit({
          variables: [],
        }),
      ];
    }

    if (!variables?.data)
      return [
        ...ExtensionKit({
          variables: [],
        }),
      ];

    return ExtensionKit({
      variables: [
        {
          name: 'Variables',
          title: 'Variables',
          commands: flattenObject(variables.data.templateFields, ''),
        },
      ],
    });
  }, [isLoading, variables]);
};
