import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import {
  useGetConditionById,
  useGetStateById,
  usePostCreateCondition,
  usePostUpdateCondition,
  usePostUpdateConditionalState,
  usePostUpdateTriggerState,
  usePostUpdateWaitDateCondition,
  usePostUpdateWaitState,
} from '@/hooks/api-hooks/useWorkflowQuery';
import { useWorkflowPropertiesStore } from '@/stores/workflow/state-properties.store';
import {
  ICondition,
  IConditionalStateProperties,
  IConditionRule,
  IDateCondition,
  IState,
  IStatementCondition,
  ITriggerStateProperties,
  StateTypes,
} from '@/types/workflow.type';
import { createId } from '@/utils/createId';
import { validatePositiveNumber } from '@/utils/validatePositiveNumber';
import { convertConditionsToRequestObjects } from '@/utils/workflow/convertConditionsToRequestObjects';
import { Loader2Icon, XIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { FieldErrors, useForm, UseFormRegister } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useNodes, useReactFlow } from 'reactflow';
import EditCondition from '../state-blocks/EditConditions';
import EditDateCondition from '../state-blocks/wait-block/EditDateCondition';
import { ActionPropertiesSlot } from './actions/ActionPropertiesWrapper';

const PropertiesLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className=" p-4  w-[340px] border-l text-sm overflow-scroll h-full flex-1 flex flex-col ">{children}</div>
  );
};

const StateLabelInput = ({
  register,
  errors,
}: {
  register: UseFormRegister<{
    label: string;
  }>;
  errors: FieldErrors<{
    label: string;
  }>;
}) => {
  return (
    <div className=" flex flex-col gap-2 ">
      <h2>Label</h2>
      <Input {...register('label')} placeholder="Enter label" />
      <p className="text-destructive text-xs h-6">{errors.label?.message}</p>
    </div>
  );
};

const getRelativeDate = ({ dateCondition }: { dateCondition: IDateCondition }) => {
  if (!dateCondition.waitTillDelta) {
    return 'today';
  }

  if (dateCondition.waitTillDelta > 0) {
    return 'nDaysAgo';
  }

  if (dateCondition.waitTillDelta < 0) {
    return 'nDaysLater';
  }

  if (dateCondition.waitTillValue) {
    return 'custom';
  }

  return 'today';
};

const ConditionProperties = ({
  stateId,
  defaultConditionId,
  catIndex,
}: {
  stateId: string;
  defaultConditionId: string;
  catIndex?: number;
}) => {
  const [conditionId, setConditionId] = useState<string>(defaultConditionId);

  const { data: conditionResponse, isLoading } = useGetConditionById({
    conditionId,
    customConfig: {
      enabled: !!conditionId,
    },
  });

  const { data: stateResponse, isLoading: isStateLoading } = useGetStateById({
    stateId,
    customConfig: {
      enabled: !!stateId,
    },
  });

  useEffect(() => {
    setConditionId(defaultConditionId);
  }, [defaultConditionId]);

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<{
    label: string;
  }>({
    values: {
      label: stateResponse?.data.label || '',
    },
    mode: 'onChange',
  });

  const { toast } = useToast();

  const reactflowInstance = useReactFlow<IState<StateTypes>>();

  const { mutate: updateTriggerState, isPending: isTriggerStateUpdateLoading } = usePostUpdateTriggerState({
    customConfig: {
      onSuccess(_, variables) {
        reactflowInstance.setNodes((nds) => {
          return nds.map((node) => {
            if (node.id === stateId) {
              return {
                ...node,
                data: {
                  ...node.data,
                  label: variables.values.label || '',
                },
              };
            }
            return node;
          });
        });
      },

      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update trigger state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateConditionalState, isPending: isConditionalStateUpdateLoading } = usePostUpdateConditionalState({
    workflowId: stateResponse?.data.workflowId || '',
    customConfig: {
      onSuccess(_, variables) {
        reactflowInstance.setNodes((nds) => {
          return nds.map((node) => {
            if (node.id === stateId) {
              return {
                ...node,
                data: {
                  ...node.data,
                  label: variables.values.label || '',
                },
              };
            }
            return node;
          });
        });
      },
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update conditional state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateWaitState, isPending: isWaitStateUpdateLoading } = usePostUpdateWaitState({
    customConfig: {
      onSuccess(_, variables) {
        reactflowInstance.setNodes((nds) => {
          return nds.map((node) => {
            if (node.id === stateId) {
              return {
                ...node,
                data: {
                  ...node.data,
                  label: variables.values.label || '',
                },
              };
            }
            return node;
          });
        });
      },
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update wait state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateCondition, isPending: isConditionUpdateLoading } = usePostUpdateCondition({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update condition. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateWaitCondition, isPending: isWaitConditionUpdateLoading } = usePostUpdateWaitDateCondition({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update condition. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const workflowId = useParams().workflowId as string;

  const [isCreate, setIsCreate] = useState(false);

  const { mutateAsync: createCondition, isPending: isCreateLoading } = usePostCreateCondition({
    customConfig: {
      onSuccess: () => {
        setIsCreate(false);
      },
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to create condition. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { setSelectedProperties } = useWorkflowPropertiesStore();

  const handleClose = () => {
    setSelectedProperties(undefined);
  };

  const isMutationPending = useMemo(() => {
    return (
      isCreateLoading ||
      isTriggerStateUpdateLoading ||
      isConditionalStateUpdateLoading ||
      isConditionUpdateLoading ||
      isWaitConditionUpdateLoading ||
      isWaitStateUpdateLoading
    );
  }, [
    isCreateLoading,
    isConditionalStateUpdateLoading,
    isTriggerStateUpdateLoading,
    isConditionUpdateLoading,
    isWaitConditionUpdateLoading,
    isWaitStateUpdateLoading,
  ]);

  const [conditions, setConditions] = useState<ICondition[]>([]);
  const [dateCondition, setDateCondition] = useState<IDateCondition>({
    comparisonOperator: '==',
    dateOperator: 'DIFF_DAYS',
    type: 'DATE_TYPE',
    conditionType: 'wait-for',
  });

  useEffect(() => {
    if (conditionResponse?.data.rule.type === 'DATE_TYPE') {
      setDateCondition({
        ...conditionResponse.data.rule,
        relativeDate: getRelativeDate({ dateCondition: conditionResponse.data.rule }),
        waitTillDelta:
          conditionResponse.data.rule.waitTillDelta && !isNaN(Number(conditionResponse.data.rule.waitTillDelta))
            ? Math.abs(conditionResponse.data.rule.waitTillDelta)
            : conditionResponse.data.rule.waitTillDelta,
        conditionType: conditionResponse.data.rule.waitFor ? 'wait-for' : 'wait-till',
      } as IDateCondition);
      return;
    }

    if (conditionResponse?.data) {
      setConditions([conditionResponse.data]);
      setIsCreate(false);
    } else {
      setIsCreate(true);
      setConditions([
        {
          id: createId(),
          workflowId: workflowId,
          rule: {
            type: 'AND_TYPE',
            childConditions: [
              {
                type: 'STATEMENT_TYPE',
              },
            ],
          },
        },
      ]);
    }
  }, [conditionResponse?.data, workflowId]);

  const handleSave = async () => {
    if (errors.label) {
      return;
    }

    if (conditionResponse?.data.rule.type === 'DATE_TYPE') {
      let waitTillDelta = 0;
      const waitTillValue = null;

      if (dateCondition.relativeDate === 'nDaysLater') {
        waitTillDelta = -1 * Number(dateCondition.waitTillDelta);
      }

      if (dateCondition.relativeDate === 'nDaysAgo') {
        waitTillDelta = Number(dateCondition.waitTillDelta);
      }

      if (dateCondition.conditionType === 'wait-for') {
        updateWaitCondition({
          conditionId: conditionId,
          rule: {
            ...dateCondition,
            comparisonOperator: '==',
            dateOperator: 'DIFF_DAYS',
            waitFor: validatePositiveNumber(dateCondition.waitFor || 0) ? 1 : dateCondition.waitFor,
            waitTillDelta: undefined,
            waitTillValue: undefined,
            type: 'DATE_TYPE',
            waitTillPlaceholder: undefined,
          },
        });
      } else {
        updateWaitCondition({
          conditionId: conditionId,
          rule: {
            ...dateCondition,
            comparisonOperator: '==',
            dateOperator: 'DIFF_DAYS',
            waitTillDelta,
            waitTillValue: waitTillValue ?? undefined,
            type: 'DATE_TYPE',
            waitTillPlaceholder: dateCondition.waitTillPlaceholder,
            waitFor: 0,
          },
        });
      }

      if (stateResponse?.data.stateType === 'WAIT') {
        updateWaitState({
          fields: ['label'],
          values: {
            label: watch('label'),
          },
          stateId: stateId,
        });
      }

      return;
    }

    if (!conditions.length) {
      toast({
        description: 'Please add at least one condition',
        variant: 'destructive',
      });
      return;
    }

    if (isCreate) {
      const result = await createCondition({
        workflowId,
        rule: convertConditionsToRequestObjects(conditions[0].rule as IConditionRule | IStatementCondition),
      }).catch((error) => {
        if (stateResponse?.data.stateType === 'TRIGGER') {
          updateTriggerState({
            fields: ['label'],
            values: {
              label: watch('label'),
            },
            stateId: stateId,
          });
          return;
        }

        if (stateResponse?.data.stateType === 'CONDITIONAL') {
          updateConditionalState({
            fields: ['label'],
            values: {
              label: watch('label'),
            },
            stateId: stateId,
          });
        }

        return error;
      });

      setConditionId(result.data.id);

      if (stateResponse?.data.stateType === 'TRIGGER') {
        updateTriggerState({
          fields: ['conditionActionTree', 'label'],
          values: {
            conditionActionTree: {
              ...(stateResponse?.data.description as ITriggerStateProperties).conditionActionTree,
              condition: result.data.id,
            },
            label: watch('label'),
          },
          stateId: stateId,
        });
        return;
      }

      if (stateResponse?.data.stateType === 'CONDITIONAL') {
        updateConditionalState({
          fields: ['conditionActionTrees', 'label'],
          values: {
            conditionActionTrees: (
              stateResponse?.data.description as IConditionalStateProperties
            ).conditionActionTrees.map((cat, index) => {
              if (catIndex === index) {
                return {
                  ...cat,
                  condition: result.data.id,
                };
              }

              return cat;
            }),
            label: watch('label'),
          },
          stateId: stateId,
        });
      }

      return;
    }

    updateCondition({
      conditionId: conditionId,
      rule: convertConditionsToRequestObjects(conditions[0].rule as IConditionRule | IStatementCondition),
    });

    if (stateResponse?.data.stateType === 'TRIGGER') {
      updateTriggerState({
        fields: ['label'],
        values: {
          label: watch('label'),
        },
        stateId: stateId,
      });
      return;
    }

    if (stateResponse?.data.stateType === 'CONDITIONAL') {
      updateConditionalState({
        fields: ['label'],
        values: {
          label: watch('label'),
        },
        stateId: stateId,
      });
    }

    if (stateResponse?.data.stateType === 'WAIT') {
      updateWaitState({
        fields: ['label'],
        values: {
          label: watch('label'),
        },
        stateId: stateId,
      });
    }
  };

  if (isLoading || isStateLoading) {
    return (
      <PropertiesLayout>
        <div className=" justify-end flex items-center ">
          <Button onClick={handleClose} variant="outline" size="icon">
            <XIcon className="w-4 h-4" />
          </Button>
        </div>
        <div className=" flex flex-col gap-2 mb-2">
          <Skeleton className=" w-32  h-4" />
          <Skeleton className=" w-full h-7" />
        </div>
        <div className="capitalize my-2">Configure conditions for {stateResponse?.data.label}</div>
        <div className=" flex flex-col gap-2">
          <Skeleton className=" w-32  h-4" />
          <Skeleton className=" w-full h-7" />
        </div>
        <div className=" flex justify-end border-t py-2 mt-auto ">
          <Button disabled className=" flex items-center gap-2 ">
            Save
            {isMutationPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
          </Button>
        </div>
      </PropertiesLayout>
    );
  }

  if (!conditionResponse) {
    return (
      <PropertiesLayout>
        <div className=" justify-end flex items-center ">
          <Button onClick={handleClose} variant="outline" size="icon">
            <XIcon className="w-4 h-4" />
          </Button>
        </div>
        <StateLabelInput register={register} errors={errors} />
        <div className="capitalize my-2">Configure conditions for {stateResponse?.data.label}</div>
        <div className=" flex-1 overflow-scroll ">
          <EditCondition conditions={conditions} setConditions={setConditions} />
        </div>
        <div className=" flex justify-end border-t py-2 ">
          <Button disabled={isMutationPending} onClick={handleSave} className=" flex items-center gap-2 ">
            Save
            {isMutationPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
          </Button>
        </div>
      </PropertiesLayout>
    );
  }

  if (conditionResponse.data.rule.type === 'DATE_TYPE') {
    return (
      <PropertiesLayout>
        <div className=" justify-end flex items-center ">
          <Button onClick={handleClose} variant="outline" size="icon">
            <XIcon className="w-4 h-4" />
          </Button>
        </div>
        <StateLabelInput register={register} errors={errors} />
        <div className="capitalize my-2">Configure conditions for {stateResponse?.data.label}</div>
        <div className=" flex-1 overflow-scroll ">
          <EditDateCondition dateCondition={dateCondition} setDateCondition={setDateCondition} />
        </div>
        <div className=" flex justify-end py-2 border-t ">
          <Button disabled={isMutationPending} onClick={handleSave} className=" flex items-center gap-2 ">
            {isMutationPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
            Save
          </Button>
        </div>
      </PropertiesLayout>
    );
  }

  return (
    <PropertiesLayout>
      <div className=" justify-end flex items-center ">
        <Button onClick={handleClose} variant="outline" size="icon">
          <XIcon className="w-4 h-4" />
        </Button>
      </div>
      <StateLabelInput register={register} errors={errors} />
      <div className="capitalize my-2">Configure conditions for {stateResponse?.data.label}</div>
      <div className=" flex-1 overflow-scroll ">
        <EditCondition conditions={conditions} setConditions={setConditions} />
      </div>
      <div className=" flex justify-end py-2 border-t ">
        <Button disabled={isMutationPending} onClick={handleSave} className=" flex items-center gap-2 ">
          {isMutationPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
          Save
        </Button>
      </div>
    </PropertiesLayout>
  );
};

const Properties = () => {
  const { selectedProperties } = useWorkflowPropertiesStore();
  const nodes = useNodes<IState<StateTypes>>();

  if (!selectedProperties) return null;

  const currentNode = nodes.find((node) => node.id === selectedProperties.nodeId);

  if (!currentNode) return null;

  if (selectedProperties.updateType === 'action') {
    return <ActionPropertiesSlot actionId={selectedProperties.actionId || ''} />;
  }

  if (selectedProperties.updateType === 'condition') {
    return (
      <ConditionProperties
        defaultConditionId={selectedProperties.conditionId || ''}
        stateId={selectedProperties.nodeId}
        catIndex={selectedProperties.catIndex}
      />
    );
  }

  return <div className=" w-[340px] border-l p-3 ">Properties</div>;
};

export default Properties;
