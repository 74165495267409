/* eslint-disable no-unused-vars */
import TableSkeleton from '@/components/shared/TableSkeleton';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { cn } from '@/lib/utils';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ArrowDownUp, GripVerticalIcon } from 'lucide-react';
import { Badge } from '../../ui/badge';

import Pagination from '@/components/shared/Pagination';
import { ColumnSelector } from '@/components/shared/ReactTableColumnSelector';
import { Skeleton } from '@/components/ui/skeleton';
import { useGetFilteredInvoicesForPosting, useGetInvoicesSummary } from '@/hooks/api-hooks/usePostingQuery';
import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { IInvoice, IInvoicesFilter, IInvoicesFilterForPosting } from '@/types/invoices.types';
import {
  POSTING_ALL_INVOICES_COLUMN_ORDER_STORAGE_KEY,
  POSTING_ALL_INVOICES_COLUMN_VISIBILITY_STORAGE_KEY,
} from '@/utils/constants/local-storage-keys';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InvoicesFilters from './InvoicesFilters';

const InvoicesDetails = ({
  filters,
  page,
  limit,
}: {
  filters: Partial<IInvoicesFilter>;
  page: number;
  limit: number;
}) => {
  const { data: invoicesSummary, isLoading } = useGetInvoicesSummary({
    ...filters,
    page: page,
    limit: limit,
    customConfig: {},
  });

  if (isLoading) {
    return (
      <>
        <h2 className=" ">
          <div className="flex gap-2">
            <span className=" text-lg font-semibold">Invoices</span>
            <Skeleton className=" h-4 w-[400px] mt-2" />
          </div>
        </h2>
        <p className=" text-sm text-muted-foreground mb-2 ">Select invoices to match</p>
      </>
    );
  }

  return (
    <>
      <h2 className=" text-lg font-semibold   ">
        Invoices{' '}
        <span className=" text-sm font-normal ">
          (Count: {invoicesSummary?.data.count}, Due/Overdue:{' '}
          {formatCurrencyByUnit(invoicesSummary?.data.totalRemaining || 0, 'actual')}, Total:{' '}
          {formatCurrencyByUnit(invoicesSummary?.data.totalValue || 0, 'actual')})
        </span>
      </h2>
      <p className=" text-sm text-muted-foreground mb-2 ">Select invoices to match</p>
    </>
  );
};

const InvoicesPostingTable = () => {
  const [invoiceFilters, setInvoiceFilters] = useState<Partial<IInvoicesFilterForPosting>>({
    paymentStatus: ['PARTIALLY_PAID', 'UNPAID'],
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const handleSubmit = useCallback(
    (filters: Partial<IInvoicesFilter>) => {
      setInvoiceFilters(filters);
    },
    [setInvoiceFilters],
  );

  const { data: invoicesResponse, isLoading } = useGetFilteredInvoicesForPosting({
    page: page,
    limit: pageLimit,
    ...invoiceFilters,
    notInCredit: [],
    customConfig: {},
  });
  const columns = useMemo<ColumnDef<IInvoice>[]>(() => {
    return [
      {
        header: 'Number',
        id: 'invoiceNumber',
        accessorKey: 'invoiceNumber',
        minSize: 30,
        cell: ({ row, getValue }) => {
          return (
            <div className=" w-full ">
              <ToolTipCell value={getValue() as string} additionalClasses="pointer " />
              <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
                {row.original.lineItems?.at(0)?.lineItemDetails?.name}
              </div>
            </div>
          );
        },
      },
      {
        header: 'Type',
        id: 'invoiceType',
        accessorKey: 'invoiceType',
        minSize: 30,
        cell: ({ getValue }) => {
          return (
            <div className=" w-full ">
              <ToolTipCell value={getValue() as string} additionalClasses="pointer " />
            </div>
          );
        },
      },
      {
        header: 'Customer',
        id: 'customerName',
        minSize: 30,
        accessorKey: 'customerName',
        cell: ({ getValue }) => {
          return (
            <div className=" w-full ">
              <ToolTipCell value={getValue() as string} additionalClasses="pointer  " />
            </div>
          );
        },
      },
      {
        header: 'Invoice Date',
        id: 'invoiceDate',
        minSize: 30,
        accessorKey: 'invoiceDate',
        cell: ({ getValue }) => dayjs(getValue() as string).format('DD MMM YYYY'),
      },
      {
        header: 'Due Date',
        id: 'invoiceDueDate',
        minSize: 30,
        accessorKey: 'invoiceDueDate',
        cell: ({ getValue }) => (
          <div className=" w-full ">
            <div className="w-full truncate text-ellipsis ">{dayjs(getValue() as string).format('DD MMM YYYY')}</div>
          </div>
        ),
      },
      {
        header: 'Due/OverDue',
        id: 'invoiceOutstandingAmount',
        minSize: 30,
        accessorKey: 'invoiceOutstandingAmount',
        cell: ({ getValue, row }) => (
          <div className=" w-full flex flex-col gap-1 ">
            <div className="w-full truncate text-ellipsis ">
              {formatCurrencyByUnit(getValue() as number, 'actual', row.original.invoiceCurrency)}
            </div>
            <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
              Total: {formatCurrencyByUnit(row.original.invoiceTotal, 'actual', row.original.invoiceCurrency)}
            </div>
          </div>
        ),
      },
      {
        header: 'Payment Status',
        id: 'paymentStatus',
        minSize: 30,
        accessorKey: 'paymentStatus',
        cell: ({ getValue }) => (
          <div className=" w-full flex flex-col gap-1 ">
            <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
              <Badge
                className={cn(
                  getValue() === 'PAID' && 'bg-green-700 hover:bg-green-700 hover:text-white text-white',
                  getValue() === 'UNPAID' && 'bg-red-500 text-white hover:bg-red-500 hover:text-white',
                  getValue() === 'PARTIALLY_PAID' && 'bg-yellow-400 text-black hover:bg-yellow-400',
                  'capitalize',
                )}
              >
                {(getValue() as string).split('_').join(' ').toLowerCase()}
              </Badge>
            </div>
          </div>
        ),
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return invoicesResponse?.data.docs || [];
  }, [invoicesResponse]);

  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);
  const defaultColumnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const { storedValue: columnVisibility, setValue: setColumnVisibility } = useLocalStorage(
    POSTING_ALL_INVOICES_COLUMN_VISIBILITY_STORAGE_KEY,
    defaultColumnVisibility,
  );

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    POSTING_ALL_INVOICES_COLUMN_ORDER_STORAGE_KEY,
    defaultColumnOrder,
  );

  const table = useReactTable({
    columns,
    data: tableData,
    columnResizeMode: 'onChange',
    state: {
      sorting: sorting,
      columnVisibility,
      columnOrder,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    defaultColumn: {
      minSize: 150,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const sizes = table.getState().columnSizingInfo;

  const tableColumns = useMemo(() => {
    return table.getAllColumns();
  }, [table]);

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    return headers.reduce(
      (acc, current) => {
        return {
          ...acc,
          [`--col-${current.column.id}-size`]: current.column.getSize(),
          [`--header-${current.id}-size`]: current.getSize(),
        };
      },
      {} as {
        [key: string]: number;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);

  const navigate = useNavigate();

  const handleNavigate = (invoiceId: string) => {
    const params = new URLSearchParams({
      postingStatus: `PARTIALLY_POSTED,UNPOSTED`,
    });
    navigate(`/posting/invoice/view/${invoiceId}?${params.toString()}`);
  };

  if (isLoading) {
    return (
      <div>
        <h2 className=" text-lg font-semibold  ">{`Invoices `} </h2>
        <p className=" text-sm text-muted-foreground mb-4">Select an invoice to match</p>
        <InvoicesFilters handleSubmit={handleSubmit} filters={invoiceFilters} />
        <TableSkeleton rows={10} columns={6} />
      </div>
    );
  }

  return (
    <div>
      <div className=" flex items-center justify-between ">
        <div>
          <InvoicesDetails filters={invoiceFilters} limit={pageLimit} page={page} />
        </div>
        <ColumnSelector columnOrder={columnOrder} columns={tableColumns} setColumnOrder={setColumnOrder} />
      </div>
      <InvoicesFilters handleSubmit={handleSubmit} filters={invoiceFilters} />
      <div className=" overflow-auto ">
        <Table
          style={{
            ...columnSizeVars,
            width: table.getTotalSize(),
          }}
          className="flex-1 ml-0"
        >
          {tableData.length === 0 && <TableCaption className="pb-10">No data found</TableCaption>}
          <TableHeader className=" bg-gray-100 ">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className="flex items-center" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      style={{
                        width: `calc(var(--header-${header?.id}-size) * 1.4px)`,
                      }}
                      className="flex items-center justify-between"
                      key={header.id}
                    >
                      {!['actions', 'select'].includes(header.id) ? (
                        <div className="break-keep truncate text-ellipsis">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                      <div className="flex gap-2">
                        {['invoiceDate', 'invoiceDueDate', 'invoiceOutstandingAmount'].includes(header.id) && (
                          <ArrowDownUp
                            className="w-4 h-4 cursor-pointer"
                            onClick={header.column.getToggleSortingHandler()}
                          />
                        )}
                        <GripVerticalIcon
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: cn(
                              'w-4 h-4 cursor-ew-resize',
                              header.column.getIsResizing() ? 'opacity-100' : 'opacity-50',
                            ),
                          }}
                        />
                      </div>
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="py-4">
            {table.getRowModel().rows.map((row) => (
              <TableRow onClick={() => handleNavigate(row.original.id)} className="flex cursor-pointer" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    style={{
                      width: `calc(var(--col-${cell.column.id}-size) * 1.4px)`,
                    }}
                    className="truncate whitespace-nowrap overflow-ellipsis"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Pagination
        hasNext={!!invoicesResponse?.data.hasNext}
        hasPrev={!!invoicesResponse?.data.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setPageLimit}
        pageNumber={page}
        rowsPerPage={pageLimit}
        totalPages={invoicesResponse?.data.totalPages || 0}
      />
    </div>
  );
};

export default InvoicesPostingTable;
