import {
  deleteTemplate,
  getEmailTemplateById,
  getEmailTemplates,
  getEmailTemplateSuggestions,
  getEmailTemplateVariables,
  postCreateTemplate,
  postGetTemplatePreview,
  postUpdateEmailTemplate,
} from '@/api-functions/email-templates.api';
import { PreviewVariable } from '@/components/email-builder/PreviewTemplate';
import { IApiResponse } from '@/types/common.types';
import {
  ICreateEmailTemplate,
  IEmailTemplateResponse,
  IGetEmailTemplateByIdResponse,
  IGetEmailTemplateSuggestions,
  IGetEmailTemplateVariablesResponse,
} from '@/types/email-templates.types';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const EmailTemplatesQueryKeys = {
  getEmailTemplatesByCompany: (companyId: string, page: number, pageSize: number) => [
    'getEmailTemplatesByCompany',
    companyId,
    page,
    pageSize,
  ],
  getEmailTemplateById: (templateId: string) => ['getEmailTemplatesById', templateId],
};

export const useGetEmailTemplatesByCompanyId = ({
  companyId,
  customConfig,
  page,
  pageSize,
}: {
  page: number;
  pageSize: number;
  companyId: string;
  customConfig?: Omit<UseQueryOptions<IEmailTemplateResponse>, 'queryKey'>;
}) => {
  const getEmailTemplatesByCompanyIdQuery = useQuery<IEmailTemplateResponse>({
    queryKey: EmailTemplatesQueryKeys.getEmailTemplatesByCompany(companyId, page, pageSize),
    queryFn: () =>
      getEmailTemplates({
        page,
        pageSize,
      }),
    ...customConfig,
  });

  return getEmailTemplatesByCompanyIdQuery;
};

export const useGetEmailTemplateById = ({
  templateId,
  customConfig,
}: {
  templateId: string;
  customConfig?: Omit<UseQueryOptions<IGetEmailTemplateByIdResponse>, 'queryKey'>;
}) => {
  const getEmailTemplateByIdQuery = useQuery<IGetEmailTemplateByIdResponse>({
    queryKey: EmailTemplatesQueryKeys.getEmailTemplateById(templateId),
    queryFn: () =>
      getEmailTemplateById({
        templateId,
      }),
    ...customConfig,
  });

  return getEmailTemplateByIdQuery;
};

export const useCreateEmailTemplate = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<IApiResponse<string>>,
    ICreateEmailTemplate
  >;
}) => {
  const postCreatePlan = useMutation({
    mutationFn: (createEmailTemplate: ICreateEmailTemplate) => postCreateTemplate(createEmailTemplate),
    ...customConfig,
  });

  return postCreatePlan;
};

export const useUpdateEmailTemplate = ({
  templateId,
  customConfig,
}: {
  templateId: string;
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<IApiResponse<string>>,
    ICreateEmailTemplate
  >;
}) => {
  const postCreatePlan = useMutation({
    mutationFn: (updateTemplateData: ICreateEmailTemplate) =>
      postUpdateEmailTemplate({ ...updateTemplateData, templateId: templateId }),
    ...customConfig,
  });

  return postCreatePlan;
};

export const usePostGetEmailTemplatePreview = ({
  templateId,
  customConfig,
}: {
  templateId: string;
  customConfig?: UseMutationOptions<
    IApiResponse<{
      body: string;
      subject: string;
    }>,
    AxiosError<IApiResponse<string>>,
    { variables: PreviewVariable[] }
  >;
}) => {
  const postCreatePlan = useMutation({
    mutationFn: ({ variables }: { variables: PreviewVariable[] }) =>
      postGetTemplatePreview({ templateId: templateId, variables }),
    ...customConfig,
  });

  return postCreatePlan;
};

export const useDeleteTemplate = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<IApiResponse<string>>,
    { templateId: string }
  >;
}) => {
  const queryClient = useQueryClient();
  const deleteEmailTemplateMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<IApiResponse<string>>,
    { templateId: string }
  >({
    mutationFn: ({ templateId }: { templateId: string }) => deleteTemplate({ templateId }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({
        queryKey: EmailTemplatesQueryKeys.getEmailTemplateById(variables.templateId),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getEmailTemplatesByCompany'),
      });
    },
  });

  return deleteEmailTemplateMutation;
};

export const useGetEmailTemplateSuggestions = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<IGetEmailTemplateSuggestions, unknown, { query: string }>;
}) => {
  const getTemplateSuggestionsQuery = useMutation<IGetEmailTemplateSuggestions, unknown, { query: string }>({
    mutationFn: ({ query }: { query: string }) => getEmailTemplateSuggestions(query),
    ...customConfig,
  });

  return getTemplateSuggestionsQuery;
};

export const useGetEmailTemplateVariablesQuery = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<IGetEmailTemplateVariablesResponse, unknown>;
}) => {
  const getEmailTemplateVariablesQuery = useQuery<IGetEmailTemplateVariablesResponse>({
    queryKey: ['getEmailTemplatesVariables'],
    queryFn: getEmailTemplateVariables,
    ...customConfig,
    staleTime: Infinity,
  });

  return getEmailTemplateVariablesQuery;
};
