import { EditReferenceId, EditReferenceText, EditReferenceType } from '@/components/invoices/InvoiceEditComponents';
import IRNDetails from '@/components/invoices/IRNDetails';
import { LabelValueDescriptionLayout } from '@/components/shared/LabelValueDescLayout';
import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import { IInvoice } from '@/types/invoices.types';
import { CardContent } from '@mui/material';

const InvoiceOtherDetails = ({ invoice, invoiceId }: { invoice: IInvoice; invoiceId: string }) => {
  return (
    <Card className=" my-8">
      <CardHeader className="border-b">
        <CardTitle className=" underline font-semibold ">Other details</CardTitle>
      </CardHeader>
      <CardContent className=" flex flex-col gap-4">
        <LabelValueDescriptionLayout
          label="Reference:"
          value={
            <div className=" flex items-center gap-4 ">
              <EditReferenceType defaultValue={invoice.referenceType} invoiceId={invoiceId} />
              <EditReferenceId
                referenceType={invoice.referenceType}
                invoiceId={invoiceId}
                defaultValue={invoice.referenceId}
              />
              <EditReferenceText
                referenceType={invoice.referenceType}
                invoiceId={invoiceId}
                defaultValue={invoice.referenceText}
              />
            </div>
          }
        />
        <IRNDetails
          irnAckNumber={invoice.irnAckNumber}
          irnGenerationDate={invoice.irnGenerationDate}
          irnNumber={invoice.irnNumber}
        />
        <LabelValueDescriptionLayout
          label="Place of supply:"
          value={<span className=" px-4 ">{invoice.placeOfSupply}</span>}
        />
        <LabelValueDescriptionLayout
          className="capitalize"
          label={(invoice?.invoiceType != '' ? invoice?.invoiceType : 'Invoice')?.toLocaleLowerCase() + ' Id:'}
          value={<span className=" px-4 normal-case">{invoice.invoiceId}</span>}
        />
      </CardContent>
    </Card>
  );
};

export default InvoiceOtherDetails;
