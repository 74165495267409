import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { sanitizeAttachmentsName } from '@/lib/utils';
import {
  IWorkflowDryRunEmailAttachment,
  IWorkflowDryRunEmailCustomer,
  IWorkflowDryRunEmailInvoice,
} from '@/types/workflow.type';
import { XIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

const ShowItemsModal = ({
  isModalOpen,
  setisModalOpen,
  items,
  type,
  setIsModalOpenForAttachment,
  setIsModalOpenForInvoice,
  buyerId,
}: {
  isModalOpen: boolean;
  setisModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpenForAttachment: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpenForInvoice: React.Dispatch<React.SetStateAction<boolean>>;

  items: IWorkflowDryRunEmailAttachment[] | IWorkflowDryRunEmailCustomer[] | IWorkflowDryRunEmailInvoice[];
  type: 'Invoices' | 'Attachments' | 'Customer' | '';
  buyerId: string;
}) => {
  const handleModalReset = () => {
    setisModalOpen(false);
    setIsModalOpenForInvoice(false);
    setIsModalOpenForAttachment(false);
  };

  const renderItems = () => {
    // TODO: Need to move it outta this component
    if (type === 'Invoices') {
      const invoices = items as IWorkflowDryRunEmailInvoice[];

      return (
        <div>
          {invoices.map((item) => (
            <div key={item.invoiceId} className="text-blue-700 mb-2">
              <Link target="_blank" to={`/invoice/view/${item.invoiceId}`}>
                {item.invoiceNumber}
              </Link>
            </div>
          ))}
        </div>
      );
    } else if (type == 'Attachments') {
      const attachments = items as IWorkflowDryRunEmailAttachment[];
      return (
        <div>
          {attachments.map((item) => (
            <div key={item.attachmentId} className="text-blue-700 mb-2">
              <Link target="_blank" to={`https://data.valyx.com/attachments/${item.attachmentId}/file`}>
                {sanitizeAttachmentsName(item.attachmentName)}
              </Link>
            </div>
          ))}
        </div>
      );
    } else if (type == 'Customer') {
      const customers = items as IWorkflowDryRunEmailCustomer[];
      const uniqueCustomers = Array.from(
        new Map(customers.map((customer) => [customer.customerName, customer])).values(),
      );
      return (
        <div>
          {uniqueCustomers.map((item) => (
            <div key={item.customerName} className="text-blue-700 mb-2">
              <Link target="_blank" to={`/customers?identifierType=BUYER_ID&identifierValue=${buyerId}`}>
                {item.customerName}
              </Link>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <Dialog open={isModalOpen} onOpenChange={handleModalReset}>
        <DialogContent className="min-w-[500px] min-h-[350px] border-2 bg-white	border-gray-500 ">
          <div>
            <div className=" h-[70px] p-4 py-2 flex  justify-between border-b-2 ">
              <div>
                <DialogHeader>
                  <div className="font-semibold capitalize">{type}</div>
                  <div>Buyer ID: {buyerId}</div>
                </DialogHeader>
              </div>
              <div>
                <XIcon className="w-[20px] h-[20px] cursor-pointer" onClick={handleModalReset} />
              </div>
            </div>
            <div className="min-h-[270px] min-w-[430px] px-4 pt-2">{renderItems()}</div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShowItemsModal;
