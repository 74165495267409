'use client';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { AttachmentFiletypes } from '@/types/attachments.types';
import { IOptions } from '@/types/common.types';
import { ChevronDownIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';

export function AttachmentsFiletypeDropdown({
  selected,
  handleSelect,
  className = '',
  disabled = false,
  defaultOpen,
}: {
  selected: IOptions[];
  handleSelect: (_: IOptions) => () => void;
  className?: string;
  disabled?: boolean;
  defaultOpen?: boolean;
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    defaultOpen && setOpen(true);
  }, [defaultOpen]);

  const options = useMemo<
    {
      label: string;
      value: AttachmentFiletypes;
    }[]
  >(
    () => [
      {
        value: 'csv',
        label: 'csv',
      },
      {
        value: 'docx',
        label: 'docx',
      },
      {
        value: 'jpeg',
        label: 'jpeg/jpg',
      },
      {
        value: 'pdf',
        label: 'pdf',
      },
      {
        value: 'png',
        label: 'png',
      },
      {
        value: 'txt',
        label: 'txt',
      },
      {
        value: 'ms-excel',
        label: 'xls/xlsv',
      },
    ],
    [],
  );

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger disabled={disabled} asChild>
        <Button
          disabled={disabled}
          className="flex items-center flex-1 min-w-[120px] truncate text-left gap-4 "
          onClick={() => setOpen((prev) => !prev)}
          variant="outline"
        >
          <span className={cn('truncate text-left w-[150px] overflow-ellipsis', className)}>
            {selected.length > 0 ? (
              selected.map((option) => option.label).join(', ')
            ) : (
              <span className=" normal-case ">Select type</span>
            )}
          </span>
          <ChevronDownIcon className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onInteractOutside={() => setOpen(false)}
        onEscapeKeyDown={() => setOpen(false)}
        onFocusOutside={() => setOpen(false)}
        onPointerDownOutside={() => setOpen(false)}
        className="w-56"
      >
        <DropdownMenuLabel>File type</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {options.map((option) => (
          <DropdownMenuCheckboxItem
            className=""
            key={option.value}
            checked={!!selected.find((item) => item.value === option.value)}
            onClick={handleSelect(option)}
          >
            {option.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
