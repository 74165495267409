import CreditLedgerTableWrapper from '@/components/posting/ledger/credits/ledger-credit-table';
import SwitchLedgerFlow from '@/components/posting/shared/SwitchLedgerFlow';
import { Layout } from '@/components/shared/Layout';

const LedgerCredit = () => {
  return (
    <Layout className=" flex-1 flex flex-col ">
      <SwitchLedgerFlow current="credits-ledger" />
      <CreditLedgerTableWrapper />
    </Layout>
  );
};

export default LedgerCredit;
