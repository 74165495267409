'use client';

import ToolTipCell from '@/components/Table/ToolTipCell';
import { Button } from '@/components/ui/button';
import { useStore } from '@/stores/email-builder/page-sections.store';
import { Section } from '@/utils/email-builder/sections';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripIcon, Trash2Icon } from 'lucide-react';
import { useMemo } from 'react';

const ColumnItem = ({
  column,
  setSelectedField,
  handleRemoveColumn,
  type,
}: {
  column: Section<'table'>['content']['content']['columns'][0];
  setSelectedField: React.Dispatch<React.SetStateAction<string>>;
  handleRemoveColumn: (_: string) => void;
  type: 'Manual' | 'Invoice';
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({
    id: column.columnId,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const div = document.createElement('div');
  div.innerHTML = column.label;

  const label = div.textContent || div.innerText || '';

  return (
    <div ref={setNodeRef} style={style} className=" flex justify-between items-center w-full " key={column.fieldName}>
      <span
        onClick={() => setSelectedField(column.columnId)}
        className=" cursor-pointer flex-1 overflow-hidden text-ellipsis w-7/10 "
      >
        {type === 'Manual' && <ToolTipCell value={label}>{label}</ToolTipCell>}
        {type !== 'Manual' && <ToolTipCell value={column.fieldName}>{column.fieldName}</ToolTipCell>}
      </span>

      <div className=" flex items-center gap-2 ">
        <Button
          variant="ghost"
          size="icon"
          className="flex items-center justify-center"
          ref={setActivatorNodeRef}
          {...listeners}
          {...attributes}
        >
          <GripIcon className="h-4 w-4" />
        </Button>
        <Button onClick={() => handleRemoveColumn(column.fieldName)} variant="ghost" size="icon">
          <Trash2Icon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

const Columns = ({
  columns,
  setSelectedField,
  handleRemoveColumn,
  type,
}: {
  columns: Section<'table'>['content']['content']['columns'];
  setSelectedField: React.Dispatch<React.SetStateAction<string>>;
  handleRemoveColumn: (_: string) => void;
  type: 'Manual' | 'Invoice';
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const { updateSection, section } = useStore((store) => ({
    updateSection: store.updateSection,
    section: store.selectedSection ? store.sections[store.selectedSection] : null,
  }));

  const tableColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      id: column.columnId,
    }));
  }, [columns]);

  const handleDragEnd = (event: DragEndEvent) => {
    if (!section) return;

    const { active, over } = event;

    if (active.id !== over?.id) {
      const currentSection = section as Section<'table'>;

      const currentColumnsState = currentSection.content.content.columns;
      const newIndex = currentColumnsState.findIndex((column) => column.columnId === over?.id);
      const oldIndex = currentColumnsState.findIndex((column) => column.columnId === active.id);

      updateSection({
        ...currentSection,
        content: {
          ...currentSection.content,
          content: {
            ...currentSection.content.content,
            columns: arrayMove(currentColumnsState, oldIndex, newIndex),
          },
        },
      });
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={tableColumns} strategy={verticalListSortingStrategy}>
        {tableColumns.map((column) => (
          <ColumnItem
            setSelectedField={setSelectedField}
            handleRemoveColumn={handleRemoveColumn}
            key={column.columnId}
            column={column}
            type={type}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default Columns;
