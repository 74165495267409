import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { CopyToClipboard } from '@/components/ui/copy-clipboard';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { useDeleteContact } from '@/hooks/api-hooks/useContactsQuery';
import { IContact } from '@/types/contacts.types';
import { useQueryClient } from '@tanstack/react-query';
import { EditIcon, MailIcon, PhoneIcon, PlusIcon, Trash2Icon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ContactDetails = ({
  contactDetails,
  customerId,
  allowCreate = true,
}: {
  contactDetails: IContact[];
  customerId: string;
  allowCreate?: boolean;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: deleteContact } = useDeleteContact({
    customerId,
    customConfig: {
      onSuccess: () => {
        queryClient.refetchQueries({
          predicate(query) {
            return query.queryKey[0] === 'getCustomerByFilters';
          },
        });
      },
    },
  });

  const handleEdit = (contactDetails: IContact) => () => {
    navigate(`/customer/view/${customerId}?scrollTo=contacts&contactKey=${contactDetails.id}`);
  };

  const handleAddNew = () => {
    navigate(`/customer/view/${customerId}?scrollTo=contacts&contactKey=create_new`);
  };

  const handleDelete = (contactId: string) => () => {
    deleteContact({ contactId });
  };

  return (
    <div className=" flex flex-col gap-4 max-h-28 overflow-y-scroll">
      {!allowCreate && contactDetails.length === 0 && (
        <p className="text-sm text-muted-foreground">No Contacts Found</p>
      )}
      {contactDetails.map((contact, index) => (
        <div key={index} className="flex items-center justify-between gap-4 min-w-[160px]">
          <HoverCard>
            <HoverCardTrigger className=" text-sm cursor-pointer underline text-[14px] text-ellipsis overflow-hidden">
              {contact.name}
            </HoverCardTrigger>
            <HoverCardContent>
              <div className="flex flex-col gap-2">
                {/* <Badge className="text-sm max-w-fit overflow-hidden text-ellipsis" >{contact.role}</Badge> */}
                <Badge className="text-sm max-w-fit overflow-hidden text-ellipsis">{contact.role}</Badge>
                {contact?.primaryEmail && (
                  <div className="text-sm flex items-center gap-2 ">
                    <MailIcon className="w-4 h-4" />
                    <p className="text-[14px] max-w-[140px] overflow-hidden text-ellipsis">{contact.primaryEmail}</p>
                    <CopyToClipboard text={contact.primaryEmail} />
                  </div>
                )}
                {contact?.primaryPhone && (
                  <div className="text-sm flex items-center gap-2 ">
                    <PhoneIcon className="w-4 h-4" /> {contact.primaryPhone}
                    <CopyToClipboard text={contact.primaryPhone} />
                  </div>
                )}
              </div>
            </HoverCardContent>
          </HoverCard>
          {!!allowCreate && (
            <div className=" flex gap-2 items-center ">
              <Button
                onClick={handleEdit(contact)}
                variant="outline"
                size="icon"
                className="w-6 h-6 flex-1 min-w-[24px] max-w-[24px]"
              >
                <EditIcon className="w-4 h-4" />
              </Button>
              <Button
                onClick={handleDelete(contact.id)}
                variant="outline"
                size="icon"
                className="w-6 h-6 flex-1 min-w-[24px] max-w-[24px]"
              >
                <Trash2Icon className="w-4 h-4" />
              </Button>
            </div>
          )}
        </div>
      ))}
      {!!allowCreate && (
        <Button
          onClick={handleAddNew}
          variant="outline"
          size="icon"
          className="w-6 h-6 flex-1 min-w-[24px] max-w-[24px]"
        >
          <PlusIcon className=" w-4 h-4 " />
        </Button>
      )}
    </div>
  );
};

export default ContactDetails;
