import emptyIllustration from '@/assets/empty.svg';

const Empty = ({ title }: { title: string }) => {
  return (
    <div className=" w-40 h-40 mx-auto my-16 ">
      <img src={emptyIllustration} alt="No data" />
      <h2 className=" text-xl font-semibold text-center ">{title || 'No data available'}</h2>
    </div>
  );
};

export default Empty;
