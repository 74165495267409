import AttachedInvoices from '@/components/credit-notes/AttachedInvoices';
import CreditNotesLineItemTable from '@/components/credit-notes/LineItemTable';
import TopDetailViewItem from '@/components/shared/DetailViewItem';
import {
  LabelValueDescriptionLayout,
  LoadingLabelValueDescriptionLayout,
} from '@/components/shared/LabelValueDescLayout';
import { TightLayout } from '@/components/shared/Layout';
import TableSkeleton from '@/components/shared/TableSkeleton';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardHeaderWithCTA, CardTitle } from '@/components/ui/card';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useToast } from '@/components/ui/use-toast';
import { useCreateInvoicesToCreditNotePosting, useGetCreditNoteById } from '@/hooks/api-hooks/useCreditNotesQuery';
import { useGetInvoiceSuggestionsByInvoiceNumber } from '@/hooks/api-hooks/useInvoiceQuery';
import { cn } from '@/lib/utils';
import {
  IAttachedInvoicesToCreditNote,
  ICreateInvoiceToCreditNotePostingRequestObject,
  IInvoiceForPostingToCreditNote,
} from '@/types/credit-notes.types';
import { IInvoice } from '@/types/invoices.types';
import { formatCurrency } from '@/utils/formatCurrency';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import { ColumnDef, flexRender, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { InfoIcon, Loader2Icon, PlusIcon, XIcon } from 'lucide-react';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { GroupBase, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';
import { AppliedInvoicesHoverCard } from './credit-note-list';

interface IInvoiceOption {
  value: string;
  label: string;
  data?: IInvoice;
}

const SelectInvoiceComponent = ({
  invoiceNumber,
  setInvoices,
  customerId,
  invoiceSelectedMap,
  index,
}: {
  invoiceNumber: string;
  setInvoices: (_: IInvoiceForPostingToCreditNote) => void;
  customerId: string;
  invoiceSelectedMap: Record<string, boolean>;
  index: number;
}) => {
  const { mutateAsync: fetchInvoices } = useGetInvoiceSuggestionsByInvoiceNumber({
    customerId,
    paymentStatus: ['PARTIALLY_PAID', 'UNPAID'],
  });

  const loadInvoices = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IInvoiceOption, GroupBase<IInvoiceOption>>) => void) => {
      fetchInvoices({
        invoiceNumber: inputValue.trim(),
      })
        .then((result) => {
          const options = result.data.docs
            .map((item) => ({
              value: item.invoiceNumber || '',
              label: item.invoiceNumber || '',
              data: item,
            }))
            .filter((item) => Boolean(item.value))
            .filter((item) => !invoiceSelectedMap[item.data.id]);

          callback(options);
        })
        .catch(() => callback([]));
      return;
    },
    [fetchInvoices, invoiceSelectedMap],
  );

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false, IInvoiceOption>();
  }, []);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const debouncedInvoiceSuggestions = useMemo(() => {
    return debounce(loadInvoices, 500);
  }, [loadInvoices]);

  const handleChange = (value: IInvoiceOption | null) => {
    if (!value) {
      return;
    }

    const { data } = value;

    if (!data) {
      return;
    }

    setInvoices({
      invoiceNumber: data.invoiceNumber,
      dueAmount: data.invoiceOutstandingAmount,
      totalAmount: data.invoiceTotal,
      invoiceId: data.id,
      postedAmount: 0,
    });

    setTimeout(() => {
      document.getElementById(`${index}-apply-invoices-to-cns`)?.focus();
    });
  };

  return (
    <AsyncSelect
      isMulti={false}
      placeholder="Enter invoice number"
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      value={
        invoiceNumber
          ? {
              value: invoiceNumber,
              label: invoiceNumber,
            }
          : undefined
      }
      loadOptions={debouncedInvoiceSuggestions}
      menuPortalTarget={document.body}
      className=" text-sm shadow-sm w-[180px]"
      styles={selectStyles}
      classNames={selectClasses}
      onChange={handleChange}
      autoFocus
    />
  );
};

const getPostedAmountError = ({
  postedAmount,
  remainingToPost,
  creditRemainingAmount,
  dueAmount,
}: {
  postedAmount: number;
  remainingToPost: number;
  creditRemainingAmount: number;
  dueAmount: number;
}) => {
  if (!postedAmount) {
    return 'Posting amount cannot be 0';
  }

  if (postedAmount > remainingToPost) {
    return `Amount cannot be greater than ${formatCurrency(creditRemainingAmount)}`;
  }

  if (dueAmount < postedAmount) {
    return `Amount cannot be greater than invoice due amount`;
  }

  return '';
};

const OriginatingInvoices = ({ invoices }: { invoices: IAttachedInvoicesToCreditNote[] | null }) => {
  if (!invoices) {
    return '-';
  }

  const length = invoices.length - 1;

  if (length < 0) {
    return '-';
  }

  if (length === 0) {
    return (
      <Link className=" text-blue-700 underline " to={`/invoice/view/${invoices[0].invoiceId}`}>
        {invoices[0].invoiceNumber}
      </Link>
    );
  }

  return (
    <span>
      <Link className=" text-blue-700 underline " to={`/invoice/view/${invoices[0].invoiceId}`}>
        {invoices[0].invoiceNumber}
      </Link>{' '}
      <AppliedInvoicesHoverCard invoices={invoices}>
        <Button variant="ghost">+ {length}</Button>
      </AppliedInvoicesHoverCard>
    </span>
  );
};

const PostedAmountInput = ({
  value,
  handlePostedAmountChange,
  index,
  remainingAmount,
  allRows,
  dueAmount,
}: {
  value: number;
  handlePostedAmountChange: (_: ChangeEvent<HTMLInputElement>) => void;
  index: number;
  remainingAmount: number;
  allRows: Row<IInvoiceForPostingToCreditNote>[];
  dueAmount: number;
}) => {
  const remainingExceptSelf =
    remainingAmount -
    allRows.filter((item, i) => i !== index).reduce((acc, item) => acc + item.original.postedAmount, 0);

  const error = useMemo(
    () =>
      getPostedAmountError({
        postedAmount: value,
        remainingToPost: remainingExceptSelf,
        creditRemainingAmount: remainingAmount,
        dueAmount: dueAmount,
      }),
    [value, remainingExceptSelf, remainingAmount, dueAmount],
  );

  return (
    <div className=" w-[250px] ">
      <Input id={`${index}-apply-invoices-to-cns`} type="number" value={value} onChange={handlePostedAmountChange} />
      {!!error && <p className=" text-destructive text-xs ">{error}</p>}
    </div>
  );
};

const PostingsToCreateTable = ({
  invoices,
  setInvoices,
  customerId,
  invoicesSelectedMap,
  remainingAmount,
}: {
  invoices: IInvoiceForPostingToCreditNote[];
  setInvoices: React.Dispatch<React.SetStateAction<IInvoiceForPostingToCreditNote[]>>;
  customerId: string;
  invoicesSelectedMap: Record<string, boolean>;
  remainingAmount: number;
}) => {
  const handleSetInvoiceNumber = useCallback(
    (rowIndex: number) => (invoice: IInvoiceForPostingToCreditNote) => {
      setInvoices((prev) => {
        return prev.map((item, index) => {
          if (index === rowIndex) {
            return {
              ...invoice,
            };
          }
          return item;
        });
      });
    },
    [setInvoices],
  );

  const handlePostedAmountChange = useCallback(
    (rowIndex: number) => (event: ChangeEvent<HTMLInputElement>) => {
      setInvoices((prev) => {
        return prev.map((item, index) => {
          if (index === rowIndex) {
            return {
              ...item,
              postedAmount: Number(event.target.value),
            };
          }
          return item;
        });
      });
    },
    [setInvoices],
  );

  const columns = useMemo<ColumnDef<IInvoiceForPostingToCreditNote>[]>(
    () => [
      {
        accessorKey: 'invoiceNumber',
        header: 'Invoice Number',
        cell: ({ row }) => (
          <SelectInvoiceComponent
            index={row.index}
            invoiceSelectedMap={invoicesSelectedMap}
            customerId={customerId}
            setInvoices={handleSetInvoiceNumber(row.index)}
            invoiceNumber={row.original.invoiceNumber}
          />
        ),
      },
      {
        accessorKey: 'dueAmount',
        header: 'Due/Overdue Amount',
        cell: ({ row }) =>
          !row.original.invoiceNumber.trim() ? null : (
            <div className=" text-sm ">
              <p>{formatCurrencyByUnit(row.original.dueAmount, 'actual')}</p>
              <p className=" text-muted-foreground ">
                Total: {formatCurrencyByUnit(row.original.totalAmount, 'actual')}
              </p>
            </div>
          ),
      },
      {
        accessorKey: 'postedAmount',
        header: 'Posted Amount',
        cell: ({ table, row }) =>
          !row.original.invoiceNumber.trim() ? null : (
            <PostedAmountInput
              dueAmount={row.original.dueAmount}
              remainingAmount={remainingAmount}
              handlePostedAmountChange={handlePostedAmountChange(row.index)}
              index={row.index}
              value={row.original.postedAmount}
              allRows={table.getRowModel().rows}
            />
          ),
      },
    ],
    [handleSetInvoiceNumber, customerId, handlePostedAmountChange, invoicesSelectedMap, remainingAmount],
  );

  const table = useReactTable({
    columns,
    data: invoices,
    getCoreRowModel: getCoreRowModel(),
    state: {},
  });

  return (
    <Table className=" rounded-lg ">
      <TableHeader className=" bg-gray-100 rounded-lg ">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const ApplyCreditNotesDialog = ({
  customerId,
  creditNoteId,
  appliedInvoiceIds,
  remainingAmount,
}: {
  customerId: string;
  creditNoteId: string;
  appliedInvoiceIds: string;
  remainingAmount: number;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [invoices, setInvoices] = useState<IInvoiceForPostingToCreditNote[]>([
    {
      dueAmount: 0,
      invoiceId: '',
      invoiceNumber: '',
      postedAmount: 0,
      totalAmount: 0,
    },
  ]);
  const { toast } = useToast();

  const { mutate: createInvoicesToCreditNotePosting, isPending } = useCreateInvoicesToCreditNotePosting({
    customConfig: {
      onSuccess() {
        toast({
          description: 'Credit Note created successfully',
        });
        setInvoices([]);
        setIsDialogOpen(false);
      },
      onError(error) {
        toast({
          title: 'Error',
          description: error.response?.data.message || 'Unable to apply credit notes',
          variant: 'destructive',
        });
      },
    },
  });

  const invoiceIds = invoices.map((item) => item.invoiceId).join(',');

  const invoicesSelectedMap = useMemo(() => {
    const selectedMap = invoiceIds.split(',').reduce(
      (acc, invoiceId) => {
        return {
          ...acc,
          [invoiceId]: true,
        };
      },
      {} as Record<string, boolean>,
    );

    return appliedInvoiceIds.split(',').reduce((acc, invoiceId) => {
      return {
        ...acc,
        [invoiceId]: true,
      };
    }, selectedMap);
  }, [invoiceIds, appliedInvoiceIds]);

  const handleAddNewInvoice = () => {
    setInvoices((prev) => [
      ...prev,
      {
        dueAmount: 0,
        invoiceNumber: '',
        postedAmount: 0,
        totalAmount: 0,
        invoiceId: '',
      },
    ]);
  };

  const handleCreatePostings = () => {
    const postingsToCreate: ICreateInvoiceToCreditNotePostingRequestObject[] = invoices
      .map((item) => ({
        invoiceId: item.invoiceId,
        creditNoteId: creditNoteId,
        postedAmount: item.postedAmount,
      }))
      .filter((item) => !!item.invoiceId && !!item.creditNoteId && !!item.postedAmount);

    createInvoicesToCreditNotePosting({
      postings: postingsToCreate,
    });
  };

  const handleCloseDialog = () => {
    setInvoices([]);
    setIsDialogOpen(false);
  };

  const isApplyDisabled = useMemo(() => {
    const isPostingValuesValid = invoices.every((item) => {
      return (
        !!item.invoiceNumber.trim() && !!item.postedAmount && item.postedAmount <= item.dueAmount && !!item.invoiceId
      );
    });

    const totalPostedAmount = invoices.reduce((acc, item) => {
      return acc + item.postedAmount;
    }, 0);

    return !isPostingValuesValid || totalPostedAmount > remainingAmount;
  }, [invoices, remainingAmount]);

  return (
    <div>
      <Button className=" flex items-center gap-2 " onClick={() => setIsDialogOpen(true)}>
        <PlusIcon className="w-4 h-4" />
        Apply Invoices
      </Button>
      {isDialogOpen && (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent>
            <div className="min-h-[52px] p-4 flex  justify-between border-b">
              <div>
                <DialogHeader>
                  <DialogTitle>Apply Credit Notes to Invoices</DialogTitle>
                </DialogHeader>
              </div>
              <div>
                <XIcon className="w-[20px] h-[20px] cursor-pointer" onClick={handleCloseDialog} />
              </div>
            </div>
            <div className=" flex flex-col gap-4 py-2 min-w-[700px] min-h-[400px] px-2">
              <PostingsToCreateTable
                invoicesSelectedMap={invoicesSelectedMap}
                setInvoices={setInvoices}
                customerId={customerId}
                invoices={invoices}
                remainingAmount={remainingAmount}
              />
              <div>
                <Button
                  onClick={handleAddNewInvoice}
                  variant="ghost"
                  className=" text-blue-700 flex items-center gap-2 "
                >
                  <PlusIcon className="w-4 h-4" />
                  Add Invoice
                </Button>
              </div>
            </div>
            <div className="h-[64px] p-4 border-t">
              <DialogFooter>
                <Button variant="outline" onClick={handleCloseDialog}>
                  Cancel
                </Button>
                <Button
                  onClick={handleCreatePostings}
                  disabled={isPending || isApplyDisabled}
                  className="flex items-center gap-2"
                >
                  Apply
                  {isPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
                </Button>
              </DialogFooter>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

const CreditNoteDetails = ({ creditNoteId }: { creditNoteId: string }) => {
  const { data: creditNoteByIdResponse, isLoading } = useGetCreditNoteById({
    creditNoteId,
    customConfig: {
      enabled: !!creditNoteId,
    },
  });

  const appliedInvoiceIds = useMemo(() => {
    return creditNoteByIdResponse?.data?.creditNoteAppliedInvoices?.map((item) => item.invoiceId).join(',') || '';
  }, [creditNoteByIdResponse]);

  const creditNote = useMemo(() => {
    return creditNoteByIdResponse?.data;
  }, [creditNoteByIdResponse]);

  if (isLoading) {
    return (
      <>
        <div className=" text-xl font-semibold my-4 flex gap-8 items-center ">
          <Skeleton className=" capitalize w-96 h-6 " />
        </div>
        <div className="flex gap-8 items-center ">
          <div className=" flex flex-col gap-2 ">
            <Skeleton className="w-32 h-4" />
            <Skeleton className="w-32 h-4" />
          </div>
          <div className=" flex flex-col gap-2 ">
            <Skeleton className="w-32 h-4" />
            <Skeleton className="w-32 h-4" />
          </div>
          <div className=" flex flex-col gap-2 ">
            <Skeleton className="w-32 h-4" />
            <Skeleton className="w-32 h-4" />
          </div>
        </div>
        <Card className=" text-sm my-8">
          <CardHeader className="border-b">
            <Skeleton className="w-48 h-5" />
          </CardHeader>
          <CardContent className=" flex flex-col gap-4">
            {new Array(4).fill(0).map((_, i) => (
              <LoadingLabelValueDescriptionLayout key={i} />
            ))}
          </CardContent>
        </Card>
        <Card className=" my-8 ">
          <CardHeader className="border-b">
            <Skeleton className="w-48 h-5" />
          </CardHeader>
          <CardContent>
            <TableSkeleton rows={6} columns={6} />
          </CardContent>
        </Card>
        <Card className=" text-sm my-8">
          <CardHeader className="border-b">
            <Skeleton className=" w-48 h-5" />
          </CardHeader>
          <CardContent className=" flex flex-col gap-4">
            {new Array(4).fill(0).map((_, i) => (
              <LoadingLabelValueDescriptionLayout key={i} />
            ))}
          </CardContent>
        </Card>
      </>
    );
  }

  if (!creditNote) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      <div className=" text-xl font-semibold my-4 flex gap-8 items-center ">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="text-[20px] truncate  whitespace-nowrap overflow-ellipsis" asChild>
              <div>
                {`${creditNote.creditNoteType ?? ''}`}{' '}
                {creditNote?.creditNoteNumber?.trim() ? creditNote.creditNoteNumber : creditNote.creditNoteId} to{' '}
                {creditNote.customerId ? (
                  <Link
                    className=" text-blue-700 underline "
                    to={`/customer/view/${creditNote.customerId}`}
                    target="_blank"
                  >
                    {creditNote.customerName}
                  </Link>
                ) : (
                  creditNote.customerName
                )}{' '}
                on {`${dayjs(creditNote.creditNoteDate).format('DD MMM YYYY')}`}
              </div>
            </TooltipTrigger>
            <TooltipContent>
              {`${creditNote.creditNoteType ?? ''}`}{' '}
              {creditNote?.creditNoteNumber?.trim() ? creditNote.creditNoteNumber : creditNote.creditNoteId} to{' '}
              {creditNote.customerId ? creditNote.customerName : creditNote.customerName} on{' '}
              {`${dayjs(creditNote.creditNoteDate).format('DD MMM YYYY')}`}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className=" flex items-center gap-4 ">
        <TopDetailViewItem
          label="Credit note status"
          value={
            <Badge className=" capitalize ">{creditNote.creditNoteStatus.split('_').join(' ').toLowerCase()}</Badge>
          }
        />
        <TopDetailViewItem label="Credit note value" value={formatCurrency(creditNote.creditNoteTotal)} />
        <TopDetailViewItem
          label="Customer"
          value={
            <Link className=" text-blue-700 underline" to={`/customer/view/${creditNote.customerId}`}>
              {creditNote.customerName}
            </Link>
          }
        />
        {!!creditNote.creditNoteRemainingAmount && (
          <TopDetailViewItem label="Available to Apply" value={formatCurrency(creditNote.creditNoteRemainingAmount)} />
        )}
        {!!creditNote.creditNoteInvoicesReferences?.length && (
          <TopDetailViewItem
            label={
              <TooltipProvider>
                <Tooltip>
                  <div className=" flex items-center gap-2 ">
                    Originating Invoices
                    <TooltipTrigger>
                      <InfoIcon className=" w-4 h-4 " />
                    </TooltipTrigger>
                    <TooltipContent>The invoice against which the credit note was created</TooltipContent>
                  </div>
                </Tooltip>
              </TooltipProvider>
            }
            value={<OriginatingInvoices invoices={creditNote.creditNoteInvoicesReferences || []} />}
          />
        )}
      </div>
      <Card id="items" className=" my-8 ">
        <CardHeader className=" border-b ">
          <CardTitle className=" underline font-semibold ">{creditNote.creditNoteType} Items</CardTitle>
        </CardHeader>
        <CardContent className=" flex flex-col gap-4 py-2 ">
          <CreditNotesLineItemTable currencyType={creditNote.currency} lineItems={creditNote.lineItemDetails || []} />
          <div className=" flex text-sm my-4 border-t-2 pt-4 ">
            <div className="min-w-[300px]  flex flex-col gap-2 ">
              <div className=" flex items-center justify-between">
                <span>Sub total:</span>
                <span
                  className={cn(
                    ' font-semibold',
                    creditNote.creditNoteSubtotal < 0 ? 'text-red-500' : 'text-green-700',
                  )}
                >
                  {formatCurrencyByUnit(creditNote.creditNoteSubtotal, 'actual', creditNote.currency)}
                </span>
              </div>
              <div className=" flex items-center justify-between gap-4">
                <div className="flex gap-4 items-center ">
                  {creditNote.discount?.isPostTax ? 'Post tax' : 'Pre tax'} Discount{' '}
                </div>
                <div>
                  -
                  {formatCurrencyByUnit(
                    creditNote.discount && creditNote.discount != null && creditNote.discount.amount
                      ? creditNote.discount.amount
                      : 0,
                    'actual',
                    creditNote.currency,
                  )}
                </div>
              </div>
              <div className=" flex items-center justify-between">
                <div>Tax</div>
                <div>{formatCurrencyByUnit(creditNote.creditNoteTax.total, 'actual', creditNote.currency)}</div>
              </div>
              <div className=" flex items-center justify-between border-t-2">
                <span className="pt-4 font-semibold ">Total</span>
                <span
                  className={cn(
                    'pt-4 font-semibold',
                    creditNote.creditNoteTotal < 0 ? 'text-red-500' : 'text-green-700',
                  )}
                >
                  {formatCurrencyByUnit(
                    creditNote.creditNoteSubtotal + creditNote.creditNoteTax.total - (creditNote.discount?.amount ?? 0),
                    'actual',
                    creditNote.currency,
                  )}
                </span>
              </div>
              {creditNote.creditNoteTax.tds != undefined ? (
                <div className=" flex items-center justify-between">
                  <div>TDS</div>
                  <div>{formatCurrencyByUnit(creditNote.creditNoteTax.tds, 'actual', creditNote.currency)}</div>
                </div>
              ) : (
                ''
              )}
              <div className=" flex items-center justify-between border-t-2">
                <span className="pt-4 font-semibold ">Total Value</span>
                <span
                  className={cn(
                    'pt-4 font-semibold',
                    creditNote.creditNoteTotal < 0 ? 'text-red-500' : 'text-green-700',
                  )}
                >
                  {formatCurrencyByUnit(creditNote.creditNoteTotal, 'actual', creditNote.currency)}
                </span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card id="attached-invoices" className=" my-8 ">
        <CardHeaderWithCTA
          className=" border-b "
          cardTitle={
            <CardTitle className=" underline font-semibold ">
              Applied Invoices{' '}
              {!!creditNote.creditNoteAppliedInvoices?.length && `(${creditNote.creditNoteAppliedInvoices?.length})`}
            </CardTitle>
          }
          cta={
            <ApplyCreditNotesDialog
              remainingAmount={creditNote.creditNoteRemainingAmount}
              creditNoteId={creditNote.id}
              appliedInvoiceIds={appliedInvoiceIds}
              customerId={creditNote.customerId}
            />
          }
        />
        <CardContent className=" flex flex-col gap-4 py-2 ">
          <AttachedInvoices creditNoteId={creditNote.id} invoices={creditNote.creditNoteAppliedInvoices || []} />
        </CardContent>
      </Card>
      <Card className=" text-sm my-8">
        <CardHeader className="border-b">
          <CardTitle className=" underline font-semibold px-0 mx-0 ">Other details</CardTitle>
        </CardHeader>
        <CardContent className=" flex flex-col gap-2">
          <LabelValueDescriptionLayout
            label="IRN:"
            value={creditNote.irnNumber ? creditNote.irnNumber : 'Not Available'}
            description=""
          />
          {!!creditNote.creditNoteDescription && (
            <LabelValueDescriptionLayout label="Description:" value={creditNote.creditNoteDescription} description="" />
          )}
          {creditNote.creditNoteType && (
            <LabelValueDescriptionLayout label="Credit note type:" value={creditNote.creditNoteType} description="" />
          )}
          {creditNote.buyerId && (
            <LabelValueDescriptionLayout label="Buyer Id:" value={creditNote.buyerId} description="" />
          )}
          {creditNote.customerName && (
            <LabelValueDescriptionLayout label="Customer Name:" value={creditNote.customerName} description="" />
          )}
        </CardContent>
      </Card>
    </>
  );
};

const ViewCreditNotePage = () => {
  const { creditNoteId } = useParams();

  return (
    <TightLayout>
      <CreditNoteDetails creditNoteId={creditNoteId || ''} />
    </TightLayout>
  );
};

export default ViewCreditNotePage;
