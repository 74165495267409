import { IExcelExportFormat } from '@/types/cashflow.types';
import { IDiscount, IInvoice, ITax } from '@/types/invoices.types';
import { useExportExcel } from '../utils/useExportExcel';
const formatDiscount = ({ discount }: { discount: IDiscount }) => {
  const discountObj: Partial<IDiscount> = {};

  if (discount.amount) {
    discountObj['amount'] = discount.amount;
  }

  if (discount.percentage) {
    discountObj['percentage'] = discount.percentage;
  }

  return discountObj;
};

const formatTax = ({ tax }: { tax: ITax }) => {
  return tax;
};

const flattenArrayToObject = (arr: IInvoice[], selectedColumns: Set<string>): IExcelExportFormat[] => {
  return arr.map((item) =>
    Object.entries(item).reduce((acc, [key, value]) => {
      if (!selectedColumns.has(key)) {
        return acc;
      }

      const formatted = key.replace(/([A-Z])/g, ' $1');
      if (key === 'discount') {
        const formattedDiscount = formatDiscount({ discount: value as IDiscount });
        return {
          ...acc,
          ['Discount Amount']: formattedDiscount.amount ?? '',
          ['Discount Percentage']: formattedDiscount.percentage ?? '',
        };
      } else if (key == 'invoiceTax') {
        const invoiceTax = formatTax({ tax: value as ITax });
        return {
          ...acc,
          ['Invoice Total Tax']: invoiceTax.total,
          ['Invoice Tds']: invoiceTax.tds,
          ['Invoice Cgst']: invoiceTax.cgst,
          ['Invoice Igst']: invoiceTax.igst,
          ['Invoice Sgst']: invoiceTax.sgst,
        };
      } else {
        return { ...acc, [formatted.charAt(0).toUpperCase() + formatted.slice(1)]: value };
      }
    }, {}),
  );
};

export const useInvoicesExport = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (_: string) => void;
  onError?: () => void;
}) => {
  const { action, isLoading } = useExportExcel({
    onSuccess,
    onError,
  });

  return {
    exportExcel: (data: IInvoice[], selectedColumns: Set<string>) =>
      action(flattenArrayToObject(data, selectedColumns)),
    isLoading,
  };
};
