import { useGetContactRoleSuggestions } from '@/hooks/api-hooks/useContactsQuery';
import { IOptions } from '@/types/common.types';
import { getClassNamesForSelectPurpose, getStylesForSelect } from '@/utils/getStylesForSelect';
import debounce from 'lodash.debounce';
import { useCallback, useMemo, useRef, useState } from 'react';
import { GroupBase, MultiValue, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';
import type Select from 'react-select/dist/declarations/src/Select';

const CustomerRoleAndEmailMultiSelect = ({
  value,
  onChange,
  setError,
  placeholder = '',
  className = '',
}: {
  value: string[];
  onChange: (_value: string[]) => void;
  setError: (_error: string) => void;
  placeholder?: string;
  className?: string;
}) => {
  const { mutateAsync: fetchCustomerRoles } = useGetContactRoleSuggestions({});
  const [ccEmailValue, setCCEmailValue] = useState<string>('');
  const [forceMenuClose, setForceMenuClose] = useState<boolean>(false);
  const selectRef = useRef<
    Select<
      {
        label: string;
        value: string;
      },
      true,
      GroupBase<IOptions>
    >
  >(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelectPurpose();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false | true, IOptions>();
  }, []);

  const loadRoles = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IOptions, GroupBase<IOptions>>) => void) => {
      if (inputValue.length < 3) {
        callback([]);
        return;
      }

      fetchCustomerRoles({
        query: inputValue.trim(),
      }).then((result) => {
        const options = result.data.suggestions.map((item) => ({
          value: item.role,
          label: item.role,
        }));

        callback(options);
      });
      return;
    },
    [fetchCustomerRoles],
  );

  const debouncedLoadOwnerRoles = useMemo(() => {
    return debounce(loadRoles, 500);
  }, [loadRoles]);

  const ccSelectValue = useMemo(() => {
    return value.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
  }, [value]);

  const handleCCChange = (
    value: MultiValue<{
      label: string;
      value: string;
    }>,
  ) => {
    setCCEmailValue('');
    setError('');
    setForceMenuClose(false);
    onChange(value.map((v) => v.value));
  };

  const handleCCKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!ccEmailValue) return;

    setForceMenuClose(false);

    setError('');

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    switch (e.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
      case ',':
        if (!emailRegex.test(ccEmailValue)) {
          setError('Invalid email address');
          setForceMenuClose(true);
          return;
        }

        if (value.length) {
          onChange([...value, ccEmailValue]);
        } else {
          onChange([ccEmailValue]);
        }

        setCCEmailValue('');
        e.preventDefault();
    }
  };

  return (
    <AsyncSelect
      className={className}
      inputValue={ccEmailValue}
      isMulti={true}
      onMenuOpen={() => {
        setIsMenuOpen(true);
      }}
      onMenuClose={() => {
        setIsMenuOpen(false);
      }}
      menuIsOpen={isMenuOpen && !forceMenuClose}
      onInputChange={(newValue, action) => {
        if (action.action === 'input-change') {
          setCCEmailValue(newValue);
        }
      }}
      components={{
        DropdownIndicator: () => null,
      }}
      ref={selectRef}
      loadOptions={debouncedLoadOwnerRoles}
      onChange={handleCCChange}
      onKeyDown={handleCCKeydown}
      value={ccSelectValue}
      placeholder={placeholder}
      styles={selectStyles}
      classNames={selectClasses}
    />
  );
};

export default CustomerRoleAndEmailMultiSelect;
