import { CreatePlanDialog } from '@/components/plan/CreatePlanDialog';
import { Button } from '@/components/ui/button';
import Empty from '@/components/ui/empty';
import Heading from '@/components/ui/heading';
import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useUserContext } from '@/contexts/UserContext';
import { useGetPlansByCompanyId } from '@/hooks/api-hooks/usePlanQuery';
import { IPlan } from '@/types/plan.types';
import { EyeOpenIcon } from '@radix-ui/react-icons';
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const columns: ColumnDef<IPlan>[] = [
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Name',
    cell: (info) => {
      const cellValue = info.getValue() as string;
      return (
        <span className="truncate text-ellipsis w-[200px]">
          {cellValue.split('').length > 20 ? `${cellValue.substring(0, 20)}...` : cellValue}
        </span>
      );
    },
  },
  {
    accessorKey: 'periodEndDate',
    header: ({ column }) => {
      return (
        <span
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Financial Year
          <ArrowUpDown className="w-3 h-3" />
        </span>
      );
    },
    cell: (info) => {
      const date = new Date(info.getValue() as string);
      return `Apr ${date.getFullYear() - 1} - Mar ${date.getFullYear()}`;
    },
  },
  {
    accessorKey: 'status',
    header: 'Actions',
    cell: (info) => {
      return (
        <Link to={`/plan/view/${info.row.original.id}`}>
          <Button variant="secondary" className="flex items-center justify-between gap-2 w-24">
            <EyeOpenIcon />
            View
          </Button>
        </Link>
      );
    },
  },
];

interface IPlansTable<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

const PlansTable = <TData, TValue>({ columns, data }: IPlansTable<TData, TValue>) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });
  return (
    <div>
      <Table>
        <TableHeader className=" bg-gray-100 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead className="px-6" key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell className="px-6" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const Plans = () => {
  const { companiesOfUser, activeCompanyIndex } = useUserContext();
  const { data, isLoading } = useGetPlansByCompanyId({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id,
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  if (isLoading) {
    return (
      <div>
        <Table>
          <TableHeader>
            <TableRow>
              {new Array(3).fill(0).map((_, i) => (
                <TableHead className="px-6" key={i + 'plan_header_rows'}>
                  <Skeleton className=" w-36  h-8" />
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {new Array(5).fill(0).map((_, i) => (
              <TableRow key={i + 'plan_table_rows'}>
                {new Array(3).fill(0).map((_, i) => (
                  <TableCell className="px-6" key={i + 'plan_body_rows'}>
                    <Skeleton className="w-36 h-8" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  if (!data?.data) {
    return <Empty title="No plans found" />;
  }

  return <PlansTable columns={columns} data={data.data.plans} />;
};

const PlanningListView = () => {
  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8">
      <Heading title="Planning" description="Check all your current and past plans here" cta={<CreatePlanDialog />} />
      <Plans />
    </div>
  );
};

export default PlanningListView;
