export const MONTH_ARRAY = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const QUARTER_ARRAY = ['Q1', 'Q2', 'Q3', 'Q4'];

export const HALF_YEAR_ARRAY = ['H1', 'H2'];

export const PNG_BASE64_PREFIX = 'data:image/png;base64,';

export const IGNORED_PLACEHOLDERS = ['REVERSED'];
