import { EditDiscount, EditInvoiceTds } from '@/components/invoices/InvoiceEditComponents';
import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { IInvoice } from '@/types/invoices.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { CardContent } from '@mui/material';
import InvoiceLineItemTable from './InvoiceLineItemTable';

const InvoiceTypeItems = ({ invoice, invoiceId }: { invoice: IInvoice; invoiceId: string }) => {
  return (
    <Card className=" my-8 ">
      <CardHeader className="border-b">
        <CardTitle className=" underline font-semibold ">{`${invoice.invoiceType ?? ''} items`}</CardTitle>
      </CardHeader>
      <CardContent>
        <InvoiceLineItemTable lineItems={invoice.lineItems} currencyType={invoice.invoiceCurrency} />
        <div className=" flex text-sm my-4 border-t-2 pt-4 ">
          <div className="min-w-[300px]  flex flex-col gap-2 ">
            <div className=" flex items-center justify-between">
              <span>Sub total:</span>
              <span className={cn(' font-semibold', invoice.invoiceSubtotal < 0 ? 'text-red-500' : 'text-green-700')}>
                {formatCurrencyByUnit(invoice.invoiceSubtotal, 'actual', invoice.invoiceCurrency)}
              </span>
            </div>
            <div className=" flex items-center justify-between gap-4">
              <div className="flex gap-4 items-center ">
                {invoice.discount?.isPostTax ? 'Post tax' : 'Pre tax'} Discount{' '}
                <EditDiscount
                  invoiceId={invoiceId}
                  defaultValue={invoice.discount}
                  currencyType={invoice.invoiceCurrency}
                />
              </div>
              <div>
                -
                {formatCurrencyByUnit(
                  invoice.discount && invoice.discount != null && invoice.discount.amount ? invoice.discount.amount : 0,
                  'actual',
                  invoice.invoiceCurrency,
                )}
              </div>
            </div>
            <div className=" flex items-center justify-between">
              <div>Tax</div>
              <div>{formatCurrencyByUnit(invoice.invoiceTax.total, 'actual', invoice.invoiceCurrency)}</div>
            </div>
            <div className=" flex items-center justify-between border-t-2">
              <CardTitle className="pt-4 font-semibold ">{`${invoice.invoiceType ?? ''} Total`}</CardTitle>
              <span className={cn('pt-4 font-semibold', invoice.invoiceTotal < 0 ? 'text-red-500' : 'text-green-700')}>
                {formatCurrencyByUnit(
                  invoice.invoiceSubtotal + invoice.invoiceTax.total - (invoice.discount?.amount ?? 0),
                  'actual',
                  invoice.invoiceCurrency,
                )}
              </span>
            </div>
            {invoice.invoiceTax.tds != undefined ? (
              <div className=" flex items-center justify-between">
                <div>TDS</div>
                <EditInvoiceTds invoiceId={invoiceId} defaultValue={String(invoice.invoiceTax.tds)} invoice={invoice} />
              </div>
            ) : (
              ''
            )}
            <div className=" flex items-center justify-between border-t-2">
              <CardTitle className="pt-4 font-semibold ">{`${invoice.invoiceType ?? ''} Total post TDS`}</CardTitle>
              <span className={cn('pt-4 font-semibold', invoice.invoiceTotal < 0 ? 'text-red-500' : 'text-green-700')}>
                {formatCurrencyByUnit(invoice.invoiceTotal, 'actual', invoice.invoiceCurrency)}
              </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default InvoiceTypeItems;
