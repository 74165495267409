import { Badge } from '@/components/ui/badge';
import { CopyToClipboard } from '@/components/ui/copy-clipboard';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { IOwner } from '@/types/contacts.types';
// import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@radix-ui/react-tooltip';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

import { InfoIcon, MailIcon, PhoneIcon } from 'lucide-react';

const Owner = ({ owner }: { owner: IOwner }) => {
  return (
    <div className=" flex gap-4 items-center min-w-[160px] ">
      <HoverCard>
        <HoverCardTrigger className=" text-sm cursor-pointer underline overflow-hidden text-ellipsis">
          {owner.name}
        </HoverCardTrigger>
        {!owner.isRegistered && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <InfoIcon className=" w-4 h-4 " />
              </TooltipTrigger>
              <TooltipContent>The user hasn&apos;t registered yet</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        <HoverCardContent>
          <div className=" flex flex-col gap-2 ">
            {!!owner.role && <Badge className="text-sm max-w-fit overflow-hidden text-ellipsis">{owner.role}</Badge>}
            {owner?.primaryEmail && (
              <div className="text-sm flex items-center gap-2 ">
                <MailIcon className="w-4 h-4 " />{' '}
                <p className="text-sm max-w-[140px] overflow-hidden text-ellipsis">{owner.primaryEmail}</p>
                <CopyToClipboard text={owner.primaryEmail} />
              </div>
            )}
            {owner?.primaryPhone && (
              <div className="text-sm flex items-center gap-2 ">
                <PhoneIcon className="w-4 h-4" />
                <p className="text-sm">{owner.primaryPhone}</p>
                <CopyToClipboard text={owner.primaryPhone} />
              </div>
            )}
          </div>
        </HoverCardContent>
      </HoverCard>
    </div>
  );
};

export default Owner;
