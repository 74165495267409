import { cn } from '@/lib/utils';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from './select';

interface IOption {
  value: string | number;
  label: string;
}
const SelectComponent = ({
  options,
  onChange,
  placeholder,
  value,
  className,
  viewPortClassName,
  selectComponentClassName,
  onFocus,
  onBlur,
  disabled = false,
  isLoading = false,
  ref,
}: {
  options: IOption[];
  onChange?: (_: string) => void;
  placeholder?: string;
  value?: string | number;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  viewPortClassName?: string;
  selectComponentClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  ref?: React.ForwardedRef<HTMLButtonElement>;
}) => {
  const handleOpenChange = (open: boolean) => {
    if (open) {
      onFocus?.();
    }
    if (!open) {
      onBlur?.();
    }
  };

  return (
    <Select disabled={disabled} onOpenChange={handleOpenChange} value={String(value)} onValueChange={onChange}>
      <SelectTrigger
        ref={ref}
        isLoading={isLoading}
        className={cn('w-[180px]', className, !value && 'text-muted-foreground')}
      >
        <SelectValue onFocus={onFocus} onBlur={onBlur} placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent viewPortClassName={viewPortClassName}>
        <SelectGroup className={selectComponentClassName}>
          {options.map((option) => (
            <SelectItem key={option.value} value={String(option.value)}>
              {option.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default SelectComponent;
