import BankIdToName from '@/components/shared/BankIdToName';
import CategoryIdToLabel from '@/components/shared/CategoryIdToLabel';
import Pagination from '@/components/shared/Pagination';
import PlaceholderIdToName from '@/components/shared/PlacholderIdToName';
import { ColumnSelector } from '@/components/shared/ReactTableColumnSelector';
import TableSkeleton from '@/components/shared/TableSkeleton';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useToast } from '@/components/ui/use-toast';
import { useUserContext } from '@/contexts/UserContext';
import { useSearchParams } from 'react-router-dom';

import {
  useExportCredit,
  useGetCreditedBankTransactions,
  useGetCreditsSummary,
  useGetPostedInvoicesForCredits,
  useGetPostingHistoryForCredit,
} from '@/hooks/api-hooks/usePostingQuery';
import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { cn } from '@/lib/utils';
import {
  ICredit,
  ICreditsFilters,
  IPostedFieldUpdates,
  IPostedToCreditObject,
  IPostingHistory,
} from '@/types/posting.types';
import {
  CREDITS_TABLE_COLUMN_ORDER_KEY,
  CREDITS_TABLE_COLUMNS_VISIBILITY_KEY,
} from '@/utils/constants/local-storage-keys';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Table as ReactTable,
  Row,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ChevronDown, ChevronRight, GripVerticalIcon, History, Pencil, XIcon } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import CreditFilters from '../../credits/CreditsFilters';
import { MarkCreditAsReversedDialog, MarkCreditAsUnReversedDialog } from '../../credits/MarkCreditReversed';
import { PostedToCreditItem } from './posted-items';

function getHistory(data: IPostedFieldUpdates[], field: string) {
  const entry = data.find((entry) => entry.field === field);
  return entry
    ? {
        newValue: entry.newValue,
        oldValue: entry.oldValue,
      }
    : {
        newValue: null,
        oldValue: null,
      };
}

const HistoryAnnotation = ({ posting }: { posting: IPostingHistory }) => {
  const isUpdate = posting.event === 'UPDATE';
  const postingValues = getHistory(posting.fieldsUpdates, 'posted_amount');
  const isCreate = isUpdate && !isNaN(Number(postingValues.oldValue)) && Number(postingValues.oldValue) === 0;

  if (posting.event === 'CREATE' || isCreate) {
    return (
      <>
        <h2>
          {formatCurrencyByUnit(Number(getHistory(posting.fieldsUpdates, 'posted_amount').newValue), 'actual')} posted
          to{' '}
          <span className=" capitalize ">
            {posting.placeholderId ? <PlaceholderIdToName placeholderId={posting.placeholderId} /> : 'Invoice'}
          </span>
        </h2>
        {!!posting.userName && <h2 className="font-extralight">By {posting.userName}</h2>}
      </>
    );
  }

  if (posting.event === 'UPDATE') {
    const postingValues = getHistory(posting.fieldsUpdates, 'posted_amount');

    if (postingValues.newValue === postingValues.oldValue) {
      return null;
    }

    return (
      <>
        <h2>
          {formatCurrencyByUnit(Number(getHistory(posting.fieldsUpdates, 'posted_amount').oldValue), 'actual')} was
          updated to{' '}
          {formatCurrencyByUnit(Number(getHistory(posting.fieldsUpdates, 'posted_amount').newValue), 'actual')} for{' '}
          <span className=" capitalize ">
            {posting.placeholderId ? <PlaceholderIdToName placeholderId={posting.placeholderId} /> : 'Invoice'}
          </span>
        </h2>
        {!!posting.userName && <h2 className="font-extralight">By {posting.userName}</h2>}
      </>
    );
  }

  if (posting.event === 'DELETE') {
    return (
      <>
        <h2>
          {formatCurrencyByUnit(Number(getHistory(posting.fieldsUpdates, 'posted_amount').oldValue), 'actual')} was
          unposted for{' '}
          <span className=" capitalize ">
            {posting.placeholderId ? <PlaceholderIdToName placeholderId={posting.placeholderId} /> : 'Invoice'}
          </span>
        </h2>
        {!!posting.userName && <h2 className="font-extralight">By {posting.userName}</h2>}
      </>
    );
  }

  return null;
};

const HistoryItemWrapper = ({ posting }: { posting: IPostingHistory }) => {
  const isUpdate = posting.event === 'UPDATE';
  const postingValues = getHistory(posting.fieldsUpdates, 'posted_amount');

  if (isUpdate && postingValues.newValue === postingValues.oldValue) {
    return null;
  }

  return (
    <div className="pl-4">
      <h2 className="font-extralight">{}</h2>
      <div className="flex gap-4 mt-2">
        <div className="pr-4 border-r-[2px] max-w-24 min-w-24">
          <h2>{dayjs(posting.timestamp).add(330, 'm').format('DD MMM YYYY')}</h2>
          <h2 className="font-extralight">{dayjs(posting.timestamp).add(330, 'm').format('HH:mm:ss a')}</h2>
        </div>
        <div className="flex-2">
          <HistoryAnnotation posting={posting} />
        </div>
      </div>
    </div>
  );
};

const TransactionHistory = ({
  setIsOpen,
  creditId,
  narration,
  bankId,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  creditId: string;
  narration: string;
  bankId: string;
}) => {
  const { data: creditsPostingHistory, isLoading } = useGetPostingHistoryForCredit({
    bat: creditId,
  });

  if (isLoading) {
    return (
      <div className="m-auto text-sm overflow-auto">
        <div className="flex justify-between p-4">
          <div>
            <h1 className="text-xl font-bold">History</h1>
            <h1 className="font-extralight">
              <BankIdToName bankId={bankId} />
            </h1>
            <h1 className="font-extralight">{narration}</h1>
          </div>
          <Button variant="ghost" onClick={() => setIsOpen(false)} size="icon">
            <XIcon className="w-4 h-4" />
          </Button>
        </div>
        {new Array(5).fill(0).map((_, index) => {
          return (
            <>
              <div className="pl-4" key={index}>
                <h2 className="font-extralight">{}</h2>

                <div className="flex gap-4 mt-2">
                  <div className="pr-4 border-r-[2px] flex flex-col gap-1">
                    <Skeleton className="w-24 h-5" />
                    <Skeleton className="w-24 h-5" />
                  </div>
                  <div className="flex-1 flex flex-col gap-1 pr-4">
                    <Skeleton className="w-full h-5" />
                    <Skeleton className="w-full h-5" />
                    <Skeleton className="w-full h-5" />
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    );
  }

  return (
    <div className="text-sm flex flex-col h-full overflow-hidden">
      <div className="flex justify-between p-4">
        <div>
          <h1 className="text-lg font-bold">History</h1>
          <h1 className="font-extralight">
            <BankIdToName bankId={bankId} />
          </h1>
          <h1 className="font-extralight">{narration}</h1>
        </div>
        <Button variant="ghost" onClick={() => setIsOpen(false)} size="icon">
          <XIcon className="w-4 h-4" />
        </Button>
      </div>
      <div className=" flex flex-1 overflow-scroll flex-col gap-1 pb-8 ">
        {creditsPostingHistory?.data
          .toSorted((a, b) => dayjs(b.timestamp).valueOf() - dayjs(a.timestamp).valueOf())
          .map((posting, index) => <HistoryItemWrapper posting={posting} key={index} />)
          .filter(Boolean)}
      </div>
    </div>
  );
};

const Postings = ({ postedInvoices }: { postedInvoices: IPostedToCreditObject[] }) => {
  return (
    <>
      {postedInvoices.map((postedInvoice, index) => (
        <Card className="mb-4" key={index}>
          <CardContent className="flex flex-col gap-4 ml-2 p-2">
            <PostedToCreditItem postedToCreditObject={postedInvoice} />
          </CardContent>
        </Card>
      ))}
    </>
  );
};

const PostingHistoryCard = ({
  creditId,
  narration,
  bankId,
  placehodlerId,
}: {
  creditId: string;
  narration: string;
  bankId: string;
  placehodlerId?: string;
}) => {
  const [transactionHistoryOpen, setTransactionHistoryOpen] = useState(false);

  const { data: postedInvoicesData, isLoading } = useGetPostedInvoicesForCredits({
    bat: creditId,
    placeholderId: placehodlerId,
  });

  const postedInvoices = useMemo(() => {
    return postedInvoicesData?.data || [];
  }, [postedInvoicesData]);

  if (isLoading) {
    return (
      <div className="ml-[52px] py-4 flex flex-col gap-1 text-sm">
        <div className=" flex justify-between items-center p-0">
          <span className=" flex font-semibold gap-2  ">
            Postings <Skeleton className=" w-12 h-5 " />
          </span>
          <div>
            <Button
              className="text-blue-700 underline disabled"
              variant="ghost"
              icon={<Pencil className="w-4 h-4" />}
              iconPosition="left"
              size={'md'}
            >
              Edit
            </Button>
            <Button
              className="text-blue-700 underline"
              variant="ghost"
              icon={<History className="w-4 h-4" />}
              iconPosition="left"
              size={'md'}
              onClick={() => setTransactionHistoryOpen(true)}
            >
              History
            </Button>
          </div>
        </div>
        <div className=" flex flex-col gap-4 ">
          {new Array(3).fill(0).map((_, index) => (
            <Card key={index}>
              <CardContent className="flex flex-col gap-4 ml-2 p-2">
                <div className="flex justify-between items-center py-2  ">
                  <div className=" flex flex-col gap-1 ">
                    <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                  </div>
                  <div className=" flex flex-col gap-1 ">
                    <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                  </div>
                  <div className=" flex flex-col gap-1 ">
                    <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                  </div>
                  <div className=" flex flex-col gap-1 ">
                    <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className="ml-[52px] py-4 flex flex-col gap-1 text-sm">
      <div className=" flex justify-between items-center p-0">
        <CardTitle className=" font-semibold">Postings ({postedInvoices.length})</CardTitle>
        {postedInvoices.length > 0 && (
          <div>
            <Button
              className="text-blue-700 underline"
              variant="ghost"
              icon={<Pencil className="w-4 h-4" />}
              iconPosition="left"
              size={'md'}
              onClick={() =>
                window.open(
                  `/posting/credit/${creditId}?paymentStatus=PARTIALLY_PAID%3APartially+Paid%2CUNPAID%3AUnpaid&defaultListType=posted`,
                )
              }
            >
              Edit
            </Button>
            <Button
              className="text-blue-700 underline"
              variant="ghost"
              icon={<History className="w-4 h-4" />}
              iconPosition="left"
              size={'md'}
              onClick={() => setTransactionHistoryOpen(true)}
            >
              History
            </Button>
          </div>
        )}
      </div>
      <Postings postedInvoices={postedInvoices} />
      <Sheet open={transactionHistoryOpen} onOpenChange={setTransactionHistoryOpen}>
        <SheetContent side="right" className="p-0 h-[90%] mt-24 border">
          <TransactionHistory
            bankId={bankId}
            narration={narration}
            setIsOpen={setTransactionHistoryOpen}
            creditId={creditId}
          />
        </SheetContent>
      </Sheet>
    </div>
  );
};

const CreditTable = ({
  isLoading,
  table,
  credits,
  handleExpand,
  placehodlerId,
}: {
  isLoading: boolean;
  table: ReactTable<ICredit>;
  credits: ICredit[];
  handleExpand: (_: Row<ICredit>) => () => void;
  placehodlerId?: string;
}) => {
  const sizes = table.getState().columnSizingInfo;

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    return headers.reduce(
      (acc, current) => {
        return {
          ...acc,
          [`--col-${current.column.id}-size`]: current.column.getSize(),
          [`--header-${current.id}-size`]: current.getSize(),
        };
      },
      {} as {
        [key: string]: number;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);

  if (isLoading) {
    return <TableSkeleton rows={10} columns={10} />;
  }

  return (
    <Table
      style={{
        ...columnSizeVars,
        width: table.getTotalSize(),
      }}
      className="flex-1 gap-1"
    >
      {credits.length === 0 && <TableCaption className="pb-10">No data found</TableCaption>}
      <TableHeader className=" bg-gray-100">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow className="flex items-center" key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  style={{
                    width: `calc(var(--header-${header?.id}-size) * 1.52px)`,
                  }}
                  className={cn(
                    'flex items-center justify-between',
                    header.column.id === 'select' && 'border-x flex items-center justify-center px-0',
                  )}
                  key={header.id}
                >
                  <div className="break-keep truncate text-ellipsis">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                  {!['view', 'select'].includes(header.id) && (
                    <GripVerticalIcon
                      {...{
                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: cn(
                          'w-4 h-4 cursor-ew-resize min-w-[18px]',
                          header.column.getIsResizing() ? 'opacity-100' : 'opacity-50',
                        ),
                      }}
                    />
                  )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody className="py-4">
        {table.getRowModel().rows.map((row) => (
          <>
            <TableRow className={cn(row.original.postedAmount > 0 ? ' flex cursor-pointer' : 'flex')} key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  onClick={cell.column.id === 'select' ? undefined : handleExpand(row)}
                  style={{
                    width: `calc(var(--col-${cell.column.id}-size) * 1.52px)`,
                  }}
                  className={cn(
                    'truncate whitespace-nowrap overflow-ellipsis',
                    cell.column.id === 'select' && 'border-x flex items-center justify-center px-0',
                  )}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
            {row.getIsExpanded() && (
              <PostingHistoryCard
                // createdBY={row.original.postedBy}
                creditId={row.original.id}
                narration={row.original.narration}
                bankId={row.original.bankAccountId}
                placehodlerId={placehodlerId}
              />
            )}
          </>
        ))}
      </TableBody>
    </Table>
  );
};

const CreditDetails = ({ filters, page, limit }: { filters: ICreditsFilters; page: number; limit: number }) => {
  const { data: creditSummary, isLoading } = useGetCreditsSummary({
    ...filters,
    page: page,
    limit: limit,
    customConfig: {},
  });

  if (isLoading) {
    return (
      <>
        <h2 className="">
          <div className="flex gap-2 ">
            <span className=" text-lg font-semibold  ">Credits</span>
            <Skeleton className=" h-4 w-[400px] mt-2" />
          </div>
        </h2>
        <p className=" text-sm text-muted-foreground mb-2 ">Ledger for posted credits</p>
      </>
    );
  }
  return (
    <>
      <h2 className=" text-lg font-semibold   ">
        Credits{' '}
        <span className=" text-sm font-normal ">
          (Count: {creditSummary?.data.count}, Available For Posting:
          {formatCurrencyByUnit(creditSummary?.data.totalRemaining || 0, 'actual')}, Total:{' '}
          {formatCurrencyByUnit(creditSummary?.data.totalValue || 0, 'actual')})
        </span>
      </h2>
      <p className=" text-sm text-muted-foreground mb-2 ">Ledger for posted credits</p>
    </>
  );
};

const CreditLedgerTableWrapper = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [filters, setFilters] = useState<ICreditsFilters>({
    bankAccountIds: [],
    postingStatus: ['POSTED', 'PARTIALLY_POSTED'],
    categories: [],
  });

  const sheetRef = useRef<HTMLDivElement>(null);

  const columns = useMemo<ColumnDef<ICredit>[]>(() => {
    return [
      {
        id: 'view',
        header: '',
        cell: ({ row }) => (
          <>
            {row.original.postedAmount > 0 ? (
              row.getIsExpanded() ? (
                <ChevronDown className="cursor-pointer w-4 h-4" aria-label="Select row" />
              ) : (
                <ChevronRight className="cursor-pointer w-4 h-5" aria-label="Select row" />
              )
            ) : (
              ''
            )}
          </>
        ),
        size: 50,
      },
      {
        id: 'markReverse',
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || table.getIsSomePageRowsSelected()}
            className={cn(table.getIsSomePageRowsSelected() && 'opacity-50')}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        label: 'Checkbox',
        maxSize: 50,
      },
      {
        header: 'Transaction Date',
        id: 'transactionDate',
        accessorKey: 'transactionDate',
        minSize: 30,

        cell: ({ getValue }) => {
          return (
            <div className="  ">
              <div className="   truncate text-ellipsis">{dayjs(getValue() as string).format('DD MMM YYYY')}</div>
            </div>
          );
        },
      },
      {
        header: 'Bank',
        id: 'bank',
        accessorKey: 'bankAccountId',
        minSize: 30,
        cell: ({ getValue }) => {
          return (
            <div className=" truncate text-ellipsis ">
              <div className="   truncate text-ellipsis">{<BankIdToName bankId={getValue() as string} />}</div>
            </div>
          );
        },
      },
      {
        header: 'Category',
        id: 'category',
        accessorKey: 'category',
        minSize: 30,
        cell: ({ getValue }) => {
          return <ToolTipCell value={<CategoryIdToLabel categoryId={getValue() as string} />} />;
        },
      },
      {
        header: 'Narration',
        id: 'narration',
        accessorKey: 'narration',
        minSize: 30,
        cell: ({ getValue }) => {
          return <ToolTipCell value={getValue() as string} />;
        },
      },
      {
        header: 'Total Credit',
        id: 'creditAmount',
        accessorKey: 'creditAmount',
        minSize: 30,
        cell: ({ getValue }) => {
          return (
            <div className="  ">
              <div className="   truncate text-ellipsis">{formatCurrencyByUnit(getValue() as number, 'actual')}</div>
            </div>
          );
        },
      },
      {
        header: 'Posted Credit',
        id: 'postedAmount',
        accessorKey: 'postedAmount',
        minSize: 30,
        cell: ({ getValue }) => {
          return (
            <div className="  ">
              <div className="truncate text-ellipsis">{formatCurrencyByUnit(getValue() as number, 'actual')}</div>
            </div>
          );
        },
      },
      {
        header: 'Available For Posting',
        id: 'remainingAmount',
        accessorKey: 'remainingAmount',
        minSize: 30,
        cell: ({ getValue }) => {
          return (
            <div className="  ">
              <div className="truncate text-ellipsis">{formatCurrencyByUnit(getValue() as number, 'actual')}</div>
            </div>
          );
        },
      },
      {
        header: 'Posting Status',
        id: 'postingStatus',
        accessorKey: 'postingStatus',
        minSize: 30,
        cell: ({ getValue }) => {
          return (
            <div className="">
              <div className="truncate text-ellipsis capitalize">
                <Badge
                  className={cn(
                    getValue() === 'POSTED' && 'bg-green-500 hover:bg-green-500 hover:text-white text-white',
                    getValue() === 'UNPOSTED' && 'bg-red-500 text-white hover:bg-red-500 hover:text-white',
                    getValue() === 'PARTIALLY_POSTED' && 'bg-yellow-400 text-black hover:bg-yellow-400',
                  )}
                >
                  {(getValue() as string).split('_').join(' ').toLowerCase()}
                </Badge>
              </div>
            </div>
          );
        },
      },
    ];
  }, []);

  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { data: creditsData, isLoading } = useGetCreditedBankTransactions({
    limit: pageLimit,
    page: page,
    ...filters,
    companyId: companiesOfUser[activeCompanyIndex]?.id || '',
    customConfig: {},
  });

  const credits = useMemo(() => {
    return creditsData?.data.docs || [];
  }, [creditsData]);

  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);
  const defaultColumnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const { storedValue: columnVisibility, setValue: setColumnVisibility } = useLocalStorage(
    CREDITS_TABLE_COLUMNS_VISIBILITY_KEY,
    defaultColumnVisibility,
  );

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    CREDITS_TABLE_COLUMN_ORDER_KEY,
    defaultColumnOrder,
  );

  const table = useReactTable({
    columns,
    data: credits,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
      columnOrder,
    },
    defaultColumn: {
      size: 100,
    },
  });

  const tableColumns = useMemo(() => table.getAllColumns(), [table]);

  const { toast } = useToast();

  const handleExpand = (row: Row<ICredit>) => () => {
    row.toggleExpanded();
  };

  const { mutate: exportCredits } = useExportCredit();

  const handleClearSelection = useCallback(() => {
    table.resetRowSelection();
  }, [table]);

  const handleSubmit = useCallback(
    (filters: ICreditsFilters) => {
      handleClearSelection();
      setFilters(filters);
    },
    [handleClearSelection],
  );

  const isSomeRowSelected = table.getIsSomeRowsSelected();
  const isAllRowSelected = table.getIsAllRowsSelected();
  const selectedRows = table.getSelectedRowModel().flatRows;

  const selectedCreditIds = useMemo(() => {
    return selectedRows.map((row) => row.original.id);
  }, [selectedRows]);

  const showExcludeModal = useMemo(() => {
    return (
      (isSomeRowSelected || isAllRowSelected) &&
      selectedRows.every((row) => {
        return !row.original.isReversed;
      })
    );
  }, [selectedRows, isSomeRowSelected, isAllRowSelected]);

  const showIncludeModal = useMemo(() => {
    return (
      (isSomeRowSelected || isAllRowSelected) &&
      selectedRows.every((row) => {
        return row.original.isReversed;
      })
    );
  }, [selectedRows, isSomeRowSelected, isAllRowSelected]);

  const handleSubmitFilter = () => {
    const startDate = filters?.dateRange?.startDate;
    const endDate = filters?.dateRange?.endDate;
    const amountMax = filters?.maxAmount;
    const amountMin = filters?.minAmount;
    const filtersForExport = {
      ...filters,
      startDate,
      endDate,
      amountMax,
      amountMin,
    };
    exportCredits(filtersForExport, {
      //TODO: config should be in definition
      onSuccess: () => {
        toast({
          description: 'Credit ledger export initiated.You will receive an email with the file shortly.',
        });
      },
      onError: () => {
        toast({
          description: `Failed to export Credit Ledger`,
          variant: 'destructive',
        });
      },
    });
  };
  const placeholderId = searchParams.get('placeholderId') || undefined;

  return (
    <>
      <div className="border-b mb-6 ">
        <div className="flex justify-between items-center">
          <div>
            <CreditDetails filters={filters} page={page} limit={pageLimit} />
          </div>
          <div className=" gap-2 flex ">
            {showExcludeModal && (
              <MarkCreditAsReversedDialog creditIds={selectedCreditIds} clearAll={handleClearSelection} />
            )}
            {showIncludeModal && (
              <MarkCreditAsUnReversedDialog creditIds={selectedCreditIds} clearAll={handleClearSelection} />
            )}
            <ColumnSelector columns={tableColumns} columnOrder={columnOrder} setColumnOrder={setColumnOrder} />
            <Button className="" onClick={handleSubmitFilter}>
              Export
            </Button>
          </div>
        </div>
        <CreditFilters
          showCustomerFilter
          showNarrationFilter
          filters={filters}
          handleSubmit={handleSubmit}
          listType={'ledger'}
        />
      </div>
      <div className=" flex-1 flex items-start " ref={sheetRef}>
        <div className=" overflow-auto ">
          <CreditTable
            credits={credits}
            handleExpand={handleExpand}
            isLoading={isLoading}
            table={table}
            placehodlerId={placeholderId}
          />
        </div>
      </div>
      <Pagination
        hasNext={!!creditsData?.data.hasNext}
        hasPrev={!!creditsData?.data.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setPageLimit}
        pageNumber={page}
        rowsPerPage={pageLimit}
        totalPages={creditsData?.data.totalPages || 0}
      />
    </>
  );
};

export default CreditLedgerTableWrapper;
