import SelectCategoryDropdown from '@/components/bank-transactions/SelectCategoryDropdown';
import { RangedInputWithLabel } from '@/components/shared/ClubbedSelectWithRange';
import FilterList from '@/components/shared/filters/FilterList';
import FilterRow from '@/components/shared/filters/FilterRow';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { DatePickerWithRange } from '@/components/ui/multi-datepicker';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useUserContext } from '@/contexts/UserContext';
import { useGetBankAccountsForCompany } from '@/hooks/api-hooks/useBankAccountQuery';
import { useGetCategoriesByCompanyQuery } from '@/hooks/api-hooks/useCategoryQuery';
import { useGetCustomerSuggestionsMutation } from '@/hooks/api-hooks/useCustomerQuery';
import { useGetCreditPlaceholders } from '@/hooks/api-hooks/usePostingQuery';
import { useGetCategory } from '@/hooks/utils/useGetCategory';
import { useGetBankName } from '@/hooks/utils/usetGetBankName';
import { IOptions } from '@/types/common.types';
import { ICreditsFilters, IPostingStatusOption, PostingStatus } from '@/types/posting.types';
import { IGNORED_PLACEHOLDERS } from '@/utils/constants';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import dayjs from 'dayjs';
import { XIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useSearchParams } from 'react-router-dom';
import Select, { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { PostingStatusDropDown } from './CreditPostingStatusDropdown';
import { PostedToDropDown } from './PostedToDropdown';

const CreditFilters = ({
  handleSubmit,
  filters,
  showTabs,
  handleListTypeChange,
  listType,
  totalCount = 0,
  postedCount = 0,
  showCustomerFilter = false,
}: {
  handleSubmit: (_: ICreditsFilters) => void;
  filters: ICreditsFilters;
  showTabs?: boolean;
  handleListTypeChange?: (_: string) => void;
  listType?: 'posted' | 'all' | 'ledger';
  totalCount?: number;
  postedCount?: number;
  showNarrationFilter?: boolean;
  showCustomerFilter?: boolean;
}) => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<IOptions[]>([]);
  const [selectedBankAccounts, setSelectedBankAccounts] = useState<IOptions[]>([]);
  const { companiesOfUser, activeCompanyIndex } = useUserContext();
  const [selectedPostingStatus, setSelectedPostedStatus] = useState<IPostingStatusOption[]>([]);
  const [selectedPostedTo, setSelectedPostedTo] = useState<IOptions>();

  const [narration, setNarration] = useState('');
  const [rangedAmount, setRangedAmount] = useState<{
    value: [number | undefined, number | undefined];
  }>({
    value: [undefined, undefined],
  });

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: bankAccountsResponse } = useGetBankAccountsForCompany({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const { data: categoriesResponse } = useGetCategoriesByCompanyQuery({
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const banksMap = useMemo(() => {
    return bankAccountsResponse?.data.bankAccounts.reduce(
      (acc, bank) => {
        acc[bank.id] = bank.name;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [bankAccountsResponse]);

  const categoriesMap = useMemo(() => {
    return categoriesResponse?.data.reduce(
      (acc, category) => {
        acc[category.id] = category.value;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [categoriesResponse]);

  const postingStatusMap = useMemo<Record<PostingStatus, string>>(
    () => ({
      PARTIALLY_POSTED: 'Partially posted',
      POSTED: 'Posted',
      UNPOSTED: 'Unposted',
      EXCLUDED: 'Reversed',
    }),
    [],
  );

  const [dateRange, setDateRange] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });

  const { mutateAsync: fetchCustomers } = useGetCustomerSuggestionsMutation({});

  useEffect(() => {
    if (filters.postingStatus.length) {
      setSelectedPostedStatus(
        filters.postingStatus.map((status) => ({
          label: postingStatusMap[status],
          value: status,
        })),
      );
    }

    if (filters.bankAccountIds.length && banksMap) {
      setSelectedBankAccounts(
        filters.bankAccountIds.map((id) => ({
          label: banksMap[id],
          value: id,
        })),
      );
    }

    if (filters.dateRange?.startDate && filters.dateRange?.endDate) {
      setDateRange({
        from: filters.dateRange.startDate,
        to: filters.dateRange.endDate,
      });
    }

    if (filters.narration) {
      setNarration(filters.narration);
    }

    if (filters.minAmount !== undefined && filters.maxAmount !== undefined) {
      setRangedAmount({ value: [filters.minAmount, filters.maxAmount] });
    }

    if (filters.customers?.length) {
      setSelectedCustomers(filters.customers);
    }

    if (filters.categories.length) {
      setSelectedCategories(filters.categories);
    }
  }, [filters, postingStatusMap, banksMap]);

  const handlePostingStatusSelect = (option: IPostingStatusOption) => () => {
    if (option.value === 'EXCLUDED') {
      if (selectedPostingStatus.find((item) => item.value === option.value)) {
        setSelectedPostedStatus(selectedPostingStatus.filter((item) => item.value !== option.value));
      } else {
        setSelectedPostedStatus([
          {
            label: option.label,
            value: option.value,
          },
        ]);
      }
      return;
    }

    if (selectedPostingStatus.find((item) => item.value === option.value)) {
      setSelectedPostedStatus(selectedPostingStatus.filter((item) => item.value !== option.value));
    } else {
      setSelectedPostedStatus([
        ...selectedPostingStatus,
        {
          label: option.label,
          value: option.value,
        },
      ]);
    }
  };

  const { data: placeholders } = useGetCreditPlaceholders();
  const creditPlaceholder = useMemo(() => {
    return placeholders?.data.filter((item) => !IGNORED_PLACEHOLDERS.includes(item.name));
  }, [placeholders]);
  const handlePostedToSelect = (option: IOptions) => {
    setSelectedPostedTo(option);
  };

  useEffect(() => {
    const selectedPlaceholderId = searchParams.get('placeholderId');
    if (!selectedPlaceholderId) {
      return;
    }
    const selectedPlaceholder = creditPlaceholder?.find((item) => item.id == selectedPlaceholderId);
    if (!selectedPlaceholder) {
      return;
    }
    const selectedOption: IOptions = {
      value: selectedPlaceholder?.id,
      label: selectedPlaceholder?.name,
    };
    setSelectedPostedTo(selectedOption);
  }, [creditPlaceholder, searchParams]);

  const handleReset = () => {
    setDateRange({
      from: undefined,
      to: undefined,
    });
    setSelectedBankAccounts([]);
    setSelectedCustomers([]);

    let postingStatus: PostingStatus[] = [];

    if (listType === 'ledger') {
      postingStatus = ['PARTIALLY_POSTED', 'POSTED'];
    } else if (listType === 'posted') {
      postingStatus = ['POSTED'];
    } else if (listType === 'all') {
      postingStatus = ['PARTIALLY_POSTED', 'UNPOSTED'];
    }

    handleSubmit({
      bankAccountIds: [],
      isPostedToInvoice: undefined,
      dateRange: {
        startDate: undefined,
        endDate: undefined,
      },
      postingStatus: postingStatus,
      categories: [],
      customers: [],
      narration: undefined,
      maxAmount: undefined,
      minAmount: undefined,
      placeholderId: undefined,
    });
    setRangedAmount({ value: [undefined, undefined] });
    setNarration('');
    setSelectedPostedStatus(postingStatus.map((item) => ({ label: postingStatusMap[item], value: item })));
    setSelectedPostedTo(undefined);
    setSelectedCategories([]);
    setSearchParams(
      {
        postingStatus: postingStatus.map((item) => item).join(','),
      },
      {
        replace: true,
      },
    );
  };
  useEffect(() => {
    handleSubmit({
      bankAccountIds:
        searchParams
          .get('bankAccountIds')
          ?.split(',')
          .filter((item) => !!item)
          .map((account) => account) || [],
      dateRange: {
        startDate: searchParams.get('dateRangeStart') ? dayjs(searchParams.get('dateRangeStart')).toDate() : undefined,
        endDate: searchParams.get('dateRangeEnd') ? dayjs(searchParams.get('dateRangeEnd')).toDate() : undefined,
      },
      customers:
        searchParams
          .get('customers')
          ?.split(',')
          .filter((item) => !!item)
          .map((item) => ({
            value: item.split(':')[0],
            label: item.split(':')[1],
          })) || [],
      postingStatus:
        searchParams
          .get('postingStatus')
          ?.split(',')
          .filter((item) => !!item)
          .map((item) => item as PostingStatus) || [],
      categories:
        searchParams
          .get('categories')
          ?.split(',')
          .filter((item) => !!item) || [],
      narration: searchParams.get('narration') || undefined,
      minAmount: searchParams.get('minAmount') ? Number(searchParams.get('minAmount')) : undefined,
      maxAmount: searchParams.get('maxAmount') ? Number(searchParams.get('maxAmount')) : undefined,
      isPostedToInvoice: searchParams.get('isPostedToInvoice') === 'true' || undefined,
      placeholderId: searchParams.get('placeholderId') || undefined,
    });
  }, [searchParams, handleSubmit]);

  const handleDateRangeChange = (value: DateRange | undefined) => {
    if (value) {
      setDateRange(value);
    }
    setDateRange({
      from: value?.from,
      to: value?.to,
    });
  };

  const handleBankAccountsChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((account) => ({ value: account.value, label: account.label }));
    setSelectedBankAccounts(newValue);
  };

  const onSubmit = () => {
    handleSubmit({
      bankAccountIds: selectedBankAccounts.map((account) => account.value),
      dateRange: dateRange ? { startDate: dateRange.from, endDate: dateRange.to } : undefined,
      postingStatus: selectedPostingStatus.map((item) => item.value),
      categories: selectedCategories,
      narration: narration,
      minAmount: rangedAmount.value[0],
      maxAmount: rangedAmount.value[1],
      customers: selectedCustomers,
      isPostedToInvoice: selectedPostedTo?.value === 'INVOICE' ? true : undefined,
      placeholderId:
        selectedPostedTo && !['INVOICE', 'ALL'].includes(selectedPostedTo?.value) ? selectedPostedTo.value : undefined,
    });

    setSearchParams(
      {
        bankAccountIds: selectedBankAccounts.map((account) => account.value).join(','),
        dateRangeStart: dateRange.from ? dayjs(dateRange.from).format('YYYY-MM-DD') : '',
        dateRangeEnd: dateRange.to ? dayjs(dateRange.to).format('YYYY-MM-DD') : '',
        postingStatus: selectedPostingStatus.map((item) => item.value).join(','),
        customers: selectedCustomers.map((item) => `${item.value}:${item.label}`).join(','),
        categories: selectedCategories.join(','),
        narration: narration,
        minAmount: rangedAmount.value[0]?.toString() || '',
        maxAmount: rangedAmount.value[1]?.toString() || '',
        isPostedToInvoice: selectedPostedTo?.value === 'INVOICE' ? 'true' : '',
        placeholderId:
          selectedPostedTo && !['INVOICE', 'ALL'].includes(selectedPostedTo?.value) ? selectedPostedTo.value : '',
      },
      {
        replace: true,
      },
    );
  };

  const handleAmountSelect = (amount: [number | undefined, number | undefined]) => {
    setRangedAmount({ value: amount });
  };

  const bankAccounts = useMemo(() => {
    return bankAccountsResponse?.data.bankAccounts || [];
  }, [bankAccountsResponse]);

  const handleCategoriesChange = useCallback((value: string[]) => {
    const newValue = [...value];
    setSelectedCategories(newValue);
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, IOptions>();
  }, []);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchCustomers({
        query: inputValue.trim(),
      });
      return result.data.suggestions.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    [fetchCustomers],
  );

  const handleCustomerSelectChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((account) => ({ value: account.value, label: account.label }));
    setSelectedCustomers(newValue);
  };

  return (
    <div className="mb-6">
      <div className="flex gap-4 flex-wrap mb-2">
        {!!showTabs && (
          <Tabs onValueChange={handleListTypeChange} value={listType}>
            <TabsList>
              <TabsTrigger value="all">All({totalCount})</TabsTrigger>
              <TabsTrigger value="posted">Posted({postedCount})</TabsTrigger>
            </TabsList>
          </Tabs>
        )}
        {listType !== 'posted' && (
          <>
            <DatePickerWithRange
              placeholder="Select transaction date"
              value={dateRange}
              onChange={handleDateRangeChange}
              buttonClass=" max-w-[250px] overflow-hidden  "
            />
            <Select
              onChange={handleBankAccountsChange}
              value={selectedBankAccounts}
              className=" max-w-[200px] text-sm shadow-sm"
              classNames={selectClasses}
              isMulti={true}
              name="bank-accounts"
              options={bankAccounts.map((account) => ({ value: account.id, label: account.name }))}
              classNamePrefix="select"
              placeholder="Select bank accounts"
              styles={selectStyles}
            />
            <SelectCategoryDropdown
              defaultSelectedCategories={[]}
              selectedCategories={selectedCategories}
              onChange={handleCategoriesChange}
              categoriesMap={categoriesMap}
              className=" max-w-[150px] "
            />
            <PostingStatusDropDown
              showExclude
              showPosted={listType === 'ledger'}
              handleSelect={handlePostingStatusSelect}
              selected={selectedPostingStatus}
              listType={listType}
            />
            {listType === 'ledger' && (
              <PostedToDropDown
                handleSelect={handlePostedToSelect}
                selected={selectedPostedTo}
                creditPlaceholder={creditPlaceholder}
              />
            )}
            <Input
              value={narration}
              onChange={(e) => setNarration(e.target.value)}
              placeholder="Search by narration"
              className=" max-w-[200px] "
            />
            <RangedInputWithLabel
              label="Total Credit"
              onChange={handleAmountSelect}
              placeholder="Enter amount"
              value={rangedAmount.value}
            />
            {showCustomerFilter && (
              <AsyncSelect
                isMulti
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                onChange={handleCustomerSelectChange}
                value={selectedCustomers}
                loadOptions={loadSelectOptions}
                className=" min-w-[150px] text-sm shadow-sm"
                styles={selectStyles}
                placeholder="Search by customer"
                classNames={selectClasses}
              />
            )}
            <Button variant="default" onClick={onSubmit}>
              Apply
            </Button>
            <Button onClick={handleReset} variant="outline">
              Reset
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export const CreditFiltersDialog = ({
  handleSubmit,
  filters,
  listType,
  showCustomerFilter = false,
}: {
  handleSubmit: (_: ICreditsFilters) => void;
  filters: ICreditsFilters;
  listType?: 'posted' | 'all' | 'ledger';
  showNarrationFilter?: boolean;
  showCustomerFilter?: boolean;
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<IOptions[]>([]);
  const [selectedBankAccounts, setSelectedBankAccounts] = useState<IOptions[]>([]);
  const { companiesOfUser, activeCompanyIndex } = useUserContext();
  const [selectedPostingStatus, setSelectedPostedStatus] = useState<IPostingStatusOption[]>([]);
  const [selectedPostedTo, setSelectedPostedTo] = useState<IOptions>();
  const [narration, setNarration] = useState('');
  const [rangedAmount, setRangedAmount] = useState<{
    value: [number | undefined, number | undefined];
  }>({
    value: [undefined, undefined],
  });

  const { getCategoryLabel } = useGetCategory();
  const { getBankNameById } = useGetBankName();

  const getFilterValueLabel = useCallback(
    (key: string, value: string): string => {
      if (key === 'postingStatus') {
        return value.split('_').join(' ').toLowerCase();
      }

      if (key === 'categories') {
        return getCategoryLabel(value);
      }

      if (key === 'bankAccountIds') {
        return getBankNameById(value);
      }

      return value;
    },
    [getCategoryLabel, getBankNameById],
  );

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: bankAccountsResponse } = useGetBankAccountsForCompany({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const { data: categoriesResponse } = useGetCategoriesByCompanyQuery({
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const banksMap = useMemo(() => {
    return bankAccountsResponse?.data.bankAccounts.reduce(
      (acc, bank) => {
        acc[bank.id] = bank.name;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [bankAccountsResponse]);

  const categoriesMap = useMemo(() => {
    return categoriesResponse?.data.reduce(
      (acc, category) => {
        acc[category.id] = category.value;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [categoriesResponse]);

  const postingStatusMap = useMemo<Record<PostingStatus, string>>(
    () => ({
      PARTIALLY_POSTED: 'Partially posted',
      POSTED: 'Posted',
      UNPOSTED: 'Unposted',
      EXCLUDED: 'Reversed',
    }),
    [],
  );

  const [dateRange, setDateRange] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });

  const { mutateAsync: fetchCustomers } = useGetCustomerSuggestionsMutation({});

  useEffect(() => {
    setSelectedPostedStatus(
      filters.postingStatus.map((status) => ({
        label: postingStatusMap[status],
        value: status,
      })),
    );

    if (banksMap) {
      setSelectedBankAccounts(
        filters.bankAccountIds.map((id) => ({
          label: banksMap[id],
          value: id,
        })),
      );
    }

    setDateRange({
      from: filters.dateRange?.startDate || undefined,
      to: filters.dateRange?.endDate || undefined,
    });

    setNarration(filters.narration || '');

    setRangedAmount({ value: [filters.minAmount, filters.maxAmount] });

    setSelectedCustomers(filters.customers || []);

    setSelectedCategories(filters.categories || []);

    // if (filters.isPostedToInvoice) {
    // }

    if (filters.categories.length) {
      setSelectedCategories(filters.categories);
    }
  }, [filters, postingStatusMap, banksMap]);

  // const { data: advancePlaceholderData } = useGetAdvancePlaceHolder();

  const handlePostingStatusSelect = (option: IPostingStatusOption) => () => {
    if (selectedPostingStatus.find((item) => item.value === option.value)) {
      setSelectedPostedStatus(selectedPostingStatus.filter((item) => item.value !== option.value));
    } else {
      setSelectedPostedStatus([
        ...selectedPostingStatus,
        {
          label: option.label,
          value: option.value,
        },
      ]);
    }
  };

  const handlePostedToSelect = (option: IOptions) => {
    setSelectedPostedTo(option);
  };

  const handleReset = () => {
    setDateRange({
      from: undefined,
      to: undefined,
    });
    setSelectedBankAccounts([]);
    setSelectedCustomers([]);
    handleSubmit({
      bankAccountIds: [],
      isPostedToInvoice: undefined,
      dateRange: {
        startDate: undefined,
        endDate: undefined,
      },
      postingStatus: [],
      categories: [],
      customers: [],
      narration: undefined,
      maxAmount: undefined,
      minAmount: undefined,
      placeholderId: undefined,
    });
    setRangedAmount({ value: [undefined, undefined] });
    setNarration('');
    setSelectedPostedStatus([]);
    setSelectedPostedTo(undefined);
    setSelectedCategories([]);
    setSearchParams(
      {},
      {
        replace: true,
      },
    );

    setIsFilterModalOpen(false);
  };
  useEffect(() => {
    handleSubmit({
      bankAccountIds:
        searchParams
          .get('bankAccountIds')
          ?.split(',')
          .filter((item) => !!item)
          .map((account) => account) || [],
      dateRange: {
        startDate: searchParams.get('dateRangeStart') ? dayjs(searchParams.get('dateRangeStart')).toDate() : undefined,
        endDate: searchParams.get('dateRangeEnd') ? dayjs(searchParams.get('dateRangeEnd')).toDate() : undefined,
      },
      customers:
        searchParams
          .get('customers')
          ?.split(',')
          .filter((item) => !!item)
          .map((item) => ({
            value: item.split(':')[0],
            label: item.split(':')[1],
          })) || [],
      postingStatus:
        searchParams
          .get('postingStatus')
          ?.split(',')
          .filter((item) => !!item)
          .map((item) => item as PostingStatus) || [],
      categories:
        searchParams
          .get('categories')
          ?.split(',')
          .filter((item) => !!item) || [],
      narration: searchParams.get('narration') || undefined,
      minAmount: searchParams.get('minAmount') ? Number(searchParams.get('minAmount')) : undefined,
      maxAmount: searchParams.get('maxAmount') ? Number(searchParams.get('maxAmount')) : undefined,
      isPostedToInvoice: searchParams.get('isPostedToInvoice') === 'true' || undefined,
      placeholderId: searchParams.get('placeholderId') || undefined,
    });
  }, [searchParams, handleSubmit]);

  const handleDateRangeChange = (value: DateRange | undefined) => {
    if (value) {
      setDateRange(value);
    }
    setDateRange({
      from: value?.from,
      to: value?.to,
    });
  };

  const handleBankAccountsChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((account) => ({ value: account.value, label: account.label }));
    setSelectedBankAccounts(newValue);
  };

  const onSubmit = () => {
    handleSubmit({
      bankAccountIds: selectedBankAccounts.map((account) => account.value),
      dateRange: dateRange ? { startDate: dateRange.from, endDate: dateRange.to } : undefined,
      postingStatus: selectedPostingStatus.map((item) => item.value),
      categories: selectedCategories,
      narration: narration,
      minAmount: rangedAmount.value[0],
      maxAmount: rangedAmount.value[1],
      customers: selectedCustomers,
      isPostedToInvoice: selectedPostedTo?.value === 'INVOICE' ? true : undefined,
      placeholderId: selectedPostedTo && selectedPostedTo?.value !== 'INVOICE' ? selectedPostedTo?.value : undefined,
    });

    setSearchParams(
      {
        bankAccountIds: selectedBankAccounts.map((account) => account.value).join(','),
        dateRangeStart: dateRange.from ? dayjs(dateRange.from).format('YYYY-MM-DD') : '',
        dateRangeEnd: dateRange.to ? dayjs(dateRange.to).format('YYYY-MM-DD') : '',
        postingStatus: selectedPostingStatus.map((item) => item.value).join(','),
        customers: selectedCustomers.map((item) => `${item.value}:${item.label}`).join(','),
        categories: selectedCategories.join(','),
        narration: narration,
        minAmount: rangedAmount.value[0]?.toString() || '',
        maxAmount: rangedAmount.value[1]?.toString() || '',
        isPostedToInvoice: selectedPostedTo?.value === 'INVOICE' ? 'true' : '',
        placeholderId: selectedPostedTo && selectedPostedTo?.value !== 'INVOICE' ? selectedPostedTo?.value : '',
      },
      {
        replace: true,
      },
    );

    setIsFilterModalOpen(false);
  };

  const handleAmountSelect = (amount: [number | undefined, number | undefined]) => {
    setRangedAmount({ value: amount });
  };

  const bankAccounts = useMemo(() => {
    return bankAccountsResponse?.data.bankAccounts || [];
  }, [bankAccountsResponse]);

  const handleCategoriesChange = useCallback((value: string[]) => {
    const newValue = [...value];
    setSelectedCategories(newValue);
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, IOptions>();
  }, []);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchCustomers({
        query: inputValue.trim(),
      });
      return result.data.suggestions.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    [fetchCustomers],
  );

  const handleCustomerSelectChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((account) => ({ value: account.value, label: account.label }));
    setSelectedCustomers(newValue);
  };

  const handleModalOpenChange = (isOpen: boolean) => {
    setIsFilterModalOpen(isOpen);
  };

  const filtersAsSearchParams = useMemo(() => {
    const filters = {
      bankAccountIds: searchParams.get('bankAccountIds')?.split(',').join(', ') || '',
      dateRangeStart: searchParams.get('dateRangeStart') || '',
      dateRangeEnd: searchParams.get('dateRangeEnd') || '',
      postingStatus: searchParams.get('postingStatus')?.split(',').join(', ') || '',
      customers: searchParams.get('customers')?.split(',').join(', ') || '',
      categories: searchParams.get('categories')?.split(',').join(', ') || '',
      narration: searchParams.get('narration') || '',
      minAmount: searchParams.get('minAmount') || '',
      maxAmount: searchParams.get('maxAmount') || '',
    };

    const postedToArray = [];

    const placeholderId = searchParams.get('placeholderId');
    if (placeholderId) {
      postedToArray.push('Advance');
    }

    const isPostedToInvoice = searchParams.get('isPostedToInvoice');
    if (isPostedToInvoice) {
      postedToArray.push('Invoice');
    }

    return {
      ...filters,
      postedTo: postedToArray.join(', '),
    };
  }, [searchParams]);

  const filterKeyToLabelMap = useMemo<Record<keyof typeof filtersAsSearchParams, string>>(() => {
    return {
      bankAccountIds: 'Bank Accounts',
      dateRangeStart: 'Date range from',
      dateRangeEnd: 'Date range to',
      postingStatus: 'Posting status',
      customers: 'Customers',
      categories: 'Categories',
      narration: 'Narration',
      minAmount: 'Min amount',
      maxAmount: 'Max amount',
      postedTo: 'Posted to',
    };
  }, []);

  const filterList = useMemo(() => {
    return Object.entries(filtersAsSearchParams)
      .map(([key, value]: [string, string]) => {
        return {
          label: key,
          value: value
            .split(',')
            .map((v) => getFilterValueLabel(key, v.trim()))
            .join(', '),
        };
      })
      .filter((filter) => !!filter.value);
  }, [filtersAsSearchParams, getFilterValueLabel]);

  return (
    <>
      <div className=" flex gap-4 items-center ">
        <Button onClick={() => setIsFilterModalOpen(true)} variant="outline" className=" relative ">
          Filters
          {filterList.length > 0 && (
            <div className="w-4 h-4 bg-black text-white rounded-xl mr-[-22px] mb-[28px]">
              <div className="text-center mb-auto mt-auto text-xs">{filterList.length}</div>
            </div>
          )}
        </Button>
        {filterList.length > 0 && (
          <Button variant="outline" onClick={handleReset}>
            Reset
          </Button>
        )}
        <div className=" flex items-center gap-2 flex-1 overflow-x-scroll py-2  ">
          <FilterList
            filterKeyToLabelMap={filterKeyToLabelMap}
            setModalOpen={setIsFilterModalOpen}
            filterList={filterList}
          />
        </div>
      </div>
      {isFilterModalOpen && (
        <Dialog open={isFilterModalOpen} onOpenChange={handleModalOpenChange}>
          <DialogContent>
            <div className="w-[620px] border-2 bg-white	">
              <div className="h-[52px] p-4 flex  justify-between border-b">
                <div>
                  <DialogHeader>
                    <DialogTitle>Filters</DialogTitle>
                  </DialogHeader>
                </div>
                <div>
                  <XIcon
                    className="w-[20px] h-[20px] cursor-pointer"
                    onClick={() => {
                      setIsFilterModalOpen(false);
                    }}
                  />
                </div>
              </div>
              <div className=" flex flex-col gap-4 py-4 ">
                <FilterRow
                  itemOneLabel="Date range"
                  itemOneFilter={
                    <DatePickerWithRange
                      placeholder="Select transaction date"
                      value={dateRange}
                      onChange={handleDateRangeChange}
                      buttonClass="w-full overflow-hidden  "
                    />
                  }
                  itemTwoLabel="Bank accounts"
                  itemTwoFilter={
                    <Select
                      onChange={handleBankAccountsChange}
                      value={selectedBankAccounts}
                      className=" w-full text-sm shadow-sm"
                      classNames={selectClasses}
                      isMulti={true}
                      name="bank-accounts"
                      options={bankAccounts.map((account) => ({ value: account.id, label: account.name }))}
                      classNamePrefix="select"
                      placeholder="Select bank accounts"
                      styles={selectStyles}
                    />
                  }
                />
                <FilterRow
                  itemOneLabel="Category"
                  itemOneFilter={
                    <SelectCategoryDropdown
                      defaultSelectedCategories={[]}
                      selectedCategories={selectedCategories}
                      onChange={handleCategoriesChange}
                      categoriesMap={categoriesMap}
                      containerClassName="w-full"
                    />
                  }
                  itemTwoLabel="Posting status"
                  itemTwoFilter={
                    <PostingStatusDropDown
                      handleSelect={handlePostingStatusSelect}
                      selected={selectedPostingStatus}
                      listType={listType}
                      containerClassName="w-full"
                    />
                  }
                />
                <FilterRow
                  itemOneLabel="Posted to"
                  itemOneFilter={
                    <PostedToDropDown
                      handleSelect={handlePostedToSelect}
                      selected={selectedPostedTo}
                      className=" w-full max-w-full "
                    />
                  }
                  itemTwoLabel="Narration"
                  itemTwoFilter={
                    <Input
                      value={narration}
                      onChange={(e) => setNarration(e.target.value)}
                      placeholder="Search by narration"
                      className=" w-full "
                    />
                  }
                />
                <FilterRow
                  itemOneLabel="Total Credit"
                  itemOneFilter={
                    <RangedInputWithLabel
                      label="Total Credit"
                      onChange={handleAmountSelect}
                      placeholder="Enter amount"
                      value={rangedAmount.value}
                      className=" w-full max-w-full "
                    />
                  }
                  itemTwoLabel="Credit Note Number"
                  itemTwoFilter={
                    showCustomerFilter && (
                      <AsyncSelect
                        isMulti
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        onChange={handleCustomerSelectChange}
                        value={selectedCustomers}
                        loadOptions={loadSelectOptions}
                        className=" min-w-[150px] text-sm shadow-sm"
                        styles={selectStyles}
                        placeholder="Search by customer"
                        classNames={selectClasses}
                      />
                    )
                  }
                />
              </div>
              <div className="h-[64px] p-4 border-t">
                <DialogFooter>
                  <Button onClick={handleReset} variant="outline">
                    Reset
                  </Button>
                  <Button onClick={onSubmit} className="flex items-center gap-2">
                    Apply
                  </Button>
                </DialogFooter>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CreditFilters;
