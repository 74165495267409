'use client';

import { Button } from '@/components/ui/button';
import { useEffect, useMemo, useState } from 'react';

import { usePostUpdateSegmentMutation } from '@/hooks/api-hooks/useSegmentQuery';
import { cn } from '@/lib/utils';
import { IOptions } from '@/types/common.types';
import { IAmountType, ICustomerFilters, TIdentifierType } from '@/types/customer.types';
import {
  IInvoiceOption,
  IInvoiceStatus,
  IPaymentStatus,
  IPaymentStatusOption,
  TInvoiceDateType,
} from '@/types/invoices.types';
import { ISegmentSearchRules } from '@/types/segment.types';
import { convertCustomerFiltersToSegmentSearchQuery } from '@/utils/convertCustomerFiltersToSegmentQuery';
import { convertSearchQueryToCustomerFilters } from '@/utils/convertCustomerSearchQueryToFilters';
import dayjs from 'dayjs';
import { Loader2Icon } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ClubbedSelectWithInput from '../shared/ClubbedSelectAndInput';
import ClubbedSelectWithDatePicker from '../shared/ClubbedSelectDatePicker';
import ClubbedSelectWithRangeInput from '../shared/ClubbedSelectWithRange';
import CompositeInput from '../shared/CompositeInput';
import { InvoiceStatusDropdown, PaymentStatusDropDown } from '../shared/StatusDropDowns';
import { useToast } from '../ui/use-toast';
import TagFilter from './TagFilter';

const CustomersFilters = ({ handleSubmit }: { handleSubmit: (_: ICustomerFilters) => void }) => {
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState<IInvoiceOption[]>([]);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const defaultIdentifierType = 'CUSTOMER_NAME';

  const [dateRangeWithType, setDateRangeWithType] = useState<{
    type: TInvoiceDateType;
    value: DateRange;
  }>({
    type: 'DUE_DATE',
    value: {
      from: undefined,
      to: undefined,
    },
  });
  const [tags, setTags] = useState<
    {
      tagName: string;
      tagValue: string;
    }[]
  >([]);

  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<IPaymentStatusOption[]>([]);
  const [searchFilter, setSearchFilter] = useState<{
    value: string | undefined;
    type: TIdentifierType | undefined;
  }>({
    value: undefined,
    type: defaultIdentifierType,
  });

  const [searchByOwnerFilter, setSearchByOwnerFilter] = useState<{
    value: IOptions | undefined;
    type: 'name-email' | 'role' | undefined;
  }>({
    value: undefined,
    type: 'name-email',
  });

  const [amountWithType, setAmountWithType] = useState<{
    type: IAmountType | undefined;
    value: [number | undefined, number | undefined];
  }>({
    type: 'OUTSTANDING',
    value: [undefined, undefined],
  });

  const amountTypeOptions = useMemo<
    {
      label: string;
      value: IAmountType;
    }[]
  >(() => {
    return [
      {
        label: 'Due/Overdue',
        value: 'OUTSTANDING',
      },
      {
        label: 'Total',
        value: 'TOTAL',
      },
    ];
  }, []);

  const handleAmountSelect = (amount: [number | undefined, number | undefined], type: IAmountType | undefined) => {
    setIsFormDirty(true);
    setAmountWithType({ type, value: amount });
  };

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const invoiceStatusValueToLabelMap = useMemo<Record<IInvoiceStatus, string>>(() => {
    return {
      DUE: 'Due',
      NO_DUES: 'No dues',
      OVERDUE: 'Overdue',
    };
  }, []);

  const paymentStatusValueToLabelMap = useMemo<Record<IPaymentStatus, string>>(() => {
    return {
      PAID: 'Paid',
      UNPAID: 'Unpaid',
      PARTIALLY_PAID: 'Partially paid',
    };
  }, []);

  const isSegmentUpdateFlowInProgress = useMemo(() => {
    const isSegmentUpdate = searchParams.get('isSegmentUpdate');
    return isSegmentUpdate === 'true';
  }, [searchParams]);

  const segmentIdToUpdate = useMemo(() => {
    const segmentId = searchParams.get('segmentId');
    return segmentId;
  }, [searchParams]);

  const segmentName = useMemo(() => {
    const segmentName = searchParams.get('segmentName');
    return segmentName;
  }, [searchParams]);

  const { toast } = useToast();
  const navigate = useNavigate();

  const { mutate: updateSegment, isPending } = usePostUpdateSegmentMutation({
    segmentId: segmentIdToUpdate as string,
    customConfig: {
      onSuccess: () => {
        toast({
          description: 'Segment updated successfully',
        });
        navigate(`/segments/view/${segmentIdToUpdate}`, {
          replace: true,
        });
      },
      onError: (error) => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to update segment',
        });
      },
    },
  });

  useEffect(() => {
    if (location.state && isSegmentUpdateFlowInProgress) {
      const state = location.state as ISegmentSearchRules;
      const filters = convertSearchQueryToCustomerFilters(state);

      setSearchFilter({
        value: filters.identifierValue,
        type: filters.identifierType || defaultIdentifierType,
      });
      setTags(filters.tags || []);
      setSelectedInvoiceStatus(
        filters.invoiceStatus?.length
          ? filters.invoiceStatus.map((status) => ({ label: invoiceStatusValueToLabelMap[status], value: status }))
          : [],
      );
      setSelectedPaymentStatus(
        filters.paymentStatus?.length
          ? filters.paymentStatus.map((status) => ({ label: paymentStatusValueToLabelMap[status], value: status }))
          : [],
      );
      setAmountWithType({
        type: filters.amountType || 'OUTSTANDING',
        value: [filters.minAmount ?? undefined, filters.maxAmount ?? undefined],
      });
      setDateRangeWithType({
        type: filters.dateType || 'DUE_DATE',
        value: {
          from: filters.dateRange?.startDate ? new Date(filters.dateRange.startDate) : undefined,
          to: filters.dateRange?.endDate ? new Date(filters.dateRange.endDate) : undefined,
        },
      });

      if (filters.ownerKeyType && filters.ownerValue) {
        setSearchByOwnerFilter({
          value:
            filters.ownerKeyType === 'name-email'
              ? {
                  label: filters.ownerName || '',
                  value: filters.ownerValue,
                }
              : {
                  label: filters.ownerValue,
                  value: filters.ownerValue,
                },
          type: filters.ownerKeyType,
        });
      } else {
        setSearchByOwnerFilter({
          value: undefined,
          type: 'name-email',
        });
      }
      handleSubmit({ ...filters });
    } else {
      handleSubmit({
        amountType: (searchParams.get('amountType') as IAmountType | undefined) || 'OUTSTANDING',
        minAmount: Number(searchParams.get('minAmount')) || undefined,
        maxAmount: Number(searchParams.get('maxAmount')) || undefined,
        dateRange: {
          startDate: searchParams.get('dateRangeStart')
            ? dayjs(searchParams.get('dateRangeStart')).toDate()
            : undefined,
          endDate: searchParams.get('dateRangeEnd') ? dayjs(searchParams.get('dateRangeEnd')).toDate() : undefined,
        },
        dateType: (searchParams.get('dateRangeType') as TInvoiceDateType) || 'INVOICE_DATE',
        paymentStatus: (searchParams
          .get('paymentStatus')
          ?.split(',')
          .filter(Boolean)
          .map((item) => item.split(':')[0] as IPaymentStatus) ?? []) as IPaymentStatus[],
        identifierType: (searchParams.get('identifierType') as TIdentifierType) || defaultIdentifierType,
        identifierValue: searchParams.get('identifierValue') || '',
        ownerKeyType: (searchParams.get('ownerKeyType') as 'name-email' | 'role' | undefined) || 'name-email',
        invoiceStatus: (searchParams
          .get('invoiceStatus')
          ?.split(',')
          .filter(Boolean)
          .map((item) => item.split(':')[0] as IInvoiceStatus) ?? []) as IInvoiceStatus[],
        ownerName: searchParams.get('ownerName') || '',
        ownerValue: searchParams.get('ownerValue') || '',
        tags:
          searchParams
            .get('tags')
            ?.split(',')
            .filter(Boolean)
            .map((item) => item.trim())
            .map((tag) => ({
              tagName: tag.split(':')[0],
              tagValue: tag.split(':')[1],
            })) || [],
      });

      setAmountWithType({
        type: (searchParams.get('amountType') as IAmountType | undefined) || 'OUTSTANDING',
        value: [Number(searchParams.get('minAmount')) || undefined, Number(searchParams.get('maxAmount')) || undefined],
      });

      setDateRangeWithType({
        type: (searchParams.get('dateRangeType') as TInvoiceDateType) || 'INVOICE_DATE',
        value: {
          from: searchParams.get('dateRangeStart') ? dayjs(searchParams.get('dateRangeStart')).toDate() : undefined,
          to: searchParams.get('dateRangeEnd') ? dayjs(searchParams.get('dateRangeEnd')).toDate() : undefined,
        },
      });

      setSelectedPaymentStatus(
        (
          searchParams
            .get('paymentStatus')
            ?.split(',')
            .filter(Boolean)
            .map((item) => item.split(':')[0] as IPaymentStatus) ?? []
        ).map((item) => ({
          label: paymentStatusValueToLabelMap[item],
          value: item,
        })),
      );

      setSelectedInvoiceStatus(
        (
          searchParams
            .get('invoiceStatus')
            ?.split(',')
            .filter(Boolean)
            .map((item) => item.split(':')[0] as IInvoiceStatus) ?? []
        ).map((item) => ({
          label: invoiceStatusValueToLabelMap[item],
          value: item,
        })),
      );

      setSearchByOwnerFilter({
        type: (searchParams.get('ownerKeyType') as 'name-email' | 'role' | undefined) || 'name-email',
        value:
          searchParams.get('ownerName') && searchParams.get('ownerValue')
            ? {
                label: searchParams.get('ownerName') || '',
                value: searchParams.get('ownerValue') || '',
              }
            : undefined,
      });

      setSearchFilter({
        type: (searchParams.get('identifierType') as TIdentifierType) || defaultIdentifierType,
        value: searchParams.get('identifierValue') || '',
      });

      setTags(
        searchParams
          .get('tags')
          ?.split(',')
          .filter(Boolean)
          .map((item) => item.trim())
          .map((tag) => ({
            tagName: tag.split(':')[0],
            tagValue: tag.split(':')[1],
          })) || [],
      );
    }
  }, [
    location.state,
    handleSubmit,
    invoiceStatusValueToLabelMap,
    paymentStatusValueToLabelMap,
    isSegmentUpdateFlowInProgress,
    searchParams,
  ]);

  const handleChange = (value: string, type: string | undefined) => {
    setIsFormDirty(true);
    if (type === undefined) {
      setSearchFilter({
        value: undefined,
        type: undefined,
      });
      return;
    }
    setSearchFilter({
      value,
      type: type as TIdentifierType,
    });
  };

  const handleOwnerSearchChange = (value: IOptions | undefined, type: string | undefined) => {
    setIsFormDirty(true);
    if (type === undefined) {
      setSearchByOwnerFilter({
        value: undefined,
        type: undefined,
      });
      return;
    }
    setSearchByOwnerFilter({
      value,
      type: type as 'role' | 'name-email',
    });
  };

  const inputTypeOptions = useMemo<
    {
      label: string;
      value: TIdentifierType;
    }[]
  >(
    () => [
      {
        label: 'Name',
        value: 'CUSTOMER_NAME',
      },
      {
        label: 'GST',
        value: 'GSTIN',
      },
      {
        label: 'PAN',
        value: 'PAN',
      },
      {
        label: 'Buyer Id',
        value: 'BUYER_ID',
      },
    ],
    [],
  );

  const ownerSearchKeyOptions = useMemo<
    {
      label: string;
      value: 'role' | 'name-email';
    }[]
  >(
    () => [
      {
        label: 'Name/Email',
        value: 'name-email',
      },
      {
        label: 'Role',
        value: 'role',
      },
    ],
    [],
  );

  const handleInvoiceStatusSelect = (option: IInvoiceOption) => () => {
    setIsFormDirty(true);
    if (selectedInvoiceStatus.find((item) => item.value === option.value)) {
      setSelectedInvoiceStatus(selectedInvoiceStatus.filter((item) => item.value !== option.value));
    } else {
      setSelectedInvoiceStatus([
        ...selectedInvoiceStatus,
        {
          label: option.label,
          value: option.value,
        },
      ]);
    }
  };

  const handlePaymentStatusSelect = (option: IPaymentStatusOption) => () => {
    setIsFormDirty(true);
    if (selectedPaymentStatus.find((item) => item.value === option.value)) {
      setSelectedPaymentStatus(selectedPaymentStatus.filter((item) => item.value !== option.value));
    } else {
      setSelectedPaymentStatus([
        ...selectedPaymentStatus,
        {
          label: option.label,
          value: option.value,
        },
      ]);
    }
  };

  const handleDateRangeChange = (value: DateRange | undefined, type: TInvoiceDateType) => {
    setIsFormDirty(true);
    if (value) {
      setDateRangeWithType({
        type,
        value: {
          from: value.from,
          to: value.to,
        },
      });
    }
    setDateRangeWithType({
      type,
      value: {
        from: value?.from,
        to: value?.to,
      },
    });
  };

  const handleSubmitFilter = () => {
    const filters = {
      identifierType: searchFilter.type,
      identifierValue: searchFilter.value,
      invoiceStatus: selectedInvoiceStatus.map((item) => item.value),
      amountType: amountWithType.type,
      minAmount: amountWithType.value[0],
      maxAmount: amountWithType.value[1],
      paymentStatus: selectedPaymentStatus.map((item) => item.value),
      dateRange: {
        startDate: dateRangeWithType.value.from,
        endDate: dateRangeWithType.value.to,
      },
      dateType: dateRangeWithType.type,
      tags: [...tags],
      ownerKeyType: searchByOwnerFilter.type,
      ownerValue: searchByOwnerFilter.value?.value,
      ownerName: searchByOwnerFilter.value?.label,
    };

    setSearchParams(
      {
        identifierType: filters.identifierType || defaultIdentifierType,
        identifierValue: filters.identifierValue || '',
        invoiceStatus: filters.invoiceStatus.map((item) => item).join(','),
        amountType: filters.amountType || 'OUTSTANDING',
        minAmount: filters.minAmount?.toString() || '',
        maxAmount: filters.maxAmount?.toString() || '',
        paymentStatus: filters.paymentStatus.map((item) => item).join(','),
        dateRangeStart: filters.dateRange.startDate ? dayjs(filters.dateRange.startDate).format('YYYY-MM-DD') : '',
        dateRangeEnd: filters.dateRange.startDate ? dayjs(filters.dateRange.endDate).format('YYYY-MM-DD') : '',
        dateType: filters.dateType || 'DUE_DATE',
        tags: filters.tags.map((item) => `${item.tagName}:${item.tagValue}`).join(','),
        ownerKeyType: filters.ownerKeyType || 'name-email',
        ownerValue: filters.ownerValue || '',
        ownerName: filters.ownerName || '',
      },
      {
        replace: true,
      },
    );

    handleSubmit(filters);
  };

  const handleUpdateSegment = () => {
    const filters = {
      identifierType: searchFilter.type,
      identifierValue: searchFilter.value,
      invoiceStatus: selectedInvoiceStatus.map((item) => item.value),
      amountType: amountWithType.type,
      minAmount: amountWithType.value[0],
      maxAmount: amountWithType.value[1],
      paymentStatus: selectedPaymentStatus.map((item) => item.value),
      dateRange: {
        startDate: dateRangeWithType.value.from,
        endDate: dateRangeWithType.value.to,
      },
      dateType: dateRangeWithType.type,
      tags: [...tags],
      ownerKeyType: searchByOwnerFilter.type,
      ownerValue: searchByOwnerFilter.value?.value,
      ownerName: searchByOwnerFilter.value?.label,
    };

    const searchQuery = convertCustomerFiltersToSegmentSearchQuery(filters);
    updateSegment({
      fields: ['rule'],
      values: {
        rule: {
          type: 'SEARCH_RULE',
          searchQuery: {
            ...searchQuery,
            companyId: '',
          },
        },
      },
    });
  };

  const handleReset = () => {
    setSearchFilter({
      value: undefined,
      type: defaultIdentifierType,
    });
    setTags([]);
    setSelectedInvoiceStatus([]);
    setSelectedPaymentStatus([]);
    setAmountWithType({ type: 'OUTSTANDING', value: [undefined, undefined] });
    setDateRangeWithType({
      type: 'DUE_DATE',
      value: {
        from: undefined,
        to: undefined,
      },
    });
    setSearchByOwnerFilter({ value: undefined, type: 'name-email' });

    handleSubmit({
      identifierType: undefined,
      identifierValue: undefined,
      invoiceStatus: [],
      amountType: undefined,
      minAmount: undefined,
      maxAmount: undefined,
      paymentStatus: [],
      dateRange: {
        startDate: undefined,
        endDate: undefined,
      },
      dateType: undefined,
      tags: [],
      ownerKeyType: undefined,
      ownerValue: undefined,
      ownerName: undefined,
    });

    setSearchParams(
      {},
      {
        replace: true,
      },
    );
  };

  const placeHolderMap = useMemo<Record<TIdentifierType, string>>(
    () => ({
      GSTIN: 'Search by GSTIN',
      PAN: 'Search by PAN',
      CUSTOMER_NAME: 'Search by Name',
      BUYER_ID: 'Search by Buyer Id',
    }),
    [],
  );

  const ownerSearchPlaceHolderMap = useMemo<Record<'role' | 'name-email', string>>(
    () => ({
      'name-email': 'Search by internal stakeholder name/email',
      role: 'Search by internal stakeholder role',
    }),
    [],
  );

  const amountTypeMap = useMemo<Record<IAmountType, string>>(
    () => ({
      OUTSTANDING: 'Select due/overdue amount',
      TOTAL: 'Select total amount',
      ADJUSTED: 'Select adjusted amount',
      PAID: 'Select paid amount',
    }),
    [],
  );

  const handleTagFilter = (value: { tagName: string; tagValue: string }[]) => {
    setIsFormDirty(true);
    setTags(value);
  };

  const dateOptions = useMemo<
    {
      value: TInvoiceDateType;
      label: string;
    }[]
  >(() => {
    return [
      {
        value: 'INVOICE_DATE',
        label: 'Invoice date',
      },
      {
        value: 'DUE_DATE',
        label: 'Due date',
      },
      {
        value: 'PROMISE_TO_PAY_DATE',
        label: 'Promise to pay date',
      },
    ];
  }, []);

  const handleCancelUpdate = () => {
    navigate(`/segments/view/${segmentIdToUpdate}`, {
      replace: true,
    });
  };

  return (
    <>
      {isSegmentUpdateFlowInProgress && (
        <div className=" py-2 mb-8 border-b flex justify-between items-center">
          <span className="text-base ">
            Update criteria for segment <span className=" font-semibold ">{segmentName}</span>
          </span>
          <div className={cn(' flex gap-4  ', isSegmentUpdateFlowInProgress && 'mb-4')}>
            <Button variant="outline" onClick={handleCancelUpdate}>
              Cancel
            </Button>
            <Button
              disabled={isPending || !isFormDirty}
              className=" flex gap-2 items-center "
              onClick={handleUpdateSegment}
            >
              Update Criteria
              {isPending ? <Loader2Icon className="w-4 h-4" /> : null}
            </Button>
          </div>
        </div>
      )}
      <div className="mb-4 flex flex-col gap-4">
        <div className="flex items-center gap-4 justify-between ">
          <ClubbedSelectWithDatePicker
            defaultSelected={dateRangeWithType.type}
            value={dateRangeWithType.value}
            onChange={handleDateRangeChange}
            options={dateOptions}
          />
          <ClubbedSelectWithInput
            placeholder="Select input type"
            value={searchFilter.value ? searchFilter.value : ''}
            placeholderMap={placeHolderMap}
            defaultSelected={searchFilter.type}
            options={inputTypeOptions}
            onChange={handleChange}
          />
          <ClubbedSelectWithRangeInput
            placeholder="Select amount type"
            onChange={handleAmountSelect}
            defaultSelected={amountWithType.type}
            options={amountTypeOptions}
            placeholderMap={amountTypeMap}
            value={amountWithType.value}
            className="flex-1"
          />
        </div>
        <div className="flex items-center gap-4 w-full  ">
          <InvoiceStatusDropdown handleSelect={handleInvoiceStatusSelect} selected={selectedInvoiceStatus} />
          <PaymentStatusDropDown handleSelect={handlePaymentStatusSelect} selected={selectedPaymentStatus} />
          <CompositeInput
            onChange={handleOwnerSearchChange}
            options={ownerSearchKeyOptions}
            placeholderMap={ownerSearchPlaceHolderMap}
            placeholder="Select a owner field"
            value={searchByOwnerFilter.value}
            defaultSelected={searchByOwnerFilter.type}
          />
          <TagFilter tags={tags} onChange={handleTagFilter} />
        </div>
        <div className={cn(' flex gap-4 ', isSegmentUpdateFlowInProgress && 'mb-4')}>
          <Button
            variant={isSegmentUpdateFlowInProgress ? 'outline' : 'default'}
            disabled={isPending}
            className=" flex gap-2 items-center "
            onClick={handleSubmitFilter}
          >
            Apply
          </Button>
          <Button variant="outline" onClick={handleReset}>
            Reset
          </Button>
        </div>
      </div>
    </>
  );
};

export default CustomersFilters;
