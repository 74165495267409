'use client';

import { cn } from '@/lib/utils';
import { TInvoiceDateType } from '@/types/invoices.types';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { DatePickerWithRange } from '../ui/multi-datepicker';
import SelectComponent from '../ui/select-component';
interface IOption {
  label: string;
  value: TInvoiceDateType;
}

const ClubbedSelectWithDatePicker = ({
  options,
  onChange,
  value,
  defaultSelected,
}: {
  options: IOption[];
  onChange: (_: DateRange | undefined, __: TInvoiceDateType) => void;
  value: DateRange;
  defaultSelected: string;
}) => {
  // TODO: add input type
  const [selectedValue, setSelectedValue] = useState(defaultSelected);

  useEffect(() => {
    setSelectedValue(defaultSelected);
  }, [defaultSelected]);

  const [focused, setFocused] = useState(false);

  const handleSelect = (value: string) => {
    const newValue = value;
    setSelectedValue(newValue);
    onChange(undefined, newValue as TInvoiceDateType);
  };

  return (
    <div className={cn('flex border rounded-md overflow-hidden', focused && 'ring-1 ring-ring')}>
      <SelectComponent
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className=" border-0 ring-0 hover:ring-0 focus-within:ring-0 focus-visible:ring-0 focus:ring-0 "
        value={selectedValue}
        options={options}
        onChange={handleSelect}
      />
      <DatePickerWithRange
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className=" border-0 ring-0 hover:ring-0 focus-within:ring-0 focus-visible:ring-0 "
        value={value}
        onChange={(dateRange) => onChange(dateRange, selectedValue as TInvoiceDateType)}
      />
    </div>
  );
};

export default ClubbedSelectWithDatePicker;
