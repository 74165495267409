import { usePostUpdateCustomerMutation } from '@/hooks/api-hooks/useCustomerQuery';

import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { validateEmail } from '@/utils/validateEmail';
import { Loader2Icon, XIcon } from 'lucide-react';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { Button } from '@/components/ui/button';
import { cleanInputNumber } from '@/utils/numberUtil';

export const EditCustomerAlias = ({
  defaultValue,
  className,
  customerId,
}: {
  defaultValue: string;
  className?: string;
  customerId: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState('');
  const { toast } = useToast();

  const { mutate: updateCustomer, isPending } = usePostUpdateCustomerMutation({
    customerId,
    customConfig: {
      onError: () => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
        setValue(defaultValue);
      },
    },
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors('');
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (value.length < 3) {
      setErrors('Customer alias must be more than 3 characters');
      return;
    }

    if (value === defaultValue) {
      setIsEditing(false);
      return;
    }

    setIsEditing(false);
    updateCustomer({
      fields: ['alias'],
      updateObject: {
        alias: value,
      },
    });
  };

  const handleBlur = () => {
    handleSave();
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  };

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
    setValue(defaultValue);
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    } else {
      setErrors('');
    }
  }, [isEditing]);

  return isEditing ? (
    <div className=" flex items-start gap-4 ">
      <div>
        <Input
          className="my-0 text-xl font-semibold "
          onKeyDown={handleKeyDown}
          ref={inputRef}
          onBlur={handleBlur}
          value={value}
          onChange={handleChange}
        />
        <p className=" text-xs text-red-500 h-4 ">{!!errors.trim() && errors}</p>
      </div>
      <Button onClick={handleCancel} variant="outline" size="icon">
        <XIcon className=" h-4 w-4 " />
      </Button>
    </div>
  ) : (
    <div
      onClick={handleClick}
      className={cn(
        ' hover:ring-1 ring-primary cursor-pointer px-4 rounded-md py-2 flex items-center gap-4 ',
        className,
      )}
    >
      {value}
      {isPending && <Loader2Icon className=" animate-spin " />}
    </div>
  );
};

export const EditPhoneNumber = ({
  defaultValue,
  className,
  customerId,
}: {
  defaultValue: string;
  className?: string;
  customerId: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState('');
  const { toast } = useToast();

  const { mutate: updateCustomer, isPending } = usePostUpdateCustomerMutation({
    customerId,
    customConfig: {
      onError: () => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
        setValue(defaultValue);
      },
    },
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors('');
    setValue(cleanInputNumber(e.target.value));
  };

  const handleSave = () => {
    setIsEditing(false);
    updateCustomer({
      fields: ['primary_phone'],
      updateObject: {
        primary_phone: value ? value : null,
      },
    });
  };

  const handleBlur = () => {
    handleSave();
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
    setValue(defaultValue);
  };

  return isEditing ? (
    <div className=" flex items-start gap-2 ">
      <div>
        <Input
          onKeyDown={handleKeyDown}
          ref={inputRef}
          onBlur={handleBlur}
          value={value}
          className=" h-8 "
          onChange={handleChange}
        />
        <p className=" text-xs text-red-500 h-4 ">{!!errors.trim() && errors}</p>
      </div>
      <Button className="my-0 relative bottom-0.5" onClick={handleCancel} variant="outline" size="icon">
        <XIcon className=" h-4 w-4 " />
      </Button>
    </div>
  ) : (
    <div
      onClick={handleClick}
      className={cn(
        ' hover:ring-1 ring-primary cursor-pointer px-4 rounded-md py-1 flex items-center gap-4 ',
        className,
      )}
    >
      {value?.trim() || 'Add phone number'}
      {isPending && <Loader2Icon className=" w-3 h-3 animate-spin " />}
    </div>
  );
};

export const EditEmail = ({
  defaultValue,
  className,
  customerId,
}: {
  defaultValue: string;
  className?: string;
  customerId: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState('');
  const { toast } = useToast();

  const { mutate: updateCustomer, isPending } = usePostUpdateCustomerMutation({
    customerId,
    customConfig: {
      onSuccess: () => {
        setErrors('');
      },
      onError: () => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
        setValue(defaultValue);
        setErrors('');
      },
    },
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors('');
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (value && !validateEmail(value)) {
      //this was done to allow empting email and then passing null in case user do not want to add email
      setErrors('Entered email is not valid');
      return;
    }

    setIsEditing(false);
    updateCustomer({
      fields: ['primary_email'],
      updateObject: {
        primary_email: value ? value : null,
      },
    });
  };

  const handleBlur = () => {
    handleSave();
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  };

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
    setValue(defaultValue);
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);
  return (
    <div className=" flex items-center gap-2 ">
      {isEditing ? (
        <div className=" flex items-start gap-2 ">
          <div>
            <Input
              onKeyDown={handleKeyDown}
              ref={inputRef}
              onBlur={handleBlur}
              value={value}
              className=" h-8 "
              onChange={handleChange}
            />
            <p className=" text-xs text-red-500 h-4 text-center">{!!errors.trim() && errors}</p>
          </div>
          <Button className="my-0 relative bottom-0.5" onClick={handleCancel} variant="outline" size="icon">
            <XIcon className=" h-4 w-4 " />
          </Button>
        </div>
      ) : (
        <div
          onClick={handleClick}
          className={cn(
            ' hover:ring-1 ring-primary cursor-pointer px-4 rounded-md py-1 flex items-center gap-4 ',
            className,
          )}
        >
          {value?.trim() || 'Add email'}
          {isPending && <Loader2Icon className=" w-3 h-3 animate-spin " />}
        </div>
      )}
    </div>
  );
};
