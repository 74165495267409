import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { usePostMutationSignUp } from '@/hooks/api-hooks/useAuth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from '@mui/material';
import { Info } from 'lucide-react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { useToast } from '../ui/use-toast';

const signUpFormSchema = z.object({
  firstName: z.string().refine((value) => /^[a-zA-Z]{2,}$/.test(value), {
    message: 'First name should be at least 2 characters long and contain only alphabets',
  }),
  lastName: z.string().refine((value) => /^[a-zA-Z]{1,}$/.test(value), {
    message: 'Last name should be at least 1 character long and contain only alphabets',
  }),
  password: z.string().min(8, {
    message: 'Password must be atleast 8 characters long.',
  }),
});

type ISignUpForm = z.infer<typeof signUpFormSchema>;

export default function SignUpForm({ inviteCode }: { inviteCode: string }) {
  const { toast } = useToast();

  const form = useForm<ISignUpForm>({
    resolver: zodResolver(signUpFormSchema),
  });

  const { mutate: signUp } = usePostMutationSignUp({
    customConfig: {
      onError: (error) => {
        toast({
          title: 'Error occurred while logging in',
          description: error.response ? error.response.data.message : 'Network Failure',
          duration: 5000,
        });
      },
    },
  });

  const onSubmit = (data: ISignUpForm) => {
    signUp({
      ...data,
      invitationCode: inviteCode,
    });
  };

  return (
    <Form {...form}>
      <form className="grid space-y-4 mt-4" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="firstName"
          render={({ field, fieldState }) => (
            <FormItem>
              <div className="flex items-center">
                <FormLabel>First name</FormLabel>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info width={20} height={20} fill={''} stroke="white" style={{ marginLeft: '0.3rem' }} />
                    </TooltipTrigger>
                    <TooltipContent>Your first name as per your PAN card</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <FormControl>
                <Input {...field} required={true} />
              </FormControl>
              {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastName"
          render={({ field, fieldState }) => (
            <FormItem>
              <div className="flex items-center">
                <FormLabel>Last name</FormLabel>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info width={20} height={20} fill={''} stroke="white" style={{ marginLeft: '0.3rem' }} />
                    </TooltipTrigger>
                    <TooltipContent>Your last name as per your PAN card</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <FormControl>
                <Input {...field} required={true} />
              </FormControl>
              {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field, fieldState }) => (
            <FormItem>
              <div className="flex items-center">
                <FormLabel>Password</FormLabel>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info width={20} height={20} fill={''} stroke="white" style={{ marginLeft: '0.3rem' }} />
                    </TooltipTrigger>
                    <TooltipContent>Password must be at least 8 characters</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <FormControl>
                <Input {...field} required={true} type="password" />
              </FormControl>
              {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
            </FormItem>
          )}
        />
        <span className="text-gray-600">
          By proceeding, you agree to these{' '}
          <Link className="text-gray-600" href="https://www.valyx.com/terms-and-conditions/">
            Terms & Conditions
          </Link>{' '}
          and{' '}
          <Link className="text-gray-600" href="https://www.valyx.com/privacy-policy/">
            Privacy Policy
          </Link>
        </span>
        <Button type="submit" className="w-full mt-4">
          Submit
        </Button>
      </form>
    </Form>
  );
}
