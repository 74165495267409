import { fetchAuth, getPanDetails, signInWithPassword, signUp, signout, switchCompany } from '@/api-functions/auth.api';
import { IAuthResponse, IPanDetailsResponse } from '@/types/auth.types';
import { IApiResponse } from '@/types/common.types';
import { UseMutationOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

export const AuthQueryKeys = {
  getAuthenticationStatus: () => ['auth'],
};

export const useAuthToken = () => {
  const authQuery = useQuery<IAuthResponse>({
    queryKey: AuthQueryKeys.getAuthenticationStatus(),
    queryFn: fetchAuth,
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: true,
  });

  return authQuery;
};

export const usePostMutationSignInWithPassword = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IAuthResponse,
    AxiosError<IApiResponse<string>>,
    {
      email: string;
      password: string;
    }
  >;
}) => {
  const navigate = useNavigate();

  const redirectURL = new URLSearchParams(useLocation().search).get('redirect');
  const queryClient = useQueryClient();

  const postSignIn = useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) =>
      signInWithPassword({
        email,
        password,
      }),
    onSuccess: async (data) => {
      await queryClient.setQueryData(AuthQueryKeys.getAuthenticationStatus(), {
        ...data,
        message: 'Authenticated user',
      });
      await queryClient.resetQueries({ queryKey: ['companies'] });
      if (!redirectURL) {
        navigate('/cashflow/summary');
        return;
      }
      navigate(redirectURL);
    },
    ...customConfig,
  });

  return postSignIn;
};

export const usePostMutationSignUp = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IAuthResponse,
    AxiosError<{
      data: object;
      message: string;
      error: string;
    }>,
    {
      firstName?: string;
      password: string;
      lastName?: string;
      pan?: string;
      invitationCode: string;
    }
  >;
}) => {
  const redirectURL = new URLSearchParams(useLocation().search).get('redirect');
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const postSignUp = useMutation({
    mutationFn: ({
      firstName,
      lastName,
      password,
      invitationCode,
      pan,
    }: {
      firstName?: string;
      password: string;
      lastName?: string;
      invitationCode: string;
      pan?: string;
    }) =>
      signUp({
        firstName,
        lastName,
        password,
        invitationCode,
        pan,
      }),
    onSuccess: async (data) => {
      await queryClient.setQueryData(AuthQueryKeys.getAuthenticationStatus(), {
        ...data,
        message: 'Authenticated user',
      });
      await queryClient.resetQueries({ queryKey: ['companies'] });
      if (data.data.status === 'ONBOARDING_COMPLETE') {
        if (redirectURL === null) {
          navigate('/cashflow/summary');
        } else {
          navigate(redirectURL);
        }
      }
    },
    ...customConfig,
  });

  return postSignUp;
};

export const useSignOut = ({ customConfig }: { customConfig?: UseMutationOptions<string, unknown> }) => {
  const QueryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: () => signout(),
    onSuccess: async () => {
      await QueryClient.invalidateQueries({ queryKey: ['auth'] });
      await QueryClient.resetQueries({ queryKey: ['companies'] });
      await QueryClient.invalidateQueries({ queryKey: ['profile'] });
      navigate('/signin');
    },
    ...customConfig,
  });
};

export const useGetPanDetailsMutation = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IPanDetailsResponse,
    AxiosError<IApiResponse<string>>,
    {
      pan: string;
      inviteCode: string;
    }
  >;
}) => {
  return useMutation<
    IPanDetailsResponse,
    AxiosError<IApiResponse<string>>,
    {
      pan: string;
      inviteCode: string;
    }
  >({
    mutationFn: ({ pan, inviteCode }: { pan: string; inviteCode: string }) =>
      getPanDetails({
        pan,
        inviteCode,
      }),
    retry: false,
    ...customConfig,
  });
};

export const useSwitchCompany = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IAuthResponse,
    AxiosError<IApiResponse<string>>,
    {
      currentCompanyId: string;
    }
  >;
}) => {
  return useMutation({
    mutationFn: ({ currentCompanyId }: { currentCompanyId: string }) => switchCompany({ companyId: currentCompanyId }),
    onSuccess: async () => {},
    ...customConfig,
  });
};
