import { getBankTransactionById } from '@/api-functions/bank-transactions.api';
import {
  deleteCreditTerm,
  getCreditTermTitleSuggestions,
  getCreditTerms,
  getCreditTermsByCustomerId,
  getCreditTermsById,
  getExportCustomerExcelForCreditTerm,
  getVariantsByCreditTermTitle,
  markAsDefault,
  postAddCustomerTermRelation,
  postCreateCreditTerm,
  postRemoveCustomerTermRelation,
  updateTerm,
} from '@/api-functions/credit-terms.api';
import {
  CreditTermType,
  ICreateCreditTermResponse,
  ICreditTermByIdResponse,
  ICreditTermTitleSuggestionsResponse,
  ICreditTerms,
  ICreditTermsResponse,
  ICreditTermsWithCustomerId,
  IExportCustomersAsExcelResponse,
  IGetCreditTermsForCompanyResponse,
} from '@/types/credit-term.types';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

const CreditTermQueryKeys = {
  getAllCreditTerms: () => ['getAllCreditTerms'],
  getCreditTermById: (creditTermsId: string) => ['getCreditTermById', creditTermsId],
  getCreditTermByCustomerId: (customerId: string) => ['getCreditTermByCustomerId', customerId],
};

export const useGetCreditTerms = ({
  customConfig,
}: {
  customConfig?: Omit<UseQueryOptions<IGetCreditTermsForCompanyResponse>, 'queryKey'>;
}) => {
  const getCreditTermsByCompanyIdQuery = useQuery<IGetCreditTermsForCompanyResponse>({
    queryKey: CreditTermQueryKeys.getAllCreditTerms(),
    queryFn: () => getCreditTerms(),
    ...customConfig,
    select(data) {
      let isDefaultPaymentTermPresent = false;
      let isDefaultGuaranteeTermPresent = false;
      const listOfTerms = data.data.map((creditTerm) => ({
        ...creditTerm,
        type: creditTerm.type as CreditTermType,
        terms: creditTerm.terms.map((term) => {
          const variants = term.variants
            .map((variant) => {
              if (variant.isDefault) {
                if (variant.type === 'PAYMENT_TERM') {
                  isDefaultPaymentTermPresent = true;
                }
                if (variant.type === 'MIN_GUARANTEE_TERM') {
                  isDefaultGuaranteeTermPresent = true;
                }
                return {
                  ...variant,
                  order: 0,
                };
              }
              return {
                ...variant,
                order: 1,
              };
            })
            .toSorted((a, b) => a.order - b.order)
            .map((variant, index) => {
              if (index === 0) {
                return {
                  ...variant,
                  showTitle: true,
                };
              }

              return variant;
            });

          return {
            ...term,
            variants,
          };
        }),
      }));

      return {
        ...data,
        data: listOfTerms,
        isDefaultPresent: {
          paymentTerm: isDefaultPaymentTermPresent,
          guaranteeTerm: isDefaultGuaranteeTermPresent,
        },
      };
    },
  });

  return getCreditTermsByCompanyIdQuery;
};

export const useGetCreditTermsByCustomerId = ({
  customerId,
  customConfig,
}: {
  customerId: string;
  customConfig?: Omit<UseQueryOptions<ICreditTermsResponse>, 'queryKey'>;
}) => {
  const getCreditTermsByCompanyIdQuery = useQuery<ICreditTermsResponse>({
    queryKey: CreditTermQueryKeys.getCreditTermByCustomerId(customerId),
    queryFn: () => getCreditTermsByCustomerId({ customerId }),
    ...customConfig,
  });

  return getCreditTermsByCompanyIdQuery;
};

export const useGetCreditTermVariantsByTitle = ({
  title,
  creditTermType,
  customConfig,
}: {
  title: string;
  creditTermType: CreditTermType;
  customConfig?: Omit<UseQueryOptions<ICreditTermsResponse>, 'queryKey'>;
}) => {
  const getCreditTermsByCompanyIdQuery = useQuery<ICreditTermsResponse>({
    queryKey: ['getCreditTermVariantsByTitle', title, creditTermType],
    queryFn: () => getVariantsByCreditTermTitle(title, creditTermType),
    ...customConfig,
  });

  return getCreditTermsByCompanyIdQuery;
};

export const useGetCreditTermTitleSuggestions = ({
  type,
  customConfig,
}: {
  customConfig?: Omit<UseQueryOptions<ICreditTermTitleSuggestionsResponse>, 'queryKey'>;
  type: CreditTermType;
}) => {
  const getCreditTermTitleSuggestionsQuery = useQuery<ICreditTermTitleSuggestionsResponse>({
    queryKey: ['getCreditTermTitleSuggestions', type],
    queryFn: () =>
      getCreditTermTitleSuggestions({
        type,
      }),
    ...customConfig,
  });

  return getCreditTermTitleSuggestionsQuery;
};

export const useGetCreditTermsById = ({
  creditTermId,
  customConfig,
}: {
  creditTermId: string;
  customConfig?: Omit<UseQueryOptions<ICreditTermByIdResponse>, 'queryKey'>;
}) => {
  const getCreditTermsByIdQuery = useQuery<ICreditTermByIdResponse>({
    queryKey: CreditTermQueryKeys.getCreditTermById(creditTermId),
    queryFn: () => getCreditTermsById(creditTermId),
    ...customConfig,
  });

  return getCreditTermsByIdQuery;
};

export const usePostCreateCreditTerm = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    ICreditTermsWithCustomerId[]
  >;
}) => {
  const queryClient = useQueryClient();
  const postCreateCreditTermMutation = useMutation<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    ICreditTermsWithCustomerId[]
  >({
    mutationFn: (terms: ICreditTermsWithCustomerId[]) => postCreateCreditTerm(terms),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: CreditTermQueryKeys.getAllCreditTerms(),
      });
      if (variables?.at(0)?.customerId) {
        queryClient.refetchQueries({
          queryKey: CreditTermQueryKeys.getCreditTermByCustomerId(variables?.at(0)?.customerId || ''),
        });
        queryClient.refetchQueries({
          queryKey: ['getCreditTermTitleSuggestions'],
        });
        queryClient.refetchQueries({
          predicate: (query) => {
            return query.queryKey.includes('getCreditTermVariantsByTitle');
          },
        });
      }
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postCreateCreditTermMutation;
};

export const usePostMarkCreditTermAsDefault = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string }
  >;
}) => {
  const queryClient = useQueryClient();
  const postMarkCreditTermAsDefaultMutation = useMutation<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string }
  >({
    mutationFn: ({ creditTermId }: { creditTermId: string }) => markAsDefault(creditTermId),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: CreditTermQueryKeys.getAllCreditTerms(),
      });
    },
  });

  return postMarkCreditTermAsDefaultMutation;
};

export const usePostAddCustomerTermRelationship = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string; customerId: string }
  >;
}) => {
  const queryClient = useQueryClient();
  const postAddCustomerTermRelationship = useMutation<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string; customerId: string }
  >({
    mutationFn: ({ creditTermId, customerId }: { creditTermId: string; customerId: string }) =>
      postAddCustomerTermRelation({
        creditTermId,
        customerId,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: CreditTermQueryKeys.getCreditTermByCustomerId(variables.customerId),
      });
    },
  });

  return postAddCustomerTermRelationship;
};

export const usePostRemoveCustomerTermRelationship = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string; customerId: string }
  >;
}) => {
  const queryClient = useQueryClient();
  const postAddCustomerTermRelationship = useMutation<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string; customerId: string }
  >({
    mutationFn: ({ creditTermId, customerId }: { creditTermId: string; customerId: string }) =>
      postRemoveCustomerTermRelation({
        creditTermId,
        customerId,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: CreditTermQueryKeys.getCreditTermByCustomerId(variables.customerId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postAddCustomerTermRelationship;
};

export const useDeleteCreditTerm = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string }
  >;
}) => {
  const queryClient = useQueryClient();
  const deleteCreditTermMutation = useMutation<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string }
  >({
    mutationFn: ({ creditTermId }: { creditTermId: string }) => deleteCreditTerm(creditTermId),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: CreditTermQueryKeys.getAllCreditTerms(),
      });
    },
  });

  return deleteCreditTermMutation;
};

export const useGetCustomerExportCreditTerm = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    AxiosResponse<IExportCustomersAsExcelResponse>,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string }
  >;
}) => {
  const deleteCreditTermMutation = useMutation<
    AxiosResponse<IExportCustomersAsExcelResponse>,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string }
  >({
    mutationFn: ({ creditTermId }: { creditTermId: string }) => getExportCustomerExcelForCreditTerm(creditTermId),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return deleteCreditTermMutation;
};

export const usePostUpdateTerm = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string; term: ICreditTerms<CreditTermType> }
  >;
}) => {
  const queryClient = useQueryClient();
  const postUpdateTermMutation = useMutation<
    ICreateCreditTermResponse,
    AxiosError<ICreateCreditTermResponse>,
    { creditTermId: string; term: ICreditTerms<CreditTermType> }
  >({
    mutationFn: ({ creditTermId, term }: { creditTermId: string; term: ICreditTerms<CreditTermType> }) =>
      updateTerm({
        creditTermId,
        term,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: CreditTermQueryKeys.getAllCreditTerms(),
      });
      queryClient.refetchQueries({
        queryKey: CreditTermQueryKeys.getCreditTermById(variables.creditTermId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postUpdateTermMutation;
};

export const useGetCreditByTransactionId = ({ transactionId }: { transactionId: string }) => {
  const getCreditByTransactionIdQuery = useQuery({
    queryKey: ['getCreditByTransactionId', transactionId],
    queryFn: () => getBankTransactionById(transactionId),
  });

  return getCreditByTransactionIdQuery;
};
