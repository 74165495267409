'use client';

import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { Input } from '../ui/input';
import SelectComponent from '../ui/select-component';
interface IOption {
  label: string;
  value: string;
}

const ClubbedSelectWithInput = <OptionTypes,>({
  options,
  onChange,
  placeholderMap,
  value,
  defaultSelected,
  placeholder,
  inputProps,
  inputClassName,
  selectClassName,
}: {
  options: IOption[];
  onChange: (_: string, __: OptionTypes | undefined) => void;
  placeholderMap: Record<OptionTypes extends string ? OptionTypes : string, string>;
  value: string;
  defaultSelected?: OptionTypes;
  placeholder: string;
  inputProps?: {
    [key: string]: unknown;
  };
  inputClassName?: string;
  selectClassName?: string;
}) => {
  // TODO: add input type
  const [selectedValue, setSelectedValue] = useState<OptionTypes | undefined>(defaultSelected);

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (defaultSelected) {
      setSelectedValue(defaultSelected as OptionTypes);
    }
  }, [defaultSelected]);

  const handleSelect = (value: string) => {
    const newValue = value as OptionTypes;
    setSelectedValue(newValue);
    onChange('', newValue);
  };

  return (
    <div className={cn('flex border rounded-md', focused && 'ring-1 ring-ring')}>
      <SelectComponent
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className={cn(
          ' border-0 ring-0 hover:ring-0 focus-within:ring-0 focus-visible:ring-0 focus:ring-0 ',
          selectClassName,
        )}
        value={selectedValue ? (selectedValue as string) : undefined}
        options={options}
        onChange={handleSelect}
      />
      <Input
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        {...inputProps}
        className={cn(' border-0 ring-0 hover:ring-0 focus-within:ring-0 focus-visible:ring-0 ', inputClassName)}
        placeholder={
          selectedValue
            ? placeholderMap[selectedValue as OptionTypes extends string ? OptionTypes : string]
            : 'Select type first'
        }
        value={value}
        onChange={(e) => onChange(e.target.value, selectedValue)}
      />
    </div>
  );
};

export default ClubbedSelectWithInput;
