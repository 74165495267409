import ToolTipCell from '@/components/Table/ToolTipCell';
import { Skeleton } from '@/components/ui/skeleton';
import { useGetConditionById } from '@/hooks/api-hooks/useWorkflowQuery';
import { cn } from '@/lib/utils';
import { useWorkflowPropertiesStore } from '@/stores/workflow/state-properties.store';
import { IDateCondition, StateTypes } from '@/types/workflow.type';
import { invoiceValueLabelMap } from '@/utils/type-map';
import { AlertTriangleIcon } from 'lucide-react';
import { useMemo } from 'react';

const ConditionItem = ({ dateCondition }: { dateCondition: IDateCondition }) => {
  const lhs = useMemo(() => {
    return dateCondition.waitTillPlaceholder;
  }, [dateCondition]);

  const dateValue = useMemo(() => {
    return dateCondition.waitTillDelta;
  }, [dateCondition]);

  const relativeTime = useMemo(() => {
    if (!dateCondition.waitTillDelta) {
      return '';
    }

    if (dateCondition.waitTillDelta < 0) {
      return 'before';
    }

    if (dateCondition.waitTillDelta > 0) {
      return 'after';
    }

    return '';
  }, [dateCondition]);

  return `till current date is ${dateValue ? `${Math.abs(dateValue)} days` : ''} ${relativeTime}  ${
    invoiceValueLabelMap[lhs?.split('.')[1] as keyof typeof invoiceValueLabelMap]
  }`;
};

const DisplayDateCondition = ({
  nodeId,
  nodeType,
  conditionId,
  catIndex,
}: {
  conditionId: string;
  nodeId: string;
  nodeType: StateTypes;
  catIndex: number;
}) => {
  const { setSelectedProperties, selectedProperties } = useWorkflowPropertiesStore();

  const { data: conditionResponse, isLoading } = useGetConditionById({
    conditionId,
    customConfig: {
      enabled: !!conditionId,
    },
  });

  const handleSelectClick = () => {
    setSelectedProperties({
      nodeId,
      type: nodeType,
      updateType: 'condition',
      catIndex,
      conditionId,
    });
  };

  const condition = useMemo(() => {
    if (!conditionResponse) {
      return null;
    }

    return conditionResponse.data.rule as IDateCondition;
  }, [conditionResponse]);

  const isConditionPresent = useMemo(() => {
    return condition?.waitTillPlaceholder || condition?.waitFor;
  }, [condition]);

  const isWaitForCondition = useMemo(() => {
    return condition?.waitFor;
  }, [condition]);

  if (isLoading) {
    return (
      <div>
        <Skeleton className="w-28 h-8" />
      </div>
    );
  }

  if (!condition || !isConditionPresent) {
    return (
      <div
        onClick={handleSelectClick}
        className={cn(
          ' text-yellow-500 font-semibold  ',
          selectedProperties?.conditionId === conditionId &&
            selectedProperties?.updateType === 'condition' &&
            selectedProperties.nodeId === nodeId &&
            selectedProperties.catIndex === catIndex &&
            'ring-1',
        )}
      >
        <ToolTipCell
          additionalClasses=" absolute -left-11 text-destructive "
          value={<AlertTriangleIcon className=" w-4 h-4 " />}
        >
          <div className=" flex flex-col gap-1 ">1. No condition added</div>
        </ToolTipCell>
        <span className="font-semibold text-primary">Wait</span> Set conditions
      </div>
    );
  }

  if (isWaitForCondition) {
    return (
      <div
        className={cn(
          selectedProperties?.conditionId === conditionId && selectedProperties?.updateType === 'condition' && 'ring-1',
          '',
        )}
        onClick={handleSelectClick}
      >
        {!!conditionResponse?.warnings?.length && (
          <ToolTipCell
            additionalClasses=" absolute -left-11 text-destructive "
            value={<AlertTriangleIcon className=" w-4 h-4 " />}
          >
            <div className=" flex flex-col gap-1 ">
              {conditionResponse?.warnings?.map((w, index) => (
                <div key={w}>
                  {index + 1}. {w}
                </div>
              ))}
            </div>
          </ToolTipCell>
        )}
        <span className="font-semibold">Wait </span>
        <span>for {condition.waitFor} day(s)</span>
      </div>
    );
  }

  return (
    <div
      className={cn(
        selectedProperties?.conditionId === conditionId && selectedProperties?.updateType === 'condition' && 'ring-1',
        '',
      )}
      onClick={handleSelectClick}
    >
      {!!conditionResponse?.warnings?.length && (
        <ToolTipCell
          additionalClasses=" absolute -left-11 text-destructive "
          value={<AlertTriangleIcon className=" w-4 h-4 " />}
        >
          <div className=" flex flex-col gap-1 ">
            {conditionResponse?.warnings?.map((w, index) => (
              <div key={w}>
                {index + 1}. {w}
              </div>
            ))}
          </div>
        </ToolTipCell>
      )}
      <span className="font-semibold">Wait </span>
      <ConditionItem dateCondition={condition} />
    </div>
  );
};

export default DisplayDateCondition;
