'use client';

import { useGetCustomerSuggestionsMutation } from '@/hooks/api-hooks/useCustomerQuery';
import { IOptions } from '@/types/common.types';
import { IAmountType } from '@/types/customer.types';
import { IInvoiceStatus, IInvoicesFilter, IPaymentStatus, TInvoiceDateType } from '@/types/invoices.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useSearchParams } from 'react-router-dom';
import { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import ClubbedSelectWithDatePicker from '../shared/ClubbedSelectDatePicker';
import ClubbedSelectWithRangeInput from '../shared/ClubbedSelectWithRange';
import SelectInvoiceComponent from '../shared/SelectInvoice';
import { InvoiceStatusDropdown, PaymentStatusDropDown } from '../shared/StatusDropDowns';
import { Button } from '../ui/button';

// TODO: common type
interface IInvoiceStatusOption {
  label: string;
  value: IInvoiceStatus;
}

interface IPaymentStatusOption {
  label: string;
  value: IPaymentStatus;
}

const InvoicesFilters = ({
  handleSubmit,
  isPostingRequest = false,
}: {
  handleSubmit: (_: Partial<IInvoicesFilter>) => void;
  isPostingRequest?: boolean;
}) => {
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState<IInvoiceStatusOption[]>([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<IPaymentStatusOption[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<IOptions[]>([]);

  const [invoiceNumber, setInvoiceNumber] = useState<string | undefined>('');

  const defaultDateRangeType = 'INVOICE_DATE';
  const [dateRangeWithType, setDateRangeWithType] = useState<{
    type: TInvoiceDateType;
    value: DateRange;
  }>({
    type: defaultDateRangeType,
    value: {
      from: undefined,
      to: undefined,
    },
  });

  const [amountWithType, setAmountWithType] = useState<{
    type: IAmountType | undefined;
    value: [number | undefined, number | undefined];
  }>({
    type: 'OUTSTANDING',
    value: [undefined, undefined],
  });

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    handleSubmit({
      amountType: (searchParams.get('amountType') as IAmountType | undefined) || 'OUTSTANDING',
      minAmount: Number(searchParams.get('minAmount')) || undefined,
      maxAmount: Number(searchParams.get('maxAmount')) || undefined,
      dateRange: {
        startDate: searchParams.get('dateRangeStart') ? dayjs(searchParams.get('dateRangeStart')).toDate() : undefined,
        endDate: searchParams.get('dateRangeEnd') ? dayjs(searchParams.get('dateRangeEnd')).toDate() : undefined,
      },

      dateType: (searchParams.get('dateRangeType') as TInvoiceDateType) || 'INVOICE_DATE',
      paymentStatus: (searchParams
        .get('paymentStatus')
        ?.trim()
        ?.split(',')
        .filter((item) => !!item)
        .map((item) => item.split(':')[0] as IPaymentStatus) ?? []) as IPaymentStatus[],
      invoiceNumber: searchParams.get('invoiceNumber') || '',
      customerIds:
        (
          searchParams
            .get('customers')
            ?.trim()
            ?.split(',')
            .filter((item) => !!item)
            .map((item) => item.split(':')[0]) ?? []
        ).join(',') || '',
      invoiceStatus: (searchParams
        .get('invoiceStatus')
        ?.trim()
        ?.split(',')
        .filter((item) => !!item)
        .map((item) => item.split(':')[0] as IInvoiceStatus) ?? []) as IInvoiceStatus[],
    });

    if (searchParams.get('amountType') && searchParams.get('minAmount') && searchParams.get('maxAmount')) {
      setAmountWithType({
        type: (searchParams.get('amountType') as IAmountType | undefined) || 'OUTSTANDING',
        value: [Number(searchParams.get('minAmount')), Number(searchParams.get('maxAmount'))],
      });
    }

    if (searchParams.get('dateRangeStart') && searchParams.get('dateRangeEnd')) {
      setDateRangeWithType({
        type: (searchParams.get('dateRangeType') as TInvoiceDateType) || 'INVOICE_DATE',
        value: {
          from: searchParams.get('dateRangeStart') ? dayjs(searchParams.get('dateRangeStart')).toDate() : undefined,
          to: searchParams.get('dateRangeEnd') ? dayjs(searchParams.get('dateRangeEnd')).toDate() : undefined,
        },
      });
    }

    if (searchParams.get('invoiceNumber')) {
      setInvoiceNumber(searchParams.get('invoiceNumber') || '');
    }

    if (searchParams.get('customers')?.length) {
      setSelectedCustomers(
        (searchParams.get('customers')?.split(',') || []).map((item) => ({
          label: item.split(':')[1] as string,
          value: item.split(':')[0],
        })) as IOptions[],
      );
    }

    if (searchParams.get('invoiceStatus')?.length) {
      setSelectedInvoiceStatus(
        (searchParams.get('invoiceStatus')?.split(',') || []).map((item) => ({
          label: item.split(':')[1] as string,
          value: item.split(':')[0] as IInvoiceStatus,
        })) as IInvoiceStatusOption[],
      );
    }

    if (searchParams.get('paymentStatus')?.length) {
      setSelectedPaymentStatus(
        (searchParams.get('paymentStatus')?.split(',') || []).map((item) => ({
          label: item.split(':')[1] as string,
          value: item.split(':')[0] as IPaymentStatus,
        })) as IPaymentStatusOption[],
      );
    }
  }, [searchParams, handleSubmit]);

  const amountTypeOptions = useMemo<
    {
      label: string;
      value: IAmountType;
    }[]
  >(
    () => [
      {
        label: 'Due/Overdue',
        value: 'OUTSTANDING',
      },
      {
        label: 'Total',
        value: 'TOTAL',
      },
    ],
    [],
  );

  const amountTypeMap = useMemo<Record<IAmountType, string>>(() => {
    return {
      OUTSTANDING: 'Select due/overdue amount',
      TOTAL: 'Select total amount',
      ADJUSTED: 'Select adjusted amount',
      PAID: 'Select paid amount',
    };
  }, []);

  const { mutateAsync: fetchCustomers } = useGetCustomerSuggestionsMutation({});

  const handleAmountSelect = (amount: [number | undefined, number | undefined], type: IAmountType | undefined) => {
    setAmountWithType({ type, value: amount });
  };

  const handleInvoiceStatusSelect = (option: IInvoiceStatusOption) => () => {
    if (selectedInvoiceStatus.find((item) => item.value === option.value)) {
      setSelectedInvoiceStatus(selectedInvoiceStatus.filter((item) => item.value !== option.value));
    } else {
      setSelectedInvoiceStatus([
        ...selectedInvoiceStatus,
        {
          label: option.label,
          value: option.value,
        },
      ]);
    }
  };

  const handlePaymentStatusSelect = (option: IPaymentStatusOption) => () => {
    if (selectedPaymentStatus.find((item) => item.value === option.value)) {
      setSelectedPaymentStatus(selectedPaymentStatus.filter((item) => item.value !== option.value));
    } else {
      setSelectedPaymentStatus([
        ...selectedPaymentStatus,
        {
          label: option.label,
          value: option.value,
        },
      ]);
    }
  };

  const handleDateRangeChange = (value: DateRange | undefined, type: TInvoiceDateType) => {
    if (value) {
      setDateRangeWithType({
        type,
        value: {
          from: value.from,
          to: value.to,
        },
      });
    }
    setDateRangeWithType({
      type,
      value: {
        from: value?.from,
        to: value?.to,
      },
    });
  };

  const dateOptions = useMemo<
    {
      value: TInvoiceDateType;
      label: string;
    }[]
  >(() => {
    return [
      {
        value: 'INVOICE_DATE',
        label: 'Invoice date',
      },
      {
        value: 'DUE_DATE',
        label: 'Due date',
      },
      {
        value: 'PROMISE_TO_PAY_DATE',
        label: 'Promise to pay date',
      },
    ];
  }, []);

  const handleSubmitFilter = () => {
    setSearchParams(
      {
        paymentStatus: selectedPaymentStatus.map((item) => `${item.value}:${item.label}`).join(','),
        customers: selectedCustomers.map((item) => `${item.value}:${item.label}`).join(','),
        invoiceStatus: selectedInvoiceStatus.map((item) => `${item.value}:${item.label}`),
        invoiceNumber: invoiceNumber?.trim() || '',
        dateRangeType: dateRangeWithType.type,
        dateRangeStart: dateRangeWithType.value.from ? dayjs(dateRangeWithType.value.from).format('YYYY-MM-DD') : '',
        dateRangeEnd: dateRangeWithType.value.to ? dayjs(dateRangeWithType.value.to).format('YYYY-MM-DD') : '',
        amountType: amountWithType.type || '',
        minAmount: amountWithType.value[0]?.toString() || '',
        maxAmount: amountWithType.value[1]?.toString() || '',
      },
      {
        replace: true,
      },
    );

    handleSubmit({
      invoiceStatus: selectedInvoiceStatus.map((item) => item.value),
      amountType: amountWithType.type,
      minAmount: amountWithType.value[0],
      maxAmount: amountWithType.value[1],
      dateRange: {
        startDate: dateRangeWithType.value.from,
        endDate: dateRangeWithType.value.to,
      },
      invoiceNumber: invoiceNumber?.trim(),
      dateType: dateRangeWithType.type,
      paymentStatus: selectedPaymentStatus.map((item) => item.value),
      customerIds: selectedCustomers.map((item) => item.value).join(','),
    });
  };

  const handleReset = () => {
    setSelectedInvoiceStatus([]);
    setSelectedPaymentStatus([]);
    setAmountWithType({ type: 'OUTSTANDING', value: [undefined, undefined] });
    handleSubmit({
      invoiceStatus: [],
      amountType: undefined,
      minAmount: undefined,
      maxAmount: undefined,
      invoiceNumber: '',
      dateRange: {
        startDate: undefined,
        endDate: undefined,
      },
      paymentStatus: [],
    });

    setDateRangeWithType({
      type: defaultDateRangeType,
      value: {
        from: undefined,
        to: undefined,
      },
    });

    setInvoiceNumber(undefined);

    setSearchParams(
      {},
      {
        replace: true,
      },
    );
  };

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchCustomers({
        query: inputValue.trim(),
      });
      return result.data.suggestions.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    [fetchCustomers],
  );

  const handleCustomerSelectChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((account) => ({ value: account.value, label: account.label }));
    setSelectedCustomers(newValue);
  };

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, IOptions>();
  }, []);

  return !isPostingRequest ? (
    <div className="mb-4 flex flex-col gap-4">
      <div className="flex items-center justify-between gap-4">
        <ClubbedSelectWithDatePicker
          defaultSelected={dateRangeWithType.type}
          value={dateRangeWithType.value}
          onChange={handleDateRangeChange}
          options={dateOptions}
        />
        <ClubbedSelectWithRangeInput
          defaultSelected={amountWithType.type}
          onChange={handleAmountSelect}
          options={amountTypeOptions}
          value={amountWithType.value}
          placeholderMap={amountTypeMap}
          placeholder="Select amount type"
        />
        <InvoiceStatusDropdown handleSelect={handleInvoiceStatusSelect} selected={selectedInvoiceStatus} />
      </div>
      <div className="flex items-center gap-4">
        <PaymentStatusDropDown handleSelect={handlePaymentStatusSelect} selected={selectedPaymentStatus} />
        <AsyncSelect
          isMulti
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          onChange={handleCustomerSelectChange}
          value={selectedCustomers}
          loadOptions={loadSelectOptions}
          className=" min-w-[200px] text-sm shadow-sm"
          styles={selectStyles}
          placeholder="Search by customer name"
          classNames={selectClasses}
        />
        <SelectInvoiceComponent
          value={invoiceNumber || ''}
          onChange={(value) => setInvoiceNumber(value)}
          className=" flex-1 min-w-[200px] text-sm "
          placeholder="Search by invoice number"
        />
      </div>
      <div className=" flex gap-4 ">
        <Button onClick={handleSubmitFilter}>Apply</Button>
        <Button onClick={handleReset} variant="outline">
          Reset
        </Button>
      </div>
    </div>
  ) : (
    <div className="mb-4">
      <div className="flex items-center gap-4 pb-2 flex-wrap">
        <ClubbedSelectWithDatePicker
          defaultSelected={dateRangeWithType.type}
          value={dateRangeWithType.value}
          onChange={handleDateRangeChange}
          options={dateOptions}
        />
        <PaymentStatusDropDown handleSelect={handlePaymentStatusSelect} selected={selectedPaymentStatus} />
        <AsyncSelect
          isMulti
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          onChange={handleCustomerSelectChange}
          value={selectedCustomers}
          loadOptions={loadSelectOptions}
          className=" min-w-[200px] text-sm shadow-sm"
          styles={selectStyles}
          placeholder="Search by customer name"
          classNames={selectClasses}
        />

        <Button onClick={handleSubmitFilter}>Apply</Button>
        <Button onClick={handleReset} variant="outline">
          Reset
        </Button>
      </div>
    </div>
  );
};

export default InvoicesFilters;
