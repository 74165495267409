import { useAuthToken } from '@/hooks/api-hooks/useAuth';
import { dataAxiosInstance } from '@/lib/axios';
import { Loader2Icon } from 'lucide-react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function RequireAuth({ children }: { children: React.ReactNode }) {
  //TODO: convert to query
  const navigate = useNavigate();
  const { isError, isLoading } = useAuthToken();
  const [navigateTo, setNavigateTo] = useState('');
  const pathName = useLocation().pathname;
  if (navigateTo === '') {
    setNavigateTo(pathName);
  }

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center overflow-hidden">
        <div className="flex items-center px-2 sm:px-4 md:px-8">
          <Loader2Icon className="w-10 h-10 animate-spin" />
        </div>
      </div>
    );
  }

  if (isError) {
    dataAxiosInstance.defaults.headers.common['Authorization'] = '';
    navigate(`/signin?redirect=${encodeURIComponent(navigateTo)}`);
  }

  return children;
}
