import './lib/sentry';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ContextProvider from './contexts/UserContext';
import MicrosoftClarityScriptTag from './lib/msclarity';
import ReactQueryProvider from './providers/QueryClientProvider';
import './styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ReactQueryProvider>
      <BrowserRouter>
        <ContextProvider>
          <MicrosoftClarityScriptTag />
          <App />
        </ContextProvider>
      </BrowserRouter>
    </ReactQueryProvider>
  </React.StrictMode>,
);
