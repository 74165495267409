import ActivityDetail from '@/components/activity/ActivityDetails';
import { Navigate, useParams } from 'react-router-dom';

const ActivityDetailsPage = () => {
  const { activityId } = useParams();

  if (!activityId) {
    return <Navigate to="/404" />;
  }

  return (
    <div className=" p-4 flex flex-col gap-4 ">
      <ActivityDetail isMainContent activityId={activityId} />
    </div>
  );
};

export default ActivityDetailsPage;
