import { useGetInvoiceSuggestionsByInvoiceNumber } from '@/hooks/api-hooks/useInvoiceQuery';
import { IOptions } from '@/types/common.types';
import { IInvoice } from '@/types/invoices.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import debounce from 'lodash.debounce';
import { useCallback, useMemo } from 'react';
import { GroupBase, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';

interface IInvoiceOption {
  value: string;
  label: string;
  data?: IInvoice;
}

const SelectInvoiceComponent = ({
  value,
  onChange,
  placeholder = 'Enter invoice number',
  className = '',
}: {
  value: string;
  onChange: (_: string) => void;
  placeholder?: string;
  className?: string;
}) => {
  const { mutateAsync: fetchInvoices } = useGetInvoiceSuggestionsByInvoiceNumber({});

  const loadInvoices = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IInvoiceOption, GroupBase<IInvoiceOption>>) => void) => {
      fetchInvoices({
        invoiceNumber: inputValue.trim(),
      })
        .then((result) => {
          const options = result.data.docs
            .map((item) => ({
              value: item.invoiceNumber || '',
              label: item.invoiceNumber || '',
            }))
            .filter((item) => Boolean(item.value));

          callback(options);
        })
        .catch(() => callback([]));
      return;
    },
    [fetchInvoices],
  );

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false, IInvoiceOption>();
  }, []);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const debouncedInvoiceSuggestions = useMemo(() => {
    return debounce(loadInvoices, 500);
  }, [loadInvoices]);

  const handleChange = (value: IOptions | null) => {
    if (!value) {
      return;
    }

    onChange(value.value);
  };

  return (
    <AsyncSelect
      isMulti={false}
      key={`select_${value}`}
      placeholder={placeholder}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      value={
        value.trim()
          ? {
              value: value,
              label: value,
            }
          : undefined
      }
      loadOptions={debouncedInvoiceSuggestions}
      menuPortalTarget={document.body}
      className={className}
      styles={selectStyles}
      classNames={selectClasses}
      onChange={handleChange}
    />
  );
};

export default SelectInvoiceComponent;
