import ToolTipCell from '@/components/Table/ToolTipCell';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import { useUserContext } from '@/contexts/UserContext';
import {
  useDeleteStateById,
  useGetStateById,
  usePostCreateEmailAction,
  usePostCreateGotoAction,
  usePostUpdateConditionalState,
} from '@/hooks/api-hooks/useWorkflowQuery';
import { cn } from '@/lib/utils';
import { IConditionActionTree, IConditionalStateProperties, IState } from '@/types/workflow.type';
import { autoUpdate, useFloating } from '@floating-ui/react';
import { AlertTriangleIcon, Loader2Icon, MailIcon, PlusIcon, Trash2Icon, WaypointsIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ActionSlot } from './action-blocks/action-blocks';
import DisplayCondition from './DisplayCondition';

const ConditionActionBlock = ({
  tree,
  index,
  stateId,
  workflowId,
  conditionActionTrees,
}: {
  tree: IConditionActionTree;
  index: number;
  stateId: string;
  workflowId: string;
  conditionActionTrees: IConditionActionTree[];
}) => {
  const timeout = useRef<number>();
  const [visible, setVisible] = useState(false);
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
  });

  const [isDelete, setIsDelete] = useState(false);
  const [isCreateEmail, setIsCreateEmail] = useState(false);
  const [isCreateGoto, setIsCreateGoto] = useState(false);

  const [isGotoActionPresent, setIsGotoActionPresent] = useState(false);

  useEffect(() => {
    setIsGotoActionPresent(false);
  }, [tree]);

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(true);
    }, 25);
  }, []);
  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(false);
    }, 25);
  }, []);

  const deleteButtonUpdateTimeout = useRef<number>();
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);
  const { refs: deleteButtonRefs, floatingStyles: deleteButtonFloatingStyles } = useFloating({
    placement: 'right-start',
    whileElementsMounted: autoUpdate,
  });

  const handleRootDivMouseEnter = useCallback(() => {
    clearTimeout(deleteButtonUpdateTimeout.current);
    deleteButtonUpdateTimeout.current = setTimeout(() => {
      setIsDeleteButtonVisible(true);
    }, 25);
  }, []);
  const handleRootDivMouseLeave = useCallback(() => {
    clearTimeout(deleteButtonUpdateTimeout.current);
    deleteButtonUpdateTimeout.current = setTimeout(() => {
      setIsDeleteButtonVisible(false);
    }, 25);
  }, []);

  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { toast } = useToast();

  const { mutateAsync: createEmailAction, isPending: isCreateEmailActionPending } = usePostCreateEmailAction({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to create email action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutateAsync: createGotoAction, isPending: isCreateGotoActionPending } = usePostCreateGotoAction({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to create email action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateConditionalState, isPending: isUpdatePending } = usePostUpdateConditionalState({
    workflowId: workflowId,
    containsGoto: isGotoActionPresent,
    customConfig: {
      onSuccess() {
        setIsDelete(false);
      },
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Unable to create email action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const newActionCreatePending = useMemo(() => {
    return isCreateEmailActionPending || isUpdatePending || isCreateGotoActionPending;
  }, [isCreateEmailActionPending, isUpdatePending, isCreateGotoActionPending]);

  const handleAddEmail = () => {
    setIsCreateGoto(false);
    setIsCreateEmail(true);
    createEmailAction({
      email: {
        companyId: companiesOfUser[activeCompanyIndex].id,
        workflowId: workflowId,
      },
      workflowId: workflowId,
    }).then((returnedData) => {
      updateConditionalState({
        stateId: stateId,
        fields: ['conditionActionTrees'],
        values: {
          conditionActionTrees: conditionActionTrees.map((conditionActionTree, treeIndex) => {
            if (treeIndex === index) {
              return {
                ...conditionActionTree,
                actions: [...conditionActionTree.actions, returnedData.data.id],
              };
            }

            return conditionActionTree;
          }),
        },
      });
    });
  };

  const handleAddGoto = () => {
    setIsCreateEmail(false);
    setIsCreateGoto(true);
    createGotoAction({
      workflowId: workflowId,
      label: 'Goto',
      fromState: stateId,
      letSystemDecide: false,
    }).then((returnedData) => {
      updateConditionalState({
        stateId: stateId,
        fields: ['conditionActionTrees'],
        values: {
          conditionActionTrees: conditionActionTrees.map((conditionActionTree, treeIndex) => {
            if (treeIndex === index) {
              return {
                ...conditionActionTree,
                actions: [...conditionActionTree.actions, returnedData.data.id],
              };
            }

            return conditionActionTree;
          }),
        },
      });
    });
  };

  const handleAddGotoAction = () => {
    createGotoAction({
      fromState: stateId,
      label: 'Goto',
      letSystemDecide: false,
      workflowId: workflowId,
    }).then((returnedData) => {
      updateConditionalState({
        stateId: stateId,
        fields: ['conditionActionTrees'],
        values: {
          conditionActionTrees: conditionActionTrees.map((conditionActionTree, treeIndex) => {
            if (treeIndex === index) {
              return {
                ...conditionActionTree,
                actions: [...conditionActionTree.actions, returnedData.data.id],
              };
            }

            return conditionActionTree;
          }),
        },
      });
    });
  };

  const handleDeleteCat = ({ catIndex }: { catIndex: number }) => {
    setIsDelete(true);
    updateConditionalState({
      stateId: stateId,
      fields: ['conditionActionTrees'],
      values: {
        conditionActionTrees: conditionActionTrees.filter((_, index) => index !== catIndex),
      },
    });
  };

  return (
    <div
      className=" mb-4 "
      ref={(ref) => {
        refs.setReference(ref);
        deleteButtonRefs.setReference(ref);
      }}
      onMouseEnter={() => {
        handleMouseEnter();
        handleRootDivMouseEnter();
      }}
      onMouseLeave={() => {
        handleMouseLeave();
        handleRootDivMouseLeave();
      }}
    >
      {index > 0 && (
        <div
          ref={deleteButtonRefs.setFloating}
          style={deleteButtonFloatingStyles}
          className={cn('  px-1 ', isDeleteButtonVisible ? 'opacity-100' : 'opacity-0')}
        >
          <Button
            disabled={isUpdatePending}
            onClick={() => handleDeleteCat({ catIndex: index })}
            className=" text-destructive   bg-white relative right-5 "
            variant="outline"
            size="icon"
          >
            {isUpdatePending ? <Loader2Icon className="w-3 h-3 animate-spin" /> : <Trash2Icon className="w-3 h-3" />}
          </Button>
        </div>
      )}
      <div className=" py-2  ">
        <div className="flex gap-2 items-start">
          {index > 0 ? <span className="font-semibold">Else if</span> : <span className="font-semibold">If</span>}
          <DisplayCondition nodeId={stateId} nodeType="CONDITIONAL" catIndex={index} conditionId={tree.condition} />
        </div>
        <div className=" flex flex-col gap-2 py-1  ">
          {tree.actions.map((action, index) => (
            <div key={index} className=" border-l-4 px-2 border-muted-foreground/20 ">
              <ActionSlot
                setIsGotoActionPresent={setIsGotoActionPresent}
                actionId={action}
                catIndex={index}
                nodeType="CONDITIONAL"
                stateId={stateId}
                workflowId={workflowId}
              />
            </div>
          ))}
          {newActionCreatePending && !isDelete && isCreateEmail && (
            <div className=" flex items-center gap-2 border-l-4 px-2 border-muted-foreground/20 ">
              <div className=" bg-muted-foreground/10 p-2 rounded-md ">
                <MailIcon className=" h-8 w-8 " />
              </div>
              <div className=" w-ful flex flex-col gap-1 flex-1 ">
                <div className=" w-full flex items-end gap-2 ">
                  <span className=" w-[80px] ">From:</span>
                  <Skeleton className="flex-1 h-4" />
                </div>
                <div className=" w-full flex items-start gap-2">
                  <span className=" w-[80px] ">Template:</span>
                  <Skeleton className="flex-1 h-4" />
                </div>
              </div>
            </div>
          )}
          {newActionCreatePending && !isDelete && isCreateGoto && (
            <div className=" flex items-center gap-2 border-l-4 px-2 border-muted-foreground/20 ">
              <div className=" bg-muted-foreground/10 p-2 rounded-md ">
                <WaypointsIcon className=" h-8 w-8 " />
              </div>
              <div className=" w-ful flex flex-col gap-1 flex-1 ">
                <div className=" w-full flex items-start  gap-2 ">
                  <span className=" ">To:</span>
                  <Skeleton className="flex-1 h-4" />
                </div>
              </div>
            </div>
          )}
          {tree.actions.length === 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button className=" border-dashed text-blue-700 w-full flex items-center gap-2 " variant="outline">
                  <PlusIcon className="w-3 h-3" />
                  Add Action
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className=" w-[250px] ">
                <DropdownMenuGroup>
                  <DropdownMenuItem onClick={handleAddEmail}>Email</DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuGroup>
                  <DropdownMenuItem onClick={handleAddGoto}>Goto</DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
      {!isGotoActionPresent && tree.actions.length > 0 && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={cn('w-[90%] text-center', visible ? 'opacity-100' : 'opacity-0')}
        >
          <div className=" border-b-4 border-blue-300 relative ">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button className=" absolute -translate-x-1/2 -bottom-2 w-4 h-4 text-white " size="icon">
                  <PlusIcon className="w-3 h-3" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className=" w-[250px] ">
                <DropdownMenuGroup>
                  <DropdownMenuItem onClick={() => handleAddEmail()}>Email</DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuGroup>
                  <DropdownMenuItem onClick={() => handleAddGotoAction()}>Goto</DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      )}
    </div>
  );
};

const AddElseIfButton = ({
  stateProperties,
  stateId,
  workflowId,
}: {
  stateProperties: IConditionalStateProperties;
  stateId: string;
  workflowId: string;
}) => {
  const { toast } = useToast();

  const { mutate: updateConditionalState, isPending } = usePostUpdateConditionalState({
    workflowId: workflowId,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Failed to add else if',
          variant: 'destructive',
        });
      },
    },
  });

  const handleAddElseIf = () => {
    updateConditionalState({
      stateId,
      fields: ['conditionActionTrees'],
      values: {
        conditionActionTrees: [
          ...stateProperties.conditionActionTrees,
          {
            actions: [],
          },
        ],
      },
    });
  };

  return (
    <Button onClick={handleAddElseIf} className=" text-blue-700  " variant="ghost">
      {isPending ? (
        <Loader2Icon className="w-4 h-4 animate-spin" />
      ) : (
        <>
          <PlusIcon className="w-4 h-4" /> Add else if
        </>
      )}
    </Button>
  );
};

const ConditionalBlock = (props: NodeProps<IState<'CONDITIONAL'>>) => {
  const { data: conditionalStateResponse, isLoading } = useGetStateById({
    stateId: props.id,
    customConfig: {
      enabled: !!props.id,
    },
  });

  const { mutate: deleteState, isPending } = useDeleteStateById({
    stateId: props.id,
    workflowId: conditionalStateResponse?.data.workflowId || '',
  });

  const timeout = useRef<number>();
  const [visible, setVisible] = useState(false);
  const { refs, floatingStyles } = useFloating({
    placement: 'right-start',
    whileElementsMounted: autoUpdate,
  });

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(true);
    }, 25);
  }, []);
  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(false);
    }, 25);
  }, []);

  const handleDeleteState = useCallback(() => {
    deleteState();
  }, [deleteState]);

  const conditionalBlockState = useMemo(() => {
    return conditionalStateResponse?.data || null;
  }, [conditionalStateResponse]);

  const conditionActionTrees = useMemo(() => {
    if (!conditionalBlockState) return [];
    return (conditionalBlockState.description as IConditionalStateProperties).conditionActionTrees || [];
  }, [conditionalBlockState]);

  if (isLoading) {
    return (
      <>
        <div>
          <Handle id="default" type="target" position={Position.Top} />
          <h3 className=" text-muted-foreground/80 text-sm mb-1 ">Conditional</h3>
          <Card className=" rounded-md  ">
            <CardContent className=" py-4 w-[350px] text-sm ">
              <div className=" mb-4 ">
                {new Array(2).fill(0).map((_, i) => (
                  <div key={i} className=" flex items-center gap-2 border-l-4 px-2 border-muted-foreground/20 ">
                    <div className=" bg-muted-foreground/10 p-2 rounded-md ">
                      <Skeleton className=" h-8 w-8 " />
                    </div>
                    <div className=" w-ful flex flex-col gap-1 flex-1 ">
                      <div className=" w-full flex items-end gap-2 ">
                        <Skeleton className=" w-[80px] h-4 " />
                        <Skeleton className="flex-1 h-4" />
                      </div>
                      <div className=" w-full flex items-start gap-2">
                        <Skeleton className=" w-[80px] h-4 " />
                        <Skeleton className="flex-1 h-4" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
          <Handle id="default" type="source" position={Position.Bottom} />
        </div>
      </>
    );
  }

  if (!conditionalBlockState) {
    return null;
  }

  return (
    <>
      {!!conditionalStateResponse?.warnings?.length && conditionalStateResponse.warnings.length > 0 && (
        <div className=" absolute -left-11 text-destructive -top-6 ">
          <ToolTipCell value={<AlertTriangleIcon className=" w-4 h-4 " />}>
            <div className=" flex flex-col gap-1 ">
              {conditionalStateResponse.warnings.map((w, index) => (
                <div key={w}>
                  {index + 1}. {w}
                </div>
              ))}
            </div>
          </ToolTipCell>
        </div>
      )}
      <h3 className=" text-muted-foreground/80 text-sm mb-1 absolute  -top-6 ">{conditionalBlockState.label}</h3>
      <Handle id="default" type="target" position={Position.Top} />
      <Handle id="goto-target" type="target" position={Position.Right} />
      <div
        ref={refs.setReference}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className=" w-[350px] flex items-center justify-center "
      >
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={cn('  px-1 ', visible ? 'opacity-100' : 'opacity-0')}
        >
          <Button
            disabled={isPending}
            onClick={handleDeleteState}
            className=" text-destructive   bg-white "
            variant="outline"
            size="icon"
          >
            {isPending ? <Loader2Icon className="w-3 h-3 animate-spin" /> : <Trash2Icon className="w-3 h-3" />}
          </Button>
        </div>
        <Card className=" rounded-md w-full   ">
          <CardContent className=" py-4  text-sm  w-full ">
            <div className=" flex flex-col gap-2 ">
              {conditionActionTrees.map((tree, index) => (
                <ConditionActionBlock
                  conditionActionTrees={
                    (conditionalBlockState.description as IConditionalStateProperties).conditionActionTrees || []
                  }
                  index={index}
                  stateId={conditionalBlockState.id}
                  workflowId={conditionalBlockState.workflowId}
                  tree={tree}
                  key={props.id + 'state' + index}
                />
              ))}
              <div className=" text-left mt-4 ">
                <AddElseIfButton
                  workflowId={conditionalBlockState.workflowId}
                  stateId={conditionalBlockState.id}
                  stateProperties={conditionalBlockState.description as IConditionalStateProperties}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <Handle id="default" type="source" position={Position.Bottom} />
    </>
  );
};

export default ConditionalBlock;
