import {
  useGetCustomerTagNameSuggestions,
  useGetTagValueSuggestionsForGivenKeyMutation,
} from '@/hooks/api-hooks/useTagsQuery';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import debounce from 'lodash.debounce';
import { useCallback, useMemo, useState } from 'react';
import { GroupBase, MultiValueGenericProps, OptionsOrGroups, components } from 'react-select';
import AsyncSelect from 'react-select/async';

import type { SelectComponents } from 'react-select/dist/declarations/src/components';

interface Option {
  readonly label: string;
  readonly value: string;
  readonly isKey?: boolean;
  readonly objectValue: {
    tagName: string;
    tagValue?: string;
  };
}

const createOption = (tagName: string, tagValue: string): Option => ({
  label: `${tagName}:${tagValue}`,
  value: `${tagName}:${tagValue}`,
  objectValue: {
    tagName,
    tagValue,
  },
});

const mapKeyAndValueToOptions = (tags: { tagName: string; tagValue: string }[]): Option[] => {
  return tags.map((tag) => createOption(tag.tagName, tag.tagValue));
};

const MultiValueContainer = (props: MultiValueGenericProps<Option>) => {
  return <components.MultiValueContainer {...props} />;
};

const selectComponent: Partial<SelectComponents<Option, true, GroupBase<Option>>> = {
  DropdownIndicator: null,
  MultiValueContainer,
};

const TagFilter = ({
  onChange,
  tags,
}: {
  onChange: (_: { tagName: string; tagValue: string }[]) => void;
  tags: { tagName: string; tagValue: string }[];
}) => {
  const [inputValue, setInputValue] = useState('');

  const { mutateAsync: fetchTagNames } = useGetCustomerTagNameSuggestions({});
  const { mutateAsync: fetchTagValues } = useGetTagValueSuggestionsForGivenKeyMutation({});

  const loadTagNames = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<Option, GroupBase<Option>>) => void) => {
      const interpretedInput = inputValue.split(':').map((item) => item.trim());

      if (interpretedInput.length === 2) {
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const [tagKey, _] = interpretedInput;
        fetchTagValues({
          query: tagKey,
        }).then((result) => {
          const options = result.data
            .map((item) => ({
              value: item.tagValue || '',
              label: item.tagValue || '',
              isKey: false,
              objectValue: {
                tagName: tagKey || '',
                tagValue: item.tagValue || '',
              },
            }))
            .filter((item) => Boolean(item.value));

          callback(options);
        });
        return;
      }

      fetchTagNames({
        query: inputValue.trim(),
      }).then((result) => {
        const options = result.data
          .map((item) => ({
            value: item.tagKey || '',
            label: item.tagKey || '',
            isKey: true,
            objectValue: {
              tagName: item.tagKey || '',
              tagValue: undefined,
            },
          }))
          .filter((item) => Boolean(item.value));

        callback(options);
      });
      return;
    },
    [fetchTagNames, fetchTagValues],
  );

  const debouncedLoadTagNames = useMemo(() => {
    return debounce(loadTagNames, 500);
  }, [loadTagNames]);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, Option>();
  }, []);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const handleChange = (newValue: readonly Option[]) => {
    const findNewKey = newValue.find((item) => item.isKey && item.objectValue.tagValue === undefined);

    if (findNewKey) {
      setInputValue(`${findNewKey?.label}: `);
      return;
    }

    const newValues = newValue
      .filter((item) => !!item.objectValue.tagValue)
      .map((item) => ({ ...item.objectValue, tagValue: item.objectValue.tagValue || '' }));

    setInputValue('');
    onChange(newValues);
  };

  return (
    <AsyncSelect
      className=" min-w-[150px] text-sm shadow-sm w-full"
      components={selectComponent}
      inputValue={inputValue}
      isMulti={true}
      onChange={handleChange}
      onInputChange={(newValue, action) => {
        if (action.action === 'input-change') {
          setInputValue(newValue);
        }
      }}
      placeholder="Enter tag:value"
      value={mapKeyAndValueToOptions(tags)}
      styles={selectStyles}
      classNames={selectClasses}
      loadOptions={debouncedLoadTagNames}
    />
  );
};

export default TagFilter;
