const CriteriaItem = ({ value, label, operator }: { value: React.ReactNode; label: string; operator: string }) => {
  return (
    <div className=" flex items-center gap-4">
      <div className="min-w-[200px] capitalize">{label.toLowerCase()}</div>
      <div className="min-w-[100px] ">{operator}</div>
      <div className="flex items-center gap-2 flex-wrap">{value}</div>
    </div>
  );
};

export default CriteriaItem;
