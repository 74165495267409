import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Link } from 'react-router-dom';

const SwitchPostingFlow = ({ current }: { current: 'available-invoices' | 'available-credits' }) => {
  return (
    <span className="text-sm flex items-center gap-2 absolute top-0">
      <Tabs className=" my-4 " value={current}>
        <TabsList>
          <Link to="/posting/invoices?paymentStatus=PARTIALLY_PAID%2CUNPAID">
            <TabsTrigger value="available-invoices">Available to Post Invoices</TabsTrigger>
          </Link>
          <Link to="/posting/credits?postingStatus=PARTIALLY_POSTED%2CUNPOSTED">
            <TabsTrigger value="available-credits">Available to Post Credits</TabsTrigger>
          </Link>
        </TabsList>
      </Tabs>
    </span>
  );
};

export default SwitchPostingFlow;
