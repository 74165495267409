import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useUserContext } from '@/contexts/UserContext';
import { useCompanyInvites, useCompanyMembers, useRevokeInviteMutation } from '@/hooks/api-hooks/useCompanyQuery';
import { useQueryClient } from '@tanstack/react-query';

import moment from 'moment';
import { Loading } from '../shared/Loading';

import { CopyToClipboard } from '../ui/copy-clipboard';
import { useToast } from '../ui/use-toast';

export function MembersTable() {
  const contextData = useUserContext();
  const activeCompanyIndex = contextData.activeCompanyIndex;
  const companyId = contextData.companiesOfUser?.[activeCompanyIndex]?.id;
  const { data, isLoading, isError } = useCompanyMembers(companyId);
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return (
      <div className="text-gray-500 flex1 flex-row justify-center items-center">
        Something went wrong. Could not fetch members list right now!!!
      </div>
    );
  }
  const users = data?.data.members;
  users?.sort((userOne, userTwo) => {
    const nameA = (userOne.firstName + userOne.lastName).toLowerCase();
    const nameB = (userTwo.firstName + userTwo.lastName).toLowerCase();
    if (nameA > nameB) {
      return 1;
    } else if (nameA < nameB) {
      return -1;
    } else {
      return 0;
    }
  });
  return (
    <Table>
      <TableHeader>
        <TableRow className="sticky">
          <TableHead className="w-[100px]">User Name</TableHead>
          <TableHead>Email Address</TableHead>
          <TableHead className="text-right">&nbsp;</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {users?.map((user) => (
          <TableRow key={user.email}>
            <TableCell className="w-3/10">{`${user.firstName} ${user.lastName}`}</TableCell>
            <TableCell className="font-medium">{user.email}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export function InvitationsTable() {
  const contextData = useUserContext();
  const activeCompanyIndex = contextData.activeCompanyIndex;
  const companyId = contextData.companiesOfUser?.[activeCompanyIndex]?.id;
  const { data, isLoading, isError } = useCompanyInvites(companyId);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { mutate: revokeInvite } = useRevokeInviteMutation({
    customConfig: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === 'invites',
        });
        toast({
          title: 'Invite revoked successfully',
          duration: 5000,
        });
      },
      onError: (error: unknown) => {
        toast({
          title: 'Could not revoke invite',
          // @ts-expect-error no common error type
          description: error.response ? error.response.data.message : 'Network Failure',
          duration: 5000,
        });
      },
    },
  });
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return (
      <div className="text-gray-500 flex flex-row justify-center items-center h-1/2">
        Something went wrong. Could not fetch members list right now!!!
      </div>
    );
  }
  const invites = data?.data.invitations;
  return (
    <Table>
      <TableHeader>
        <TableRow className="sticky">
          <TableHead>Email Address</TableHead>
          <TableHead>Invited On</TableHead>
          <TableHead className="text-center">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {invites?.map((invite) => (
          <TableRow key={invite.email}>
            <TableCell className="w-3/10 font-medium">{invite.email}</TableCell>
            <TableCell>{moment(invite.createdAt).format('DD MMM YYYY')}</TableCell>
            <TableCell>
              <div className="flex gap-4 justify-center">
                <div
                  className="text-xs text-blue-700 text-center font-semibold cursor-pointer"
                  onClick={() => {
                    revokeInvite({ invitationId: invite.invitationId });
                  }}
                >
                  Revoke Invite
                </div>
                <div>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger className=" cursor-pointer">
                        <CopyToClipboard text={invite.inviteLink ?? ''} copyText="Invite link copied to clipboard" />
                      </TooltipTrigger>
                      <TooltipContent>Click to copy invite link</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
