import { useGetActivePlanByCompanyId } from '@/hooks/api-hooks/usePlanQuery';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useUserContext } from './UserContext';

const PlanContext = createContext<{
  planId: string;
  isLoading: boolean;
  isError: boolean;
  setPlanId: React.Dispatch<React.SetStateAction<string>>;
}>({
  planId: '',
  setPlanId: () => {},
  isLoading: false,
  isError: false,
});

const PlanProvider = ({ children }: { children: React.ReactNode }) => {
  const { companiesOfUser, activeCompanyIndex } = useUserContext();
  const [planId, setPlanId] = useState<string>('');

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const {
    data: planData,
    isLoading,
    isError,
  } = useGetActivePlanByCompanyId({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    financialYear: currentMonth < 3 ? currentYear : currentYear + 1,
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
      retry: false,
    },
  });

  useEffect(() => {
    if (planData?.data.id) {
      setPlanId(planData.data.id);
    }
  }, [planData?.data.id]);

  return <PlanContext.Provider value={{ planId, setPlanId, isLoading, isError }}>{children}</PlanContext.Provider>;
};

export default PlanProvider;

export const usePlanContext = () => {
  return useContext(PlanContext);
};
