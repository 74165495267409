import { usePlanContext } from '@/contexts/PlanContext';
import { useUserContext } from '@/contexts/UserContext';
import { useGetCashflowCategoryGraph, useGetCashflowSummaryGraph } from '@/hooks/api-hooks/useCashflowQuery';
import { useGetCategoriesByCompanyQuery } from '@/hooks/api-hooks/useCategoryQuery';
import { cn } from '@/lib/utils';
import { ICashflowGraphPoint } from '@/types/cashflow.types';
import { formatCurrency } from '@/utils/formatCurrency';
import { AxisBottom, AxisLeft } from '@visx/axis';
import * as allCurves from '@visx/curve';
import { localPoint } from '@visx/event';
import { GlyphCircle } from '@visx/glyph';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { Line, LinePath } from '@visx/shape';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { bisector, extent } from '@visx/vendor/d3-array';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import Empty from '../../ui/empty';
import { Skeleton } from '../../ui/skeleton';

type CurveType = keyof typeof allCurves;

const GRAPH_LEFT_OFFSET = 60;

const curveTypes = Object.keys(allCurves);

// data accessors

type IGraphPoint = Omit<Omit<ICashflowGraphPoint, 'closingBalance'>, 'openingBalance'> & {
  closingBalance?: number;
  value: number;
  openingBalance?: number;
};

export type GraphProps = {
  width: number;
  height: number;
  showControls?: boolean;
  lineData: IGraphPoint[];
  plannedLineData: IGraphPoint[];
  isCategory?: boolean;
  categoryName: string;
  isDebit?: boolean;
  startDate: Date;
  endDate: Date;
};

const LineGraph = ({
  showPoints,
  lineData,
  strokeType,
  xScale,
  yScale,
  getX,
  getY,
}: {
  showPoints: boolean;
  lineData: IGraphPoint[];
  strokeType: 'solid' | 'dashed';
  xScale: (_: Date) => number;
  yScale: (_: number) => number;
  getX: (_: IGraphPoint) => Date;
  getY: (_: IGraphPoint) => number;
}) => {
  return (
    <Group left={GRAPH_LEFT_OFFSET}>
      {showPoints &&
        lineData.map((d, j) => (
          <circle
            key={0 + j}
            r={3}
            cx={xScale(getX(d))}
            cy={yScale(getY(d))}
            stroke="rgba(33,33,33,0.5)"
            fill="#000000"
          />
        ))}
      <LinePath<IGraphPoint>
        curve={allCurves.curveLinear}
        data={lineData}
        x={(d) => xScale(getX(d)) ?? 0}
        y={(d) => yScale(getY(d)) ?? 0}
        stroke="#333"
        strokeDasharray={strokeType === 'dashed' ? '4,4' : 'solid'}
        strokeWidth={1}
        strokeOpacity={1}
        shapeRendering="geometricPrecision"
        markerMid="url(#marker-circle)"
      />
    </Group>
  );
};

const SummaryGraph = ({
  width,
  height,
  showControls = true,
  lineData,
  plannedLineData,
  isCategory,
  categoryName,
  isDebit,
  startDate,
  endDate,
}: GraphProps) => {
  const [curveType, setCurveType] = useState<CurveType>('curveNatural');
  const [showPoints, setShowPoints] = useState<boolean>(false);
  const margin = { top: 40, right: 40, bottom: 50, left: 0 };

  const multiplier = isDebit ? -1 : 1;

  const getX = useCallback((d: IGraphPoint) => {
    return dayjs(d.date).toDate();
  }, []);
  const getY = useCallback((d: IGraphPoint) => multiplier * d.value, [multiplier]);

  const xScale = useMemo(() => {
    return scaleTime<number>({
      domain: [startDate, endDate],
    });
  }, [startDate, endDate]);

  const yScale = useMemo(() => {
    const minMaxLineData = extent(lineData, getY);
    const minMaxPlannedData = extent(plannedLineData, getY);
    return scaleLinear<number>({
      domain: [
        Math.min(
          minMaxLineData[0] ? minMaxLineData[0] - Math.floor(Math.abs(minMaxLineData[0] / 10)) : 0,
          minMaxPlannedData[0] ? minMaxPlannedData[0] - Math.floor(Math.abs(minMaxPlannedData[0] / 10)) : 0,
        ),
        Math.max(
          minMaxLineData[1] ? minMaxLineData[1] + Math.ceil(minMaxLineData[1] / 10) : 0,
          minMaxPlannedData[1] ? minMaxPlannedData[1] + Math.ceil(minMaxPlannedData[1] / 10) : 0,
        ),
      ],
    });
  }, [lineData, plannedLineData, getY]);

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const { tooltipData, tooltipLeft = 0, showTooltip, hideTooltip, tooltipTop } = useTooltip<IGraphPoint[]>();

  const bisectDate = bisector((d: IGraphPoint) => new Date(d.date)).left;

  // update scale output ranges
  xScale.range([0, innerWidth]);
  yScale.range([innerHeight, 0]);

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement, MouseEvent>) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = xScale.invert(x - GRAPH_LEFT_OFFSET); // get Date from the scale

      const firstGraphIndex = bisectDate(lineData, x0, 0);

      const d0 = lineData[firstGraphIndex];

      if (d0) {
        showTooltip({
          tooltipData: [d0],
          tooltipLeft: xScale(getX(d0)),
          tooltipTop: yScale(getY(d0)),
        });
      }
    },
    [bisectDate, showTooltip, lineData, xScale, yScale, getX, getY],
  );

  const formatBasedOnSign = useCallback(
    (currency: { valueOf: () => number }) => {
      return formatCurrency(multiplier * currency.valueOf());
    },
    [multiplier],
  );

  return (
    <div className=" relative">
      {showControls && (
        <>
          <label>
            Curve type &nbsp;
            <select onChange={(e) => setCurveType(e.target.value as CurveType)} value={curveType}>
              {curveTypes.map((curve) => (
                <option key={curve} value={curve}>
                  {curve}
                </option>
              ))}
            </select>
          </label>
          &nbsp;
          <label>
            Show points&nbsp;
            <input type="checkbox" checked={showPoints} onChange={() => setShowPoints(!showPoints)} />
          </label>
          <br />
        </>
      )}
      <svg width={width} height={height} fill="transparent">
        <Group top={margin.top} left={margin.left} fill="transparent">
          <AxisLeft numTicks={10} scale={yScale} left={GRAPH_LEFT_OFFSET} tickFormat={formatBasedOnSign} />
          <AxisBottom left={GRAPH_LEFT_OFFSET} numTicks={7} scale={xScale} top={innerHeight} />
          {plannedLineData.length > 0 && width > 8 && (
            <LineGraph
              getX={getX}
              getY={getY}
              xScale={xScale}
              yScale={yScale}
              strokeType="dashed"
              showPoints={showPoints}
              lineData={plannedLineData}
            />
          )}
          {lineData.length > 0 && width > 8 && (
            <LineGraph
              getX={getX}
              getY={getY}
              xScale={xScale}
              yScale={yScale}
              strokeType="solid"
              showPoints={showPoints}
              lineData={lineData}
            />
          )}
          <Group left={GRAPH_LEFT_OFFSET}>
            {tooltipData && (
              <g>
                <Line
                  from={{ x: tooltipLeft, y: 0 }}
                  to={{ x: tooltipLeft, y: innerHeight }}
                  stroke={'#000000'}
                  pointerEvents="none"
                  strokeDasharray="4,9"
                />
              </g>
            )}
            {tooltipData &&
              tooltipData.map((d, i) => (
                <g key={`graph_point_${i}`}>
                  <GlyphCircle left={tooltipLeft} top={tooltipTop} size={40} fill={'#000000'} />
                </g>
              ))}
          </Group>
          {lineData.length > 0 && (
            <rect
              x={0 + GRAPH_LEFT_OFFSET}
              y={0}
              z={10}
              width={innerWidth}
              height={innerHeight}
              onTouchStart={handleTooltip}
              fill="transparent"
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />
          )}
        </Group>
      </svg>
      {!!tooltipData && !!tooltipData.length ? (
        <TooltipWithBounds
          offsetTop={10}
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft + 30 + GRAPH_LEFT_OFFSET / 2}
        >
          <div className="relative text-sm py-2 px-4">
            <h3 className=" text-center ">{dayjs(tooltipData[0].date).format('DD MMM YYYY')}</h3>
            {!!tooltipData[0].openingBalance && (
              <p className="w-full flex gap-4 justify-between">
                <span>Opening Bal.</span>
                <span
                  className={cn(
                    tooltipData[0].openingBalance === 0 && '',
                    tooltipData[0].openingBalance < 0 && 'text-red-500',
                    tooltipData[0].openingBalance > 0 && 'text-green-700',
                  )}
                >
                  {formatCurrency(Math.abs(tooltipData[0].openingBalance))}
                </span>
              </p>
            )}
            {!isCategory && (
              <>
                <p className="w-full flex gap-4 justify-between">
                  <span>Credit</span>
                  <span className="text-green-700">{formatCurrency(tooltipData[0].creditAmount)}</span>
                </p>
                <p className="w-full flex gap-4 justify-between">
                  <span>Debit</span>
                  <span className="text-red-500">{formatCurrency(tooltipData[0].debitAmount)}</span>
                </p>
              </>
            )}
            {isCategory && (
              <p className="w-full flex gap-4 justify-between">
                <span className="font-semibold">{categoryName}:</span>
                <span
                  className={cn(
                    tooltipData[0].value === 0 && '',
                    tooltipData[0].value < 0 && 'text-red-500',
                    tooltipData[0].value > 0 && 'text-green-700',
                  )}
                >
                  {formatCurrency(Math.abs(tooltipData[0].value))}
                </span>
              </p>
            )}

            {!!tooltipData[0].closingBalance && (
              <p className="w-full flex gap-4 justify-between">
                <span>Closing Bal.</span>
                <span
                  className={cn(
                    tooltipData[0].closingBalance === 0 && '',
                    tooltipData[0].closingBalance < 0 && 'text-red-500',
                    tooltipData[0].closingBalance > 0 && 'text-green-700',
                  )}
                >
                  {formatCurrency(Math.abs(tooltipData[0].closingBalance))}
                </span>
              </p>
            )}
          </div>
        </TooltipWithBounds>
      ) : null}
    </div>
  );
};

const SummaryGraphWrapper = ({
  dateFilter,
  selectedCategory,
  isCategory,
  isDaily,
}: {
  dateFilter: {
    startDate: Date;
    endDate: Date;
  };
  selectedCategory: string;
  isCategory: boolean;
  isDaily: boolean;
}) => {
  const { planId, isLoading: isPlanIdLoading } = usePlanContext();

  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { data: summaryData, isLoading: isSummaryLoading } = useGetCashflowSummaryGraph({
    planId: planId,
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
    customConfig: {
      enabled:
        !isCategory && !!dateFilter.startDate && !!dateFilter.endDate && !!companiesOfUser?.[activeCompanyIndex]?.id,
      refetchOnWindowFocus: false,
    },
  });

  const { data: summaryDataForCategory, isLoading: isLoadingForCategory } = useGetCashflowCategoryGraph({
    planId: planId,
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    categoryId: selectedCategory,
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
    customConfig: {
      enabled:
        isCategory && !!dateFilter.startDate && !!dateFilter.endDate && !!companiesOfUser?.[activeCompanyIndex]?.id,
      refetchOnWindowFocus: false,
    },
  });

  const { data: categoriesResponse } = useGetCategoriesByCompanyQuery({
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const categoriesMap = useMemo(() => {
    return (
      categoriesResponse?.data.reduce(
        (acc, category) => {
          acc[category.id] = category.value;
          return acc;
        },
        {} as Record<string, string>,
      ) || {}
    );
  }, [categoriesResponse]);

  const isLoading = useMemo(() => {
    return isSummaryLoading || isLoadingForCategory || isPlanIdLoading;
  }, [isSummaryLoading, isLoadingForCategory, isPlanIdLoading]);

  if (isLoading) {
    return (
      <div>
        <Skeleton className="w-full h-[400px] my-4 " />
      </div>
    );
  }

  if (!isCategory && summaryData) {
    return (
      <ParentSize className="h-full">
        {({ width, height }) => (
          <SummaryGraph
            categoryName="summary"
            plannedLineData={summaryData.data.plannedValues.map((item) => ({
              ...item,
              value: item.closingBalance,
            }))}
            lineData={summaryData.data.actualValues
              .map((item) => ({
                ...item,
                value: item.closingBalance,
              }))
              .filter((item) => dayjs(item.date).isBefore(dayjs()))}
            width={width}
            height={height}
            showControls={false}
            startDate={dateFilter.startDate}
            endDate={dateFilter.endDate}
          />
        )}
      </ParentSize>
    );
  }

  if (isCategory && summaryDataForCategory) {
    const plannedValues = isDaily
      ? summaryDataForCategory.data.dailyValues.plannedValues
      : summaryDataForCategory.data.cumulativeValues.plannedValues;
    const actualValues = isDaily
      ? summaryDataForCategory.data.dailyValues.actualValues
      : summaryDataForCategory.data.cumulativeValues.actualValues;

    const totalCredits = actualValues.reduce((acc, item) => acc + item.creditAmount, 0);
    const totalDebits = actualValues.reduce((acc, item) => acc + item.debitAmount, 0);

    return (
      <ParentSize className="h-full">
        {({ width, height }) => (
          <SummaryGraph
            isCategory
            isDebit={totalDebits > totalCredits}
            categoryName={categoriesMap[selectedCategory] || 'Value'}
            plannedLineData={plannedValues.map((item) => ({
              ...item,
              value: item.creditAmount - item.debitAmount,
            }))}
            lineData={actualValues
              .map((item) => ({
                ...item,
                value: item.creditAmount - item.debitAmount,
              }))
              .filter((item) => dayjs(item.date).isBefore(dayjs()))}
            width={width}
            height={height}
            showControls={false}
            startDate={dateFilter.startDate}
            endDate={dateFilter.endDate}
          />
        )}
      </ParentSize>
    );
  }

  return <Empty title="No data" />;
};

export default SummaryGraphWrapper;
