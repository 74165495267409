import { Link } from 'react-router-dom';
import PostedItemDescriptionSmall from '../PostedItemDescription';

const PostedCreditNoteDescription = ({
  creditNoteNumber,
  creditNoteId,
  createdAt,
  createdBy,
}: {
  creditNoteNumber: string;
  creditNoteId: string;
  createdAt: string;
  createdBy: string;
}) => {
  return (
    <PostedItemDescriptionSmall
      createdAt={createdAt}
      createdBy={createdBy}
      descriptionTitle="Credit Note Number"
      descriptionValue={
        <Link className=" text-blue-700 underline " to={`/credit-note/view/${creditNoteId}`}>
          {creditNoteNumber}
        </Link>
      }
      type="Credit Note"
    />
  );
};

export default PostedCreditNoteDescription;
