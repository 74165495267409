import {
  createBulkInvoicesToCreditNotesPosting,
  deleteCreditNoteToInvoicePosting,
  getCreditNoteById,
  getCreditNotesByFilters,
} from '@/api-functions/credit-notes.api';
import { IApiResponse } from '@/types/common.types';
import {
  ICreateInvoiceToCreditNotePostingRequestObject,
  ICreditNotesFilters,
  IGetCreditNoteDetailsByIdResponse,
  IGetCreditNotesByFiltersResponse,
} from '@/types/credit-notes.types';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { InvoiceQueryKeys } from './useInvoiceQuery';
import { PostingQueryKeys } from './usePostingQuery';

const CreditNotesQuery = {
  getCreditNotesByFiltersKey: ({
    limit,
    page,
    amount,
    invoiceNumber,
    creditNoteNumber,
    customers,
    dateRange,
    status,
    type,
  }: Partial<ICreditNotesFilters> & { page: number; limit: number }) => [
    'getCreditNotesByFilter',
    limit,
    page,

    dateRange?.from?.toISOString(),
    dateRange?.to?.toISOString(),
    ...(invoiceNumber || []),
    ...(creditNoteNumber || []),
    ...(customers || []),
    ...(status ? status : []),
    ...(type ? type : []),
    ...(amount ? amount : []).filter((v) => !!v),
  ],

  getCreditNoteById: (creditNoteId: string) => ['getCreditNoteById', creditNoteId],
};

interface IGetCreditNotesByFiltersWithPaginationParams extends Partial<ICreditNotesFilters> {
  page: number;
  limit: number;
  customConfig?: Omit<UseQueryOptions<IGetCreditNotesByFiltersResponse>, 'queryKey'>;
}

export const useGetFilteredCreditNotes = ({
  customConfig,
  page,
  limit,
  invoiceNumber,
  amount,
  dateRange,
  creditNoteNumber,
  customers,
  status,
  type,
}: IGetCreditNotesByFiltersWithPaginationParams) => {
  return useQuery<IGetCreditNotesByFiltersResponse>({
    queryKey: CreditNotesQuery.getCreditNotesByFiltersKey({
      limit,
      page,
      invoiceNumber,
      dateRange,
      creditNoteNumber,
      status,
      customers,
      type,
      amount,
    }),
    queryFn: () =>
      getCreditNotesByFilters({
        creditNoteNumber,
        customers,
        dateRange,
        invoiceNumber,
        amount,
        status,
        type,
        limit,
        page,
      }),
    ...customConfig,
  });
};

export const useGetCreditNoteById = ({
  creditNoteId,
  customConfig,
}: {
  creditNoteId: string;
  customConfig?: Omit<UseQueryOptions<IGetCreditNoteDetailsByIdResponse>, 'queryKey'>;
}) => {
  return useQuery<IGetCreditNoteDetailsByIdResponse>({
    queryKey: CreditNotesQuery.getCreditNoteById(creditNoteId),
    queryFn: () =>
      getCreditNoteById({
        creditNoteId,
      }),

    ...customConfig,
  });
};

export const useCreateInvoicesToCreditNotePosting = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    unknown,
    AxiosError<IApiResponse<string>>,
    { postings: ICreateInvoiceToCreditNotePostingRequestObject[] }
  >;
}) => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    AxiosError<IApiResponse<string>>,
    { postings: ICreateInvoiceToCreditNotePostingRequestObject[] }
  >({
    mutationFn: ({ postings }: { postings: ICreateInvoiceToCreditNotePostingRequestObject[] }) =>
      createBulkInvoicesToCreditNotesPosting({
        createBulkInvoicesToCreditNotesPosting: postings,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      if (variables.postings[0].creditNoteId) {
        queryClient.invalidateQueries({
          queryKey: CreditNotesQuery.getCreditNoteById(variables.postings[0].creditNoteId),
        });
      }

      variables.postings.forEach((posting) => {
        queryClient.invalidateQueries({
          queryKey: PostingQueryKeys.getPostingsForInvoice({
            invoiceId: posting.invoiceId || '',
          }),
        });

        queryClient.invalidateQueries({
          queryKey: InvoiceQueryKeys.getInvoiceById(posting.invoiceId || ''),
        });
      });

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getCreditNoteSummaryByCustomerId'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getCreditNotesByFilter'),
      });

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('postedInvoices'),
      });

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoiceByFilters'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoicesByFilterForPosting'),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteCreditNoteToInvoicePosting = ({
  customConfig,
  creditNoteId,
  invoiceId,
}: {
  creditNoteId: string;
  invoiceId: string;
  customConfig?: UseMutationOptions<unknown, AxiosError<IApiResponse<string>>, { postingId: string }>;
}) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError<IApiResponse<string>>, { postingId: string }>({
    mutationFn: ({ postingId }: { postingId: string }) =>
      deleteCreditNoteToInvoicePosting({
        postingId,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getCreditNoteSummaryByCustomerId'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getCreditNotesByFilter'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoiceByFilters'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoicesByFilterForPosting'),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('postedInvoices'),
      });

      queryClient.invalidateQueries({
        queryKey: CreditNotesQuery.getCreditNoteById(creditNoteId),
      });
      queryClient.invalidateQueries({
        queryKey: InvoiceQueryKeys.getInvoiceById(invoiceId),
      });
      queryClient.invalidateQueries({
        queryKey: PostingQueryKeys.getPostingsForInvoice({ invoiceId }),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });
};
