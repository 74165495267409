import { dataAxiosInstance } from '@/lib/axios';
import { IBulkPromiseToPayUpdateRequest, IComments, IInvoice, IInvoicesFilter } from '@/types/invoices.types';
import dayjs from 'dayjs';

export const getInvoiceById = async ({ invoiceId }: { invoiceId: string }) => {
  const result = await dataAxiosInstance.get(`/invoice/${invoiceId}`);
  return result.data;
};

export const getInvoicesByFilters = async ({
  page,
  limit,
  amountType,
  invoiceNumber,
  invoiceStatus,
  maxAmount,
  minAmount,
  dateRange,
  customerIds,
  dateType,
  paymentStatus,
  fetchOnlyNonZeroAmount,
}: Partial<IInvoicesFilter> & { page: number; limit: number }) => {
  let url = `/invoice/search?page=${page}&pageSize=${limit}`;

  if (amountType) {
    url += `&amountType=${amountType}`;
  }

  if (minAmount !== undefined && maxAmount) {
    url += `&amountMin=${minAmount}&amountMax=${maxAmount}`;
  }

  if (paymentStatus?.length) {
    url += paymentStatus.map((status: string) => `&paymentStatus=${status}`).join('');
  }

  if (invoiceStatus?.length) {
    url += invoiceStatus.map((status: string) => `&invoiceStatus=${status}`).join('');
  }

  if (invoiceNumber?.trim()) {
    url += `&invoiceNumber=${invoiceNumber}`;
  }

  if (dateType && dateRange?.startDate && dateRange?.endDate) {
    url += `&dateType=${dateType}&startDate=${dayjs(dateRange.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(
      dateRange.endDate,
    ).format('YYYY-MM-DD')}`;
  }

  if (customerIds?.trim()) {
    url += `&customerIds=${customerIds}`;
  }

  if (fetchOnlyNonZeroAmount !== undefined) {
    url += `&onlyFetchNonZero=${fetchOnlyNonZeroAmount.toString()}`;
  }

  const result = await dataAxiosInstance.get(url);
  return result.data;
};

export const postUpdateInvoice = async ({
  fields,
  updateObject,
  invoiceId,
  comments,
}: {
  fields: (keyof IInvoice)[];
  updateObject: Partial<IInvoice>;
  invoiceId: string;
  comments?: IComments;
}) => {
  const result = await dataAxiosInstance.post('/invoice/update', {
    invoiceId,
    updates: {
      fields,
      values: updateObject,
      comments: comments,
    },
  });

  return result.data;
};

export const postBulkPromiseToPayUpdate = async (requestBody: IBulkPromiseToPayUpdateRequest) => {
  const result = await dataAxiosInstance.post('/invoice/bulkPromiseToPayUpdate', requestBody);
  return result.data;
};

export const uploadInvoice = async ({ file, isValidationOnly }: { file: File; isValidationOnly: boolean }) => {
  if (!file) {
    throw new Error('Invalid invoice file');
  }
  const formData = new FormData();
  formData.append('file', file); // Append the file to the FormData object
  isValidationOnly && formData.append('isValidationOnly', isValidationOnly.toString());
  const result = await dataAxiosInstance.post('/invoice/upload', formData); // Sending formData instead of file and isValidationOnly
  return result.data;
};

export const getInvoicesSummaryByFilters = async ({
  page,
  amountType,
  invoiceNumber,
  invoiceStatus,
  maxAmount,
  minAmount,
  dateRange,
  customerIds,
  dateType,
  paymentStatus,
  fetchOnlyNonZeroAmount,
}: Partial<IInvoicesFilter> & { page: number }) => {
  let url = `/invoice/summary?page=${page}`;

  if (amountType) {
    url += `&amountType=${amountType}`;
  }

  if (minAmount !== undefined && maxAmount) {
    url += `&amountMin=${minAmount}&amountMax=${maxAmount}`;
  }

  if (paymentStatus?.length) {
    url += paymentStatus.map((status: string) => `&paymentStatus=${status}`).join('');
  }

  if (invoiceStatus?.length) {
    url += invoiceStatus.map((status: string) => `&invoiceStatus=${status}`).join('');
  }

  if (invoiceNumber?.trim()) {
    url += `&invoiceNumber=${invoiceNumber}`;
  }

  if (dateType && dateRange?.startDate && dateRange?.endDate) {
    url += `&dateType=${dateType}&startDate=${dayjs(dateRange.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(
      dateRange.endDate,
    ).format('YYYY-MM-DD')}`;
  }

  if (customerIds?.trim()) {
    url += `&customerIds=${customerIds}`;
  }

  if (fetchOnlyNonZeroAmount !== undefined) {
    url += `&onlyFetchNonZero=${fetchOnlyNonZeroAmount.toString()}`;
  }

  const result = await dataAxiosInstance.get(url);
  return result.data;
};

export const getPromiseToPayReasonRequired = async () => {
  const result = await dataAxiosInstance.get('/invoice/promiseToPayReasonRequired');
  return result.data;
};

export const getPromiseToPayReasonList = async () => {
  const result = await dataAxiosInstance.get('/invoice/promiseToPayReasons');
  return result.data;
};
