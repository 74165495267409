import {
  deleteAttachment,
  getAttachmentFile,
  getAttachmentsWithFilters,
  uploadAttachmentApi,
} from '@/api-functions/attachments.api';
import { IAttachmentsFilter, IFetchAttachmentFileResponse, IGetAttachmentResponse } from '@/types/attachments.types';
import { IApiResponse } from '@/types/common.types';
import { ICustomerUploadResponse } from '@/types/customer.types';
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
interface AttachmentsByCustomerRequestParams extends Partial<IAttachmentsFilter> {
  referenceObjectId: string;
  referenceObjectType: string;
}

const AttachmentsQuery = {
  getAttachmentsWithFilters: ({
    filename,
    uploadedBy,
    uploadedOn,
    filetype,
    referenceObjectId,
    referenceObjectType,
  }: Partial<IAttachmentsFilter> & { referenceObjectId: string; referenceObjectType: string }) => [
    'getAttachmentsWithFilters',
    filename,
    uploadedBy,
    uploadedOn,
    filetype,
    referenceObjectId,
    referenceObjectType,
  ],
};
export const useGetAttachmentsWithFilters = ({
  filename,
  uploadedBy,
  uploadedOn,
  filetype,
  referenceObjectId,
  referenceObjectType,
}: AttachmentsByCustomerRequestParams) => {
  return useQuery<IGetAttachmentResponse>({
    queryKey: AttachmentsQuery.getAttachmentsWithFilters({
      filename,
      uploadedBy,
      uploadedOn,
      filetype,
      referenceObjectId,
      referenceObjectType,
    }),
    queryFn: () =>
      getAttachmentsWithFilters({
        filename,
        uploadedBy,
        uploadedOn,
        filetype,
        referenceObjectId,
        referenceObjectType,
      }),
  });
};
export const useDeleteAttachment = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<IApiResponse<string>>,
    { attachmentId: string }
  >;
}) => {
  const queryClient = useQueryClient();
  const deleteAttachmentMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<IApiResponse<string>>,
    { attachmentId: string }
  >({
    mutationFn: ({ attachmentId }: { attachmentId: string }) => deleteAttachment({ attachmentId }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({
        queryKey: ['getAttachmentsWithFilters'],
      });
    },
  });

  return deleteAttachmentMutation;
};

export const useAttachmentUpload = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    ICustomerUploadResponse,
    AxiosError<ICustomerUploadResponse>,
    {
      file: File;
      referenceObjectId: string;
      referenceObjectType: string;
      purpose: string;
    }
  >;
}) => {
  return useMutation({
    mutationFn: ({
      file,
      referenceObjectId,
      referenceObjectType,
      purpose,
    }: {
      file: File;
      referenceObjectId: string;
      referenceObjectType: string;
      purpose: string;
    }) =>
      uploadAttachmentApi({
        file: file,
        referenceObjectId: referenceObjectId,
        referenceObjectType: referenceObjectType,
        purpose: purpose,
      }),
    onSuccess: async () => {},
    onError: async () => {},
    ...customConfig,
  });
};

export const useFetchAttachmentFile = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    AxiosResponse<IFetchAttachmentFileResponse>,
    AxiosError<ICustomerUploadResponse>,
    { attachmentId: string }
  >;
}) => {
  const fetchAttachmentFileMutation = useMutation<
    AxiosResponse<IFetchAttachmentFileResponse>,
    AxiosError<ICustomerUploadResponse>,
    { attachmentId: string }
  >({
    mutationFn: ({ attachmentId }: { attachmentId: string }) => getAttachmentFile(attachmentId),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
    },
  });
  return fetchAttachmentFileMutation;
};
