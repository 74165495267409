'use client';

import ClubbedSelectWithDatePicker from '@/components/shared/ClubbedSelectDatePicker';
import ClubbedSelectWithRangeInput from '@/components/shared/ClubbedSelectWithRange';
import FilterList from '@/components/shared/filters/FilterList';
import FilterRow from '@/components/shared/filters/FilterRow';
import SelectInvoiceComponent from '@/components/shared/SelectInvoice';
import { PaymentStatusDropDown } from '@/components/shared/StatusDropDowns';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useGetCustomerSuggestionsMutation } from '@/hooks/api-hooks/useCustomerQuery';
import { IOptions } from '@/types/common.types';
import { IAmountType } from '@/types/customer.types';
import { IInvoicesFilterForPosting, IPaymentStatus, TInvoiceDateType } from '@/types/invoices.types';
import { ICredit } from '@/types/posting.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import dayjs from 'dayjs';
import { StarsIcon, XIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';
import { ActionMeta, MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';

interface IPaymentStatusOption {
  label: string;
  value: IPaymentStatus;
}

const InvoicesFilters = ({
  handleSubmit,
  filters,
  showTabs,
  handleListTypeChange,
  listType,
  totalCount = 0,
  postedCount = 0,
}: {
  handleSubmit: (_: Partial<IInvoicesFilterForPosting>) => void;
  filters: Partial<IInvoicesFilterForPosting>;
  showTabs?: boolean;
  handleListTypeChange?: (_: string) => void;
  listType?: 'posted' | 'all' | 'ledger';
  totalCount?: number;
  postedCount?: number;
  showInvoiceNumberSearch?: boolean;
}) => {
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<IPaymentStatusOption[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<IOptions[]>([]);
  const [invoiceNumber, setInvoiceNumber] = useState<string | undefined>('');
  const [dateRangeWithType, setDateRangeWithType] = useState<{
    type: TInvoiceDateType;
    value: DateRange;
  }>({
    type: 'DUE_DATE',
    value: {
      from: undefined,
      to: undefined,
    },
  });

  const [amountWithType, setAmountWithType] = useState<{
    type: IAmountType | undefined;
    value: [number | undefined, number | undefined];
  }>({
    type: 'OUTSTANDING',
    value: [undefined, undefined],
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const paymentStatusValueToLabelMap = useMemo<Record<IPaymentStatus, string>>(
    () => ({
      PAID: 'Paid',
      PARTIALLY_PAID: 'Partially Paid',
      UNPAID: 'Unpaid',
    }),
    [],
  );

  const amountTypeOptions = useMemo<
    {
      label: string;
      value: IAmountType;
    }[]
  >(
    () => [
      {
        label: 'Due/Overdue',
        value: 'OUTSTANDING',
      },
      {
        label: 'Total',
        value: 'TOTAL',
      },
    ],
    [],
  );

  const handleAmountSelect = (amount: [number | undefined, number | undefined], type: IAmountType | undefined) => {
    setAmountWithType({ type, value: amount });
  };

  const { mutateAsync: fetchCustomers } = useGetCustomerSuggestionsMutation({});

  useEffect(() => {
    if (filters.dateRange && filters.dateType && filters.dateRange.startDate && filters.dateRange.endDate) {
      setDateRangeWithType({
        type: filters.dateType,
        value: {
          from: filters.dateRange.startDate,
          to: filters.dateRange.endDate,
        },
      });
    }
    if (filters.paymentStatus && filters.paymentStatus.length) {
      const statusFilters = filters.paymentStatus.map((status) => ({
        label: paymentStatusValueToLabelMap[status],
        value: status,
      }));
      setSelectedPaymentStatus(statusFilters);
    }

    if (filters.customers?.length) {
      setSelectedCustomers(filters.customers);
    }

    if (filters.invoiceNumber) {
      setInvoiceNumber(filters.invoiceNumber);
    }

    if (
      filters.amountType &&
      filters.minAmount !== null &&
      filters.minAmount !== undefined &&
      filters.maxAmount !== null &&
      filters.maxAmount !== undefined
    ) {
      setAmountWithType({
        type: filters.amountType,
        value: [filters.minAmount, filters.maxAmount],
      });
    }
  }, [filters, paymentStatusValueToLabelMap]);

  const handlePaymentStatusSelect = (option: IPaymentStatusOption) => () => {
    if (selectedPaymentStatus.find((item) => item.value === option.value)) {
      setSelectedPaymentStatus(selectedPaymentStatus.filter((item) => item.value !== option.value));
    } else {
      setSelectedPaymentStatus([
        ...selectedPaymentStatus,
        {
          label: option.label,
          value: option.value,
        },
      ]);
    }
  };

  const handleDateRangeChange = (value: DateRange | undefined, type: TInvoiceDateType) => {
    if (value) {
      setDateRangeWithType({
        type,
        value: {
          from: value.from,
          to: value.to,
        },
      });
    }
    setDateRangeWithType({
      type,
      value: {
        from: value?.from,
        to: value?.to,
      },
    });
  };

  const dateOptions = useMemo<
    {
      value: TInvoiceDateType;
      label: string;
    }[]
  >(() => {
    return [
      {
        value: 'INVOICE_DATE',
        label: 'Invoice date',
      },
      {
        value: 'DUE_DATE',
        label: 'Due date',
      },
      {
        value: 'PROMISE_TO_PAY_DATE',
        label: 'Promise to pay date',
      },
    ];
  }, []);

  useEffect(() => {
    handleSubmit({
      amountType: (searchParams.get('amountType') as IAmountType | undefined) || 'OUTSTANDING',
      minAmount: Number(searchParams.get('minAmount')) || undefined,
      maxAmount: Number(searchParams.get('maxAmount')) || undefined,
      dateRange: {
        startDate: searchParams.get('dateRangeStart') ? dayjs(searchParams.get('dateRangeStart')).toDate() : undefined,
        endDate: searchParams.get('dateRangeEnd') ? dayjs(searchParams.get('dateRangeEnd')).toDate() : undefined,
      },
      customers:
        searchParams
          .get('customers')
          ?.split(',')
          .filter((item) => !!item)
          .map((item) => ({
            value: item.split(':')[0],
            label: item.split(':')[1],
          })) || [],
      dateType: (searchParams.get('dateRangeType') as TInvoiceDateType) || 'INVOICE_DATE',
      paymentStatus: (searchParams
        .get('paymentStatus')
        ?.split(',')
        .filter((item) => !!item)
        .map((item) => item.split(':')[0] as IPaymentStatus) ?? []) as IPaymentStatus[],
      invoiceNumber: searchParams.get('invoiceNumber') || '',
    });
  }, [searchParams, handleSubmit]);

  const handleSubmitFilter = () => {
    setSearchParams(
      {
        paymentStatus: selectedPaymentStatus.map((item) => `${item.value}:${item.label}`).join(','),
        customers: selectedCustomers.map((item) => `${item.value}:${item.label}`).join(','),
        invoiceNumber: invoiceNumber?.trim() || '',
        dateRangeType: dateRangeWithType.type,
        dateRangeStart: dateRangeWithType.value.from ? dayjs(dateRangeWithType.value.from).format('YYYY-MM-DD') : '',
        dateRangeEnd: dateRangeWithType.value.to ? dayjs(dateRangeWithType.value.to).format('YYYY-MM-DD') : '',
        amountType: amountWithType.type || '',
        minAmount: amountWithType.value[0]?.toString() || '',
        maxAmount: amountWithType.value[1]?.toString() || '',
      },
      {
        replace: true,
      },
    );

    handleSubmit({
      amountType: amountWithType.type,
      minAmount: amountWithType.value[0],
      maxAmount: amountWithType.value[1],
      dateRange: {
        startDate: dateRangeWithType.value.from,
        endDate: dateRangeWithType.value.to,
      },

      dateType: dateRangeWithType.type,
      paymentStatus: selectedPaymentStatus.map((item) => item.value),
      customers: selectedCustomers,
      invoiceNumber: invoiceNumber?.trim(),
    });
  };

  const handleReset = () => {
    setSelectedPaymentStatus([]);
    setAmountWithType({ type: 'OUTSTANDING', value: [undefined, undefined] });
    handleSubmit({
      invoiceStatus: [],
      amountType: undefined,
      minAmount: undefined,
      maxAmount: undefined,
      invoiceNumber: '',
      dateRange: {
        startDate: undefined,
        endDate: undefined,
      },
      paymentStatus: [],
      customers: [],
    });

    setSelectedCustomers([]);

    setDateRangeWithType({
      type: 'DUE_DATE',
      value: {
        from: undefined,
        to: undefined,
      },
    });
    setInvoiceNumber('');

    setSearchParams(
      {},
      {
        replace: true,
      },
    );
  };

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchCustomers({
        query: inputValue.trim(),
      });
      return result.data.suggestions.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    [fetchCustomers],
  );

  const handleCustomerSelectChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((account) => ({ value: account.value, label: account.label }));
    setSelectedCustomers(newValue);
  };

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, IOptions>();
  }, []);

  const amountTypeMap = useMemo<Record<IAmountType, string>>(() => {
    return {
      OUTSTANDING: 'Select due/overdue amount',
      TOTAL: 'Select total amount',
      ADJUSTED: 'Select adjusted amount',
      PAID: 'Select paid amount',
    };
  }, []);

  return (
    <div className="mb-4">
      <div className="flex items-center gap-4 pb-2 flex-wrap">
        {!!showTabs && (
          <Tabs onValueChange={handleListTypeChange} value={listType}>
            <TabsList>
              <TabsTrigger value="all">All({totalCount})</TabsTrigger>
              <TabsTrigger value="posted">Posted({postedCount})</TabsTrigger>
            </TabsList>
          </Tabs>
        )}
        {listType !== 'posted' && (
          <>
            <ClubbedSelectWithDatePicker
              defaultSelected={dateRangeWithType.type}
              value={dateRangeWithType.value}
              onChange={handleDateRangeChange}
              options={dateOptions}
            />
            <PaymentStatusDropDown
              className="max-w-[150px]"
              handleSelect={handlePaymentStatusSelect}
              selected={selectedPaymentStatus}
              listType={listType}
            />
            <ClubbedSelectWithRangeInput
              defaultSelected={amountWithType.type}
              onChange={handleAmountSelect}
              options={amountTypeOptions}
              value={amountWithType.value}
              placeholderMap={amountTypeMap}
              placeholder="Select amount type"
            />
            <SelectInvoiceComponent
              value={invoiceNumber || ''}
              onChange={(value) => setInvoiceNumber(value)}
              className=" min-w-[200px] text-sm "
              placeholder="Search by invoice number"
            />
            <AsyncSelect
              isMulti
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              onChange={handleCustomerSelectChange}
              value={selectedCustomers}
              loadOptions={loadSelectOptions}
              className=" min-w-[150px] text-sm shadow-sm"
              styles={selectStyles}
              placeholder="Search by customer"
              classNames={selectClasses}
            />

            <Button onClick={handleSubmitFilter}>Apply</Button>
            <Button onClick={handleReset} variant="outline">
              Reset
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const customBadge = (
  filter: IOptions,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
) => {
  return (
    <Badge
      key={filter.label}
      className="rounded-full min-w-fit  capitalize flex items-center gap-2 py-2 px-4 bg-muted hover:bg-muted text-primary shadow-md"
    >
      <span
        className="cursor-pointer flex items-center gap-1 min-w-fit truncate"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <StarsIcon className=" fill-signature w-4 h-4 text-signature " />{' '}
        <span className=" text-signature ">AI Customer Suggestion:</span> {filter.label}
      </span>
      <span className=" cursor-pointer ">
        <XIcon
          className="w-4 h-4 text-primary"
          onClick={() => {
            const getAllValues =
              searchParams
                .get('customers')
                ?.split(',')
                .filter(Boolean)
                .filter((item) => {
                  const customer = item.split(':');

                  if (customer.length < 2) {
                    return false;
                  }

                  return customer[1] !== filter.label;
                }) || [];

            searchParams.set('customers', getAllValues.join(','));
            setSearchParams(searchParams, {
              replace: true,
            });
          }}
        />
      </span>
    </Badge>
  );
};

const customBadgeForNonAiCustomers = (
  filter: IOptions,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
) => {
  return (
    <Badge
      key={filter.label}
      className="rounded-full min-w-fit  capitalize flex items-center gap-2 py-2 px-4 bg-muted hover:bg-muted text-primary shadow-md"
    >
      <span
        className="cursor-pointer flex items-center gap-1 min-w-fit truncate"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {filter.label}:{' '}
        {filter.value.split(',').length > 1
          ? `${filter.value.split(',')[0].split(':')[1]}...+${filter.value.split(',').length - 1}`
          : filter.value.split(',')[0].split(':')[1]}
      </span>
      <span className=" cursor-pointer ">
        <XIcon
          className="w-4 h-4 text-primary"
          onClick={() => {
            const currentValue = filter.value.split(',').map((item) => item.trim());

            const getAllValues =
              searchParams
                .get('customers')
                ?.split(',')
                .filter(Boolean)
                .filter((item) => !currentValue.includes(item)) || [];

            searchParams.set('customers', getAllValues.join(','));
            setSearchParams(searchParams, {
              replace: true,
            });
          }}
        />
      </span>
    </Badge>
  );
};

export const InvoicesFiltersDialog = ({
  handleSubmit,
  filters,
  listType,
  customers = [],
  customerSuggestions = [],
}: {
  handleSubmit: (_: Partial<IInvoicesFilterForPosting>) => void;
  filters: Partial<IInvoicesFilterForPosting>;
  listType?: 'posted' | 'all' | 'ledger';
  customers?: ICredit['customers'];
  customerSuggestions?: ICredit['customerSuggestions'];
}) => {
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<IPaymentStatusOption[]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [selectedCustomers, setSelectedCustomers] = useState<IOptions[]>([]);
  const [invoiceNumber, setInvoiceNumber] = useState<string | undefined>('');
  const [dateRangeWithType, setDateRangeWithType] = useState<{
    type: TInvoiceDateType;
    value: DateRange;
  }>({
    type: 'DUE_DATE',
    value: {
      from: undefined,
      to: undefined,
    },
  });

  const customerSuggestionsMap = useMemo(() => {
    return customerSuggestions.reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr.id]: curr.name,
        };
      },
      {} as Record<string, string>,
    );
  }, [customerSuggestions]);

  const [amountWithType, setAmountWithType] = useState<{
    type: IAmountType | undefined;
    value: [number | undefined, number | undefined];
  }>({
    type: 'OUTSTANDING',
    value: [undefined, undefined],
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const paymentStatusValueToLabelMap = useMemo<Record<IPaymentStatus, string>>(
    () => ({
      PAID: 'Paid',
      PARTIALLY_PAID: 'Partially Paid',
      UNPAID: 'Unpaid',
    }),
    [],
  );

  const amountTypeOptions = useMemo<
    {
      label: string;
      value: IAmountType;
    }[]
  >(
    () => [
      {
        label: 'Due/Overdue',
        value: 'OUTSTANDING',
      },
      {
        label: 'Total',
        value: 'TOTAL',
      },
    ],
    [],
  );

  const handleAmountSelect = (amount: [number | undefined, number | undefined], type: IAmountType | undefined) => {
    setAmountWithType({ type, value: amount });
  };

  const { mutateAsync: fetchCustomers } = useGetCustomerSuggestionsMutation({});

  const allSuggestedCustomers = useMemo(() => {
    return [...customers, ...customerSuggestions];
  }, [customers, customerSuggestions]);

  const suggestedCustomerString = allSuggestedCustomers.map((item) => `${item.id}:${item.name}`).join(',');

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.set('customers', suggestedCustomerString);
        return params;
      },
      {
        replace: true,
      },
    );
    // because setSearchParams doesn't behave like a state setter function and we won't have a problem of closures here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedCustomerString]);

  useEffect(() => {
    setDateRangeWithType({
      type: filters.dateType || 'DUE_DATE',
      value: {
        from: filters.dateRange?.startDate || undefined,
        to: filters.dateRange?.endDate || undefined,
      },
    });

    const statusFilters =
      filters.paymentStatus?.map((status) => ({
        label: paymentStatusValueToLabelMap[status],
        value: status,
      })) || [];
    setSelectedPaymentStatus(statusFilters);

    setSelectedCustomers(filters.customers || []);

    setInvoiceNumber(filters.invoiceNumber);

    setAmountWithType({
      type: filters.amountType || 'OUTSTANDING',
      value: [filters.minAmount, filters.maxAmount],
    });
  }, [filters, paymentStatusValueToLabelMap]);

  const handlePaymentStatusSelect = (option: IPaymentStatusOption) => () => {
    if (selectedPaymentStatus.find((item) => item.value === option.value)) {
      setSelectedPaymentStatus(selectedPaymentStatus.filter((item) => item.value !== option.value));
    } else {
      setSelectedPaymentStatus([
        ...selectedPaymentStatus,
        {
          label: option.label,
          value: option.value,
        },
      ]);
    }
  };

  const handleDateRangeChange = (value: DateRange | undefined, type: TInvoiceDateType) => {
    if (value) {
      setDateRangeWithType({
        type,
        value: {
          from: value.from,
          to: value.to,
        },
      });
    }
    setDateRangeWithType({
      type,
      value: {
        from: value?.from,
        to: value?.to,
      },
    });
  };

  const dateOptions = useMemo<
    {
      value: TInvoiceDateType;
      label: string;
    }[]
  >(() => {
    return [
      {
        value: 'INVOICE_DATE',
        label: 'Invoice date',
      },
      {
        value: 'DUE_DATE',
        label: 'Due date',
      },
      {
        value: 'PROMISE_TO_PAY_DATE',
        label: 'Promise to pay date',
      },
    ];
  }, []);

  useEffect(() => {
    handleSubmit({
      amountType: (searchParams.get('amountType') as IAmountType | undefined) || 'OUTSTANDING',
      minAmount: Number(searchParams.get('minAmount')) || undefined,
      maxAmount: Number(searchParams.get('maxAmount')) || undefined,
      dateRange: {
        startDate: searchParams.get('dateRangeStart') ? dayjs(searchParams.get('dateRangeStart')).toDate() : undefined,
        endDate: searchParams.get('dateRangeEnd') ? dayjs(searchParams.get('dateRangeEnd')).toDate() : undefined,
      },
      customers:
        searchParams
          .get('customers')
          ?.split(',')
          .filter((item) => !!item)
          .map((item) => ({
            value: item.split(':')[0],
            label: item.split(':')[1],
          })) || [],
      dateType: (searchParams.get('dateRangeType') as TInvoiceDateType) || 'INVOICE_DATE',
      paymentStatus: (searchParams
        .get('paymentStatus')
        ?.split(',')
        .filter((item) => !!item)
        .map((item) => item.split(':')[0] as IPaymentStatus) ?? []) as IPaymentStatus[],
      invoiceNumber: searchParams.get('invoiceNumber') || '',
    });
  }, [searchParams, handleSubmit]);

  const handleSubmitFilter = () => {
    setSearchParams(
      {
        paymentStatus: selectedPaymentStatus.map((item) => `${item.value}:${item.label}`).join(','),
        customers: selectedCustomers.map((item) => `${item.value}:${item.label}`).join(','),
        invoiceNumber: invoiceNumber?.trim() || '',
        dateRangeType: dateRangeWithType.type,
        dateRangeStart: dateRangeWithType.value.from ? dayjs(dateRangeWithType.value.from).format('YYYY-MM-DD') : '',
        dateRangeEnd: dateRangeWithType.value.to ? dayjs(dateRangeWithType.value.to).format('YYYY-MM-DD') : '',
        amountType: amountWithType.type || '',
        minAmount: amountWithType.value[0]?.toString() || '',
        maxAmount: amountWithType.value[1]?.toString() || '',
      },
      {
        replace: true,
      },
    );

    handleSubmit({
      amountType: amountWithType.type,
      minAmount: amountWithType.value[0],
      maxAmount: amountWithType.value[1],
      dateRange: {
        startDate: dateRangeWithType.value.from,
        endDate: dateRangeWithType.value.to,
      },

      dateType: dateRangeWithType.type,
      paymentStatus: selectedPaymentStatus.map((item) => item.value),
      customers: selectedCustomers,
      invoiceNumber: invoiceNumber?.trim(),
    });

    setIsFilterModalOpen(false);
  };

  const filtersAsSearchParams = useMemo(() => {
    return {
      paymentStatus: searchParams.get('paymentStatus') || '',
      customers: searchParams.get('customers') || '',
      invoiceNumber: searchParams.get('invoiceNumber') || '',
      dateRangeType: searchParams.get('dateRangeType') || '',
      dateRangeStart: searchParams.get('dateRangeStart') || '',
      dateRangeEnd: searchParams.get('dateRangeEnd') || '',
      amountType: searchParams.get('amountType') || '',
      minAmount: searchParams.get('minAmount') || '',
      maxAmount: searchParams.get('maxAmount') || '',
    };
  }, [searchParams]);

  const filterKeyToLabelMap = useMemo<Record<keyof typeof filtersAsSearchParams, string>>(() => {
    return {
      paymentStatus: 'Payment status',
      customers: 'Customer',
      invoiceNumber: 'Invoice number',
      dateRangeType: 'Date range type',
      dateRangeStart: 'Date range start',
      dateRangeEnd: 'Date range end',
      amountType: 'Amount type',
      minAmount: 'Min amount',
      maxAmount: 'Max amount',
    };
  }, []);

  const handleReset = () => {
    setSelectedPaymentStatus([]);
    setAmountWithType({ type: 'OUTSTANDING', value: [undefined, undefined] });
    handleSubmit({
      invoiceStatus: [],
      amountType: undefined,
      minAmount: undefined,
      maxAmount: undefined,
      invoiceNumber: '',
      dateRange: {
        startDate: undefined,
        endDate: undefined,
      },
      paymentStatus: [],
      customers: [],
    });

    setSelectedCustomers([]);

    setDateRangeWithType({
      type: 'DUE_DATE',
      value: {
        from: undefined,
        to: undefined,
      },
    });
    setInvoiceNumber('');

    setSearchParams(
      {},
      {
        replace: true,
      },
    );

    setIsFilterModalOpen(false);
  };

  const handleModalOpenChange = (isOpen: boolean) => {
    setIsFilterModalOpen(isOpen);
  };

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchCustomers({
        query: inputValue.trim(),
      });
      return result.data.suggestions.map((item) => {
        return {
          label: item.name,
          value: item.id,
          hide: false,
        };
      });
    },
    [fetchCustomers],
  );

  const handleCustomerSelectChange = (
    value: MultiValue<{
      value: string;
      label: string;
      hide?: boolean;
    }>,

    action: ActionMeta<{
      value: string;
      label: string;
      hide?: boolean;
    }>,
  ) => {
    const removedValueLabel = action.removedValue?.label;
    const newValue = value.map((item) => ({ ...item, hide: false })).filter((item) => item.label !== removedValueLabel);
    setSelectedCustomers(newValue);
  };

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<
      true,
      {
        value: string;
        label: string;
        hide: boolean;
        new?: boolean;
      }
    >();
  }, []);

  const amountTypeMap = useMemo<Record<IAmountType, string>>(() => {
    return {
      OUTSTANDING: 'Select due/overdue amount',
      TOTAL: 'Select total amount',
      ADJUSTED: 'Select adjusted amount',
      PAID: 'Select paid amount',
    };
  }, []);

  const getFilterValueLabel = useCallback(
    (key: string, value: string): string => {
      if (key === 'paymentStatus') {
        return value.split('_').join(' ').toLowerCase().split(':')[0].trim();
      }

      if (key === 'non-ai-customers') {
        const values = value.split(':');

        const customerId = values[0];

        if (customerSuggestionsMap[customerId]) {
          return '';
        }

        return value;
      }

      return value;
    },
    [customerSuggestionsMap],
  );

  const filterList = useMemo(() => {
    const filtersAsSearchParamsExceptCustomers = {
      ...filtersAsSearchParams,
      customers: '',
    };

    return Object.entries(filtersAsSearchParamsExceptCustomers)
      .map(([key, value]: [string, string]) => {
        return {
          label: key,
          value: value
            .split(',')
            .map((v) => getFilterValueLabel(key, v.trim()))
            .filter((v) => !!v)
            .join(', '),
        };
      })
      .filter((filter) => !!filter.value)
      .filter((item) => !['amountType', 'dateRangeType'].includes(item.label));
  }, [filtersAsSearchParams, getFilterValueLabel]);

  const customerAiSuggestions = useMemo(() => {
    return [
      ...new Set(
        filtersAsSearchParams.customers
          .split(',')
          .filter((item) => {
            if (!item) {
              return false;
            }

            const values = item.split(':');

            if (values.length < 2) {
              return false;
            }

            return customerSuggestionsMap[values[0]];
          })
          .map((item) => {
            const values = item.split(':');
            return values[1].trim();
          }),
      ),
    ]
      .map((item) => ({ label: item, value: item }))
      .filter((item) => !!item.value);
  }, [filtersAsSearchParams, customerSuggestionsMap]);

  const customerFilterList = useMemo(() => {
    return [
      {
        label: 'Customers',
        value: [
          ...new Set(
            filtersAsSearchParams.customers.split(',').map((v) => getFilterValueLabel('non-ai-customers', v.trim())),
          ),
        ]
          .filter((v) => !!v)
          .join(', '),
      },
    ].filter((filter) => !!filter.value);
  }, [filtersAsSearchParams, getFilterValueLabel]);

  const selectedCustomersWithOccurence = useMemo(() => {
    const alreadyShowedTheseCustomers = {} as Record<string, boolean>;

    return [...new Set(selectedCustomers.map((item) => `${item.value}-${item.label}`))].map((item) => {
      const [id, name] = item.split('-');

      const isCustomerDisplayed = !!alreadyShowedTheseCustomers[name];

      if (isCustomerDisplayed) {
        return {
          value: id,
          label: name,
          hide: true,
        };
      }

      alreadyShowedTheseCustomers[name] = true;
      return {
        value: id,
        label: name,
        hide: false,
      };
    });
  }, [selectedCustomers]);

  return (
    <>
      <div className=" flex gap-4 items-center w-full overflow-hidden ">
        <Button onClick={() => setIsFilterModalOpen(true)} variant="outline" className=" relative ">
          Filters
          {filterList.length > 0 && (
            <div className="w-4 h-4 bg-black text-white rounded-xl mr-[-22px] mb-[28px]">
              <div className="text-center mb-auto mt-auto text-xs">{filterList.length}</div>
            </div>
          )}
        </Button>
        {filterList.length > 0 && (
          <Button variant="outline" onClick={handleReset}>
            Reset
          </Button>
        )}
        <div className=" flex items-center gap-2 flex-1 overflow-x-scroll py-2  ">
          <FilterList
            filterKeyToLabelMap={filterKeyToLabelMap}
            setModalOpen={setIsFilterModalOpen}
            filterList={customerAiSuggestions}
            customBadge={customBadge}
          />
          <FilterList
            filterKeyToLabelMap={filterKeyToLabelMap}
            setModalOpen={setIsFilterModalOpen}
            filterList={customerFilterList}
            customBadge={customBadgeForNonAiCustomers}
          />
          <FilterList
            filterKeyToLabelMap={filterKeyToLabelMap}
            setModalOpen={setIsFilterModalOpen}
            filterList={filterList}
          />
        </div>
      </div>
      {isFilterModalOpen && (
        <Dialog open={isFilterModalOpen} onOpenChange={handleModalOpenChange}>
          <DialogContent>
            <div className="w-[620px] border-2 bg-white	">
              <div className="h-[52px] p-4 flex  justify-between border-b">
                <div>
                  <DialogHeader>
                    <DialogTitle>Filters</DialogTitle>
                  </DialogHeader>
                </div>
                <div>
                  <XIcon
                    className="w-[20px] h-[20px] cursor-pointer"
                    onClick={() => {
                      setIsFilterModalOpen(false);
                    }}
                  />
                </div>
              </div>
              <div className=" flex flex-col gap-4 py-4 ">
                <FilterRow
                  itemOneLabel="Date range"
                  itemOneFilter={
                    <ClubbedSelectWithDatePicker
                      defaultSelected={dateRangeWithType.type}
                      value={dateRangeWithType.value}
                      onChange={handleDateRangeChange}
                      options={dateOptions}
                    />
                  }
                  itemTwoLabel="Payment status"
                  itemTwoFilter={
                    <PaymentStatusDropDown
                      className="max-w-full min-w-full w-full"
                      containerClass="w-full"
                      handleSelect={handlePaymentStatusSelect}
                      selected={selectedPaymentStatus}
                      listType={listType}
                    />
                  }
                />

                <FilterRow
                  itemOneLabel="Invoice number"
                  itemOneFilter={
                    <SelectInvoiceComponent
                      value={invoiceNumber || ''}
                      onChange={(value) => setInvoiceNumber(value)}
                      placeholder="Search by invoice number"
                      className="text-sm"
                    />
                  }
                  itemTwoLabel="Customers"
                  itemTwoFilter={
                    <AsyncSelect
                      isMulti
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={handleCustomerSelectChange}
                      value={selectedCustomersWithOccurence}
                      loadOptions={loadSelectOptions}
                      className=" min-w-[150px] text-sm shadow-sm"
                      styles={{
                        ...selectStyles,
                        option(base, props) {
                          if (props.data.hide) {
                            return {
                              ...base,
                              display: 'none',
                            };
                          }
                          return base;
                        },
                        multiValue(base, props) {
                          if (props.data.hide) {
                            return {
                              ...base,
                              display: 'none',
                            };
                          }
                          return base;
                        },
                      }}
                      placeholder="Search by customer"
                      classNames={selectClasses}
                      formatOptionLabel={({ label, value }) =>
                        customerSuggestionsMap[value] ? (
                          <div className=" flex items-center gap-1 ">
                            <StarsIcon className=" min-w-4 w-4 h-4 fill-signature text-signature " />
                            {label}
                          </div>
                        ) : (
                          <div>{label}</div>
                        )
                      }
                    />
                  }
                />
                <FilterRow
                  itemOneLabel="Amount"
                  itemOneFilter={
                    <ClubbedSelectWithRangeInput
                      defaultSelected={amountWithType.type}
                      onChange={handleAmountSelect}
                      options={amountTypeOptions}
                      value={amountWithType.value}
                      placeholderMap={amountTypeMap}
                      placeholder="Select amount type"
                      className="w-full min-w-full max-w-full"
                    />
                  }
                />
              </div>
              <div className="h-[64px] p-4 border-t">
                <DialogFooter>
                  <Button onClick={handleReset} variant="outline">
                    Reset
                  </Button>
                  <Button onClick={handleSubmitFilter} className="flex items-center gap-2">
                    Apply
                  </Button>
                </DialogFooter>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default InvoicesFilters;
