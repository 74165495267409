import { gePostedPlaceholdersByCustomerId } from '@/api-functions/posting.api';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  getCreditNoteSummaryByCustomerId,
  getCustomerById,
  getCustomerSuggestions,
  getCustomersByFilters,
  getCustomersSummaryByFilters,
  getInvoicesSummaryByCustomerIdByDateRange,
  postUpdateCustomer,
  uploadCustomer,
} from './../../api-functions/customers.api';
import {
  ICreditNoteSummaryForCustomerResponse,
  ICustomerFilteredSummaryResponse,
  ICustomerFilters,
  ICustomerInvoicesSummaryResponse,
  ICustomerPaginatedResponse,
  ICustomerResponse,
  ICustomerSuggestionsResponse,
  ICustomerUploadResponse,
  IPostedPlaceholderSummaryResponse,
} from './../../types/customer.types';

export const CustomerQueryKeys = {
  getCustomerByFilters: ({
    page,
    limit,
    amountType,
    identifierType,
    identifierValue,
    minAmount,
    maxAmount,
    invoiceStatus,
    paymentStatus,
    dateType,
    dateRange,
    tags,
    ownerKeyType,
    ownerValue,
  }: ICustomerFilters & { page: number; limit: number }) => [
    'getCustomerByFilters',
    page,
    limit,
    amountType,
    identifierType,
    identifierValue,
    minAmount,
    maxAmount,
    dateType,
    dateRange?.startDate,
    dateRange?.endDate,
    ownerKeyType,
    ownerValue,
    ...(invoiceStatus ? invoiceStatus : []),
    ...(paymentStatus ? paymentStatus : []),
    ...(tags ? tags.map((tag) => `${tag.tagName}:${tag.tagValue}`) : []),
  ],
  getCustomerSummaryByFilters: ({
    page,
    amountType,
    identifierType,
    identifierValue,
    minAmount,
    maxAmount,
    invoiceStatus,
    paymentStatus,
    dateType,
    dateRange,
    tags,
    ownerKeyType,
    ownerValue,
  }: ICustomerFilters & { page: number }) => [
    'getCustomerByFilters',
    page,
    amountType,
    identifierType,
    identifierValue,
    minAmount,
    maxAmount,
    dateType,
    dateRange?.startDate,
    dateRange?.endDate,
    ownerKeyType,
    ownerValue,
    ...(invoiceStatus ? invoiceStatus : []),
    ...(paymentStatus ? paymentStatus : []),
    ...(tags ? tags.map((tag) => `${tag.tagName}:${tag.tagValue}`) : []),
  ],
  getPostedPlaceholderForCustomer: (id: string) => ['getPostedPlaceholderForCustomer', id],
  getCustomerDetailsById: (id: string) => ['getCustomerDetailsById', id],
  getCustomerOverDueAndDueInvoices: (id: string) => ['getCustomerOverDueAndDueInvoices', id],
  getInvoicesSummaryByCustomerId: (id: string) => ['getInvoicesSummaryByCustomerId', id],
  getCreditNoteSummaryByCustomerId: (id: string) => ['getCreditNoteSummaryByCustomerId', id],
};

interface IUseGetFilteredCustomersParams extends ICustomerFilters {
  page: number;
  limit: number;

  customConfig?: Omit<UseQueryOptions<ICustomerPaginatedResponse>, 'queryKey'>;
}

interface IUseGetFilteredCustomersSummaryParams extends ICustomerFilters {
  page: number;
  limit: number;

  customConfig?: Omit<UseQueryOptions<ICustomerFilteredSummaryResponse>, 'queryKey'>;
}

export const useGetFilteredCustomers = ({
  customConfig,
  page,
  limit,
  amountType,
  identifierType,
  identifierValue,
  minAmount,
  maxAmount,
  invoiceStatus,
  paymentStatus,
  dateRange,
  dateType,
  tags,
  ownerKeyType,
  ownerValue,
}: IUseGetFilteredCustomersParams) => {
  const getActualDetailsQuery = useQuery<ICustomerPaginatedResponse>({
    queryKey: CustomerQueryKeys.getCustomerByFilters({
      page,
      limit,
      amountType,
      identifierType,
      identifierValue,
      minAmount,
      maxAmount,
      invoiceStatus,
      paymentStatus,
      dateRange,
      dateType,
      tags,
      ownerKeyType,
      ownerValue,
    }),
    queryFn: () =>
      getCustomersByFilters({
        page,
        limit,
        amountType,
        identifierType,
        identifierValue,
        minAmount,
        maxAmount,
        invoiceStatus,
        paymentStatus,
        dateRange,
        dateType,
        tags,
        ownerKeyType,
        ownerValue,
      }),

    ...customConfig,
  });

  return getActualDetailsQuery;
};

export const useGetCustomerById = ({
  customConfig,
  customerId,
}: {
  customConfig?: Omit<UseQueryOptions<ICustomerResponse>, 'queryKey'>;
  customerId: string;
}) => {
  const getCustomerDetailsByIdQuery = useQuery<ICustomerResponse>({
    queryKey: CustomerQueryKeys.getCustomerDetailsById(customerId),
    // queryFn: () => getCustomerById(),
    queryFn: () => getCustomerById(customerId),
    ...customConfig,
  });

  return getCustomerDetailsByIdQuery;
};

export const useGetInvoicesSummaryByCustomerId = ({
  customerId,
  customConfig,
}: {
  customConfig?: Omit<UseQueryOptions<ICustomerInvoicesSummaryResponse>, 'queryKey'>;
  customerId: string;
}) => {
  const getInvoicesSummaryByCustomerIdByDateRangeQuery = useQuery<ICustomerInvoicesSummaryResponse>({
    queryKey: CustomerQueryKeys.getInvoicesSummaryByCustomerId(customerId),
    queryFn: () =>
      getInvoicesSummaryByCustomerIdByDateRange({
        customerId,
      }),
    ...customConfig,
  });

  return getInvoicesSummaryByCustomerIdByDateRangeQuery;
};

export const useGetCreditNoteSummaryByCustomerId = ({
  customerId,
  customConfig,
}: {
  customConfig?: Omit<UseQueryOptions<ICreditNoteSummaryForCustomerResponse>, 'queryKey'>;
  customerId: string;
}) => {
  return useQuery<ICreditNoteSummaryForCustomerResponse>({
    queryKey: CustomerQueryKeys.getCreditNoteSummaryByCustomerId(customerId),
    queryFn: () => getCreditNoteSummaryByCustomerId({ customerId }),
    ...customConfig,
  });
};

export const useGetCustomerSuggestionsMutation = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<ICustomerSuggestionsResponse, unknown, { query: string }>;
}) => {
  const getCustomerSuggestionsQuery = useMutation<ICustomerSuggestionsResponse, unknown, { query: string }>({
    mutationFn: ({ query }: { query: string }) => getCustomerSuggestions(query),
    ...customConfig,
  });

  return getCustomerSuggestionsQuery;
};

export const usePostUpdateCustomerMutation = ({
  customerId,
  customConfig,
}: {
  customerId: string;
  customConfig: UseMutationOptions<unknown, unknown, { fields: string[]; updateObject: object }>;
}) => {
  const queryClient = useQueryClient();
  const postUpdateCustomerMutation = useMutation<unknown, unknown, { fields: string[]; updateObject: object }>({
    mutationFn: ({ fields, updateObject }: { fields: string[]; updateObject: object }) =>
      postUpdateCustomer({
        fields,
        updateObject,
        customerId,
      }),
    ...customConfig,
    onSettled(data, error, variables, context) {
      customConfig?.onSettled?.(data, error, variables, context);
      queryClient.invalidateQueries({ queryKey: CustomerQueryKeys.getCustomerDetailsById(customerId) });
    },
  });

  return postUpdateCustomerMutation;
};
export const useCustomerUpload = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    ICustomerUploadResponse,
    AxiosError<ICustomerUploadResponse>,
    {
      file: File;
      isValidationOnly: boolean;
    }
  >;
}) => {
  return useMutation({
    mutationFn: ({ file, isValidationOnly }: { file: File; isValidationOnly: boolean }) =>
      uploadCustomer({ file: file, isValidationOnly: isValidationOnly }),
    onSuccess: async () => {},
    onError: async () => {},
    ...customConfig,
  });
};

export const useGetFilteredCustomersSummary = ({
  customConfig,
  page,
  amountType,
  identifierType,
  identifierValue,
  minAmount,
  maxAmount,
  invoiceStatus,
  paymentStatus,
  dateRange,
  dateType,
  tags,
  ownerKeyType,
  ownerValue,
}: IUseGetFilteredCustomersSummaryParams) => {
  const getActualDetailsQuery = useQuery<ICustomerFilteredSummaryResponse>({
    queryKey: CustomerQueryKeys.getCustomerSummaryByFilters({
      page,
      amountType,
      identifierType,
      identifierValue,
      minAmount,
      maxAmount,
      invoiceStatus,
      paymentStatus,
      dateRange,
      dateType,
      tags,
      ownerKeyType,
      ownerValue,
    }),
    queryFn: () =>
      getCustomersSummaryByFilters({
        page,
        amountType,
        identifierType,
        identifierValue,
        minAmount,
        maxAmount,
        invoiceStatus,
        paymentStatus,
        dateRange,
        dateType,
        tags,
        ownerKeyType,
        ownerValue,
      }),

    ...customConfig,
  });

  return getActualDetailsQuery;
};

export const useGetPostedPlaceholdersByCustomerId = ({
  customerId,
  customConfig,
}: {
  customerId: string;
  customConfig?: Omit<UseQueryOptions<IPostedPlaceholderSummaryResponse>, 'queryKey'>;
}) => {
  const getCreditTermsByCompanyIdQuery = useQuery<IPostedPlaceholderSummaryResponse>({
    queryKey: CustomerQueryKeys.getPostedPlaceholderForCustomer(customerId),
    queryFn: () => gePostedPlaceholdersByCustomerId({ customerId }),
    ...customConfig,
  });

  return getCreditTermsByCompanyIdQuery;
};
