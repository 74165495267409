import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { IMinimumGuaranteeTermItem } from '@/types/credit-term.types';
import { validateNumber } from '@/utils/validateNumber';
import { Trash2Icon } from 'lucide-react';
import { ChangeEvent, useEffect, useState } from 'react';

export const EditMinimumGuranteeTerm = ({
  details,
  onChange,
  index,
  remove,
  length,
}: {
  details: IMinimumGuaranteeTermItem;
  onChange: (_: IMinimumGuaranteeTermItem, __: number) => void;
  index: number;
  remove: (_: number) => void;
  length?: number;
}) => {
  const [guranteeTerm, setGuranteeTerm] = useState(details);
  const [amountErrors, setAmountErrors] = useState('');
  const [frequencyNumberErrors, setFrequencyNumberErrors] = useState('');
  const [guranteeAmount, setGuaranteedAmount] = useState('');
  const [frequencyNumber, setFrequencyNumber] = useState('');

  useEffect(() => {
    setGuranteeTerm(details);
    setGuaranteedAmount(String(details.minGuaranteedAmount || 0));
  }, [details]);

  useEffect(() => {
    setGuaranteedAmount(String(details.minGuaranteedAmount || 0));
  }, [details.minGuaranteedAmount]);

  useEffect(() => {
    setFrequencyNumber(String(details.number || 0));
  }, [details.number]);

  const handleChange = (value: string | number) => {
    setGuaranteedAmount(value.toString());
    let details = { ...guranteeTerm };
    const validatedResult = validateNumber(String(value), 'Percentage');
    setAmountErrors(validatedResult.message || '');
    if (!validatedResult.isValid) {
      setAmountErrors('Invalid');
      return;
    }
    if (Number(value) < 0) {
      setAmountErrors('Invalid');
      return;
    }

    details = { ...details, minGuaranteedAmount: Number(value) } as IMinimumGuaranteeTermItem;
    setGuranteeTerm(details);
    onChange(details, index);
  };

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFrequencyNumber(e.target.value);
    const validatedResult = validateNumber(e.target.value, details.frequency);
    setFrequencyNumberErrors(validatedResult.message || '');
    if (!validatedResult.isValid) {
      return;
    }

    if (Number(e.target.value) <= 0) {
      setFrequencyNumberErrors('Month is Invalid');
      return;
    }

    const guranteeTermDetails = { ...guranteeTerm, number: Number(e.target.value) } as IMinimumGuaranteeTermItem;
    setGuranteeTerm(guranteeTermDetails);
    onChange(guranteeTermDetails, index);
  };

  return (
    <div className=" px-4 pt-4 pb-0.5 border border-secondary justify-between rounded-lg flex items-center gap-4 ">
      <div className=" flex items-start gap-2 ">
        <span className=" relative top-1 ">Minimum billing of</span>
        <div>
          <span className=" flex gap-2 items-center ">
            &#8377;
            <Input className=" w-16 h-7 " onChange={(e) => handleChange(e.target.value)} value={guranteeAmount} />
          </span>
          <p className=" text-red-500 text-xs h-4">{amountErrors}</p>
        </div>
        <span className=" relative top-1 ">for {!!length && index !== 0 && 'next'}</span>
        <div>
          <span className=" flex items-center gap-2 ">
            <Input className=" w-16 h-7 " onChange={handleNumberChange} value={frequencyNumber} />
            month
          </span>
          <p className=" text-red-500 text-xs h-4 capitalize">{frequencyNumberErrors.toLowerCase()}</p>
        </div>
      </div>
      <Button onClick={() => remove(index)} size="icon" variant="outline">
        <Trash2Icon className="h-4 w-4 " />
      </Button>
    </div>
  );
};
