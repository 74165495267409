import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useUserContext } from '@/contexts/UserContext';
import {
  useGetStateById,
  usePostCreateEmailAction,
  usePostUpdateTriggerState,
} from '@/hooks/api-hooks/useWorkflowQuery';
import { cn } from '@/lib/utils';
import { IState, ITriggerStateProperties } from '@/types/workflow.type';
import { autoUpdate, useFloating } from '@floating-ui/react';

import ToolTipCell from '@/components/Table/ToolTipCell';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import { AlertTriangleIcon, MailIcon, PlusIcon } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import DisplayCondition from './DisplayCondition';
import { ActionSlot } from './action-blocks/action-blocks';

const TriggerBlock = (props: NodeProps<IState<'TRIGGER'>>) => {
  const { data: triggerStateResponse, isLoading } = useGetStateById({
    stateId: props.id,
    customConfig: {
      enabled: !!props.id,
    },
  });

  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { toast } = useToast();

  const { mutateAsync: createEmailAction, isPending: isCreateEmailActionPending } = usePostCreateEmailAction({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Unable to create email action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateState, isPending: isUpdatePending } = usePostUpdateTriggerState({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Unable to create email action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const newActionCreatePending = useMemo(() => {
    return isCreateEmailActionPending || isUpdatePending;
  }, [isCreateEmailActionPending, isUpdatePending]);

  const timeout = useRef<number>();
  const [visible, setVisible] = useState(false);
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
  });

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(true);
    }, 25);
  }, []);
  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(false);
    }, 25);
  }, []);

  const handleAddEmail = () => {
    if (!triggerState?.workflowId || !companiesOfUser[activeCompanyIndex]?.id) return;

    createEmailAction({
      email: {
        companyId: companiesOfUser[activeCompanyIndex].id,
        workflowId: triggerState.workflowId,
      },
      workflowId: triggerState.workflowId,
    }).then((returnedData) => {
      updateState({
        stateId: triggerState.id,
        fields: ['conditionActionTree'],
        values: {
          conditionActionTree: {
            ...(triggerState.description as ITriggerStateProperties).conditionActionTree,
            actions: [
              ...(triggerState.description as ITriggerStateProperties).conditionActionTree.actions,
              returnedData.data.id,
            ],
          },
        },
      });
    });
  };

  const triggerState = useMemo(() => {
    return triggerStateResponse?.data || null;
  }, [triggerStateResponse]);

  if (isLoading) {
    return (
      <>
        <div>
          <h3 className=" text-muted-foreground/80 text-sm mb-1 ">Trigger</h3>
          <Card className=" rounded-md  ">
            <CardContent className=" py-4 w-[350px] text-sm ">
              <div className=" mb-4 flex flex-col gap-2 ">
                {new Array(2).fill(0).map((_, i) => (
                  <div key={i} className=" flex items-center gap-2 border-l-4 px-2 border-muted-foreground/20 ">
                    <div className=" bg-muted-foreground/10 p-2 rounded-md ">
                      <Skeleton className=" h-8 w-8 " />
                    </div>
                    <div className=" w-ful flex flex-col gap-1 flex-1 ">
                      <div className=" w-full flex items-end gap-2 ">
                        <Skeleton className=" w-[80px] h-4 " />
                        <Skeleton className="flex-1 h-4" />
                      </div>
                      <div className=" w-full flex items-start gap-2">
                        <Skeleton className=" w-[80px] h-4 " />
                        <Skeleton className="flex-1 h-4" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
          <Handle id="default" type="source" position={Position.Bottom} />
        </div>
      </>
    );
  }

  if (!triggerState) {
    return null;
  }

  return (
    <>
      <Handle id="goto-target" type="target" position={Position.Right} />
      <div>
        {!!triggerStateResponse?.warnings?.length && triggerStateResponse.warnings.length > 0 && (
          <div className=" absolute -left-11 text-destructive ">
            <ToolTipCell value={<AlertTriangleIcon className=" w-4 h-4 " />}>
              <div className=" flex flex-col gap-1 ">
                {triggerStateResponse.warnings.map((w, index) => (
                  <div key={w}>
                    {index + 1}. {w}
                  </div>
                ))}
              </div>
            </ToolTipCell>
          </div>
        )}
        <h3 className=" text-muted-foreground/80 text-sm mb-1 ">{triggerStateResponse?.data.label}</h3>
        <Card className=" rounded-md  ">
          <CardContent className=" py-4 w-[350px] text-sm ">
            <div
              className=" mb-4 "
              ref={refs.setReference}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className=" py-2 ">
                <div className=" flex items-start gap-2">
                  <span className=" font-semibold ">If</span>
                  <DisplayCondition
                    catIndex={0}
                    nodeId={props.id}
                    nodeType="TRIGGER"
                    conditionId={
                      (triggerState.description as ITriggerStateProperties as ITriggerStateProperties)
                        .conditionActionTree.condition
                    }
                  />
                </div>
                <div className=" flex flex-col gap-2 py-1  ">
                  {(
                    triggerState.description as ITriggerStateProperties as ITriggerStateProperties
                  ).conditionActionTree.actions.map((action, index) => (
                    <div key={index} className=" border-l-4 px-2 border-muted-foreground/20 ">
                      <ActionSlot
                        nodeType="TRIGGER"
                        catIndex={0}
                        actionId={action}
                        stateId={triggerState.id}
                        workflowId={triggerState.workflowId}
                      />
                    </div>
                  ))}
                  {newActionCreatePending && (
                    <div className=" flex items-center gap-2 border-l-4 px-2 border-muted-foreground/20 ">
                      <div className=" bg-muted-foreground/10 p-2 rounded-md ">
                        <MailIcon className=" h-8 w-8 " />
                      </div>
                      <div className=" w-ful flex flex-col gap-1 flex-1 ">
                        <div className=" w-full flex items-end gap-2 ">
                          <span className=" w-[80px] ">From:</span>
                          <Skeleton className="flex-1 h-4" />
                        </div>
                        <div className=" w-full flex items-start gap-2">
                          <span className=" w-[80px] ">Template:</span>
                          <Skeleton className="flex-1 h-4" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {(triggerState.description as ITriggerStateProperties as ITriggerStateProperties).conditionActionTree
                .actions.length === 0 && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button className=" border-dashed text-blue-700 w-full flex items-center gap-2 " variant="outline">
                      <PlusIcon className="w-3 h-3" />
                      Add Action
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className=" w-[250px] ">
                    <DropdownMenuGroup>
                      <DropdownMenuItem onClick={handleAddEmail}>Email</DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
              {(triggerState.description as ITriggerStateProperties).conditionActionTree.actions.length > 0 && (
                <div
                  ref={refs.setFloating}
                  style={floatingStyles}
                  className={cn('w-[90%] text-center', visible ? 'opacity-100' : 'opacity-0')}
                >
                  <div className=" border-b-4 border-blue-300 relative">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button className=" absolute -translate-x-1/2 -top-2 w-4 h-4 text-white " size="icon">
                          <PlusIcon className="w-3 h-3" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className=" w-[250px] ">
                        <DropdownMenuGroup>
                          <DropdownMenuItem onClick={handleAddEmail}>Email</DropdownMenuItem>
                        </DropdownMenuGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
        <Handle id="default" type="source" position={Position.Bottom} />
      </div>
    </>
  );
};

export default TriggerBlock;
