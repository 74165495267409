import { flexRender, Table } from '@tanstack/react-table';
import { memo } from 'react';
import { TableBody, TableCell, TableRow } from '../ui/table';

export const CustomTableBody = <T,>({ table, columnScale = 1 }: { table: Table<T>; columnScale?: number }) => {
  return (
    <TableBody className="py-4">
      {table.getRowModel().rows.map((row) => (
        <TableRow className="flex" key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <TableCell
              style={{
                width: `calc(var(--col-${cell.column.id}-size) * ${columnScale}px)`,
              }}
              className="truncate whitespace-nowrap overflow-ellipsis"
              key={cell.id}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export const MemoizedTableBody = memo(
  CustomTableBody,
  (prev, next) => prev.table.options.data === next.table.options.data,
) as typeof CustomTableBody;
