import ToolTipCell from '@/components/Table/ToolTipCell';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import GotoEdge from '@/components/workflow/edges/GotoEdge';
import WorkflowLayout from '@/components/workflow/Layout';
import Properties from '@/components/workflow/properties-panel/Properties';
import ActionBlock from '@/components/workflow/state-blocks/action-blocks/ActionBlock';
import ConditionalBlock from '@/components/workflow/state-blocks/ConditionalBlock';
import ExitBlock from '@/components/workflow/state-blocks/ExitBlock';
import LoadingBlock from '@/components/workflow/state-blocks/LoadingBlock';
import TriggerBlock from '@/components/workflow/state-blocks/TriggerBlock';
import WaitBlock from '@/components/workflow/state-blocks/WaitBlock';
import Tabs from '@/components/workflow/Tabs';
import { useGetWorkflowById, usePublishWorkflow } from '@/hooks/api-hooks/useWorkflowQuery';
import {
  IConditionalStateProperties,
  IOnlyActionProperties,
  IState,
  ITriggerStateProperties,
  IWaitStateProperties,
  StateTypes,
} from '@/types/workflow.type';
import { getLayoutedElements, getLayoutedElementsOnClick } from '@/utils/workflow/getLayoutedElements';
import { Loader2Icon, NetworkIcon } from 'lucide-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  EdgeChange,
  Node,
  NodeChange,
  NodeTypes,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import ButtonEdge from '../../components/workflow/edges/ButtonEdge';

const edgeTypes = {
  button: ButtonEdge,
  goto: GotoEdge,
};

const nodeTypes: NodeTypes = {
  TRIGGER: TriggerBlock,
  ACTION_ONLY: ActionBlock,
  WAIT: WaitBlock,
  EXIT: ExitBlock,
  CONDITIONAL: ConditionalBlock,
  LOADING: LoadingBlock,
};

const Playground = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState<IState<StateTypes>>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const workflowId = useParams().workflowId;

  const { data: workflowResponse, isFetching } = useGetWorkflowById({
    workflowId: workflowId || '',
    customConfig: {
      enabled: !!workflowId,
    },
  });

  const handleFormat = () => {
    const result = getLayoutedElementsOnClick(nodes, edges, {});

    setNodes(result.nodes);

    setEdges(result.edges);
  };

  const DEFAULT_BLOCK_HEIGHT = 30;

  useEffect(() => {
    const states: Node<IState<StateTypes>>[] =
      workflowResponse?.data.description.states.map((state, index) => {
        if (state.stateType === 'WAIT') {
          return {
            id: state.id,
            type: 'WAIT',

            data: {
              workflowId: state.workflowId,
              type: state.stateType,
              label: state.label,
              properties: {
                ...(state.description as IWaitStateProperties),
              },
              transitionAction: state.description?.transitionAction || 'new',
              id: state.id,
            },
            height: DEFAULT_BLOCK_HEIGHT,
            position: {
              x: 100,
              y: (index + 1) * 300,
            },
          };
        }

        if (state.stateType === 'CONDITIONAL') {
          return {
            id: state.id,
            type: 'CONDITIONAL',

            data: {
              workflowId: state.workflowId,
              type: state.stateType,
              label: state.label,
              properties: {
                ...(state.description as IConditionalStateProperties),
              },
              transitionAction: state.description?.transitionAction || 'new',
              id: state.id,
            },
            height:
              DEFAULT_BLOCK_HEIGHT +
              (state.description as IConditionalStateProperties).conditionActionTrees.reduce(
                (prev, tree) => prev + Math.max(tree.actions.length, 1) * 100,
                0,
              ),
            position: {
              x: 100,
              y: (index + 1) * 300,
            },
          };
        }

        if (state.stateType === 'ACTION_ONLY') {
          return {
            id: state.id,
            type: 'ACTION_ONLY',
            data: {
              workflowId: state.workflowId,
              type: state.stateType,
              label: state.label,
              properties: {
                ...(state.description as IOnlyActionProperties),
              },
              transitionAction: state.description?.transitionAction || 'new',
              id: state.id,
            },
            height: DEFAULT_BLOCK_HEIGHT,
            position: {
              x: 100,
              y: (index + 1) * 300,
            },
          };
        }

        if (state.stateType === 'TRIGGER') {
          return {
            id: state.id,
            type: state.stateType,
            data: {
              workflowId: state.workflowId,
              type: state.stateType,
              label: state.label,
              properties: {
                ...state.description,
              },
              transitionAction: state.description?.transitionAction || 'new',
              id: state.id,
            },
            height:
              DEFAULT_BLOCK_HEIGHT +
              Math.max((state.description as ITriggerStateProperties).conditionActionTree.actions.length - 1, 0) * 100,
            position: {
              x: 100,
              y: (index + 1) * 300,
            },
          };
        }

        return {
          id: state.id,
          type: state.stateType,
          data: {
            workflowId: state.workflowId,
            type: state.stateType,
            label: state.label,
            properties: {
              ...state.description,
            },
            transitionAction: state.description?.transitionAction || 'new',
            id: state.id,
          },
          height: DEFAULT_BLOCK_HEIGHT,
          position: {
            x: 100,
            y: (index + 1) * 300,
          },
        };
      }) || [];

    const transitions =
      workflowResponse?.data.description.transitions.map((transition) => {
        return {
          id: transition.id,
          source: transition.description.fromState,
          target: transition.description.toState,
          sourceHandle: 'default',
          style: { stroke: '#000' },
          type: 'button',
        };
      }) || [];

    const { nodes, edges } = getLayoutedElements(states, transitions, {});

    setNodes(nodes);

    setEdges(edges);
  }, [
    setEdges,
    setNodes,
    workflowResponse?.data.description.transitions,
    workflowResponse?.data.description.states,
    isFetching,
  ]);

  const handleCustomNodesChange = (nodes: NodeChange[]) => {
    const changedNodes = nodes.reduce((acc, change) => {
      if (change.type === 'remove') {
        return acc;
      }

      return [...acc, change];
    }, [] as NodeChange[]);

    onNodesChange(changedNodes);
  };

  const handleCustomEdgesChange = (edges: EdgeChange[]) => {
    const changedEdges = edges.reduce((acc, change) => {
      if (change.type === 'remove') {
        return acc;
      }

      return [...acc, change];
    }, [] as EdgeChange[]);

    onEdgesChange(changedEdges);
  };

  const { toast } = useToast();

  const { mutate: publish, isPending } = usePublishWorkflow({
    workflowId: workflowId as string,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Failed to publish workflow',
          variant: 'destructive',
        });
      },
      onSuccess() {
        toast({
          description: 'Workflow published successfully',
        });
      },
    },
  });

  if (!workflowId) {
    return null;
  }

  return (
    <>
      {workflowResponse?.data.status !== 'ACTIVE' && (
        <Button
          disabled={isPending}
          className=" absolute top-2 right-4 flex items-center gap-2 "
          onClick={() => publish()}
        >
          Publish
          {isPending && <Loader2Icon className=" animate-spin w-4 h-4" />}
        </Button>
      )}
      <div className=" absolute top-3 left-16 font-semibold ">{`${
        workflowResponse?.data.name ? workflowResponse?.data.name + ' [' + workflowResponse?.data.status + ']' : ''
      }`}</div>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={handleCustomNodesChange}
        onEdgesChange={handleCustomEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineStyle={{
          stroke: '#000',
        }}
      >
        <Panel position="top-right" className=" px-4 ">
          <ToolTipCell
            value={
              <Button variant="outline" size="icon" onClick={handleFormat}>
                <NetworkIcon className=" w-4 h-4 " />
              </Button>
            }
          >
            Format the workflow
          </ToolTipCell>
        </Panel>
      </ReactFlow>
    </>
  );
};

const PlaygroundLayout = () => {
  return (
    <ReactFlowProvider>
      <div className=" flex flex-1 overflow-hidden  ">
        <div className=" flex-1 p-3 px-0 ">
          <Playground />
        </div>
        <div className="  overflow-hidden ">
          <Properties />
        </div>
      </div>
    </ReactFlowProvider>
  );
};

const PlaygroundPage = () => {
  const { workflowId } = useParams();

  return (
    <WorkflowLayout>
      <Tabs isEdit={!!workflowId} workflowId={workflowId} />
      <PlaygroundLayout />
    </WorkflowLayout>
  );
};

export default PlaygroundPage;
