import { dataAxiosInstance } from '@/lib/axios';
import { IApiResponse } from '@/types/common.types';
import { IPostedPlaceholderSummaryResponse } from '@/types/customer.types';
import { IInvoicesFilter } from '@/types/invoices.types';
import dayjs from 'dayjs';
import {
  ICreateBulkPostingForCreditsRequest,
  ICreateBulkPostingForInvoiceRequest,
  IPlaceholder,
  IPostedToCreditObject,
  IPostedToInvoiceObject,
  IPostingHistory,
  PostingStatus,
} from './../types/posting.types';

export const getPlaceholders = async () => {
  const result = await dataAxiosInstance.get<IApiResponse<IPlaceholder[]>>('/placeholder/all');

  return result.data;
};
export const getCreditPlaceholders = async () => {
  const result = await dataAxiosInstance.get<IApiResponse<IPlaceholder[]>>('/placeholder/credits');

  return result.data;
};

export const getPlaceholderDetail = async ({ placeholderId }: { placeholderId: string }) => {
  const result = await dataAxiosInstance.get<IApiResponse<IPlaceholder>>(`/placeholder?id=${placeholderId}`);

  return result.data;
};

export const createBulkPostingForInvoice = async ({ request }: { request: ICreateBulkPostingForInvoiceRequest }) => {
  const result = await dataAxiosInstance.post('/posting/invoice/bulk', {
    ...request,
  });

  return result.data;
};

export const createBulkPostingForCredits = async ({ request }: { request: ICreateBulkPostingForCreditsRequest }) => {
  const result = await dataAxiosInstance.post('/posting/credit/bulk', {
    ...request,
  });

  return result.data;
};

export const getPostedCreditsForInvoice = async ({ invoiceId }: { invoiceId: string }) => {
  const result = await dataAxiosInstance.get<IApiResponse<IPostedToInvoiceObject[]>>(`/posting/invoice/${invoiceId}`);
  return result.data;
};

export const getPostedInvoiceForCredit = async ({ bat, placeholderId }: { bat: string; placeholderId?: string }) => {
  const params = placeholderId ? `?placeholderId=${placeholderId}` : '';
  const result = await dataAxiosInstance.get<IApiResponse<IPostedToCreditObject[]>>(`/posting/credit/${bat}${params}`);
  return result.data;
};

export const getPostedHistoryForInvoice = async ({ invoiceId }: { invoiceId: string }) => {
  const result = await dataAxiosInstance.get<IApiResponse<IPostingHistory[]>>(`/posting/history/invoice/${invoiceId}`);
  return result.data;
};

export const getPostedHistoryForCredit = async ({ bat }: { bat: string }) => {
  const result = await dataAxiosInstance.get<IApiResponse<IPostingHistory[]>>(`/posting/history/credit/${bat}`);
  return result.data;
};
export const getInvoicesForCredit = async (creditId: string) => {
  const result = await dataAxiosInstance.get(`/posting/credit/${creditId}`);

  return result.data;
};

export const postDeletePosting = async (postingId: string) => {
  const result = await dataAxiosInstance.post(`/posting/delete/${postingId}`);
  return result.data;
};

export const getInvoicesByFilters = async ({
  page,
  limit,
  amountType,
  invoiceNumber,
  invoiceStatus,
  maxAmount,
  minAmount,
  dateRange,
  customerIds,
  dateType,
  paymentStatus,
  fetchOnlyNonZeroAmount,
  notInCredit,
}: Partial<IInvoicesFilter> & { page: number; limit: number; notInCredit?: string[] }) => {
  let url = `/posting/invoices?page=${page}&pageSize=${limit}`;

  if (amountType) {
    url += `&amountType=${amountType}`;
  }

  if (minAmount !== undefined && maxAmount) {
    url += `&amountMin=${minAmount}&amountMax=${maxAmount}`;
  }

  if (paymentStatus?.length) {
    url += paymentStatus.map((status: string) => `&paymentStatus=${status}`).join('');
  }

  if (invoiceStatus?.length) {
    url += invoiceStatus.map((status: string) => `&invoiceStatus=${status}`).join('');
  }

  if (invoiceNumber?.trim()) {
    url += `&invoiceNumber=${invoiceNumber}`;
  }

  if (dateType && dateRange?.startDate && dateRange?.endDate) {
    url += `&dateType=${dateType}&startDate=${dayjs(dateRange.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(
      dateRange.endDate,
    ).format('YYYY-MM-DD')}`;
  }

  if (customerIds?.trim()) {
    url += `&customerIds=${customerIds}`;
  }

  if (fetchOnlyNonZeroAmount !== undefined) {
    url += `&onlyFetchNonZero=${fetchOnlyNonZeroAmount.toString()}`;
  }

  if (notInCredit?.length) {
    url += notInCredit.map((status: string) => `&notInCredit=${status}`).join('');
  }

  const result = await dataAxiosInstance.get(url);
  return result.data;
};

export const getCreditsSummary = async ({
  page = 1,
  limit = 10,
  bankAccounts,
  placeholderId,
  isPostedToInvoice,
  startDate,
  endDate,
  postingStatus,
  categories,
  narration,
  customerIds,
}: {
  page: number;
  limit: number;
  bankAccounts: string[];
  startDate?: Date;
  endDate?: Date;
  placeholderId?: string;
  isPostedToInvoice?: boolean;
  postingStatus?: PostingStatus[];
  categories: string[];
  narration?: string;
  customerIds?: string;
}) => {
  let url = `/posting/credits/summary?page=${page}&pageSize=${limit}`;

  if (bankAccounts.length > 0) {
    url += `&bankAccountIds=${bankAccounts.join('&bankAccountIds=')}`;
  }

  if (placeholderId) {
    url += `&placeholderId=${placeholderId}`;
  }

  if (isPostedToInvoice !== undefined) {
    url += `&isPostedToInvoice=${isPostedToInvoice}`;
  }

  if (startDate && endDate) {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

    url += `&periodStartDate=${formattedStartDate}&periodEndDate=${formattedEndDate}`;
  }

  if (postingStatus?.length) {
    url += postingStatus.map((status: string) => `&postingStatus=${status}`).join('');
  }

  if (categories.length) {
    url += `&categories=${categories.join('&categories=')}`;
  }
  if (customerIds !== undefined && customerIds !== '') {
    url += customerIds
      .split(',')
      .map((customerId: string) => `&customerId=${customerId}`)
      .join('');
  }

  if (narration?.trim()) {
    url += `&narrationLike=${narration.trim()}`;
  }

  const result = await dataAxiosInstance.get(url);

  return result.data;
};

export const getInvoicesSummary = async ({
  page,
  limit,
  amountType,
  invoiceNumber,
  invoiceStatus,
  maxAmount,
  minAmount,
  dateRange,
  customerIds,
  dateType,
  paymentStatus,
  fetchOnlyNonZeroAmount,
}: Partial<IInvoicesFilter> & { page: number; limit: number; notInCredit?: string[] }) => {
  let url = `/posting/invoices/summary?page=${page}&pageSize=${limit}`;

  if (amountType) {
    url += `&amountType=${amountType}`;
  }

  if (minAmount !== undefined && maxAmount) {
    url += `&amountMin=${minAmount}&amountMax=${maxAmount}`;
  }

  if (paymentStatus?.length) {
    url += paymentStatus.map((status: string) => `&paymentStatus=${status}`).join('');
  }

  if (invoiceStatus?.length) {
    url += invoiceStatus.map((status: string) => `&invoiceStatus=${status}`).join('');
  }

  if (invoiceNumber?.trim()) {
    url += `&invoiceNumber=${invoiceNumber}`;
  }

  if (dateType && dateRange?.startDate && dateRange?.endDate) {
    url += `&dateType=${dateType}&startDate=${dayjs(dateRange.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(
      dateRange.endDate,
    ).format('YYYY-MM-DD')}`;
  }

  if (customerIds?.trim()) {
    url += `&customerIds=${customerIds}`;
  }

  if (fetchOnlyNonZeroAmount !== undefined) {
    url += `&onlyFetchNonZero=${fetchOnlyNonZeroAmount.toString()}`;
  }

  const result = await dataAxiosInstance.get(url);
  return result.data;
};

export const getInvoicesExport = async ({
  amountType,
  invoiceNumber,
  invoiceStatus,
  maxAmount,
  minAmount,
  dateRange,
  customerIds,
  dateType,
  paymentStatus,
  fetchOnlyNonZeroAmount,
}: Partial<IInvoicesFilter>) => {
  let url = `/posting/invoices/export?page=1`;

  if (amountType) {
    url += `&amountType=${amountType}`;
  }

  if (minAmount !== undefined && maxAmount) {
    url += `&amountMin=${minAmount}&amountMax=${maxAmount}`;
  }

  if (paymentStatus?.length) {
    url += paymentStatus.map((status: string) => `&paymentStatus=${status}`).join('');
  }

  if (invoiceStatus?.length) {
    url += invoiceStatus.map((status: string) => `&invoiceStatus=${status}`).join('');
  }

  if (invoiceNumber?.trim()) {
    url += `&invoiceNumber=${invoiceNumber}`;
  }

  if (dateType && dateRange?.startDate && dateRange?.endDate) {
    url += `&dateType=${dateType}&startDate=${dayjs(dateRange.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(
      dateRange.endDate,
    ).format('YYYY-MM-DD')}`;
  }

  if (customerIds?.trim()) {
    url += `&customerIds=${customerIds}`;
  }

  if (fetchOnlyNonZeroAmount !== undefined) {
    url += `&onlyFetchNonZero=${fetchOnlyNonZeroAmount.toString()}`;
  }

  const result = await dataAxiosInstance.get(url);
  return result.data;
};

export const getCreditsExport = async ({
  bankAccountIds,
  placeholderId,
  isPostedToInvoice,
  startDate,
  endDate,
  postingStatus,
  categories,
  narration,
  minAmount,
  maxAmount,
}: {
  bankAccountIds: string[];
  startDate?: Date;
  endDate?: Date;
  placeholderId?: string;
  isPostedToInvoice?: boolean;
  postingStatus?: PostingStatus[];
  categories: string[];
  narration?: string;
  minAmount?: number;
  maxAmount?: number;
}) => {
  let url = `/posting/credits/export?page=1`;

  if (bankAccountIds.length > 0) {
    url += `&bankAccountIds=${bankAccountIds}`;
  }

  if (placeholderId) {
    url += `&placeholderId=${placeholderId}`;
  }

  if (isPostedToInvoice !== undefined) {
    url += `&isPostedToInvoice=${isPostedToInvoice}`;
  }

  if (startDate && endDate) {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

    url += `&periodStartDate=${formattedStartDate}&periodEndDate=${formattedEndDate}`;
  }

  if (postingStatus?.length) {
    url += postingStatus.map((status: string) => `&postingStatus=${status}`).join('');
  }

  if (categories.length) {
    url += `&categories=${categories.join('&categories=')}`;
  }

  if (minAmount !== undefined && maxAmount) {
    url += `&amountMin=${minAmount}&amountMax=${maxAmount}`;
  }

  if (narration?.trim()) {
    url += `&narrationLike=${narration.trim()}`;
  }

  const result = await dataAxiosInstance.get(url);

  return result.data;
};

export const markCreditAsReversed = async ({ creditIds }: { creditIds: string[] }) => {
  const result = await dataAxiosInstance.post(`/posting/markCreditsAsReversed`, {
    creditIds: creditIds,
  });
  return result.data;
};

export const markCreditAsUnreversed = async ({ creditIds }: { creditIds: string[] }) => {
  const result = await dataAxiosInstance.post(`/posting/markCreditsAsUnreversed`, {
    creditIds: creditIds,
  });
  return result.data;
};

export const gePostedPlaceholdersByCustomerId = async ({ customerId }: { customerId: string }) => {
  const result = await dataAxiosInstance.get<IPostedPlaceholderSummaryResponse>(`/placeholder/summary/${customerId}`);
  return result.data;
};

export const roundOffInvoices = async ({
  thresholdAmount,
  invoiceIds,
}: {
  thresholdAmount: number;
  invoiceIds: string[];
}) => {
  const result = await dataAxiosInstance.post('/posting/roundOff/invoices', {
    thresholdAmount,
    invoiceIds,
  });
  return result.data;
};
