import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { usePostCreateCreditTerm, usePostUpdateTerm } from '@/hooks/api-hooks/useCreditTermsQuery';
import { cn } from '@/lib/utils';
import {
  ICreditTerms,
  IDueDatePaymentTerm,
  IEarlyDiscountPaymentTerm,
  IPrepaidPaymentTerm,
} from '@/types/credit-term.types';
import { createTermObjectForRequest } from '@/utils/createTermObjectForRequest';
import dayjs from 'dayjs';
import { Loader2Icon } from 'lucide-react';
import { useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditDescription, EditTermTitle } from '../shared/EditComponents';
import {
  EditDiscountTerm,
  EditFrequency,
  EditIndividualDiscountTerm,
  EditPrePaymentTerm,
} from './PaymentTermEditComponents';

const paymentTermReducer = (
  state: ICreditTerms<'PAYMENT_TERM'> & { isDirty?: boolean; isFullUpfront?: boolean },
  action: {
    type:
      | 'ATTRIBUTE_UPDATE'
      | 'TERM_UPDATE'
      | 'TERM_APPEND'
      | 'TERM_REMOVE'
      | 'APPEND_EARLY_DISCOUNT'
      | 'REMOVE_EARLY_DISCOUNT'
      | 'REMOVE_ALL_EARLY_DISCOUNT'
      | 'UPDATE_EARLY_DISCOUNT'
      | 'SET_AS_FULL_UPFRONT';
    payload: string | number | IEarlyDiscountPaymentTerm | boolean;
    property:
      | keyof ICreditTerms<'PAYMENT_TERM'>
      | keyof ICreditTerms<'PAYMENT_TERM'>['terms']
      | 'isDirty'
      | 'isFullUpfront';
    subField?: keyof IPrepaidPaymentTerm | keyof IDueDatePaymentTerm | keyof IEarlyDiscountPaymentTerm;
    updateIndex?: number;
  },
): ICreditTerms<'PAYMENT_TERM'> & { isDirty?: boolean; isFullUpfront?: boolean } => {
  state.isDirty = true;
  switch (action.type) {
    case 'ATTRIBUTE_UPDATE':
      return {
        ...state,
        [action.property]: action.payload,
      };
    case 'TERM_UPDATE':
      if (action.subField === 'percentage') {
        return {
          ...state,
          terms: {
            ...state.terms,
            [action.property]: {
              ...state.terms[action.property as keyof ICreditTerms<'PAYMENT_TERM'>['terms']],
              [action.subField!]: action.payload,
              amount: 0,
            },
          },
        };
      }

      if (action.subField === 'amount') {
        return {
          ...state,
          terms: {
            ...state.terms,
            [action.property]: {
              ...state.terms[action.property as keyof ICreditTerms<'PAYMENT_TERM'>['terms']],
              [action.subField!]: action.payload,
              percentage: 0,
            },
          },
        };
      }

      return {
        ...state,
        terms: {
          ...state.terms,
          [action.property]: {
            ...state.terms[action.property as keyof ICreditTerms<'PAYMENT_TERM'>['terms']],
            [action.subField!]: action.payload,
          },
        },
      };
    case 'APPEND_EARLY_DISCOUNT':
      return {
        ...state,
        terms: {
          ...state.terms,
          earlyDiscountPaymentTerm: state.terms.earlyDiscountPaymentTerm?.length
            ? [...state.terms.earlyDiscountPaymentTerm, action.payload as IEarlyDiscountPaymentTerm]
            : [action.payload as IEarlyDiscountPaymentTerm],
        },
      };

    case 'REMOVE_EARLY_DISCOUNT':
      return {
        ...state,
        terms: {
          ...state.terms,
          earlyDiscountPaymentTerm: state.terms.earlyDiscountPaymentTerm?.length
            ? state.terms.earlyDiscountPaymentTerm.filter((_, index) => index !== action.updateIndex)
            : state.terms.earlyDiscountPaymentTerm,
        },
      };

    case 'REMOVE_ALL_EARLY_DISCOUNT':
      return {
        ...state,
        terms: {
          ...state.terms,
          earlyDiscountPaymentTerm: undefined,
        },
      };

    case 'UPDATE_EARLY_DISCOUNT':
      return {
        ...state,
        terms: {
          ...state.terms,
          earlyDiscountPaymentTerm: state.terms.earlyDiscountPaymentTerm?.length
            ? state.terms.earlyDiscountPaymentTerm.map((earlyDiscount, index) => {
                if (index === action.updateIndex) {
                  return action.payload as IEarlyDiscountPaymentTerm;
                }
                return earlyDiscount;
              })
            : state.terms.earlyDiscountPaymentTerm,
        },
      };

    case 'SET_AS_FULL_UPFRONT':
      return {
        ...state,
        isFullUpfront: true,
        terms: {
          ...state.terms,
          earlyDiscountPaymentTerm: [],
          dueDatePaymentTerm: undefined,
          prepaidPaymentTerm: {
            percentage: 100,
            amount: 0,
          },
        },
      };
  }

  return state;
};

const PaymentTerm = ({
  creditTermDetails,
  isNew,
  creditTermId,
  isModal,
  customerId,
  handleClose,
}: {
  creditTermDetails: ICreditTerms<'PAYMENT_TERM'>;
  isNew?: boolean;
  creditTermId: string;
  isModal?: boolean;
  customerId?: string;
  handleClose?: () => void;
}) => {
  const [termDetails, setTermDetails] = useReducer(paymentTermReducer, creditTermDetails);

  const { toast } = useToast();

  const navigate = useNavigate();

  const { mutate: updateTerm, isPending: isUpdatePending } = usePostUpdateTerm({
    customConfig: {
      onSuccess: () => {
        toast({
          description: 'Term updated successfully',
        });
        setTermDetails({ type: 'ATTRIBUTE_UPDATE', property: 'isDirty', payload: false });
      },

      onError: (error) => {
        toast({
          description: error.response?.data.message || 'Unable to create term. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: createCreditTerm, isPending } = usePostCreateCreditTerm({
    customConfig: {
      onError: (error) => {
        toast({
          description: error.response?.data.message || 'Unable to create term. Please try again.',
          variant: 'destructive',
        });
      },
      onSuccess: () => {
        toast({
          description: 'Term created successfully',
        });
        handleClose?.();
        if (customerId) return;
        navigate(`/credit-terms`);
      },
    },
  });

  const checkEarlyDiscountTerm = ({ term }: { term: IEarlyDiscountPaymentTerm }) => {
    if (!term.discountAmount && !term.discountPercentage) {
      return false;
    }

    if (term.discountAmount) {
      if (isNaN(Number(term.discountAmount))) {
        return false;
      }

      if (Number(term.discountAmount) < 0) {
        return false;
      }
    }

    if (term.discountPercentage) {
      if (isNaN(Number(term.discountPercentage))) {
        return false;
      }

      if (Number(term.discountPercentage) < 0) {
        return false;
      }

      if (Number(term.discountPercentage) > 100) {
        return false;
      }
    }

    return true;
  };

  const handleSave = () => {
    const validatedResult = createTermObjectForRequest({
      term: termDetails,
    });

    if (!validatedResult.isValid) {
      toast({
        description: validatedResult.errors[0].message,
        variant: 'destructive',
      });
      return;
    }

    if (isNew) {
      createCreditTerm([
        {
          customerId: customerId,
          ...termDetails,
          terms: {
            ...termDetails.terms,
            earlyDiscountPaymentTerm: termDetails.terms.earlyDiscountPaymentTerm
              ?.filter((earlyDiscount) => checkEarlyDiscountTerm({ term: earlyDiscount }))
              .map((earlyDiscount) => ({
                ...earlyDiscount,
                discountAmount: earlyDiscount.discountPercentage ? 0 : earlyDiscount.discountAmount,
              })),
          },
        },
      ]);

      return;
    }

    updateTerm({
      creditTermId: creditTermId,
      term: {
        ...termDetails,
        terms: {
          ...termDetails.terms,
          earlyDiscountPaymentTerm: termDetails.terms.earlyDiscountPaymentTerm
            ?.filter((earlyDiscount) => checkEarlyDiscountTerm({ term: earlyDiscount }))
            .map((earlyDiscount) => ({
              ...earlyDiscount,
              discountAmount: earlyDiscount.discountPercentage ? 0 : earlyDiscount.discountAmount,
            })),
        },
      },
    });
  };

  const handlePrepaidChange = (value: string | number, unit: 'Percentage' | 'Amount') => {
    setTermDetails({
      payload: false,
      property: 'isFullUpfront',
      type: 'ATTRIBUTE_UPDATE',
    });
    if (unit === 'Percentage') {
      if (Number(value) === 100) {
        setTermDetails({
          type: 'SET_AS_FULL_UPFRONT',
          payload: true,
          property: 'prepaidPaymentTerm',
        });
        return;
      }
      setTermDetails({
        type: 'TERM_UPDATE',
        property: 'prepaidPaymentTerm',
        subField: 'percentage',
        payload: value,
      });
      return;
    }

    setTermDetails({
      type: 'TERM_UPDATE',
      property: 'prepaidPaymentTerm',
      subField: 'amount',
      payload: value,
    });
  };

  const handleTitleChange = (value: string) => {
    setTermDetails({
      type: 'ATTRIBUTE_UPDATE',
      property: 'title',
      payload: value,
    });
  };

  const handleIsDiscountChange = (value: string) => {
    if (value === 'yes') {
      setTermDetails({
        type: 'APPEND_EARLY_DISCOUNT',
        payload: {
          discountAmount: 0,
          discountPercentage: 0,
          number: 0,
          frequency: 'DAY',
        },
        property: 'earlyDiscountPaymentTerm',
      });
    } else if (value === 'no') {
      setTermDetails({
        type: 'REMOVE_ALL_EARLY_DISCOUNT',
        payload: 0,
        property: 'earlyDiscountPaymentTerm',
      });
    }
  };

  const handleAddDiscount = () => {
    setTermDetails({
      type: 'APPEND_EARLY_DISCOUNT',
      payload: {
        discountAmount: 0,
        discountPercentage: 0,
        number: 0,
        frequency: 'DAY',
      },
      property: 'earlyDiscountPaymentTerm',
    });
  };

  const handleDiscountTermChange = (value: IEarlyDiscountPaymentTerm, index: number) => {
    setTermDetails({
      type: 'UPDATE_EARLY_DISCOUNT',
      property: 'earlyDiscountPaymentTerm',
      payload: { ...value },
      updateIndex: index,
    });
  };

  const handleDuePaymentTermChange = (value: string | number, field: keyof IDueDatePaymentTerm) => {
    setTermDetails({
      type: 'TERM_UPDATE',
      property: 'dueDatePaymentTerm',
      subField: field,
      payload: value,
    });
  };

  const handleRemoveDiscountTerm = (index: number) => {
    setTermDetails({
      type: 'REMOVE_EARLY_DISCOUNT',
      property: 'earlyDiscountPaymentTerm',
      updateIndex: index,
      payload: 0,
    });
  };

  const handleDescriptionChange = (value: string) => {
    setTermDetails({
      type: 'ATTRIBUTE_UPDATE',
      property: 'description',
      payload: value,
    });
  };

  const lastUpdatedAndBy = useMemo(() => {
    if (!creditTermDetails?.metaData?.edit_history) {
      if (creditTermDetails.createdAt && creditTermDetails.createdAt) {
        return {
          lastUpdated: dayjs(creditTermDetails.createdAt).add(330, 'minutes').toDate(),
          updatedBy: creditTermDetails.createdBy,
        };
      }

      return null;
    }

    const recentItem = creditTermDetails.metaData.edit_history.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    })[0];

    return {
      lastUpdated: dayjs(recentItem.updatedAt).add(330, 'minutes').toDate(),
      updatedBy: recentItem.userName,
    };
  }, [creditTermDetails]);

  return (
    <>
      {!isModal && (
        <div className=" flex justify-between items-center my-4 ">
          <div>
            {lastUpdatedAndBy && (
              <div className=" text-sm text-muted-foreground ">
                Last updated by <span className=" font-semibold text-primary ">{lastUpdatedAndBy.updatedBy}</span> on{' '}
                <span className=" text-primary font-semibold ">
                  {dayjs(lastUpdatedAndBy.lastUpdated).format('DD MMM YYYY, hh:mm A')}
                </span>
              </div>
            )}
          </div>
          <div className=" flex items-center gap-4 justify-end ">
            <Button variant={'outline'} className=" flex items-center gap-2 " onClick={() => navigate('/credit-terms')}>
              Cancel
            </Button>
            <Button
              disabled={isPending || isUpdatePending || !termDetails?.isDirty}
              onClick={handleSave}
              className=" flex items-center gap-2 "
            >
              Save
              {isPending || isUpdatePending ? <Loader2Icon size="w-4 h-4" /> : null}
            </Button>
          </div>
        </div>
      )}
      <Card className={cn(' px-2 py-4', isModal ? ' w-full max-w-[600px] max-h-[600px] overflow-scroll ' : '')}>
        <div className=" flex w-full justify-between ">
          <EditTermTitle
            onChange={handleTitleChange}
            className="text-xl font-semibold"
            defaultValue={termDetails.title}
          />
        </div>
        <div className=" py-4 flex flex-col gap-4 text-sm ">
          <div className=" px-4 pt-4 pb-0.5 border border-secondary rounded-lg flex items-start gap-4 c ">
            <EditPrePaymentTerm
              className=" w-16 h-7 "
              onChange={handlePrepaidChange}
              value={
                termDetails.terms.prepaidPaymentTerm?.amount
                  ? termDetails.terms.prepaidPaymentTerm.amount
                  : termDetails.terms.prepaidPaymentTerm?.percentage
                    ? termDetails.terms.prepaidPaymentTerm.percentage
                    : '0'
              }
              unit={Number(termDetails.terms.prepaidPaymentTerm?.amount) ? 'Amount' : 'Percentage'}
            />
            <span className=" relative top-1 ">upfront payment required</span>
          </div>
          <div
            className={cn(
              ' px-4 py-2 border border-secondary justify-between rounded-lg flex items-center gap-4 ',
              termDetails?.isFullUpfront && ' bg-gray-100/90 opacity-50 pointer-events-none ',
            )}
          >
            <div className=" flex items-center gap-2 ">
              There{' '}
              <EditDiscountTerm
                className=" w-20 h-7"
                value={termDetails.terms.earlyDiscountPaymentTerm?.length ? 'yes' : 'no'}
                onChange={handleIsDiscountChange}
              />{' '}
              early payment discount
            </div>
          </div>
          {termDetails.terms.earlyDiscountPaymentTerm?.map((discountTerm, index) => (
            <EditIndividualDiscountTerm
              remove={handleRemoveDiscountTerm}
              addDiscount={handleAddDiscount}
              onChange={handleDiscountTermChange}
              details={discountTerm}
              index={index}
              unit={discountTerm.discountAmount ? 'Amount' : 'Percentage'}
              key={index}
              isLast={termDetails.terms.earlyDiscountPaymentTerm?.length === index + 1}
            />
          ))}
          <div
            className={cn(
              ' px-4 pt-4 pb-0.5 border border-secondary rounded-lg flex items-start gap-4 ',
              termDetails?.isFullUpfront && ' bg-gray-100/90 opacity-50 pointer-events-none ',
            )}
          >
            <span className=" relative top-1 ">Total payable is due by</span>
            <EditFrequency
              onChange={handleDuePaymentTermChange}
              frequency={termDetails.terms.dueDatePaymentTerm?.frequency || 'DAY'}
              value={termDetails.terms.dueDatePaymentTerm?.number ?? 0}
              className=" w-24 h-7 "
            />
            <span className=" relative top-1 ">from invoice date</span>
          </div>
          <div className=" px-4 py-2 border border-secondary rounded-lg bg-secondary ">
            <h2 className=" mb-1 ">Legal description</h2>
            <p>
              <EditDescription onChange={handleDescriptionChange} value={termDetails.description} />
            </p>
          </div>
        </div>
        {isModal && (
          <div className=" flex items-center gap-4 justify-end ">
            <Button variant="outline" onClick={handleClose} className=" flex items-center gap-2 ">
              Cancel
            </Button>
            <Button
              disabled={isPending || isUpdatePending || !termDetails.isDirty}
              onClick={handleSave}
              className=" flex items-center gap-2 "
            >
              Save
              {isPending || (isUpdatePending && <Loader2Icon className=" w-4 h-4 animate-spin " />)}
            </Button>
          </div>
        )}
      </Card>
    </>
  );
};

export default PaymentTerm;
