import { Button } from '@/components/ui/button';
import ComboboxComponent from '@/components/ui/combobox-component';
import { Input } from '@/components/ui/input';
import SelectComponent from '@/components/ui/select-component';
import { IDueDatePaymentTerm, IEarlyDiscountPaymentTerm } from '@/types/credit-term.types';
import { validateNumber } from '@/utils/validateNumber';
import { PlusIcon, Trash2Icon } from 'lucide-react';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

export const EditPrePaymentTerm = ({
  value,
  className,
  onChange,
  unit,
}: {
  value: string | number | undefined;
  className?: string;
  onChange: (_: string | number, __: 'Percentage' | 'Amount') => void;
  unit: 'Percentage' | 'Amount';
}) => {
  const [upfrontPaymentTermUnit, setUpfrontPaymentTermUnit] = useState(() => unit);
  const [upfrontValue, setUpfrontValue] = useState(() => value);
  const [errors, setErrors] = useState('');
  const perCentageOptions = useMemo(() => {
    const percentageArray = new Array(101).fill(0).map((_, index) => {
      return { value: String(index), label: `${index}%` };
    });

    return [...percentageArray];
  }, []);

  useEffect(() => {
    setUpfrontPaymentTermUnit(unit);
  }, [unit]);

  useEffect(() => {
    setUpfrontValue(value);
  }, [value]);

  const handleChange = (unit: 'Percentage' | 'Amount') => (value: string | number) => {
    setErrors('');
    if (unit === 'Percentage') {
      onChange(value, 'Percentage');
      return;
    }

    setUpfrontValue(value);
    const validatedResult = validateNumber(String(value), 'Prepayment amount');
    if (validatedResult.isValid) {
      onChange(Number(value), 'Amount');
    }

    setErrors(validatedResult.message || '');
  };

  const handleSelectChange = (value: string) => {
    setErrors('');
    setUpfrontPaymentTermUnit(value as 'Percentage' | 'Amount');
    handleChange(value as 'Percentage' | 'Amount')('0');
  };

  return (
    <div>
      <span className=" flex gap-2 items-center ">
        {upfrontPaymentTermUnit === 'Percentage' ? (
          <ComboboxComponent
            className={className}
            options={perCentageOptions}
            placeholder="Select prepayment"
            onChange={handleChange('Percentage')}
            value={{
              label: String(value),
              value: String(value),
            }}
          />
        ) : (
          <Input className={className} onChange={(e) => handleChange('Amount')(e.target.value)} value={upfrontValue} />
        )}

        <SelectComponent
          className={className}
          options={[
            {
              label: '%',
              value: 'Percentage',
            },
            {
              label: '₹',
              value: 'Amount',
            },
          ]}
          onChange={(value) => handleSelectChange(value)}
          value={upfrontPaymentTermUnit}
        />
      </span>

      <p className=" text-red-500 text-xs h-4">{errors}</p>
    </div>
  );
};

export const EditFrequency = ({
  value,
  className,
  onChange,
  frequency,
}: {
  value: number;
  className?: string;
  onChange: (_: string | number, __: keyof IDueDatePaymentTerm) => void;
  frequency: 'DAY' | 'MONTH';
}) => {
  const [errors, setErrors] = useState('');
  const [inputValue, setInputValue] = useState('');
  const frequencyOptions = useMemo(() => {
    return [
      {
        label: 'Days',
        value: 'DAY',
      },

      {
        label: 'Months',
        value: 'MONTH',
      },
    ];
  }, []);

  useEffect(() => {
    setInputValue(String(value));
  }, [value]);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    const validatedResult = validateNumber(value, 'Frequency');
    if (!validatedResult.isValid) {
      setErrors(validatedResult.message || '');
      return;
    }

    setErrors(validatedResult.message || '');
    onChange(Number(value), 'number');
  };

  return (
    <div className=" flex items-start gap-2 ">
      <div>
        <span>
          <Input className={className} value={inputValue} onChange={(e) => handleInputChange(e.target.value)} />
        </span>
        <p className=" text-red-500 text-xs h-4">{errors}</p>
      </div>
      <SelectComponent
        className={className}
        options={frequencyOptions}
        placeholder="Select frequency"
        onChange={(value) => onChange(value, 'frequency')}
        value={frequency}
      />
    </div>
  );
};

export const EditDiscountTerm = ({
  value,
  className,
  onChange,
}: {
  value: string;
  className?: string;
  onChange: (_: string) => void;
}) => {
  const frequencyOptions = useMemo(() => {
    return [
      {
        label: 'is no',
        value: 'no',
      },

      {
        label: 'is',
        value: 'yes',
      },
    ];
  }, []);

  return (
    <SelectComponent
      className={className}
      options={frequencyOptions}
      placeholder="Select discount term"
      onChange={onChange}
      value={value ? value : 'no'}
    />
  );
};

export const EditIndividualDiscountTerm = ({
  details,
  onChange,
  unit,
  index,
  remove,
  isLast,
  addDiscount,
}: {
  details: IEarlyDiscountPaymentTerm;
  onChange: (_: IEarlyDiscountPaymentTerm, __: number) => void;
  unit: 'Percentage' | 'Amount';
  index: number;
  remove: (_: number) => void;
  isLast: boolean;
  addDiscount?: () => void;
}) => {
  const [earlyDiscount, setEarlyDiscount] = useState(details);
  const [paymentUnit, setPaymentUnit] = useState(() => unit);
  const [amountErrors, setAmountErrors] = useState('');
  const [frequencyNumberErrors, setFrequencyNumberErrors] = useState('');
  const [discountValue, setDiscountValue] = useState('');
  const [frequencyNumberValue, setFrequencyNumberValue] = useState('');

  useEffect(() => {
    setEarlyDiscount(details);
  }, [details]);

  useEffect(() => {
    setDiscountValue(String(details.discountAmount));
  }, [details.discountAmount]);

  useEffect(() => {
    setFrequencyNumberValue(String(details.number || 0));
  }, [details.number]);

  useEffect(() => {
    setPaymentUnit(unit);
  }, [unit]);

  const perCentageOptions = useMemo(() => {
    const percentageArray = new Array(101).fill(0).map((_, index) => {
      return { value: String(index), label: `${index}%` };
    });

    return [...percentageArray];
  }, []);

  const handleChange = (unit: 'Percentage' | 'Amount') => (value: string | number) => {
    let details = { ...earlyDiscount };
    if (unit === 'Percentage') {
      details = { ...details, discountPercentage: Number(value) };
      onChange(details, index);
      return;
    }

    setDiscountValue(String(value));
    const validatedResult = validateNumber(String(value), 'Discount amount');
    if (validatedResult.isValid) {
      details = { ...details, discountAmount: Number(value) };
    }

    setAmountErrors(validatedResult.message || '');
    setEarlyDiscount(details);
    onChange(details, index);
    setPaymentUnit(unit);
  };

  const handleFrequencyChange = (value: string) => {
    const details = { ...earlyDiscount, frequency: value } as IEarlyDiscountPaymentTerm;
    setEarlyDiscount(details);
    onChange(details, index);
    setPaymentUnit(unit);
  };

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFrequencyNumberValue(e.target.value);
    const validatedResult = validateNumber(e.target.value, details.frequency);
    if (!validatedResult.isValid) {
      setFrequencyNumberErrors(validatedResult.message || '');
      return;
    }
    const discountDetails = {
      ...earlyDiscount,
      number: Number(e.target.value),
    } as IEarlyDiscountPaymentTerm;
    setEarlyDiscount(discountDetails);
    onChange(discountDetails, index);
    setPaymentUnit(unit);
  };

  return (
    <div className=" px-4 pt-4 pb-0.5 border border-secondary justify-between rounded-lg flex items-start gap-4 ">
      <div className=" flex items-start gap-2 ">
        <div>
          <span className=" flex gap-2 items-center ">
            {paymentUnit === 'Percentage' ? (
              <ComboboxComponent
                className=" w-16 h-7 "
                options={perCentageOptions}
                placeholder="Select prepayment"
                onChange={handleChange('Percentage')}
                value={{
                  label: String(details.discountPercentage),
                  value: String(details.discountPercentage),
                }}
              />
            ) : (
              <Input
                className=" w-16 h-7 "
                onChange={(e) => handleChange('Amount')(e.target.value)}
                value={discountValue}
              />
            )}
            <SelectComponent
              className=" w-16 h-7 "
              options={[
                {
                  label: '%',
                  value: 'Percentage',
                },
                {
                  label: '₹',
                  value: 'Amount',
                },
              ]}
              onChange={(value) => setPaymentUnit(value as 'Percentage' | 'Amount')}
              value={paymentUnit}
            />
          </span>
          <p className=" text-red-500 text-xs h-4">{amountErrors}</p>
        </div>
        <span className=" relative top-1 ">discount if paid within</span>
        <div>
          <span className=" flex items-center gap-2 ">
            <Input className=" w-16 h-7 " onChange={handleNumberChange} value={frequencyNumberValue} />
            <SelectComponent
              className=" w-24 h-7 "
              options={[
                {
                  label: 'Days',
                  value: 'DAY',
                },
                {
                  label: 'Months',
                  value: 'MONTH',
                },
              ]}
              onChange={handleFrequencyChange}
              value={details.frequency}
            />
          </span>
          <p className=" text-red-500 text-xs h-4 capitalize">{frequencyNumberErrors.toLowerCase()}</p>
        </div>
      </div>
      <div className=" flex items-center gap-1 ">
        <Button className=" relative bottom-0.5 " onClick={() => remove(index)} size="icon" variant="outline">
          <Trash2Icon className="h-4 w-4 " />
        </Button>
        {isLast && (
          <Button onClick={() => addDiscount?.()} variant="outline" size="icon" className="relative bottom-0.5 ">
            <PlusIcon className="h-4 w-4 " />
          </Button>
        )}
      </div>
    </div>
  );
};
