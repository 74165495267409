'use client';

import { useTextExtensions } from '@/hooks/template-builder/useTextExtensions';
import { cn } from '@/lib/utils';
import { useStore } from '@/stores/email-builder/page-sections.store';
import { Section, SectionTypes } from '@/utils/email-builder/sections';
import { autoPlacement, autoUpdate, offset, useFloating } from '@floating-ui/react';
import { ColumnDef, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import Color from '@tiptap/extension-color';
import FocusClasses from '@tiptap/extension-focus';
import LinkExtension from '@tiptap/extension-link';
import TextStyle from '@tiptap/extension-text-style';
import { Editor, EditorContent, EditorContext, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { AlertTriangleIcon, Trash2Icon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import { TableExtensionKit } from './extensions/extension-kit';
import LinkMenu from './link-menu';
import { TextMenu } from './text-menu';

const extensions = [
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3, 4],
    },
  }),
  FocusClasses,
  Color.configure({
    types: ['textStyle'],
  }),
  TextStyle.extend({
    priority: 10000,
  }),
  LinkExtension.configure({
    openOnClick: false,
    autolink: false,
    HTMLAttributes: {
      rel: 'noopener noreferrer',
      target: '_blank',
    },
  }),
];

const ButtonSection = ({ content, id, properties, ...props }: Section<'button'>) => {
  const { setSelectedSection, selectedSection, updateSection } = useStore();
  const editor = useEditor({
    content: content,
    extensions: extensions,
    onUpdate: (updateProps) => {
      const content = updateProps.editor.getHTML();

      updateSection({
        ...props,
        id: id,
        content: content,
        properties: properties,
      });
    },
  });

  const menuContainerRef = useRef<HTMLButtonElement>(null);

  const handleSelect = () => {
    setSelectedSection(id);
  };

  const parentStyles = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: properties.element.align,
    };
  }, [properties.element.align]);

  const elementStyles = useMemo(() => {
    return {
      padding: `${properties.element.padding.top}px ${properties.element.padding.right}px ${properties.element.padding.bottom}px ${properties.element.padding.left}px`,
    };
  }, [properties.element.padding]);

  if (!editor) return null;

  return (
    <div
      style={parentStyles}
      onClick={handleSelect}
      className={cn(selectedSection === id && 'ring-1', 'prosemirror-button')}
    >
      <Button ref={menuContainerRef} style={elementStyles} className=" rounded-md bg-primary px-4 py-1 text-white ">
        <EditorContext.Provider value={{ editor }}>
          <EditorContent editor={editor} />
          <TextMenu isLinkOnly editor={editor} />
        </EditorContext.Provider>
      </Button>
    </div>
  );
};

export const SubjectEditor = () => {
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const updateSubjectHtml = useStore((store) => store.updateSubjectHtml);
  const subjectHtml = useStore((store) => store.subjectHtml);
  const textExtensions = useTextExtensions();

  const editor = useEditor(
    {
      extensions: textExtensions,
      onUpdate(props) {
        updateSubjectHtml(props.editor.getHTML());
      },
      onCreate(props) {
        props.editor.commands.setContent(subjectHtml);
      },
    },
    [textExtensions],
  );

  if (!editor) return null;

  return (
    <div className="  border-b py-2 ">
      <div className=" text-sm font-semibold ">Subject:</div>
      <div className=" flex-1 ">
        <EditorContext.Provider value={{ editor }}>
          <LinkMenu editor={editor} appendTo={menuContainerRef} />
          <TextMenu editor={editor} />
          <div ref={menuContainerRef}>
            <EditorContent placeholder="Subject" editor={editor} />
          </div>
        </EditorContext.Provider>
      </div>
    </div>
  );
};

const Text = ({ content, id, ...props }: Section<'text'>) => {
  const { setSelectedSection, updateSection, selectedSection } = useStore();
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const textExtensions = useTextExtensions();

  const editor = useEditor(
    {
      extensions: textExtensions,
      content,
      onUpdate(updateProps) {
        updateSection({
          ...props,
          id: id,
          content: updateProps.editor.getHTML(),
        });
      },
    },
    [textExtensions],
  );

  const handleSelect = () => {
    setSelectedSection(id);
  };

  if (!editor) return null;

  return (
    <div onClick={handleSelect} className={cn(selectedSection === id && 'ring-2')}>
      <EditorContext.Provider value={{ editor }}>
        <TextMenu editor={editor} />
        <LinkMenu editor={editor} appendTo={menuContainerRef} />
        <div ref={menuContainerRef}>
          <EditorContent editor={editor} />
        </div>
      </EditorContext.Provider>
    </div>
  );
};

const EditTableHeader = ({
  content,
  sectionId,
  columnId,
}: {
  content: string;
  sectionId: string;
  columnId: string;
}) => {
  const menuContainerRef = useRef<HTMLDivElement>(null);

  const { updateTableHeaderLabel } = useStore();

  const editor = useEditor({
    extensions: [
      ...TableExtensionKit({
        tableGroup: [
          {
            name: 'Variables',
            title: 'Variables',
            commands: [
              {
                name: 'customer.fame',
                label: '{{customer.fame}}',
                action: (editor: Editor) => {
                  editor.chain().focus().insertContent('{{customer.name}}').run();
                },
              },
              {
                name: 'customer.buyerId',
                label: '{{customer.buyerId}}',
                action: (editor: Editor) => {
                  editor.chain().focus().insertContent('{{customer.buyerId}}').run();
                },
              },
            ],
          },
        ],
      }),
    ],
    content,
    onUpdate(updateProps) {
      updateTableHeaderLabel({
        columnId,
        sectionId,
        value: updateProps.editor.getHTML(),
      });
    },
  });

  useEffect(() => {
    if (!editor) return;

    if (content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  if (!editor) return null;

  return (
    <EditorContext.Provider value={{ editor }}>
      <div ref={menuContainerRef}>
        <EditorContent editor={editor} />
        <LinkMenu editor={editor} appendTo={menuContainerRef} />
      </div>
    </EditorContext.Provider>
  );
};

const EditTableCell = ({
  content,
  rowIndex,
  field,
  sectionId,
}: {
  content: string;
  rowIndex: number;
  field: string;
  sectionId: string;
}) => {
  const menuContainerRef = useRef<HTMLDivElement>(null);

  const { updateTableCell } = useStore();
  const textExtensions = useTextExtensions();

  const editor = useEditor(
    {
      extensions: [...textExtensions],
      content,
      onUpdate(props) {
        updateTableCell({
          fieldName: field,
          rowIndex,
          sectionId: sectionId,
          value: props.editor.getHTML(),
        });
      },
    },
    [textExtensions],
  );

  if (!editor) return null;

  return (
    <EditorContext.Provider value={{ editor }}>
      <TextMenu editor={editor} />
      <LinkMenu editor={editor} appendTo={menuContainerRef} />
      <div ref={menuContainerRef}>
        <EditorContent editor={editor} />
      </div>
    </EditorContext.Provider>
  );
};

const TableRowComponent = ({
  row,
  type,
  sectionId,
}: {
  row: Row<{
    [key: string]: unknown;
    id: string;
  }>;
  type: 'Manual' | 'Invoice';
  sectionId: string;
}) => {
  const timeout = useRef<number>();
  const [visible, setVisible] = useState(false);
  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(true);
    }, 25);
  }, []);
  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(false);
    }, 25);
  }, []);
  const { updateSection, section } = useStore((store) => ({
    updateSection: store.updateSection,
    section: store.sections[sectionId],
  }));

  const { refs, floatingStyles } = useFloating({
    placement: 'left-start',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: -38,
        crossAxis: 4,
      }),
      autoPlacement({
        allowedPlacements: ['right-start', 'right', 'right-end'],
      }),
    ],
  });

  const handleDeleteRow = (rowId: string) => {
    if (!section) return;
    const currentSection = section as Section<'table'>;

    updateSection({
      ...currentSection,
      content: {
        ...currentSection.content,
        content: {
          ...currentSection.content.content,
          rows: currentSection.content.content.rows.filter((item) => item.id !== rowId),
        },
      },
    });
  };

  return (
    <>
      <TableRow onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={refs.setReference} key={row.id}>
        {row.getVisibleCells().map((cell) => (
          <TableCell className="px-6 truncate whitespace-nowrap overflow-ellipsis min-w-[150px] " key={cell.id}>
            {type === 'Manual' ? (
              <EditTableCell
                sectionId={sectionId}
                rowIndex={row.index}
                // TODO fixme
                // @ts-expect-error missing types
                content={cell.row.original[cell.column.columnDef?.accessorKey] as string}
                // @ts-expect-error missing types
                field={cell.column.columnDef?.accessorKey as string}
              />
            ) : (
              // @ts-expect-error missing types
              (cell.row.original[cell.column.columnDef?.accessorKey] as string)
            )}
          </TableCell>
        ))}
        {type === 'Manual' && (
          <td
            ref={refs.setFloating}
            style={floatingStyles}
            className={cn(
              'z-10 space-y-2 rounded-md border bg-red-400 bg-background p-1',
              visible ? 'opacity-100' : 'opacity-0',
            )}
          >
            <button className="flex items-center justify-center p-1">
              <Trash2Icon onClick={() => handleDeleteRow(row.original.id)} className="h-4 w-4" />
            </button>
          </td>
        )}
      </TableRow>
    </>
  );
};

const TableSection = ({ content, id }: Section<'table'>) => {
  const columns: ColumnDef<{
    [key: string]: unknown;
    id: string;
  }>[] = content.content.columns.map((column) => ({
    id: column.columnId,
    accessorKey: column.fieldName,
    header: column.label,
  }));

  const { setSelectedSection, selectedSection } = useStore();

  const table = useReactTable({
    data: content.content.rows || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSelect = () => {
    setSelectedSection(id);
  };

  return (
    <div className=" w-full ">
      <div className=" overflow-scroll w-full ">
        <Table onClick={handleSelect} className={cn('flex-1 my-4', selectedSection === id ? 'ring-1' : '')}>
          <TableHeader className=" bg-gray-100 ">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead className="px-6" key={header.id}>
                      <div className="flex items-center justify-between">
                        <span>
                          <EditTableHeader
                            columnId={header.id}
                            sectionId={id}
                            content={header.column.columnDef.header as string}
                          />
                        </span>
                      </div>
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="py-4">
            {table.getRowModel().rows.map((row) => (
              <TableRowComponent sectionId={id} key={row.id} row={row} type={content.content.variable} />
            ))}
          </TableBody>
        </Table>
      </div>
      <div className=" flex justify-start items-center mb-4 ">
        {columns.length > 2 && (
          <p className="text-xs text-yellow-600 font-semibold flex gap-1 items-center">
            <AlertTriangleIcon className="h-3 w-3" />
            This table will appear truncated on small devices, will be visible in webview
          </p>
        )}
      </div>
    </div>
  );
};

export const renderSection = ({ type, ...props }: Section<SectionTypes>): React.ReactNode => {
  switch (type) {
    case 'button': {
      const buttonProps = { ...props, type } as Section<'button'>;
      return <ButtonSection {...buttonProps} />;
    }
    case 'text': {
      const textProps = { ...props, type } as Section<'text'>;
      return <Text {...textProps} />;
    }
    case 'table': {
      const tableProps = { ...props, type } as Section<'table'>;
      return <TableSection {...tableProps} />;
    }
  }
};
