import { useAuthToken } from '@/hooks/api-hooks/useAuth';
import { dataAxiosInstance } from '@/lib/axios';
import { Loader2Icon } from 'lucide-react';
import { Navigate } from 'react-router-dom';

export default function SkipIfAuthenticated({ children }: { children: React.ReactNode }) {
  const { isError, isLoading } = useAuthToken();

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center overflow-hidden">
        <div className="flex items-center px-2 sm:px-4 md:px-8">
          <Loader2Icon className="w-10 h-10 animate-spin" />
        </div>
      </div>
    );
  }

  if (isError) {
    dataAxiosInstance.defaults.headers.common['Authorization'] = '';
    return children;
  }

  return <Navigate to={'/cashflow/summary'} />;
}
