import { cn } from '@/lib/utils';
import { formatCurrency } from '@/utils/formatCurrency';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Info } from 'lucide-react';
import { useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../ui/tooltip';

interface ISummaryTableData {
  label: string;
  planned: number;
  actual: number;
  avgForecast: number;
  avgActual: number;
  isDebit: boolean;
  diff: number;
  startDate: Date;
  endDate: Date;
}

const getMonthsToDisplay = (startDate: Date, endDate: Date) => {
  const startMonth = dayjs(startDate).format('MMM');
  const endMonth = dayjs(endDate).format('MMM');

  if (startMonth === endMonth) {
    return startMonth;
  }

  if (Math.abs(dayjs(startDate).diff(dayjs(endDate), 'month')) === 11) {
    return 'FY' + dayjs(endDate).format('YY');
  }

  return `${dayjs(startDate).format('MMM')} - ${dayjs(endDate).format('MMM')}`;
};

const SummaryTable = ({
  data,
  planEndDate,
  planStartDate,
}: {
  data: ISummaryTableData[];
  planStartDate: Date;
  planEndDate: Date;
}) => {
  const { startDate, endDate } = data[0];

  const lastDate = useMemo(() => {
    if (dayjs(endDate).isBefore(dayjs(planStartDate))) {
      return planEndDate;
    }
    return endDate;
  }, [endDate, planEndDate, planStartDate]);

  const columns: ColumnDef<ISummaryTableData>[] = useMemo(
    () => [
      {
        id: 'category',
        header: 'Category',
        accessorKey: 'label',
      },

      {
        header: () => {
          return (
            <>
              <div className="text-center">Plan</div>
              <div className="text-center text-xs">({getMonthsToDisplay(planStartDate, planEndDate)})</div>
            </>
          );
        },
        accessorKey: 'planned',
        id: 'planned',
        cell: ({ getValue }) => {
          return (
            <span
              className={cn(
                'text-right',
                (getValue() as number) < 0 ? 'text-red-500' : 'text-green-700',
                !getValue() && 'text-gray-500',
              )}
            >
              {formatCurrency(Math.abs(getValue() as number))}
            </span>
          );
        },
      },
      {
        header: () => {
          return (
            <>
              <div className="text-center">Actual</div>
              <div className="text-center text-xs">
                ({dayjs(startDate).format('DD MMM YY')} -<br />
                {dayjs(lastDate).format('DD MMM YY')})
              </div>
            </>
          );
        },
        accessorKey: 'actual',
        id: 'actual',
        cell: ({ getValue }) => {
          return (
            <span
              className={cn(
                'text-right',
                (getValue() as number) < 0 ? 'text-red-500' : 'text-green-700',
                !getValue() && 'text-gray-500',
              )}
            >
              {formatCurrency(Math.abs(getValue() as number))}
            </span>
          );
        },
      },
      {
        header: () => (
          <div className="flex items-center gap-2">
            Difference
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Info width={20} height={20} fill={''} stroke="white" />
                </TooltipTrigger>
                <TooltipContent>Actual - Planned</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ),
        accessorKey: 'diff',
        id: 'diff',
        cell: ({ getValue }) => {
          return (
            <span
              className={cn(
                'text-right',
                (getValue() as number) < 0 ? 'text-red-500' : 'text-green-700',
                !getValue() && 'text-gray-500',
              )}
            >
              {formatCurrency(Math.abs(getValue() as number))}
            </span>
          );
        },
      },
      {
        id: 'Forecast',
        cell: ({ row }) => (
          <div className="flex flex-col justify-start text-sm px-4 min-w-[350px]">
            {Math.abs(row.original.avgForecast) > 0 && (
              <div className="flex justify-between gap-4 items-center">
                {row.original.isDebit ? (
                  <>Avg. Daily {row.original.label} Forecast</>
                ) : (
                  <>Avg. Daily {row.original.label} Required To Meet Plan</>
                )}
                <span>{formatCurrency(Math.abs(row.original.avgForecast))}</span>
              </div>
            )}
            <div className="flex justify-between items-center">
              Avg. Daily {row.original.label} Actual <span>{formatCurrency(Math.abs(row.original.avgActual))}</span>
            </div>
          </div>
        ),
      },
    ],
    [startDate, lastDate, planEndDate, planStartDate],
  );

  const table = useReactTable({
    columns,
    data: data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table>
      <TableHeader className=" bg-gray-100 ">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead className="text-sm py-4" key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
              {row.getVisibleCells().map((cell) => (
                <TableCell className=" py-4 text-sm" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default SummaryTable;
