import { cn } from '@/lib/utils';
import React from 'react';

const Heading = ({
  title,
  description,
  className,
  cta,
}: {
  title: string;
  description?: string;
  className?: string;
  cta?: React.ReactNode;
}) => {
  return (
    <div className={cn('pb-4 border-b mb-6 flex justify-between items-center', className)}>
      <div>
        <h1 className="text-lg  font-bold">{title}</h1>
        <p className=" text-sm text-muted-foreground ">{description}</p>
      </div>
      {cta}
    </div>
  );
};

export default Heading;
