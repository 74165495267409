'use client';

import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons';
import * as React from 'react';

import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useUserContext } from '@/contexts/UserContext';
import { useGetCategoryRelationShipsForCompany } from '@/hooks/api-hooks/useCategoryQuery';
import { cn } from '@/lib/utils';
import { ICategoryRelationship } from '@/types/category.types';
import { useMemo } from 'react';
import { Skeleton } from './skeleton';

interface IOptions {
  value: string;
  label: string;
  level: number;
  isLeaf: boolean;
}

const flattenCategories = (relationsShips: ICategoryRelationship, level: number): IOptions[] => {
  return Object.entries(relationsShips).reduce((prev, current) => {
    if (current[1].categoryValue.includes('_diffnode')) {
      return prev;
    }
    return [
      ...prev,
      {
        value: current[1].categoryId,
        label: current[1].categoryValue,
        level,
        isLeaf: !current[1]?.children,
      },
      ...(current[1].children ? flattenCategories(current[1].children, level + 1) : []),
    ];
  }, [] as IOptions[]);
};

export function CategoryCombobox({
  handleSelectChange,
  selectedCategory,
  onlyLeaf = false,
  hideSummary = false,
  handleEditOpen,
  defaultOpen = false,
}: {
  handleSelectChange: (_: string) => void;
  selectedCategory: string | undefined;
  onlyLeaf?: boolean;
  hideSummary?: boolean;
  handleEditOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  defaultOpen?: boolean;
}) {
  const [open, setOpen] = React.useState(defaultOpen);
  const [inputSearch, setInputSearch] = React.useState<string>('');

  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { data: categoryRelations, isLoading: isCategoriesLoading } = useGetCategoryRelationShipsForCompany({
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const categories = useMemo(() => {
    if (!categoryRelations?.data) {
      return [];
    }
    if (hideSummary) {
      return [...flattenCategories(categoryRelations.data.relationships, 0)];
    }
    return [
      {
        value: 'summary',
        label: 'Summary',
        level: 0,
        isLeaf: false,
      },
      ...flattenCategories(categoryRelations.data.relationships, 0),
    ];
  }, [categoryRelations, hideSummary]);

  if (isCategoriesLoading) {
    return <Skeleton className="w-[200px] h-8" />;
  }

  if (!categoryRelations?.data) {
    return null;
  }

  const handleSelect = (value: string, onlyLeafAllowed: boolean, isLeaf: boolean) => () => {
    if (onlyLeafAllowed && !isLeaf) {
      return;
    }
    handleSelectChange(value);
    setOpen(false);
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      handleEditOpen?.(false);
    }
  };

  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="w-[200px] justify-between">
          {selectedCategory
            ? categories.find((category) => category.value === selectedCategory)?.label
            : 'Select category...'}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[250px]  p-0">
        <Command>
          <CommandInput
            value={inputSearch}
            onValueChange={setInputSearch}
            placeholder="Search category..."
            className="h-9"
          />
          <CommandEmpty>No category found.</CommandEmpty>
          <CommandGroup className="h-[300px] overflow-y-scroll">
            {categories.map((category) => (
              <CommandItem
                key={category.value}
                value={category.label}
                className={cn(onlyLeaf && !category.isLeaf && 'opacity-50')}
                onSelect={handleSelect(category.value, onlyLeaf, category.isLeaf)}
              >
                <div
                  className="flex items-center gap-2"
                  style={{
                    paddingLeft: inputSearch.trim() ? 0 : `${(category.level || 0) * 24}px`,
                  }}
                >
                  <CheckIcon
                    className={cn('h-4 w-4', selectedCategory === category.value ? 'opacity-100' : 'opacity-0')}
                  />
                  {category.label}
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
