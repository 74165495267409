import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '@/components/ui/dialog';
import { useToast } from '@/components/ui/use-toast';
import { usePostMarkCreditAsReversed, usePostMarkCreditAsUnReversed } from '@/hooks/api-hooks/usePostingQuery';
import { useState } from 'react';

export const MarkCreditAsReversedDialog = ({ creditIds, clearAll }: { creditIds: string[]; clearAll: () => void }) => {
  const { toast } = useToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { mutate: markAsReversed, isPending } = usePostMarkCreditAsReversed({
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to mark credit as reversed. Please try again.',
        });
      },
      onSuccess: () => {
        toast({ description: 'Credit(s) marked as reversed successfully.' });
        setIsDeleteDialogOpen(false);
        clearAll();
      },
    },
  });

  return (
    <>
      <Button variant={'outline'} onClick={() => setIsDeleteDialogOpen(true)}>
        Mark as Reversed
      </Button>
      {isDeleteDialogOpen && (
        <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
          <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md max-w-64">
            <DialogHeader className=" font-semibold text-base ">Mark Credit as Reversed</DialogHeader>
            <p className="text-sm">Are you sure you want to mark the selected transactions as reversed?</p>
            <div className=" p-2 bg-yellow-300/20 text-sm rounded  ">
              <span className=" font-semibold ">Note:</span> Any postings made to this credit will also be removed and
              cannot be reverted.
            </div>
            <DialogFooter>
              <Button onClick={() => setIsDeleteDialogOpen(false)} variant="outline">
                Cancel
              </Button>
              <Button
                loading={isPending}
                onClick={() =>
                  markAsReversed({
                    creditIds,
                  })
                }
              >
                Mark as Reversed
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export const MarkCreditAsUnReversedDialog = ({
  creditIds,
  clearAll,
}: {
  creditIds: string[];
  clearAll: () => void;
}) => {
  const { toast } = useToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { mutate: markAsUnReversed, isPending } = usePostMarkCreditAsUnReversed({
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to mark credit as unreversed. Please try again.',
        });
      },
      onSuccess: () => {
        toast({ description: 'Credit(s) added to list of available to post.' });
        setIsDeleteDialogOpen(false);
        clearAll();
      },
    },
  });

  return (
    <>
      <Button variant={'outline'} onClick={() => setIsDeleteDialogOpen(true)}>
        Mark as Unreversed
      </Button>
      {isDeleteDialogOpen && (
        <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
          <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md max-w-64">
            <DialogHeader className=" font-semibold text-base ">Mark Credit as Unreversed</DialogHeader>
            <p className="text-sm">
              Marking the selected credit(s) as unreversed will make them available for posting. Are you sure?
            </p>
            <DialogFooter>
              <Button onClick={() => setIsDeleteDialogOpen(false)} variant="outline">
                Cancel
              </Button>
              <Button
                loading={isPending}
                onClick={() =>
                  markAsUnReversed({
                    creditIds,
                  })
                }
              >
                Mark as Unreversed
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
