import UpiQRView from '@/components/upi-qr/UpiQRView';
import { useGetPaymentDetails } from '@/hooks/api-hooks/useUpiQuery';
import { Loader2Icon } from 'lucide-react';
import { Navigate, useSearchParams } from 'react-router-dom';

const UPIQRPage = () => {
  const [searchParams] = useSearchParams();
  const paymentId = searchParams.get('data');

  const {
    data: paymentDetailsResponse,
    isLoading,
    isError,
  } = useGetPaymentDetails({
    paymentId: paymentId as string,
    customConfig: {
      enabled: !!paymentId,
    },
  });
  const paymentDetails = paymentDetailsResponse?.data;

  if (!paymentId) {
    return <Navigate to="/404" />;
  }

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Loader2Icon className="w-10 h-10 animate-spin" />
      </div>
    );
  }

  if (isError || !paymentDetails) {
    return <Navigate to="/404" />;
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <UpiQRView
        companyName={paymentDetails.companyName}
        companyLogoUrl={paymentDetails.companyLogo}
        vpa={paymentDetails.vpa}
        message={paymentDetails.message}
        qrCode={paymentDetails.qrCode}
        amount={paymentDetails.amount}
        isQRLoading={isLoading}
        isQRError={isError}
      />
    </div>
  );
};

export default UPIQRPage;
