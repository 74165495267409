import { Skeleton } from '@/components/ui/skeleton';
import { useGetSegments } from '@/hooks/api-hooks/useSegmentQuery';
import { IOptions } from '@/types/common.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import { useEffect, useMemo, useState } from 'react';
import Select, { MultiValue } from 'react-select';

const SelectSegments = ({ onChange, value }: { onChange: (_: IOptions[]) => void; value: IOptions[] }) => {
  const [selectedSegments, setSelectedSegments] = useState<IOptions[]>([]);

  const { data: segmentsResponse, isLoading } = useGetSegments({});

  const segmentsMap = useMemo(() => {
    return (
      segmentsResponse?.data.segments.reduce(
        (prev, current) => ({
          ...prev,
          [current.id]: current.name,
        }),
        {} as { [key: string]: string },
      ) || {}
    );
  }, [segmentsResponse]);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, IOptions>();
  }, []);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  useEffect(() => {
    setSelectedSegments(
      value.map((item) => ({
        value: item.value,
        label: (item.value === item.label ? segmentsMap[item.value] : item.label) || '',
      })),
    );
  }, [value, segmentsMap]);

  const handleBankAccountsChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((segment) => ({ value: segment.value, label: segment.label }));
    onChange(newValue);
    setSelectedSegments(newValue);
  };

  const segments = useMemo(() => {
    return [
      ...(segmentsResponse?.data.segments.map((segment) => ({ value: segment.id, label: segment.name })) || []),
      {
        value: 'all',
        label: 'All',
      },
    ];
  }, [segmentsResponse]);

  if (isLoading) {
    return <Skeleton className=" w-full h-7 " />;
  }

  return (
    <Select
      onChange={handleBankAccountsChange}
      menuPlacement="top"
      value={selectedSegments}
      className=" min-w-[200px] text-sm shadow-sm"
      classNames={selectClasses}
      isMulti={true}
      name="segments"
      options={segments}
      classNamePrefix="select"
      placeholder="Select segments"
      styles={selectStyles}
    />
  );
};

export default SelectSegments;
