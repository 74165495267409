import { Button } from '@/components/ui/button';

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { useGetCustomerSuggestionsMutation } from '@/hooks/api-hooks/useCustomerQuery';
import { IOptions } from '@/types/common.types';
import { CreditNoteStatus, ICreditNotesFilters } from '@/types/credit-notes.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import dayjs from 'dayjs';
import { XIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { NumberRangeInputWithoutValidation, validateInputs } from '../shared/ClubbedSelectWithRange';
import FilterList from '../shared/filters/FilterList';
import MultiInvoiceNumberInput from '../shared/MultiInvoiceInput';
import MultiValueInput from '../shared/MultiValueInput';
import { FormField } from '../ui/form';
import { DatePickerWithRange } from '../ui/multi-datepicker';
import { CreditNotesStatusDropdown } from './Dropdowns';

interface ICreditNotesFiltersSearchParams
  extends Record<keyof Omit<ICreditNotesFilters, 'dateRange' | 'amount'>, string> {
  fromDate: string;
  toDate: string;
  maxAmount: string;
  minAmount: string;
}

const getFilterValueLabel = (key: string, value: string) => {
  if (key === 'status') {
    return value.split('_').join(' ').toLowerCase();
  }

  return value;
};

const CreditNotesFilters = ({
  handleSubmit,
  creditNotesFilters,
  showCustomerFilter = true,
}: {
  handleSubmit: (_: Partial<ICreditNotesFilters>) => void;
  creditNotesFilters: Partial<ICreditNotesFilters>;
  showCustomerFilter?: boolean;
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [focusedField, setFocusedField] = useState<keyof Partial<ICreditNotesFilters> | ''>('');

  const { control, handleSubmit: handleFormSubmit } = useForm<Partial<ICreditNotesFilters>>({
    values: {
      ...creditNotesFilters,
    },
  });

  const handleModalOpenChange = (isOpen: boolean) => {
    setIsFilterModalOpen(isOpen);
  };

  const handleReset = () => {
    setSearchParams(
      {},
      {
        replace: true,
      },
    );
    setIsFilterModalOpen(false);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const filterKeyToLabelMap = useMemo<Record<keyof ICreditNotesFiltersSearchParams, string>>(() => {
    return {
      fromDate: 'From Date',
      toDate: 'To Date',
      customers: 'Customer',
      invoiceNumber: 'Invoice Number',
      creditNoteNumber: 'Credit Note Number',
      status: 'Status',
      type: 'Type',
      amount: 'Amount',
      maxAmount: 'Max Amount',
      minAmount: 'Min Amount',
    };
  }, []);

  useEffect(() => {
    const filtersAsSearchParams: ICreditNotesFiltersSearchParams = {
      customers: searchParams.get('customers') || '',
      fromDate: searchParams.get('fromDate') || '',
      toDate: searchParams.get('toDate') || '',
      invoiceNumber: searchParams.get('invoiceNumber') || '',
      creditNoteNumber: searchParams.get('creditNoteNumber') || '',
      maxAmount: searchParams.get('maxAmount') || '',
      minAmount: searchParams.get('minAmount') || '',
      status: searchParams.get('status') || '',
      type: searchParams.get('type') || '',
    };

    handleSubmit({
      customers: filtersAsSearchParams.customers
        ? filtersAsSearchParams.customers
            .split(',')
            .filter((v) => !!v.trim() && v.split(':').length === 2)
            .map((item) => ({
              label: item.split(':')[0],
              value: item.split(':')[1],
            }))
        : undefined,
      dateRange: {
        from: filtersAsSearchParams.fromDate ? dayjs(filtersAsSearchParams.fromDate).toDate() : undefined,
        to: filtersAsSearchParams.toDate ? dayjs(filtersAsSearchParams.toDate).toDate() : undefined,
      },
      invoiceNumber: filtersAsSearchParams.invoiceNumber
        ? filtersAsSearchParams.invoiceNumber.split(',').map((v) => v.trim())
        : undefined,
      creditNoteNumber: filtersAsSearchParams.creditNoteNumber
        ? filtersAsSearchParams.creditNoteNumber
            .trim()
            .split(',')
            .map((v) => v.trim())
        : undefined,
      amount:
        filtersAsSearchParams.maxAmount && filtersAsSearchParams.minAmount
          ? [Number(filtersAsSearchParams.minAmount), Number(filtersAsSearchParams.maxAmount)]
          : [undefined, undefined],
      status: filtersAsSearchParams.status
        ? filtersAsSearchParams.status.split(',').map((v) => v.trim() as CreditNoteStatus)
        : undefined,
      type: filtersAsSearchParams.type ? filtersAsSearchParams.type.split(',').map((v) => v.trim()) : undefined,
    });
  }, [searchParams, handleSubmit]);

  const { mutateAsync: fetchCustomers } = useGetCustomerSuggestionsMutation({});

  const onSubmit = (data: Partial<ICreditNotesFilters>) => {
    // handleSubmit
    const filtersAsSearchParams: ICreditNotesFiltersSearchParams = {
      customers: data.customers?.map((c) => `${c.label}:${c.value}`).join(',') || '',
      fromDate: data.dateRange?.from ? dayjs(data.dateRange.from).format('YYYY-MM-DD') : '',
      toDate: data.dateRange?.to ? dayjs(data.dateRange.to).format('YYYY-MM-DD') : '',
      creditNoteNumber: data.creditNoteNumber?.join(',') || '',
      maxAmount: data.amount?.[1] ? String(data.amount?.[1]) : '',
      minAmount: data.amount?.[0] ? String(data.amount?.[0]) : '',
      status: data.status?.join(',') || '',
      type: data.type?.join(',') || '',
      invoiceNumber: data.invoiceNumber?.join(',') || '',
    };

    setSearchParams(
      {
        ...filtersAsSearchParams,
      },
      {
        replace: true,
      },
    );
    setIsFilterModalOpen(false);
  };

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, IOptions>();
  }, []);

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchCustomers({
        query: inputValue.trim(),
      });
      return result.data.suggestions.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    [fetchCustomers],
  );

  const filtersAsSearchParams: ICreditNotesFiltersSearchParams = useMemo(
    () => ({
      customers: searchParams.get('customers') || '',
      fromDate: searchParams.get('fromDate') || '',
      toDate: searchParams.get('toDate') || '',
      invoiceNumber: searchParams.get('invoiceNumber') || '',
      creditNoteNumber: searchParams.get('creditNoteNumber') || '',
      maxAmount: searchParams.get('maxAmount') || '',
      minAmount: searchParams.get('minAmount') || '',
      status: searchParams.get('status') || '',
      type: searchParams.get('type') || '',
    }),
    [searchParams],
  );

  const filterList = useMemo(() => {
    return Object.entries(filtersAsSearchParams)
      .map(([key, value]: [string, string]) => {
        return {
          label: key,
          value: value
            .split(',')
            .map((v) => getFilterValueLabel(key, v.trim()))
            .join(', '),
        };
      })
      .filter((filter) => !!filter.value);
  }, [filtersAsSearchParams]);

  return (
    <>
      <div className=" flex gap-4 items-center">
        <Button onClick={() => setIsFilterModalOpen(true)} variant="outline" className=" relative ">
          Filters
          {filterList.length > 0 && (
            <div className="w-4 h-4 bg-black text-white rounded-xl mr-[-22px] mb-[28px]">
              <div className="text-center mb-auto mt-auto text-xs">{filterList.length}</div>
            </div>
          )}
        </Button>
        {filterList.length > 0 && (
          <Button variant="outline" onClick={handleReset}>
            Reset
          </Button>
        )}
        <div className=" flex items-center gap-2 flex-1 overflow-x-scroll py-2 ">
          <FilterList
            filterKeyToLabelMap={filterKeyToLabelMap}
            setFocusField={setFocusedField}
            setModalOpen={setIsFilterModalOpen}
            filterList={filterList}
          />
        </div>
      </div>
      {isFilterModalOpen && (
        <Dialog open={isFilterModalOpen} onOpenChange={handleModalOpenChange}>
          <DialogContent>
            <form onSubmit={handleFormSubmit(onSubmit)} className="w-[620px] border-2 bg-white	">
              <div className="h-[52px] p-4 flex  justify-between border-b">
                <div>
                  <DialogHeader>
                    <DialogTitle>Filters</DialogTitle>
                  </DialogHeader>
                </div>
                <div>
                  <XIcon
                    className="w-[20px] h-[20px] cursor-pointer"
                    onClick={() => {
                      setIsFilterModalOpen(false);
                    }}
                  />
                </div>
              </div>
              <div className=" flex flex-col gap-4 py-4 ">
                <div className=" w-full flex gap-4  px-4 justify-between">
                  <div className="min-w-[240px]  flex-1">
                    <Label className="text-right">Type</Label>
                    <Controller
                      control={control}
                      name="type"
                      render={({ field: { value, onChange, ref } }) => (
                        <MultiValueInput
                          ref={ref}
                          autoFocus
                          value={value || []}
                          onChange={onChange}
                          placeholder="Enter credit note type"
                        />
                      )}
                    />
                  </div>
                  <div className=" min-w-[240px] flex-1 ">
                    <Label className="text-right">Credit note date</Label>
                    <Controller
                      control={control}
                      rules={{
                        validate: (value) => {
                          if (value === undefined) {
                            return true;
                          }

                          if (value.from && value.to) {
                            return true;
                          }

                          if (value.from === value.to) {
                            return true;
                          }

                          return 'Please select date range';
                        },
                      }}
                      name="dateRange"
                      render={({ field: { value, onChange, ref }, formState: { errors } }) => (
                        <>
                          <DatePickerWithRange ref={ref} buttonClass="w-full " value={value} onChange={onChange} />
                          <p className="text-destructive text-xs">{errors.dateRange?.message}</p>
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="flex gap-4 items-center  px-4 justify-between">
                  <div className="min-w-[240px] flex-1">
                    <Label className="text-right">Credit note status</Label>
                    <FormField
                      control={control}
                      name="status"
                      render={({ field: { value, onChange } }) => (
                        <CreditNotesStatusDropdown value={value || []} onChange={onChange} />
                      )}
                    />
                  </div>
                  <div className="min-w-[240px] flex-1">
                    <Label className="text-right">Credit note number</Label>
                    <Controller
                      control={control}
                      name="creditNoteNumber"
                      render={({ field: { value, onChange } }) => (
                        <MultiValueInput
                          value={value || []}
                          onChange={onChange}
                          placeholder="Search by credit note number"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex gap-4 items-center  px-4 justify-between">
                  <div className="min-w-[240px] flex-1">
                    <Label className="text-right">Invoice Numbers</Label>
                    <Controller
                      control={control}
                      name="invoiceNumber"
                      render={({ field: { value, onChange } }) => (
                        <MultiInvoiceNumberInput
                          value={value || []}
                          onChange={onChange}
                          placeholder="Search by invoice number"
                        />
                      )}
                    />
                  </div>
                  {!!showCustomerFilter && (
                    <div className="min-w-[240px] flex-1">
                      <Label className="text-right">Customers</Label>
                      <Controller
                        control={control}
                        name="customers"
                        render={({ field: { value, onChange } }) => (
                          <AsyncSelect
                            isMulti
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            onChange={onChange}
                            value={value}
                            loadOptions={loadSelectOptions}
                            className=" min-w-[200px] text-sm shadow-sm"
                            styles={selectStyles}
                            placeholder="Search by customer name"
                            classNames={selectClasses}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
                <div className="flex gap-4 items-center  px-4 justify-between">
                  <div className="min-w-[240px] flex-1">
                    <Label className="text-right">Amount</Label>
                    <Controller
                      control={control}
                      name="amount"
                      rules={{
                        validate: (value) => {
                          if (value === undefined) {
                            return true;
                          }

                          if (value[0] === value[1]) {
                            return true;
                          }

                          if (
                            (value[0] === undefined && value[1] !== undefined) ||
                            (value[0] !== undefined && value[1] === undefined)
                          ) {
                            return 'Please add a range for amount';
                          }

                          const validation = validateInputs({
                            from: String(value[0]),
                            max: Infinity,
                            to: String(value[1]),
                          });

                          if (validation.isError) {
                            return validation.errorString;
                          }

                          return true;
                        },
                      }}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <>
                          <NumberRangeInputWithoutValidation
                            containerClassName="flex-row"
                            itemClassName="flex-1"
                            value={value || [undefined, undefined]}
                            onValueChange={onChange}
                            max={Infinity}
                            min={0}
                          />
                          <p className=" text-xs text-destructive ">{error?.message}</p>
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="h-[64px] p-4 border-t">
                <DialogFooter>
                  <Button onClick={handleReset} variant="outline">
                    Reset
                  </Button>
                  <Button type="submit" disabled={false} className="flex items-center gap-2">
                    Apply
                  </Button>
                </DialogFooter>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default CreditNotesFilters;
