import {
  getCategoryByCompany,
  getCategoryRelationshipsForCompany,
  getLeafCategoriesForCompany,
  updateReceivableDays,
} from '@/api-functions/category.api';
import { ICategoryRelationshipResponse, ICategoryResponse, ILeafCategoriesResponse } from '@/types/category.types';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

const CategoryQueryKeys = {
  getCategoryByCompany: () => ['getCategoryByCompany'],
  getLeafCategoriesForCompany: () => ['getLeafCategoriesForCompany'],
  getCategoryRelationshipsForCompany: () => ['getCategoryRelationshipsForCompany'],
};

export const useGetCategoriesByCompanyQuery = ({
  customConfig,
}: {
  customConfig?: Omit<UseQueryOptions<ICategoryResponse>, 'queryKey'>;
}) => {
  const getCategoryByCompanyQuery = useQuery<ICategoryResponse>({
    queryKey: CategoryQueryKeys.getCategoryByCompany(),
    queryFn: () => getCategoryByCompany(),
    ...customConfig,
    staleTime: Infinity,
  });

  return getCategoryByCompanyQuery;
};

export const useGetLeafCategoriesForCompany = ({
  customConfig,
}: {
  customConfig?: Omit<UseQueryOptions<ILeafCategoriesResponse>, 'queryKey'>;
}) => {
  const getLeafCategoriesForCompanyQuery = useQuery<ILeafCategoriesResponse>({
    queryKey: CategoryQueryKeys.getLeafCategoriesForCompany(),
    queryFn: () => getLeafCategoriesForCompany(),
    ...customConfig,
    staleTime: Infinity,
  });

  return getLeafCategoriesForCompanyQuery;
};

export const useGetCategoryRelationShipsForCompany = ({
  customConfig,
}: {
  customConfig?: Omit<UseQueryOptions<ICategoryRelationshipResponse>, 'queryKey'>;
}) => {
  const getCategoryRelationshipsForCompanyQuery = useQuery<ICategoryRelationshipResponse>({
    queryKey: CategoryQueryKeys.getCategoryRelationshipsForCompany(),
    queryFn: () => getCategoryRelationshipsForCompany(),
    ...customConfig,
    staleTime: Infinity,
  });

  return getCategoryRelationshipsForCompanyQuery;
};

export const usePostUpdateReceivableDays = ({
  categoryId,
  customConfig,
}: {
  categoryId: string;
  customConfig?: UseMutationOptions<unknown, unknown, { receivableDays: number }, unknown>;
}) => {
  const postUpdateReceivableDays = useMutation({
    mutationFn: ({ receivableDays }: { receivableDays: number }) =>
      updateReceivableDays({ categoryId, receivableDays }),
    ...customConfig,
  });

  return postUpdateReceivableDays;
};
