import {
  getPaymentDetails,
  getVPAList,
  postGenerateUpiPaymentLink,
  postGenerateUpiQRCode,
} from '@/api-functions/upi.api';
import {
  IPaymentDetailsResponse,
  IUpiIdQRCodeRequest,
  IUpiIdQRCodeResponse,
  IUpiPaymentLinkRequest,
  IUpiPaymentLinkResponse,
  IVPAListResponse,
} from '@/types/upi.type';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const UpiQueryKeys = {
  getUpiPaymentLink: (qrCode: string, message: string) => ['getUpiPaymentLink', qrCode, message],
  getPaymentDetails: () => ['getPaymentDetails'],
};

export const useGetVPAListMutation = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<IVPAListResponse, AxiosError<IVPAListResponse>>;
}) => {
  const getVPAMutation = useMutation<IVPAListResponse, AxiosError<IVPAListResponse>>({
    mutationFn: getVPAList,
    ...customConfig,
    onSettled(data, error, variables, context) {
      customConfig?.onSettled?.(data, error, variables, context);
    },
  });

  return getVPAMutation;
};

export const useGetUpiQRCodeMutation = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<IUpiIdQRCodeResponse, AxiosError<IUpiIdQRCodeResponse>, IUpiIdQRCodeRequest>;
}) => {
  const getUpiQRCodeMutation = useMutation<IUpiIdQRCodeResponse, AxiosError<IUpiIdQRCodeResponse>, IUpiIdQRCodeRequest>(
    {
      mutationFn: postGenerateUpiQRCode,
      ...customConfig,
      onSettled(data, error, variables, context) {
        customConfig?.onSettled?.(data, error, variables, context);
      },
    },
  );

  return getUpiQRCodeMutation;
};

export const useGetUpiPaymentLink = ({
  request,
  qrCode,
  customConfig,
}: {
  request: IUpiPaymentLinkRequest;
  qrCode: string;
  customConfig?: Omit<UseQueryOptions<IUpiPaymentLinkResponse>, 'queryKey'>;
}) => {
  const getUpiPaymentLinkQuery = useQuery<IUpiPaymentLinkResponse>({
    queryKey: UpiQueryKeys.getUpiPaymentLink(qrCode, request.message),
    queryFn: () => postGenerateUpiPaymentLink({ ...request }),
    ...customConfig,
  });

  return getUpiPaymentLinkQuery;
};

export const useGetPaymentDetails = ({
  paymentId,
  customConfig,
}: {
  paymentId: string;
  customConfig?: Omit<UseQueryOptions<IPaymentDetailsResponse>, 'queryKey'>;
}) => {
  const getPaymentDetailsQuery = useQuery<IPaymentDetailsResponse>({
    queryKey: UpiQueryKeys.getPaymentDetails(),
    queryFn: () => getPaymentDetails({ paymentId }),
    ...customConfig,
  });

  return getPaymentDetailsQuery;
};
