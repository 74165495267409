import { Button } from '@/components/ui/button';
import { useDeleteCreditNoteToInvoicePosting } from '@/hooks/api-hooks/useCreditNotesQuery';
import { IAdjustMents } from '@/types/adjustment.types';
import { Loader2Icon, Trash2Icon } from 'lucide-react';
import { useState } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '../ui/dialog';
import { useToast } from '../ui/use-toast';
import AdjustmentsTable from './Adjustments/AdjustmentsTable';

const DeleteCreditNoteFromInvoiceDetailsDialog = ({
  adjustment,
  postingId,
  invoiceNumber,
}: {
  adjustment: IAdjustMents;
  postingId: string;
  invoiceNumber: string;
}) => {
  const { toast } = useToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { mutate: deleteCredit, isPending } = useDeleteCreditNoteToInvoicePosting({
    creditNoteId: adjustment.id || '',
    invoiceId: adjustment.invoiceId || '',
    customConfig: {
      onError(error) {
        toast({
          title: 'Error',
          description: error.response?.data.message || 'Unable to credit note adjustment',
          variant: 'destructive',
        });
        setIsDeleteDialogOpen(false);
      },
      onSuccess() {
        toast({
          description: 'Credit Note adjustment deleted successfully',
        });
        setIsDeleteDialogOpen(false);
      },
    },
  });

  return (
    <>
      <Button onClick={() => setIsDeleteDialogOpen(true)} size="icon" variant="ghost">
        <Trash2Icon className=" text-destructive w-4 h-4 " />
      </Button>
      {isDeleteDialogOpen && (
        <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
          <DialogContent className="min-w-[600px] bg-white p-4 border-2 rounded-md">
            <DialogHeader className=" font-semibold border-b py-4 ">Delete applied credit note</DialogHeader>
            <AdjustmentsTable invoiceNumber={invoiceNumber} showDelete={false} data={[adjustment]} />
            <p className="text-sm">
              Are you sure you want to delete this posted credit note {invoiceNumber ? `from ${invoiceNumber}` : ''}?
              These changes cannot be reversed.
            </p>
            <DialogFooter className=" border-t py-4 ">
              <Button onClick={() => setIsDeleteDialogOpen(false)} variant="outline">
                Cancel
              </Button>
              <Button
                className=" flex items-center gap-2 "
                disabled={isPending}
                variant="destructive"
                onClick={() =>
                  deleteCredit({
                    postingId,
                  })
                }
              >
                Delete
                {isPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default DeleteCreditNoteFromInvoiceDetailsDialog;
