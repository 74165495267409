import valyxLogo from '@/assets/valyx_logo.svg';
import { PNG_BASE64_PREFIX } from '@/utils/constants';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { Loader2Icon } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export interface UpiQRViewProps {
  message: string;
  qrCode: string;
  vpa: string;
  isQRLoading: boolean;
  isQRError: boolean;
  companyName: string;
  companyLogoUrl: string;
  amount: number;
}

const UpiQRView = ({
  message,
  qrCode,
  vpa,
  companyName,
  companyLogoUrl,
  amount,
  isQRLoading,
  isQRError,
}: UpiQRViewProps) => {
  const [logoLoadError, setLogoLoadError] = useState(false);

  return (
    <div className="w-[380px] bg-[#FAF5FF] relative p-6 pb-16 flex flex-col items-center">
      {companyLogoUrl && !logoLoadError ? (
        <div className="h-6 mb-4">
          <img alt="company logo" className="h-full" src={companyLogoUrl} onError={() => setLogoLoadError(true)} />
        </div>
      ) : (
        <div className="w-full p-1 mb-3 text-xs text-center text-[#9E3DFB] break-words font-medium bg-white rounded">
          {companyName}
        </div>
      )}
      <p className="text-xs mb-6 text-center break-words">{message}</p>
      <div className="w-80 bg-white rounded-lg p-4 flex flex-col items-center justify-between gap-4">
        <p className="text-xs text-center text-[#9E3DFB] break-words font-medium">{companyName}</p>
        <div className="w-64 h-64 rounded-md border-2 border-[#E9D4FF] flex justify-center items-center">
          {isQRLoading && <Loader2Icon className="w-6 h-6 animate-spin" />}
          {!isQRLoading && !isQRError && qrCode && (
            <img alt="qr code" src={`${PNG_BASE64_PREFIX}${qrCode}`} className="w-full h-full" />
          )}
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-xs text-center break-all">UPI ID: {vpa}</p>
          {!!amount && (
            <p className="text-xs text-center break-all">Amount: {formatCurrencyByUnit(amount, 'actual')}</p>
          )}
        </div>
      </div>
      <div className="w-full absolute left-0 bottom-[12px] flex justify-center items-center text-xs">
        <div className="h-5 w-5 mr-2">
          <img alt="valyx logo" src={valyxLogo} className="w-full h-full" />
        </div>
        Powered by
        <Link target="_blank" to="https://www.valyx.com" className="text-[#9E3DFB] ml-1">
          www.valyx.com
        </Link>
      </div>
      <div className="w-full h-[4px] bg-[#E9D4FF] absolute left-0 bottom-[4px]"></div>
      <div className="w-full h-[4px] bg-[#C081FF] absolute left-0 bottom-0"></div>
    </div>
  );
};

export default UpiQRView;
