import BankIdToName from '@/components/shared/BankIdToName';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import { useDeleteCreditNoteToInvoicePosting } from '@/hooks/api-hooks/useCreditNotesQuery';
import { usePostUpdateInvoiceInlineMutation } from '@/hooks/api-hooks/useInvoiceQuery';
import { usePostCreateBulkInvoicePosting, usePostDeletePosting } from '@/hooks/api-hooks/usePostingQuery';
import { useCreateWriteOffMutation } from '@/hooks/api-hooks/useWriteOffQuery';
import { cn } from '@/lib/utils';
import { IInvoice } from '@/types/invoices.types';
import {
  IBatToInvoicePostingRequest,
  ICreditNotePosting,
  ICreditNoteToInvoicePostingRequest,
  ICreditPosting,
  IPlaceholderToInvoicePostingRequest,
  IPostingFormDetails,
} from '@/types/posting.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { toFixed } from '@/utils/toFixed';
import { Loader2Icon, Trash2Icon, XIcon } from 'lucide-react';
import React, { SetStateAction, useEffect, useState } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormStateReturn,
  UseFormWatch,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';

const CreditPostingItem = ({
  credit,
  register,
  index,
  error,
  watch,
}: {
  credit: ICreditPosting;
  register: UseFormRegister<IPostingFormDetails>;
  index: number;
  error?: Merge<FieldError, FieldErrorsImpl<ICreditPosting>>;
  watch: UseFormWatch<IPostingFormDetails>;
}) => {
  const postedAmountString = watch(`credits.${index}.postedAmount`);
  const postedAmount = postedAmountString ? Number(postedAmountString) : 0;

  const { toast } = useToast();

  const { invoiceId } = useParams();

  const { mutate: deletePosting } = usePostDeletePosting({
    creditId: credit.transactionId,
    invoiceId: invoiceId as string,
    customConfig: {
      onSuccess() {
        toast({
          description: 'Posting deleted successfully',
        });
      },
      onError(error) {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to delete posting',
        });
      },
    },
  });

  return (
    <div className=" border-b pb-2 ">
      <div className=" flex items-center gap-2 ">
        <h3 className=" w-9/10 ">
          <ToolTipCell
            value={
              <>
                {index + 1}. <BankIdToName bankId={credit.bankAccountId} />{' '}
              </>
            }
          />
        </h3>
        {!!credit.isDefaultSelected && (
          <Button
            onClick={() => deletePosting({ postingId: credit.postingId || '' })}
            variant="ghost"
            size="icon"
            className=" text-destructive flex-1 "
          >
            <Trash2Icon className="w-4 h-4" />
          </Button>
        )}
      </div>
      <p className=" text-muted-foreground text-ellipsis">
        <ToolTipCell value={credit.narration} />
      </p>
      <div className=" flex items-center gap-2 ">
        <div className=" relative bottom-3 ">₹</div>
        <div className=" flex-1 ">
          <Input
            {...register(`credits.${index}.postedAmount`, {
              required: {
                value: true,
                message: 'Amount is required',
              },
              pattern: {
                value: /^\d*\.?\d*$/i,
                message: 'Invalid amount',
              },
              min: {
                value: 0.0000001,
                message: `Amount must be greater than ${formatCurrencyByUnit(0, 'actual')}`,
              },
              max: {
                value: credit.remainingAmount + credit.alreadyPostedAmount,
                message: `Amount must be less than ${formatCurrencyByUnit(credit.remainingAmount + credit.alreadyPostedAmount, 'actual')}`,
              },
            })}
            className=" my-2 text-sm "
            placeholder="Enter posting amount"
          />
          <p className=" text-xs text-destructive h-6 ">{error?.postedAmount?.message}</p>
        </div>
      </div>
      {toFixed(credit.remainingAmount - postedAmount + credit.alreadyPostedAmount, 2) > 0 && (
        <p className="  text-muted-foreground">
          <span
            className={cn(
              toFixed(credit.remainingAmount - postedAmount + credit.alreadyPostedAmount, 2) > 0 && '',
              toFixed(credit.remainingAmount - postedAmount + credit.alreadyPostedAmount, 2) < 0 && 'text-destructive',
            )}
          >
            {formatCurrencyByUnit(credit.remainingAmount - postedAmount + credit.alreadyPostedAmount, 'actual')}
          </span>{' '}
          available for posting{' '}
        </p>
      )}
    </div>
  );
};

const CreditNotePostingItem = ({
  creditNote,
  register,
  index,
  error,
  watch,
}: {
  creditNote: ICreditNotePosting;
  register: UseFormRegister<IPostingFormDetails>;
  index: number;
  error?: Merge<FieldError, FieldErrorsImpl<ICreditNotePosting>>;
  watch: UseFormWatch<IPostingFormDetails>;
}) => {
  const postedAmountString = watch(`creditNotes.${index}.postedAmount`);
  const postedAmount = postedAmountString ? Number(postedAmountString) : 0;

  const { toast } = useToast();

  const { invoiceId } = useParams();

  const { mutate: deletePosting } = useDeleteCreditNoteToInvoicePosting({
    invoiceId: invoiceId as string,
    creditNoteId: creditNote.creditNoteId,
    customConfig: {
      onSuccess() {
        toast({
          description: 'Posting deleted successfully',
        });
      },
      onError(error) {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to delete posting',
        });
      },
    },
  });

  return (
    <div className=" border-b pb-2 text-sm ">
      <div className=" flex items-center gap-2 ">
        <h3 className=" w-9/10 ">
          <ToolTipCell
            value={
              <>
                {index + 1}. {creditNote.creditNoteNumber}{' '}
              </>
            }
          />
        </h3>
        {!!creditNote.isDefaultSelected && (
          <Button
            onClick={() => deletePosting({ postingId: creditNote.postingId || '' })}
            variant="ghost"
            size="icon"
            className=" text-destructive flex-1 "
          >
            <Trash2Icon className="w-4 h-4" />
          </Button>
        )}
      </div>
      {!!creditNote.creditNoteDescription && (
        <p className=" text-muted-foreground text-ellipsis">
          <ToolTipCell value={creditNote.creditNoteDescription} />
        </p>
      )}
      <div className=" flex items-center gap-2 ">
        <div className=" relative bottom-3 ">₹</div>
        <div className=" flex-1 ">
          <Input
            {...register(`creditNotes.${index}.postedAmount`, {
              required: {
                value: true,
                message: 'Amount is required',
              },
              pattern: {
                value: /^\d*\.?\d*$/i,
                message: 'Invalid amount',
              },
              min: {
                value: 0.0000001,
                message: `Amount must be greater than ${formatCurrencyByUnit(0, 'actual')}`,
              },
              max: {
                value: creditNote.creditNoteRemainingAmount + creditNote.alreadyPostedAmount,
                message: `Amount must be less than ${formatCurrencyByUnit(creditNote.creditNoteRemainingAmount + creditNote.alreadyPostedAmount, 'actual')}`,
              },
            })}
            className=" my-2 text-sm "
            placeholder="Enter posting amount"
          />
          <p className=" text-xs text-destructive h-6 ">{error?.postedAmount?.message}</p>
        </div>
      </div>
      {toFixed(creditNote.creditNoteRemainingAmount - postedAmount + creditNote.alreadyPostedAmount, 2) > 0 && (
        <p className="  text-muted-foreground">
          <span
            className={cn(
              toFixed(creditNote.creditNoteRemainingAmount - postedAmount + creditNote.alreadyPostedAmount, 2) > 0 &&
                '',
              toFixed(creditNote.creditNoteRemainingAmount - postedAmount + creditNote.alreadyPostedAmount, 2) < 0 &&
                'text-destructive',
            )}
          >
            {formatCurrencyByUnit(
              creditNote.creditNoteRemainingAmount - postedAmount + creditNote.alreadyPostedAmount,
              'actual',
            )}
          </span>{' '}
          available for posting{' '}
        </p>
      )}
    </div>
  );
};

const RoundOffAmount = ({
  isRoundOffEnabled,
  isRoundOffAlreadyPosted,
  postingId,
  register,
  errorMessage,
  remainingAmount,
  setValue,
  placeholderId,
  setIsRoundOffEnabled,
  setRoundOffType,
}: {
  isRoundOffEnabled: boolean;
  isRoundOffAlreadyPosted: boolean;
  postingId?: string;
  register: UseFormRegister<IPostingFormDetails>;
  errorMessage?: string;
  remainingAmount: number;
  setValue: UseFormSetValue<IPostingFormDetails>;
  placeholderId?: string;
  setIsRoundOffEnabled: React.Dispatch<SetStateAction<boolean>>;
  setRoundOffType: React.Dispatch<SetStateAction<'loss' | 'gain'>>;
}) => {
  const { toast } = useToast();

  const { invoiceId } = useParams();

  const { mutate: deletePosting, isPending } = usePostDeletePosting({
    invoiceId: invoiceId as string,
    customConfig: {
      onSuccess() {
        toast({
          description: 'Posting deleted successfully',
        });
        setIsRoundOffEnabled(false);
      },
      onError(error) {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to delete posting',
        });
      },
    },
  });

  const handleRemoveRoundOff = () => {
    setValue('roundOffAmount', 0, {
      shouldDirty: true,
    });
    setIsRoundOffEnabled(false);
  };

  const handleDeleteRoundOff = () => {
    if (postingId) {
      deletePosting({
        postingId,
      });
    }
  };

  if (!placeholderId) {
    return null;
  }

  if (isRoundOffEnabled) {
    return (
      <div className=" px-4 ">
        <div className=" flex items-center justify-between ">
          Round off amount
          {isRoundOffAlreadyPosted && !!postingId && (
            <Button onClick={handleDeleteRoundOff} loading={isPending} variant="ghost" size="icon">
              <Trash2Icon className="h-4 w-4 text-destructive" />
            </Button>
          )}
          {!isRoundOffAlreadyPosted && (
            <Button onClick={handleRemoveRoundOff} loading={isPending} variant="ghost" size="icon">
              <XIcon className="h-4 w-4" />
            </Button>
          )}
        </div>
        <div className=" flex items-center gap-2 ">
          <div className=" relative bottom-3 ">₹</div>
          <div className=" flex-1 ">
            <Input
              {...register('roundOffAmount', {
                required: {
                  value: true,
                  message: 'Amount is required',
                },
                pattern: {
                  value: /^\d*\.?\d*$/i,
                  message: 'Invalid amount',
                },
                max: {
                  value: 5,
                  message: `We only support rounding off upto ${formatCurrencyByUnit(5, 'actual')}`,
                },
                min: {
                  value: 0.0000001,
                  message: `Amount must be greater than ${formatCurrencyByUnit(0, 'actual')}`,
                },
              })}
              className=" my-2 text-sm "
              placeholder="Enter round off amount"
            />
            <p className=" text-destructive h-6 text-xs ">{errorMessage}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    remainingAmount !== 0 && (
      <Button
        onClick={() => {
          setValue('roundOffAmount', Math.abs(Math.min(Number(remainingAmount.toFixed(2)), 5)), {
            shouldDirty: true,
          });
          setIsRoundOffEnabled(true);
          setRoundOffType(remainingAmount > 0 ? 'loss' : 'gain');
        }}
        variant="ghost"
        className=" text-blue-700 "
      >
        {remainingAmount > 0 ? 'Add Round Off Loss' : 'Add Round Off Gain'}
      </Button>
    )
  );
};

const SelectedItems = ({
  postedCredits,
  postedCreditNotes,
  watch,
  setValue,
  getValues,
  handleFormSubmit,
  errors,
  register,
  isDirty,
  invoice,
  isTdsAlreadyPresent,
  isRoundOffAmountAlreadyPosted,
  alreadyPresentTds = 0,
  isLoading,
  roundOffPlaceholderId,
  roundOffPlaceholderPostingId,
  alreadyRoundOffAmount,
}: {
  postedCredits: ICreditPosting[];
  postedCreditNotes: ICreditNotePosting[];
  watch: UseFormWatch<IPostingFormDetails>;
  setValue: UseFormSetValue<IPostingFormDetails>;
  getValues: UseFormGetValues<IPostingFormDetails>;
  handleFormSubmit: UseFormHandleSubmit<IPostingFormDetails>;
  errors: UseFormStateReturn<IPostingFormDetails>['errors'];
  register: UseFormRegister<IPostingFormDetails>;
  isDirty: boolean;
  reset: UseFormReset<IPostingFormDetails>;
  invoice: IInvoice;
  isTdsAlreadyPresent: boolean;
  isRoundOffAmountAlreadyPosted: boolean;
  alreadyPresentTds?: number;
  isLoading: boolean;
  roundOffPlaceholderPostingId?: string;
  roundOffPlaceholderId?: string;
  alreadyRoundOffAmount: number;
}) => {
  const [isWriteOffEnabled, setIsWriteOffEnabled] = useState(false);
  const [isTdsEnabled, setIsTdsEnabled] = useState(false);
  const [isRoundOffEnabled, setIsRoundOffEnabled] = useState(false);
  const [roundOffType, setRoundOffType] = useState<'loss' | 'gain'>('loss');
  const roundOffAmount = watch('roundOffAmount');

  const { invoiceId } = useParams();

  useEffect(() => {
    if (alreadyRoundOffAmount > 0) {
      setRoundOffType('loss');
    } else {
      setRoundOffType('gain');
    }
  }, [alreadyRoundOffAmount]);

  useEffect(() => {
    if (isRoundOffAmountAlreadyPosted) {
      setIsRoundOffEnabled(isRoundOffAmountAlreadyPosted);
    }
  }, [isRoundOffAmountAlreadyPosted]);

  const { toast } = useToast();

  const {
    mutateAsync: updateInvoiceAsync,
    mutate: updateInvoice,
    isPending: isUpdateInvoicePending,
  } = usePostUpdateInvoiceInlineMutation({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to update tds for the invoice',
        });
      },
    },
  });

  const { mutate: createPosting, isPending } = usePostCreateBulkInvoicePosting({
    invoiceId: invoice.id,
    customConfig: {
      onSuccess() {
        toast({
          description: 'Invoice posting created successfully',
        });
      },
      onError(error) {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to create invoice posting',
        });
      },
    },
  });

  const { mutate: createWriteOff } = useCreateWriteOffMutation({
    invoiceId: invoice.id,
    approvalStatusList: ['PENDING'],
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to create write off',
        });
      },
    },
  });
  const onSubmit = async (data: IPostingFormDetails) => {
    const batToInvoicePosting: IBatToInvoicePostingRequest[] = data.credits.map((credit) => ({
      bankAccountTransactionId: credit.transactionId,
      postedAmount: Number(credit.postedAmount),
      invoiceId: invoice.id,
      customerId: invoice.customerId,
    }));

    const creditNoteToInvoicePosting: ICreditNoteToInvoicePostingRequest[] = data.creditNotes.map((creditNote) => ({
      creditNoteId: creditNote.identifierId,
      customerId: invoice.customerId,
      invoiceId: invoice.id,
      postedAmount: Number(creditNote.postedAmount),
    }));

    let placeholderPostings: IPlaceholderToInvoicePostingRequest[] = [];

    if (isRoundOffEnabled && roundOffAmount !== 0 && roundOffPlaceholderId) {
      placeholderPostings = [
        ...placeholderPostings,
        {
          invoiceId: invoiceId as string,
          placeholderId: roundOffPlaceholderId,
          postedAmount: (roundOffType === 'loss' ? 1 : -1) * Number(roundOffAmount),
          customerId: invoice.customerId,
        },
      ];
    }

    if (data.adjustedAmount) {
      if (isWriteOff) {
        createWriteOff({
          amount: Number(data.adjustedAmount),
          comment: data.writeOffComment,
          invoiceId: invoice.id,
        });
      } else {
        await updateInvoiceAsync({
          invoiceId: invoice.id,
          fields: ['invoiceAdjustedAmount'],
          updateObject: {
            invoiceAdjustedAmount:
              Number(invoice.invoiceAdjustedAmount) +
              Number(data.isWriteOff ? Number(data.adjustedAmount) : -1 * Number(data.adjustedAmount)),
          },
        });
      }
    }

    await updateInvoiceAsync({
      invoiceId: invoice.id,
      fields: ['invoiceTax'],
      updateObject: {
        invoiceTax: {
          cgst: invoice.invoiceTax.cgst,
          sgst: invoice.invoiceTax.sgst,
          igst: invoice.invoiceTax.igst,
          tds: Number(data.tdsAmount),
          total: invoice.invoiceTax.total,
        },
      },
    });

    createPosting({
      request: {
        bankAccountTransactionsPostings: batToInvoicePosting,
        creditNotePostings: creditNoteToInvoicePosting,
        placeholderPostings: placeholderPostings,
      },
    });

    setIsWriteOffEnabled(false);
  };

  const credits = watch('credits');
  const creditNotes = watch('creditNotes');

  const alreadyPostedAmount =
    postedCredits.reduce((acc, curr) => {
      if (!isNaN(Number(curr.alreadyPostedAmount))) {
        return acc + Number(curr.alreadyPostedAmount);
      }
      return acc;
    }, 0) +
    postedCreditNotes.reduce((acc, curr) => {
      if (!isNaN(Number(curr.alreadyPostedAmount))) {
        return acc + Number(curr.alreadyPostedAmount);
      }
      return acc;
    }, 0);

  const remainingAmount = toFixed(
    invoice.invoiceOutstandingAmount -
      credits.reduce((acc, curr) => {
        if (!isNaN(Number(curr.postedAmount))) {
          return acc + Number(curr.postedAmount);
        }
        return acc;
      }, 0) +
      alreadyPostedAmount -
      Number(watch('adjustedAmount')) -
      Number(watch('tdsAmount')) +
      alreadyPresentTds -
      (roundOffType === 'loss' ? 1 : -1) * Number(watch('roundOffAmount')) +
      alreadyRoundOffAmount -
      creditNotes.reduce((acc, curr) => {
        if (!isNaN(Number(curr.postedAmount))) {
          return acc + Number(curr.postedAmount);
        }
        return acc;
      }, 0),
    2,
  );

  useEffect(() => {
    setValue('isWriteOff', remainingAmount > 0);
  }, [remainingAmount, setValue]);

  useEffect(() => {
    if (isTdsAlreadyPresent) {
      setIsTdsEnabled(true);
    }
  }, [isTdsAlreadyPresent]);

  const isWriteOff = getValues('isWriteOff');

  const handleCloseTds = () => {
    setIsTdsEnabled(false);
    setValue('tdsAmount', 0, {
      shouldDirty: true,
    });
  };

  const handleRemoveTds = () => {
    setValue('tdsAmount', 0);
    updateInvoice({
      invoiceId: invoice.id,
      fields: ['invoiceTax'],
      updateObject: {
        invoiceTax: {
          cgst: invoice.invoiceTax.cgst,
          sgst: invoice.invoiceTax.sgst,
          igst: invoice.invoiceTax.igst,
          tds: 0,
          total: invoice.invoiceTax.total,
        },
      },
    });
    setIsTdsEnabled(false);
  };

  if (isLoading) {
    return (
      <div className=" flex flex-col text-sm h-full">
        <div className={cn(' py-2 px-4 flex justify-between border-b items-center  ')}>
          <h2 className=" font-semibold text-base"> Post Invoice</h2>
        </div>
        <div className=" px-4 flex flex-col gap-3 py-4 ">
          {new Array(4).fill(0).map((_, index) => (
            <div key={index + 'skeleton-loading-selected-items'} className=" flex flex-col gap-1 ">
              <Skeleton className=" h-4 w-32 " />
              <Skeleton className=" h-4 w-full " />
              <Skeleton className=" h-5 w-full " />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className=" flex flex-col text-sm h-full">
      <div className={cn(' py-2 px-4 flex justify-between border-b items-center  ')}>
        <h2 className=" font-semibold text-base"> Post Invoice</h2>
      </div>
      <form
        onSubmit={handleFormSubmit(onSubmit)}
        id="posting-form"
        className=" my-2 flex flex-col gap-4 flex-1 overflow-scroll "
      >
        {postedCreditNotes.length > 0 && (
          <div>
            <h3 className=" border-l-4 border-black px-2 my-2  font-semibold ">Credit Notes</h3>
            <div className=" px-4 flex flex-col gap-3 ">
              {postedCreditNotes.map((creditNote, index) => (
                <CreditNotePostingItem
                  watch={watch}
                  key={creditNote.id}
                  creditNote={creditNote}
                  index={index}
                  error={errors?.creditNotes?.[index]}
                  register={register}
                />
              ))}
            </div>
          </div>
        )}
        {postedCredits.length > 0 && (
          <div>
            <h3 className=" border-l-4 border-black px-2 my-2  font-semibold ">Credits</h3>
            <div className=" px-4 flex flex-col gap-3 ">
              {postedCredits.map((credit, index) => (
                <CreditPostingItem
                  watch={watch}
                  key={credit.id}
                  credit={credit}
                  index={index}
                  error={errors?.credits?.[index]}
                  register={register}
                />
              ))}
            </div>
          </div>
        )}
        {postedCreditNotes.length === 0 && postedCredits.length === 0 && (
          <div className=" text-center font-semibold py-4 text-xs text-muted-foreground">
            No credits or credit notes to post
          </div>
        )}

        <div className=" px-2 ">
          {isTdsEnabled ? (
            <div className=" px-4 ">
              <div className=" flex items-center justify-between ">
                TDS
                {isTdsAlreadyPresent && (
                  <Button onClick={handleRemoveTds} variant="ghost" size="icon">
                    <Trash2Icon className="h-4 w-4 text-destructive" />
                  </Button>
                )}
                {!isTdsAlreadyPresent && (
                  <Button onClick={handleCloseTds} variant="ghost" size="icon">
                    <XIcon className="h-4 w-4" />
                  </Button>
                )}
              </div>
              <div className=" flex items-center gap-2 ">
                <div className=" relative bottom-3 ">₹</div>
                <div className=" flex-1 ">
                  <Input
                    {...register('tdsAmount', {
                      required: {
                        value: true,
                        message: 'Amount is required',
                      },
                      pattern: {
                        value: /^\d*\.?\d*$/i,
                        message: 'Invalid amount',
                      },

                      min: {
                        value: 0.0000001,
                        message: `Amount must be greater than ${formatCurrencyByUnit(0, 'actual')}`,
                      },
                    })}
                    className=" my-2 text-sm "
                    placeholder={`Enter TDS amount`}
                  />
                  <p className=" text-destructive h-6 text-xs ">{errors.tdsAmount?.message}</p>
                </div>
              </div>
            </div>
          ) : (
            <Button
              onClick={() => {
                setIsTdsEnabled(true);
                setValue('tdsAmount', Number(remainingAmount.toFixed(2)));
              }}
              variant="ghost"
              className=" text-blue-700 "
            >
              Add TDS
            </Button>
          )}

          <RoundOffAmount
            setIsRoundOffEnabled={setIsRoundOffEnabled}
            isRoundOffAlreadyPosted={isRoundOffAmountAlreadyPosted}
            isRoundOffEnabled={isRoundOffEnabled}
            postingId={roundOffPlaceholderPostingId}
            register={register}
            remainingAmount={remainingAmount}
            setValue={setValue}
            placeholderId={roundOffPlaceholderId}
            errorMessage={errors.roundOffAmount?.message}
            setRoundOffType={setRoundOffType}
          />
        </div>
      </form>

      <div className=" border-t px-4 py-2 flex flex-col gap-1  ">
        <h4 className=" text-muted-foreground ">Due/Overdue</h4>
        <div className=" flex items-center justify-between ">
          <span
            className={cn(
              ' font-semibold ',
              remainingAmount > 0 && ' text-destructive',
              remainingAmount < 0 && ' text-green-700',
            )}
          >
            {formatCurrencyByUnit(remainingAmount, 'actual')}
          </span>
          {!isWriteOffEnabled && remainingAmount !== 0 && (
            <Button
              onClick={() => {
                setIsWriteOffEnabled(true);
                setValue('isWriteOff', remainingAmount > 0);
                setValue('adjustedAmount', Math.abs(remainingAmount));
              }}
              variant="ghost"
              className=" text-blue-700 "
            >
              {remainingAmount > 0 ? 'Write off' : 'One time gain'}
            </Button>
          )}
          {isWriteOffEnabled && (
            <Button
              onClick={() => {
                setValue('isWriteOff', false);
                setValue('adjustedAmount', 0);
                setIsWriteOffEnabled(false);
              }}
              size="icon"
              variant="ghost"
            >
              <XIcon className=" h-4 w-4" />
            </Button>
          )}
        </div>
        {isWriteOffEnabled &&
          (isWriteOff ? (
            <div>
              <p>Write off</p>
              <div className=" flex items-center gap-2 ">
                <div className=" relative bottom-3 ">₹</div>
                <div className=" flex-1 ">
                  <Input
                    {...register('adjustedAmount', {
                      required: {
                        value: true,
                        message: 'Amount is required',
                      },
                      pattern: {
                        value: /^\d*\.?\d*$/i,
                        message: 'Invalid amount',
                      },

                      min: {
                        value: 0.0000001,
                        message: `Amount must be greater than ${formatCurrencyByUnit(0, 'actual')}`,
                      },
                    })}
                    className=" my-2 text-sm "
                    placeholder={`Enter adjusted amount`}
                  />
                  <p className=" text-destructive h-6 text-xs ">{errors.adjustedAmount?.message}</p>
                </div>
              </div>
              <div>
                <Textarea
                  placeholder="Enter comment"
                  {...register('writeOffComment', {
                    required: {
                      value: true,
                      message: 'Comment is required',
                    },
                    minLength: {
                      value: 3,
                      message: 'Comment must be at least 3 characters',
                    },
                  })}
                />
                <p className=" text-destructive h-6 text-xs ">{errors.writeOffComment?.message}</p>
              </div>
            </div>
          ) : (
            <div>
              <p>One time gain</p>
              <div className=" flex items-center gap-2 ">
                <div className=" relative bottom-3 ">₹</div>
                <div className=" flex-1 ">
                  <Input
                    {...register('adjustedAmount', {
                      required: {
                        value: true,
                        message: 'Amount is required',
                      },
                      pattern: {
                        value: /^\d*\.?\d*$/i,
                        message: 'Invalid amount',
                      },

                      min: {
                        value: 0.0000001,
                        message: `Amount must be greater than ${formatCurrencyByUnit(0, 'actual')}`,
                      },
                    })}
                    className=" my-2 text-sm "
                    placeholder={`Enter adjusted amount`}
                  />
                  <p className=" text-destructive h-6 text-xs ">{errors.adjustedAmount?.message}</p>
                </div>
              </div>
            </div>
          ))}
        <div className=" flex items-center justify-end ">
          <Button disabled={!isDirty || isPending || isUpdateInvoicePending} form="posting-form" type="submit">
            Post
            {isPending || (isUpdateInvoicePending && <Loader2Icon className="w-4 h-4 animate-spin" />)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectedItems;
