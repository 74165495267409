import { Button } from '@/components/ui/button';
import {
  useDeleteCustomerTag,
  useGetCustomerTagNameSuggestions,
  useGetTagValueSuggestionsForGivenKey,
  usePostCreateTagForCustomer,
} from '@/hooks/api-hooks/useTagsQuery';
import { IOptions } from '@/types/common.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import debounce from 'lodash.debounce';
import { EditIcon, PlusIcon, Trash2Icon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { GroupBase, OptionsOrGroups } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import type Select from 'react-select/dist/declarations/src/Select';
import { Card, CardContent, CardHeaderWithCTA, CardTitle } from '../ui/card';
import { Input } from '../ui/react-select/input';
import { useToast } from '../ui/use-toast';

interface ITag {
  id: string;
  name: string;
  value: string;
}

const TagDetailsRow = ({
  tag,
  handleSave,
  isCreate = false,
  handleDelete,
  setIsCreate,
}: {
  tag: {
    id: string;
    name: string;
    value: string;
  };
  handleSave: (_: ITag) => void;
  isCreate?: boolean;
  handleDelete: (_: string) => void;
  setIsCreate?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isEditing, setIsEditing] = useState(false || isCreate);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('tagKey') === tag.name) {
      setIsEditing(true);
      setSearchParams(
        (prev) => {
          prev.delete('tagKey');
          return prev;
        },
        {
          replace: true,
        },
      );
    }
  }, [searchParams, tag.name, setSearchParams]);

  const { mutateAsync: fetchTagNames } = useGetCustomerTagNameSuggestions({});

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
  } = useForm<ITag>({
    defaultValues: {
      ...tag,
    },
  });

  useEffect(() => {
    reset(tag);
  }, [tag, reset]);

  const tagName = watch('name');

  const { data: tagValueSuggestions } = useGetTagValueSuggestionsForGivenKey({
    key: tagName,
    customConfig: {},
  });

  const tagValueOptions = useMemo(() => {
    if (tagValueSuggestions?.data) {
      return tagValueSuggestions.data
        .map((item) => ({
          value: item.tagValue || '',
          label: item.tagValue || '',
        }))
        .filter((item) => Boolean(item.value));
    }
    return [];
  }, [tagValueSuggestions]);

  const handleCancel = () => {
    reset(tag);
    setIsEditing(false);
    setIsCreate?.(false);
  };
  const tagValueInputRef = useRef<Select<{ value: string; label: string }, false, GroupBase<IOptions>>>(null);

  const onSubmit = (data: ITag) => {
    handleSave(data);
  };

  const loadTagNames = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IOptions, GroupBase<IOptions>>) => void) => {
      fetchTagNames({
        query: inputValue.trim(),
      }).then((result) => {
        const options = result.data
          .map((item) => ({
            value: item.tagKey || '',
            label: item.tagKey || '',
          }))
          .filter((item) => Boolean(item.value));

        callback(options);
      });
      return;
    },
    [fetchTagNames],
  );

  const debouncedLoadTagNames = useMemo(() => {
    return debounce(loadTagNames, 500);
  }, [loadTagNames]);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false, IOptions>();
  }, []);

  return !isEditing ? (
    <div className=" flex gap-4 items-center ">
      <p className=" font-semibold min-w-[120px] text-sm ">{tag.name}:</p>
      <p className=" text-sm flex-1 ">{tag.value}</p>
      <Button variant="outline" size="icon" onClick={() => setIsEditing(true)}>
        <EditIcon className=" h-4 w-4 " />
      </Button>
      <Button variant="outline" onClick={() => handleDelete(tag.name)}>
        <Trash2Icon className="h-4 w-4" />
      </Button>
    </div>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full flex items-start gap-2 ">
      <div>
        <Controller
          control={control}
          name="name"
          rules={{
            required: {
              value: true,
              message: 'Tag name is required',
            },
            minLength: {
              value: 1,
              message: 'Tag name should have atleast 3 characters',
            },
            maxLength: {
              value: 25,
              message: "Tag name shouldn't have more than 25 characters",
            },
          }}
          render={({ field: { value, onChange } }) => (
            <AsyncCreatableSelect
              autoFocus={isCreate}
              isDisabled={!isCreate}
              isMulti={false}
              placeholder="Enter tag name"
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Input: Input }}
              onChange={(item) => {
                onChange(item?.value || '');
                setTimeout(() => {
                  tagValueInputRef.current?.focus();
                });
              }}
              inputValue={value}
              onInputChange={(newValue, action) => {
                if (action.action === 'input-change') {
                  onChange(newValue);
                }
              }}
              value={
                value?.trim()
                  ? {
                      value: value,
                      label: value,
                    }
                  : {
                      value: '',
                      label: '',
                    }
              }
              loadOptions={debouncedLoadTagNames}
              className=" min-w-[200px] text-sm shadow-sm"
              styles={selectStyles}
              classNames={selectClasses}
            />
          )}
        />
        <p className=" text-red-500 text-[10px]">{!!errors.name?.message && errors.name.message}</p>
      </div>
      <div className="flex-1">
        <Controller
          control={control}
          name="value"
          rules={{
            required: {
              value: true,
              message: 'Tag value is required',
            },
            minLength: {
              value: 1,
              message: 'Tag value should have atleast 1 characters',
            },
            maxLength: {
              value: 50,
              message: "Tag value shouldn't have more than 50 characters",
            },
          }}
          render={({ field: { value, onChange } }) => (
            <CreatableSelect
              ref={tagValueInputRef}
              autoFocus={!isCreate}
              isDisabled={!tagName.trim()}
              isMulti={false}
              inputValue={value}
              onInputChange={(newValue, action) => {
                if (action.action === 'input-change') {
                  onChange(newValue);
                }
              }}
              placeholder="Enter tag value"
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Input: Input }}
              onChange={(item) => {
                onChange(item?.value || undefined);
              }}
              value={
                value.trim()
                  ? {
                      value: value,
                      label: value,
                    }
                  : {
                      value: '',
                      label: '',
                    }
              }
              options={tagValueOptions}
              className=" min-w-[200px] text-sm shadow-sm"
              styles={selectStyles}
              classNames={selectClasses}
            />
          )}
        />
        <p className=" text-red-500 text-[10px]">{!!errors.value?.message && errors.value.message}</p>
      </div>
      <Button type="submit">Save</Button>
      <Button onClick={handleCancel} variant="outline">
        Cancel
      </Button>
    </form>
  );
};

const TagsDetails = ({
  tagDetails,
  customerId,
}: {
  tagDetails: { id: string; name: string; value: string }[];
  customerId: string;
}) => {
  const [isCreate, setIsCreate] = useState(false);
  const [tagsArray, setTagsArray] = useState(tagDetails);
  const { toast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate: deleteTag } = useDeleteCustomerTag({
    customerId,
    customConfig: {
      onError() {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Unable to delete tag',
        });
      },
    },
  });

  const handleDelete = (id: string) => {
    deleteTag({ tagKey: id });
  };

  useEffect(() => {
    setTagsArray(tagDetails);
  }, [tagDetails]);

  useEffect(() => {
    if (searchParams.get('scrollTo') === 'tags') {
      document.querySelector('#tags')?.scrollIntoView({ behavior: 'smooth' });
      setSearchParams(
        (prev) => {
          prev.delete('scrollTo');
          return prev;
        },
        {
          replace: true,
        },
      );
    }
    if (searchParams.get('tagKey') === 'create_new') {
      setIsCreate(true);
      setSearchParams(
        (prev) => {
          prev.delete('tagKey');
          return prev;
        },
        {
          replace: true,
        },
      );
    }
  }, [searchParams, setSearchParams]);

  const { mutate: createTag } = usePostCreateTagForCustomer({
    customerId: customerId,
    customConfig: {
      onError(error) {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to create tag',
        });
      },
      onSettled: () => {
        setIsCreate(false);
      },
    },
  });

  const handleSave = (data: ITag) => {
    if (data.id === 'NEW') {
      createTag({
        tagKey: data.name,
        tagValue: data.value,
      });
      return;
    }
    createTag({
      tagKey: data.name.trim(),
      tagValue: data.value.trim(),
    });
  };

  return (
    <Card id="tags" className=" my-8 ">
      <CardHeaderWithCTA
        className=" border-b "
        cardTitle={<CardTitle className=" underline font-semibold ">Tags</CardTitle>}
        cta={
          !isCreate && (
            <Button className="flex gap-4 items-center" onClick={() => setIsCreate((prev) => !prev)}>
              Add Tag
              <PlusIcon className=" w-4 h-4 " />
            </Button>
          )
        }
      />
      <CardContent className=" flex flex-col gap-4 p-6 ">
        <div className=" flex flex-col gap-4 ">
          {!isCreate && !tagsArray.length && <p className="text-sm text-center">No tags created for the customer</p>}
          {tagsArray.map((tag) => (
            <TagDetailsRow handleDelete={handleDelete} handleSave={handleSave} key={tag.name + tag.value} tag={tag} />
          ))}
          {isCreate && (
            <TagDetailsRow
              handleDelete={handleDelete}
              handleSave={handleSave}
              setIsCreate={setIsCreate}
              isCreate
              tag={{
                id: 'NEW',
                name: '',
                value: '',
              }}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default TagsDetails;
