import {
  getInvoiceById,
  getInvoicesByFilters,
  getInvoicesSummaryByFilters,
  getPromiseToPayReasonList,
  getPromiseToPayReasonRequired,
  postBulkPromiseToPayUpdate,
  postUpdateInvoice,
  uploadInvoice,
} from '@/api-functions/invoices.api';
import { IApiResponse } from '@/types/common.types';
import {
  IBulkPromiseToPayUpdateRequest,
  IBulkPromiseToPayUpdateResponse,
  IComments,
  IFilteredInvoiceResponse,
  IInvoice,
  IInvoiceFilteredSummaryResponse,
  IInvoiceResponse,
  IInvoiceUploadResponse,
  IInvoicesFilter,
  IPaymentStatus,
  IPromiseToPayReasonListResponse,
  IPromiseToPayReasonRequiredResponse,
} from '@/types/invoices.types';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const InvoiceQueryKeys = {
  getInvoicesSuggestionsByInvoiceNumber: ({ invoiceNumber }: { invoiceNumber: string }) => [
    'getInvoicesSuggestions',
    invoiceNumber,
  ],
  getInvoiceByFilters: ({
    limit,
    page,
    amountType,
    invoiceNumber,
    invoiceStatus,
    maxAmount,
    minAmount,
    dateRange,
    dateType,
    customerIds,
    paymentStatus,
    fetchOnlyNonZeroAmount,
  }: Partial<IInvoicesFilter> & { page: number; limit: number }) => [
    'getInvoiceByFilters',
    limit,
    page,
    amountType,
    invoiceNumber,
    maxAmount,
    minAmount,
    dateRange?.startDate?.toISOString(),
    dateRange?.endDate?.toISOString(),
    dateType,
    customerIds,
    fetchOnlyNonZeroAmount,
    ...(invoiceStatus ? invoiceStatus : []),
    ...(paymentStatus ? paymentStatus : []),
  ],
  getInvoiceSummaryByFilters: ({
    page,
    amountType,
    invoiceNumber,
    invoiceStatus,
    maxAmount,
    minAmount,
    dateRange,
    dateType,
    customerIds,
    paymentStatus,
    fetchOnlyNonZeroAmount,
  }: Partial<IInvoicesFilter> & { page: number }) => [
    'getInvoiceSummaryByFilters',
    page,
    amountType,
    invoiceNumber,
    maxAmount,
    minAmount,
    dateRange?.startDate?.toISOString(),
    dateRange?.endDate?.toISOString(),
    dateType,
    customerIds,
    fetchOnlyNonZeroAmount,
    ...(invoiceStatus ? invoiceStatus : []),
    ...(paymentStatus ? paymentStatus : []),
  ],
  getInvoiceById: (invoiceId: string) => ['getInvoiceById', invoiceId],
  getPromiseToPayReasonRequired: () => ['getPromiseToPayReasonRequired'],
};

interface IUseGetInvoicesByFiltersParams extends Partial<IInvoicesFilter> {
  page: number;
  limit: number;
  customConfig?: Omit<UseQueryOptions<IFilteredInvoiceResponse>, 'queryKey'>;
}

interface IUseGetInvoicesSummaryByFiltersParams extends Partial<IInvoicesFilter> {
  page: number;
  limit: number;
  customConfig?: Omit<UseQueryOptions<IInvoiceFilteredSummaryResponse>, 'queryKey'>;
}

export const useGetFilteredInvoices = ({
  customConfig,
  page,
  limit,
  amountType,
  invoiceNumber,
  maxAmount,
  minAmount,
  dateRange,
  invoiceStatus,
  dateType,
  customerIds,
  paymentStatus,
  fetchOnlyNonZeroAmount,
}: IUseGetInvoicesByFiltersParams) => {
  const getFilteredInvoicesQuery = useQuery<IFilteredInvoiceResponse>({
    queryKey: InvoiceQueryKeys.getInvoiceByFilters({
      limit,
      page,
      amountType,
      invoiceNumber,
      invoiceStatus,
      maxAmount,
      minAmount,
      dateRange,
      dateType,
      customerIds,
      paymentStatus,
      fetchOnlyNonZeroAmount,
    }),
    queryFn: () =>
      getInvoicesByFilters({
        limit,
        page,
        amountType,
        invoiceNumber,
        invoiceStatus,
        maxAmount,
        minAmount,
        dateRange,
        dateType,
        customerIds,
        paymentStatus,
        fetchOnlyNonZeroAmount,
      }),
    ...customConfig,
  });

  return getFilteredInvoicesQuery;
};

export const useGetInvoiceSuggestionsByInvoiceNumber = ({
  customConfig,
  customerId,
  paymentStatus,
}: {
  customConfig?: UseMutationOptions<
    IFilteredInvoiceResponse,
    AxiosError<IApiResponse<null>>,
    { invoiceNumber: string }
  >;
  customerId?: string;
  paymentStatus?: IPaymentStatus[];
}) => {
  return useMutation<IFilteredInvoiceResponse, AxiosError<IApiResponse<null>>, { invoiceNumber: string }>({
    mutationFn: ({ invoiceNumber }) =>
      getInvoicesByFilters({
        limit: 5,
        page: 1,
        invoiceNumber,
        customerIds: customerId,
        paymentStatus,
      }),
    ...customConfig,
  });
};

export const useGetInvoiceById = ({
  customConfig,
  invoiceId,
}: {
  customConfig?: Omit<UseQueryOptions<IInvoiceResponse>, 'queryKey'>;
  invoiceId: string;
}) => {
  const getInvoiceDetailsByIdQuery = useQuery<IInvoiceResponse>({
    queryKey: InvoiceQueryKeys.getInvoiceById(invoiceId),
    queryFn: () =>
      getInvoiceById({
        invoiceId,
      }),
    ...customConfig,
  });

  return getInvoiceDetailsByIdQuery;
};

export const usePostUpdateInvoiceMutation = ({
  invoiceId,
  customConfig,
}: {
  invoiceId: string;
  customConfig: UseMutationOptions<
    unknown,
    AxiosError<IApiResponse<null>>,
    {
      fields: (keyof IInvoice)[];
      updateObject: Partial<IInvoice>;
      comments?: IComments;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postUpdateInvoiceMutation = useMutation<
    unknown,
    AxiosError<IApiResponse<null>>,
    {
      fields: (keyof IInvoice)[];
      updateObject: Partial<IInvoice>;
      comments?: IComments;
    }
  >({
    mutationFn: ({
      fields,
      updateObject,
      comments,
    }: {
      fields: (keyof IInvoice)[];
      updateObject: Partial<IInvoice>;
      comments?: IComments;
    }) =>
      postUpdateInvoice({
        fields,
        updateObject,
        invoiceId,
        comments,
      }),
    ...customConfig,
    onSettled(data, error, variables, context) {
      queryClient.invalidateQueries({
        queryKey: InvoiceQueryKeys.getInvoiceById(invoiceId),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoiceByFilters'),
      });
      customConfig?.onSettled?.(data, error, variables, context);
    },
  });

  return postUpdateInvoiceMutation;
};

export const useBulkPromiseToPayUpdateMutation = ({
  invoiceId,
  customConfig,
}: {
  invoiceId?: string;
  customConfig: UseMutationOptions<
    IBulkPromiseToPayUpdateResponse,
    AxiosError<IBulkPromiseToPayUpdateResponse>,
    IBulkPromiseToPayUpdateRequest
  >;
}) => {
  const queryClient = useQueryClient();
  const postBulkPromiseToPayUpdateMutation = useMutation<
    IBulkPromiseToPayUpdateResponse,
    AxiosError<IBulkPromiseToPayUpdateResponse>,
    IBulkPromiseToPayUpdateRequest
  >({
    mutationFn: postBulkPromiseToPayUpdate,
    ...customConfig,
    onSettled(data, error, variables, context) {
      if (invoiceId) {
        queryClient.invalidateQueries({
          queryKey: InvoiceQueryKeys.getInvoiceById(invoiceId),
        });
      } else {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes('getInvoiceByFilters'),
        });
      }
      customConfig.onSettled?.(data, error, variables, context);
    },
  });

  return postBulkPromiseToPayUpdateMutation;
};

export const usePostUpdateInvoiceInlineMutation = ({
  customConfig,
}: {
  customConfig: UseMutationOptions<
    unknown,
    AxiosError<IApiResponse<null>>,
    {
      fields: (keyof IInvoice)[];
      updateObject: Partial<IInvoice>;
      comments?: IComments;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postUpdateInvoiceMutation = useMutation<
    unknown,
    AxiosError<IApiResponse<null>>,
    {
      invoiceId: string;
      fields: (keyof IInvoice)[];
      updateObject: Partial<IInvoice>;
      comments?: IComments;
    }
  >({
    mutationFn: ({
      fields,
      updateObject,
      comments,
      invoiceId,
    }: {
      invoiceId: string;
      fields: (keyof IInvoice)[];
      updateObject: Partial<IInvoice>;
      comments?: IComments;
    }) =>
      postUpdateInvoice({
        fields,
        updateObject,
        invoiceId,
        comments,
      }),
    ...customConfig,
    onSettled(data, error, variables, context) {
      queryClient.invalidateQueries({
        queryKey: InvoiceQueryKeys.getInvoiceById(variables.invoiceId),
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('getInvoiceByFilters'),
      });
      customConfig?.onSettled?.(data, error, variables, context);
    },
  });

  return postUpdateInvoiceMutation;
};

export const useInvoiceUpload = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IInvoiceUploadResponse,
    AxiosError<IInvoiceUploadResponse>,
    {
      file?: File;
      isValidationOnly: boolean;
    }
  >;
}) => {
  return useMutation({
    mutationFn: ({ file, isValidationOnly }: { file: File; isValidationOnly: boolean }) =>
      uploadInvoice({ file: file, isValidationOnly: isValidationOnly }),
    onSuccess: async () => {},
    onError: async () => {},
    ...customConfig,
  });
};

export const useGetFilteredInvoicesSummary = ({
  customConfig,
  page,
  amountType,
  invoiceNumber,
  maxAmount,
  minAmount,
  dateRange,
  invoiceStatus,
  dateType,
  customerIds,
  paymentStatus,
  fetchOnlyNonZeroAmount,
}: IUseGetInvoicesSummaryByFiltersParams) => {
  const getFilteredInvoicesQuery = useQuery<IInvoiceFilteredSummaryResponse>({
    queryKey: InvoiceQueryKeys.getInvoiceSummaryByFilters({
      page,
      amountType,
      invoiceNumber,
      invoiceStatus,
      maxAmount,
      minAmount,
      dateRange,
      dateType,
      customerIds,
      paymentStatus,
      fetchOnlyNonZeroAmount,
    }),
    queryFn: () =>
      getInvoicesSummaryByFilters({
        page,
        amountType,
        invoiceNumber,
        invoiceStatus,
        maxAmount,
        minAmount,
        dateRange,
        dateType,
        customerIds,
        paymentStatus,
        fetchOnlyNonZeroAmount,
      }),
    ...customConfig,
  });

  return getFilteredInvoicesQuery;
};

export const useGetPromiseToPayReasonRequired = ({
  customConfig,
}: {
  customConfig?: Omit<UseQueryOptions<IPromiseToPayReasonRequiredResponse>, 'queryKey'>;
}) => {
  const getPromiseToPayReasonRequiredQuery = useQuery<IPromiseToPayReasonRequiredResponse>({
    queryKey: InvoiceQueryKeys.getPromiseToPayReasonRequired(),
    queryFn: () => getPromiseToPayReasonRequired(),
    ...customConfig,
  });

  return getPromiseToPayReasonRequiredQuery;
};

export const useGetPromiseToPayReasonListMutation = ({
  customConfig,
}: {
  customConfig: UseMutationOptions<IPromiseToPayReasonListResponse, AxiosError<IPromiseToPayReasonListResponse>>;
}) => {
  const getPromiseToPayReasonListMutation = useMutation<
    IPromiseToPayReasonListResponse,
    AxiosError<IPromiseToPayReasonListResponse>
  >({
    mutationFn: getPromiseToPayReasonList,
    ...customConfig,
    onSettled(data, error, variables, context) {
      customConfig.onSettled?.(data, error, variables, context);
    },
  });

  return getPromiseToPayReasonListMutation;
};
