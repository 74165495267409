import { dataAxiosInstance } from '@/lib/axios';
import { ICustomerFilters } from '@/types/customer.types';
import { getStartAndEndDateOfFy } from '@/utils/getCurrentFyDate';
import dayjs from 'dayjs';

export const getCustomersByFilters = async ({
  page,
  limit,
  amountType,
  identifierType,
  identifierValue,
  minAmount,
  maxAmount,
  invoiceStatus,
  paymentStatus,
  dateType,
  dateRange,
  tags,
  ownerKeyType,
  ownerValue,
}: ICustomerFilters & { page: number; limit: number }) => {
  let url = `/customer/search?page=${page}&pageSize=${limit}`;

  if (amountType && minAmount && maxAmount) {
    url += `&amountType=${amountType}&minAmount=${minAmount}&maxAmount=${maxAmount}`;
  }

  if (identifierType && identifierValue?.trim()) {
    url += `&identifierType=${identifierType}&identifierValue=${identifierValue}`;
  }

  if (paymentStatus?.length) {
    url += paymentStatus.map((status: string) => `&paymentStatus=${status}`).join('');
  }

  if (dateType && dateRange?.startDate && dateRange?.endDate) {
    url += `&dateType=${dateType}&startDate=${dayjs(dateRange.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(
      dateRange.endDate,
    ).format('YYYY-MM-DD')}`;
  }

  if (invoiceStatus?.length) {
    url += invoiceStatus.map((status: string) => `&invoiceStatus=${status}`).join('');
  }

  if (tags?.length) {
    url += `&tag=${tags.map((tag) => `${tag.tagName}:${tag.tagValue}`).join('&tag=')}`;
  }

  if (ownerKeyType && ownerKeyType === 'name-email' && ownerValue?.trim()) {
    url += `&ownerId=${ownerValue.trim()}`;
  }

  if (ownerKeyType && ownerKeyType === 'role' && ownerValue?.trim()) {
    url += `&ownerRole=${ownerValue.trim()}`;
  }

  const result = await dataAxiosInstance.get(url);
  return result.data;
};

export const getCustomerById = async (customerId: string) => {
  const result = await dataAxiosInstance.get(`/customer/${customerId}`);

  return result.data;
};

export const getInvoicesSummaryByCustomerIdByDateRange = async ({ customerId }: { customerId: string }) => {
  const { startDate, endDate } = getStartAndEndDateOfFy();

  const result = await dataAxiosInstance.get(
    `/customer/${customerId}/invoicesSummary?startDate=${dayjs(startDate).format('YYYY-MM-DD')}&endDate=${dayjs(
      endDate,
    ).format('YYYY-MM-DD')}`,
  );
  return result.data;
};

export const getCreditNoteSummaryByCustomerId = async ({ customerId }: { customerId: string }) => {
  const result = await dataAxiosInstance.get(`/customer/${customerId}/creditNotesSummary`);
  return result.data;
};

export const getCustomerSuggestions = async (query: string) => {
  const result = await dataAxiosInstance.get(`/customer/suggestions?nameContains=${query.trim()}`);
  return result.data;
};

export const postUpdateCustomer = async ({
  fields,
  updateObject,
  customerId,
}: {
  fields: string[];
  updateObject: object;
  customerId: string;
}) => {
  const result = await dataAxiosInstance.post('/customer/update', {
    customerId,
    updates: {
      fields,
      values: updateObject,
    },
  });

  return result.data;
};
export const uploadCustomer = async ({ file, isValidationOnly }: { file: File; isValidationOnly: boolean }) => {
  if (!file) {
    throw new Error('Invalid Customer file');
  }
  const formData = new FormData();
  formData.append('file', file); // Append the file to the FormData object
  isValidationOnly && formData.append('isValidationOnly', isValidationOnly.toString());
  const result = await dataAxiosInstance.post('/customer/upload', formData); // Sending formData instead of file and isValidationOnly
  return result.data;
};

export const getCustomersSummaryByFilters = async ({
  page,
  amountType,
  identifierType,
  identifierValue,
  minAmount,
  maxAmount,
  invoiceStatus,
  paymentStatus,
  dateType,
  dateRange,
  tags,
  ownerKeyType,
  ownerValue,
}: ICustomerFilters & { page: number }) => {
  let url = `/customer/summary?page=${page}`;

  if (amountType && minAmount && maxAmount) {
    url += `&amountType=${amountType}&minAmount=${minAmount}&maxAmount=${maxAmount}`;
  }

  if (identifierType && identifierValue?.trim()) {
    url += `&identifierType=${identifierType}&identifierValue=${identifierValue}`;
  }

  if (paymentStatus?.length) {
    url += paymentStatus.map((status: string) => `&paymentStatus=${status}`).join('');
  }

  if (dateType && dateRange?.startDate && dateRange?.endDate) {
    url += `&dateType=${dateType}&startDate=${dayjs(dateRange.startDate).format('YYYY-MM-DD')}&endDate=${dayjs(
      dateRange.endDate,
    ).format('YYYY-MM-DD')}`;
  }

  if (invoiceStatus?.length) {
    url += invoiceStatus.map((status: string) => `&invoiceStatus=${status}`).join('');
  }

  if (tags?.length) {
    url += `&tag=${tags.map((tag) => `${tag.tagName}:${tag.tagValue}`).join('&tag=')}`;
  }

  if (ownerKeyType && ownerKeyType === 'name-email' && ownerValue?.trim()) {
    url += `&ownerId=${ownerValue.trim()}`;
  }

  if (ownerKeyType && ownerKeyType === 'role' && ownerValue?.trim()) {
    url += `&ownerRole=${ownerValue.trim()}`;
  }

  const result = await dataAxiosInstance.get(url);
  return result.data;
};
