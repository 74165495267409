import { useGetFilteredCustomers, useGetFilteredCustomersSummary } from '@/hooks/api-hooks/useCustomerQuery';
import { useExportCustomerDetails } from '@/hooks/excel-export/useCustomersDetailsExport';
import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { cn } from '@/lib/utils';
import { IContact, IOwner } from '@/types/contacts.types';
import { ICustomer, ICustomerDataWithInvoicesData, ICustomerFilters } from '@/types/customer.types';
import { TInvoiceDateType } from '@/types/invoices.types';
import {
  CUSTOMER_COLUMN_ORDER_STORAGE_KEY,
  CUSTOMER_COLUMN_VISIBILITY_STORAGE_KEY,
} from '@/utils/constants/local-storage-keys';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import {
  ColumnDef,
  RowSelectionState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeft,
  ChevronsRight,
  GripVerticalIcon,
  Loader2Icon,
} from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../components/ui/tooltip';
import { CustomTableBody, MemoizedTableBody } from '../Table/MemoizedTableBody';
import ToolTipCell from '../Table/ToolTipCell';
import { ColumnSelector } from '../shared/ReactTableColumnSelector';
import TextTooltip from '../shared/TextTooltip';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import Empty from '../ui/empty';
import SelectComponent from '../ui/select-component';
import { Skeleton } from '../ui/skeleton';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import { TableUnitsTabs } from '../ui/table-unit-tabs';
import { useToast } from '../ui/use-toast';
import ContactDetails from './customer-search/Contacts';
import OwnerDetails from './customer-search/Owners';
import TagsDetails from './customer-search/Tags';

const isAnyFilteredApplied = (filters: ICustomerFilters) => {
  if (filters.identifierType && filters.identifierValue) {
    return true;
  }

  if (filters.dateType && filters.dateRange?.startDate && filters.dateRange?.endDate) {
    return true;
  }

  if (filters.invoiceStatus?.length) {
    return true;
  }

  if (filters.dateType && filters.dateRange?.startDate && filters.dateRange?.endDate) {
    return true;
  }

  if (filters.tags?.length) {
    return true;
  }

  if (filters.paymentStatus?.length) {
    return true;
  }

  if (filters.ownerKeyType && filters.ownerValue) {
    return true;
  }

  if (filters.amountType && filters.minAmount !== undefined && filters.maxAmount !== undefined) {
    return true;
  }

  return false;
};

const CustomersTable = ({
  customerFilters,
  hideTableFilters,
  customerData,
}: {
  customerFilters: ICustomerFilters;
  hideTableFilters?: boolean;
  customerData?: ICustomerDataWithInvoicesData[];
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [unit, setUnit] = useState<'k' | 'l' | 'cr' | 'actual'>('actual');

  const { data: customerResponse, isLoading } = useGetFilteredCustomers({
    page: page,
    limit: rowsPerPage,
    ...customerFilters,
    customConfig: {
      enabled: !customerData,
    },
  });

  const { data: customerSummaryResponse, isLoading: isCustomerSummmaryLoading } = useGetFilteredCustomersSummary({
    page: page,
    limit: rowsPerPage,
    ...customerFilters,
    customConfig: {
      enabled: !customerData,
    },
  });

  useEffect(() => {
    handleFirstPage();
  }, [customerFilters]);

  const { toast } = useToast();

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    setPage((prev) => prev - 1);
  };

  const handleFirstPage = () => {
    setPage(1);
  };

  const handleLastPage = () => {
    setPage(customerResponse?.data.totalPages || 1);
  };

  const handleRowsPerPageChange = (value: string) => {
    setRowsPerPage(Number(value));
  };

  const handleUnitChange = (value: string) => {
    const newUnit = value as 'k' | 'l' | 'cr' | 'actual';
    setUnit(newUnit);
  };

  const isAnyValidFilterApplied = useMemo(() => {
    return isAnyFilteredApplied(customerFilters);
  }, [customerFilters]);

  const navigate = useNavigate();

  const columns: ColumnDef<ICustomerDataWithInvoicesData>[] = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || table.getIsSomePageRowsSelected()}
            className={cn(table.getIsSomePageRowsSelected() && 'opacity-50')}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
        minSize: 50,
        size: 50,
        showGrips: false,
      },
      {
        id: 'name',
        header: 'Customer Name',
        label: 'Customer Name',
        accessorKey: 'name',
        cell: ({ getValue, row }) => (
          <Link className=" text-blue-700 " to={`/customer/view/${row.original.id}`}>
            <ToolTipCell additionalClasses="underline" value={row.original.alias || (getValue() as string)} />
          </Link>
        ),
      },
      {
        id: 'gstin',
        header: 'GST',
        label: 'GST',
        accessorKey: 'gstin',
        cell: ({ getValue }) => (
          <div style={{ cursor: 'pointer' }}>
            {' '}
            <ToolTipCell value={getValue() as string} additionalClasses="cursor-text" />
          </div>
        ),
      },
      {
        id: 'pan',
        header: 'PAN',
        label: 'PAN',
        accessorKey: 'pan',
        cell: ({ getValue }) => <ToolTipCell value={getValue() as string} additionalClasses="cursor-text" />,
      },
      {
        id: 'buyerId',
        header: 'Buyer Id',
        label: 'Buyer Id',
        accessorKey: 'buyerId',
        cell: ({ getValue }) => <ToolTipCell value={getValue() as string} additionalClasses="cursor-text" />,
      },
      {
        id: 'tags',
        header: 'Tags',
        label: 'Tags',
        minSize: 300,
        accessorKey: 'tags',
        cell: ({ getValue, row }) => {
          const tags = getValue() as ICustomer['tags'];

          const mappedTags = tags
            ? Object.entries(tags).map((item) => ({
                name: item[0],
                value: item[1],
                id: item[0],
              }))
            : [];

          return <TagsDetails allowCreate={!hideTableFilters} customerId={row.original.id} tagDetails={mappedTags} />;
        },
      },
      {
        id: 'contactsData',
        header: 'Customer Contacts',
        accessorKey: 'contactsData',
        minSize: 250,
        cell: ({ getValue, row }) => (
          <ContactDetails
            allowCreate={!hideTableFilters}
            customerId={row.original.id}
            contactDetails={getValue() ? (getValue() as IContact[]) : []}
          />
        ),
      },
      {
        id: 'ownersData',
        header: 'Internal Stakeholders',
        accessorKey: 'ownersData',
        minSize: 250,
        cell: ({ getValue, row }) => (
          <OwnerDetails
            allowCreate={!hideTableFilters}
            customerId={row.original.id}
            ownerDetails={getValue() ? (getValue() as IOwner[]) : []}
          />
        ),
      },
      {
        id: 'registeredAddress',
        header: 'Address',
        label: 'Address',
        cell: ({ row }) =>
          row.original.registeredAddress && row.original.registeredAddress?.line1 ? (
            <ToolTipCell
              value={`
          ${row.original.registeredAddress?.line1}
          ${row.original.registeredAddress?.line2}
        `.trim()}
              additionalClasses="cursor-text"
            />
          ) : (
            'Not Available'
          ),
      },
      {
        id: 'totalAmount',
        label: 'Total Amount',
        header: () => {
          const startDate = customerFilters.dateRange?.startDate ? dayjs(customerFilters.dateRange.startDate) : null;
          const endDate = customerFilters.dateRange?.endDate ? dayjs(customerFilters.dateRange.endDate) : null;

          const dateTypeText: Record<TInvoiceDateType, string> = {
            INVOICE_DATE: 'Total for Invoices raised in',
            DUE_DATE: 'Total for Invoices with due date',
            PROMISE_TO_PAY_DATE: 'Total for Invoices with PTP date',
          };

          if (customerFilters.dateType && startDate && endDate) {
            return (
              <TextTooltip
                text={`${dateTypeText[customerFilters.dateType]} ${startDate.format('DD MMM YY')} - ${endDate.format(
                  'DD MMM YY',
                )}`}
              >
                {dateTypeText[customerFilters.dateType]} {startDate.format('DD MMM YY')} - {endDate.format('DD MMM YY')}
              </TextTooltip>
            );
          }

          return <TextTooltip text="Lifetime Invoiced">Lifetime Invoiced</TextTooltip>;
        },
        accessorKey: 'totalAmount',
        cell: ({ getValue }) => (
          <div className=" w-full flex flex-col gap-1 ">
            <div className="w-full truncate text-ellipsis ">{formatCurrencyByUnit(getValue() as number, unit)}</div>
          </div>
        ),
      },
      {
        id: 'totalDueAmount',
        label: 'Amount Due',
        header: () => {
          const startDate = customerFilters.dateRange?.startDate ? dayjs(customerFilters.dateRange.startDate) : null;
          const endDate = customerFilters.dateRange?.endDate ? dayjs(customerFilters.dateRange.endDate) : null;

          const dateTypeText: Record<TInvoiceDateType, string> = {
            INVOICE_DATE: 'Due for Invoices raised in',
            DUE_DATE: 'Due for Invoices with due date',
            PROMISE_TO_PAY_DATE: 'Due for Invoices with PTP date',
          };

          if (customerFilters.dateType && startDate && endDate) {
            return (
              <TextTooltip
                text={`${dateTypeText[customerFilters.dateType]} ${startDate.format('DD MMM YY')} - ${endDate.format(
                  'DD MMM YY',
                )}`}
              >
                {dateTypeText[customerFilters.dateType]} {startDate.format('DD MMM YY')} - {endDate.format('DD MMM YY')}
              </TextTooltip>
            );
          }

          return <TextTooltip text="Amount Due">Amount Due</TextTooltip>;
        },
        accessorKey: 'totalDueAmount',

        cell: ({ getValue }) => {
          return (
            <div className=" w-full flex flex-col gap-1 ">
              <div className="w-full truncate text-ellipsis ">{formatCurrencyByUnit(Number(getValue()), unit)}</div>
            </div>
          );
        },
      },
      {
        id: 'totalOverdueAmount',
        label: 'Amount Overdue',
        header: () => {
          const startDate = customerFilters.dateRange?.startDate ? dayjs(customerFilters.dateRange.startDate) : null;
          const endDate = customerFilters.dateRange?.endDate ? dayjs(customerFilters.dateRange.endDate) : null;

          const dateTypeText: Record<TInvoiceDateType, string> = {
            INVOICE_DATE: 'Overdue for Invoices raised in',
            DUE_DATE: 'Overdue for Invoices with due date',
            PROMISE_TO_PAY_DATE: 'Overdue for Invoices with PTP date',
          };

          if (customerFilters.dateType && startDate && endDate) {
            return (
              <TextTooltip
                text={`${dateTypeText[customerFilters.dateType]} ${startDate.format('DD MMM YY')} - ${endDate.format(
                  'DD MMM YY',
                )}`}
              >
                {dateTypeText[customerFilters.dateType]} {startDate.format('DD MMM YY')} - {endDate.format('DD MMM YY')}
              </TextTooltip>
            );
          }

          return <TextTooltip text="Amount Overdue">Amount Overdue</TextTooltip>;
        },
        accessorKey: 'totalOverdueAmount',

        cell: ({ getValue }) => (
          <div className=" w-full flex flex-col gap-1 ">
            <div className="w-full truncate text-ellipsis ">{formatCurrencyByUnit(getValue() as number, unit)}</div>
          </div>
        ),
      },
      {
        id: 'actions',
        enableHiding: false,
        size: 70,
        minSize: 70,
        cell: ({ row }) => {
          return (
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <DotsHorizontalIcon className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuLabel>Actions</DropdownMenuLabel>
                  <DropdownMenuItem asChild>
                    <Link to={`/customer/view/${row.original.id}`}>View customer details</Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        },
      },
    ],
    [
      unit,
      customerFilters.dateType,
      customerFilters.dateRange?.startDate,
      customerFilters.dateRange?.endDate,
      hideTableFilters,
    ],
  );

  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);
  const defaultColumnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    CUSTOMER_COLUMN_ORDER_STORAGE_KEY,
    defaultColumnOrder,
  );

  const { exportExcel, isLoading: isExportLoading } = useExportCustomerDetails({
    onSuccess: (url) => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Customer Details.xlsx');
      document.body.appendChild(link);
      link.click();
      toast({
        description: 'Customer details exported',
      });
    },
    onError() {
      toast({
        variant: 'destructive',
        description: 'Failed to export customer details',
      });
    },
  });

  const { storedValue: storedColumnVisibility, setValue: setColumnVisibilityValue } = useLocalStorage(
    CUSTOMER_COLUMN_VISIBILITY_STORAGE_KEY,
    defaultColumnVisibility,
  );

  const tableData = useMemo(() => {
    return customerResponse?.data?.docs || [];
  }, [customerResponse]);

  const table = useReactTable({
    data: customerData ? customerData : tableData,
    columns,
    columnResizeMode: 'onChange',
    onRowSelectionChange: (value) => setRowSelection(value),
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibilityValue,
    getRowId: (row) => row.id,
    defaultColumn: {
      minSize: 50,
    },
    state: {
      columnVisibility: {
        ...storedColumnVisibility,
        select: !hideTableFilters,
      },
      rowSelection: rowSelection,
      columnOrder,
    },
  });

  const tableColumns = useMemo(() => table.getAllColumns(), [table]);

  const sizes = table.getState().columnSizingInfo;

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    return headers.reduce(
      (acc, current) => {
        return {
          ...acc,
          [`--col-${current.column.id}-size`]: current.column.getSize(),
          [`--header-${current.id}-size`]: current.getSize(),
        };
      },
      {} as {
        [key: string]: number;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);

  if (isLoading) {
    return (
      <div className="py-2">
        <div className="flex items-center justify-end my-4">
          <TableUnitsTabs value="actual" onValueChange={() => {}} />
        </div>
        <Table>
          <TableHeader className=" bg-gray-100">
            {new Array(1).fill(0).map((_, i) => (
              <TableRow key={i}>
                {new Array(6).fill(0).map((_, i) => (
                  <TableHead className="px-6 min-w-[100px]" key={i}>
                    <Skeleton className="w-full h-4" />
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="py-4">
            {new Array(10).fill(0).map((_, i) => (
              <TableRow key={i}>
                {new Array(6).fill(0).map((_, i) => (
                  <TableCell className="px-6 text-center" key={i}>
                    <Skeleton className="w-full h-4" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  if (!customerResponse) {
    return <Empty title="No data available" />;
  }

  const handleExport = () => {
    const rowsToExport = customerResponse?.data.docs.filter((row) => !!rowSelection[row.id]);
    exportExcel(rowsToExport, new Set(tableColumns.filter((col) => col.getIsVisible()).map((col) => col.id)));
  };

  const handleSaveSegment = () => {
    navigate('/segments/create', {
      state: customerFilters,
    });
  };

  return (
    <div>
      {!hideTableFilters && (
        <div className="flex justify-between my-4">
          <div className="flex items-center gap-4 truncate text-ellipsis ">
            <>
              {!isCustomerSummmaryLoading ? (
                <h2 className=" text-lg font-semibold">
                  {/* Invoices{' '} */}
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <div
                          style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                          className="w-[550px] text-left font-light"
                        >
                          <span className="text-sm font-normal">
                            Count: {customerSummaryResponse?.data.customerCount}, Due/Overdue:{' '}
                            {formatCurrencyByUnit(
                              customerSummaryResponse?.data.customerInvoiceOutstandingAmount || 0,
                              unit,
                            )}
                            , Lifetime Invoiced:{' '}
                            {formatCurrencyByUnit(customerSummaryResponse?.data.customerInvoiceAmount || 0, unit)}
                          </span>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        {
                          <span className="text-sm font-normal">
                            Count: {customerSummaryResponse?.data.customerCount}, Due/Overdue:{' '}
                            {formatCurrencyByUnit(
                              customerSummaryResponse?.data.customerInvoiceOutstandingAmount || 0,
                              unit,
                            )}
                            , Lifetime Invoiced:{' '}
                            {formatCurrencyByUnit(customerSummaryResponse?.data.customerInvoiceAmount || 0, unit)}
                          </span>
                        }
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </h2>
              ) : (
                <div className=" flex flex-col gap-1 mt-2 ">
                  <Skeleton className=" min-w-[500px] w-[160px] h-4" />
                </div>
              )}
            </>
          </div>
          <div className="flex items-center justify-end gap-4">
            {isAnyValidFilterApplied && (
              <Button variant="outline" onClick={handleSaveSegment}>
                Save as segment
              </Button>
            )}
            {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
              <Button onClick={handleExport} disabled={isExportLoading}>
                Export
                {isExportLoading && <Loader2Icon className=" h-4 w-4 animate-spin " />}
              </Button>
            )}
            <ColumnSelector columns={tableColumns} setColumnOrder={setColumnOrder} columnOrder={columnOrder} />
            <TableUnitsTabs value={unit} onValueChange={handleUnitChange} />
          </div>
        </div>
      )}
      <div className=" overflow-auto ">
        <Table
          style={{
            ...columnSizeVars,
            width: table.getTotalSize(),
          }}
          className="flex-1"
        >
          {customerResponse?.data?.docs?.length === 0 && <TableCaption className="pb-10">No data found</TableCaption>}
          <TableHeader className=" bg-gray-100 ">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className="flex items-center" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      style={{
                        width: `calc(var(--header-${header?.id}-size) * 1px)`,
                      }}
                      className="flex items-center justify-between"
                      key={header.id}
                    >
                      {!['actions', 'select'].includes(header.id) ? (
                        <div className="break-keep truncate text-ellipsis w-[90%]">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                      {!['actions', 'select'].includes(header.id) && (
                        <GripVerticalIcon
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: cn(
                              'w-4 h-4 cursor-ew-resize min-w-4 min-h-4',
                              header.column.getIsResizing() ? 'opacity-100' : 'opacity-50',
                            ),
                          }}
                        />
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          {table.getState().columnSizingInfo.isResizingColumn ? (
            <MemoizedTableBody table={table} />
          ) : (
            <CustomTableBody table={table} />
          )}
        </Table>
      </div>
      {customerResponse.data.totalPages > 0 && (
        <div className="flex my-4 justify-between text-sm px-4">
          <div className="flex items-center gap-2">
            <Button disabled={!customerResponse.data.hasPrev} onClick={handleFirstPage} variant="outline" size="icon">
              <ChevronsLeft className="w-4 h-4" />
            </Button>
            <Button
              disabled={!customerResponse.data.hasPrev}
              onClick={handlePreviousPage}
              variant="outline"
              size="icon"
            >
              <ChevronLeftIcon className="w-4 h-4" />
            </Button>
          </div>
          <span className="flex items-center gap-8">
            <span>
              Page{' '}
              <strong>
                {customerResponse.data.pageNumber} of {customerResponse.data.totalPages}
              </strong>{' '}
            </span>
            <span className="flex items-center gap-2">
              Rows per page{' '}
              <SelectComponent
                className="w-[80px] p-1"
                onChange={handleRowsPerPageChange}
                value={String(rowsPerPage)}
                options={[
                  {
                    label: '10',
                    value: '10',
                  },
                  {
                    label: '20',
                    value: '20',
                  },
                  {
                    label: '50',
                    value: '50',
                  },
                  {
                    label: '100',
                    value: '100',
                  },
                ]}
              />
            </span>
          </span>
          <div className="flex items-center gap-2">
            <Button disabled={!customerResponse.data.hasNext} onClick={handleNextPage} variant="outline" size="icon">
              <ChevronRightIcon className="w-4 h-4" />
            </Button>
            <Button disabled={!customerResponse.data.hasNext} onClick={handleLastPage} variant="outline" size="icon">
              <ChevronsRight className="w-4 h-4" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomersTable;
