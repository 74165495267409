import { cn } from '@/lib/utils';

const SubItem = ({
  label,
  value,
  className,
  description,
}: {
  label: string;
  value: React.ReactNode;
  className?: string;
  description?: string;
}) => {
  return (
    <div className={cn(' flex flex-col ', className)}>
      <div className=" text-muted-foreground text-sm">{label}</div>
      <div className="text-sm">{value}</div>
      {description && <div className="text-xs text-muted-foreground">{description}</div>}
    </div>
  );
};

export default SubItem;
