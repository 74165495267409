import EditAttachments from '@/components/customers/EditAttachments';
import Adjustments from '@/components/invoices/Adjustments/AdjustmentsWrapper';
import InvoiceOtherDetails from '@/components/invoices/InvoiceOtherDetails';
import InvoiceTypeItems from '@/components/invoices/InvoiceTypeItems';
import PaymentDetails from '@/components/invoices/PaymentDetails';
import Postings from '@/components/invoices/Postings';
import { LoadingLabelValueDescriptionLayout } from '@/components/shared/LabelValueDescLayout';
import TableSkeleton from '@/components/shared/TableSkeleton';
import { Badge } from '@/components/ui/badge';
import { Card, CardHeader } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { useGetInvoiceById } from '@/hooks/api-hooks/useInvoiceQuery';
import { useGetPostedCreditsForInvoice } from '@/hooks/api-hooks/usePostingQuery';
import { useGetWriteOffByInvoiceId } from '@/hooks/api-hooks/useWriteOffQuery';
import { cn } from '@/lib/utils';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { CardContent } from '@mui/material';
import dayjs, { extend } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../src/components/ui/tooltip';

const ViewInvoicePage = () => {
  extend(utc);
  extend(timezone);

  // Set the default timezone
  dayjs.tz.setDefault('Asia/Kolkata');

  const { invoiceId } = useParams();

  const {
    data: invoiceData,
    isError,
    isLoading,
  } = useGetInvoiceById({
    invoiceId: invoiceId as string,
    customConfig: {
      enabled: !!invoiceId,
    },
  });

  const { data: writeOffDetails } = useGetWriteOffByInvoiceId({
    invoiceId: invoiceId as string,
    approvalStatusList: ['PENDING'],
  });

  const { data: postedCreditsData } = useGetPostedCreditsForInvoice({
    invoiceId: invoiceId ?? '',
  });

  const postedCredits = useMemo(() => {
    return postedCreditsData?.data.filter((item) => !!item.credit.id) || [];
  }, [postedCreditsData]);

  const postedCreditNotes = useMemo(() => {
    return (
      postedCreditsData?.data
        .filter((item) => !!item.creditNote.id)
        .map((item) => ({
          appliedAmount: item.postedAmount,
          appliedBy: item.createdBy,
          appliedOn: item.timestamp,
          reference: {
            creditNoteId: item.creditNote.id || '',
            creditNoteNumber: item.creditNote.creditNoteNumber || '',
          },
          type: 'Credit Note',
          id: item.creditNote.id || '',
          postingId: item.postingId,
          invoiceId: item.invoiceId,
        })) || []
    );
  }, [postedCreditsData]);

  const postedPlaceholders = useMemo(() => {
    return (
      postedCreditsData?.data
        .filter((item) => !!item.placeholder.id)
        .map((item) => ({
          appliedAmount: item.postedAmount,
          appliedBy: item.createdBy,
          appliedOn: item.timestamp,
          reference: undefined,
          type: item.placeholder.placeholderName,
          id: item.placeholder.id || '',
          postingId: item.postingId,
          invoiceId: item.invoiceId,
        })) || []
    );
  }, [postedCreditsData]);

  const postedAdjustments = useMemo(() => {
    return [...postedCreditNotes, ...postedPlaceholders];
  }, [postedCreditNotes, postedPlaceholders]);

  if (isLoading) {
    return (
      <div className="sm:px-16 sm:py-8 px-4 py-8">
        <div className=" text-xl font-semibold mb-4 flex gap-8 items-center ">
          <Skeleton className=" capitalize w-32 h-6 " />
          <Skeleton className=" capitalize w-96 h-6 " />
        </div>
        <div className="flex gap-8 items-center ">
          <div className=" flex flex-col gap-2 ">
            <Skeleton className="w-32 h-4" />
            <Skeleton className="w-32 h-4" />
          </div>
          <div className=" flex flex-col gap-2 ">
            <Skeleton className="w-32 h-4" />
            <Skeleton className="w-32 h-4" />
          </div>
          <div className=" flex flex-col gap-2 ">
            <Skeleton className="w-32 h-4" />
            <Skeleton className="w-32 h-4" />
          </div>
        </div>
        <Card className=" text-sm my-8">
          <CardHeader className="border-b">
            <Skeleton className="w-48 h-5" />
          </CardHeader>
          <CardContent className=" flex flex-col gap-4">
            {new Array(4).fill(0).map((_, i) => (
              <LoadingLabelValueDescriptionLayout key={i} />
            ))}
          </CardContent>
        </Card>
        <Card className=" my-8 ">
          <CardHeader className="border-b">
            <Skeleton className="w-48 h-5" />
          </CardHeader>
          <CardContent>
            <TableSkeleton rows={6} columns={6} />
          </CardContent>
        </Card>
        <Card className=" text-sm my-8">
          <CardHeader className="border-b">
            <Skeleton className=" w-48 h-5" />
          </CardHeader>
          <CardContent className=" flex flex-col gap-4">
            {new Array(4).fill(0).map((_, i) => (
              <LoadingLabelValueDescriptionLayout key={i} />
            ))}
          </CardContent>
        </Card>
      </div>
    );
  }

  const invoice = invoiceData?.data;
  const writeOffList = writeOffDetails?.data;

  if (isError || !invoice || !invoiceId) {
    return <Navigate to="/404" replace />;
  }

  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8">
      <div className=" text-xl font-semibold mb-4 flex gap-8 items-center ">
        <div className=" min-w-[150px] ">
          <h2 className=" font-semibold  text-sm ">{`${invoice?.invoiceType ?? ''} status:`}</h2>
          <div className=" text-sm mt-[4px]">
            <Badge
              className={cn(
                'capitalize ',
                invoice.invoiceStatus === 'NO_DUES' && 'bg-green-700 hover:bg-green-700 hover:text-white text-white',
                invoice.invoiceStatus === 'OVERDUE' &&
                  'bg-red-500 text-white hover:bg-red-500 border border-red-500 hover:text-white',
                invoice.invoiceStatus === 'DUE' && 'bg-red-500 text-white hover:bg-red-500 hover:text-white',
              )}
            >
              {invoice.invoiceStatus.split('_').join(' ')}
            </Badge>
          </div>
        </div>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="text-[20px] truncate  whitespace-nowrap overflow-ellipsis" asChild>
              <div>
                {`${invoice.invoiceType ?? ''}`}{' '}
                {invoice?.invoiceNumber?.trim() ? invoice.invoiceNumber : invoice.invoiceId} to{' '}
                {invoice.customerId ? (
                  <Link
                    className=" text-blue-700 underline "
                    to={`/customer/view/${invoice.customerId}`}
                    target="_blank"
                  >
                    {invoice.customerName}
                  </Link>
                ) : (
                  invoice.customerName
                )}{' '}
                on {`${dayjs(invoice.invoiceDate).format('DD MMM YYYY')}`}
              </div>
            </TooltipTrigger>
            <TooltipContent>
              {`${invoice.invoiceType ?? ''}`}{' '}
              {invoice?.invoiceNumber?.trim() ? invoice.invoiceNumber : invoice.invoiceId} to{' '}
              {invoice.customerId ? invoice.customerName : invoice.customerName} on{' '}
              {`${dayjs(invoice.invoiceDate).format('DD MMM YYYY')}`}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="flex gap-8 items-center  ">
        <div className=" min-w-[150px] ">
          <div className=" font-semibold text-sm ">Payment status:</div>
          <div className=" text-sm mt-[4px]">
            <Badge
              className={cn(
                invoice.paymentStatus === 'PAID' && 'bg-green-700 hover:bg-green-700 hover:text-white text-white',
                invoice.paymentStatus === 'UNPAID' && 'bg-red-500 text-white hover:bg-red-500 hover:text-white',
                invoice.paymentStatus === 'PARTIALLY_PAID' && 'bg-yellow-400 text-black hover:bg-yellow-400',
              )}
            >
              {invoice.paymentStatus.split('_').join(' ')}
            </Badge>
          </div>
        </div>
        <div>
          {invoice.invoiceOutstandingAmount > 0 && (
            <div className=" font-semibold ">
              {dayjs(invoice.invoiceDueDate).endOf('day').diff(dayjs(), 'day') < 0 ? (
                <span className=" text-red-500 ">
                  {Math.abs(dayjs(invoice.invoiceDueDate).endOf('day').diff(dayjs(), 'day'))} days overdue
                </span>
              ) : dayjs(invoice.invoiceDueDate).endOf('day').diff(dayjs(), 'day') == 0 ? (
                <span className=" text-yellow-500 ">Due Today</span>
              ) : dayjs(invoice.invoiceDueDate).endOf('day').diff(dayjs(), 'day') > 0 &&
                dayjs().diff(invoice.invoiceDueDate, 'day') <= 7 ? (
                <span className=" text-yellow-500 ">{`Due in ${Math.abs(
                  dayjs(invoice.invoiceDueDate).endOf('day').diff(dayjs(), 'day'),
                )} day${
                  Math.abs(dayjs(invoice.invoiceDueDate).endOf('day').diff(dayjs(), 'days')) > 1 ? 's' : ''
                }`}</span>
              ) : (
                ''
              )}
            </div>
          )}

          <div className=" text-sm ">Due: {dayjs(invoice.invoiceDueDate).format('DD MMM YYYY')}</div>
        </div>
        {invoice.invoiceOutstandingAmount > 0 && (
          <div>
            <div className=" text-red-500 font-semibold ">
              {dayjs(invoice.promiseToPayDate).isAfter(dayjs())
                ? dayjs().diff(invoice.invoiceDueDate, 'day') === 0
                  ? `Follow-up due today for ${formatCurrencyByUnit(invoice.invoiceOutstandingAmount, 'actual')}`
                  : `Follow-up in ${Math.abs(
                      dayjs().diff(invoice.invoiceDueDate, 'day'),
                    )} days for ${formatCurrencyByUnit(
                      invoice.invoiceOutstandingAmount,
                      'actual',
                      invoice.invoiceCurrency,
                    )}`
                : `Follow-up overdue by ${Math.abs(
                    dayjs().diff(invoice.promiseToPayDate, 'day'),
                  )} days for ${formatCurrencyByUnit(
                    invoice.invoiceOutstandingAmount,
                    'actual',
                    invoice.invoiceCurrency,
                  )}`}
            </div>
            <div className=" text-sm ">Promise To Pay: {dayjs(invoice.promiseToPayDate).format('DD MMM YYYY')}</div>
          </div>
        )}
      </div>
      <PaymentDetails
        invoice={invoice}
        invoiceId={invoiceId}
        writeOffList={writeOffList}
        postedCreditNotes={postedCreditNotes}
      />
      <Adjustments invoiceNumber={invoice.invoiceNumber} data={postedAdjustments} />
      <Postings invoiceNumber={invoice.invoiceNumber} postedToInvoice={postedCredits} />
      <EditAttachments id={invoice?.id} type={'INVOICE'} />
      <InvoiceTypeItems invoice={invoice} invoiceId={invoiceId} />
      <InvoiceOtherDetails invoice={invoice} invoiceId={invoiceId} />
    </div>
  );
};

export default ViewInvoicePage;
