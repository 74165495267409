import { Layout } from '@/components/shared/Layout';
import { Skeleton } from '@/components/ui/skeleton';
import WorkflowDryRunList from '@/components/workflow/list/WorkflowDryRunTable';
import { useGetWorkflowsDryRunQuery } from '@/hooks/api-hooks/useWorkflowQuery';
import { IWorkflowDryRun } from '@/types/workflow.type';
import dayjs from 'dayjs';
import { Navigate, useParams } from 'react-router-dom';

const WorkflowDryRunListPage = () => {
  const { workflowId } = useParams();
  const { data: workflowsDryRunResponse, isLoading } = useGetWorkflowsDryRunQuery({
    workflowId: workflowId as string,
  });

  const workflowDryRunResponseData = workflowsDryRunResponse?.data as IWorkflowDryRun;
  if (!isLoading && !workflowDryRunResponseData) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      <div className="border-b flex justify-between items-center bg-[#F8FAFC]">
        <Layout>
          <div className=" w-full">
            <h1 className="text-lg  font-bold">
              Test Run{' '}
              {workflowDryRunResponseData && (
                <span className="text-muted-foreground text-sm font-normal">{`[${workflowDryRunResponseData?.name}]`}</span>
              )}
            </h1>{' '}
            <div className="w-[300px] text-sm text-muted-foreground ">
              {workflowDryRunResponseData ? (
                <div className="flex justify-between">
                  <div>
                    <div>On</div>
                    <div>
                      {dayjs(workflowDryRunResponseData?.initiatedAt)
                        .add(330, 'minute')
                        .format('DD MMM YY, HH:mm A')}
                    </div>
                  </div>
                  <div>
                    <div>By</div>
                    <div>{workflowDryRunResponseData?.initiatedBy}</div>
                  </div>
                </div>
              ) : (
                <div className=" text-xl font-semibold mb-4 flex gap-8 items-center ">
                  <Skeleton className=" capitalize w-32 h-6 " />
                  <Skeleton className=" capitalize w-96 h-6 " />
                </div>
              )}
            </div>
          </div>
        </Layout>
      </div>

      <Layout className="py-0 sm:py-0">
        <WorkflowDryRunList workflowDryRunData={workflowDryRunResponseData} isLoading={isLoading} />
      </Layout>
    </>
  );
};

export default WorkflowDryRunListPage;
