import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import { useDeleteStateById, useGetStateById } from '@/hooks/api-hooks/useWorkflowQuery';
import { cn } from '@/lib/utils';
import { IOnlyActionProperties, IState, StateTypes } from '@/types/workflow.type';
import { autoUpdate, useFloating } from '@floating-ui/react';
import { Loader2Icon, Trash2Icon } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ActionSlot } from './action-blocks';

const ActionBlock = (props: NodeProps<IState<StateTypes>>) => {
  const { data: stateResponse, isLoading } = useGetStateById({
    stateId: props.id,
    customConfig: {
      enabled: !!props.id,
    },
  });

  const { toast } = useToast();

  const { mutate: deleteState, isPending } = useDeleteStateById({
    stateId: props.id,
    workflowId: stateResponse?.data.workflowId || '',
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Unable to delete state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const timeout = useRef<number>();
  const [visible, setVisible] = useState(false);
  const { refs, floatingStyles } = useFloating({
    placement: 'right-start',
    whileElementsMounted: autoUpdate,
  });

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(true);
    }, 25);
  }, []);
  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(false);
    }, 25);
  }, []);

  const actionState = useMemo(() => {
    if (!stateResponse) {
      return null;
    }

    return stateResponse.data;
  }, [stateResponse]);

  const handleDeleteState = useCallback(() => {
    deleteState();
  }, [deleteState]);

  if (isLoading) {
    return (
      <>
        <div>
          <Handle id="default" type="target" position={Position.Top} />
          <Handle id="goto-target" type="target" position={Position.Right} />
          <h3 className=" text-muted-foreground/80 text-sm mb-1 ">Action Block</h3>
          <Card className=" rounded-md  ">
            <CardContent className=" py-4 w-[350px] text-sm ">
              <div className=" mb-4 ">
                {new Array(1).fill(0).map((_, i) => (
                  <div key={i} className=" flex items-center gap-2 border-l-4 px-2 border-muted-foreground/20 ">
                    <div className=" bg-muted-foreground/10 p-2 rounded-md ">
                      <Skeleton className=" h-8 w-8 " />
                    </div>
                    <div className=" w-ful flex flex-col gap-1 flex-1 ">
                      <div className=" w-full flex items-end gap-2 ">
                        <Skeleton className=" w-[80px] h-4 " />
                        <Skeleton className="flex-1 h-4" />
                      </div>
                      <div className=" w-full flex items-start gap-2">
                        <Skeleton className=" w-[80px] h-4 " />
                        <Skeleton className="flex-1 h-4" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
          <Handle id="default" type="source" position={Position.Bottom} />
        </div>
      </>
    );
  }

  if (!stateResponse || !actionState) {
    return null;
  }

  return (
    <>
      <Handle id="default" type="target" position={Position.Top} />
      <div
        ref={refs.setReference}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className=" w-[350px] flex items-center justify-center "
      >
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={cn(' px-2 ', visible ? 'opacity-100' : 'opacity-0')}
        >
          <Button
            disabled={isPending}
            onClick={handleDeleteState}
            className=" text-destructive  bg-white "
            variant="outline"
            size="icon"
          >
            {isPending ? <Loader2Icon className="w-3 h-3 animate-spin" /> : <Trash2Icon className="w-3 h-3" />}
          </Button>
        </div>
        <Card className=" rounded-md  w-full  ">
          <CardContent className=" py-4  text-sm  ">
            <div className=" border-l-4 px-2 border-muted-foreground/20 ">
              <ActionSlot
                catIndex={0}
                nodeType="ACTION_ONLY"
                actionId={(actionState.description as IOnlyActionProperties).action}
                stateId={props.id}
                workflowId={actionState.workflowId}
              />
            </div>
          </CardContent>
        </Card>
      </div>
      <Handle id="default" type="source" position={Position.Bottom} />
    </>
  );
};

export default ActionBlock;
