export const EmailReceivedIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3332 9.16665C18.3332 8.75715 18.3288 8.34765 18.32 7.93697C18.2656 5.38236 18.2383 4.10506 17.2958 3.15886C16.3531 2.21267 15.0413 2.17971 12.4175 2.11379C10.8004 2.07316 9.19925 2.07316 7.58219 2.11378C4.95845 2.1797 3.64657 2.21266 2.70396 3.15886C1.76135 4.10505 1.73412 5.38236 1.67964 7.93696C1.66212 8.7584 1.66213 9.5749 1.67965 10.3963C1.73412 12.951 1.76136 14.2282 2.70397 15.1745C3.64657 16.1206 4.95845 16.1536 7.5822 16.2195C8.80925 16.2503 10.0272 16.2578 11.2498 16.2418"
        stroke="#141B34"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6665 4.16669L7.42735 7.4372C9.53209 8.6321 10.4676 8.6321 12.5723 7.4372L18.3332 4.16669"
        stroke="#141B34"
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M14.1665 15.8333C14.5761 16.2547 15.6663 17.9167 16.2498 17.9167M16.2498 17.9167C16.8333 17.9167 17.9236 16.2547 18.3332 15.8333M16.2498 17.9167V11.25"
        stroke="#141B34"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
