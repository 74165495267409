import { PreviewVariable } from '@/components/email-builder/PreviewTemplate';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useToast } from '@/components/ui/use-toast';
import { usePostSendEmailPreview, usePostSendMail } from '@/hooks/api-hooks/useEmailQuery';
import { usePreviewStore } from '@/stores/email-builder/preview-store';
import { IOptions } from '@/types/common.types';
import { getClassNamesForSelectPurpose, getStylesForSelect } from '@/utils/getStylesForSelect';
import { Loader2Icon } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';

interface IEmailProperties {
  to?: string[];
  from?: string;
  cc?: string[];
}

const validateEmailProperties = (data: Partial<IEmailProperties>) => {
  const errors: Record<keyof Omit<IEmailProperties, 'clientId'>, string> = {
    from: '',
    to: '',
    cc: '',
  };
  let isValid = true;

  if (!data.from) {
    errors.from = 'From is required';
    isValid = false;
  } else {
    const emailRegex = /^[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    if (!emailRegex.test(data.from || '')) {
      errors.from = 'Invalid email';
      isValid = false;
    }
  }

  if (!data.to?.length) {
    errors.to = 'To is required';
    isValid = false;
  }

  return {
    isValid,
    errors,
  };
};

const SendEmailDialog = ({
  variables,
  isForWorkflow,
  body,
  subject,
}: {
  variables: PreviewVariable[];
  isForWorkflow: boolean;
  body?: string;
  subject?: string;
}) => {
  const { toast } = useToast();
  const { templateId } = useParams();
  const [isSendDialogOpen, setIsSendDialogOpen] = useState(false);

  const { mutate: sendEmail, isPending } = usePostSendEmailPreview({
    templateId: templateId as string,
    customConfig: {
      onSuccess: () => {
        toast({
          title: 'Email sent successfully',
        });
        setIsSendDialogOpen(false);
      },
      onError: () => {
        toast({
          title: 'Error sending email',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: sendEmailForTestWorkflow, isPending: isSending } = usePostSendMail({
    customConfig: {
      onSuccess: () => {
        toast({
          title: 'Email sent successfully',
        });
        setIsSendDialogOpen(false);
      },
      onError: () => {
        toast({
          title: 'Error sending email',
          variant: 'destructive',
        });
      },
    },
  });

  const selectClasses = useMemo(() => {
    return getClassNamesForSelectPurpose();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false | true, IOptions>();
  }, []);

  const [errors, setErrors] = useState<Record<keyof Omit<IEmailProperties, 'clientId'>, string>>({
    from: '',
    to: '',
    cc: '',
  });

  const [emailProperties, setEmailProperties] = useState<Omit<IEmailProperties, 'clientId'>>({
    from: '',
    to: [],
    cc: [],
  });

  const [ccEmailValue, setCCEmailValue] = useState<string>('');
  const [toEmailValue, setToEmailValue] = useState<string>('');

  const ccSelectValue = useMemo(() => {
    return emailProperties.cc?.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
  }, [emailProperties]);

  const toSelectValue = useMemo(() => {
    return emailProperties.to?.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
  }, [emailProperties]);

  const handleFromEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailRegex = /^[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;

    setErrors({
      ...errors,
      from: '',
    });

    if (!emailRegex.test(e.target.value)) {
      setErrors({
        ...errors,
        from: 'Invalid email address',
      });
    }

    setEmailProperties({
      ...emailProperties,
      from: e.target.value,
    });
  };

  const handleCCChange = (
    value: MultiValue<{
      label: string;
      value: string;
    }>,
  ) => {
    setErrors((prev) => {
      return {
        ...prev,
        cc: '',
      };
    });

    setEmailProperties({
      ...emailProperties,
      cc: value.map((v) => v.value),
    });
  };

  const handleCCKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!ccEmailValue) return;

    setErrors((prev) => {
      return {
        ...prev,
        cc: '',
      };
    });

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    switch (e.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
      case ',':
        if (!emailRegex.test(ccEmailValue)) {
          setErrors((prev) => {
            return {
              ...prev,
              cc: 'Invalid email address',
            };
          });
          return;
        }

        if (emailProperties.cc?.length) {
          setEmailProperties({
            ...emailProperties,
            cc: [...emailProperties.cc, ccEmailValue],
          });
        } else {
          setEmailProperties({
            ...emailProperties,
            cc: [ccEmailValue],
          });
        }

        setCCEmailValue('');
        e.preventDefault();
    }
  };

  const handleToChange = (
    value: MultiValue<{
      label: string;
      value: string;
    }>,
  ) => {
    setErrors((prev) => {
      return {
        ...prev,
        to: '',
      };
    });

    setEmailProperties({
      ...emailProperties,
      to: value.map((v) => v.value),
    });
  };

  const handleToKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!toEmailValue) return;

    setErrors((prev) => {
      return {
        ...prev,
        to: '',
      };
    });

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    switch (e.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
      case ',':
        if (!emailRegex.test(toEmailValue)) {
          setErrors((prev) => {
            return {
              ...prev,
              to: 'Invalid email address',
            };
          });
          return;
        }

        if (emailProperties.to?.length) {
          setEmailProperties({
            ...emailProperties,
            to: [...emailProperties.to, toEmailValue],
          });
        } else {
          setEmailProperties({
            ...emailProperties,
            to: [toEmailValue],
          });
        }

        setToEmailValue('');
        e.preventDefault();
    }
  };

  const handleSend = () => {
    const { isValid, errors: validationErrors } = validateEmailProperties(emailProperties);

    if (!isValid) {
      setErrors(validationErrors);
      return;
    }
    if (isForWorkflow !== true) {
      sendEmail({
        cc: emailProperties.cc ?? [],
        from: emailProperties.from ?? '',
        to: emailProperties.to ?? [],
        variables: variables,
      });
    } else {
      sendEmailForTestWorkflow({
        to: emailProperties.to ?? [],
        fromEmail: emailProperties.from ?? '',
        cc: emailProperties.cc ?? [],
        body: body ?? '',
        subject: subject ?? '',
        templateId: templateId,
      });
    }
  };

  return (
    <>
      <Button onClick={() => setIsSendDialogOpen(true)}>Send test mail</Button>
      {isSendDialogOpen && (
        <Dialog open={isSendDialogOpen} onOpenChange={setIsSendDialogOpen}>
          <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
            <DialogHeader className=" font-semibold ">Send email</DialogHeader>
            <div>
              <div>
                <div>From</div>
                <Input onChange={handleFromEmailInputChange} value={emailProperties.from} />
                <p className="text-destructive text-xs h-6">{errors.from}</p>
              </div>
              <div>
                <div>To</div>
                <AsyncSelect
                  className=" min-w-[150px] max-w-[360px] w-full text-sm shadow-sm"
                  inputValue={toEmailValue}
                  isMulti={true}
                  onInputChange={(newValue, action) => {
                    if (action.action === 'input-change') {
                      setToEmailValue(newValue);
                    }
                  }}
                  components={{
                    DropdownIndicator: () => null,
                  }}
                  onChange={handleToChange}
                  menuIsOpen={false}
                  onKeyDown={handleToKeydown}
                  value={toSelectValue}
                  placeholder="Enter identifiers"
                  styles={selectStyles}
                  classNames={selectClasses}
                />
                <p className="text-destructive text-xs h-6">{errors.to}</p>
              </div>
              <div>
                <div>CC</div>
                <AsyncSelect
                  className=" min-w-[150px] max-w-[360px] w-full text-sm shadow-sm"
                  inputValue={ccEmailValue}
                  isMulti={true}
                  onInputChange={(newValue, action) => {
                    if (action.action === 'input-change') {
                      setCCEmailValue(newValue);
                    }
                  }}
                  components={{
                    DropdownIndicator: () => null,
                  }}
                  onChange={handleCCChange}
                  menuIsOpen={false}
                  onKeyDown={handleCCKeydown}
                  value={ccSelectValue}
                  placeholder="Enter identifiers"
                  styles={selectStyles}
                  classNames={selectClasses}
                />
                <p className="text-destructive text-xs h-6">{errors.cc}</p>
              </div>
            </div>
            <DialogFooter>
              <Button onClick={() => setIsSendDialogOpen(false)} variant="outline">
                Cancel
              </Button>
              <Button disabled={isPending || isSending} onClick={handleSend} className="flex items-center gap-2">
                Send
                {(isPending || isSending) && <Loader2Icon className="w-4 h-4 animate-spin" />}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const PreviewPage = () => {
  const { subject, body, variables } = usePreviewStore();
  const [mediumType, setMediumType] = useState<'phone' | 'desktop'>('desktop');
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [searchParams] = useSearchParams();

  const isForWorkFlow = searchParams.get('isForWorkflow');

  useEffect(() => {
    if (iframeRef.current) {
      const htmlElement = iframeRef.current.querySelector('html');
      if (htmlElement) {
        htmlElement.style.overflow = 'scroll';
      }
    }
  }, []);
  if (isForWorkFlow && isForWorkFlow !== 'true' && variables.length < 1) {
    return null;
  }

  return (
    <div className=" p-4 flex flex-col h-screen overflow-scroll  ">
      <div className=" flex justify-between gap-4">
        <div className=" w-full flex items-center justify-between ">
          <h1 className=" text-2xl ">Preview</h1>
          <SendEmailDialog
            variables={variables}
            isForWorkflow={isForWorkFlow ? true : false}
            body={body}
            subject={subject}
          />
        </div>
        <Tabs onValueChange={(value) => setMediumType(value as 'phone' | 'desktop')} value={mediumType}>
          <TabsList>
            <TabsTrigger value="phone">Phone</TabsTrigger>
            <TabsTrigger value="desktop">Desktop</TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className=" my-4 flex flex-col flex-1  ">
        <div
          style={{
            width: mediumType === 'phone' ? '400px' : '100%',
          }}
          className=" flex flex-col gap-4 border p-2 rounded-md h-full flex-1 template-preview"
        >
          <div className=" border-b pb-4 ">
            <h3 className=" font-semibold text-sm preview-title ">Subject:</h3>
            <div
              className=" w-full max-h-fit   "
              dangerouslySetInnerHTML={{ __html: subject }}
              title="Preview subject"
            />
          </div>
          <div className=" overflow-auto flex-1   ">
            <div dangerouslySetInnerHTML={{ __html: body }} />
            {/* <iframe
              ref={iframeRef}
              className=" w-full h-full overflow-scroll "
              srcDoc={`<style>html { overflow: scroll; } </style>${body}`}
              title="Preview body"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPage;
