import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import { useToast } from '@/components/ui/use-toast';
import { useUserContext } from '@/contexts/UserContext';
import {
  usePostCreateActionOnlyState,
  usePostCreateConditionalState,
  usePostCreateEmailAction,
  usePostCreateWaitState,
} from '@/hooks/api-hooks/useWorkflowQuery';
import { IState, StateTypes } from '@/types/workflow.type';
import { createId } from '@/utils/createId';
import { getLayoutedElements } from '@/utils/workflow/getLayoutedElements';
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { Loader2Icon, PlusIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';
import {
  BaseEdge,
  Edge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  Node,
  useEdges,
  useNodes,
  useReactFlow,
} from 'reactflow';

const addLoadingNode = <T,>({
  targetNode,
  target,
  source,
  nodes,
  edges,
  edgeId,
  newId,
}: {
  targetNode: Node<T>;
  source: string;
  target: string;
  nodes: Node<IState<StateTypes>>[];
  edges: Edge<unknown>[];
  edgeId: string;
  newId: string;
}) => {
  const newNode: Node<IState<'LOADING'>> = {
    id: newId,
    type: 'LOADING',
    height: 200,
    data: {
      label: 'Loading...',
      type: 'LOADING',
      workflowId: '',
      id: '',
      properties: {
        type: 'LOADING',
      },
      transitionAction: '',
    },
    position: {
      x: targetNode.position.x,
      y: targetNode.position.y + 300,
    },
  };

  const newNodes = [
    ...nodes.map((node) => {
      if (node.id === targetNode.id) {
        return {
          ...node,
          position: {
            x: targetNode.position.x,
            y: targetNode.position.y + 300,
          },
        };
      }

      return node;
    }),
    newNode,
  ];

  const newEdges = [
    ...edges.map((item) => {
      if (item.id === edgeId) {
        return {
          ...item,
          source: newId,
          sourceHandle: 'default',
          targetHandle: 'default',
          target,
          isDisabled: true,
          data: {
            isDisabled: true,
          },
        };
      }

      return {
        ...item,
      };
    }),
    {
      id: createId(),
      source,
      target: newId,
      type: 'button',
      sourceHandle: 'default',
      targetHandle: 'default',
      isDisabled: true,
      data: {
        isDisabled: true,
      },
    },
  ];

  return getLayoutedElements(newNodes, newEdges, { direction: 'TB' });
};

export default function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  ...props
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { setEdges, setNodes } = useReactFlow<IState<StateTypes>>();

  const nodes = useNodes<IState<StateTypes>>();

  const edges = useEdges();

  const { target, source, data } = props;

  const { toast } = useToast();

  const { mutateAsync: createEmailAction } = usePostCreateEmailAction({
    customConfig: {
      onError(error) {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to create email action. Please try again.',
        });
      },
    },
  });

  const { mutateAsync: createActionOnlyState } = usePostCreateActionOnlyState({
    customConfig: {
      onError(error) {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to create state. Please try again.',
        });
      },
    },
  });

  const { mutateAsync: createConditionalState } = usePostCreateConditionalState({
    customConfig: {
      onError(error) {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to create state. Please try again.',
        });
      },
    },
  });

  const { mutateAsync: createWaitState } = usePostCreateWaitState({
    customConfig: {
      onError(error) {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to create state. Please try again.',
        });
      },
    },
  });

  const { workflowId } = useParams();

  const handleAddEmail = () => {
    if (!workflowId || !companiesOfUser[activeCompanyIndex]?.id) return;

    const newId = createId();

    const { nodes: nodesToUpdate, edges: edgesToUpdate } = addLoadingNode({
      targetNode: nodes.find((node) => node.id === source) as Node<IState<StateTypes>>,
      target: target,
      nodes,
      edges,
      edgeId: id,
      source: source,
      newId: newId,
    });

    setNodes(nodesToUpdate);
    setEdges(edgesToUpdate);

    createEmailAction({
      workflowId: workflowId,
      email: {
        companyId: companiesOfUser[activeCompanyIndex].id,
        workflowId: workflowId,
      },
    })
      .then((returnedData) => {
        createActionOnlyState({
          action: returnedData.data.id,
          label: 'Email',
          nextState: target,
          previousState: source,
          workflowId: workflowId,
        });
      })
      .catch(() => {
        setNodes((nds) => nds.filter((nd) => nd.id !== newId));
        setEdges((edgs) =>
          edgs
            .filter((ed) => ed.source !== id)
            .map((ed) => {
              if (ed.target === newId) {
                return {
                  ...ed,
                  source: source,
                  target: target,
                  data: {
                    ...ed.data,
                    isDisabled: false,
                  },
                };
              }
              return ed;
            }),
        );
      });
  };

  const handleAddConditional = () => {
    if (!workflowId) return;

    const newId = createId();

    const { nodes: nodesToUpdate, edges: edgesToUpdate } = addLoadingNode({
      targetNode: nodes.find((node) => node.id === source) as Node<IState<StateTypes>>,
      target: target,
      nodes,
      edges,
      edgeId: id,
      source: source,
      newId: newId,
    });

    setNodes(nodesToUpdate);
    setEdges(edgesToUpdate);

    createConditionalState({
      workflowId: workflowId,
      label: 'Conditional',
      nextState: target,
      previousState: source,
      conditionActionTrees: [
        {
          actions: [],
        },
      ],
    }).catch(() => {
      setNodes((nds) => nds.filter((nd) => nd.id !== newId));
      setEdges((edges) =>
        edges
          .filter((ed) => ed.source !== id)
          .map((ed) => {
            if (ed.target === newId) {
              return {
                ...ed,
                source: source,
                target: target,
                data: {
                  isDisabled: false,
                },
              };
            }
            return ed;
          }),
      );
    });
  };

  const handleAddWait = () => {
    if (!workflowId) return;

    const newId = createId();

    const { nodes: nodesToUpdate, edges: edgesToUpdate } = addLoadingNode({
      targetNode: nodes.find((node) => node.id === source) as Node<IState<StateTypes>>,
      target: target,
      nodes,
      edges,
      edgeId: id,
      source: source,
      newId: newId,
    });

    setNodes(nodesToUpdate);
    setEdges(edgesToUpdate);

    createWaitState({
      workflowId: workflowId,
      label: 'Wait',
      nextState: target,
      previousState: source,
      actions: [],
    }).catch(() => {
      setNodes((nds) => nds.filter((nd) => nd.id !== newId));
      setEdges((edges) =>
        edges
          .filter((ed) => ed.source !== id)
          .map((ed) => {
            if (ed.target === newId) {
              return {
                ...ed,
                source: source,
                target: target,
                data: {
                  isDisabled: false,
                },
              };
            }
            return ed;
          }),
      );
    });
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <DropdownMenu>
            <DropdownMenuTrigger disabled={data?.isDisabled} asChild>
              <Button disabled={data?.isDisabled} size="icon" className="edgebutton h-4 w-4">
                {data?.isDisabled ? (
                  <Loader2Icon className="w-3 h-3 animate-spin" />
                ) : (
                  <PlusIcon className=" w-3 h-3 " />
                )}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className=" w-[250px] ">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={handleAddEmail}>Email</DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuLabel>Other</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={handleAddConditional}>If/Else</DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={handleAddWait}>Wait</DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
