'use client';

import { CalendarIcon } from '@radix-ui/react-icons';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export function DatePicker({
  value,
  onChange,
  defaultOpen = false,
  onOpenChange,
  open,
  className,
}: {
  value: Date | undefined;
  onChange: (_: Date | undefined) => void;
  defaultOpen?: boolean;
  onOpenChange?: (_: boolean) => void;
  open?: boolean;
  className?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    defaultOpen && setIsOpen(true);
  }, [defaultOpen]);

  useEffect(() => {
    if (onOpenChange) {
      setIsOpen(!!open);
    }
  }, [open, setIsOpen, onOpenChange]);

  const handleOpenChange = (value: boolean) => {
    setIsOpen(value);
    onOpenChange && onOpenChange(value);
  };

  const handleDateChange = (date: Date | undefined) => {
    onChange(date);
    handleOpenChange(false);
  };

  return (
    <Popover defaultOpen={defaultOpen} open={isOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn('w-[240px] justify-start text-left font-normal', !value && 'text-muted-foreground', className)}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value ? dayjs(value).format('DD MMM YYYY') : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar mode="single" selected={value} onSelect={handleDateChange} initialFocus />
      </PopoverContent>
    </Popover>
  );
}
