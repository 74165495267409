import {
  ICreditTerms,
  IDueDatePaymentTerm,
  IEarlyDiscountPaymentTerm,
  IMinimumGuaranteeTermItem,
  IPrepaidPaymentTerm,
} from '@/types/credit-term.types';
import React, { createContext, useContext, useReducer } from 'react';

interface IMinGuaranteeTermDispatchProps {
  type:
    | 'ATTRIBUTE_UPDATE'
    | 'TERM_APPEND'
    | 'TERM_REMOVE'
    | 'TERM_UPDATE'
    | 'CREATE_NEW'
    | 'DELETE_TERM'
    | 'SET_ERRORS';
  payload:
    | string
    | number
    | IMinimumGuaranteeTermItem
    | ICreditTerms<'MIN_GUARANTEE_TERM'>
    | Map<
        number,
        {
          [key: string]: string;
        }
      >;
  property: keyof ICreditTerms<'MIN_GUARANTEE_TERM'> | keyof ICreditTerms<'MIN_GUARANTEE_TERM'>['terms'];
  updateIndex?: number;
  termIndex: number;
}

interface IPaymentTermWithIsFullUpfront extends ICreditTerms<'PAYMENT_TERM'> {
  isFullUpfront?: boolean;
  errors?: {
    [key: string]: string;
  };
}

interface IMinGuaranteeTermWithErrors extends ICreditTerms<'MIN_GUARANTEE_TERM'> {
  errors?: {
    [key: string]: string;
  };
}

const minimumGuranteeReducer = (
  state: IMinGuaranteeTermWithErrors[],
  action: IMinGuaranteeTermDispatchProps,
): IMinGuaranteeTermWithErrors[] => {
  switch (action.type) {
    case 'ATTRIBUTE_UPDATE':
      return state.map((term, index) => {
        if (index !== action.termIndex) {
          return term;
        }
        return {
          ...term,
          [action.property]: action.payload,
        };
      });

    case 'TERM_APPEND':
      return state.map((term, index) => {
        if (index !== action.termIndex) {
          return term;
        }
        return {
          ...term,
          terms: {
            minimumGuaranteeTerms: term.terms.minimumGuaranteeTerms?.length
              ? [...term.terms.minimumGuaranteeTerms, action.payload as IMinimumGuaranteeTermItem]
              : [action.payload as IMinimumGuaranteeTermItem],
          },
        };
      });
    case 'TERM_REMOVE':
      return state.map((term, index) => {
        if (index !== action.termIndex) {
          return term;
        }
        return {
          ...term,
          terms: {
            ...term.terms,
            minimumGuaranteeTerms: term.terms?.minimumGuaranteeTerms?.filter(
              (_: IMinimumGuaranteeTermItem, index: number) => index !== action.updateIndex,
            ),
          },
        };
      });

    case 'TERM_UPDATE':
      return state.map((term, index) => {
        if (index !== action.termIndex) {
          return term;
        }
        return {
          ...term,
          terms: {
            ...term.terms,
            minimumGuaranteeTerms: term.terms.minimumGuaranteeTerms?.map((individualTerm, index) => {
              if (index === action.updateIndex) {
                return action.payload as IMinimumGuaranteeTermItem;
              }
              return individualTerm;
            }),
          },
        };
      });

    case 'CREATE_NEW':
      return [...state, action.payload as ICreditTerms<'MIN_GUARANTEE_TERM'>];

    case 'DELETE_TERM':
      return state.filter((term, index) => index !== action.termIndex);

    case 'SET_ERRORS': {
      const errorMap = action.payload as Map<
        number,
        {
          [key: string]: string;
        }
      >;
      return state.map((term, index) => {
        if (errorMap.has(index)) {
          return {
            ...term,
            errors: errorMap.get(index) || {},
          };
        }

        return term;
      });
    }

    default:
      return state;
  }

  return state;
};

interface IPaymentTermDispatch {
  type:
    | 'ATTRIBUTE_UPDATE'
    | 'TERM_UPDATE'
    | 'TERM_APPEND'
    | 'TERM_REMOVE'
    | 'APPEND_EARLY_DISCOUNT'
    | 'REMOVE_EARLY_DISCOUNT'
    | 'REMOVE_ALL_EARLY_DISCOUNT'
    | 'UPDATE_EARLY_DISCOUNT'
    | 'CREATE_NEW'
    | 'SET_AS_FULL_UPFRONT'
    | 'SET_ERRORS';
  payload:
    | string
    | number
    | IEarlyDiscountPaymentTerm
    | ICreditTerms<'PAYMENT_TERM'>
    | boolean
    | Map<
        number,
        {
          [key: string]: string;
        }
      >;
  property: keyof ICreditTerms<'PAYMENT_TERM'> | keyof ICreditTerms<'PAYMENT_TERM'>['terms'] | 'isFullUpfront';
  subField?: keyof IPrepaidPaymentTerm | keyof IDueDatePaymentTerm | keyof IEarlyDiscountPaymentTerm;
  updateIndex?: number;
  termIndex: number;
}

const PaymentTermContext = createContext<
  | {
      paymentTerms: IPaymentTermWithIsFullUpfront[];
      dispatchPaymentTerm: React.Dispatch<IPaymentTermDispatch>;
    }
  | undefined
>(undefined);

const MinGuaranteeTermContext = createContext<{
  minGuaranteeTerms: ICreditTerms<'MIN_GUARANTEE_TERM'>[];
  dispatchMinGuaranteeTerm: React.Dispatch<IMinGuaranteeTermDispatchProps>;
}>({
  minGuaranteeTerms: [],
  dispatchMinGuaranteeTerm: () => {},
});

const paymentTermReducer = (state: IPaymentTermWithIsFullUpfront[], action: IPaymentTermDispatch) => {
  switch (action.type) {
    case 'ATTRIBUTE_UPDATE':
      return state.map((term, index) => {
        if (index !== action.termIndex) {
          return term;
        }

        return {
          ...term,
          errors: {
            ...term.errors,
            [action.property]: undefined,
          },
          [action.property]: action.payload,
        };
      });
    case 'TERM_UPDATE':
      return state.map((term, index) => {
        if (index !== action.termIndex) {
          return term;
        }

        if (action.subField === 'percentage') {
          return {
            ...term,
            terms: {
              ...term.terms,
              [action.property]: {
                ...term.terms[action.property as keyof ICreditTerms<'PAYMENT_TERM'>['terms']],
                [action.subField!]: action.payload,
                amount: 0,
              },
            },
          };
        }

        if (action.subField === 'amount') {
          return {
            ...term,
            terms: {
              ...term.terms,
              [action.property]: {
                ...term.terms[action.property as keyof ICreditTerms<'PAYMENT_TERM'>['terms']],
                [action.subField!]: action.payload,
                percentage: 0,
              },
            },
          };
        }

        return {
          ...term,
          terms: {
            ...term.terms,
            [action.property]: {
              ...term.terms[action.property as keyof ICreditTerms<'PAYMENT_TERM'>['terms']],
              [action.subField!]: action.payload,
            },
          },
        };
      });

    case 'APPEND_EARLY_DISCOUNT':
      return state.map((term, index) => {
        if (index !== action.termIndex) {
          return term;
        }
        return {
          ...term,
          terms: {
            ...term.terms,
            earlyDiscountPaymentTerm: term.terms.earlyDiscountPaymentTerm?.length
              ? [...term.terms.earlyDiscountPaymentTerm, action.payload as IEarlyDiscountPaymentTerm]
              : [action.payload as IEarlyDiscountPaymentTerm],
          },
        };
      });

    case 'REMOVE_EARLY_DISCOUNT':
      return state.map((term, index) => {
        if (index !== action.termIndex) {
          return term;
        }
        return {
          ...term,
          terms: {
            ...term.terms,
            earlyDiscountPaymentTerm: term.terms.earlyDiscountPaymentTerm?.length
              ? term.terms.earlyDiscountPaymentTerm.filter((_, index) => index !== action.updateIndex)
              : term.terms.earlyDiscountPaymentTerm,
          },
        };
      });

    case 'REMOVE_ALL_EARLY_DISCOUNT':
      return state.map((term, index) => {
        if (index !== action.termIndex) {
          return term;
        }
        return {
          ...term,
          terms: {
            ...term.terms,
            earlyDiscountPaymentTerm: undefined,
          },
        };
      });

    case 'UPDATE_EARLY_DISCOUNT':
      return state.map((term, index) => {
        if (index !== action.termIndex) {
          return term;
        }
        return {
          ...term,
          terms: {
            ...term.terms,
            earlyDiscountPaymentTerm: term.terms.earlyDiscountPaymentTerm?.length
              ? term.terms.earlyDiscountPaymentTerm.map((earlyDiscount, index) => {
                  if (index === action.updateIndex) {
                    return action.payload as IEarlyDiscountPaymentTerm;
                  }
                  return earlyDiscount;
                })
              : term.terms.earlyDiscountPaymentTerm,
          },
        };
      });

    case 'CREATE_NEW':
      return [...state, action.payload as ICreditTerms<'PAYMENT_TERM'>];

    case 'TERM_REMOVE':
      return state.filter((_, index) => index !== action.termIndex);

    case 'SET_AS_FULL_UPFRONT':
      return state.map((term, index) => {
        if (index !== action.termIndex) {
          return term;
        }
        return {
          ...term,
          isFullUpfront: true,
          terms: {
            dueDatePaymentTerm: undefined,
            earlyDiscountPaymentTerm: [],
            prepaidPaymentTerm: {
              percentage: 100,
            },
          },
        };
      });

    case 'SET_ERRORS': {
      const errorMap = action.payload as Map<
        number,
        {
          [key: string]: string;
        }
      >;
      return state.map((term, index) => {
        if (errorMap.has(index)) {
          return {
            ...term,
            errors: errorMap.get(index) || {},
          };
        }

        return term;
      });
    }
  }

  return state;
};

const CreditTermProvider = ({ children }: { children: React.ReactNode }) => {
  const [paymentTerms, dispatchPaymentTerm] = useReducer(paymentTermReducer, []);
  const [minGuaranteeTerms, dispatchMinGuaranteeTerm] = useReducer(minimumGuranteeReducer, []);

  const paymentTermValues = {
    paymentTerms,
    dispatchPaymentTerm,
  };

  return (
    <MinGuaranteeTermContext.Provider
      value={{
        minGuaranteeTerms,
        dispatchMinGuaranteeTerm,
      }}
    >
      <PaymentTermContext.Provider value={paymentTermValues}>{children}</PaymentTermContext.Provider>
    </MinGuaranteeTermContext.Provider>
  );
};

export default CreditTermProvider;

export const usePaymentTermContext = () => {
  const values = useContext(PaymentTermContext);

  if (!values) {
    throw new Error('usePaymentTermContext must be used within a PaymentTermProvider');
  }

  return {
    terms: values.paymentTerms,
    dispatch: values.dispatchPaymentTerm,
  };
};

export const useMinGuaranteeTermContext = () => {
  const values = useContext(MinGuaranteeTermContext);

  if (!values) {
    throw new Error('useMinGuaranteeTermContext must be used within a MinGuaranteeTermProvider');
  }

  return {
    terms: values.minGuaranteeTerms,
    dispatch: values.dispatchMinGuaranteeTerm,
  };
};
