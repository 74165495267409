'use client';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import SelectComponent from '@/components/ui/select-component';
import { useStore } from '@/stores/email-builder/page-sections.store';
import { createId } from '@/utils/createId';
import { Section } from '@/utils/email-builder/sections';
import { propertiesMap } from '@/utils/type-map';
import { CheckIcon, PlusIcon, XIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const AddColumn = () => {
  const [isAddColumn, setIsAddColumn] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<{
    fieldName: string;
  }>();

  const { updateSection, section } = useStore((store) => ({
    updateSection: store.updateSection,
    section: store.selectedSection ? store.sections[store.selectedSection] : null,
  }));

  const variableName = (section as Section<'table'>)?.content?.content?.variable === 'Invoice' ? 'invoice' : 'manual';

  const handleAddColumn = (fieldName: string) => {
    if (!section) return;

    const currentSection = section as Section<'table'>;

    if (variableName === 'manual') {
      updateSection({
        ...currentSection,
        content: {
          ...currentSection.content,
          content: {
            ...currentSection.content.content,
            columns: [
              ...currentSection.content.content.columns,
              {
                fieldName: fieldName,
                isVariable: currentSection.content.content.variable !== 'Manual',
                label: fieldName,
                columnId: createId(),
                operation: 'none',
                operationLabel: '',
              },
            ],
          },
        },
      });
    } else {
      updateSection({
        ...currentSection,
        content: {
          ...currentSection.content,
          content: {
            ...currentSection.content.content,
            columns: [
              ...currentSection.content.content.columns,
              {
                fieldName: fieldName,
                isVariable: currentSection.content.content.variable !== 'Manual',
                label: fieldName,
                columnId: createId(),
                operation: 'none',
                operationLabel: '',
              },
            ],
            rows: [
              {
                ...currentSection.content.content.rows[0],
                [fieldName]: fieldName,
              },
            ],
          },
        },
      });
    }
    setIsAddColumn(false);
  };

  const columnPresenceMap = useMemo(() => {
    return (
      (section?.type === 'table' &&
        !!(section.content as Section<'table'>['content']).content.columns.length &&
        (section.content as Section<'table'>['content']).content.columns.reduce(
          (acc, current) => {
            return {
              ...acc,
              [current.fieldName]: true,
            };
          },
          {} as Record<string, boolean>,
        )) ||
      ({} as Record<string, boolean>)
    );
  }, [section]);

  const onSubmit = (data: { fieldName: string }) => {
    handleAddColumn(data.fieldName);
  };

  const options = useMemo(() => {
    return (
      (variableName !== 'manual' &&
        propertiesMap?.[variableName] &&
        Object.keys(propertiesMap?.[variableName])
          ?.map((key) => ({
            label: `{{${variableName.toLowerCase()}.${key}}}`,
            value: `{{${variableName.toLowerCase()}.${key}}}`,
          }))
          .filter((item) => !columnPresenceMap[item.value])) ||
      []
    );
  }, [variableName, columnPresenceMap]);

  const handleClose = () => {
    reset();
    setIsAddColumn(false);
  };

  if (!isAddColumn) {
    return (
      <div className=" flex justify-end items-center my-2 ">
        <Button onClick={() => setIsAddColumn(true)} size="icon" variant="outline">
          <PlusIcon className="h-4 w-4" />
        </Button>
      </div>
    );
  }

  return variableName === 'manual' ? (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className=" flex items-center gap-4">
        <div>
          <Input
            placeholder="Column Field"
            className=" flex-1 "
            {...register('fieldName', {
              required: {
                value: true,
                message: 'Column name is required',
              },
            })}
          />
        </div>
        <Button onClick={handleClose} className=" flex-1 min-w-[24px] " size="icon" variant="outline">
          <XIcon className="h-4 w-4" />
        </Button>
        <Button type="submit" size="icon" className=" flex-1 min-w-[24px] " variant="outline">
          <CheckIcon className="h-4 w-4" />
        </Button>
      </form>
      <p className=" text-xs text-destructive ">{errors?.fieldName?.message}</p>
    </div>
  ) : (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className=" flex items-center gap-2">
        <Controller
          name="fieldName"
          rules={{
            required: {
              value: true,
              message: 'Column name is required',
            },
          }}
          control={control}
          render={({ field }) => (
            <SelectComponent className=" w-7/10 " value={field.value} onChange={field.onChange} options={options} />
          )}
        />
        <Button onClick={handleClose} className=" flex-1 min-w-[24px] " size="icon" variant="outline">
          <XIcon className="h-4 w-4" />
        </Button>
        <Button type="submit" size="icon" className=" flex-1 min-w-[24px] " variant="outline">
          <CheckIcon className="h-4 w-4" />
        </Button>
      </form>
      <p className=" text-xs text-destructive ">{errors?.fieldName?.message}</p>
    </div>
  );
};

export default AddColumn;
