'use client';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { IOptions } from '@/types/common.types';
import { IPlaceholder } from '@/types/posting.types';
import { ChevronDownIcon } from 'lucide-react';
import { useMemo, useState } from 'react';

export function PostedToDropDown({
  selected,
  handleSelect,
  className = '',
  creditPlaceholder,
}: {
  selected?: IOptions;
  handleSelect: (_: IOptions) => void; // Remove function returning a function
  className?: string;
  creditPlaceholder?: IPlaceholder[];
}) {
  const [open, setOpen] = useState(false);

  const handleOptionClick = (option: IOptions) => {
    handleSelect(option);
    setOpen(false);
  };

  const options = useMemo<
    {
      label: string;
      value: string;
    }[]
  >(() => {
    const dynamicOptions =
      creditPlaceholder?.map((placeholder) => ({
        label: placeholder.name,
        value: placeholder.id,
      })) || [];
    return [
      {
        label: 'All',
        value: 'ALL',
      },
      {
        label: 'Invoice',
        value: 'INVOICE',
      },

      ...dynamicOptions,
    ];
  }, [creditPlaceholder]);

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild>
        <Button
          className="flex items-center gap-4 text-left "
          onClick={() => setOpen((prev) => !prev)}
          variant="outline"
        >
          <span className={cn(' flex-1 truncate text-left w-[150px] capitalize overflow-ellipsis', className)}>
            {selected ? (
              selected.label
            ) : (
              <span className=" transform-none normal-case text-muted-foreground ">Select posted to</span>
            )}
          </span>
          <ChevronDownIcon className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        onInteractOutside={() => setOpen(false)}
        onEscapeKeyDown={() => setOpen(false)}
        onFocusOutside={() => setOpen(false)}
        onPointerDownOutside={() => setOpen(false)}
        className="w-56"
      >
        <DropdownMenuLabel>Posted to</DropdownMenuLabel>
        <DropdownMenuSeparator />

        {options.map((option) => (
          <DropdownMenuItem
            className="normal-case"
            key={option.value}
            onClick={() => {
              handleOptionClick(option);
            }}
          >
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
