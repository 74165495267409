import ActivityList from '@/components/activity/ActivityList';
import { Layout } from '@/components/shared/Layout';

const ActivityListPage = () => {
  return (
    <Layout className="overflow-scroll">
      <ActivityList />
    </Layout>
  );
};

export default ActivityListPage;
