import { dataAxiosInstance } from '@/lib/axios';
import {
  CreditTermType,
  ICreditTerms,
  ICreditTermsResponse,
  ICreditTermsWithCustomerId,
} from '@/types/credit-term.types';

export const getCreditTermsById = async (id: string) => {
  const result = await dataAxiosInstance.get(`/creditTerms/${id}`);
  return result.data;
};

export const getCreditTerms = async () => {
  const result = await dataAxiosInstance.get('/creditTerms');
  return result.data;
};

export const postCreateCreditTerm = async (terms: ICreditTermsWithCustomerId[]) => {
  const result = await dataAxiosInstance.post('/creditTerms', {
    terms: terms,
  });
  return result.data;
};

export const markAsDefault = async (creditTermId: string) => {
  const result = await dataAxiosInstance.post(`/creditTerms/markDefault`, {
    creditTermId,
  });
  return result.data;
};

export const deleteCreditTerm = async (creditTermId: string) => {
  const result = await dataAxiosInstance.delete(`/creditTerms/delete/${creditTermId}`);
  return result.data;
};

export const updateTerm = async ({
  creditTermId,
  term,
}: {
  creditTermId: string;
  term: ICreditTerms<CreditTermType>;
}) => {
  const result = await dataAxiosInstance.post(`/creditTerms/update`, {
    creditTermId,
    creditTermType: term.type,
    updates: {
      fields: ['title', 'description', 'terms', 'type'],
      values: {
        ...term,
      },
    },
  });
  return result.data;
};

export const getCreditTermsByCustomerId = async ({ customerId }: { customerId: string }) => {
  const result = await dataAxiosInstance.get<ICreditTermsResponse>(`/creditTerms/customer/${customerId}`);
  return result.data;
};

export const getCreditTermTitleSuggestions = async ({ type }: { type: CreditTermType }) => {
  const result = await dataAxiosInstance.get('/creditTerms/title/suggestions?creditTermType=' + type);
  return result.data;
};

export const getVariantsByCreditTermTitle = async (title: string, credit_term_type: CreditTermType) => {
  const result = await dataAxiosInstance.get(`/creditTerms/variants?title=${title}&creditTermType=${credit_term_type}`);
  return result.data;
};

export const postAddCustomerTermRelation = async ({
  customerId,
  creditTermId,
}: {
  customerId: string;
  creditTermId: string;
}) => {
  const result = await dataAxiosInstance.post('/creditTerms/addCustomerRelationship', {
    customerId,
    creditTermId,
  });
  return result.data;
};

export const postRemoveCustomerTermRelation = async ({
  customerId,
  creditTermId,
}: {
  customerId: string;
  creditTermId: string;
}) => {
  const result = await dataAxiosInstance.post('/creditTerms/removeCustomerRelationship', {
    customerId,
    creditTermId,
  });
  return result.data;
};

export const getExportCustomerExcelForCreditTerm = async (creditTermId: string) => {
  const result = await dataAxiosInstance.get(`/creditTerms/${creditTermId}/customer/excel`, {
    responseType: 'blob',
  });
  return result;
};
