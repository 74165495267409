import ClubbedSelectWithInput from '@/components/shared/ClubbedSelectAndInput';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import SelectComponent from '@/components/ui/select-component';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import {
  useBulkPromiseToPayUpdateMutation,
  useGetPromiseToPayReasonListMutation,
  useGetPromiseToPayReasonRequired,
  usePostUpdateInvoiceMutation,
} from '@/hooks/api-hooks/useInvoiceQuery';
import { cn } from '@/lib/utils';
import {
  IBulkPromiseToPayUpdateRequest,
  IDiscount,
  IInvoice,
  IInvoiceStatus,
  IPromiseToPayDate,
} from '@/types/invoices.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { FormLabel } from '@mui/material';

import {
  useCancelWriteOffFromInvoicePageMutation,
  useCreateWriteOffMutation,
} from '@/hooks/api-hooks/useWriteOffQuery';
import { ICreateWriteOffRequest, IWriteOff, IWriteOffActionList } from '@/types/write-off.types';
import { getSymbol } from '@/utils/currencySymbolUtil';
import { Table } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { CalendarRange, CheckIcon, Edit2Icon, Loader2Icon, PlusIcon, StickyNoteIcon, XIcon } from 'lucide-react';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { DatePicker } from '../ui/date-picker';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../ui/select';

export const EditInvoiceStatus = ({
  invoiceStatus,
  invoiceId,
}: {
  invoiceStatus: IInvoiceStatus;
  invoiceId: string;
}) => {
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState<IInvoiceStatus>(invoiceStatus);
  const { toast } = useToast();

  const { mutate: updateInvoice } = usePostUpdateInvoiceMutation({
    invoiceId,
    customConfig: {
      onError: () => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
        setSelectedInvoiceStatus(invoiceStatus);
      },
    },
  });

  const options = useMemo<
    {
      label: string;
      value: IInvoiceStatus;
    }[]
  >(
    () => [
      {
        value: 'OVERDUE',
        label: 'Overdue',
      },
      {
        value: 'DUE',
        label: 'Due',
      },
      {
        value: 'NO_DUES',
        label: 'No dues',
      },
    ],
    [],
  );

  const handleChange = (value: string) => {
    setSelectedInvoiceStatus(value as IInvoiceStatus);
    updateInvoice({
      fields: ['invoiceStatus'],
      updateObject: {
        invoiceStatus: value as IInvoiceStatus,
      },
    });
  };

  return <SelectComponent options={options} value={selectedInvoiceStatus} onChange={handleChange} />;
};

export const EditReferenceType = ({
  defaultValue,
  invoiceId,
  className,
}: {
  defaultValue: string | null;
  invoiceId: string;
  className?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState('');
  const { toast } = useToast();

  const { mutate: updateInvoice, isPending } = usePostUpdateInvoiceMutation({
    invoiceId,
    customConfig: {
      onError: () => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
        setValue(defaultValue);
      },
    },
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors('');
    if (e.target.value.length > 100) {
      setErrors('Reference type is too long');
    }
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (errors.trim()) {
      return;
    }

    if (value == defaultValue) {
      setIsEditing(false);
      return;
    }
    updateInvoice({
      fields: ['referenceType'],
      updateObject: {
        referenceType: value?.trim() || null,
      },
    });
    setIsEditing(false);
  };

  const handleBlur = () => {
    handleSave();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
    setErrors('');
  }, [isEditing]);

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
    setValue(defaultValue);
  };

  return isEditing ? (
    <div className=" flex items-start gap-2 ">
      <div>
        <Input
          onKeyDown={handleKeyDown}
          ref={inputRef}
          onBlur={handleBlur}
          value={value ?? ''}
          onChange={handleChange}
        />
        <p className=" text-xs text-red-500 h-4 ">{!!errors.trim() && errors}</p>
      </div>
      <Button className="my-0 relative bottom-0.5" onClick={handleCancel} variant="outline" size="icon">
        <XIcon className=" h-4 w-4 " />
      </Button>
    </div>
  ) : (
    <div
      onClick={handleClick}
      className={cn(
        ' hover:ring-1 ring-primary cursor-pointer px-4 rounded-md py-1 flex items-center gap-4 ',
        className,
      )}
    >
      {value ? value : 'Add reference type'}
      {isPending && <Loader2Icon className="animate-spin h-4 w-4" />}
    </div>
  );
};

export const EditReceivedAmount = ({
  defaultValue,
  className,
  invoiceId,
  currencyType,
}: {
  defaultValue: number;
  className?: string;
  invoiceId: string;
  currencyType: string | undefined;
}) => {
  return (
    <div className={cn('px-4 rounded-md py-1 flex items-center gap-4 ', className)}>
      {formatCurrencyByUnit(isNaN(Number(defaultValue)) ? 0 : Number(defaultValue), 'actual', currencyType)}
      <Link to={`/posting/invoice/view/${invoiceId}?shouldAdapt=true`}>
        <Button size="icon" variant="outline">
          <PlusIcon className=" h-4 w-4 " />
        </Button>
      </Link>
    </div>
  );
};

export const EditReferenceText = ({
  defaultValue,
  className,
  invoiceId,
  referenceType,
}: {
  defaultValue: string | null;
  className?: string;
  invoiceId: string;
  referenceType?: string | null;
}) => {
  const [isEditing, setIsEditing] = useState(false && !!referenceType);
  const [value, setValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState('');
  const { toast } = useToast();

  const { mutate: updateInvoice, isPending } = usePostUpdateInvoiceMutation({
    invoiceId,
    customConfig: {
      onError: () => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
        setValue(defaultValue);
      },
    },
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setIsEditing(true && !!referenceType);
    if (!referenceType) {
      toast({
        title: 'Reference type is none',
        description: 'Please add reference type first',
        duration: 1000,
        variant: 'destructive',
      });
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors('');

    if (e.target.value.length > 100) {
      setErrors('Reference text is too long');
    }
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (errors.trim()) {
      return;
    }

    if (value == defaultValue) {
      setIsEditing(false);
      return;
    }
    updateInvoice({
      fields: ['referenceText'],
      updateObject: {
        referenceText: value?.trim() || null,
      },
    });
    setIsEditing(false);
  };

  const handleBlur = () => {
    handleSave();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
    setErrors('');
  }, [isEditing]);

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
    setValue(defaultValue);
  };

  return isEditing ? (
    <div className=" flex items-start gap-2 ">
      <div>
        <Input
          onKeyDown={handleKeyDown}
          ref={inputRef}
          onBlur={handleBlur}
          value={value ?? ''}
          className=" h-8 "
          onChange={handleChange}
        />
        <p className=" text-xs text-red-500 h-4 ">{!!errors.trim() && errors}</p>
      </div>
      <Button className="my-0 relative bottom-0.5" onClick={handleCancel} variant="outline" size="icon">
        <XIcon className=" h-4 w-4 " />
      </Button>
    </div>
  ) : (
    <div
      onClick={handleClick}
      className={cn(
        ' hover:ring-1 ring-primary cursor-pointer px-4 rounded-md py-1 flex items-center gap-4 ',
        className,
      )}
    >
      {value ? value : 'Add reference text'}
      {isPending && <Loader2Icon className="animate-spin h-4 w-4" />}
    </div>
  );
};
export const EditInvoiceTds = ({
  defaultValue,
  className,
  invoiceId,
  invoice,
}: {
  defaultValue: string;
  className?: string;
  invoiceId: string;
  invoice: IInvoice;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(String(defaultValue));
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState('');
  const { toast } = useToast();

  const { mutate: updateInvoice, isPending } = usePostUpdateInvoiceMutation({
    invoiceId,
    customConfig: {
      onError: (error) => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to edit TDS',
        });
        setValue(defaultValue);
      },
    },
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors('');
    const inputValue = e.target.value.trim();
    if (!/^\d+(\.\d+)?$/.test(inputValue) && inputValue.length > 0) {
      setErrors('Invalid amount');
    }
    if (parseInt(e.target.value) > invoice.invoiceTax.total) {
      setErrors('TDS exceeding total');
    }
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (errors.trim()) {
      return;
    }

    if (value == defaultValue) {
      setIsEditing(false);
      return;
    }
    updateInvoice({
      fields: ['invoiceTax'],
      updateObject: {
        invoiceTax: {
          cgst: invoice.invoiceTax.cgst,
          sgst: invoice.invoiceTax.sgst,
          igst: invoice.invoiceTax.igst,
          tds: Number(value) >= 0 ? Number(value) : defaultValue,
          total: invoice.invoiceTax.total,
        },
      },
    });
    setIsEditing(false);
  };

  const handleBlur = () => {
    handleSave();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
    setErrors('');
  }, [isEditing]);

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
    setValue(defaultValue);
  };

  return isEditing ? (
    <div>
      <div className=" flex items-start gap-2  ml-4 w-[200px]">
        <div className="flex">
          <Input
            onKeyDown={handleKeyDown}
            ref={inputRef}
            onBlur={handleBlur}
            value={value ?? ''}
            onChange={handleChange}
          />
        </div>
        <Button className="my-0 relative bottom-0.5" onClick={handleSave} variant="outline" size="icon">
          <CheckIcon className=" h-3 w-3 " />
        </Button>
        <Button className="my-0 relative bottom-0.5" onClick={handleCancel} variant="outline" size="icon">
          <XIcon className=" h-3 w-3 " />
        </Button>
      </div>
      <p className=" text-xs text-red-500 h-4 p-1 ml-4">{!!errors.trim() && errors}</p>
    </div>
  ) : (
    <div
      onClick={handleClick}
      className={cn('hover:ring-1 ring-primary cursor-pointer  rounded-md py-2 flex items-center gap-4 p-1', className)}
    >
      -
      {value != undefined
        ? formatCurrencyByUnit(Number(value), 'actual', invoice.invoiceCurrency)
        : formatCurrencyByUnit(0, 'actual', invoice.invoiceCurrency)}
      {isPending && <Loader2Icon className="animate-spin h-4 w-4" />}
    </div>
  );
};

export const EditReferenceId = ({
  defaultValue,
  className,
  invoiceId,
  referenceType,
}: {
  defaultValue: string | null;
  className?: string;
  invoiceId: string;
  referenceType?: string | null;
}) => {
  const [isEditing, setIsEditing] = useState(false && !!referenceType);
  const [value, setValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState('');
  const { toast } = useToast();

  const { mutate: updateInvoice, isPending } = usePostUpdateInvoiceMutation({
    invoiceId,
    customConfig: {
      onError: () => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
        setValue(defaultValue);
      },
    },
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setIsEditing(true && !!referenceType);
    if (!referenceType) {
      toast({
        title: 'Reference type is none',
        description: 'Please add reference type first',
        duration: 1000,
        variant: 'destructive',
      });
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors('');

    if (e.target.value.length > 100) {
      setErrors('Reference id is too long');
    }
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (errors.trim()) {
      return;
    }

    if (value == defaultValue) {
      setIsEditing(false);
      return;
    }
    updateInvoice({
      fields: ['referenceId'],
      updateObject: {
        referenceId: value?.trim() || null,
      },
    });
    setIsEditing(false);
  };

  const handleBlur = () => {
    handleSave();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
    setErrors('');
  }, [isEditing]);

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
    setValue(defaultValue);
  };

  return isEditing ? (
    <div className=" flex items-start  gap-2 ">
      <div>
        <Input
          onKeyDown={handleKeyDown}
          ref={inputRef}
          onBlur={handleBlur}
          value={value ?? ''}
          onChange={handleChange}
        />
        <p className=" text-xs text-red-500 h-4 ">{!!errors.trim() && errors}</p>
      </div>
      <Button className="my-0 relative bottom-0.5" onClick={handleCancel} variant="outline" size="icon">
        <XIcon className=" h-4 w-4 " />
      </Button>
    </div>
  ) : (
    <div
      onClick={handleClick}
      className={cn(
        ' hover:ring-1 ring-primary cursor-pointer px-4 rounded-md py-1 flex items-center gap-4 ',
        className,
      )}
    >
      {value ? value : 'Add reference text'}
      {isPending && <Loader2Icon className="animate-spin h-4 w-4" />}
    </div>
  );
};

export const DisplayEditHistoryComponent = ({
  history,
}: {
  history: {
    userId?: string;
    updatedAt?: Date;
    userName?: string;
    comment?: string;
  }[];
}) => {
  const historyData = useMemo(() => {
    return [...history].reverse();
  }, [history]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant="outline" size="icon">
          <StickyNoteIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <div className=" flex flex-col gap-8 max-h-64 overflow-y-scroll p-4 ">
          {historyData.map((h, index) => (
            <div
              key={'edit-history' + index}
              className=" flex flex-col  border-secondary border bg-gray-100 rounded-md p-2 "
            >
              <div>
                <div className=" flex items-center gap-2 text-xs ">
                  <span>{h.userName}</span>
                  <span>{dayjs(h.updatedAt).format('DD MMM YYYY')}</span>
                </div>
              </div>
              <div className=" text-sm ">{h.comment}</div>
            </div>
          ))}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const PromiseToPayDateDialog = ({
  open,
  toggleDialog,
  defaultDate,
  isPending,
  handleFormSubmit,
}: {
  open: boolean;
  toggleDialog: (_isOpen: boolean) => void;
  defaultDate?: Date;
  isPending: boolean;
  handleFormSubmit: (_data: { reason: string; date: Date }) => void;
}) => {
  const [isOtherReasonSelected, setIsOtherReasonSelected] = useState<boolean>(false);
  const { toast } = useToast();

  const {
    data: ptpReasonRequiredResponse,
    isError: isPtpReasonRequiredError,
    isLoading: isPtpReasonRequiredLoading,
  } = useGetPromiseToPayReasonRequired({});

  const {
    mutate: fetchPtpReasonList,
    isPending: isPtpReasonListLoading,
    data: ptpReasonListResponse,
    isSuccess: isPtpReasonListFetched,
  } = useGetPromiseToPayReasonListMutation({
    customConfig: {
      onError: () => {
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
      },
    },
  });

  const ptpReasonList = ptpReasonListResponse?.data.reasons.toSorted((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase()),
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
    setFocus,
  } = useForm<{
    reason: string;
    date: Date;
  }>({
    values: {
      date: dayjs(defaultDate).toDate(),
      reason: '',
    },
    mode: 'onChange',
  });

  const date = watch('date');
  const reason = watch('reason');

  useEffect(() => {
    setValue('date', defaultDate === undefined ? dayjs().toDate() : dayjs(defaultDate).toDate());
  }, [defaultDate, setValue]);

  useEffect(() => {
    let timeoutId: number;
    if (isOtherReasonSelected) timeoutId = setTimeout(() => setFocus('reason'));

    return () => clearTimeout(timeoutId);
  }, [isOtherReasonSelected]);

  const isReasonRequired: boolean = isPtpReasonRequiredError || (ptpReasonRequiredResponse?.data.isRequired ?? true);
  const isFormValid = isDirty && date && (isReasonRequired ? reason : true);

  const handleSelectChange = (value: string) => {
    const isOtherSelected = value === 'other';
    setIsOtherReasonSelected(isOtherSelected);
    setValue('reason', isOtherSelected ? '' : value);
  };

  const handlePtpReasonDropdownOpenChange = (open: boolean) => {
    if (open && !isPtpReasonListFetched) {
      fetchPtpReasonList();
    }
  };

  return (
    <Dialog open={open} onOpenChange={toggleDialog}>
      <DialogContent className="sm:max-w-[425px] bg-white">
        <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4 min-w-[300px] p-4 border-2 rounded-md">
          <DialogHeader>
            <DialogTitle>Update Promise to Pay Date</DialogTitle>
          </DialogHeader>

          <div className=" flex flex-col items-start gap-2">
            <p className=" text-sm ">Promise to pay date</p>
            <div className="w-full">
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Date is required',
                  },
                }}
                name="date"
                render={({ field }) => (
                  <>
                    <DatePicker
                      value={date}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      className="w-full"
                    />
                  </>
                )}
              />
              <p className=" text-xs text-red-500 h-4 ">{errors.date?.message || ''}</p>
            </div>
            <p className=" text-sm ">Reason</p>
            <div className=" w-full ">
              <Controller
                control={control}
                name="reason"
                rules={{
                  required: {
                    value: isReasonRequired,
                    message: 'Reason is required',
                  },
                }}
                render={({ field }) => (
                  <>
                    <Select
                      onValueChange={(value) => {
                        field.onChange(value);
                        handleSelectChange(value);
                      }}
                      onOpenChange={handlePtpReasonDropdownOpenChange}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select a reason" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {isPtpReasonListLoading ? (
                            <SelectItem disabled value="loading">
                              <span className="flex gap-2 items-center">
                                <Loader2Icon className="animate-spin h-4 w-4" /> Fetching...
                              </span>
                            </SelectItem>
                          ) : (
                            <>
                              {ptpReasonList?.map((reason, index) => (
                                <SelectItem key={index} value={reason}>
                                  {reason}
                                </SelectItem>
                              ))}
                              <SelectItem value="other">Other</SelectItem>
                            </>
                          )}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {isOtherReasonSelected && (
                      <Textarea
                        {...field}
                        placeholder="Write your custom reason..."
                        className="w-full mt-4 p-2 border rounded-lg"
                      />
                    )}
                  </>
                )}
              />
              <p className="text-xs text-red-500 h-4">{errors.reason?.message || ''}</p>
            </div>
          </div>

          <DialogFooter className=" flex items-center gap-2 ">
            <Button onClick={() => toggleDialog(false)} variant="outline">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isPending || isPtpReasonRequiredLoading || !isFormValid}
              className="flex items-center gap-2 "
            >
              Save
              {isPending && <Loader2Icon className="animate-spin h-4 w-4" />}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export const EditPromiseToPayDate = ({ defaultValue, invoiceId }: { defaultValue: Date; invoiceId: string }) => {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);

  const { mutate: bulkUpdate, isPending } = useBulkPromiseToPayUpdateMutation({
    invoiceId,
    customConfig: {
      onError: () => {
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
      },
      onSuccess: (data) => {
        setOpen(false);
        const successCount = data?.data.succeeded;
        const failedCount = data?.data.failed;

        if (failedCount) {
          toast({
            variant: 'destructive',
            description: 'Failed to update Promise to pay date!',
          });
        }

        if (successCount) {
          toast({
            variant: 'default',
            description: 'Promise to pay date updated!',
          });
        }
      },
    },
  });

  const handleFormSubmit = (data: { reason: string; date: Date }) => {
    const requestData: IBulkPromiseToPayUpdateRequest = {
      promiseToPayUpdates: [
        {
          invoiceId,
          updatedPromiseToPayDate: dayjs(data.date).format('YYYY-MM-DD'),
          comment: data.reason,
        },
      ],
    };

    bulkUpdate(requestData);
  };

  const toggleDialog = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return (
    <>
      <Button variant="outline" className="flex gap-2" onClick={() => setOpen(true)}>
        <CalendarRange className="h-4 w-4" />
        {dayjs(defaultValue).format('DD MMM YYYY')}
      </Button>
      {open && (
        <PromiseToPayDateDialog
          defaultDate={defaultValue}
          isPending={isPending}
          open={open}
          toggleDialog={toggleDialog}
          handleFormSubmit={handleFormSubmit}
        />
      )}
    </>
  );
};

export const EditPromiseToPayDateBulk = ({
  open,
  table,
  toggleDialog,
}: {
  open: boolean;
  table: Table<IInvoice>;
  toggleDialog: (_isOpen: boolean) => void;
}) => {
  const { toast } = useToast();
  const { mutate: bulkUpdate, isPending } = useBulkPromiseToPayUpdateMutation({
    customConfig: {
      onError: () => {
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
      },
      onSuccess: (data) => {
        toggleDialog(false);
        const successCount = data?.data.succeeded;
        const failedCount = data?.data.failed;

        if (failedCount) {
          toast({
            variant: 'destructive',
            description: `Failed to update ${failedCount} ${failedCount === 1 ? 'invoice' : 'invoices'}`,
          });
        }

        if (successCount) {
          toast({
            variant: 'default',
            description: `Promise to pay date updated for ${successCount} ${successCount === 1 ? 'invoice' : 'invoices'}!`,
          });
        }

        table.toggleAllRowsSelected(false);
      },
    },
  });

  const handleFormSubmit = (data: { reason: string; date: Date }) => {
    const requestData: IBulkPromiseToPayUpdateRequest = {
      promiseToPayUpdates: table.getSelectedRowModel().rows.map((row) => {
        const ptp: IPromiseToPayDate = {
          invoiceId: row.original.id,
          updatedPromiseToPayDate: dayjs(data.date).format('YYYY-MM-DD'),
          comment: data.reason,
        };

        return ptp;
      }),
    };
    bulkUpdate(requestData);
  };

  return (
    open && (
      <PromiseToPayDateDialog
        defaultDate={dayjs().toDate()}
        open={open}
        isPending={isPending}
        toggleDialog={toggleDialog}
        handleFormSubmit={handleFormSubmit}
      />
    )
  );
};

export const EditDiscount = ({
  defaultValue,
  className,
  invoiceId,
  currencyType,
}: {
  defaultValue: IDiscount | null;
  className?: string;
  invoiceId: string;
  currencyType: string | undefined;
}) => {
  const defaultDiscount: IDiscount = {
    amount: 0,
    percentage: 0,
    isPostTax: false,
  };
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(String(defaultValue?.amount ?? 0));
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState('');
  const [inputType, setInputType] = useState('amount');
  const { toast } = useToast();

  const { mutate: updateInvoice, isPending } = usePostUpdateInvoiceMutation({
    invoiceId,
    customConfig: {
      onError: () => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
        setValue(String(defaultValue?.amount ?? 0));
      },
    },
  });

  useEffect(() => {
    setValue(String(defaultValue?.amount ?? 0));
  }, [defaultValue]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (inputValue: string, inputType: string | undefined) => {
    setErrors('');
    if (isNaN(Number(inputValue))) {
      setErrors('Discount amount is invalid');
    }

    if (!isNaN(Number(inputValue)) && Number(inputValue) < 0) {
      setErrors('Discount amount cannot be negative');
    }

    if (inputType) setInputType(inputType);

    setValue(inputValue);
  };

  const handleSave = () => {
    if (isNaN(Number(value))) {
      setErrors('Discount amount is invalid');
      return;
    }

    if (errors.trim()) {
      return;
    }

    setIsEditing(false);

    const updated_discount = defaultValue ?? defaultDiscount;

    const updateValue = Number(value);
    if (inputType === 'percentage') {
      updated_discount.percentage = updateValue;
      updated_discount.amount = 0;
    } else {
      updated_discount.amount = updateValue;
      updated_discount.percentage = 0;
    }

    updateInvoice({
      fields: ['discount'],
      updateObject: {
        discount: updated_discount,
      },
    });
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
    setErrors('');
    setInputType('amount');
  }, [isEditing]);

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
    setValue(String(defaultValue?.amount ?? 0));
  };

  return isEditing ? (
    <div className=" flex items-center gap-2 ">
      <div>
        <ClubbedSelectWithInput
          value={value}
          onChange={handleChange}
          selectClassName="w-16 h-7"
          inputClassName=" w-28 h-7"
          options={[
            {
              value: 'percentage',
              label: '%',
            },
            {
              value: 'amount',
              label: getSymbol(currencyType),
            },
          ]}
          defaultSelected={inputType}
          placeholderMap={{
            percentage: 'Enter percentage',
            amount: 'Enter amount',
          }}
          placeholder="Add discount"
          inputProps={{
            ref: inputRef,
          }}
        />
        <p className=" text-xs text-red-500 h-4 ">{!!errors.trim() && errors}</p>
      </div>
      <Button className="my-0 relative bottom-2 h-7 w-7" onClick={handleSave} variant="outline" size="icon">
        <CheckIcon className=" h-3 w-3 " />
      </Button>
      <Button className="my-0 relative bottom-2 h-7 w-7" onClick={handleCancel} variant="outline" size="icon">
        <XIcon className=" h-3 w-3 " />
      </Button>
    </div>
  ) : (
    <div
      onClick={handleClick}
      className={cn(
        ' hover:ring-1 ring-primary cursor-pointer px-4 rounded-md py-1 flex items-center gap-4 ',
        className,
      )}
    >
      {formatCurrencyByUnit(isNaN(Number(value)) ? 0 : Number(value), 'actual', currencyType)}
      {isPending && <Loader2Icon className="animate-spin h-4 w-4" />}
    </div>
  );
};

export const WriteOffDialog = ({
  invoiceId,
}: {
  invoiceId: string;
  // metaData?: IInvoiceMetaData;
}) => {
  const [isWriteOffModelOpen, setIsWriteOffModelOpen] = useState(false);
  const [isWriteOffButtonEnabled, setIsWriteOffButtonEnabled] = useState(false);
  const { toast } = useToast();

  const { mutate: createWriteOff, isPending: isWriteOffCreating } = useCreateWriteOffMutation({
    invoiceId: invoiceId,
    approvalStatusList: ['PENDING'],
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Some error occurred',
        });
      },
      onSettled: () => {
        setIsWriteOffModelOpen(false);
      },
    },
  });

  const form = useForm<{
    writeOffAmount: string;
    comment: string;
  }>({
    values: {
      writeOffAmount: '',
      comment: '',
    },
  });

  const onSubmit = async (data: { writeOffAmount: string; comment: string }) => {
    const requestData: ICreateWriteOffRequest = {
      invoiceId: invoiceId,
      amount: Number(data.writeOffAmount),
      comment: data.comment,
    };
    createWriteOff({ ...requestData });
  };

  useEffect(() => {
    form.reset();
  }, [isWriteOffModelOpen, form]);

  useEffect(() => {
    form.formState.dirtyFields.writeOffAmount ? setIsWriteOffButtonEnabled(true) : setIsWriteOffButtonEnabled(false);
  }, [form.watch()]);

  return (
    <div className=" flex items-center gap-4 ">
      <Button variant="outline" onClick={() => setIsWriteOffModelOpen(true)}>
        Write off
      </Button>
      {isWriteOffModelOpen && (
        <Dialog open={isWriteOffModelOpen} onOpenChange={setIsWriteOffModelOpen}>
          <DialogContent className="sm:max-w-[425px]">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4 min-w-[300px] p-4 border-2 rounded-md"
                noValidate
              >
                <DialogHeader>
                  <DialogTitle>Write off</DialogTitle>
                </DialogHeader>

                <FormItem>
                  <FormLabel className=" text-sm ">Write off amount</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      autoComplete="off"
                      placeholder="Enter write off amount"
                      {...form.register('writeOffAmount', {
                        required: 'Write off amount is required',
                        validate: (value) => Number(value) > 0 || 'Write off amount must be greater than 0',
                      })}
                    />
                  </FormControl>
                  {form.formState.errors['writeOffAmount'] && (
                    <FormMessage className=" text-xs ">{form.formState.errors['writeOffAmount'].message}</FormMessage>
                  )}
                </FormItem>

                <FormItem>
                  <FormLabel className=" text-sm ">Comments</FormLabel>
                  <FormControl>
                    <Textarea autoComplete="off" placeholder="Enter comment" {...form.register('comment')} />
                  </FormControl>
                </FormItem>

                <DialogFooter className=" flex items-center gap-2 ">
                  <Button variant="outline" onClick={() => setIsWriteOffModelOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="flex items-center gap-2"
                    disabled={isWriteOffCreating || !isWriteOffButtonEnabled}
                  >
                    Write off
                    {isWriteOffCreating && <Loader2Icon className="w-4 h-4 animate-spin" />}
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export const ReverseDialog = ({
  invoiceId,
  alreadyAdjustedAmount,
}: {
  invoiceId: string;
  alreadyAdjustedAmount: number;
}) => {
  const [isReverseDialog, setIsReverseDialog] = useState(false);
  // const [isReverseButtonEnabled, setIsReverseButtonEnabled] = useState(false);
  const { toast } = useToast();

  const { mutate: updateInvoice } = usePostUpdateInvoiceMutation({
    invoiceId,
    customConfig: {
      onError: () => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
      },
      onSettled: () => {
        setIsReverseDialog(false);
      },
    },
  });

  const form = useForm<{
    writeOffAmount: number;
    comment: string;
  }>({
    values: {
      writeOffAmount: alreadyAdjustedAmount,
      comment: '',
    },
  });

  const onSubmit = async () => {
    updateInvoice({
      fields: ['invoiceAdjustedAmount'],
      updateObject: {
        invoiceAdjustedAmount: 0,
      },
    });
  };

  useEffect(() => {
    form.reset();
  }, [isReverseDialog, form]);

  return (
    <>
      <Button variant="outline" onClick={() => setIsReverseDialog(true)}>
        Reverse
      </Button>
      {isReverseDialog && (
        <Dialog open={isReverseDialog} onOpenChange={setIsReverseDialog}>
          <DialogContent className=" min-w-[300px] p-4 border-2 rounded-md">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <DialogHeader>
                  <DialogTitle>Reverse adjustment amount</DialogTitle>
                </DialogHeader>
                <FormField
                  control={form.control}
                  name="writeOffAmount"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel className=" text-sm ">Reverse adjustment amount</FormLabel>
                      <FormControl aria-disabled>
                        <Input
                          className={cn(alreadyAdjustedAmount > 0 ? ' text-green-500' : ' text-red-500')}
                          autoComplete="off"
                          placeholder="Enter reverse adjustment amount"
                          {...field}
                          disabled
                        />
                      </FormControl>
                      {fieldState.error && <FormMessage className=" text-xs ">{fieldState.error.message}</FormMessage>}
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="comment"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel className=" text-sm ">Comments</FormLabel>
                      <FormControl>
                        <Textarea autoComplete="off" placeholder="Enter comment" {...field} />
                      </FormControl>
                      {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                    </FormItem>
                  )}
                />
                <DialogFooter className=" flex items-center gap-2 ">
                  <Button variant="outline" onClick={() => setIsReverseDialog(false)}>
                    Cancel
                  </Button>
                  <Button type="submit">Reverse</Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export const WriteOffStatus = ({
  writeOffDetails,
  invoice,
}: {
  writeOffDetails: IWriteOff[] | undefined;
  invoice: IInvoice;
}) => {
  const [isWriteOffDialogOpen, setIsWriteOffDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  // const [isWriteOffButtonEnabled, setIsWriteOffButtonEnabled] = useState(false);
  const { toast } = useToast();

  const { mutate: createWriteOff, isPending: isWriteOffUpdating } = useCreateWriteOffMutation({
    invoiceId: invoice.id,
    approvalStatusList: ['PENDING'],
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Some error occurred',
        });
      },
      onSettled: () => {
        setIsWriteOffDialogOpen(false);
      },
    },
  });

  const { mutate: cancelWriteOff, isPending: isWriteOffCancelling } = useCancelWriteOffFromInvoicePageMutation({
    invoiceId: invoice.id,
    approvalStatusList: ['PENDING'],
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Some error occurred',
        });
      },
      onSettled: () => {
        setIsCancelDialogOpen(false);
      },
    },
  });

  const form = useForm<{
    writeOffAmount: string;
    comment: string;
  }>({
    values: {
      writeOffAmount: writeOffDetails ? String(writeOffDetails[0].writeOffAmount) : '',
      comment: writeOffDetails
        ? writeOffDetails[0].comment.find((comment) => comment.verdict === 'PENDING')?.comment || ''
        : '',
    },
  });

  const onSubmit = async (data: { writeOffAmount: string; comment: string }) => {
    const requestData: ICreateWriteOffRequest = {
      invoiceId: invoice.id,
      amount: Number(data.writeOffAmount),
      comment: data.comment,
    };
    createWriteOff({ ...requestData });
  };

  const editButtonClickHandler = () => {
    setIsWriteOffDialogOpen(true);
  };

  const cancelRequestClickHandler = () => {
    setIsCancelDialogOpen(true);
  };

  const cancelWriteOffHandler = () => {
    const requestData: IWriteOffActionList[] = [
      { id: writeOffDetails ? writeOffDetails[0].requestId : '', comment: '' },
    ];
    cancelWriteOff({ requests: requestData });
  };

  useEffect(() => {
    form.reset();
  }, [isWriteOffDialogOpen, form]);

  // useEffect(() => {
  //   form.formState.dirtyFields.writeOffAmount ? setIsWriteOffButtonEnabled(true) : setIsWriteOffButtonEnabled(false);
  // }, [form.watch()]);

  return (
    <>
      <div className="flex items-center gap-5">
        <span className="text-yellow-600">
          Write off requested:{' '}
          {formatCurrencyByUnit(
            writeOffDetails ? writeOffDetails[0].writeOffAmount : 0,
            'actual',
            invoice.invoiceCurrency,
          )}
        </span>
        <Button
          size="icon"
          variant="outline"
          className="cursor-pointer"
          title="Edit Request"
          onClick={editButtonClickHandler}
        >
          <Edit2Icon className="h-4 w-4" />
        </Button>
        <Button variant="outline" onClick={cancelRequestClickHandler}>
          Cancel Request
        </Button>
      </div>

      <Dialog open={isWriteOffDialogOpen} onOpenChange={setIsWriteOffDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-4 min-w-[300px] p-4 border-2 rounded-md"
              noValidate
            >
              <DialogHeader>
                <DialogTitle>Write off</DialogTitle>
              </DialogHeader>

              <FormItem>
                <FormLabel className=" text-sm ">Write off amount</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    autoComplete="off"
                    placeholder="Enter write off amount"
                    {...form.register('writeOffAmount', {
                      required: 'Write off amount is required',
                      validate: (value) => Number(value) > 0 || 'Write off amount must be greater than 0',
                    })}
                  />
                </FormControl>
                {form.formState.errors['writeOffAmount'] && (
                  <FormMessage className=" text-xs ">{form.formState.errors['writeOffAmount'].message}</FormMessage>
                )}
              </FormItem>

              <FormItem>
                <FormLabel className=" text-sm ">Comments</FormLabel>
                <FormControl>
                  <Textarea autoComplete="off" placeholder="Enter comment" {...form.register('comment')} />
                </FormControl>
              </FormItem>
              <DialogFooter className=" flex items-center gap-2 ">
                <Button variant="outline" onClick={() => setIsWriteOffDialogOpen(false)}>
                  Cancel
                </Button>
                <Button type="submit" className="flex items-center gap-2" disabled={isWriteOffUpdating}>
                  Write off
                  {isWriteOffUpdating && <Loader2Icon className="w-4 h-4 animate-spin" />}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      <Dialog open={isCancelDialogOpen} onOpenChange={setIsCancelDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <div className="space-y-4 min-w-[400px] p-4 border-2 rounded-md">
            <DialogHeader className="font-semibold">Cancel Request</DialogHeader>
            <p className="text-sm">
              Are you certain you want to cancel the write-off request for{' '}
              {formatCurrencyByUnit(
                writeOffDetails ? writeOffDetails[0].writeOffAmount : 0,
                'actual',
                invoice.invoiceCurrency,
              )}
              ?
            </p>
            <DialogFooter className=" flex items-center gap-2 ">
              <Button variant="outline" onClick={() => setIsCancelDialogOpen(false)}>
                No
              </Button>
              <Button
                type="submit"
                className="flex items-center gap-2"
                disabled={isWriteOffCancelling}
                onClick={cancelWriteOffHandler}
              >
                Yes
                {isWriteOffCancelling && <Loader2Icon className="w-4 h-4 animate-spin" />}
              </Button>
            </DialogFooter>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
