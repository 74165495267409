import { usePostUpdateBudgets } from '@/hooks/api-hooks/useBudgetQuery';
import { cn } from '@/lib/utils';
import { IBudgets } from '@/types/budget.types';
import { Loader2 } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Input } from '../ui/input';
import { useToast } from '../ui/use-toast';

const BudgetCell = ({
  budget,
  unit,
  isAllocateReduce = false,
  refetch,
}: {
  budget: IBudgets;
  unit: 'k' | 'l' | 'cr' | 'actual';
  isAllocateReduce?: boolean;
  refetch: () => void;
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isError, setIsError] = useState(false);
  const [budgetValue, setBudgetValue] = useState(String(budget.value ? budget.value : ''));
  const inputRef = useRef<HTMLInputElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);
  const { toast } = useToast();

  const { mutate: updateBudget, isPending } = usePostUpdateBudgets({
    budgetId: budget.id,
    customConfig: {
      onSuccess: async () => {
        refetch?.();
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'Failed to update budget.',
          variant: 'destructive',
        });
        setBudgetValue(String(budget.value ? budget.value : ''));
        cellRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        setIsError(true);
      },
    },
  });

  const handleClick = () => {
    if (isError) setIsError(false);
    setIsEditable(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) setIsError(false);
    setBudgetValue(e.target.value);
  };

  const handleSave = () => {
    if (Number.isNaN(Number(budgetValue))) {
      toast({
        title: 'Error',
        description: 'Invalid budget value.',
        variant: 'destructive',
      });
      setBudgetValue(String(budget.value ? budget.value : ''));
      cellRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      setIsError(true);
      return;
    }
    if (Number(budgetValue) === 0) {
      setBudgetValue('');
    }
    const value = Number(budgetValue);
    setIsEditable(false);
    if (value === budget.value) return;
    updateBudget({
      budgetValue: value,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditable]);

  const canEdit = useMemo(() => {
    return !isAllocateReduce;
  }, [isAllocateReduce]);

  useEffect(() => {
    setBudgetValue(String(budget.value ? budget.value : ''));
  }, [budget]);

  const adjustedValue = useMemo(() => {
    const value = Math.abs(Number(budgetValue));
    if (unit === 'k') {
      return value / 1000;
    } else if (unit === 'l') {
      return value / 100000;
    } else if (unit === 'cr') {
      return value / 10000000;
    } else {
      return value;
    }
  }, [budgetValue, unit]);

  return (
    <div
      ref={cellRef}
      key={budget.id}
      onClick={handleClick}
      className={cn(
        'min-w-[180px] max-w-[180px] border-r text-center flex gap-2 justify-center items-center py-3 px-4 border-b',
        isError && 'border border-red-400',
      )}
    >
      {isEditable && canEdit ? (
        <Input
          ref={inputRef}
          value={budgetValue}
          onChange={handleChange}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
          className={cn(
            'rounded-none border-none hover:border-none',
            'hover:outline-none hover:ring-0 focus-visible:outline-none',
            'focus-visible:ring-0 h-5 [appearance:textfield]',
            '[&::-webkit-outer-spin-button]:appearance-none',
            '[&::-webkit-inner-spin-button]:appearance-none',
          )}
        />
      ) : (
        <span
          className={cn(
            'truncate text-ellipsis capitalize',
            Number(budgetValue) !== 0 && (Number(budgetValue) < 0 ? 'text-red-500' : 'text-green-600'),
          )}
        >
          {!Number(budgetValue)
            ? '-'
            : `${adjustedValue.toLocaleString('en-IN', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'INR',
              })} ${unit === 'actual' ? '' : unit}`}
        </span>
      )}
      {isPending && <Loader2 className=" animate-spin w-3 h-3 " />}
    </div>
  );
};

export default BudgetCell;
