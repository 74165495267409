import { TightLayout } from '@/components/shared/Layout';
import { Button } from '@/components/ui/button';
import { useUserContext } from '@/contexts/UserContext';
import { getRazorPayIntegrationUrl, getZohoIntegratonUrl } from '@/utils/thirdParyUtils';

const IntegrationsPage = () => {
  const PaymentCard = ({
    logoUrl,
    title,
    description,
    onButtonClick,
  }: {
    logoUrl: string;
    title: string;
    description: string;
    onButtonClick: () => void;
  }) => {
    const Icon = ({ logoUrl }: { logoUrl: string }) => <img src={logoUrl} alt="PayPal" className="w-32 h-8" />;

    return (
      <div className="max-w-sm bg-white p-6 rounded-xl shadow-md font-sans">
        <div className="flex justify-between items-center">
          <Icon logoUrl={logoUrl} />
          <Button onClick={onButtonClick} variant="outline">
            Integrate
          </Button>
        </div>
        <div className="mt-4">
          <h3 className="text-lg font-semibold">{title}</h3>
          <p className="text-gray-600 text-sm mt-2">{description}</p>
        </div>
      </div>
    );
  };
  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const handleRazorpayClick = () => {
    const companyId = companiesOfUser[activeCompanyIndex]?.id || '';
    window.location.href = getRazorPayIntegrationUrl(companyId);
  };
  const handleZohoClick = () => {
    window.location.href = getZohoIntegratonUrl();
  };

  return (
    <TightLayout>
      <h1 className=" text-base font-bold absolute top-8 ">Integrate Your Third Party Apps</h1>
      <div className="w-[80%] h-[100%]  flex justify-between ml-auto mr-auto items-center mt-10">
        <PaymentCard
          logoUrl={'https://razorpay.com/newsroom-content/uploads/2020/12/output-onlinepngtools-1-1.png'}
          title={'Razorpay'}
          description={'Integrate all your razorpay accounts to view transactions at 1 place'}
          onButtonClick={handleRazorpayClick}
        />

        <PaymentCard
          logoUrl={'https://www.zohowebstatic.com/sites/zweb/images/commonroot/zoho-logo-web.svg'}
          title={'Zoho'}
          description={'Integrate all your zoho accounts to view transactions at 1 place'}
          onButtonClick={handleZohoClick}
        />
      </div>
    </TightLayout>
  );
};

export default IntegrationsPage;
