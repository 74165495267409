import { IConditionRule, ICreateConditionObject, IStatementCondition } from '@/types/workflow.type';

export const convertConditionsToRequestObjects = (
  condition: IConditionRule | IStatementCondition,
): ICreateConditionObject['rule'] => {
  if (condition.type === 'AND_TYPE' || condition.type === 'OR_TYPE') {
    return {
      ...condition,
      _type: condition.type,
      childConditions: condition.childConditions.map((childCondition) =>
        convertConditionsToRequestObjects(childCondition),
      ),
    };
  }

  return {
    ...condition,
    _type: condition.type,
  };
};
