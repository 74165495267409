import { CreditTermType, ICreditTerms } from '@/types/credit-term.types';

export const createTermObjectForRequest = <T extends CreditTermType>({
  term,
}: {
  term: ICreditTerms<T>;
}): {
  isValid: boolean;
  errors: {
    field: string;
    message: string;
  }[];
} => {
  if (!term) {
    return {
      isValid: false,
      errors: [
        {
          field: 'title',
          message: 'Title is required',
        },
      ],
    };
  }

  if (!term.title) {
    return {
      isValid: false,
      errors: [
        {
          field: 'title',
          message: 'Title is required',
        },
      ],
    };
  }

  if (!term.type) {
    return {
      isValid: false,
      errors: [
        {
          field: 'type',
          message: 'Type is required',
        },
      ],
    };
  }

  if (!term.description) {
    return {
      isValid: false,
      errors: [
        {
          field: 'description',
          message: 'Description is required',
        },
      ],
    };
  }

  if (!term.terms) {
    return {
      isValid: false,
      errors: [
        {
          field: 'terms',
          message: 'Terms is required',
        },
      ],
    };
  }

  return {
    isValid: true,
    errors: [],
  };
};

export const validateAndReturnErrorsAsObject = <T extends CreditTermType>({ term }: { term: ICreditTerms<T> }) => {
  let isValid = true;
  const errors: {
    [key: string]: string;
  } = {};
  if (!term) {
    isValid = false;
    errors['term'] = 'Term is required';
  }

  if (!term.title) {
    isValid = false;
    errors['title'] = 'Title is required';
  }

  if (!term.type) {
    isValid = false;
    errors['type'] = 'Type is required';
  }

  if (!term.description) {
    isValid = false;
    errors['description'] = 'Description is required';
  }

  if (!term.terms) {
    isValid = false;
    errors['terms'] = 'Terms is required';
  }

  return {
    isValid: isValid,
    errors: errors,
  };
};
