import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { sanitizeAttachmentsName } from '@/lib/utils';
import { IWorkflowDryRunEmailAttachment } from '@/types/workflow.type';
import { PaperclipIcon, XIcon } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';

const ShowAttachmentsModal = ({ attachments }: { attachments: IWorkflowDryRunEmailAttachment[] }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleModalReset = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {attachments.length > 0 && (
        <Button
          onClick={() => setIsModalOpen(true)}
          variant="ghost"
          className=" flex items-center gap-1 text-blue-700 text-sm "
        >
          <PaperclipIcon className="w-4 h-4" />
          {attachments.length} attachment(s)
        </Button>
      )}
      {isModalOpen && (
        <Dialog open={isModalOpen} onOpenChange={handleModalReset}>
          <DialogContent className="min-w-[500px] min-h-[350px] border-2 bg-white	border-gray-500 ">
            <div>
              <div className=" h-[70px] p-4 py-2 flex  justify-between border-b-2 ">
                <div>
                  <DialogHeader>
                    <div className="font-semibold capitalize">Attachments</div>
                  </DialogHeader>
                </div>
                <div>
                  <XIcon className="w-[20px] h-[20px] cursor-pointer" onClick={handleModalReset} />
                </div>
              </div>
              <div className="min-h-[270px] min-w-[430px] px-4 pt-2">
                <div>
                  {attachments.map((item) => (
                    <div key={item.attachmentId} className=" text-blue-700 mb-2">
                      <Link target="_blank" to={`https://data.valyx.com/attachments/${item.attachmentId}/file`}>
                        {sanitizeAttachmentsName(item.attachmentName)}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ShowAttachmentsModal;
