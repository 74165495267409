import EditAddress from '@/components/customers/EditRegisteredAddress';
import { LabelValueDescriptionLayout } from '@/components/shared/LabelValueDescLayout';
import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import { ICustomer } from '@/types/customer.types';
import { CardContent } from '@mui/material';

const CustomerOtherDetails = ({ customer, customerId }: { customer: ICustomer; customerId: string }) => {
  return (
    <Card className=" text-sm my-8">
      <CardHeader className="border-b">
        <CardTitle className=" underline font-semibold ">Other details</CardTitle>
      </CardHeader>
      <CardContent className=" flex flex-col gap-4">
        <LabelValueDescriptionLayout label="Buyer Id:" value={customer.buyerId} description="" />
        <LabelValueDescriptionLayout label="Customer Name:" value={customer.name} description="" />
        <LabelValueDescriptionLayout
          label="Registered Address:"
          value={<EditAddress address={customer.registeredAddress} customerId={customerId} />}
          description={''}
        />
        <LabelValueDescriptionLayout label="GST:" value={customer.gstin} description="" />
        <LabelValueDescriptionLayout label="PAN:" value={customer.pan} description="" />
      </CardContent>
    </Card>
  );
};

export default CustomerOtherDetails;
