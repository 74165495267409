import { dataAxiosInstance } from '@/lib/axios';

export const fetchCompanies = async () => {
  const response = await dataAxiosInstance.get('/company');
  return response.data;
};

export const fetchCompanyMembers = async (companyId: string) => {
  const response = await dataAxiosInstance.get(`/members?companyId=${companyId}`);
  return response.data;
};

export const fetchCompanyInvites = async (companyId: string) => {
  const response = await dataAxiosInstance.get(`/invitation/list?companyId=${companyId}`);
  return response.data;
};

export const inviteEmail = async (email: string, companyId: string) => {
  const response = await dataAxiosInstance.post('/invitation/invite', { email, companyId });
  return response.data;
};

export const revokeInvite = async (invitationId: string) => {
  const response = await dataAxiosInstance.post(`/invitation/revoke?invitationId=${invitationId}`);
  return response.data;
};

export const createCompany = async ({ cin }: { cin: string }) => {
  const response = await dataAxiosInstance.post('/company', {
    cin: cin,
  });

  return response.data;
};

export const fetchCompanyLogo = async () => {
  const response = await dataAxiosInstance.get('/company/logo');
  return response.data;
};
