import ToolTipCell from '@/components/Table/ToolTipCell';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useDeleteActionById, useDeleteStateById } from '@/hooks/api-hooks/useWorkflowQuery';
import { cn } from '@/lib/utils';
import { useWorkflowPropertiesStore } from '@/stores/workflow/state-properties.store';
import { IEmailActionProperties, StateTypes } from '@/types/workflow.type';
import { autoUpdate, useFloating } from '@floating-ui/react';
import { AlertTriangleIcon, Loader2Icon, MailIcon, Trash2Icon } from 'lucide-react';
import { useCallback, useRef, useState } from 'react';

interface IEmailBlockProps {
  actionId: string;
  properties: IEmailActionProperties;
  stateId: string;
  workflowId: string;
  catIndex: number;
  nodeType: StateTypes;
  warnings: string[];
}

const EmailBlock = (props: IEmailBlockProps) => {
  const { setSelectedProperties, selectedActionId } = useWorkflowPropertiesStore((store) => ({
    setSelectedProperties: store.setSelectedProperties,
    selectedActionId: store.selectedProperties?.actionId,
  }));

  const { toast } = useToast();

  const { mutate: deleteAction, isPending } = useDeleteActionById({
    actionId: props.actionId,
    stateId: props.stateId,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Unable to delete action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: deleteSate } = useDeleteStateById({
    stateId: props.stateId,
    workflowId: props.workflowId,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Unable to delete state. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const handleDeleteAction = useCallback(() => {
    deleteAction();
    if (props.nodeType === 'ACTION_ONLY') {
      deleteSate();
    }
  }, [deleteAction, deleteSate, props.nodeType]);

  const timeout = useRef<number>();
  const [visible, setVisible] = useState(false);
  const { refs, floatingStyles } = useFloating({
    placement: 'right-start',
    whileElementsMounted: autoUpdate,
  });

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(true);
    }, 25);
  }, []);
  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(false);
    }, 25);
  }, []);

  const handleEmailSelect = () => {
    setSelectedProperties({
      type: props.nodeType,
      nodeId: props.stateId,
      updateType: 'action',
      actionId: props.actionId,
      catIndex: props.catIndex,
    });
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cn(
        ' items-center flex gap-2 text-sm relative ',
        selectedActionId === props.actionId ? ' ring-2 ' : '  ',
      )}
      ref={refs.setReference}
    >
      {props.nodeType !== 'ACTION_ONLY' && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={cn(' flex justify-end text-center ', visible ? 'opacity-100' : 'opacity-0')}
        >
          <Button
            disabled={isPending}
            onClick={handleDeleteAction}
            className=" text-destructive relative right-8  bg-white "
            variant="outline"
            size="icon"
          >
            {isPending ? <Loader2Icon className="w-3 h-3 animate-spin" /> : <Trash2Icon className="w-3 h-3" />}
          </Button>
        </div>
      )}
      <div onClick={handleEmailSelect} className=" w-full flex items-center gap-2 cursor-pointer ">
        <div className=" bg-muted-foreground/10 p-2 rounded-md  ">
          <MailIcon className=" h-8 w-8 " />
        </div>
        {props.warnings.length > 0 && (
          <div className=" absolute -left-20 text-destructive ">
            <ToolTipCell value={<AlertTriangleIcon className=" w-4 h-4 " />}>
              <div className=" flex flex-col gap-1 ">
                {props.warnings.map((w, index) => (
                  <div key={w}>
                    {index + 1}. {w}
                  </div>
                ))}
              </div>
            </ToolTipCell>
          </div>
        )}
        <div>
          <div className="  ">From: {props.properties.fromEmail}</div>
          <div>Template: {props.properties?.templateData?.name}</div>
        </div>
      </div>
    </div>
  );
};

export default EmailBlock;
