import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { cn } from '@/lib/utils';
import { IAdjustMents } from '@/types/adjustment.types';
import { formatCurrency } from '@/utils/formatCurrency';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import DeleteCreditNoteFromInvoiceDetailsDialog from '../DeleteAppliedCreditNoteDialog';

const AdjustmentsTable = ({
  data,
  showDelete = true,
  invoiceNumber,
}: {
  data: IAdjustMents[];
  showDelete?: boolean;
  invoiceNumber: string;
}) => {
  const columns = useMemo<ColumnDef<IAdjustMents>[]>(
    () => [
      {
        accessorKey: 'type',
        header: 'Type',
        cell: ({ getValue }) => (
          <span className=" capitalize ">{(getValue() as string).split('_').join(' ').toLowerCase()}</span>
        ),
      },
      {
        accessorKey: 'reference',
        header: 'Reference',
        cell({ getValue }) {
          const reference = getValue<IAdjustMents['reference']>();

          if (reference?.creditNoteId) {
            return (
              <span>
                <Link className=" text-blue-700 underline " to={`/credit-note/view/${reference.creditNoteId}`}>
                  {reference.creditNoteNumber}
                </Link>
              </span>
            );
          }

          return <span>-</span>;
        },
      },
      {
        accessorKey: 'appliedOn',
        header: 'Applied On',
        cell({ row }) {
          return (
            <div className=" text-sm ">
              <div>{dayjs(row.original.appliedOn).format('DD MMM YYYY')}</div>
              <div className=" text-xs text-muted-foreground ">by {row.original.appliedBy}</div>
            </div>
          );
        },
      },
      {
        accessorKey: 'appliedAmount',
        header: 'Applied Amount',
        cell({ getValue }) {
          const appliedAmount = getValue<number>();

          return (
            <span
              className={cn(
                appliedAmount > 0 ? 'text-destructive' : ' text-green-700 ',

                appliedAmount === 0 && 'text-primary',
              )}
            >
              {formatCurrency(Math.abs(appliedAmount))}
            </span>
          );
        },
      },
      {
        id: 'actions',
        cell({ row }) {
          return showDelete ? (
            <DeleteCreditNoteFromInvoiceDetailsDialog
              invoiceNumber={invoiceNumber}
              adjustment={row.original}
              postingId={row.original.postingId}
            />
          ) : null;
        },
      },
    ],
    [showDelete, invoiceNumber],
  );

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    data,
  });

  return (
    <div>
      <Table>
        <TableHeader className=" bg-gray-100 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AdjustmentsTable;
