import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { usePostRoundOffInvoices } from '@/hooks/api-hooks/usePostingQuery';
import { formatCurrency } from '@/utils/formatCurrency';
import { useForm } from 'react-hook-form';
import { Input } from '../ui/input';
import { useToast } from '../ui/use-toast';

const RoundOffInvoicesDialog = ({
  open,
  toggleDialog,
  selectedInvoiceIds,
}: {
  open: boolean;
  toggleDialog: (_isOpen: boolean) => void;
  selectedInvoiceIds: string[];
}) => {
  const { toast } = useToast();
  const { mutate: bulkRoundOff, isPending } = usePostRoundOffInvoices({
    customConfig: {
      onError(error) {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to round off invoices',
        });
      },
      onSuccess(data) {
        toast({
          description: `Rounded off ${data.data.ids.length} of ${selectedInvoiceIds.length} invoices`,
        });
        toggleDialog(false);
      },
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{
    thresholdAmount: number;
  }>();

  const handleFormSubmit = (data: { thresholdAmount: number }) => {
    bulkRoundOff({ thresholdAmount: Number(data.thresholdAmount), invoiceIds: selectedInvoiceIds });
  };

  const thresholdAmount = watch('thresholdAmount');

  return (
    open && (
      <Dialog open={open} onOpenChange={toggleDialog}>
        <DialogContent className="sm:max-w-[425px] bg-white">
          <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4 min-w-[300px] p-4 border-2 rounded-md">
            <DialogHeader>
              <DialogTitle>Bulk round off invoices</DialogTitle>
            </DialogHeader>

            <div className=" flex flex-col items-start gap-2">
              <p className=" text-sm ">Threshold Amount</p>
              <div className="w-full">
                <Input
                  {...register('thresholdAmount', {
                    required: {
                      value: true,
                      message: 'Threshold amount is required',
                    },
                    min: {
                      value: 0,
                      message: 'Threshold amount should be greater than 0',
                    },
                    max: {
                      value: 5,
                      message: 'We only support rounding off upto ₹5',
                    },
                    validate: (value) => {
                      if (isNaN(Number(value))) {
                        return 'Threshold amount should be a number';
                      }

                      return true;
                    },
                  })}
                />
                <p className=" text-xs text-red-500 h-4 ">{errors.thresholdAmount?.message || ''}</p>
              </div>
              <div className=" p-2 bg-yellow-300/20 text-sm rounded  ">
                <span className=" font-semibold ">Note:</span> Invoice with due or gain less than{' '}
                {thresholdAmount ? formatCurrency(thresholdAmount) : 'threshold amount'} will be rounded off
              </div>
            </div>

            <DialogFooter className=" flex items-center gap-2 ">
              <Button onClick={() => toggleDialog(false)} variant="outline">
                Cancel
              </Button>
              <Button
                type="submit"
                loading={isPending}
                disabled={isPending || !watch('thresholdAmount')}
                className="flex items-center gap-2 "
              >
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    )
  );
};

export default RoundOffInvoicesDialog;
