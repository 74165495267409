import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { useMinGuaranteeTermContext } from '@/contexts/CreditTermContext';
import { usePostCreateCreditTerm } from '@/hooks/api-hooks/useCreditTermsQuery';
import { cn } from '@/lib/utils';
import { ICreditTerms, IMinimumGuaranteeTermItem } from '@/types/credit-term.types';
import { validateAndReturnErrorsAsObject } from '@/utils/createTermObjectForRequest';
import { CopyIcon, PlusIcon, Trash2Icon } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EditDescription, EditTermTitle } from '../shared/EditComponents';
import { EditMinimumGuranteeTerm } from './MiniGuranteeEditComponents';

const checkMinimumGuaranteeTerm = (term: IMinimumGuaranteeTermItem) => {
  if (term.number <= 0) {
    return false;
  }
  return true;
};

const MinimumGuaranteeTerm = ({
  termDetails,
  termIndex,
  showDelete,
}: {
  termDetails: ICreditTerms<'MIN_GUARANTEE_TERM'> & {
    errors?: {
      [key: string]: string;
    };
  };
  termIndex: number;
  showDelete: boolean;
}) => {
  const { dispatch } = useMinGuaranteeTermContext();

  const handleDeleteTerm = () => {
    dispatch({
      type: 'DELETE_TERM',
      updateIndex: termIndex,
      payload: 0,
      termIndex,
      property: 'id',
    });
  };

  const handleCloneTerm = () => {
    const newTerm = JSON.parse(JSON.stringify(termDetails));
    dispatch({
      type: 'CREATE_NEW',
      updateIndex: termIndex,
      payload: { ...newTerm, id: '', title: `Copy of ${newTerm.title}` },
      termIndex,
      property: 'id',
    });
  };

  const handleTitleChange = (title: string) => {
    dispatch({
      type: 'ATTRIBUTE_UPDATE',
      property: 'title',
      payload: title,
      termIndex,
    });
  };

  const handleDescriptionChange = (description: string) => {
    dispatch({
      type: 'ATTRIBUTE_UPDATE',
      property: 'description',
      payload: description,
      termIndex,
    });
  };

  const handleAddTerm = () => {
    dispatch({
      type: 'TERM_APPEND',
      payload: {
        frequency: 'MONTH',
        minGuaranteedAmount: 0,
        number: 1,
      },
      property: 'terms',
      termIndex,
    });
  };

  const handleEditTerm = (term: IMinimumGuaranteeTermItem, index: number) => {
    dispatch({
      type: 'TERM_UPDATE',
      payload: term,
      updateIndex: index,
      property: 'terms',
      termIndex,
    });
  };

  const handleRemoveTerm = (index: number) => {
    dispatch({
      type: 'TERM_REMOVE',
      updateIndex: index,
      property: 'terms',
      payload: index,
      termIndex,
    });
  };

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div ref={divRef} className=" w-full flex items-start gap-2 ">
      <Card className={cn(' px-2 py-4  flex-1')}>
        <div className=" flex w-full justify-between ">
          <EditTermTitle
            onChange={handleTitleChange}
            className="text-xl font-semibold"
            defaultValue={termDetails.title}
            titleErrors={termDetails.errors?.['title']}
          />
        </div>
        <div className=" py-4 flex flex-col gap-4 text-sm ">
          {termDetails.terms.minimumGuaranteeTerms?.map((minimumGuaranteeTerm, index) => (
            <EditMinimumGuranteeTerm
              remove={handleRemoveTerm}
              onChange={handleEditTerm}
              details={minimumGuaranteeTerm}
              index={index}
              key={index}
              length={termDetails.terms.minimumGuaranteeTerms?.length}
            />
          ))}
          <div className=" px-4 py-2  justify-end rounded-lg flex items-center gap-4 ">
            <div>
              <Button onClick={handleAddTerm} variant="outline" className=" h-7 ">
                Add term
              </Button>
            </div>
          </div>
          <div className=" px-4 py-2 border border-secondary rounded-lg bg-secondary ">
            <h2 className=" mb-1 ">Legal description</h2>
            <p>
              <EditDescription
                descriptionErrors={termDetails.errors?.['description']}
                onChange={handleDescriptionChange}
                value={termDetails.description}
              />
            </p>
          </div>
        </div>
      </Card>
      <div className=" flex flex-col gap-2 ">
        <Button onClick={handleCloneTerm} size="icon" variant="ghost">
          <CopyIcon className="w-4 h-4" />
        </Button>
        {showDelete && (
          <Button onClick={handleDeleteTerm} size="icon" variant="ghost">
            <Trash2Icon className="w-4 h-4" />
          </Button>
        )}
      </div>
    </div>
  );
};

const CreateMultipleMinimumGuaranteeTerm = () => {
  const { terms, dispatch } = useMinGuaranteeTermContext();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { mutate: createCreditTerm, isPending } = usePostCreateCreditTerm({
    customConfig: {
      onError: (error) => {
        toast({
          description: error.response?.data.message || 'Unable to create term. Please try again.',
          variant: 'destructive',
        });
      },
      onSuccess: () => {
        toast({
          description: 'Terms created successfully',
        });
        navigate('/credit-terms');
      },
    },
  });

  const handleSave = () => {
    const errors = terms
      .map((term, index) => {
        const validated = validateAndReturnErrorsAsObject({ term });
        if (!validated.isValid) {
          return {
            index: index,
            errors: validated.errors,
          };
        }
        return null;
      })
      .filter((error) => !!error);

    if (errors.length > 0) {
      const errorMap = new Map<
        number,
        {
          [key: string]: string;
        }
      >();
      errors.forEach((error) => {
        if (error) {
          errorMap.set(error.index, error.errors);
        }
      });
      dispatch({
        type: 'SET_ERRORS',
        payload: errorMap,
        property: 'terms',
        termIndex: -1,
      });
      return;
    }

    const termRequestObject = terms.map((termDetails) => ({
      ...termDetails,
      terms: {
        minimumGuaranteeTerms: termDetails.terms.minimumGuaranteeTerms?.filter((term) =>
          checkMinimumGuaranteeTerm(term),
        ),
      },
    }));

    createCreditTerm(termRequestObject);
  };

  return (
    <div className=" flex flex-col w-full gap-8 ">
      <div className=" flex justify-end gap-4 ">
        <Link to={'/credit-term/templates/min_guarantee'} replace>
          <Button variant="outline" className=" flex items-center gap-2 ">
            Add term <PlusIcon className="w-4 h-4" />
          </Button>
        </Link>
        <Link to="/credit-terms">
          <Button variant="outline">Cancel</Button>
        </Link>
        <Button disabled={isPending} onClick={handleSave}>
          Save
        </Button>
      </div>
      {terms?.map((term, index) => (
        <MinimumGuaranteeTerm
          showDelete={terms.length > 1}
          key={term.id + index}
          termDetails={term}
          termIndex={index}
        />
      ))}
    </div>
  );
};

export default CreateMultipleMinimumGuaranteeTerm;
