import { Button } from '@/components/ui/button';
import AsyncSelect from 'react-select/async';

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useGetRequesterSuggestionsMutation } from '@/hooks/api-hooks/useWriteOffQuery';
import { IAttachmentsFilter } from '@/types/attachments.types';
import { IOptions } from '@/types/common.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import dayjs from 'dayjs';
import { XIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MultiValue } from 'react-select';
import type Select from 'react-select/dist/declarations/src/Select';
import { DatePicker } from '../ui/date-picker';
import { AttachmentsFiletypeDropdown } from './AttachmentsFilterComponents';

const AttachmentFilters = ({
  isFilterModalOpen,
  setIsFilterModalOpen,
  handleSubmit,
  attachmentFilters,
  focusField,
}: {
  handleSubmit: (_: Partial<IAttachmentsFilter>) => void;
  isFilterModalOpen: boolean;
  setIsFilterModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attachmentFilters: Partial<IAttachmentsFilter>;
  focusField: string;
}) => {
  const [selectedAttachmentFiletype, setSelectedAttachmentFiletype] = useState<IOptions[]>([]);
  const [selectedUser, setSelectedUser] = useState<IOptions[]>([]);
  const [filename, setFilename] = useState<string>('');
  const [uploadedOn, setUploadedOn] = useState<Date>();
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [filetypeFilterOpen, setFiletypeFilterOpen] = useState<boolean>(false);
  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<Select<IOptions, true> | null>(null);

  useEffect(() => {
    setFilename(attachmentFilters?.filename ?? '');
    setUploadedOn(attachmentFilters.uploadedOn);
    setSelectedUser(attachmentFilters.uploadedBy ?? []);
    setSelectedAttachmentFiletype(attachmentFilters.filetype ?? []);
  }, [attachmentFilters]);

  useEffect(() => {
    if (focusField != 'uploadedOn') {
      setCalendarOpen(false);
    }
    if (focusField != 'filetype') {
      setFiletypeFilterOpen(false);
    }
  }, [focusField]);

  const handleReset = () => {
    setFilename('');
    setSelectedAttachmentFiletype([]);
    setUploadedOn(undefined);
    setSelectedUser([]);
    handleSubmit({
      filename: '',
      uploadedOn: undefined,
      filetype: [],
      uploadedBy: [],
    });
    setFiletypeFilterOpen(false);
    setCalendarOpen(false);
    setIsFilterModalOpen(false);
    setSearchParams(
      (prev) => {
        prev.delete('filename');
        prev.delete('uploadedOn');
        prev.delete('filetype');
        prev.delete('uploadedBy');
        return prev;
      },
      {
        replace: true,
      },
    );
  };

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const uploadedOnDate = searchParams.get('uploadedOn');
    const filename = searchParams.get('filename');
    const uploadedBy = searchParams.get('uploadedBy');
    const fileTypes = searchParams.get('filetype');

    setFilename(filename || '');
    setUploadedOn(uploadedOnDate?.trim() ? dayjs(uploadedOnDate).toDate() : undefined);
    setSelectedAttachmentFiletype(
      !!fileTypes && fileTypes.split(',').filter((item) => !!item && item.split(':').length === 2).length > 0
        ? fileTypes
            ?.split(',')
            .filter((item) => !!item && item.split(':').length === 2)
            .map((item) => ({
              value: item.split(':')[0],
              label: item.split(':')[1],
            }))
        : [],
    );
    setSelectedUser(
      !!uploadedBy && uploadedBy.split(',').filter((item) => !!item && item.split(':').length === 2).length > 0
        ? uploadedBy
            ?.split(',')
            .filter((item) => !!item && item.split(':').length === 2)
            .map((item) => ({
              value: item.split(':')[0],
              label: item.split(':')[1],
            }))
        : [],
    );

    handleSubmit({
      filename: filename || undefined,
      filetype:
        !!fileTypes && fileTypes.split(',').filter((item) => !!item && item.split(':').length === 2).length > 0
          ? fileTypes
              ?.split(',')
              .filter((item) => !!item && item.split(':').length === 2)
              .map((item) => ({
                value: item.split(':')[0],
                label: item.split(':')[1],
              }))
          : undefined,
      uploadedOn: uploadedOnDate?.trim() ? dayjs(uploadedOnDate).toDate() : undefined,
      uploadedBy:
        !!uploadedBy && uploadedBy.split(',').filter((item) => !!item && item.split(':').length === 2).length > 0
          ? uploadedBy
              ?.split(',')
              .filter((item) => !!item && item.split(':').length === 2)
              .map((item) => ({
                value: item.split(':')[0],
                label: item.split(':')[1],
              }))
          : undefined,
    });
  }, [searchParams, handleSubmit]);

  const onSubmit = () => {
    setSearchParams(
      (prev) => {
        prev.set('filename', filename);
        prev.set('uploadedOn', uploadedOn ? dayjs(uploadedOn).format('YYYY-MM-DD') : '');
        prev.set('filetype', selectedAttachmentFiletype.map((item) => `${item.value}:${item.label}`).join(','));
        prev.set('uploadedBy', selectedUser.map((item) => `${item.value}:${item.label}`).join(','));
        return prev;
      },
      {
        replace: true,
      },
    );

    handleSubmit({
      filename: filename,
      uploadedOn: uploadedOn,
      filetype: selectedAttachmentFiletype,
      uploadedBy: selectedUser,
    });
    setFiletypeFilterOpen(false);
    setCalendarOpen(false);
    setIsFilterModalOpen(false);
  };

  const handleAttachmentFiletypeSelect = (option: IOptions) => () => {
    if (selectedAttachmentFiletype.find((item) => item.value === option.value)) {
      setSelectedAttachmentFiletype(selectedAttachmentFiletype.filter((item) => item.value !== option.value));
    } else {
      setSelectedAttachmentFiletype([
        ...selectedAttachmentFiletype,
        {
          label: option.label,
          value: option.value,
        },
      ]);
    }
  };
  const { mutateAsync: fetchUsers } = useGetRequesterSuggestionsMutation({});

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchUsers({
        query: inputValue.trim(),
      });
      return result.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    [fetchUsers],
  );

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, IOptions>();
  }, []);

  const handleSelectedUserChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((account) => ({ value: account.value, label: account.label }));
    setSelectedUser(newValue);
  };

  useEffect(() => {
    if (focusField == 'filename') {
      inputRef?.current?.focus();
    } else if (focusField == 'uploadedBy') {
      selectRef?.current?.focus();
    } else if (focusField == 'uploadedOn') {
      setCalendarOpen(true);
    } else if (focusField == 'filetype') {
      setFiletypeFilterOpen(true);
    }
  }, [selectRef, inputRef, focusField]);

  useEffect(() => {
    if (filename != '' || selectedAttachmentFiletype.length > 0 || uploadedOn != undefined || selectedUser.length > 0) {
      setIsFiltersApplied(true);
    } else {
      setIsFiltersApplied(false);
    }
  }, [filename, selectedAttachmentFiletype, selectedUser, uploadedOn]);
  return (
    <>
      {isFilterModalOpen && (
        <Dialog open={isFilterModalOpen} onOpenChange={setIsFilterModalOpen}>
          <DialogContent className="w-[620px] h-[350px] border-2 bg-white	">
            <div className="h-[52px] p-4 flex  justify-between border-b-2">
              <div>
                <DialogHeader>
                  <DialogTitle>Filters</DialogTitle>
                </DialogHeader>
              </div>
              <div>
                <XIcon
                  className="w-[20px] h-[20px] cursor-pointer"
                  onClick={() => {
                    setIsFilterModalOpen(false);
                    setFiletypeFilterOpen(false);
                    setCalendarOpen(false);
                  }}
                />
              </div>
            </div>
            <div className="w-[568px] h-[200px] m-auto">
              <div className="flex gap-4 py-4 justify-between">
                <div>
                  <Label htmlFor="name" className="text-right">
                    File
                  </Label>
                  <Input
                    id="fileName"
                    value={filename}
                    ref={inputRef}
                    className="col-span-3 w-[240px]"
                    placeholder="Search by file name"
                    onChange={(e) => {
                      setFilename(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <Label htmlFor="name" className="text-right">
                    File type
                  </Label>
                  <AttachmentsFiletypeDropdown
                    className="w-[240px]"
                    handleSelect={handleAttachmentFiletypeSelect}
                    selected={selectedAttachmentFiletype}
                    defaultOpen={filetypeFilterOpen}
                  />
                </div>
              </div>
              <div className="flex  py-4 justify-between">
                <div className="w-[240px]">
                  <Label htmlFor="name" className="text-right">
                    Uploaded by
                  </Label>
                  <AsyncSelect
                    isMulti
                    ref={selectRef}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    onChange={handleSelectedUserChange}
                    value={selectedUser}
                    loadOptions={loadSelectOptions}
                    autoFocus={focusField == 'uploadedBy'}
                    className="w-[240px] text-sm shadow-sm"
                    styles={selectStyles}
                    placeholder="Search by uploaded by"
                    classNames={selectClasses}
                  />
                </div>
                <div className="w-[306px]">
                  <Label htmlFor="name" className="text-right">
                    Uploaded On
                  </Label>
                  <div className="w-[290px]">
                    <DatePicker
                      className=" w-[306px] "
                      value={uploadedOn}
                      defaultOpen={calendarOpen}
                      onChange={(e) => {
                        setUploadedOn(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="h-[64px] p-4 border-t-2">
              <DialogFooter>
                <Button onClick={handleReset} variant="outline">
                  Reset
                </Button>
                <Button onClick={onSubmit} disabled={!isFiltersApplied} className="flex items-center gap-2">
                  Apply
                </Button>
              </DialogFooter>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default AttachmentFilters;
