import pageNotFoundIllustration from '@/assets/404.svg';
import AppBarComponent from '@/components/shared/AppBar';

const NotFoundPage = () => {
  return (
    <div className="h-screen overflow-hidden">
      <AppBarComponent />
      <div className="h-[80vh] flex items-center justify-center">
        <div className="max-w-[400px] flex flex-col justify-center items-center gap-8">
          <img src={pageNotFoundIllustration} alt="404" />
          <h1 className="text-3xl">Page Not Found!</h1>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
