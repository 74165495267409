import {
  deleteContact,
  getContactsRoleSuggestions,
  postCreateContactForCustomer,
  postUpdateContact,
} from '@/api-functions/contacts.api';
import { IApiResponse } from '@/types/common.types';
import { IContact, IContactsRoleSuggestionsResponse, ICreateContactsResponse } from '@/types/contacts.types';
import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CustomerQueryKeys } from './useCustomerQuery';

export const usePostCreateContactsForCustomer = ({
  customerId,
  customConfig,
}: {
  customConfig?: UseMutationOptions<ICreateContactsResponse, AxiosError<ICreateContactsResponse>, IContact>;
  customerId: string;
}) => {
  const queryClient = useQueryClient();
  const postCreateCustomerContactMutation = useMutation<
    ICreateContactsResponse,
    AxiosError<ICreateContactsResponse>,
    IContact
  >({
    mutationFn: ({ ...contact }: IContact) =>
      postCreateContactForCustomer({
        customerId,
        name: contact.name.trim(),
        primaryEmail: contact?.primaryEmail?.trim() ? contact.primaryEmail.trim() : null,
        primaryPhone: contact?.primaryPhone?.trim() ? contact.primaryPhone.trim() : null,
        role: contact.role.trim(),
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: CustomerQueryKeys.getCustomerDetailsById(customerId),
      });
    },
  });

  return postCreateCustomerContactMutation;
};

export const usePostUpdateContact = ({
  customerId,
  customConfig,
}: {
  customConfig?: UseMutationOptions<ICreateContactsResponse, AxiosError<ICreateContactsResponse>, IContact>;
  customerId: string;
}) => {
  const queryClient = useQueryClient();
  const postCreateCustomerContactMutation = useMutation<
    ICreateContactsResponse,
    AxiosError<ICreateContactsResponse>,
    IContact
  >({
    mutationFn: ({ ...contact }: IContact) =>
      postUpdateContact({
        contactId: contact.id,
        updateObject: {
          name: contact.name.trim(),
          primaryEmail: contact.primaryEmail?.trim() ? contact.primaryEmail.trim() : null,
          primaryPhone: contact.primaryPhone?.trim() ? contact.primaryPhone.trim() : null,
          role: contact.role.trim(),
        },
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: CustomerQueryKeys.getCustomerDetailsById(customerId),
      });
    },
  });

  return postCreateCustomerContactMutation;
};

export const useDeleteContact = ({
  customerId,
  customConfig,
}: {
  customConfig?: UseMutationOptions<IApiResponse<null>, AxiosError<IApiResponse<null>>, { contactId: string }>;
  customerId: string;
}) => {
  const queryClient = useQueryClient();
  const deleteContactMutation = useMutation<IApiResponse<null>, AxiosError<IApiResponse<null>>, { contactId: string }>({
    mutationFn: ({ contactId }: { contactId: string }) =>
      deleteContact({
        contactId,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: CustomerQueryKeys.getCustomerDetailsById(customerId),
      });
    },
  });

  return deleteContactMutation;
};

export const useGetContactRoleSuggestions = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<IContactsRoleSuggestionsResponse, unknown, { query: string }>;
}) => {
  const getContactsRoleSuggestionsQuery = useMutation<IContactsRoleSuggestionsResponse, unknown, { query: string }>({
    mutationFn: ({ query }: { query: string }) => getContactsRoleSuggestions(query),
    ...customConfig,
  });

  return getContactsRoleSuggestionsQuery;
};
