import { IApiResponse } from '@/types/common.types';
import {
  ICreateActionOnlyRequestObject,
  ICreateConditionalStateRequestObject,
  ICreateConditionObject,
  ICreateExitStateRequestObject,
  ICreateGotoRequestObject,
  ICreateTransitionRequestObject,
  ICreateTriggerStateRequestObject,
  ICreateWaitStateRequestObject,
  ICreateWorkflowRequestObject,
  IDateCondition,
  IEmailRequestObject,
  IGetActionByIdResponse,
  IGetConditionByIdResponse,
  IGetStateById,
  IGetWorkflowByIdResponse,
  IGetWorkflowsDryRunResponse,
  IGetWorkflowsResponse,
} from '@/types/workflow.type';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  createActionOnlyState,
  createCondition,
  createConditionalState,
  createEmailAction,
  createExitState,
  createGotoAction,
  createTransition,
  createTriggerState,
  createWaitState,
  createWorkflow,
  deleteAction,
  deleteState,
  deleteWorkflow,
  dryRunWorkflow,
  getActionById,
  getConditionById,
  getTriggerStateById,
  getWorkflow,
  getWorkflowDryRunResults,
  getWorkflows,
  initiateWorkflow,
  postUpdateActionOnly,
  postUpdateConditionalState,
  postUpdateExitState,
  postUpdateTriggerState,
  postUpdateWaitState,
  postUpdateWorkflow,
  publishWorkflow,
  runWorkflow,
  togglePauseWorkflow,
  updateCondition,
  updateEmailAction,
  updateGotoAction,
  updateWaitCondition,
} from './../../api-functions/workflow.api';

const WorkflowQueryKeys = {
  getWorkflowById: (workflowId: string) => ['getWorkflowById', workflowId],
  getStateById: (stateId: string) => ['getTriggerStateById', stateId],
  getConditionById: (conditionId: string) => ['getConditionById', conditionId],
  getActionById: (actionId: string) => ['getActionById', actionId],
  getWorkflows: ({ page, limit }: { page: number; limit: number }) => ['getWorkflows', page, limit],
  getWorkflowDryRun: (workflowId: string) => ['getWorkflowDryRun', workflowId],
};

export const usePostCreateWorkflow = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateWorkflowRequestObject
  >;
}) => {
  const postCreateWorkflowMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateWorkflowRequestObject
  >({
    mutationFn: createWorkflow,
    ...customConfig,
  });

  return postCreateWorkflowMutation;
};

export const usePostUpdateWorkflow = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      fields: (keyof ICreateWorkflowRequestObject)[];
      values: ICreateWorkflowRequestObject;
      workflowId: string;
    }
  >;
}) => {
  const postUpdateWorkflowMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      fields: (keyof ICreateWorkflowRequestObject)[];
      values: ICreateWorkflowRequestObject;
      workflowId: string;
    }
  >({
    mutationFn: postUpdateWorkflow,
    ...customConfig,
  });

  return postUpdateWorkflowMutation;
};

export const usePostInitiateWorkflow = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      workflowId: string;
      trigger: ICreateTriggerStateRequestObject;
      exit: ICreateExitStateRequestObject;
    }
  >;
}) => {
  const postInitiateWorkflowMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      workflowId: string;
      trigger: ICreateTriggerStateRequestObject;
      exit: ICreateExitStateRequestObject;
    }
  >({
    mutationFn: ({ workflowId, trigger, exit }) => initiateWorkflow(workflowId, trigger, exit),
    ...customConfig,
  });

  return postInitiateWorkflowMutation;
};

export const usePostCreateCondition = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateConditionObject
  >;
}) => {
  const postCreateConditionMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateConditionObject
  >({
    mutationFn: createCondition,
    ...customConfig,
  });

  return postCreateConditionMutation;
};

export const usePostUpdateCondition = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      conditionId: string;
      rule: ICreateConditionObject['rule'];
    }
  >;
}) => {
  const queryClient = useQueryClient();

  const postUpdateConditionMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      conditionId: string;
      rule: ICreateConditionObject['rule'];
    }
  >({
    mutationFn: updateCondition,
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getConditionById(variables.conditionId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postUpdateConditionMutation;
};

export const usePostUpdateWaitDateCondition = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      conditionId: string;
      rule: IDateCondition;
    }
  >;
}) => {
  const queryClient = useQueryClient();

  const postUpdateConditionMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      conditionId: string;
      rule: IDateCondition;
    }
  >({
    mutationFn: updateWaitCondition,
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getConditionById(variables.conditionId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postUpdateConditionMutation;
};

export const usePostCreateTriggerState = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateTriggerStateRequestObject
  >;
}) => {
  const postCreateTriggerStateMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateTriggerStateRequestObject
  >({
    mutationFn: createTriggerState,
    ...customConfig,
  });

  return postCreateTriggerStateMutation;
};

export const usePostCreateWaitState = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateWaitStateRequestObject
  >;
}) => {
  const queryClient = useQueryClient();

  const postCreateWaitStateMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateWaitStateRequestObject
  >({
    mutationFn: createWaitState,
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getWorkflowById(variables.workflowId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postCreateWaitStateMutation;
};

export const usePostUpdateTriggerState = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      stateId: string;
      fields: (keyof ICreateTriggerStateRequestObject)[];
      values: Partial<ICreateTriggerStateRequestObject>;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postUpdateTriggerStateMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      stateId: string;
      fields: (keyof ICreateTriggerStateRequestObject)[];
      values: Partial<ICreateTriggerStateRequestObject>;
    }
  >({
    mutationFn: postUpdateTriggerState,
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getStateById(variables.stateId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postUpdateTriggerStateMutation;
};

export const usePostUpdateExitState = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      stateId: string;
      fields: (keyof ICreateExitStateRequestObject)[];
      values: Partial<ICreateExitStateRequestObject>;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postUpdateExitStateMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      stateId: string;
      fields: (keyof ICreateExitStateRequestObject)[];
      values: Partial<ICreateExitStateRequestObject>;
    }
  >({
    mutationFn: postUpdateExitState,
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getStateById(variables.stateId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postUpdateExitStateMutation;
};

export const usePostUpdateWaitState = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      stateId: string;
      fields: (keyof ICreateWaitStateRequestObject)[];
      values: Partial<ICreateWaitStateRequestObject>;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postUpdateWaitStateMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      stateId: string;
      fields: (keyof ICreateWaitStateRequestObject)[];
      values: Partial<ICreateWaitStateRequestObject>;
    }
  >({
    mutationFn: postUpdateWaitState,
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getStateById(variables.stateId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postUpdateWaitStateMutation;
};

export const usePostUpdateActionOnlyState = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      stateId: string;
      fields: (keyof ICreateActionOnlyRequestObject)[];
      values: Partial<ICreateActionOnlyRequestObject>;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postUpdateActionOnlyStateMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      stateId: string;
      fields: (keyof ICreateActionOnlyRequestObject)[];
      values: Partial<ICreateActionOnlyRequestObject>;
    }
  >({
    mutationFn: postUpdateActionOnly,
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getStateById(variables.stateId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postUpdateActionOnlyStateMutation;
};

export const usePostUpdateConditionalState = ({
  customConfig,
  workflowId,
  containsGoto = false,
}: {
  workflowId: string;
  containsGoto?: boolean;
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      stateId: string;
      fields: (keyof ICreateConditionalStateRequestObject)[];
      values: Partial<ICreateConditionalStateRequestObject>;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postUpdateTriggerStateMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      stateId: string;
      fields: (keyof ICreateConditionalStateRequestObject)[];
      values: Partial<ICreateConditionalStateRequestObject>;
    }
  >({
    mutationFn: postUpdateConditionalState,
    ...customConfig,
    onSuccess(data, variables, context) {
      if (containsGoto && workflowId) {
        queryClient.refetchQueries({
          queryKey: WorkflowQueryKeys.getWorkflowById(workflowId),
        });
      }

      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getStateById(variables.stateId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postUpdateTriggerStateMutation;
};

export const usePostCreateConditionalState = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateConditionalStateRequestObject
  >;
}) => {
  const queryClient = useQueryClient();
  const postCreateConditionalStateMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateConditionalStateRequestObject
  >({
    mutationFn: createConditionalState,
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getWorkflowById(variables.workflowId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postCreateConditionalStateMutation;
};

export const usePostUpdateEmailAction = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      actionId: string;
      fields: (keyof IEmailRequestObject)[];
      values: Partial<IEmailRequestObject>;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postUpdateEmailAction = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      actionId: string;
      fields: (keyof IEmailRequestObject)[];
      values: Partial<IEmailRequestObject>;
    }
  >({
    mutationFn: updateEmailAction,
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getActionById(variables.actionId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postUpdateEmailAction;
};

export const usePostUpdateGotoAction = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      actionId: string;
      fields: (keyof ICreateGotoRequestObject)[];
      values: Partial<ICreateGotoRequestObject>;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postUpdateGotoAction = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    {
      actionId: string;
      fields: (keyof ICreateGotoRequestObject)[];
      values: Partial<ICreateGotoRequestObject>;
    }
  >({
    mutationFn: updateGotoAction,
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getActionById(variables.actionId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postUpdateGotoAction;
};

export const usePostCreateExitState = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateExitStateRequestObject
  >;
}) => {
  const postCreateExitStateMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateExitStateRequestObject
  >({
    mutationFn: createExitState,
    ...customConfig,
  });

  return postCreateExitStateMutation;
};

export const usePostCreateTransitionAction = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateTransitionRequestObject
  >;
}) => {
  const postCreateTransitionMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateTransitionRequestObject
  >({
    mutationFn: createTransition,
    ...customConfig,
  });

  return postCreateTransitionMutation;
};

export const usePostCreateEmailAction = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    { email: IEmailRequestObject; workflowId: string }
  >;
}) => {
  const postCreateEmailActionMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    { email: IEmailRequestObject; workflowId: string }
  >({
    mutationFn: ({ email }) => createEmailAction(email),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postCreateEmailActionMutation;
};

export const usePostCreateGotoAction = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateGotoRequestObject
  >;
}) => {
  const postCreateGotoActionMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateGotoRequestObject
  >({
    mutationFn: createGotoAction,
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postCreateGotoActionMutation;
};

export const usePostCreateActionOnlyState = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateActionOnlyRequestObject
  >;
}) => {
  const queryClient = useQueryClient();

  const postCreateActionOnlyStateMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<
      IApiResponse<{
        id: string;
      }>
    >,
    ICreateActionOnlyRequestObject
  >({
    mutationFn: createActionOnlyState,
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getWorkflowById(variables.workflowId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postCreateActionOnlyStateMutation;
};

export const useGetWorkflowById = ({
  customConfig,
  workflowId,
}: {
  customConfig?: Omit<UseQueryOptions<IGetWorkflowByIdResponse>, 'queryKey'>;
  workflowId: string;
}) => {
  const getWorkflowByIdQuery = useQuery<IGetWorkflowByIdResponse>({
    queryKey: WorkflowQueryKeys.getWorkflowById(workflowId),
    queryFn: () => getWorkflow(workflowId),
    ...customConfig,
  });

  return getWorkflowByIdQuery;
};

export const useGetStateById = ({
  customConfig,
  stateId,
}: {
  customConfig?: Omit<UseQueryOptions<IGetStateById>, 'queryKey'>;
  stateId: string;
}) => {
  const getTriggerStateByIdQuery = useQuery<IGetStateById>({
    queryKey: WorkflowQueryKeys.getStateById(stateId),
    queryFn: () => getTriggerStateById(stateId),
    ...customConfig,
  });

  return getTriggerStateByIdQuery;
};

export const useGetConditionById = ({
  customConfig,
  conditionId,
}: {
  customConfig?: Omit<UseQueryOptions<IGetConditionByIdResponse>, 'queryKey'>;
  conditionId: string;
}) => {
  const getConditionByIdQuery = useQuery<IGetConditionByIdResponse>({
    queryKey: WorkflowQueryKeys.getConditionById(conditionId),
    queryFn: () => getConditionById(conditionId),
    ...customConfig,
  });

  return getConditionByIdQuery;
};

export const useGetActionById = ({
  customConfig,
  actionId,
}: {
  customConfig?: Omit<UseQueryOptions<IGetActionByIdResponse>, 'queryKey'>;
  actionId: string;
}) => {
  const getActionByIdQuery = useQuery<IGetActionByIdResponse>({
    queryKey: WorkflowQueryKeys.getActionById(actionId),
    queryFn: () => getActionById(actionId),
    ...customConfig,
  });

  return getActionByIdQuery;
};

export const useDeleteActionById = ({
  customConfig,
  actionId,
  stateId,
  isTransition = false,
  workflowId,
}: {
  customConfig?: Omit<UseMutationOptions<IApiResponse<void>, AxiosError<IApiResponse<void>>>, 'mutationFn'>;
  actionId: string;
  stateId: string;
  isTransition?: boolean;
  workflowId?: string;
}) => {
  const queryClient = useQueryClient();
  const deleteActionByIdMutation = useMutation<IApiResponse<void>, AxiosError<IApiResponse<void>>>({
    mutationFn: () => deleteAction(actionId),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getStateById(stateId),
      });
      if (isTransition && workflowId) {
        queryClient.refetchQueries({
          queryKey: WorkflowQueryKeys.getWorkflowById(workflowId),
        });
      }
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return deleteActionByIdMutation;
};

export const useDeleteStateById = ({
  customConfig,
  stateId,
  workflowId,
}: {
  customConfig?: Omit<UseMutationOptions<IApiResponse<void>, AxiosError<IApiResponse<void>>>, 'mutationFn'>;
  stateId: string;
  workflowId: string;
}) => {
  const queryClient = useQueryClient();
  const deleteStateByIdMutation = useMutation<IApiResponse<void>, AxiosError<IApiResponse<void>>>({
    mutationFn: () => deleteState(stateId),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: WorkflowQueryKeys.getWorkflowById(workflowId),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return deleteStateByIdMutation;
};

export const useGetWorkflowsQuery = ({
  customConfig,
  page,
  limit,
}: {
  page: number;
  limit: number;
  customConfig?: Omit<UseQueryOptions<IGetWorkflowsResponse>, 'queryKey'>;
}) => {
  const getWorkflowsQuery = useQuery<IGetWorkflowsResponse>({
    queryKey: WorkflowQueryKeys.getWorkflows({
      limit,
      page,
    }),
    queryFn: () =>
      getWorkflows({
        page,
        limit,
      }),
    ...customConfig,
  });

  return getWorkflowsQuery;
};

export const useGetWorkflowsDryRunQuery = ({
  customConfig,
  workflowId,
}: {
  customConfig?: Omit<UseQueryOptions<IGetWorkflowsDryRunResponse>, 'queryKey'>;
  workflowId: string;
}) => {
  const getWorkflowsDryRunQuery = useQuery<IGetWorkflowsDryRunResponse>({
    queryKey: WorkflowQueryKeys.getWorkflowDryRun(workflowId),
    queryFn: () => getWorkflowDryRunResults({ workflowId }),
    ...customConfig,
  });

  return getWorkflowsDryRunQuery;
};
export const usePublishWorkflow = ({
  customConfig,
  workflowId,
}: {
  customConfig?: Omit<UseMutationOptions<IApiResponse<void>, AxiosError<IApiResponse<void>>>, 'mutationFn'>;
  workflowId: string;
}) => {
  const queryClient = useQueryClient();
  const publishWorkflowByIdMutation = useMutation<IApiResponse<void>, AxiosError<IApiResponse<void>>>({
    mutationFn: () =>
      publishWorkflow({
        workflowId,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: ['getWorkflows'] });
      queryClient.invalidateQueries({ queryKey: ['getConditionById'] });
      queryClient.invalidateQueries({ queryKey: ['getWorkflowById', workflowId] });
      queryClient.invalidateQueries({ queryKey: ['getTriggerStateById'] });
      queryClient.invalidateQueries({ queryKey: [''] });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return publishWorkflowByIdMutation;
};

export const useDeleteWorkflow = ({
  customConfig,
  workflowId,
}: {
  customConfig?: Omit<UseMutationOptions<IApiResponse<void>, AxiosError<IApiResponse<void>>>, 'mutationFn'>;
  workflowId: string;
}) => {
  const queryClient = useQueryClient();
  const deleteWorkflowByIdMutation = useMutation<IApiResponse<void>, AxiosError<IApiResponse<void>>>({
    mutationFn: () =>
      deleteWorkflow({
        workflowId,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getWorkflows');
        },
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return deleteWorkflowByIdMutation;
};

export const useRunWorkflow = ({
  customConfig,
  workflowId,
}: {
  customConfig?: Omit<UseMutationOptions<IApiResponse<void>, AxiosError<IApiResponse<void>>>, 'mutationFn'>;
  workflowId: string;
}) => {
  const queryClient = useQueryClient();
  const runWorkflowByIdMutation = useMutation<IApiResponse<void>, AxiosError<IApiResponse<void>>>({
    mutationFn: () =>
      runWorkflow({
        workflowId,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getWorkflows');
        },
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return runWorkflowByIdMutation;
};

export const useDryRunWorkflow = ({
  customConfig,
  workflowId,
}: {
  customConfig?: Omit<UseMutationOptions<IApiResponse<void>, AxiosError<IApiResponse<void>>>, 'mutationFn'>;
  workflowId: string;
}) => {
  const queryClient = useQueryClient();
  const dryRunWorkflowByIdMutation = useMutation<IApiResponse<void>, AxiosError<IApiResponse<void>>>({
    mutationFn: () =>
      dryRunWorkflow({
        workflowId,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getWorkflows');
        },
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return dryRunWorkflowByIdMutation;
};

export const useTogglePauseWorkflow = ({
  customConfig,
  workflowId,
}: {
  customConfig?: Omit<UseMutationOptions<IApiResponse<void>, AxiosError<IApiResponse<void>>>, 'mutationFn'>;
  workflowId: string;
}) => {
  const queryClient = useQueryClient();
  const togglePauseWorkflowByIdMutation = useMutation<IApiResponse<void>, AxiosError<IApiResponse<void>>>({
    mutationFn: () =>
      togglePauseWorkflow({
        workflowId,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getWorkflows');
        },
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return togglePauseWorkflowByIdMutation;
};
