import PlanningTable from '@/components/plan/PlanningTable';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { usePostFinalizePlan } from '@/hooks/api-hooks/usePlanQuery';
import { cn } from '@/lib/utils';
import { ArrowLeft, ArrowRight, Check, Loader2Icon } from 'lucide-react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Step = ({
  step,
  currentStep,
  heading,
  description,
  isFinal,
}: {
  step: 0 | 1 | 2;
  currentStep: 0 | 1 | 2;
  heading: string;
  description: string;
  isFinal?: boolean;
}) => {
  return (
    <div className="flex gap-2 items-start">
      <div>
        <Button disabled={currentStep !== step} size="icon" className="rounded-full hover:cursor-auto">
          {currentStep > step ? <Check /> : step + 1}
        </Button>
      </div>
      <div className={cn('max-w-[300px] flex gap-2 h-full', currentStep !== step && 'text-secondary-foreground/50')}>
        <div>
          <h3 className=" font-bold ">{heading}</h3>
          <p className="text-sm">{description}</p>
        </div>
        {!isFinal && (
          <div className=" self-center ">
            <ArrowRight />
          </div>
        )}
      </div>
    </div>
  );
};

const StepperHeader = ({ currentStep }: { currentStep: 0 | 1 | 2 }) => {
  return (
    <div className="w-full flex justify-between text-primary">
      <Step step={0} currentStep={currentStep} heading="Set Annual Budget" description="Set your annual budget here" />
      <Step
        step={1}
        currentStep={currentStep}
        heading="Distribute Among Categories"
        description="Divide the annual revenue, expense budget across business categories"
      />
      <Step
        step={2}
        currentStep={currentStep}
        heading="Allocate Monthly Targets"
        description="Break down annual category budgets into monthly targets"
        isFinal
      />
    </div>
  );
};

const StepperBody = ({
  currentStep,
  className,
  planId,
}: {
  currentStep: 0 | 1 | 2;
  className?: string;
  planId: string;
}) => {
  return (
    <div className={cn('my-8', className)}>
      <PlanningTable
        isDefaultExpanded={currentStep > 0}
        planId={planId}
        defaultScope={currentStep === 2 ? 'monthly' : 'yearly'}
        hideScopeTabs
        isExpandable={currentStep > 0}
        showReceivables={currentStep === 1}
      />
    </div>
  );
};

const StepperFooter = ({
  currentStep,
  onNext,
  onBack,
  isLoading,
}: {
  currentStep: 0 | 1 | 2;
  onNext?: () => void;
  onBack?: () => void;
  isLoading?: boolean;
}) => {
  return (
    <div className="flex gap-4 items-center">
      <Button disabled={currentStep === 0 || isLoading} className="gap-4" onClick={onBack}>
        <ArrowLeft className="w-3 h-3" /> Back
      </Button>
      {currentStep === 2 ? (
        <Button disabled={isLoading} className="gap-4" onClick={onNext}>
          Finish {isLoading ? <Loader2Icon className=" animate-spin w-4 h-4 " /> : <Check className="w-3 h-3" />}
        </Button>
      ) : (
        <Button className="gap-4" onClick={onNext}>
          Next <ArrowRight className="w-3 h-3" />
        </Button>
      )}
    </div>
  );
};

const CreatePlanView = () => {
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const { toast } = useToast();
  const navigate = useNavigate();
  const { planId } = useParams();
  const { mutate: finalizePlan, isPending } = usePostFinalizePlan({
    planId: planId as string,
    customConfig: {
      onSuccess: () => {
        toast({
          title: 'Plan finalized',
          description: 'Your plan has been finalized successfully',
          variant: 'default',
        });
        navigate('/plan');
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'An error occurred while finalizing your plan',
          variant: 'destructive',
        });
      },
    },
  });

  const handleNext = () => {
    if (step === 2) {
      finalizePlan();
      return;
    }
    const nextStep = ((step + 1) % 3) as 0 | 1 | 2;
    setStep(nextStep);
  };

  const handleBack = () => {
    const previousStep = ((step - 1) % 3) as 0 | 1 | 2;
    setStep(previousStep);
  };

  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8 flex-1 flex flex-col">
      <StepperHeader currentStep={step} />
      <StepperBody planId={planId as string} className="flex-1" currentStep={step} />
      <StepperFooter isLoading={isPending} currentStep={step} onNext={handleNext} onBack={handleBack} />
    </div>
  );
};

export default CreatePlanView;
