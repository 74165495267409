import { Button } from '@/components/ui/button';
import { usePostDeletePosting } from '@/hooks/api-hooks/usePostingQuery';
import { Trash2Icon } from 'lucide-react';
import { useState } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '../../ui/dialog';
import { useToast } from '../../ui/use-toast';
import PostedItemDescriptionSmall from '../ledger/PostedItemDescription';

const DeletePosting = ({
  descriptionTitle,
  descriptionValue,
  type,
  timestamp,
  createdBy,
  invoiceId,
  creditId,
  postingId,
}: {
  descriptionTitle: string;
  descriptionValue: string;
  type: string;
  invoiceId?: string;
  creditId?: string;
  timestamp?: string;
  createdBy: string;
  postingId: string;
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { toast } = useToast();

  const { mutate: deleteCredit, isPending } = usePostDeletePosting({
    invoiceId: invoiceId || '',
    creditId: creditId || '',
    customConfig: {
      onError(error) {
        toast({
          title: 'Error',
          description: error.response?.data.message || 'Unable to delete posting',
          variant: 'destructive',
        });
        setIsDeleteDialogOpen(false);
      },
      onSuccess() {
        toast({
          description: 'Posting deleted successfully',
        });
        setIsDeleteDialogOpen(false);
      },
    },
  });

  return (
    <>
      <Button size="icon" variant="ghost">
        <Trash2Icon className=" w-4 h-4 text-destructive " onClick={() => setIsDeleteDialogOpen(true)} />
      </Button>
      {isDeleteDialogOpen && (
        <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
          <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
            <DialogHeader className=" font-semibold border-b py-4 ">Delete posting</DialogHeader>
            <div className=" border rounded-lg p-4 ">
              <PostedItemDescriptionSmall
                createdAt={timestamp}
                createdBy={createdBy}
                descriptionTitle={descriptionTitle}
                descriptionValue={descriptionValue}
                type={type}
              />
            </div>
            <p className="text-sm">Are you sure you want to delete this posting?</p>
            <DialogFooter className=" border-t py-4 ">
              <Button onClick={() => setIsDeleteDialogOpen(false)} variant="outline">
                Cancel
              </Button>
              <Button
                className=" flex items-center gap-2 "
                loading={isPending}
                variant="destructive"
                onClick={() =>
                  deleteCredit({
                    postingId,
                  })
                }
              >
                Delete
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default DeletePosting;
