/* eslint-disable react/display-name */
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import React, { ChangeEvent, useRef } from 'react';

interface DropzoneProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  classNameWrapper?: string;
  className?: string;
  handleOnDrop: (_: FileList | null) => void;
}

const DropAttachment = React.forwardRef<HTMLDivElement, DropzoneProps>(
  ({ className, classNameWrapper, handleOnDrop, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    // Function to handle drag over event
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };

    // Function to handle drop event
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const { files } = e.dataTransfer;
      if (inputRef.current) {
        inputRef.current.files = files;
        handleOnDrop(files);
      }
    };

    // Function to simulate a click on the file input element
    const handleButtonClick = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };

    const resetInput = () => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };

    return (
      <Card
        ref={ref}
        className={cn(
          `border-2 border-dashed bg-[#EEF5FF] hover:cursor-pointer hover:border-muted-foreground/50 mt-4`,
          classNameWrapper,
        )}
      >
        <CardContent
          className="flex flex-col items-center justify-center space-y-2 px-2 py-4 text-xs border-2-black"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleButtonClick}
        >
          <div className="flex items-center justify-center gap-6 flex-col text-muted-foreground">
            <div className="text-[#1554F6] font-[500] text-[14px]">Click here or drag file to upload</div>
            <Input
              {...props}
              value={undefined}
              ref={inputRef}
              type="file"
              accept=".csv,.xls,.xlsx,.png,.jpg,.jpeg,.docx,.pdf"
              className={cn('hidden', className)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleOnDrop(e.target.files);
                resetInput();
              }}
            />
          </div>
        </CardContent>
      </Card>
    );
  },
);

export default DropAttachment;
