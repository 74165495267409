import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { usePostUpdateCustomerMutation } from '@/hooks/api-hooks/useCustomerQuery';
import { IAddress } from '@/types/customer.types';
import { FormLabel } from '@mui/material';
import { Edit2Icon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../ui/button';

const EditAddressModal = ({ customerId, address }: { customerId: string; address?: IAddress }) => {
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
  const [nextButtonEnabled, setNextEnabled] = useState<boolean>(false);
  const { toast } = useToast();

  const { mutate: updateCustomer } = usePostUpdateCustomerMutation({
    customerId,
    customConfig: {
      onError: () => {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
      },
      onSettled: () => {
        setIsEditAddressModalOpen(false);
      },
    },
  });

  const form = useForm<IAddress>({
    values: {
      city: address?.city ?? '',
      country: address?.country ?? '',
      line1: address?.line1 ?? '',
      line2: address?.line2 ?? '',
      state: address?.state ?? '',
      zipCode: address?.zipCode ?? '',
    },
  });

  const onSubmit = async (data: IAddress) => {
    updateCustomer({
      fields: ['registeredAddress'],
      updateObject: {
        registeredAddress: {
          ...data,
        },
      },
    });
  };

  useEffect(() => {
    form.reset();
    setNextEnabled(false);
  }, [isEditAddressModalOpen, form]);

  useEffect(() => {
    form.formState.isDirty && (form.getValues().line1 == '' ? setNextEnabled(false) : setNextEnabled(true));
  }, [form.watch()]);
  return (
    <>
      <Button variant="outline" size="icon" onClick={() => setIsEditAddressModalOpen(true)}>
        <Edit2Icon className="w-4 h-4" />
      </Button>
      {isEditAddressModalOpen && (
        <Dialog open={isEditAddressModalOpen} onOpenChange={setIsEditAddressModalOpen}>
          <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <DialogHeader>
                  <DialogTitle>Edit Address</DialogTitle>
                </DialogHeader>
                <FormField
                  control={form.control}
                  name="line1"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel className=" text-sm" style={{ color: 'black' }}>
                        First address line
                      </FormLabel>
                      <FormControl>
                        <Input autoComplete="off" placeholder="Enter first address line" {...field} required={true} />
                      </FormControl>
                      {fieldState.error && <FormMessage className=" text-xs ">{fieldState.error.message}</FormMessage>}
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="line2"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel className=" text-sm " style={{ color: 'black' }}>
                        Second address line
                      </FormLabel>
                      <FormControl>
                        <Input autoComplete="off" placeholder="Enter second address line" {...field} />
                      </FormControl>
                      {fieldState.error && <FormMessage className=" text-xs ">{fieldState.error.message}</FormMessage>}
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="city"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel className=" text-sm " style={{ color: 'black' }}>
                        City
                      </FormLabel>
                      <FormControl>
                        <Input autoComplete="off" placeholder="Enter city" {...field} />
                      </FormControl>
                      {fieldState.error && <FormMessage className=" text-xs ">{fieldState.error.message}</FormMessage>}
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="state"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel className=" text-sm " style={{ color: 'black' }}>
                        State
                      </FormLabel>
                      <FormControl>
                        <Input autoComplete="off" placeholder="Enter state" {...field} />
                      </FormControl>
                      {fieldState.error && <FormMessage className=" text-xs ">{fieldState.error.message}</FormMessage>}
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="country"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel className=" text-sm " style={{ color: 'black' }}>
                        Country
                      </FormLabel>
                      <FormControl>
                        <Input autoComplete="off" placeholder="Enter country" {...field} />
                      </FormControl>
                      {fieldState.error && <FormMessage className=" text-xs ">{fieldState.error.message}</FormMessage>}
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="zipCode"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel className=" text-sm " style={{ color: 'black' }}>
                        Zip code
                      </FormLabel>
                      <FormControl>
                        <Input autoComplete="off" placeholder="Enter zip code" {...field} />
                      </FormControl>
                      {fieldState.error && <FormMessage className=" text-xs ">{fieldState.error.message}</FormMessage>}
                    </FormItem>
                  )}
                />
                <DialogFooter className=" flex items-center gap-2 ">
                  <Button variant="outline" onClick={() => setIsEditAddressModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button type="submit" disabled={!nextButtonEnabled}>
                    Save
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const EditAddress = ({ address, customerId }: { address?: IAddress; customerId: string }) => {
  return (
    <div className=" flex gap-4 items-center ">
      {address && address.line1 ? (
        <HoverCard>
          <HoverCardTrigger className=" text-sm cursor-pointer underline ">{address.line1}</HoverCardTrigger>
          <HoverCardContent>
            <div className=" flex flex-col gap-2 ">
              <div>
                <span className=" font-semibold min-w-[70px] ">First Address line:</span> {address.line1}
              </div>
              <div>
                <span className=" font-semibold min-w-[70px] ">Second Address line:</span> {address.line2}
              </div>
              <div>
                <span className=" font-semibold min-w-[70px] ">City:</span> {address.city}
              </div>
              <div>
                <span className=" font-semibold min-w-[70px] ">State:</span> {address.state}
              </div>
              <div>
                <span className=" font-semibold min-w-[70px] ">Country:</span> {address.country}
              </div>
              <div>
                <span className=" font-semibold min-w-[70px] ">Zip code:</span> {address.zipCode}
              </div>
            </div>
          </HoverCardContent>
        </HoverCard>
      ) : (
        'Add address'
      )}
      <EditAddressModal address={address} customerId={customerId} />
    </div>
  );
};

export default EditAddress;
