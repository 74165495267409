interface ITableContent {
  columns: {
    fieldName: string;
    label: string;
    isVariable: boolean;
    columnId: string;
    operation: 'sum' | 'none';
    operationLabel: string;
  }[];
  rows: { [key: string]: string; id: string }[];
  variable: 'Invoice' | 'Manual';
}

export type SectionTypes = 'text' | 'button' | 'table';

interface IContentJSON<SectionType extends SectionTypes> {
  tagName: 'table';
  content: SectionType extends 'table' ? ITableContent : string;
}

export interface IProperties {
  element: {
    padding: {
      left: number;
      right: number;
      top: number;
      bottom: number;
    };
    align: 'center' | 'start' | 'end';
    url?: string;
  };
  container: {
    padding: {
      left: number;
      right: number;
      top: number;
      bottom: number;
    };
  };
}

export type IContent<SectionType extends SectionTypes> = SectionType extends 'table'
  ? IContentJSON<SectionType>
  : string;

export type Section<SectionType extends SectionTypes> = {
  id: string;
  type: SectionType;
  label: React.ReactNode;
  content: IContent<SectionType>;
  properties: IProperties;
};

const createNewId = () => {
  return `${Math.random() * 100000}`;
};

const SectionLabel = ({ label }: { label: string }) => {
  return <div className=" rounded-md border px-4 py-2 text-sm font-semibold ">{label}</div>;
};

export const SECTIONS: Section<SectionTypes>[] = [
  {
    id: 'text-editor',
    type: 'text',
    content: '<p>Hello</p>',
    label: <SectionLabel label="Text" />,
    properties: {
      element: {
        padding: {
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
        },
        align: 'start',
      },
      container: {
        padding: {
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
        },
      },
    },
  },
  {
    id: 'button',
    type: 'button',
    content: 'Hey',
    label: <SectionLabel label="Button" />,
    properties: {
      element: {
        padding: {
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
        },
        align: 'start',
      },
      container: {
        padding: {
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
        },
      },
    },
  },
  {
    id: 'table',
    type: 'table',
    content: {
      tagName: 'table',
      content: {
        columns: [
          {
            fieldName: 'fieldName',
            label: 'First label',
            isVariable: false,
            columnId: createNewId(),
            operation: 'none',
            operationLabel: '',
          },
          {
            fieldName: 'something',
            label: 'Second label',
            isVariable: true,
            columnId: createNewId(),
            operation: 'none',
            operationLabel: '',
          },
        ],
        rows: [
          {
            id: createNewId(),
            fieldName: 'FirstRow First Value',
            something: 'First Row Second Value',
          },
          {
            id: createNewId(),
            fieldName: 'Second Row First Value',
            something: 'Second Row First Value',
          },
        ],
        variable: 'Manual',
      },
    },
    label: <SectionLabel label="Table" />,
    properties: {
      element: {
        padding: {
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
        },
        align: 'start',
      },
      container: {
        padding: {
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
        },
      },
    },
  },
];

export const templates = SECTIONS.reduce(
  (acc, current) => {
    return {
      ...acc,
      [current.id]: current,
    };
  },
  {} as Record<string, Section<SectionTypes>>,
);
