import BankIdToName from '@/components/shared/BankIdToName';
import CategoryIdToLabel from '@/components/shared/CategoryIdToLabel';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Badge } from '@/components/ui/badge';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { ICredit } from '@/types/posting.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import dayjs from 'dayjs';

const CreditDetailItem = ({
  label,
  value,
  className,
  valueClassName,
}: {
  label: string;
  value: React.ReactNode;
  className?: string;
  valueClassName?: string;
}) => {
  return (
    <div className={cn(' flex flex-col gap-1', className)}>
      <div className="  text-muted-foreground  ">{label}</div>
      <div className={cn(valueClassName)}>{value}</div>
    </div>
  );
};

const CreditDetails = ({ credit, isLoading }: { credit?: ICredit; isLoading: boolean }) => {
  if (isLoading) {
    return (
      <div className=" py-2 mt-2 border-b text-sm bg-muted px-4 ">
        <div>
          <div className=" flex items-center gap-12 ">
            <div className=" flex flex-col gap-2 ">
              <h3 className=" text-muted-foreground ">Total Credit</h3>
              <div className=" flex items-center gap-8 ">
                <Skeleton className=" h-7 w-48 " />
              </div>
            </div>
            <div className=" flex flex-col gap-2 ">
              <h3 className=" text-muted-foreground ">Posted Amount</h3>
              <div className=" flex items-center gap-8 ">
                <Skeleton className=" h-7 w-48 " />
              </div>
            </div>
            <div className="bg-background shadow-sm border py-2 px-4 rounded-sm flex flex-col gap-2">
              <h3 className=" text-muted-foreground ">Available for posting</h3>
              <div className=" flex items-center gap-8 ">
                <Skeleton className=" h-7 w-48 " />
              </div>
            </div>
          </div>
        </div>
        <div className=" flex items-start gap-4 my-4 justify-between  ">
          <div className=" flex flex-col gap-2">
            <div className="  text-muted-foreground  ">
              <Skeleton className=" h-5 w-24 " />
            </div>
            <div>
              <Skeleton className=" h-5 w-24 " />
            </div>
          </div>
          <div className={' flex flex-col gap-2'}>
            <div className="  text-muted-foreground  ">
              <Skeleton className=" h-5 w-24 " />
            </div>
            <div>
              <Skeleton className=" h-5 w-24 " />
            </div>
          </div>
          <div className=" flex flex-col gap-2">
            <div className="  text-muted-foreground  ">
              <Skeleton className=" h-5 w-24 " />
            </div>
            <div>
              <Skeleton className=" h-5 w-24 " />
            </div>
          </div>{' '}
          <div className=" flex flex-col gap-2">
            <div className="  text-muted-foreground  ">
              <Skeleton className=" h-5 w-24 " />
            </div>
            <div>
              <Skeleton className=" h-5 w-24 " />
            </div>
          </div>
          <div className=" flex flex-col gap-2 flex-1 max-w-[50%]">
            <div className="  text-muted-foreground  ">
              <Skeleton className=" h-5 w-24 " />
            </div>
            <div>
              <Skeleton className=" h-5 " />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!credit) {
    return null;
  }

  return (
    <div className=" py-2 border-b text-sm bg-muted px-4 ">
      <div className=" flex items-center gap-12 ">
        <div>
          <h3 className=" text-muted-foreground ">Total Credit</h3>
          <div className=" flex items-center gap-8 ">
            <h2 className=" text-2xl font-semibold  ">{formatCurrencyByUnit(credit.creditAmount, 'actual')}</h2>
          </div>
        </div>
        <div>
          <h3 className=" text-muted-foreground ">Posted Amount</h3>
          <div className=" flex items-center gap-8 ">
            <h2 className=" text-2xl font-semibold  ">{formatCurrencyByUnit(credit.postedAmount, 'actual')}</h2>
          </div>
        </div>
        <div className="bg-background shadow-sm border py-2 px-4 rounded-sm">
          <h3 className=" text-muted-foreground ">Available for posting</h3>
          <div className=" flex items-center gap-8 ">
            <h2 className=" text-2xl font-semibold  ">{formatCurrencyByUnit(credit.remainingAmount, 'actual')}</h2>
          </div>
        </div>
      </div>
      <div className=" flex items-start gap-4 my-4 justify-between  ">
        <CreditDetailItem
          className=" min-w-[160px]"
          label="Posting Status"
          value={<Badge className=" capitalize ">{credit.postingStatus.split('_').join(' ').toLowerCase()}</Badge>}
        />
        <CreditDetailItem
          className=" min-w-[160px]"
          label="Transaction Date"
          value={dayjs(credit.transactionDate).format('DD MMM YYYY')}
        />

        <CreditDetailItem
          className=" min-w-[160px]"
          label="Category"
          value={<CategoryIdToLabel categoryId={credit.category} />}
        />
        <CreditDetailItem
          className=" min-w-[160px] max-w-[240px]"
          valueClassName="truncate"
          label="Bank"
          value={
            <ToolTipCell value={<BankIdToName bankId={credit.bankAccountId} />}>
              <BankIdToName bankId={credit.bankAccountId} />
            </ToolTipCell>
          }
        />

        <CreditDetailItem className=" flex-1 " label="Narration" value={credit.narration} />
      </div>
    </div>
  );
};

export default CreditDetails;
