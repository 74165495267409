'use client';

import { cn } from '@/lib/utils';
import { useCallback, useMemo, useState } from 'react';
import SelectComponent from '../ui/select-component';
interface IOption {
  label: string;
  value: string;
}

import { useGetOwnerRoleSuggestions, useGetOwnerSuggestions } from '@/hooks/api-hooks/useOwnersQuery';
import { IOptions } from '@/types/common.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import debounce from 'lodash.debounce';
import { GroupBase, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';

const components = {
  DropdownIndicator: null,
};

export const OwnerSelect = ({
  onFocus,
  onBlur,
  onChange,
  value,
  placeholder,
}: {
  onFocus: () => void;
  onBlur: () => void;
  placeholder: string;
  onChange: (_: IOption | undefined) => void;
  value?: IOption;
}) => {
  const { mutateAsync: fetchOwners } = useGetOwnerSuggestions({});

  const selectStyles = useMemo(() => {
    return {
      ...getStylesForSelect<false, IOption>(),
      // @ts-expect-error base is untyped
      control: (base) => ({
        ...base,
        border: 'none',
        '&:hover': {
          border: 'none',
        },
        outline: 'none',
        boxShadow: 'none',
        height: '2.25rem',
        padding: 0,
        minHeight: '2.25rem',
      }),
    };
  }, []);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const loadRoles = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IOptions, GroupBase<IOptions>>) => void) => {
      if (inputValue.length < 3) {
        callback([]);
        return;
      }

      fetchOwners({
        emailString: inputValue.trim(),
        nameString: inputValue.trim(),
      }).then((result) => {
        const options = result.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        callback(options);
      });
      return;
    },
    [fetchOwners],
  );

  const debouncedLoadOwnerRoles = useMemo(() => {
    return debounce(loadRoles, 500);
  }, [loadRoles]);

  return (
    <AsyncSelect
      components={components}
      isMulti={false}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={(newValue) => {
        if (newValue === null) {
          onChange(undefined);
          return;
        }
        onChange(newValue);
      }}
      className=" text-sm min-w-[200px] max-w-[200px] truncate text-ellipsis"
      placeholder={placeholder}
      value={value ?? null}
      loadOptions={debouncedLoadOwnerRoles}
      styles={selectStyles}
      classNames={selectClasses}
      menuPortalTarget={document.body}
    />
  );
};

export const RoleSelect = ({
  onFocus,
  onBlur,
  onChange,
  value,
  placeholder,
}: {
  onFocus: () => void;
  onBlur: () => void;
  placeholder: string;
  onChange: (_: IOption | undefined) => void;
  value?: IOption;
}) => {
  const { mutateAsync: fetchOwnerRoles } = useGetOwnerRoleSuggestions({});

  const selectStyles = useMemo(() => {
    return {
      ...getStylesForSelect<false, IOption>(),
      // @ts-expect-error base is untyped
      control: (base) => ({
        ...base,
        border: 'none',
        '&:hover': {
          border: 'none',
        },
        outline: 'none',
        boxShadow: 'none',
        height: '2.25rem',
        padding: 0,
        minHeight: '2.25rem',
      }),
    };
  }, []);

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const loadRoles = useCallback(
    (inputValue: string, callback: (_: OptionsOrGroups<IOptions, GroupBase<IOptions>>) => void) => {
      if (inputValue.length < 3) {
        callback([]);
        return;
      }

      fetchOwnerRoles({
        query: inputValue.trim(),
      }).then((result) => {
        const options = result.data.suggestions.map((item) => ({
          value: item.role,
          label: item.role,
        }));

        callback(options);
      });
      return;
    },
    [fetchOwnerRoles],
  );

  const debouncedLoadOwnerRoles = useMemo(() => {
    return debounce(loadRoles, 500);
  }, [loadRoles]);

  return (
    <AsyncSelect
      components={components}
      isMulti={false}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={(newValue) => {
        if (newValue === null) {
          onChange(undefined);
          return;
        }
        onChange(newValue);
      }}
      className=" text-sm min-w-[200px] max-w-[200px] truncate text-ellipsis"
      placeholder={placeholder}
      value={value ?? null}
      loadOptions={debouncedLoadOwnerRoles}
      styles={selectStyles}
      classNames={selectClasses}
      menuPortalTarget={document.body}
    />
  );
};

const CompositeInput = <OptionTypes,>({
  options,
  onChange,
  placeholderMap,
  value,
  defaultSelected,
  placeholder,
  inputProps,
  selectClassName,
}: {
  options: IOption[];
  onChange: (_: IOption | undefined, __: OptionTypes | undefined) => void;
  placeholderMap: Record<OptionTypes extends string ? OptionTypes : string, string>;
  value?: IOption;
  defaultSelected?: OptionTypes;
  placeholder: string;
  inputProps?: {
    [key: string]: unknown;
  };
  inputClassName?: string;
  selectClassName?: string;
}) => {
  // TODO: add input type
  const [selectedValue, setSelectedValue] = useState<OptionTypes | undefined>(defaultSelected);

  const [focused, setFocused] = useState(false);

  const handleSelect = (value: string) => {
    const newValue = value as OptionTypes;
    setSelectedValue(newValue);
    onChange(undefined, newValue);
  };

  return (
    <div className={cn('flex border rounded-md', focused && 'ring-1 ring-ring')}>
      <SelectComponent
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className={cn(
          ' border-0 max-w-[150px] ring-0 hover:ring-0 focus-within:ring-0 focus-visible:ring-0 focus:ring-0 ',
          selectClassName,
        )}
        value={selectedValue ? (selectedValue as string) : undefined}
        options={options}
        onChange={handleSelect}
      />
      {selectedValue === 'role' ? (
        <RoleSelect
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...inputProps}
          placeholder={
            selectedValue
              ? placeholderMap[selectedValue as OptionTypes extends string ? OptionTypes : string]
              : 'Select type first'
          }
          value={value}
          onChange={(newValues) => onChange(newValues, selectedValue)}
        />
      ) : (
        <OwnerSelect
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...inputProps}
          placeholder={
            selectedValue
              ? placeholderMap[selectedValue as OptionTypes extends string ? OptionTypes : string]
              : 'Select type first'
          }
          value={value}
          onChange={(newValues) => onChange(newValues, selectedValue)}
        />
      )}
    </div>
  );
};

export default CompositeInput;
