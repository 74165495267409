import { ICreditTerms } from '@/types/credit-term.types';

interface ICreditTermTemplate extends ICreditTerms<'PAYMENT_TERM'> {
  templateId: string;
}

const templates: ICreditTermTemplate[] = [
  {
    title: 'Net 30',
    templateId: 'template-1',
    description: 'The customer has 30 days from the invoice date to pay the full amount.',
    type: 'PAYMENT_TERM',
    terms: {
      dueDatePaymentTerm: {
        frequency: 'MONTH',
        number: 1,
      },
    },
    id: '',
    isDefault: false,
    metaData: null,
  },
  {
    title: '2/10, Net 30',
    templateId: 'template-2',
    description:
      'The customer receives a 2% discount if they pay within 10 days of the invoice date. Otherwise, the full amount is due within 30 days.',
    type: 'PAYMENT_TERM',
    terms: {
      dueDatePaymentTerm: {
        frequency: 'MONTH',
        number: 1,
      },
      earlyDiscountPaymentTerm: [
        {
          frequency: 'DAY',
          number: 10,
          discountPercentage: 2,
          discountAmount: 0,
        },
      ],
    },
    id: '',
    isDefault: false,
    metaData: null,
  },
  {
    title: '40% upfront, 60% later',
    description: 'Customer has to pay 40% upfront and remaining within 30 days of invoice date.',
    type: 'PAYMENT_TERM',
    terms: {
      prepaidPaymentTerm: {
        percentage: 40,
      },
      dueDatePaymentTerm: {
        frequency: 'DAY',
        number: 30,
      },
    },
    id: '',
    templateId: 'template-3',
    isDefault: false,
    metaData: null,
  },
];

const emptyTemplate: ICreditTerms<'PAYMENT_TERM'> = {
  title: '',
  description: '',
  type: 'PAYMENT_TERM',
  terms: {},
  id: '',
  isDefault: false,
  metaData: null,
};

export const getAllTemplates = () => {
  return templates;
};

export const getTemplateById = (id: string) => {
  const foundTemplate = templates.find((template) => template.templateId === id);

  if (foundTemplate) {
    return JSON.parse(JSON.stringify(foundTemplate));
  }

  return JSON.parse(JSON.stringify(emptyTemplate));
};
