import { Button } from '@/components/ui/button';
import { useGetCustomerSuggestionsMutation } from '@/hooks/api-hooks/useCustomerQuery';
import { usePostGetEmailTemplatePreview } from '@/hooks/api-hooks/useEmailTemplatesQuery';
import { usePreviewStore } from '@/stores/email-builder/preview-store';
import { IOptions } from '@/types/common.types';
import { Variable } from '@/types/email-templates.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { MultiInvoiceNumberInputWithSuggestions } from '../shared/MultiInvoiceInput';
import SelectInvoiceComponent from '../shared/SelectInvoice';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '../ui/dialog';
import { Input } from '../ui/input';
import { useToast } from '../ui/use-toast';

export interface PreviewVariable {
  modelType: string;
  name: string;
  identifiers: string;
  type: 'OBJECT' | 'ARRAY' | 'STRING' | 'NUMBER' | 'DATE';
}

const mapStringToOptions = (items: string) => {
  return items
    .split(',')
    .map((item) => item.trim())
    .filter(Boolean) as string[];
};

const getFormattedVariableName = (str: string) => {
  return str
    .trim()
    .split(/\.?(?=[A-Z])/)
    .join(' ')
    .toLowerCase();
};

export const PreviewTemplateDialog = ({
  setIsPreviewDialogOpen,
  isPreviewDialogOpen,
  variables,
  templateId,
}: {
  setIsPreviewDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPreviewDialogOpen: boolean;
  variables: Variable[];
  templateId: string;
}) => {
  const [selectedCustomers, setSelectedCustomers] = useState<SingleValue<IOptions>>();
  const [previewVariables, setPreviewVariables] = useState<PreviewVariable[]>([]);
  const loadData = usePreviewStore((store) => store.loadData);
  const { toast } = useToast();

  useEffect(() => {
    setPreviewVariables(
      variables
        .toSorted((a, b) => a.name.localeCompare(b.name))
        .map((item) => ({
          modelType: item.modelType,
          name: item.name,
          identifiers: '',
          type: item.type as PreviewVariable['type'],
        })),
    );
  }, [variables]);

  const handleInputChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setPreviewVariables((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            identifiers: e.target.value,
          };
        }
        return item;
      });
    });
  };

  const handleInvoiceInputChange = (index: number) => (value: string) => {
    setPreviewVariables((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            identifiers: value,
          };
        }
        return item;
      });
    });
  };

  const navigate = useNavigate();

  const { mutate: preview, isPending } = usePostGetEmailTemplatePreview({
    templateId: templateId,
    customConfig: {
      onSuccess(data) {
        loadData({
          body: data.data.body,
          subject: data.data.subject,
          variables: previewVariables.map((item) => ({
            ...item,
            identifiers: item.identifiers.trim(),
          })),
        });
        navigate(`/email-builder/${templateId}/preview`);
      },
      onError: (error) => {
        toast({
          description: error.response?.data.message || 'Unable to preview template',
          variant: 'destructive',
        });
      },
    },
  });

  const handlePreview = () => {
    preview({
      variables: previewVariables.map((item) => ({
        ...item,
        identifiers: item.identifiers.trim(),
      })),
    });
  };

  const { mutateAsync: fetchCustomers } = useGetCustomerSuggestionsMutation({});

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchCustomers({
        query: inputValue.trim(),
      });
      return result.data.suggestions.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    [fetchCustomers],
  );

  const handleCustomerSelectChange = (index: number) => (value: SingleValue<IOptions>) => {
    setSelectedCustomers(value);
    if (value?.value) {
      setPreviewVariables((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              identifiers: value.value,
            };
          }
          return item;
        });
      });
    }
  };

  const handleChange = (index: number) => (value: string[]) => {
    setPreviewVariables((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            identifiers: value.filter(Boolean).join(','),
          };
        }

        return item;
      });
    });
  };

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false | true, IOptions>();
  }, []);

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      setSelectedCustomers(undefined);
    }
    setIsPreviewDialogOpen(value);
  };

  const isDisabled = useMemo(() => {
    return previewVariables.some((item) => !item.identifiers);
  }, [previewVariables]);

  return (
    <Dialog open={isPreviewDialogOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
        <DialogHeader className=" font-semibold ">Fill variables for preview</DialogHeader>
        <div className=" flex flex-col gap-4 ">
          {previewVariables.length === 0 && <div className=" text-sm ">No variables to add</div>}
          {previewVariables.map((variable, index) => (
            <div key={variable.name}>
              <div className=" capitalize text-sm my-1 ">
                {getFormattedVariableName(variable.name)}
                {variable.type === 'ARRAY' && '(Multi)'}
              </div>
              {variable.modelType === 'customer' ? (
                <AsyncSelect
                  isMulti={false}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  onChange={handleCustomerSelectChange(index)}
                  value={selectedCustomers}
                  loadOptions={loadSelectOptions}
                  className=" min-w-[200px] text-sm shadow-sm"
                  styles={selectStyles}
                  placeholder="Search by customer name"
                  classNames={selectClasses}
                />
              ) : (
                <>
                  {variable.type === 'ARRAY' && (
                    <MultiInvoiceNumberInputWithSuggestions
                      className=" min-w-[150px] max-w-full w-full text-sm shadow-sm"
                      onChange={handleChange(index)}
                      value={mapStringToOptions(variable.identifiers)}
                      placeholder="Enter identifiers"
                    />
                  )}
                  {variable.type === 'OBJECT' && (
                    <SelectInvoiceComponent
                      value={variable.identifiers}
                      placeholder="Enter identifiers"
                      className="text-sm"
                      onChange={handleInvoiceInputChange(index)}
                    />
                  )}
                  {variable.type === 'NUMBER' && (
                    <Input
                      type="number"
                      value={variable.identifiers}
                      placeholder={`Enter ${getFormattedVariableName(variable.name)}`}
                      onChange={handleInputChange(index)}
                    />
                  )}
                  {variable.type === 'STRING' && (
                    <Input
                      value={variable.identifiers}
                      placeholder={`Enter ${getFormattedVariableName(variable.name)}`}
                      onChange={handleInputChange(index)}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        <DialogFooter>
          <Button onClick={() => handleOpenChange(false)} variant="outline">
            Cancel
          </Button>
          <Button disabled={isDisabled} loading={isPending} onClick={handlePreview}>
            Preview
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
