import { getBudget, updateBudget } from '@/api-functions/budget.api';
import { IBudgetsForPlanResponse, ICategory } from '@/types/budget.types';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

export const BudgetQueryKeys = {
  getBudgetsByCompany: (planId: string, frequency: 'monthly' | 'quarterly' | 'yearly') => [
    'getBudgetsByCompany',
    planId,
    frequency,
  ],
};

export const useGetBudgetsByCompany = ({
  frequency,
  planId,
  customConfig,
}: {
  frequency: 'monthly' | 'yearly';
  planId: string;
  customConfig?: Omit<UseQueryOptions<IBudgetsForPlanResponse>, 'queryKey'>;
}) => {
  const sortCategories = useCallback((category: ICategory[]): ICategory[] => {
    return category
      .map((category: ICategory) => {
        return {
          ...category,
          order: category.categoryValue.includes('_diffnode') ? 2 : category.categoryValue.trim() === 'Expense' ? 1 : 0,
          children: sortCategories(category.children || []),
        };
      })
      .sort((a, b) => a.order - b.order);
  }, []);

  const getBudgets = useQuery<{
    error: string | null;
    message: string;
    data: {
      planName: string;
      financialYear: number;
      budgetsAndRelations: ICategory[];
    };
  }>({
    queryKey: BudgetQueryKeys.getBudgetsByCompany(planId, frequency),
    queryFn: () => getBudget({ scope: frequency, planId }),
    ...customConfig,
    select(data) {
      return {
        ...data,
        data: {
          ...data.data,
          budgetsAndRelations: sortCategories(data.data.budgetsAndRelations),
        },
      };
    },
  });

  return getBudgets;
};

export const usePostUpdateBudgets = ({
  budgetId,
  customConfig,
}: {
  budgetId: string;
  customConfig?: UseMutationOptions<unknown, unknown, { budgetValue: number }, unknown>;
}) => {
  const postUpdateBudgets = useMutation({
    mutationFn: ({ budgetValue }: { budgetValue: number }) => updateBudget({ budgetId, budgetValue }),
    ...customConfig,
  });

  return postUpdateBudgets;
};
