import { useEffect } from 'react';

const MicrosoftClarityScriptTag = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';

    if (import.meta.env.VITE_ENVIRONMENT === 'prod') {
      script.innerHTML = `
          (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "k0ijegv9to");
        `;
    } else {
      script.innerHTML = `
         (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "kj39wnysdi");
        `;
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default MicrosoftClarityScriptTag;
