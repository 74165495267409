'use client';

import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import { useGetEmailTemplateVariablesQuery, useUpdateEmailTemplate } from '@/hooks/api-hooks/useEmailTemplatesQuery';
import { cn } from '@/lib/utils';
import { useStore } from '@/stores/email-builder/page-sections.store';
import { convertHtmlToText } from '@/utils/convertHtmlToText';
import { convertSectionsToHtml } from '@/utils/email-builder/convertSectionsToHtml';
import { getVariables } from '@/utils/getVariablesFromContent';
import { ArrowLeftIcon, Loader2Icon } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

const EmailBuilderUpdateNav = () => {
  const [isEditDropdownOpen, setIsEditDropdownOpen] = useState(false);
  const { updateTemplateData, templateData, sections, pageSectionsOrder, subjectHtml, isDirty } = useStore();
  const { templateId } = useParams();
  const { mutate: updateEmailTemplate, isPending } = useUpdateEmailTemplate({
    templateId: templateId as string,
    customConfig: {
      onSuccess() {
        toast({
          description: 'Template updated successfully',
        });
      },
      onError(error) {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to update template',
        });
      },
    },
  });
  const { toast } = useToast();

  const { data: templateVariablesResponse } = useGetEmailTemplateVariablesQuery({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    name: string;
    description: string;
  }>({
    values: {
      name: templateData.name,
      description: templateData.description,
    },
  });

  const handleOpenChange = (value: boolean) => {
    reset();
    setIsEditDropdownOpen(value);
  };

  const onSubmit = (data: { name: string; description: string }) => {
    updateTemplateData(data);
    handleOpenChange(false);
  };

  const handlePublish = () => {
    try {
      const { htmlString, variableSet } = convertSectionsToHtml({
        order: pageSectionsOrder,
        sections: sections,
        variableTypeMap: templateVariablesResponse?.data || {
          availableAttributes: {},
          templateFields: {},
        },
      });

      const { variables: finalVariables, replaceRequests } = getVariables({
        content: subjectHtml,
        variables: variableSet,
        variableTypeMap: templateVariablesResponse?.data || {
          availableAttributes: {},
          templateFields: {},
        },
      });

      const subjectHtmlWithReplaces = replaceRequests.reduce((acc, replaceRequest) => {
        return acc.replaceAll(replaceRequest.from, replaceRequest.to);
      }, subjectHtml);

      const subject = convertHtmlToText(subjectHtmlWithReplaces);

      if (!subject) {
        toast({
          variant: 'destructive',
          description: 'Please provide a subject for the template',
        });
        return;
      }

      updateEmailTemplate({
        config: {
          variables: finalVariables,
        },
        contentHtml: htmlString,
        contentJson: {
          sections: sections,
          pageSectionsOrder: pageSectionsOrder,
        },
        description: templateData.description,
        name: templateData.name,
        subjectContentHtml: subject,
        status: 'ACTIVE',
      });
    } catch (error) {
      const err = error as Error;

      toast({
        variant: 'destructive',
        description: err.message || 'Unable to publish template',
      });
    }
  };

  const handleSaveAsDraft = () => {
    try {
      const { htmlString, variableSet } = convertSectionsToHtml({
        order: pageSectionsOrder,
        sections: sections,
        variableTypeMap: templateVariablesResponse?.data || {
          availableAttributes: {},
          templateFields: {},
        },
      });

      const { variables: finalVariables, replaceRequests } = getVariables({
        content: subjectHtml,
        variables: variableSet,
        variableTypeMap: templateVariablesResponse?.data || {
          availableAttributes: {},
          templateFields: {},
        },
      });

      const subjectHtmlWithReplaces = replaceRequests.reduce((acc, replaceRequest) => {
        return acc.replaceAll(replaceRequest.from, replaceRequest.to);
      }, subjectHtml);

      const subject = convertHtmlToText(subjectHtmlWithReplaces);

      if (!subject) {
        toast({
          variant: 'destructive',
          description: 'Please provide a subject for the template',
        });
        return;
      }

      updateEmailTemplate({
        config: {
          variables: finalVariables,
        },
        contentHtml: htmlString,
        contentJson: {
          sections: sections,
          pageSectionsOrder: pageSectionsOrder,
        },
        description: templateData.description,
        name: templateData.name,
        subjectContentHtml: subject,
        status: 'DRAFT',
      });
    } catch (error) {
      const err = error as Error;

      toast({
        variant: 'destructive',
        description: err.message || 'Unable to publish template',
      });
    }
  };

  return (
    <div className="flex justify-between border-b items-center pr-8">
      <div className=" flex px-4 py-2 gap-4  ">
        <Link to="/templates">
          <Button variant="ghost">
            <ArrowLeftIcon className="w-4 h-4 text-xl" />
          </Button>
        </Link>
        <div>
          <DropdownMenu open={isEditDropdownOpen} onOpenChange={handleOpenChange}>
            <DropdownMenuTrigger>
              <div
                className={cn(
                  ' text-left text-sm hover:bg-muted-foreground/10 p-2 rounded-md  hover:ring-muted-foreground hover:ring-1 ',
                  isEditDropdownOpen && 'bg-muted-foreground/10 p-2 rounded-md  ring-muted-foreground ring-1',
                )}
              >
                <div>{templateData.name?.trim() || 'Add name'}</div>
                <p>{templateData.description?.trim() || 'Add description'}</p>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <form className="px-2 py-4 text-sm" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col  ">
                  <div>
                    <p>Template name</p>
                    <Input
                      {...register('name', {
                        required: {
                          value: true,
                          message: 'Name is required',
                        },
                        minLength: {
                          value: 3,
                          message: 'Name must be at least 3 characters',
                        },
                      })}
                      placeholder="Enter name"
                    />
                    <p className=" text-destructive h-6 text-xs ">{errors.name?.message}</p>
                  </div>
                  <div>
                    <p>Description</p>
                    <Textarea {...register('description', {})} placeholder="Enter description" />
                    <p className=" text-destructive h-4 text-xs ">{errors.description?.message}</p>
                  </div>
                </div>
                <div className=" flex items-center justify-end ">
                  <Button type="submit">Save</Button>
                </div>
              </form>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className=" flex items-center gap-4 ">
        <Button variant="outline" disabled={isPending || !isDirty} onClick={handleSaveAsDraft}>
          Save as draft
          {isPending && <Loader2Icon className=" w-4 h-4 animate-spin " />}
        </Button>
        <Button disabled={isPending || !isDirty} onClick={handlePublish}>
          Publish
          {isPending && <Loader2Icon className=" w-4 h-4 animate-spin " />}
        </Button>
      </div>
    </div>
  );
};

export default EmailBuilderUpdateNav;
