import { Plus } from 'lucide-react';
import { useState } from 'react';
import { AddInviteDialog } from './AddInviteDialog';
import { InvitationsTable, MembersTable } from './MemberInvitationTables';

const MemberList = () => {
  const [mode, setMode] = useState('members');
  const [isAddInviteModelOpen, setIsAddInviteModelOpen] = useState(false);
  const handleModeChangeTo = (newMode: string) => {
    setMode(newMode);
  };
  return (
    <div className="w-full h-9/10 md:w-8/10 flex flex-col space-y-4 md:text-sm px-4 pt-4">
      <div className="flex flex-row justify-between space-x-4 w-full px-4 md:text-sm">
        <span className="font-bold">Manage Team Members</span>
        <span
          className="flex items-center cursor-pointer text-blue-700 font-semibold"
          onClick={() => {
            setIsAddInviteModelOpen(true);
          }}
        >
          <Plus size={16} />
          <span className="ml-1">Add new member</span>
        </span>
      </div>
      <div className="h-1/10 w-full border-b-2">
        <div className="h-full flex flex-row space-x-16 px-2">
          <span
            className={`h-full border-primary flex items-center px-2 cursor-pointer 
                  ${mode === 'members' ? 'border-b-4 font-bold text-foreground' : ''}`}
            onClick={() => {
              handleModeChangeTo('members');
            }}
          >
            Members List
          </span>
          <span
            className={`h-full border-primary flex items-center px-2 cursor-pointer 
                  ${mode === 'invites' ? 'border-b-4 font-bold text-foreground' : ''}`}
            onClick={() => {
              handleModeChangeTo('invites');
            }}
          >
            Invitees List
          </span>
        </div>
      </div>
      {mode === 'members' ? <MembersTable /> : <InvitationsTable />}
      <AddInviteDialog setIsAddInviteModelOpen={setIsAddInviteModelOpen} isAddInviteModelOpen={isAddInviteModelOpen} />
    </div>
  );
};

export default MemberList;
