import BankIdToName from '@/components/shared/BankIdToName';
import CategoryIdToLabel from '@/components/shared/CategoryIdToLabel';
import Pagination from '@/components/shared/Pagination';
import { ColumnSelector } from '@/components/shared/ReactTableColumnSelector';
import TableSkeleton from '@/components/shared/TableSkeleton';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useToast } from '@/components/ui/use-toast';
import { useUserContext } from '@/contexts/UserContext';
import { useGetBankAccountsForCompany } from '@/hooks/api-hooks/useBankAccountQuery';
import { useGetCategoriesByCompanyQuery } from '@/hooks/api-hooks/useCategoryQuery';
import { useGetCreditedBankTransactions, useGetCreditsSummary } from '@/hooks/api-hooks/usePostingQuery';
import { useCreditsExport } from '@/hooks/excel-export/useCreditsExport';
import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { cn } from '@/lib/utils';
import { ICredit, ICreditsFilters } from '@/types/posting.types';
import {
  POSTING_ALL_CREDITS_COLUMN_ORDER_STORAGE_KEY,
  POSTING_ALL_CREDITS_COLUMN_VISIBILITY_STORAGE_KEY,
} from '@/utils/constants/local-storage-keys';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Table as ReactTable,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { GripVerticalIcon, InfoIcon, Loader2Icon, StarsIcon } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreditFilters from './CreditsFilters';
import CustomerSuggestions from './CustomerSuggestionDialog';
import { MarkCreditAsReversedDialog, MarkCreditAsUnReversedDialog } from './MarkCreditReversed';

const CreditsTable = ({
  table,
  credits,
  isLoading,
}: {
  table: ReactTable<ICredit>;
  credits: ICredit[];
  isLoading: boolean;
}) => {
  const navigate = useNavigate();

  const handleNavigate = (transactionId: string) => {
    const params = new URLSearchParams({
      paymentStatus: `PARTIALLY_PAID:Partially Paid,UNPAID:Unpaid`,
    });
    navigate(`/posting/credit/${transactionId}?${params.toString()}`);
  };

  const sizes = table.getState().columnSizingInfo;

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    return headers.reduce(
      (acc, current) => {
        return {
          ...acc,
          [`--col-${current.column.id}-size`]: current.column.getSize(),
          [`--header-${current.id}-size`]: current.getSize(),
        };
      },
      {} as {
        [key: string]: number;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);

  if (isLoading) {
    return <TableSkeleton rows={10} columns={10} />;
  }

  return (
    <div className=" overflow-auto ">
      <Table
        style={{
          ...columnSizeVars,
          width: table.getTotalSize(),
        }}
      >
        {credits.length === 0 && <TableCaption className="pb-10">No data found</TableCaption>}
        <TableHeader className=" bg-gray-100">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className="flex items-center" key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    style={{
                      width: `calc(var(--header-${header?.id}-size) * 1.4px)`,
                    }}
                    className="flex items-center justify-between"
                    key={header.id}
                  >
                    <div className="break-keep truncate text-ellipsis">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                    <div className="flex gap-2">
                      {!['view'].includes(header.id) && (
                        <GripVerticalIcon
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: cn(
                              'w-4 h-4 cursor-ew-resize',
                              header.column.getIsResizing() ? 'opacity-100' : 'opacity-50',
                            ),
                          }}
                        />
                      )}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="py-4">
          {table.getRowModel().rows.map((row) => (
            <TableRow className="flex cursor-pointer" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  style={{
                    width: `calc(var(--col-${cell.column.id}-size) * 1.4px)`,
                  }}
                  className="truncate whitespace-nowrap overflow-ellipsis"
                  key={cell.id}
                  onClick={
                    ['select', 'customerId'].includes(cell.column.id)
                      ? undefined
                      : () => handleNavigate(row.original.id)
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const CreditDetails = ({ filters, page, limit }: { filters: ICreditsFilters; page: number; limit: number }) => {
  const { data: creditSummary, isLoading } = useGetCreditsSummary({
    ...filters,
    page: page,
    limit: limit,
    customConfig: {},
  });
  if (isLoading) {
    return (
      <>
        <h2 className="">
          <div className="flex gap-2 ">
            <span className=" text-lg font-semibold  ">Credits</span>
            <Skeleton className=" h-4 w-[400px] mt-2" />
          </div>
        </h2>
        <p className=" text-sm text-muted-foreground mb-2 ">Select credits to match</p>
      </>
    );
  }

  return (
    <>
      <h2 className=" text-lg font-semibold   ">
        Credits{' '}
        <span className=" text-sm font-normal ">
          (Count: {creditSummary?.data.count}, Available For Posting:{' '}
          {formatCurrencyByUnit(creditSummary?.data.totalRemaining || 0, 'actual')}, Total:{' '}
          {formatCurrencyByUnit(creditSummary?.data.totalValue || 0, 'actual')})
        </span>
      </h2>
      <p className=" text-sm text-muted-foreground mb-2 ">Select credits to match</p>
    </>
  );
};

const CreditsTableWrapper = () => {
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { companiesOfUser, activeCompanyIndex } = useUserContext();
  const [filters, setFilters] = useState<ICreditsFilters>({
    bankAccountIds: [],
    postingStatus: ['PARTIALLY_POSTED', 'UNPOSTED'],
    categories: [],
  });
  const { data: creditsData, isLoading } = useGetCreditedBankTransactions({
    ...filters,
    page: page,
    limit: pageLimit,
    companyId: companiesOfUser[activeCompanyIndex]?.id || '',
    customConfig: {},
  });

  const sheetRef = useRef<HTMLDivElement>(null);
  const columns = useMemo<ColumnDef<ICredit>[]>(() => {
    return [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || table.getIsSomePageRowsSelected()}
            className={cn(table.getIsSomePageRowsSelected() && 'opacity-50')}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
        minSize: 50,
        size: 50,
        showGrips: false,
      },
      {
        header: 'Transaction Date',
        id: 'transactionDate',
        accessorKey: 'transactionDate',
        cell: ({ getValue }) => dayjs(getValue() as string).format('DD MMM YYYY'),
        size: 180,
      },
      {
        header: 'Bank',
        id: 'bank',
        accessorKey: 'bankAccountId',
        cell: ({ getValue }) => <BankIdToName bankId={getValue() as string} />,
      },

      {
        header: 'Total Credit',
        id: 'totalCredit',
        accessorKey: 'creditAmount',
        size: 130,
        cell: ({ getValue }) => formatCurrencyByUnit(getValue() as number, 'actual'),
      },
      {
        header: () => (
          <TooltipProvider>
            <Tooltip>
              <div className=" flex items-center gap-2 text-signature ">
                <StarsIcon fill="#9E3DFB" className="min-w-4 w-4 h-4 text-signature" />
                AI Customer Suggestion
                <TooltipTrigger>
                  <InfoIcon className=" w-4 h-4 " />
                </TooltipTrigger>
                <TooltipContent>AI responses can be inaccurate.</TooltipContent>
              </div>
            </Tooltip>
          </TooltipProvider>
        ),
        id: 'customerId',
        accessorKey: 'customers',
        label: 'Customer',
        cell: ({ row }) => {
          const alreadyVisitedCustomer = {} as Record<string, boolean>;
          const postedCustomerIds = row.original.customers.map((customer) => customer.id);

          const uniqueCustomerSuggestions = row.original.customerSuggestions
            .map((customer) => {
              if (!alreadyVisitedCustomer[customer.name]) {
                alreadyVisitedCustomer[customer.name] = true;
                return customer;
              }
              return null;
            })
            .filter(Boolean) as ICredit['customerSuggestions'];

          return (
            <CustomerSuggestions
              bankTransactionId={row.original.id}
              customerSuggestions={uniqueCustomerSuggestions}
              postedCustomerIds={postedCustomerIds}
            />
          );
        },
      },
      {
        header: 'Posted Credit',
        id: 'postedCredit',
        accessorKey: 'postedAmount',
        size: 130,
        cell: ({ getValue }) => formatCurrencyByUnit(getValue() as number, 'actual'),
      },
      {
        header: 'Available For Posting',
        id: 'availableCredit',
        accessorKey: 'remainingAmount',
        size: 200,
        cell: ({ getValue }) => formatCurrencyByUnit(getValue() as number, 'actual'),
      },
      {
        header: 'Posting Status',
        id: 'postingStatus',
        accessorKey: 'postingStatus',
        cell: ({ getValue, row }) =>
          row.original.isReversed ? (
            <Badge>Reversed</Badge>
          ) : (
            <Badge
              className={cn(
                getValue() === 'POSTED' && 'bg-green-500 hover:bg-green-500 hover:text-white text-white',
                getValue() === 'UNPOSTED' && 'bg-red-500 text-white hover:bg-red-500 hover:text-white',
                getValue() === 'PARTIALLY_POSTED' && 'bg-yellow-400 text-black hover:bg-yellow-400',
                'capitalize',
              )}
            >
              {(getValue() as string).split('_').join(' ').toLowerCase()}
            </Badge>
          ),
        size: 130,
      },
      {
        header: 'Category',
        id: 'category',
        accessorKey: 'category',
        cell: ({ getValue }) => <CategoryIdToLabel categoryId={getValue() as string} />,
      },
      {
        header: 'Narration',
        id: 'narration',
        accessorKey: 'narration',
        cell: ({ getValue }) => <ToolTipCell value={getValue() as string} />,
      },
    ];
  }, []);

  const credits = useMemo(() => {
    return creditsData?.data.docs || [];
  }, [creditsData]);

  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);
  const defaultColumnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const { storedValue: columnVisibility, setValue: setColumnVisibility } = useLocalStorage(
    POSTING_ALL_CREDITS_COLUMN_VISIBILITY_STORAGE_KEY,
    defaultColumnVisibility,
    (storedValue, initialValue) => {
      return Object.keys(storedValue).length !== Object.keys(initialValue).length;
    },
  );

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    POSTING_ALL_CREDITS_COLUMN_ORDER_STORAGE_KEY,
    defaultColumnOrder,
    (storedValue, initialValue) => {
      return storedValue.length !== initialValue.length;
    },
  );

  const table = useReactTable({
    columns,
    data: credits,
    columnResizeMode: 'onChange',
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
      columnOrder,
    },
  });

  const tableColumns = useMemo(() => {
    return table.getAllColumns();
  }, [table]);

  const { data: bankAccountsResponse } = useGetBankAccountsForCompany({
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const { data: categoriesResponse } = useGetCategoriesByCompanyQuery({
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const banksMap = useMemo(() => {
    return bankAccountsResponse?.data.bankAccounts.reduce(
      (acc, bank) => {
        acc[bank.id] = bank.name;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [bankAccountsResponse]);

  const categoriesMap = useMemo(() => {
    return categoriesResponse?.data.reduce(
      (acc, category) => {
        acc[category.id] = category.value;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [categoriesResponse]);

  const { toast } = useToast();

  const { exportExcel, isLoading: isExcelExportLoading } = useCreditsExport({
    onError() {
      toast({
        title: 'Error exporting credits',
        description: 'Please try again',
        variant: 'destructive',
      });
    },
    onSuccess: (url) => {
      const startingNumber = (page - 1) * pageLimit + 1;
      const endingNumber = credits.length < 2 ? '' : (page - 1) * pageLimit + credits.length;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `credits_available_for_posting_${startingNumber}_${endingNumber}_${dayjs().format('DDMMYYYYHHMM')}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      toast({
        description: 'Credits exported',
      });
    },
  });

  const handleExport = () => {
    exportExcel(
      credits.map((item) => ({
        ...item,
        category: categoriesMap?.[item.category] || item.category,
        bankAccount: banksMap?.[item.bankAccountId] || item.bankAccountId,
      })),
    );
  };

  const handleClearSelection = useCallback(() => {
    table.resetRowSelection();
  }, [table]);

  const handleSubmit = useCallback(
    (filters: ICreditsFilters) => {
      handleClearSelection();
      setFilters(filters);
    },
    [handleClearSelection],
  );

  const isSomeRowSelected = table.getIsSomeRowsSelected();
  const isAllRowSelected = table.getIsAllRowsSelected();
  const selectedRows = table.getSelectedRowModel().flatRows;

  const selectedCreditIds = useMemo(() => {
    return selectedRows.map((row) => row.original.id);
  }, [selectedRows]);

  const showExcludeModal = useMemo(() => {
    return (
      (isSomeRowSelected || isAllRowSelected) &&
      selectedRows.every((row) => {
        return !row.original.isReversed;
      })
    );
  }, [selectedRows, isSomeRowSelected, isAllRowSelected]);

  const showIncludeModal = useMemo(() => {
    return (
      (isSomeRowSelected || isAllRowSelected) &&
      selectedRows.every((row) => {
        return row.original.isReversed;
      })
    );
  }, [selectedRows, isSomeRowSelected, isAllRowSelected]);

  return (
    <>
      <div className="">
        <div className=" flex-1 flex items-center justify-between ">
          <div>
            <CreditDetails filters={filters} page={page} limit={pageLimit} />
          </div>
          <div className=" flex gap-2 ">
            {showExcludeModal && (
              <MarkCreditAsReversedDialog creditIds={selectedCreditIds} clearAll={handleClearSelection} />
            )}
            {showIncludeModal && (
              <MarkCreditAsUnReversedDialog creditIds={selectedCreditIds} clearAll={handleClearSelection} />
            )}
            <ColumnSelector columnOrder={columnOrder} setColumnOrder={setColumnOrder} columns={tableColumns} />
            {!!credits.length && (
              <Button disabled={isExcelExportLoading} onClick={handleExport} className=" flex items-center gap-4 ">
                Export
                {isExcelExportLoading && <Loader2Icon className="animate-spin w-4 h-4" />}
              </Button>
            )}
          </div>
        </div>
        <div className="mt-4">
          <CreditFilters listType="all" filters={filters} handleSubmit={handleSubmit} />
        </div>
      </div>
      <div className=" flex items-center justify-end mb-4 "></div>
      <div className=" flex-1 flex items-start " ref={sheetRef}>
        <CreditsTable credits={credits} isLoading={isLoading} table={table} />
      </div>
      <Pagination
        hasNext={!!creditsData?.data.hasNext}
        hasPrev={!!creditsData?.data.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setPageLimit}
        pageNumber={page}
        rowsPerPage={pageLimit}
        totalPages={creditsData?.data.totalPages || 0}
      />
    </>
  );
};

export default CreditsTableWrapper;
