'use client';

import * as React from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useUserContext } from '@/contexts/UserContext';
import { useGetCategoryRelationShipsForCompany } from '@/hooks/api-hooks/useCategoryQuery';
import { cn } from '@/lib/utils';
import { ICategoryRelationship } from '@/types/category.types';
import { ChevronDown } from 'lucide-react';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import { Skeleton } from '../ui/skeleton';

interface INode {
  id: string;
  name: string;
  children: INode[];
  path: number[];
}

const flattenCategories = (relationShips: ICategoryRelationship, path: number[]): INode[] => {
  return Object.entries(relationShips).map(([key, value], index) => {
    return {
      id: key,
      name: value.categoryValue,
      selected: false,
      path: [...path, index],
      children: value.children ? flattenCategories(value.children, [...path, index]) : [],
    };
  });
};

const getNodeById = (id: string, tree: INode[]): INode | null => {
  const result = tree
    .map((node) => {
      if (node.id === id) {
        return node;
      } else {
        if (node.children.length > 0) {
          return getNodeById(id, node.children);
        }
        return null;
      }
    })
    .filter(Boolean);

  if (result.length > 0) {
    return result[0];
  }

  return null;
};

const getAllChildren = (node: INode) => {
  if (!node.children) {
    return [];
  }
  const children = [...node.children];
  node.children.forEach((child) => {
    children.push(...getAllChildren(child));
  });
  return children.filter((child) => !child.name.includes('_diffnode'));
};

const getAllAncestors = (path: number[], tree: INode[]) => {
  const ancestors: INode[] = [];
  let traversingTree = tree;
  path.forEach((index) => {
    ancestors.push(traversingTree[index]);
    traversingTree = traversingTree[index].children;
  });

  return ancestors;
};

const isAnyChildrenSelected = (node: INode, selectedCategories: string[]) => {
  if (!node) {
    return false;
  }
  const children = getAllChildren(node);
  return children.some((child) => selectedCategories.includes(child.id));
};

const isAllChildrenSelected = (node: INode, selectedCategories: string[]) => {
  if (!node) {
    return false;
  }
  const children = getAllChildren(node);
  return children.every((child) => selectedCategories.includes(child.id));
};

const calculateStateForAncestorsBasedOnSelectedCategories = ({
  node,
  selectedCategories,
  partiallySelected,
  tree,
}: {
  node: INode;
  selectedCategories: string[];
  partiallySelected: string[];
  tree: INode[];
}) => {
  const newSelectedCategories = [...new Set(selectedCategories)];
  const newPartiallySelected = [...new Set(partiallySelected)];

  const ancestors = getAllAncestors(node.path, tree).filter((ancestor) => ancestor.id !== node.id);

  // for all ancestors do this
  ancestors.reverse().forEach((ancestor) => {
    const isAnyChildrenSelectedForAncestor = isAnyChildrenSelected(ancestor, newSelectedCategories);
    const isAllChildrenSelectedForAncestor = isAllChildrenSelected(ancestor, newSelectedCategories);

    // if any one of its children is selected and not all are selected
    // it means it is partially selected and not itself selected
    if (isAnyChildrenSelectedForAncestor && !isAllChildrenSelectedForAncestor) {
      newPartiallySelected.push(ancestor.id);
      const ancestorIndexInSelected = newSelectedCategories.findIndex((category) => category === ancestor.id);
      if (ancestorIndexInSelected !== -1) {
        newSelectedCategories.splice(ancestorIndexInSelected, 1);
      }
    }

    //  if all of its children are selected means it itself is selected
    // and hence not partially selected
    if (isAllChildrenSelectedForAncestor) {
      newSelectedCategories.push(ancestor.id);
      const ancestorIndexInPartiallySelected = newPartiallySelected.findIndex((category) => category === ancestor.id);
      if (ancestorIndexInPartiallySelected !== -1) {
        newPartiallySelected.splice(ancestorIndexInPartiallySelected, 1);
      }
    }

    // if nothing is selected remove it from all lists
    if (!isAnyChildrenSelectedForAncestor && !isAllChildrenSelectedForAncestor) {
      const ancestorIndexInSelected = newSelectedCategories.findIndex((category) => category === ancestor.id);
      if (ancestorIndexInSelected !== -1) {
        newSelectedCategories.splice(ancestorIndexInSelected, 1);
      }
      const ancestorIndexInPartiallySelected = newPartiallySelected.findIndex((category) => category === ancestor.id);
      if (ancestorIndexInPartiallySelected !== -1) {
        newPartiallySelected.splice(ancestorIndexInPartiallySelected, 1);
      }
    }
  });

  return {
    newSelectedCategories: [...new Set(newSelectedCategories)],
    newPartiallySelected: [...new Set(newPartiallySelected)],
  };
};

const DropdownMenuCheckboxes = ({
  node,
  handleChange,
  selectedCategories,
  partiallySelected,
}: {
  node: INode;
  handleChange: (_: INode) => () => void;
  selectedCategories: string[];
  partiallySelected: string[];
}) => {
  if (node.name.includes('_diffnode')) {
    return null;
  }

  return (
    <>
      <DropdownMenuItem className="flex items-center px-4 gap-2" onClick={handleChange(node)}>
        <Checkbox
          className={cn(partiallySelected.includes(node.id) && !selectedCategories.includes(node.id) && ' opacity-50 ')}
          checked={selectedCategories.includes(node.id) || partiallySelected.includes(node.id)}
        />
        {node.name}
      </DropdownMenuItem>
      <div className="relative left-4 px-2">
        {node.children.map((child) => (
          <DropdownMenuCheckboxes
            partiallySelected={partiallySelected}
            selectedCategories={selectedCategories}
            handleChange={handleChange}
            key={child.id}
            node={child}
          />
        ))}
      </div>
    </>
  );
};

const SelectCategoryDropdown = ({
  onChange,
  selectedCategories,
  defaultSelectedCategories,
  categoriesMap,
  className,
  containerClassName,
}: {
  onChange: (_: string[]) => void;
  selectedCategories: string[];
  defaultSelectedCategories: string[];
  categoriesMap: Record<string, string> | undefined;
  className?: string;
  containerClassName?: string;
}) => {
  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const [categories, setCategories] = React.useState<INode[]>([]);
  const [partiallySelected, setPartiallySelected] = React.useState<string[]>([]);
  const { data: categoryRelations, isLoading } = useGetCategoryRelationShipsForCompany({
    customConfig: {
      enabled: !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (categoryRelations) {
      setCategories(flattenCategories(categoryRelations.data.relationships, []));
    }
  }, [categoryRelations]);

  React.useEffect(() => {
    if (selectedCategories.length === 0) {
      setPartiallySelected([]);
    }
  }, [selectedCategories, categories]);

  React.useEffect(() => {
    if (defaultSelectedCategories.length && categories.length) {
      let initiallySelectedCategories: string[] = [...defaultSelectedCategories];
      let initiallYPartiallySelected: string[] = [];
      defaultSelectedCategories.forEach((category) => {
        const node = getNodeById(category, categories);
        if (node) {
          const children = getAllChildren(node).map((node) => node.id);
          initiallySelectedCategories = initiallySelectedCategories.concat(children);
          const { newSelectedCategories, newPartiallySelected } = calculateStateForAncestorsBasedOnSelectedCategories({
            node,
            partiallySelected: initiallYPartiallySelected,
            selectedCategories: initiallySelectedCategories,
            tree: categories,
          });
          initiallYPartiallySelected = initiallYPartiallySelected.concat(newPartiallySelected);
          initiallySelectedCategories = initiallySelectedCategories.concat(newSelectedCategories);
        }
      });
      setPartiallySelected([...new Set(initiallYPartiallySelected)]);
      onChange([...new Set(initiallySelectedCategories)]);
    }
  }, [onChange, categories, defaultSelectedCategories]);

  const handleChange = React.useCallback(
    (node: INode) => () => {
      // check if category is already selected
      const foundCategory = selectedCategories.find((category) => category === node.id);
      if (foundCategory) {
        // unselect category and all its children
        const children = getAllChildren(node).map((node) => node.id);
        const newSelectedCategories = [...selectedCategories].filter(
          (category) => !children.includes(category) && category !== node.id,
        );

        const { newSelectedCategories: finalSelectedCategories, newPartiallySelected } =
          calculateStateForAncestorsBasedOnSelectedCategories({
            node,
            partiallySelected,
            selectedCategories: newSelectedCategories,
            tree: categories,
          });

        setPartiallySelected(newPartiallySelected);
        onChange(finalSelectedCategories);

        return;
      }

      // if category is partially selected
      const foundInPartial = partiallySelected.find((category) => category === node.id);
      if (foundInPartial) {
        setPartiallySelected((prev) => prev.filter((category) => category !== node.id));
        const newSelectedCategories = [
          ...new Set([...selectedCategories, node.id, ...getAllChildren(node).map((node) => node.id)]),
        ];

        const { newSelectedCategories: finalSelectedCategories, newPartiallySelected } =
          calculateStateForAncestorsBasedOnSelectedCategories({
            node,
            partiallySelected,
            selectedCategories: newSelectedCategories,
            tree: categories,
          });

        setPartiallySelected(newPartiallySelected);
        onChange(finalSelectedCategories);
        return;
      }

      // if category not already selected

      // select category and all its children
      const newSelectedCategories = [
        ...new Set([...selectedCategories, node.id, ...getAllChildren(node).map((node) => node.id)]),
      ];

      const { newSelectedCategories: finalSelectedCategories, newPartiallySelected } =
        calculateStateForAncestorsBasedOnSelectedCategories({
          node,
          partiallySelected,
          selectedCategories: newSelectedCategories,
          tree: categories,
        });

      setPartiallySelected(newPartiallySelected);
      onChange(finalSelectedCategories);
    },
    [selectedCategories, onChange, categories, partiallySelected],
  );

  return isLoading ? (
    <Skeleton className="w-[200px] h-9" />
  ) : (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild>
        <Button
          onClick={() => setOpen((prev) => !prev)}
          variant={'outline'}
          className={cn(
            'text-muted-foreground text-left',
            selectedCategories.length > 0 && 'text-primary',
            containerClassName,
          )}
        >
          <span className={cn('flex items-center gap-2 w-[200px]', className)}>
            <span className="flex-1 truncate text-ellipsis">
              {selectedCategories.length === 0 ? (
                <>Select Categories</>
              ) : (
                selectedCategories.map((category) => (categoriesMap ? categoriesMap[category] : category)).join(', ')
              )}
            </span>
            <ChevronDown className="h-4 w-4" />
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="py-2 h-[400px] overflow-y-scroll overflow-x-hidden"
        onInteractOutside={() => setOpen(false)}
        onEscapeKeyDown={() => setOpen(false)}
        onFocusOutside={() => setOpen(false)}
        onPointerDownOutside={() => setOpen(false)}
      >
        {categories.map((node) => (
          <DropdownMenuCheckboxes
            selectedCategories={selectedCategories}
            partiallySelected={partiallySelected}
            handleChange={handleChange}
            key={node.id}
            node={node}
          />
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default SelectCategoryDropdown;
