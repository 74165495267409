import { useAuthToken } from '@/hooks/api-hooks/useAuth';
import { Company } from '@/types/company.types';
import { createContext, useContext, useEffect, useState } from 'react';

// Create a Context object using createContext
export const UserContext = createContext<{
  appContext: { apiUrl: string };
  companiesOfUser: Company[];
  setCompaniesOfUser: React.Dispatch<React.SetStateAction<Company[]>>;
  activeCompanyIndex: number;
  setactiveCompanyIndex: React.Dispatch<React.SetStateAction<number>>;
}>({
  appContext: { apiUrl: 'https://data.valyx.com' },
  companiesOfUser: [],
  setCompaniesOfUser: () => { },
  activeCompanyIndex: 0,
  setactiveCompanyIndex: () => { },
});

export default function ContextProvider({ children }: { children: React.ReactNode }) {
  const appContext = { apiUrl: 'https://data.valyx.com' };
  const [companiesOfUser, setCompaniesOfUser] = useState<Company[]>([]);
  const { isError, isLoading, data } = useAuthToken();
  const [activeCompanyIndex, setactiveCompanyIndex] = useState(0); //from here we are picking first company from the list of companies
  useEffect(() => { //TODO: get rid of this useEffect 
    if (!isError && !isLoading) {
      const currentUserCompanyId = data?.data.companyId;
      const userCompanyIndex = companiesOfUser.findIndex(x => x.id === currentUserCompanyId);
      setactiveCompanyIndex(userCompanyIndex);
    }
  }, [companiesOfUser])

  return (
    <UserContext.Provider
      value={{ appContext, companiesOfUser, setCompaniesOfUser, activeCompanyIndex, setactiveCompanyIndex }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => {
  return useContext(UserContext);
};
