import {
  createSegment,
  deleteSegmentById,
  evaluateCustomerSegment,
  getCustomerCountForSegments,
  getCustomersForSegment,
  getSegmentById,
  getSegments,
  updateSegment,
} from '@/api-functions/customer-segments.api';

import { IApiResponse } from '@/types/common.types';
import { ICustomer, ICustomerFilters } from '@/types/customer.types';
import { IGetCustomerCountForSegments, IGetSegmentById, IGetSegments, ISegment } from '@/types/segment.types';

import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const SegmentsQueryKeys = {
  getSegments: () => ['getSegments'],
  getSegmentById: (segmentId: string) => ['getSegmentById', segmentId],
  getCustomerCountForSegments: () => ['getCustomerCountForSegmentsQuery'],
};

export const useGetSegments = ({
  customConfig,
}: {
  customConfig?: Omit<UseQueryOptions<IGetSegments>, 'queryKey'>;
}) => {
  const getSegmentsQuery = useQuery<IGetSegments>({
    queryKey: SegmentsQueryKeys.getSegments(),
    queryFn: () => getSegments(),
    ...customConfig,
  });

  return getSegmentsQuery;
};

export const usePostCreateSegment = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<IApiResponse<string>>,
    { filters: ICustomerFilters; name: string; description: string }
  >;
}) => {
  const queryClient = useQueryClient();
  const postCreateSegment = useMutation({
    mutationFn: ({ filters, name, description }: { filters: ICustomerFilters; name: string; description: string }) =>
      createSegment(
        {
          ...filters,
        },
        name,
        description,
      ),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: SegmentsQueryKeys.getSegments(),
      });
      queryClient.refetchQueries({
        queryKey: SegmentsQueryKeys.getCustomerCountForSegments(),
      });
    },
  });

  return postCreateSegment;
};

export const useGetSegmentById = ({
  segmentId,
  customConfig,
}: {
  segmentId: string;
  customConfig?: Omit<UseQueryOptions<IGetSegmentById>, 'queryKey'>;
}) => {
  const getActiveSegmentByCompanyIdQuery = useQuery<IGetSegmentById>({
    queryKey: SegmentsQueryKeys.getSegmentById(segmentId),
    queryFn: () => getSegmentById(segmentId),
    ...customConfig,
  });

  return getActiveSegmentByCompanyIdQuery;
};

export const useGetCustomerBySegmentId = ({
  segmentId,
  customConfig,
}: {
  segmentId: string;
  customConfig?: Omit<
    UseQueryOptions<
      IApiResponse<{
        customers: ICustomer[];
      }>
    >,
    'queryKey'
  >;
}) => {
  const getCustomerBySegmentIdQuery = useQuery({
    queryKey: ['getCustomerBySegmentId', segmentId],
    queryFn: () => getCustomersForSegment(segmentId),
    ...customConfig,
  });

  return getCustomerBySegmentIdQuery;
};

export const useDeleteSegmentById = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<string>,
    AxiosError<IApiResponse<string>>,
    {
      segmentId: string;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const deleteSegmentByIdMutation = useMutation({
    mutationFn: ({ segmentId }: { segmentId: string }) => deleteSegmentById({ segmentId }),
    ...customConfig,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: SegmentsQueryKeys.getSegments(),
      });
    },
  });

  return deleteSegmentByIdMutation;
};

export const useGetCustomerCountForSegments = () => {
  const getCustomerCountForSegmentsQuery = useQuery<IGetCustomerCountForSegments>({
    queryKey: SegmentsQueryKeys.getCustomerCountForSegments(),
    queryFn: () => getCustomerCountForSegments(),
    staleTime: Infinity,
  });

  return getCustomerCountForSegmentsQuery;
};

export const usePostUpdateSegmentMutation = ({
  segmentId,
  customConfig,
}: {
  segmentId: string;
  customConfig?: UseMutationOptions<
    IApiResponse<null>,
    AxiosError<IApiResponse<null>>,
    {
      fields: (keyof ISegment)[];
      values: Partial<ISegment>;
    }
  >;
}) => {
  const queryClient = useQueryClient();
  const postUpdateSegmentMutation = useMutation({
    mutationFn: ({ fields, values }) =>
      updateSegment({
        segmentId,
        fields,
        values,
      }),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: SegmentsQueryKeys.getSegments(),
      });
      queryClient.invalidateQueries({
        queryKey: SegmentsQueryKeys.getSegmentById(segmentId),
      });
      queryClient.invalidateQueries({
        queryKey: SegmentsQueryKeys.getCustomerCountForSegments(),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });

  return postUpdateSegmentMutation;
};

export const useEvaluateSegmentQuery = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<IApiResponse<string>>,
    { segmentId: string }
  >;
}) => {
  const queryClient = useQueryClient();
  const evaluateSegmentMutation = useMutation<
    IApiResponse<{
      id: string;
    }>,
    AxiosError<IApiResponse<string>>,
    { segmentId: string }
  >({
    mutationFn: ({ segmentId }: { segmentId: string }) => evaluateCustomerSegment(segmentId),
    ...customConfig,
    onSuccess(data, variables, context) {
      customConfig?.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({
        queryKey: ['getSegments'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getCustomerCountForSegmentsQuery'],
      });
    },
  });

  return evaluateSegmentMutation;
};
