import React from 'react';

const TopDetailViewItem = ({ label, value }: { label: React.ReactNode; value: React.ReactNode }) => (
  <div className=" min-w-[150px] ">
    <div className=" font-semibold text-sm flex items-center gap-2 ">{label}:</div>
    <div className=" text-sm mt-[4px]">{value}</div>
  </div>
);

export default TopDetailViewItem;
