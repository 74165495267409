import MinimumGuaranteeTerm from '@/components/credit-terms/minimum-gurantee-term/MinGuranteeTermComponent';
import CreateMultipleMinimumGuaranteeTerm from '@/components/credit-terms/minimum-gurantee-term/MinGuranteeTermCreateComponent';
import PaymentTerms from '@/components/credit-terms/payment-term/CreatePaymentTerm';
import PaymentTerm from '@/components/credit-terms/payment-term/PaymentTermComponent';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import Empty from '@/components/ui/empty';
import { Skeleton } from '@/components/ui/skeleton';
import { useGetCreditTermsById } from '@/hooks/api-hooks/useCreditTermsQuery';
import { ICreditTerms } from '@/types/credit-term.types';

import { useNavigate, useParams } from 'react-router-dom';

const CreditTerm = ({ creditTermId }: { creditTermId: string }) => {
  const { data: creditTermDetails, isLoading } = useGetCreditTermsById({
    creditTermId,
    customConfig: {
      enabled: !!creditTermId && creditTermId !== 'add_payment_term' && creditTermId !== 'add_minimum_guarantee_term',
    },
  });

  if (creditTermId === 'add_payment_term') {
    return <PaymentTerms />;
  }

  if (creditTermId === 'add_minimum_guarantee_term') {
    return <CreateMultipleMinimumGuaranteeTerm />;
  }

  if (isLoading) {
    return (
      <div>
        <Card>
          <CardHeader>
            <Skeleton className="h-6 w-full" />
          </CardHeader>
          <CardContent className=" flex flex-col gap-1 ">
            <Skeleton className="h-6 w-full" />
            <Skeleton className="h-6 w-full" />
            <Skeleton className="h-6 w-full" />
            <Skeleton className="h-32 w-full mt-4" />
          </CardContent>
        </Card>
      </div>
    );
  }

  if (!creditTermDetails?.data.id) {
    return <Empty title="No credit term available" />;
  }

  if (creditTermDetails?.data.type === 'PAYMENT_TERM') {
    return (
      <PaymentTerm
        creditTermId={creditTermId}
        creditTermDetails={creditTermDetails.data as ICreditTerms<'PAYMENT_TERM'>}
      />
    );
  }

  return (
    <MinimumGuaranteeTerm
      creditTermId={creditTermId}
      creditTermDetails={creditTermDetails.data as ICreditTerms<'MIN_GUARANTEE_TERM'>}
    />
  );
};

const ViewCreditTerm = () => {
  const { creditTermId } = useParams();
  const navigate = useNavigate();
  if (creditTermId === undefined) {
    navigate('/404', { replace: true });
  }

  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8">
      <CreditTerm creditTermId={creditTermId as string} />
    </div>
  );
};

export default ViewCreditTerm;
