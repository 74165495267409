export const EmailSentIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2498 16.2418C10.0272 16.2578 8.80925 16.2503 7.5822 16.2195C4.95845 16.1536 3.64657 16.1207 2.70397 15.1745C1.76136 14.2282 1.73412 12.951 1.67965 10.3963C1.66213 9.57491 1.66212 8.75841 1.67964 7.93698C1.73412 5.38237 1.76135 4.10506 2.70396 3.15887C3.64657 2.21267 4.95845 2.17971 7.58219 2.1138C9.19925 2.07317 10.8004 2.07317 12.4175 2.1138C15.0413 2.17973 16.3531 2.21269 17.2958 3.15888C18.2383 4.10507 18.2656 5.38238 18.32 7.93699C18.3288 8.34766 18.3332 8.75716 18.3332 9.16666"
        stroke="#141B34"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6665 4.16667L7.42735 7.43719C9.53209 8.63209 10.4676 8.63209 12.5723 7.43719L18.3332 4.16667"
        stroke="#141B34"
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M13.3335 13.3333C13.7431 12.9119 14.8333 11.25 15.4168 11.25M15.4168 11.25C16.0003 11.25 17.0906 12.9119 17.5002 13.3333M15.4168 11.25V17.9167"
        stroke="#141B34"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
