import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { Skeleton } from '@/components/ui/skeleton';
import { toast } from '@/components/ui/use-toast';
import WorkflowLayout from '@/components/workflow/Layout';
import EmailProperties from '@/components/workflow/properties-panel/actions/EmailProperties';

import { ActionSlot } from '@/components/workflow/state-blocks/action-blocks/action-blocks';
import { ExitCriteriaConditions } from '@/components/workflow/state-blocks/EditConditions';
import Tabs from '@/components/workflow/Tabs';
import { useUserContext } from '@/contexts/UserContext';
import {
  useGetConditionById,
  useGetStateById,
  useGetWorkflowById,
  usePostCreateCondition,
  usePostCreateEmailAction,
  usePostUpdateCondition,
  usePostUpdateExitState,
  usePublishWorkflow,
} from '@/hooks/api-hooks/useWorkflowQuery';
import { useWorkflowPropertiesStore } from '@/stores/workflow/state-properties.store';
import {
  ICondition,
  IConditionRule,
  IExitStateProperties,
  IStatementCondition,
  ITriggerStateProperties,
} from '@/types/workflow.type';
import { createId } from '@/utils/createId';
import { convertConditionsToRequestObjects } from '@/utils/workflow/convertConditionsToRequestObjects';
import { Loader2Icon, MailIcon, PlusIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const ExitCriteriaForm = ({ stateId, workflowId }: { stateId: string; workflowId: string }) => {
  const [conditions, setConditions] = useState<ICondition[]>([]);
  const [isCreate, setIsCreate] = useState(false);

  const { data: exitStateResponse, isLoading } = useGetStateById({
    stateId: stateId,
    customConfig: {
      enabled: !!stateId,
    },
  });

  const { data: conditionResponse, isLoading: isConditionLoading } = useGetConditionById({
    conditionId:
      (exitStateResponse?.data?.description as Partial<IExitStateProperties>)?.conditionActionTree?.condition || '',
    customConfig: {
      enabled: !!(exitStateResponse?.data?.description as Partial<IExitStateProperties>)?.conditionActionTree
        ?.condition,
    },
  });

  const { mutate: updateCondition, isPending: isConditionUpdateLoading } = usePostUpdateCondition({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Failed to update exit criteria',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutateAsync: createCondition, isPending: isCreateLoading } = usePostCreateCondition({
    customConfig: {
      onSuccess: () => {
        setIsCreate(false);
      },
    },
  });

  const { mutate: updateExitState, isPending: isUpdatePending } = usePostUpdateExitState({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Failed to update exit criteria',
          variant: 'destructive',
        });
      },
    },
  });

  const { data: workflowResponse } = useGetWorkflowById({
    workflowId: workflowId || '',
    customConfig: {
      enabled: !!workflowId,
    },
  });

  const isMutationPending = useMemo(() => {
    return isCreateLoading || isConditionUpdateLoading;
  }, [isCreateLoading, isConditionUpdateLoading]);

  useEffect(() => {
    if (conditionResponse?.data) {
      setConditions([conditionResponse.data]);
      setIsCreate(false);
    } else {
      setIsCreate(true);
      setConditions([
        {
          id: createId(),
          workflowId: workflowId,
          rule: {
            type: 'AND_TYPE',
            childConditions: [
              {
                type: 'STATEMENT_TYPE',
              },
            ],
          },
        },
      ]);
    }
  }, [conditionResponse?.data, workflowId]);

  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { mutate: publish, isPending: isPublishPending } = usePublishWorkflow({
    workflowId: workflowId as string,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Failed to publish workflow',
          variant: 'destructive',
        });
      },
      onSuccess() {
        toast({
          description: 'Workflow published successfully',
        });
      },
    },
  });

  const handleSave = async () => {
    if (!conditions.length) {
      // TODO: show error
      return;
    }

    if (isCreate || !conditionResponse?.data.id) {
      const result = await createCondition({
        workflowId,
        rule: convertConditionsToRequestObjects(conditions[0].rule as IConditionRule | IStatementCondition),
      });

      if (exitStateResponse?.data.stateType === 'EXIT') {
        updateExitState({
          fields: ['conditionActionTree'],
          values: {
            conditionActionTree: {
              ...(exitStateResponse?.data.description as IExitStateProperties).conditionActionTree,
              condition: result.data.id,
            },
          },
          stateId: stateId,
        });
        return;
      }

      return;
    }

    updateCondition({
      conditionId: conditionResponse.data.id,
      rule: convertConditionsToRequestObjects(conditions[0].rule as IConditionRule | IStatementCondition),
    });
  };

  const { mutateAsync: createEmailAction, isPending: isCreateEmailActionPending } = usePostCreateEmailAction({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message || 'Unable to create email action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const isCreateActionPending = useMemo(() => {
    return isUpdatePending || isCreateEmailActionPending;
  }, [isUpdatePending, isCreateEmailActionPending]);

  const handleAddEmail = () => {
    createEmailAction({
      email: {
        companyId: companiesOfUser[activeCompanyIndex].id,
        workflowId: workflowId,
      },
      workflowId: workflowId,
    }).then((returnedData) => {
      updateExitState({
        stateId: stateId,
        fields: ['conditionActionTree'],
        values: {
          conditionActionTree: {
            actions: [
              ...(exitStateResponse?.data.description as ITriggerStateProperties).conditionActionTree.actions,
              returnedData.data.id,
            ],
          },
        },
      });
    });
  };

  if (isLoading || isConditionLoading) {
    return <ExitCriteriaFormLoading />;
  }

  return (
    <div className=" max-w-[800px] mx-auto my-12 ">
      <Card>
        <CardContent className=" text-sm flex flex-col gap-8 py-8 ">
          <div className=" flex flex-col gap-2">
            <CardTitle>Set Exit Criteria</CardTitle>
            <div className="flex flex-col gap-1 ">
              <ExitCriteriaConditions conditions={conditions} setConditions={setConditions} />
            </div>
          </div>
          <div className=" flex flex-col gap-2 ">
            <CardTitle>Set Exit Actions</CardTitle>
            <div className=" border p-4 rounded-md flex flex-col gap-2 ">
              {(exitStateResponse?.data.description as IExitStateProperties).conditionActionTree.actions.map(
                (actionId) => (
                  <ActionSlot
                    actionId={actionId}
                    catIndex={0}
                    nodeType="EXIT"
                    stateId={stateId}
                    workflowId={workflowId}
                    key={actionId}
                  />
                ),
              )}
              {isCreateActionPending && (
                <div className=" flex items-center gap-2 border-muted-foreground/20 ">
                  <div className=" bg-muted-foreground/10 p-2 rounded-md ">
                    <MailIcon className=" h-8 w-8 " />
                  </div>
                  <div className=" w-ful flex flex-col gap-1 flex-1 ">
                    <div className=" w-full flex items-end gap-2 ">
                      <span className=" w-[80px] ">From:</span>
                      <Skeleton className="flex-1 h-4" />
                    </div>
                    <div className=" w-full flex items-start gap-2">
                      <span className=" w-[80px] ">Template:</span>
                      <Skeleton className="flex-1 h-4" />
                    </div>
                  </div>
                </div>
              )}
              <div className=" py-2 ">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button className=" text-blue-700  flex items-center gap-2 " variant="outline">
                      <PlusIcon className="w-3 h-3" />
                      Add Action
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className=" w-[250px] ">
                    <DropdownMenuGroup>
                      <DropdownMenuItem onClick={handleAddEmail}>Email</DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      {workflowResponse?.data.status !== 'ACTIVE' && (
        <div className=" absolute top-2 right-4 flex items-center gap-2">
          <Button disabled={isPublishPending} className=" flex items-center gap-2 " onClick={() => publish()}>
            Publish
            {isPublishPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
          </Button>
          <Button className=" flex items-center gap-2 " disabled={isMutationPending} onClick={handleSave}>
            Save
            {isMutationPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
          </Button>
        </div>
      )}
    </div>
  );
};

const ExitCriteriaFormLoading = () => {
  return (
    <div className=" max-w-[800px] mx-auto my-12 ">
      <Card>
        <CardContent className=" text-sm flex flex-col gap-12 py-8 ">
          <div className=" flex flex-col gap-2">
            <Skeleton className=" w-40 h-6 " />
            <div className="flex flex-col gap-1 ">
              <div className=" flex items-center gap-2 ">
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
              </div>
            </div>
            <div className="flex flex-col gap-1 ">
              <div className=" flex items-center gap-2 ">
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
              </div>
            </div>
            <div className="flex flex-col gap-1 ">
              <div className=" flex items-center gap-2 ">
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
              </div>
            </div>
          </div>
          <div className=" flex flex-col gap-2">
            <Skeleton className=" w-40 h-6 " />
            <div className="flex flex-col gap-1 ">
              <div className=" flex items-center gap-2 ">
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
              </div>
            </div>
            <div className="flex flex-col gap-1 ">
              <div className=" flex items-center gap-2 ">
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
              </div>
            </div>
            <div className="flex flex-col gap-1 ">
              <div className=" flex items-center gap-2 ">
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
                <Skeleton className=" flex-1 h-6 " />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const ExitStateProperties = ({ actionId, isOpen }: { actionId: string; isOpen: boolean }) => {
  return (
    <Sheet open={isOpen}>
      <SheetContent side="right" className="p-0 m-0 h-[90%] mt-24 w-[340px] border">
        <EmailProperties actionId={actionId} />
      </SheetContent>
    </Sheet>
  );
};

const ExitCriteriaPage = () => {
  const { workflowId } = useParams();

  const { selectedProperties } = useWorkflowPropertiesStore();

  const { data: workflowResponse, isLoading } = useGetWorkflowById({
    workflowId: workflowId as string,
    customConfig: {
      enabled: !!workflowId,
    },
  });

  const state = useMemo(() => {
    return workflowResponse?.data.description.states.find((item) => item.stateType === 'EXIT');
  }, [workflowResponse?.data]);

  if (isLoading) {
    return (
      <WorkflowLayout>
        <Tabs isEdit={!!workflowId} workflowId={workflowId} />
        <div className=" flex-1 overflow-scroll ">
          <ExitCriteriaFormLoading />
        </div>
      </WorkflowLayout>
    );
  }

  if (!state) {
    return null;
  }

  return (
    <WorkflowLayout>
      <div className=" absolute top-3 left-16 font-semibold ">{`${
        workflowResponse?.data.name ? workflowResponse?.data.name + ' [' + workflowResponse?.data.status + ']' : ''
      }`}</div>
      <Tabs isEdit={!!workflowId} workflowId={workflowId} />
      <div className=" flex-1 overflow-scroll ">
        <ExitCriteriaForm workflowId={workflowId as string} stateId={state.id} />
      </div>
      {!!selectedProperties?.actionId && (
        <ExitStateProperties
          actionId={selectedProperties.actionId}
          isOpen={
            !!selectedProperties.actionId &&
            selectedProperties.type === 'EXIT' &&
            selectedProperties.updateType === 'action'
          }
        />
      )}
    </WorkflowLayout>
  );
};

export default ExitCriteriaPage;
