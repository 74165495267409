export const typeMap = {
  customer: 'customer',
  invoice: 'invoice',
};

export const invoiceValueLabelMap = {
  invoiceSubtotal: 'invoice subtotal',
  invoiceTotal: 'invoice total',
  invoicePaidAmount: 'invoice paid amount',
  invoiceOutstandingAmount: 'invoice outstanding amount',
  invoiceAdjustedAmount: 'invoice adjusted amount',
  invoiceDate: 'invoice date',
  invoiceDueDate: 'invoice due date',
  promiseToPayDate: 'promise to pay date',
  customerName: 'customer name',
  invoiceStatus: 'invoice status',
  paymentStatus: 'Payment Status',
  invoiceNumber: 'invoice number',
};

export const inputTypeMap: Record<
  string,
  'STRING' | 'NUMBER' | 'DATE' | 'INVOICE-TYPE-DROPDOWN' | 'INVOICE-STATUS-DROPDOWN' | 'PAYMENT-STATUS-DROPDOWN'
> = {
  invoiceSubtotal: 'NUMBER',
  invoiceTotal: 'NUMBER',
  invoicePaidAmount: 'NUMBER',
  invoiceOutstandingAmount: 'NUMBER',
  invoiceAdjustedAmount: 'NUMBER',
  invoiceDate: 'DATE',
  invoiceDueDate: 'DATE',
  promiseToPayDate: 'DATE',
  customerName: 'STRING',
  invoiceType: 'INVOICE-TYPE-DROPDOWN',
  invoiceStatus: 'INVOICE-STATUS-DROPDOWN',
  paymentStatus: 'PAYMENT-STATUS-DROPDOWN',
  invoiceNumber: 'STRING',
};

export const isCurrencyMap = {
  invoiceSubtotal: true,
  invoiceTotal: true,
  invoicePaidAmount: true,
  invoiceOutstandingAmount: true,
  invoiceAdjustedAmount: true,
};

export const propertiesMap: Record<
  'invoice' | 'customer',
  Record<string, 'OBJECT' | 'ARRAY' | 'STRING' | 'NUMBER' | 'DATE'>
> = {
  customer: {
    name: 'STRING',
    email: 'STRING',
    phone: 'STRING',
    address: 'STRING',
    city: 'STRING',
    country: 'STRING',
    postalCode: 'STRING',
  },
  invoice: {
    id: 'STRING',
    invoiceId: 'STRING',
    invoiceNumber: 'STRING',
    currency: 'STRING',
    invoiceSubtotal: 'NUMBER',
    invoiceTotal: 'NUMBER',
    invoicePaidAmount: 'NUMBER',
    invoiceOutstandingAmount: 'NUMBER',
    invoiceAdjustedAmount: 'NUMBER',
    invoiceDate: 'DATE',
    invoiceDueDate: 'DATE',
    promiseToPayDate: 'STRING',
    customerId: 'STRING',
    discount: 'NUMBER',
    discountPercentage: 'NUMBER',
    irnNumber: 'STRING',
    irnGenerationDate: 'STRING',
    irnAckNumber: 'STRING',
    companyId: 'STRING',
    placeOfSupply: 'STRING',
    countryOfSupply: 'STRING',
    customerName: 'STRING',
    invoiceCurrency: 'STRING',
    invoiceType: 'STRING',
    invoiceStatus: 'STRING',
    paymentStatus: 'STRING',
  },
};
