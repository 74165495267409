import MemberList from '@/components/members/MemberList';
import { Loading } from '@/components/shared/Loading';

import { useGetUserDetails } from '@/hooks/api-hooks/useUser';

const UserProfileAndSettings = () => {
  const { data, isLoading, isError } = useGetUserDetails({});
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return (
      <div className="text-gray-500 flex1 flex-row justify-center items-center">
        Something went wrong. Could not fetch members list right now!!!
      </div>
    );
  }
  const user = data?.data;
  return (
    <div className="flex flex-col space-y-8 items-center flex-1">
      <div className="h-1/10 flex justify-start w-8/10 mt-2 flex-col space-y-4">
        <span className="font-semibold">User Profile</span>
        <div className="flex items-center space-x-2">
          <span className="bg-blue-500 text-white w-8 h-8 rounded-full flex items-center justify-center">
            {user?.firstName[0]}
          </span>
          <div className="flex flex-col">
            <span>{`${user?.firstName} ${user?.lastName}`}</span>
            <span className="text-sm text-gray-500">{user?.email}</span>
          </div>
        </div>
      </div>
      <MemberList />
    </div>
  );
};

export default UserProfileAndSettings;
