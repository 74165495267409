/* eslint-disable @typescript-eslint/no-explicit-any */
import { cn, sanitizeAttachmentsName } from '@/lib/utils';

import TableSkeleton from '@/components/shared/TableSkeleton';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useDryRunExport } from '@/hooks/excel-export/useDryRunExport';
import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { usePreviewStore } from '@/stores/email-builder/preview-store';
import {
  IWorkflowDryRun,
  IWorkflowDryRunEmail,
  IWorkflowDryRunEmailAttachment,
  IWorkflowDryRunEmailCustomer,
  IWorkflowDryRunEmailInvoice,
  IWorkflowDryRunExportHeader,
} from '@/types/workflow.type';
import {
  WORKFLOW_DRY_COLUMN_VISIBILITY_STORAGE_KEY,
  WORKFLOW_DRY_RUN_COLUMN_ORDER_STORAGE_KEY,
} from '@/utils/constants/local-storage-keys';
import {
  ColumnDef,
  RowSelectionState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ArrowDownUp, GripVerticalIcon, Loader2Icon } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ColumnSelector } from '../../shared/ReactTableColumnSelector';
import { CustomTableBody, MemoizedTableBody } from '../../Table/MemoizedTableBody';
import { Skeleton } from '../../ui/skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table';
import ShowItemsModal from '../components/dryrun-items-modal';

const Items = <T extends IWorkflowDryRunEmailAttachment | IWorkflowDryRunEmailInvoice | IWorkflowDryRunEmailCustomer>({
  items,
  accessorKey,
  type,
  handleOpenModalClick,
}: {
  items: T[];
  accessorKey: keyof T;
  type: 'Invoice' | 'Attachment';
  handleOpenModalClick: (
    _items: IWorkflowDryRunEmailInvoice[] | IWorkflowDryRunEmailAttachment[],
    _type: 'Invoice' | 'Attachment',
  ) => void;
}): React.ReactNode => {
  if (items.length === 0) return '';

  let firstItem = items[0][accessorKey] as React.ReactNode;
  if (type == 'Attachment') {
    firstItem = sanitizeAttachmentsName(firstItem as string) as React.ReactNode;
  }
  const remainingCount = items.length - 1;
  let redirectUrl = '';

  if (type === 'Invoice') {
    const invoiceId = (items as IWorkflowDryRunEmailInvoice[])[0].invoiceId;
    redirectUrl = `/invoice/view/${invoiceId}`;
  } else if (type === 'Attachment') {
    const attachmentId = (items as IWorkflowDryRunEmailAttachment[])[0].attachmentId;
    redirectUrl = `https://data.valyx.com/attachments/${attachmentId}/file`;
  }

  const itemLink = (
    <div
      onClick={() => {
        window.open(redirectUrl, '_blank');
      }}
    >
      <ToolTipCell value={firstItem as React.ReactNode}></ToolTipCell>
    </div>
  );

  const remainingItems =
    remainingCount > 0 ? (
      <div
        onClick={() =>
          handleOpenModalClick(items as IWorkflowDryRunEmailInvoice[] | IWorkflowDryRunEmailAttachment[], type)
        }
        className="cursor-pointer"
      >{`+ ${remainingCount} more`}</div>
    ) : null;

  return (
    <>
      {itemLink}
      {remainingItems}
    </>
  );
};

const Customers = ({
  data,
  handleOpenModalClick,
}: {
  data: IWorkflowDryRunEmailCustomer[];
  handleOpenModalClick: (_data: IWorkflowDryRunEmailCustomer[], _type: 'Customer') => void;
}) => {
  const uniqueCustomers = Array.from(new Map(data.map((customer) => [customer.customerName, customer])).values());
  const buyerId = data[0].buyerId;
  return uniqueCustomers.length == 1 ? (
    <Link target="_blank" to={`/customers?identifierType=BUYER_ID&identifierValue=${buyerId}`}>
      {`${buyerId} - ${data[0].customerName}`}
    </Link>
  ) : (
    <div
      onClick={() => {
        handleOpenModalClick(data, 'Customer');
      }}
    >
      <ToolTipCell value={`${buyerId}`}></ToolTipCell>
    </div>
  );
};

const WFDryRunTable = ({ workflowDryRunResponse }: { workflowDryRunResponse: IWorkflowDryRun }) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const loadData = usePreviewStore((store) => store.loadData);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenForInvoice, setIsModalOpenForInvoice] = useState<boolean>(false);
  const [isModalOpenForAttachment, setIsModalOpenForAttachment] = useState<boolean>(false);
  const [isModalOpenForCustomer, setIsModalOpenForCustomer] = useState<boolean>(false);
  const [buyerId, setBuyerId] = useState<string>('');

  const data = workflowDryRunResponse.emails;
  const workflowDryRunstatus = workflowDryRunResponse.status;
  const navigate = useNavigate();
  const [dataForModal, setDataForModal] = useState<
    IWorkflowDryRunEmailAttachment[] | IWorkflowDryRunEmailInvoice[] | IWorkflowDryRunEmailCustomer[]
  >([]);

  const handleModalOpen = (type: 'Invoice' | 'Attachment' | 'Customer') => {
    if (type == 'Invoice') {
      setIsModalOpenForInvoice(true);
    } else if (type == 'Customer') {
      setIsModalOpenForCustomer(true);
    } else {
      setIsModalOpenForAttachment(true);
    }
    setIsModalOpen(true);
  };

  const handleOpenModalClick = useCallback(
    (
      data: IWorkflowDryRunEmailAttachment[] | IWorkflowDryRunEmailInvoice[] | IWorkflowDryRunEmailCustomer[],
      type: 'Invoice' | 'Attachment' | 'Customer',
    ) => {
      handleModalOpen(type);
      setDataForModal(data);
    },
    [],
  );

  const { toast } = useToast();
  const { exportExcel, isLoading: isExporting } = useDryRunExport({
    onSuccess: (url) => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'TestRunResults.xlsx');
      document.body.appendChild(link);
      link.click();
      toast({
        description: 'Test run results exported',
      });
    },
    onError() {
      toast({
        variant: 'destructive',
        description: 'Failed to export test run results',
      });
    },
  });
  const [sorting, setSorting] = useState<SortingState>([]);

  const handleExportDryRunResult = () => {
    const headerForExcel: IWorkflowDryRunExportHeader = {
      testRunname: workflowDryRunResponse?.name,
      initiatedBy: workflowDryRunResponse?.initiatedBy,
      initiatedOn: dayjs(workflowDryRunResponse?.initiatedAt).add(330, 'm').format('DD MMM YY, HH:MM A'),
    };
    exportExcel(data, new Set(tableColumns.filter((col) => col.getIsVisible()).map((col) => col.id)), headerForExcel);
  };

  const handlePreviewMailClick = useCallback(
    (templateId: string, html: string, subject: string) => {
      loadData({
        body: html,
        subject: subject,
        variables: [],
      });
      navigate(`/email-builder/${templateId}/preview?isForWorkflow=true`);
    },
    [navigate, loadData],
  );

  const columns: ColumnDef<IWorkflowDryRunEmail>[] = useMemo(
    () => [
      {
        label: 'Subject',
        accessorKey: 'subject',
        id: 'subject',
        header: ({ column }) => (
          <div className=" flex items-center justify-between">
            Subject
            <ArrowDownUp
              className="w-4 h-4 ml-2 cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            />
          </div>
        ),
        size: 200,
        minSize: 20,
        enableHiding: true,
        cell: ({ row }) => (
          <div
            onClick={() => {
              handlePreviewMailClick(row.original.templateId, row.original.contentHtml, row.original.subject);
            }}
          >
            <ToolTipCell additionalClasses="cursor-pointer text-blue-700" value={row.original.subject} />
          </div>
        ),
      },
      {
        label: 'To',
        accessorKey: 'to',
        id: 'to',
        header: ({ column }) => (
          <div className=" flex items-center justify-between">
            To
            <ArrowDownUp
              className="w-4 h-4 ml-2 cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            />
          </div>
        ),
        size: 180,
        minSize: 20,
        cell: ({ row }) => <ToolTipCell value={row.original.to.join(', ')} />,
      },
      {
        label: 'Cc',
        accessorKey: 'cc',
        id: 'cc',
        size: 180,
        header: 'Cc',
        minSize: 20,
        cell: ({ row }) => <ToolTipCell value={row.original.cc.join(', ')} />,
      },
      {
        label: 'Bcc',
        accessorKey: 'bcc',
        id: 'bcc',
        header: 'Bcc',
        size: 180,
        minSize: 20,
        cell: ({ row }) => <ToolTipCell value={row.original.bcc.join(', ')} />,
      },
      {
        label: 'Buyer ID',
        accessorKey: 'customers',
        id: 'customers',
        size: 180,

        header: ({ column }) => (
          <div className=" flex items-center justify-between">
            Buyer ID
            <ArrowDownUp
              className="w-4 h-4 ml-2 cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            />
          </div>
        ),
        minSize: 20,
        cell: ({ row }) => (
          <div
            className="text-blue-700"
            onClick={() => {
              setBuyerId(row.original.customers[0].buyerId);
            }}
          >
            {row.original.customers && row.original.customers.length > 0 && (
              <Customers data={row.original.customers} handleOpenModalClick={handleOpenModalClick} />
            )}
          </div>
        ),
      },
      {
        label: 'Invoices',
        accessorKey: 'invoices',
        id: 'invoices',
        size: 180,
        header: ({ column }) => (
          <div className=" flex items-center justify-between">
            Invoices
            <ArrowDownUp
              className="w-4 h-4 ml-2 cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            />
          </div>
        ),
        minSize: 20,
        cell: ({ row }) => (
          <div
            onClick={() => {
              setBuyerId(row.original.customers[0].buyerId);
            }}
            className="text-blue-700"
          >
            <Items
              accessorKey={'invoiceNumber'}
              items={row.original.invoices}
              handleOpenModalClick={handleOpenModalClick}
              type="Invoice"
            />
          </div>
        ),
      },
      {
        label: 'Attachments',
        minSize: 20,
        accessorKey: 'attachments',
        id: 'attachments',
        size: 180,
        header: 'Attachments',
        cell: ({ row }) => (
          <div
            onClick={() => {
              setBuyerId(row.original.customers[0].buyerId);
            }}
            className="text-blue-700"
          >
            <Items
              accessorKey={'attachmentName'}
              items={row.original.attachments}
              handleOpenModalClick={handleOpenModalClick}
              type="Attachment"
            />
          </div>
        ),
      },
    ],
    [handleOpenModalClick, handlePreviewMailClick],
  );
  const tableData = useMemo(() => {
    return data;
  }, [data]);

  const sortedTableData = useMemo(() => {
    if (sorting.length === 0) return tableData;

    return [...tableData].sort((a, b) => {
      for (const sort of sorting) {
        const key = sort.id as keyof IWorkflowDryRunEmail;
        let valueA = a[key];
        let valueB = b[key];

        // Handle specific cases based on the type of data
        if (key === 'customers') {
          valueA = a.customers?.[0]?.buyerId || '';
          valueB = b.customers?.[0]?.buyerId || '';
        }

        // Ensure values are strings for comparison
        if (typeof valueA !== 'string') valueA = String(valueA);
        if (typeof valueB !== 'string') valueB = String(valueB);

        // Apply natural string comparison for consistent sorting
        if (sort.desc) {
          return valueB.localeCompare(valueA, undefined, { numeric: true });
        } else {
          return valueA.localeCompare(valueB, undefined, { numeric: true });
        }
      }
      return 0;
    });
  }, [tableData, sorting]);
  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    WORKFLOW_DRY_RUN_COLUMN_ORDER_STORAGE_KEY,
    defaultColumnOrder,
  );

  const defaultColumnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const { storedValue: columnVisibility, setValue: setColumnVisibilityValue } = useLocalStorage(
    WORKFLOW_DRY_COLUMN_VISIBILITY_STORAGE_KEY,
    defaultColumnVisibility,
  );

  const table = useReactTable({
    data: sortedTableData,
    columns,
    onRowSelectionChange: (value) => setRowSelection(value),
    getRowId: (row) => row.id,
    onColumnVisibilityChange: setColumnVisibilityValue,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 50,
    },
    state: {
      rowSelection,
      sorting,
      columnOrder,
      columnVisibility,
    },
    onSortingChange: setSorting,
  });

  const tableColumns = useMemo(() => table.getAllColumns(), [table]);

  const sizes = table.getState().columnSizingInfo;

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    return headers.reduce(
      (acc, current) => {
        return {
          ...acc,
          [`--col-${current.column.id}-size`]: current.column.getSize(),
          [`--header-${current.id}-size`]: current.getSize(),
        };
      },
      {} as {
        [key: string]: number;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);
  const isLoading = false;
  if (isLoading) {
    return (
      <div className="py-2">
        <Table>
          <TableHeader className=" bg-gray-100">
            {new Array(1).fill(0).map((_, i) => (
              <TableRow key={i}>
                {new Array(6).fill(0).map((_, i) => (
                  <TableHead className="px-6 min-w-[100px]" key={i}>
                    <Skeleton className="w-full h-4" />
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="py-4">
            {new Array(10).fill(0).map((_, i) => (
              <TableRow key={i}>
                {new Array(6).fill(0).map((_, i) => (
                  <TableCell className="px-6 text-center" key={i}>
                    <Skeleton className="w-full h-4" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between my-4 ">
        <div className="flex justify-between w-full ">
          <div className="flex items-center gap-4 truncate text-ellipsis ">
            <ShowItemsModal
              isModalOpen={isModalOpen}
              setisModalOpen={setIsModalOpen}
              type={
                isModalOpenForInvoice
                  ? 'Invoices'
                  : isModalOpenForAttachment
                    ? 'Attachments'
                    : isModalOpenForCustomer
                      ? 'Customer'
                      : ''
              }
              setIsModalOpenForAttachment={setIsModalOpenForAttachment}
              setIsModalOpenForInvoice={setIsModalOpenForInvoice}
              items={dataForModal}
              buyerId={buyerId}
            />
          </div>
          <div className="flex w-full justify-between items-center gap-4">
            <div className="text-muted-foreground text-sm font-normal ">
              {workflowDryRunstatus == 'RUNNING'
                ? 'Running....'
                : workflowDryRunstatus == 'COMPLETED'
                  ? `Test Run completed. ${data.length} email(s) would have been sent`
                  : workflowDryRunstatus == 'FAILED'
                    ? 'Dry Run failed to complete. Try again'
                    : workflowDryRunstatus == 'INITIATED'
                      ? 'Test run initiated....'
                      : ''}
            </div>
            <div className=" flex ">
              {data.length > 0 && (
                <Button disabled={isExporting} onClick={handleExportDryRunResult} className="mr-4">
                  Export {isExporting ? <Loader2Icon className="w-4 h-4 animate-spin" /> : ''}
                </Button>
              )}
              <ColumnSelector setColumnOrder={setColumnOrder} columnOrder={columnOrder} columns={tableColumns} />
            </div>
          </div>
        </div>
      </div>
      <div className=" overflow-auto ">
        <Table
          style={{
            ...columnSizeVars,
            width: table.getTotalSize(),
          }}
          className="flex-1"
        >
          <TableHeader className=" bg-gray-100 ">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className="flex items-center" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      style={{
                        width: `calc(var(--header-${header?.id}-size) * 1px)`,
                      }}
                      className="flex items-center justify-between"
                      key={header.id}
                    >
                      <div className="break-keep truncate text-ellipsis">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                      <GripVerticalIcon
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: cn(
                            'w-4 h-4 cursor-ew-resize min-w-4',
                            header.column.getIsResizing() ? 'opacity-100' : 'opacity-50',
                          ),
                        }}
                      />
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          {table.getState().columnSizingInfo.isResizingColumn ? (
            <MemoizedTableBody table={table} />
          ) : (
            <CustomTableBody table={table} />
          )}
        </Table>
      </div>
    </div>
  );
};

const WorkflowDryRunList = ({
  isLoading,
  workflowDryRunData,
}: {
  isLoading: boolean;
  workflowDryRunData?: IWorkflowDryRun;
}) => {
  if (isLoading) {
    return <TableSkeleton columns={3} rows={10} />;
  }

  return <>{workflowDryRunData && <WFDryRunTable workflowDryRunResponse={workflowDryRunData} />}</>;
};

export default WorkflowDryRunList;
