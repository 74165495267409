import { useGetOwnerById } from '@/hooks/api-hooks/useOwnersQuery';
import Owner from '../owner/Owner';
import { Skeleton } from '../ui/skeleton';

const OwnerName = ({ ownerId }: { ownerId: string }) => {
  const { data: ownerData, isLoading } = useGetOwnerById({
    ownerId,
  });

  if (isLoading) {
    return <Skeleton className=" w-32 h-5 " />;
  }

  if (!ownerData) {
    return <div className="flex items-center gap-2">{ownerId}</div>;
  }

  return <Owner owner={ownerData.data} />;
};

export default OwnerName;
