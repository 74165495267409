import { dataAxiosInstance } from '@/lib/axios';
import { IUpiIdQRCodeRequest, IUpiPaymentLinkRequest } from '@/types/upi.type';

export const getVPAList = async () => {
  const result = await dataAxiosInstance.get('/company/vpaList');
  return result.data;
};

export const postGenerateUpiQRCode = async ({ customerId, upiVpa, amount }: IUpiIdQRCodeRequest) => {
  const result = await dataAxiosInstance.post('/upiQrCode/generateQr/customer', {
    upiVpa,
    customerId,
    amount,
  });
  return result.data;
};

export const postGenerateUpiPaymentLink = async ({ upiVpa, customerId, message, amount }: IUpiPaymentLinkRequest) => {
  const result = await dataAxiosInstance.post('/upiQrCode/generateLink/customer', {
    upiVpa,
    customerId,
    message,
    amount,
  });
  return result.data;
};

export const getPaymentDetails = async ({ paymentId }: { paymentId: string }) => {
  const result = await dataAxiosInstance.get(`/payment/upi?data=${paymentId}`);
  return result.data;
};
