import CreditDetails from '@/components/posting/credits/CreditDetails';
import PostingCreditHeader from '@/components/posting/credits/CreditPageHeader';
import { MarkCreditAsUnReversedDialog } from '@/components/posting/credits/MarkCreditReversed';
import { PostAsDropDown } from '@/components/posting/credits/PostAsDropDown';
import SelectedRows from '@/components/posting/credits/SelectedRows';
import { InvoicesFiltersDialog } from '@/components/posting/invoices/InvoicesFilters';
import { Layout } from '@/components/shared/Layout';
import Pagination from '@/components/shared/Pagination';
import { ColumnSelector } from '@/components/shared/ReactTableColumnSelector';
import TableSkeleton from '@/components/shared/TableSkeleton';
import { ResizableTable } from '@/components/Table/ResizableTable';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import {
  useGetCreditById,
  useGetCreditPlaceholders,
  useGetFilteredInvoicesForPosting,
  useGetPostedInvoicesForCredits,
} from '@/hooks/api-hooks/usePostingQuery';
import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { cn } from '@/lib/utils';
import { IInvoice, IInvoicesFilter, IInvoicesFilterForPosting } from '@/types/invoices.types';
import { ICredit, IInvoiceWithPosting, IPlaceholder, IPlaceHolderWithPosting } from '@/types/posting.types';
import { IGNORED_PLACEHOLDERS } from '@/utils/constants';
import {
  POSTING_SINGLE_CREDIT_VIEW_INVOICE_TABLE_COLUMN_ORDER_STORAGE_KEY,
  POSTING_SINGLE_CREDIT_VIEW_INVOICE_TABLE_COLUMN_VISIBILITY_STORAGE_KEY,
} from '@/utils/constants/local-storage-keys';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { toFixed } from '@/utils/toFixed';
import {
  ColumnDef,
  getCoreRowModel,
  Table as ReactTable,
  RowSelectionState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ChevronsLeftIcon, ChevronsRightIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Link, Navigate, useParams, useSearchParams } from 'react-router-dom';

const InvoiceTable = ({ table, isLoading }: { table: ReactTable<IInvoice>; isLoading: boolean }) => {
  return <ResizableTable headersToExcludeResizing={['select']} isLoading={isLoading} table={table} />;
};

const PostingWrapper = ({
  transactionId,
  amountAvailableForPosting,
  customers = [],
  suggestedCustomers = [],
}: {
  transactionId: string;
  amountAvailableForPosting: number;
  suggestedCustomers?: ICredit['customerSuggestions'];
  customers?: ICredit['customers'];
}) => {
  const [invoiceFilters, setInvoiceFilters] = useState<Partial<IInvoicesFilterForPosting>>({
    paymentStatus: ['PARTIALLY_PAID', 'UNPAID'],
  });
  const [searchParams] = useSearchParams();
  const [isPostingSheetOpen, setIsPostingSheetOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const { data: postedToCreditData, isLoading: isGetPostedToCreditDataLoading } = useGetPostedInvoicesForCredits({
    bat: transactionId,
  });

  const postedPlaceholders = useMemo(() => {
    return postedToCreditData?.data.filter((item) => !!item.placeholderId) || [];
  }, [postedToCreditData]);

  const postedInvoices = useMemo(() => {
    return postedToCreditData?.data.filter((item) => !!item.invoice.id) || [];
  }, [postedToCreditData]);

  const {
    register,
    control,
    handleSubmit: handleFormSubmit,
    formState: { errors, isDirty },
    watch,
    reset,
  } = useForm<{
    invoices: IInvoiceWithPosting[];
    placeholders: IPlaceHolderWithPosting[];
  }>({
    values: {
      invoices:
        postedInvoices?.map((item) => {
          return {
            ...item.invoice,
            adjustedAmount: 0,
            invoiceId: item.invoice.id,
            isDefaultSelected: true,
            postingId: item.postingId,
            alreadyPostedAmount: Number(item.postedAmount),
            postedAmount: Number(item.postedAmount),
          };
        }) || [],
      placeholders:
        postedPlaceholders?.map((item) => {
          return {
            amount: item.postedAmount,
            name: item.postingId,
            placeholderId: item.placeholderId || '',
            customer: {
              value: item.customerId || '',
              label: item.customerName || '',
            },
            postingId: item.postingId,
            alreadyPostedAmount: Number(item.postedAmount),
            postedAmount: Number(item.postedAmount),
            isAlreadyPosted: true,
          };
        }) || [],
    },
    mode: 'onChange',
  });

  const { append: appendInvoice, remove: removeInvoice } = useFieldArray({
    name: 'invoices',
    control,
  });

  const { append: appendPlaceholder, remove: removePlaceholder } = useFieldArray({
    name: 'placeholders',
    control,
  });
  const invoicesFields = watch('invoices');
  const placeholderFields = watch('placeholders');

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    const selection = invoicesFields.reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr.invoiceId]: true,
        };
      },
      {} as { [key: string]: boolean },
    );
    setRowSelection(selection);
  }, [invoicesFields]);

  const { data: invoicesData, isLoading } = useGetFilteredInvoicesForPosting({
    page: page,
    limit: pageLimit,
    notInCredit: [transactionId as string],
    ...invoiceFilters,
    customConfig: {},
  });

  const handleSubmit = useCallback(
    (filters: Partial<IInvoicesFilter>) => {
      setInvoiceFilters(filters);
    },
    [setInvoiceFilters],
  );

  const invoices = useMemo(() => {
    return invoicesData?.data.docs || [];
  }, [invoicesData]);

  const handleToggleInvoice = useCallback(
    (value: boolean | string, invoice: IInvoice) => {
      setIsPostingSheetOpen(true);
      if (value) {
        const newlyPostedInvoices = invoicesFields.reduce((acc, curr) => {
          if (curr.invoiceId === invoice.id) {
            return Number(acc);
          }
          return Number(acc) + Number(curr.postedAmount) - Number(curr.alreadyPostedAmount);
        }, 0);

        const newlyPostedPlaceholders = placeholderFields.reduce((acc, current) => {
          return Number(acc) + Number(current.amount) - Number(current.alreadyPostedAmount);
        }, 0);

        const remaining = toFixed(amountAvailableForPosting - newlyPostedInvoices - newlyPostedPlaceholders, 2);

        appendInvoice({
          ...invoice,
          postedAmount: Number(Math.min(remaining, invoice.invoiceOutstandingAmount).toFixed(2)),
          adjustedAmount: 0,
          alreadyPostedAmount: 0,
          invoiceId: invoice.id,
        });
      } else {
        const foundInvoice = invoicesFields.find((x) => x.invoiceId === invoice.id);
        if (foundInvoice && !foundInvoice.isDefaultSelected) {
          removeInvoice(invoicesFields.findIndex((x) => x.invoiceId === invoice.id));
        }
      }
    },
    [appendInvoice, removeInvoice, invoicesFields, amountAvailableForPosting, placeholderFields],
  );

  const columns = useMemo<ColumnDef<IInvoice>[]>(
    () => [
      {
        id: 'select',
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => handleToggleInvoice(value, row.original)}
            aria-label="Select row"
          />
        ),

        enableSorting: false,
        enableHiding: false,
        minSize: 50,
        size: 50,
        showGrips: false,
      },
      {
        id: 'invoiceNumber',
        header: 'Number',
        accessorKey: 'invoiceNumber',
        cell: ({ row, getValue }) => {
          return (
            <div className=" w-full ">
              <Link to={`/invoice/view/${row.original.id}`}>
                <ToolTipCell value={getValue() as string} additionalClasses="pointer underline text-blue-700 " />
                <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
                  {row.original.lineItems?.at(0)?.lineItemDetails?.name}
                </div>
              </Link>
            </div>
          );
        },
      },
      {
        id: 'customer',
        header: 'Customer',
        accessorKey: 'customerName',
        cell: ({ row, getValue }) => {
          return (
            <div className=" w-full ">
              <Link to={`/customer/view/${row.original.customerId}`}>
                <ToolTipCell value={getValue() as string} additionalClasses="pointer underline text-blue-700 " />
              </Link>
            </div>
          );
        },
      },
      {
        id: 'amountDue',
        header: 'Due/Overdue',
        accessorKey: 'invoiceOutstandingAmount',
        cell: ({ getValue, row }) => (
          <div className=" w-full flex flex-col gap-1 ">
            <div className="w-full truncate text-ellipsis ">{formatCurrencyByUnit(getValue() as number, 'actual')}</div>
            <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
              Total: {formatCurrencyByUnit(row.original.invoiceTotal, 'actual')}
            </div>
          </div>
        ),
      },
      {
        id: 'paymentStatus',
        header: 'Payment Status',
        accessorKey: 'paymentStatus',
        cell: ({ getValue }) => (
          <div className=" w-full flex flex-col gap-1 ">
            <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
              <Badge
                className={cn(
                  getValue() === 'PAID' && 'bg-green-700 hover:bg-green-700 hover:text-white text-white',
                  getValue() === 'UNPAID' && 'bg-red-500 text-white hover:bg-red-500 hover:text-white',
                  getValue() === 'PARTIALLY_PAID' && 'bg-yellow-400 text-black hover:bg-yellow-400',
                  'capitalize',
                )}
              >
                {(getValue() as string).split('_').join(' ').toLowerCase()}
              </Badge>
            </div>
          </div>
        ),
      },
      {
        id: 'invoiceDate',
        header: 'Invoice Date',
        accessorKey: 'invoiceDate',
        cell: ({ getValue }) => dayjs(getValue() as string).format('DD MMM YYYY'),
      },
      {
        id: 'dueDate',
        header: 'Due Date',
        accessorKey: 'invoiceDueDate',
        cell: ({ getValue, row }) => (
          <div className=" w-full ">
            <div className="w-full truncate text-ellipsis ">{dayjs(getValue() as string).format('DD MMM YYYY')}</div>
            {dayjs(getValue() as string)
              .endOf('day')
              .diff(dayjs(), 'days') == 0 ? (
              <div className="text-xs text-muted-foreground w-full text-yellow-400  font-semibold truncate text-ellipsis">
                {row.original.invoiceOutstandingAmount > 0 && `Due Today`}
              </div>
            ) : dayjs(getValue() as string)
                .endOf('day')
                .diff(dayjs(), 'days') < 0 ? (
              <div className="text-xs text-muted-foreground w-full text-red-500 font-semibold truncate text-ellipsis">
                {row.original.invoiceOutstandingAmount > 0 &&
                  `Overdue by ${Math.abs(
                    dayjs(getValue() as string)
                      .endOf('day')
                      .diff(dayjs(), 'days'),
                  )} days`}
              </div>
            ) : dayjs(getValue() as string)
                .endOf('day')
                .diff(dayjs(), 'days') > 0 && dayjs(getValue() as string).diff(dayjs(), 'days') <= 7 ? (
              <div className="text-xs text-muted-foreground w-full text-yellow-400  font-semibold truncate text-ellipsis">
                {row.original.invoiceOutstandingAmount > 0 &&
                  `Due in ${Math.abs(
                    dayjs(getValue() as string)
                      .endOf('day')
                      .diff(dayjs(), 'days'),
                  )} day${
                    Math.abs(
                      dayjs(getValue() as string)
                        .endOf('day')
                        .diff(dayjs(), 'days'),
                    ) > 1
                      ? 's'
                      : ''
                  }`}
              </div>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        id: 'promiseToPayDate',
        header: 'Promise To Pay Date',
        accessorKey: 'promiseToPayDate',
        cell: ({ getValue }) => (
          <div className=" w-full ">
            <div className="w-full truncate text-ellipsis ">{dayjs(getValue() as string).format('DD MMM YYYY')}</div>
          </div>
        ),
      },
    ],
    [handleToggleInvoice],
  );
  const toggleSheetOpen = useCallback(() => {
    setIsPostingSheetOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    searchParams.get('defaultListType') === 'posted' && setIsPostingSheetOpen(true);
  }, [searchParams, toggleSheetOpen]);

  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);
  const defaultColumnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const { storedValue: columnVisibility, setValue: setColumnVisibility } = useLocalStorage(
    POSTING_SINGLE_CREDIT_VIEW_INVOICE_TABLE_COLUMN_VISIBILITY_STORAGE_KEY,
    defaultColumnVisibility,
  );

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    POSTING_SINGLE_CREDIT_VIEW_INVOICE_TABLE_COLUMN_ORDER_STORAGE_KEY,
    defaultColumnOrder,
  );

  const table = useReactTable({
    columns,
    data: invoices,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    getRowId: (row) => row.id,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection: {
        ...rowSelection,
      },
      columnOrder,
      columnVisibility,
    },
  });

  const handleRemoveInvoice = (invoiceId: string) => {
    const indexOfInvoice = invoicesFields.findIndex((invoice) => invoice.invoiceId === invoiceId);
    if (indexOfInvoice !== -1) {
      removeInvoice(indexOfInvoice);
    }
  };

  const tableColumns = useMemo(() => table.getAllColumns(), [table]);

  const { data: creditPlaceholdersData } = useGetCreditPlaceholders();

  const creditPlaceholders = useMemo(() => {
    return creditPlaceholdersData?.data;
  }, [creditPlaceholdersData?.data]);

  const creditPlaceholdersToPost = useMemo(() => {
    return creditPlaceholders?.filter(
      (item) =>
        !postedPlaceholders.some((field) => field.placeholderId === item.id) &&
        !IGNORED_PLACEHOLDERS.includes(item.name),
    );
  }, [creditPlaceholders, postedPlaceholders]);

  const handleAddPlaceholder = useCallback(
    (placeholder?: IPlaceholder) => {
      if (!placeholder) {
        return;
      }

      setIsPostingSheetOpen(true);

      const newlyPostedInvoices = invoicesFields.reduce((acc, curr) => {
        return Number(acc) + Number(curr.postedAmount) - Number(curr.alreadyPostedAmount);
      }, 0);

      const newlyPostedPlaceholders = placeholderFields.reduce((acc, current) => {
        return Number(acc) + Number(current.amount) - Number(current.alreadyPostedAmount);
      }, 0);

      const remaining = toFixed(amountAvailableForPosting - newlyPostedInvoices - newlyPostedPlaceholders, 2);

      if (placeholder && !placeholderFields.find((item) => item.placeholderId === placeholder.id)) {
        appendPlaceholder({
          placeholderId: placeholder.id,
          alreadyPostedAmount: 0,
          amount: remaining,
          name: placeholder.name,
          customer: undefined,
          isAlreadyPosted: false,
        });
      }
    },
    [amountAvailableForPosting, appendPlaceholder, invoicesFields, placeholderFields],
  );

  return (
    <div className=" text-sm  flex-1 flex overflow-hidden ">
      <div className=" flex flex-col flex-1 overflow-auto  ">
        <div className=" flex items-center justify-between py-4 gap-4 ">
          <InvoicesFiltersDialog
            customerSuggestions={suggestedCustomers}
            customers={customers}
            handleSubmit={handleSubmit}
            filters={invoiceFilters}
          />
          <div className={cn(' flex items-center gap-4', isPostingSheetOpen && 'pr-4')}>
            <ColumnSelector columns={tableColumns} columnOrder={columnOrder} setColumnOrder={setColumnOrder} />
            <PostAsDropDown
              handleAddPlaceholder={handleAddPlaceholder}
              creditPlaceholders={creditPlaceholdersToPost} // Pass the handleAddPlaceholder function
            />
            {!isPostingSheetOpen && (
              <Button
                icon={<ChevronsLeftIcon className={cn('w-4 h-4', isPostingSheetOpen && 'rotate-180')} />}
                iconPosition="left"
                onClick={toggleSheetOpen}
              >
                Posted({postedToCreditData?.data.length || 0})
              </Button>
            )}
          </div>
        </div>

        <InvoiceTable table={table} isLoading={isLoading} />
        <Pagination
          hasNext={!!invoicesData?.data.hasNext}
          hasPrev={!!invoicesData?.data.hasPrev}
          onPageChange={setPage}
          onRowsPerPageChange={setPageLimit}
          pageNumber={page}
          rowsPerPage={pageLimit}
          totalPages={invoicesData?.data.totalPages || 0}
        />
      </div>
      <Sheet modal={false} open={isPostingSheetOpen}>
        <SheetContent side="right" className="p-0 border min-w-[415px] bg-none border-none shadow-none">
          <div className=" bg-white h-[90%] mt-[14.3%] border relative ">
            {isPostingSheetOpen && (
              <Button size="icon" className=" absolute top-[174px] -left-4 z-50 h-7 w-7 " onClick={toggleSheetOpen}>
                <ChevronsRightIcon className="w-4 h-4" />
              </Button>
            )}
            <SelectedRows
              handleRemoveInvoice={handleRemoveInvoice}
              isLoading={isGetPostedToCreditDataLoading}
              control={control}
              totalCredit={amountAvailableForPosting}
              postedInvoices={invoicesFields}
              postedPlaceholders={placeholderFields}
              appendPlaceholder={appendPlaceholder}
              errors={errors}
              handleSubmit={handleFormSubmit}
              isDirty={isDirty}
              register={register}
              removePlaceholder={removePlaceholder}
              watch={watch}
              reset={reset}
              creditPlaceholders={creditPlaceholdersToPost}
            />
          </div>
        </SheetContent>
      </Sheet>
      {isPostingSheetOpen && <div className="  min-w-[400px] flex-2 border border-t-0 "></div>}
    </div>
  );
};

const IncludeCreditCta = ({ creditId }: { creditId: string }) => {
  return (
    <div className=" w-[500px] mx-auto flex flex-col gap-4 items-center ">
      <h1 className=" text-3xl font-semibold ">Cannot Post!</h1>
      <div className=" text-xl ">
        <p>This transaction has been marked as reversed.</p>
        <p>To post, include it back.</p>
      </div>
      <div>
        <MarkCreditAsUnReversedDialog creditIds={[creditId]} clearAll={() => {}} />
      </div>
    </div>
  );
};

const SingleCreditPage = () => {
  const { transactionId } = useParams();

  const { data: credit, isLoading } = useGetCreditById({
    creditId: transactionId as string,
    customConfig: {
      enabled: !!transactionId,
    },
  });

  const uniqueCustomersForCredit = useMemo(() => {
    return [...new Set(credit?.data.customers.map((customer) => `${customer.name}-${customer.id}`))].map((customer) => {
      const [name, id] = customer.split('-');
      return { name, id };
    });
  }, [credit?.data.customers]);

  const uniqueCustomerSuggestionsForCredit = useMemo(() => {
    return [...new Set(credit?.data.customerSuggestions.map((customer) => `${customer.name}-${customer.id}`))].map(
      (customer) => {
        const [name, id] = customer.split('-');
        return { name, id };
      },
    );
  }, [credit?.data.customerSuggestions]);

  if (isLoading) {
    return (
      <Layout className="sm:px-4 sm:py-4 px-4 py-4 h-screen overflow-hidden flex flex-col">
        <PostingCreditHeader isLoading={isLoading} />
        <CreditDetails isLoading={isLoading} />
        <div className=" text-sm ">
          <div className=" my-4 ">
            <h3 className=" font-semibold text-base ">Invoices</h3>
            <p className=" text-muted-foreground text-sm ">
              Select invoices to match with credit available for posting
            </p>
          </div>
          <InvoicesFiltersDialog filters={{}} handleSubmit={() => {}} />
          <TableSkeleton rows={10} columns={6} />
        </div>
      </Layout>
    );
  }

  if (!credit) {
    return <Navigate to="/404" replace />;
  }

  if (credit.data.isReversed) {
    return (
      <Layout className="sm:px-4 sm:py-4 px-4 py-4 h-screen overflow-hidden flex flex-col">
        <PostingCreditHeader isLoading={isLoading} />
        <CreditDetails credit={credit?.data} isLoading={isLoading} />
        <div className="  flex items-center justify-center h-full text-center">
          <IncludeCreditCta creditId={credit.data.id} />
        </div>
      </Layout>
    );
  }

  return (
    <Layout className="sm:px-4 sm:py-4 px-4 py-4 h-screen overflow-hidden flex flex-col">
      <PostingCreditHeader isLoading={isLoading} />
      <CreditDetails credit={credit?.data} isLoading={isLoading} />
      <PostingWrapper
        suggestedCustomers={uniqueCustomerSuggestionsForCredit}
        customers={uniqueCustomersForCredit}
        transactionId={credit.data.id}
        amountAvailableForPosting={credit.data.remainingAmount}
      />
    </Layout>
  );
};

export default SingleCreditPage;
