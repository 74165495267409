import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useUserContext } from '@/contexts/UserContext';
import { useGetCashflowActualDetails } from '@/hooks/api-hooks/useCashflowQuery';
import { useExportCashflowDetails } from '@/hooks/excel-export/useCashflowDetailsExport';
import { cn } from '@/lib/utils';
import { IAggregateBy, ICashflowActualDetailsTableData } from '@/types/cashflow.types';
import { Skeleton } from '@mui/material';
import {
  ColumnDef,
  ColumnPinningState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ChevronRightIcon, Loader2Icon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Empty from '../../ui/empty';
import SelectComponent from '../../ui/select-component';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table';
import { TableUnitsTabs } from '../../ui/table-unit-tabs';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../ui/tooltip';

const flattenArrayToObject = (arr: ICashflowActualDetailsTableData[]) => {
  return arr.map((item): ICashflowActualDetailsTableData => {
    return {
      ...item,
      ...item.actuals.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.dateRange.startDate]: curr.amount,
        };
      }, {}),
      children: flattenArrayToObject(item.children || []),
    };
  });
};

const reduceDataByAggregate = (
  arr: ICashflowActualDetailsTableData[],
  parentIsSum: boolean,
  isParentOpeningBalance: boolean,
  isParentLink: boolean,
  sort: boolean,
  ancestors: string[],
  categoryToAncestorsMapping: {
    [key: string]: string[];
  },
) => {
  const modifiedData = arr.map((category: ICashflowActualDetailsTableData): ICashflowActualDetailsTableData => {
    const isSum =
      !(category.name.toLowerCase() === 'opening balance' || category.name.toLowerCase() === 'closing balance') &&
      parentIsSum;
    const isOpeningBalance = category.name.toLowerCase() === 'opening balance' || isParentOpeningBalance;
    const isCurrentLink = isSum;
    const currentAncestors = [...ancestors, category.id];
    categoryToAncestorsMapping[category.id] = currentAncestors;
    return {
      ...category,
      isLink: isParentLink || isCurrentLink,
      children: reduceDataByAggregate(
        category.children || [],
        isSum,
        isOpeningBalance,
        isCurrentLink,
        true,
        currentAncestors,
        categoryToAncestorsMapping,
      ),
    };
  });

  if (!sort) {
    return modifiedData;
  }

  return modifiedData.sort((prevCategory, nextCategory) => {
    if (prevCategory.name < nextCategory.name) {
      return -1;
    }
    if (prevCategory.name > nextCategory.name) {
      return 1;
    }
    return 0;
  });
};

const DetailsTable = ({
  dateFilter,
}: {
  dateFilter: {
    startDate: Date;
    endDate: Date;
  };
}) => {
  const { companiesOfUser, activeCompanyIndex } = useUserContext();
  const [aggregateBy, setAggregateBy] = useState<IAggregateBy>('day');
  const [expansionState, setExpansionState] = useState<{
    [key: string]: number[];
  }>({});
  const { data: detailsTable, isLoading } = useGetCashflowActualDetails({
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
    aggregateBy: aggregateBy,
    companyId: companiesOfUser?.[activeCompanyIndex]?.id || '',
    customConfig: {
      enabled: !!dateFilter.startDate && !!dateFilter.endDate && !!companiesOfUser?.[activeCompanyIndex]?.id,
    },
  });

  const [unit, setUnit] = useState<'k' | 'l' | 'cr' | 'actual'>('actual');

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [searchParams] = useSearchParams();

  const handleUnitChange = (unit: string) => {
    const newUnit = unit as 'k' | 'l' | 'cr' | 'actual';
    setUnit(newUnit);
  };

  const handleSelectChange = useCallback((value: string) => {
    const newAggregateBy = value as 'day' | 'five_day' | 'week' | 'three_day';
    setAggregateBy(newAggregateBy);
  }, []);

  const data = useMemo(() => {
    const expansionState = {};
    const value = reduceDataByAggregate(detailsTable?.data || [], true, false, false, false, [], expansionState);
    setExpansionState(expansionState);
    return value;
  }, [detailsTable]);

  useEffect(() => {
    const category = searchParams.get('category');
    if (category && category !== 'summary' && expansionState[category]) {
      const state = expansionState[category].reduce(
        (acc, current) => ({
          ...acc,
          [current]: true,
        }),
        {} as { [key: string]: boolean },
      );
      setExpanded(state);
    }
  }, [expansionState, searchParams]);

  const adjustValue = useCallback(
    (value: number) => {
      let adjustedValue = value;
      if (unit === 'k') {
        adjustedValue = value / 1000;
      } else if (unit === 'l') {
        adjustedValue = value / 100000;
      } else if (unit === 'cr') {
        adjustedValue = value / 10000000;
      }
      return `${Math.abs(adjustedValue).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'INR',
      })} ${unit === 'actual' ? '' : unit}`;
    },
    [unit],
  );

  const columns: ColumnDef<ICashflowActualDetailsTableData>[] = useMemo(
    () => [
      {
        header: 'Category',
        accessorKey: 'name',
        columns: [],
        cell: ({ row, getValue }) => (
          <div
            onClick={row.getToggleExpandedHandler()}
            className="cursor-pointer flex items-center gap-2 min-w-[200px]"
            style={{
              paddingLeft: row.depth * 24,
            }}
          >
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>{getValue() as string}</TooltipTrigger>
                <TooltipContent>
                  <p className=" max-w-[200px] whitespace-break-spaces text-left ">{getValue() as string}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            {row.getCanExpand() && <ChevronRightIcon className={cn(row.getIsExpanded() && 'rotate-90', 'h-4 w-4')} />}
          </div>
        ),
      },
      ...((data?.[0]?.actuals
        ?.filter(({ dateRange }) => {
          return dayjs(dateRange.startDate).isBefore(dayjs());
        })
        .map((day) => {
          return {
            header: () => {
              const { startDate, endDate } = day.dateRange;
              return (
                <span className="text-xs">
                  {startDate !== endDate
                    ? `${dayjs(startDate).format('DD MMM')} - ${dayjs(endDate).format('DD MMM')}`
                    : dayjs(endDate).format('DD MMM')}
                </span>
              );
            },
            accessorKey: day.dateRange.startDate,
            cell: ({ getValue, row }) => {
              const value = getValue() as number;
              const { startDate, endDate } = day.dateRange;
              const href = `/dashboard/bank-transactions?startDate=${dayjs(startDate).format(
                'YYYY-MM-DD',
              )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}&categories=${row.original.id.toLowerCase()}`;

              return (
                <span className={cn(value < 0 ? 'text-red-500' : 'text-green-500', value === 0 && 'text-gray-500')}>
                  {row.original.isLink ? <Link to={href}>{adjustValue(value)}</Link> : <>{adjustValue(value)}</>}
                </span>
              );
            },
          };
        }) || []) as ColumnDef<ICashflowActualDetailsTableData>[]),
    ],
    [data, adjustValue],
  );

  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['name', 'plan', 'actual', 'deviation'],
    right: [],
  });

  const { toast } = useToast();

  const { exportExcel, isLoading: isExcelExportLoading } = useExportCashflowDetails({
    onSuccess(url: string) {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Cashflow Details.xlsx');
      document.body.appendChild(link);
      link.click();
    },
    onError() {
      toast({
        title: 'Error',
        description: 'Something went wrong',
        variant: 'destructive',
      });
    },
  });

  const flattenData = useMemo(() => {
    return flattenArrayToObject(data);
  }, [data]);

  const aggregateOptions = useMemo(() => {
    // TODO: maybe derive types from the array, if used in more than 3 places
    const options: {
      value: IAggregateBy;
      label: string;
    }[] = [
      {
        value: 'day',
        label: 'Daily',
      },
      {
        value: 'three_day',
        label: '3 Days',
      },
      {
        value: 'five_day',
        label: '5 Days',
      },
      {
        value: 'week',
        label: 'Weekly',
      },
      {
        value: 'fortnight',
        label: 'Fortnightly',
      },
      {
        value: 'month',
        label: 'Monthly',
      },
    ];

    return options;
  }, []);

  useEffect(() => {
    setAggregateBy('day');
  }, [dateFilter]);

  const handleExcelExport = useCallback(() => {
    if (data) {
      exportExcel(data);
    }
  }, [data, exportExcel]);

  const table = useReactTable({
    data: flattenData,
    columns,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => row.children,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    onColumnPinningChange: setColumnPinning,
    state: {
      columnPinning,
      expanded,
    },
  });

  if (isLoading) {
    return (
      <div className="mt-8 mb-2">
        <div className="flex justify-between items-center my-2 gap-4">
          <h2 className="text-xl font-semibold">Cashflow details</h2>
          <div className="flex items-center gap-4">
            <SelectComponent
              value={aggregateBy}
              onChange={handleSelectChange}
              options={[
                {
                  value: 'day',
                  label: 'Daily',
                },
                {
                  value: '3day',
                  label: '3 Days',
                },
                {
                  value: '5day',
                  label: '5 Days',
                },
                {
                  value: 'week',
                  label: 'Weekly',
                },
              ]}
            />
            <TableUnitsTabs value={unit} onValueChange={handleUnitChange} />
          </div>
        </div>
        <div className="py-2">
          <Table>
            <TableHeader className=" bg-gray-100">
              {new Array(1).fill(0).map((_, i) => (
                <TableRow key={i}>
                  {new Array(10).fill(0).map((_, i) => (
                    <TableHead className="px-6 min-w-[100px]" key={i}>
                      <Skeleton className="w-full h-5" />
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody className="py-4">
              {new Array(10).fill(0).map((_, i) => (
                <TableRow key={i}>
                  {new Array(10).fill(0).map((_, i) => (
                    <TableCell className="px-6 text-center" key={i}>
                      <Skeleton className="w-full h-5" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  if (!detailsTable || !detailsTable?.data?.at(0)?.actuals?.length) {
    return <Empty title="No data available" />;
  }

  return (
    <>
      <div className="flex items-center mt-8 mb-2 gap-4 justify-between">
        <h2 className="text-xl font-semibold">Cashflow details</h2>
        <div className="flex items-center gap-4">
          <Button disabled={isExcelExportLoading} className="flex items-center gap-4" onClick={handleExcelExport}>
            Export
            {isExcelExportLoading ? <Loader2Icon className="w-4 h-4 animate-spin" /> : null}
          </Button>
          <SelectComponent value={aggregateBy} onChange={handleSelectChange} options={aggregateOptions} />
          <TableUnitsTabs onValueChange={handleUnitChange} value={unit} />
        </div>
      </div>
      <div className="flex justify-start items-start mt-8">
        <div className="pb-8 flex-1">
          <Table>
            <TableHeader className=" bg-gray-100">
              {table.getLeftHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead className="px-6 min-w-[200px]" key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody className="py-4">
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getLeftVisibleCells().map((cell) => (
                    <TableCell
                      className="px-4 text-center truncate overflow-ellipsis min-w-[200px] capitalize"
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="overflow-auto pb-6">
          <Table className="flex-1">
            <TableHeader className=" bg-gray-100 ">
              {table.getCenterHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead className="px-6 min-w-[180px] max-w-[200px]" key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody className="py-4">
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getCenterVisibleCells().map((cell) => (
                    <TableCell
                      className="px-6 truncate min-w-[180px] max-w-[200px] capitalize whitespace-nowrap overflow-ellipsis"
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default DetailsTable;
