import { IOptions } from '@/types/common.types';
import { getClassNamesForSelectPurpose, getStylesForSelect } from '@/utils/getStylesForSelect';
import { useEffect, useMemo, useState } from 'react';
import { RefCallBack } from 'react-hook-form';
import { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';

const MultiValueInput = ({
  value,
  onChange,
  placeholder,
  ref,
  autoFocus = false,
}: {
  value: string[];
  onChange: (_: string[]) => void;
  placeholder?: string;
  ref?: RefCallBack;
  autoFocus?: boolean;
}) => {
  const [inputValue, setInputValue] = useState('');
  const [componentValue, setComponentValue] = useState<string[]>([]);

  const INPUT_ID = 'multi-value-input';

  const handleValueChange = (
    value: MultiValue<{
      label: string;
      value: string;
    }>,
  ) => {
    setComponentValue(value.map((v) => v.value));
    onChange(value.map((v) => v.value));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!inputValue) return;

    switch (e.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
      case ',':
        setComponentValue([...componentValue, inputValue]);
        onChange([...componentValue, inputValue]);

        setInputValue('');
        e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    // @ts-expect-error event error
    if (e.target?.id === INPUT_ID) {
      const emails = e.clipboardData
        .getData('text/plain')
        .split(',')
        .map((invoiceNumber) => {
          return invoiceNumber.trim();
        })
        .filter(Boolean);

      setComponentValue([...componentValue, ...emails]);
      onChange([...componentValue, ...emails]);
    }

    e.preventDefault();
  };

  const selectClasses = useMemo(() => {
    return getClassNamesForSelectPurpose();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false | true, IOptions>();
  }, []);

  useEffect(() => {
    setComponentValue(value);
  }, [value]);

  return (
    <div onPaste={handlePaste}>
      <AsyncSelect
        autoFocus={autoFocus}
        id={INPUT_ID}
        inputId={INPUT_ID}
        className=" min-w-[150px] w-full text-sm shadow-sm"
        inputValue={inputValue}
        ref={ref}
        isMulti={true}
        onInputChange={(newValue, action) => {
          if (action.action === 'input-change') {
            setInputValue(newValue);
          }
        }}
        components={{
          DropdownIndicator: () => null,
        }}
        onChange={handleValueChange}
        menuIsOpen={false}
        onKeyDown={handleKeyDown}
        value={componentValue.map((v) => ({ label: v, value: v }))}
        placeholder={placeholder || 'Enter comma seperated values'}
        styles={selectStyles}
        classNames={selectClasses}
      />
    </div>
  );
};

export default MultiValueInput;
