import { cn } from '@/lib/utils';
import React from 'react';

export const Layout = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return <div className={cn('sm:px-16 sm:py-8 px-4 py-8', className)}>{children}</div>;
};

export const TightLayout = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return <div className={cn('sm:px-16 sm:py-2 px-4 py-2 pb-8', className)}>{children}</div>;
};
