export const mockApiReturn = async <T>(data: T, time: number, isError = false) => {
  return new Promise<T>((resolve, reject) => {
    setTimeout(() => {
      if (isError) {
        reject(new Error('Something went wrong'));
      }
      resolve(JSON.parse(JSON.stringify({ ...data })));
    }, time);
  });
};
