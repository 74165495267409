import { Section, SectionTypes, templates } from '@/utils/email-builder/sections';
import { create } from 'zustand';

const createNewId = () => {
  return `${Math.random() * 100000}`;
};

type State = {
  templateData: {
    name: string;
    description: string;
  };
  isDirty: boolean;
  subjectHtml: string;
  sections: Record<string, Section<SectionTypes>>;
  pageSectionsOrder: string[];
  addSectionAtIndex: (_: string, __: number) => void;
  removeSectionAtIndex: (_: number) => void;
  swapSections: (_: number, __: number) => void;
  selectedSection?: string;
  setSelectedSection: (_: string) => void;
  updateSection: (_: Section<SectionTypes>) => void;
  updateTableCell: ({
    // eslint-disable-next-line no-unused-vars
    sectionId,
    // eslint-disable-next-line no-unused-vars
    rowIndex,
    // eslint-disable-next-line no-unused-vars
    fieldName,
    // eslint-disable-next-line no-unused-vars
    value,
  }: {
    sectionId: string;
    rowIndex: number;
    fieldName: string;
    value: string;
  }) => void;
  updateTableHeaderLabel: ({
    // eslint-disable-next-line no-unused-vars
    sectionId,
    // eslint-disable-next-line no-unused-vars
    columnId,
    // eslint-disable-next-line no-unused-vars
    value,
  }: {
    sectionId: string;
    columnId: string;
    value: string;
  }) => void;
  updateTemplateData: (_: { name: string; description: string }) => void;
  loadData: (_: {
    sections: Record<string, Section<SectionTypes>>;
    subjectHtml: string;
    name: string;
    description: string;
    pageSectionsOrder: string[];
  }) => void;
  updateSubjectHtml: (_: string) => void;
};

function arrayMove<T>(array: T[], from: number, to: number): T[] {
  const newArray = array.slice();
  newArray.splice(to < 0 ? newArray.length + to : to, 0, newArray.splice(from, 1)[0]);

  return newArray;
}

export const useStore = create<State>((set) => ({
  isDirty: false,
  sections: {},
  subjectHtml: '',
  templateData: {
    name: '',
    description: '',
  },
  selectedSection: undefined,
  pageSectionsOrder: [],
  setSelectedSection: (sectionId) => {
    set((state) => ({
      ...state,
      selectedSection: sectionId,
    }));
  },
  addSectionAtIndex: (templateId, index) => {
    set((state) => {
      const section = templates[templateId];
      if (!section) {
        return state;
      }
      const sectionId = createNewId();
      const sections = {
        ...state.sections,
        [sectionId]: {
          ...section,
          id: sectionId,
        },
      };
      const pageSectionsOrder = [...state.pageSectionsOrder];
      pageSectionsOrder.splice(index, 0, sectionId);
      return { pageSectionsOrder, sections, isDirty: true };
    });
  },
  removeSectionAtIndex: (index) => {
    set((state) => {
      const pageSectionsOrder = [...state.pageSectionsOrder];
      pageSectionsOrder.splice(index, 1);
      return { pageSectionsOrder, isDirty: true };
    });
  },
  swapSections: (fromIndex: number, toIndex: number) => {
    set((state) => {
      const pageSectionsOrder = arrayMove(state.pageSectionsOrder, fromIndex, toIndex);
      return { pageSectionsOrder, isDirty: true };
    });
  },
  updateSection(section) {
    set((state) => {
      const sections = {
        ...state.sections,
        [section.id]: {
          ...section,
        },
      };

      return { sections, isDirty: true };
    });
  },
  updateTableCell: ({ rowIndex, fieldName, value, sectionId }) => {
    set((state) => {
      if (state.sections[sectionId].type !== 'table') return {};

      const currentSection = state.sections[sectionId] as Section<'table'>;
      const rows = currentSection.content.content.rows.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [fieldName]: value,
          };
        }
        return row;
      });

      const sections = {
        ...state.sections,
        [sectionId]: {
          ...currentSection,
          content: {
            ...currentSection.content,
            content: {
              ...currentSection.content.content,
              rows,
            },
          },
        },
      };

      return { sections, isDirty: true };
    });
  },

  updateTemplateData({ name, description }: { name: string; description: string }) {
    set((state) => {
      return {
        ...state,
        templateData: {
          name,
          description,
        },
        isDirty: true,
      };
    });
  },

  loadData(data) {
    set((state) => {
      return {
        ...state,
        templateData: {
          name: data.name,
          description: data.description,
        },
        sections: data.sections,
        pageSectionsOrder: data.pageSectionsOrder,
        subjectHtml: data.subjectHtml,
        isDirty: false,
      };
    });
  },

  updateTableHeaderLabel({ sectionId, columnId, value }) {
    set((state) => {
      if (state.sections[sectionId].type !== 'table') return {};

      const currentSection = state.sections[sectionId] as Section<'table'>;

      const newColumns = currentSection.content.content.columns.map((column) => {
        if (column.columnId === columnId) {
          return {
            ...column,
            label: value,
          };
        }
        return column;
      });

      const sections = {
        ...state.sections,
        [sectionId]: {
          ...currentSection,
          content: {
            ...currentSection.content,
            content: {
              ...currentSection.content.content,
              columns: newColumns,
            },
          },
        },
      };

      return { sections, isDirty: true };
    });
  },
  updateSubjectHtml(subjectHtml) {
    return set((state) => ({ ...state, subjectHtml, isDirty: true }));
  },
}));
