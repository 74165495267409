import { dataAxiosInstance } from '@/lib/axios';
import { AgeingReportFilter } from '@/types/ageing.types';
import dayjs from 'dayjs';

export const getAgeingReportExport = async ({ fromDate, toDate, customerIds }: Partial<AgeingReportFilter>) => {
  let url = `/ageing/summary?action=EXPORT`;

  if (fromDate) {
    url += `&fromDate=${dayjs(fromDate).format('YYYY-MM-DD')}`;
  }

  if (toDate) {
    url += `&toDate=${dayjs(toDate).format('YYYY-MM-DD')}`;
  }

  if (customerIds?.length) {
    url += customerIds.map((customerId: string) => `&customerId=${customerId}`).join('');
  }

  const result = await dataAxiosInstance.get(url);
  return result.data;
};
