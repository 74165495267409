import { dataAxiosInstance } from '@/lib/axios';

export const getUserDetails = async () => {
  const response = await dataAxiosInstance.get('/user/details');
  return response.data;
};

export const postAttachPanToUser = async ({ pan }: { pan: string }) => {
  const response = await dataAxiosInstance.post('/user/attachPan', {
    pan,
  });
  return response.data;
};
