import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import DropAttachment from '@/components/ui/drop-attachment';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../ui/tooltip';

import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import { useToast } from '@/components/ui/use-toast';
import { useAttachmentUpload } from '@/hooks/api-hooks/useAttachmentsQuery';
import { IOptions } from '@/types/common.types';
import { useQueryClient } from '@tanstack/react-query';
import { FileCheck2Icon, Loader2Icon, Trash2, XIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import AttachmentPurposeDropdown from '../AttachmentPurposeType';

export function AddAttachments({
  isUploadModalOpen,
  open,
  setOpen,
  referenceObjectId,
  referenceObjectType,
}: {
  isUploadModalOpen: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  referenceObjectId: string;
  referenceObjectType: string;
}) {
  const [uploadedfile, setUploadedFile] = useState<File | null>(); // this will store the uploaded file by user

  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);
  const [purpose, setPurpose] = useState<IOptions | undefined>();
  const queryClient = useQueryClient();

  const { toast } = useToast();
  const defaultValues: { file: null | File; purpose: IOptions | undefined } = {
    file: null,
    purpose: undefined,
  };
  const { mutate: attachmentUpload, isPending } = useAttachmentUpload({
    customConfig: {
      onSuccess: () => {
        try {
          methods.setValue('file', null);
          setPurpose(undefined);
          setNextButtonDisabled(true);
          setOpen(false);
          toast({
            title: 'Success',
            description: 'Attachment created successfully',
          });
          queryClient.invalidateQueries({
            queryKey: ['getAttachmentsWithFilters'],
          });
        } catch (error) {
          toast({
            title: 'Error',
            description: 'Error occurred in parsing file',
            variant: 'destructive',
          });
        }
      },
      onError: (e) => {
        toast({
          title: 'Error',
          description: e.response?.data.message ?? 'Error occured while uploading attachment',
          variant: 'destructive',
        });
        setOpen(true);
      },
    },
  });
  const handleFormSubmit = () => {
    if (uploadedfile) {
      attachmentUpload({
        file: uploadedfile,
        referenceObjectId: referenceObjectId,
        referenceObjectType: referenceObjectType,
        purpose: purpose?.value ?? 'GENERAL',
      });
    }
  };

  const deleteFile = () => {
    methods.setValue('file', null);
    setNextButtonDisabled(true);
  };
  const handleCancel = () => {
    methods.setValue('file', null);
    setNextButtonDisabled(true);
    setOpen(false);
  };
  function handleOnDrop(acceptedFiles: FileList | null) {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const allowedTypes = [
        { name: 'csv', types: ['text/csv'] },
        { name: 'jpg', types: ['image/jpeg'] },
        { name: 'png', types: ['image/png'] },
        { name: 'txt', types: ['text'] },
        { name: 'jpeg', types: ['image/jpeg'] },
        { name: 'pdf', types: ['application/pdf'] },
        { name: 'xls', types: ['application/vnd.ms-excel'] },
        { name: 'xlsx', types: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] },
        { name: 'docx', types: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] },
        {
          name: 'excel',
          types: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        },
      ];

      const fileType = allowedTypes.find((allowedType) =>
        allowedType.types.find((type) => type === acceptedFiles[0].type),
      );
      if (!fileType) {
        methods.setValue('file', null);
        methods.setError('file', {
          message: 'File type is not valid',
          type: 'typeError',
        });
      } else {
        methods.setValue('file', acceptedFiles[0]);
        methods.getValues('file') && setUploadedFile(methods.getValues('file'));
        setNextButtonDisabled(false);
        methods.clearErrors('file');
      }
    } else {
      methods.setValue('file', null);
      methods.setError('file', {
        message: 'File is required',
        type: 'typeError',
      });
    }
  }
  const methods = useForm({
    defaultValues,
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
  });
  const handlePurposeSelect = (newPurpose: IOptions | undefined) => {
    if (newPurpose) {
      setPurpose(newPurpose);
    }
  };

  const purposeTypeOptions = useMemo<
    {
      label: string;
      value: string;
    }[]
  >(
    () => [
      {
        label: 'Commercial Agreement',
        value: 'COMMERCIAL_AGREEMENT',
      },
      {
        label: 'Commercial Agreement Supporting',
        value: 'COMMERCIAL_AGREEMENT_SUPPORTING',
      },
      {
        label: 'Delivery Document',
        value: 'DELIVERY_DOCUMENT',
      },
      {
        label: 'Dispute',
        value: 'DISPUTE',
      },
      {
        label: 'Dispute Resolution',
        value: 'DISPUTE_RESOLUTION',
      },
      {
        label: 'Enquiry',
        value: 'ENQUIRY',
      },
      {
        label: 'Enquiry Resolution',
        value: 'ENQUIRY_RESOLUTION',
      },
      {
        label: 'General',
        value: 'GENERAL',
      },
      {
        label: 'Invoice for buyer',
        value: 'INVOICE_FOR_BUYER',
      },
      {
        label: 'Invoice Supporting',
        value: 'INVOICE_SUPPORTING',
      },
      {
        label: 'Legal',
        value: 'LEGAL',
      },
      {
        label: 'Payment Proof',
        value: 'PAYMENT_PROOF',
      },
      {
        label: 'Purchase Order',
        value: 'PURCHASE_ORDER',
      },

      {
        label: 'Sales Order',
        value: 'SALES_ORDER',
      },
      {
        label: 'Tax Document',
        value: 'TAX_DOCUMENT',
      },
    ],
    [],
  );
  return (
    isUploadModalOpen && (
      <Dialog
        open={open}
        onOpenChange={(open) => {
          methods.clearErrors('file');
          methods.setValue('file', null);
          setOpen(open);
        }}
      >
        <DialogContent className="w-[600px] min-h-[356px]  border-gray border-2 ">
          <Card className="border-none shadow-none ">
            <div className="h-[60px] px-4 flex border-b-2 pt-4  pb-4 justify-between">
              <div>
                <CardTitle className=" text-xl ">Add Attachment</CardTitle>
              </div>
              <div>
                <XIcon
                  className="w-[20px] h-[20px] cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </div>
            </div>
            <CardContent className="px-0" style={{ paddingBottom: '16px' }}>
              <FormProvider {...methods}>
                <form
                  className="flex flex-col  justify-center w-full"
                  onSubmit={methods.handleSubmit(handleFormSubmit)}
                  noValidate
                  autoComplete="off"
                >
                  <div className="h-[210px] mb-2">
                    <div className="bg-[#EEF5FF] w-full pt-2 pb-6 px-4 ">
                      <FormField
                        control={methods.control}
                        name="file"
                        render={({ field }) => (
                          <FormItem className="w-full">
                            <FormControl>
                              <DropAttachment
                                {...field}
                                handleOnDrop={handleOnDrop}
                                classNameWrapper="border-[#1554F6] text-[#1554F6] h-[56px]"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>

                    <div className="p-2 flex gap-2 w-[280px] ">
                      <div className="m-auto">
                        <Label>Purpose</Label>
                      </div>
                      <AttachmentPurposeDropdown
                        onChange={handlePurposeSelect}
                        options={purposeTypeOptions}
                        placeholder="Select"
                      />
                    </div>
                    <div className="text-[12px] font-normal text-left ml-4">
                      Supported format: .csv, .docx, .jpg, .jpeg, .pdf, .png, .txt, .xls <br />
                      Max file size: 10MB
                    </div>
                  </div>

                  {methods.watch('file') && (
                    <div className="flex justify-between w-full">
                      <div className="flex  gap-3 p-2 relative">
                        <FileCheck2Icon className="h-4 w-4" />
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger className="cursor-pointer text-xs font-medium  w-[400px] text-left truncate  whitespace-nowrap overflow-ellipsis">
                              {methods.watch('file')?.name}
                            </TooltipTrigger>
                            <TooltipContent>{methods.watch('file')?.name}</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                      <Trash2 className="h-4 w-4 cursor-pointer mt-auto mb-auto mr-4" onClick={deleteFile} />
                    </div>
                  )}
                  <div className="w-full px-4 flex justify-end gap-4 pt-4 border-t-2 m-auto">
                    <Button onClick={handleCancel} variant="outline" type="button">
                      Cancel
                    </Button>

                    {!isPending ? (
                      <Button type="submit" disabled={nextButtonDisabled}>
                        Upload
                      </Button>
                    ) : (
                      <Loader2Icon className="w-8 h-8 mt-[3px] animate-spin" />
                    )}
                  </div>
                </form>
              </FormProvider>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    )
  );
}
