import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  useDeleteStateById,
  useGetStateById,
  usePostCreateEmailAction,
  usePostUpdateWaitState,
} from '@/hooks/api-hooks/useWorkflowQuery';
import { cn } from '@/lib/utils';
import { IState, IWaitStateProperties } from '@/types/workflow.type';
import { autoUpdate, useFloating } from '@floating-ui/react';
import { AlertTriangleIcon, Loader2Icon, MailIcon, PlusIcon, Trash2Icon } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';

import ToolTipCell from '@/components/Table/ToolTipCell';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import { useUserContext } from '@/contexts/UserContext';
import { ActionSlot } from './action-blocks/action-blocks';
import DisplayDateCondition from './wait-block/DisplayDateCondition';

const WaitBlock = (props: NodeProps<IState<'WAIT'>>) => {
  const { data: waitStateResponse, isLoading } = useGetStateById({
    stateId: props.id,
    customConfig: {
      enabled: !!props.id,
    },
  });

  const { companiesOfUser, activeCompanyIndex } = useUserContext();

  const { toast } = useToast();

  const { mutateAsync: createEmailAction, isPending: isCreateEmailActionPending } = usePostCreateEmailAction({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Unable to create email action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const { mutate: updateState, isPending: isUpdatePending } = usePostUpdateWaitState({
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data?.message || 'Unable to create email action. Please try again.',
          variant: 'destructive',
        });
      },
    },
  });

  const newActionCreatePending = useMemo(() => {
    return isCreateEmailActionPending || isUpdatePending;
  }, [isCreateEmailActionPending, isUpdatePending]);

  const deleteButtonUpdateTimeout = useRef<number>();
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);
  const { refs: deleteButtonRefs, floatingStyles: deleteButtonFloatingStyles } = useFloating({
    placement: 'right-start',
    whileElementsMounted: autoUpdate,
  });

  const handleRootDivMouseEnter = useCallback(() => {
    clearTimeout(deleteButtonUpdateTimeout.current);
    deleteButtonUpdateTimeout.current = setTimeout(() => {
      setIsDeleteButtonVisible(true);
    }, 25);
  }, []);
  const handleRootDivMouseLeave = useCallback(() => {
    clearTimeout(deleteButtonUpdateTimeout.current);
    deleteButtonUpdateTimeout.current = setTimeout(() => {
      setIsDeleteButtonVisible(false);
    }, 25);
  }, []);

  const handleAddEmail = () => {
    if (!waitState?.workflowId || !companiesOfUser[activeCompanyIndex]?.id) return;

    createEmailAction({
      email: {
        companyId: companiesOfUser[activeCompanyIndex].id,
        workflowId: waitState.workflowId,
      },
      workflowId: waitState.workflowId,
    }).then((returnedData) => {
      updateState({
        stateId: waitState.id,
        fields: ['actions'],
        values: {
          actions: [...(waitState.description as IWaitStateProperties).actions, returnedData.data.id],
        },
      });
    });
  };

  const { mutate: deleteState, isPending } = useDeleteStateById({
    stateId: props.id,
    workflowId: waitStateResponse?.data.workflowId || '',
  });

  const timeout = useRef<number>();
  const [visible, setVisible] = useState(false);
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
  });

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(true);
    }, 25);
  }, []);
  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(false);
    }, 25);
  }, []);

  const handleDeleteState = useCallback(() => {
    deleteState();
  }, [deleteState]);

  const waitState = useMemo(() => {
    return waitStateResponse?.data || null;
  }, [waitStateResponse]);

  if (isLoading) {
    return (
      <>
        <div>
          <Handle id="default" type="target" position={Position.Top} />
          <h3 className=" text-muted-foreground/80 text-sm mb-1 ">Wait</h3>
          <Card className=" rounded-md  ">
            <CardContent className=" py-4 w-[350px] text-sm ">
              <div className=" mb-4 ">
                {new Array(2).fill(0).map((_, i) => (
                  <div key={i} className=" flex items-center gap-2 border-l-4 px-2 border-muted-foreground/20 ">
                    <div className=" bg-muted-foreground/10 p-2 rounded-md ">
                      <Skeleton className=" h-8 w-8 " />
                    </div>
                    <div className=" w-ful flex flex-col gap-1 flex-1 ">
                      <div className=" w-full flex items-end gap-2 ">
                        <Skeleton className=" w-[80px] h-4 " />
                        <Skeleton className="flex-1 h-4" />
                      </div>
                      <div className=" w-full flex items-start gap-2">
                        <Skeleton className=" w-[80px] h-4 " />
                        <Skeleton className="flex-1 h-4" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
          <Handle id="default" type="source" position={Position.Bottom} />
        </div>
      </>
    );
  }
  if (!waitState) {
    return null;
  }

  return (
    <>
      <div
        onMouseEnter={handleRootDivMouseEnter}
        onMouseLeave={handleRootDivMouseLeave}
        ref={deleteButtonRefs.setReference}
      >
        {!!waitStateResponse?.warnings?.length && waitStateResponse.warnings.length > 0 && (
          <div className=" absolute -left-11 -top-6 text-destructive ">
            <ToolTipCell value={<AlertTriangleIcon className=" w-4 h-4 " />}>
              <div className=" flex flex-col gap-1 ">
                {waitStateResponse.warnings.map((w, index) => (
                  <div key={w}>
                    {index + 1}. {w}
                  </div>
                ))}
              </div>
            </ToolTipCell>
          </div>
        )}
        <h3 className=" text-muted-foreground/80 text-sm mb-1 absolute -top-6 ">{waitStateResponse?.data.label}</h3>
        <Handle id="default" type="target" position={Position.Top} />
        <Handle id="goto-target" type="target" position={Position.Right} />
        <div
          ref={deleteButtonRefs.setFloating}
          style={deleteButtonFloatingStyles}
          className={cn('  px-1 ', isDeleteButtonVisible ? 'opacity-100' : 'opacity-0')}
        >
          <Button
            disabled={isPending}
            onClick={handleDeleteState}
            className=" text-destructive   bg-white "
            variant="outline"
            size="icon"
          >
            {isPending ? <Loader2Icon className="w-3 h-3 animate-spin" /> : <Trash2Icon className="w-3 h-3" />}
          </Button>
        </div>
        <Card className=" rounded-md  ">
          <CardContent className=" py-4 w-[350px] text-sm ">
            <div
              className=" mb-4 "
              ref={refs.setReference}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className=" py-2 ">
                <div className=" ">
                  <DisplayDateCondition
                    nodeId={waitState.id}
                    nodeType="WAIT"
                    catIndex={0}
                    conditionId={(waitState.description as IWaitStateProperties).dateCondition}
                  />
                </div>
                <div className=" flex flex-col gap-2 py-1  ">
                  {(waitState.description as IWaitStateProperties).actions.map((action) => (
                    <div key={action} className=" border-l-4 px-2 border-muted-foreground/20 ">
                      <ActionSlot
                        actionId={action}
                        catIndex={0}
                        nodeType="WAIT"
                        stateId={waitState.id}
                        workflowId={waitState.workflowId}
                      />
                    </div>
                  ))}
                  {newActionCreatePending && (
                    <div className=" flex items-center gap-2 border-l-4 px-2 border-muted-foreground/20 ">
                      <div className=" bg-muted-foreground/10 p-2 rounded-md ">
                        <MailIcon className=" h-8 w-8 " />
                      </div>
                      <div className=" w-ful flex flex-col gap-1 flex-1 ">
                        <div className=" w-full flex items-end gap-2 ">
                          <span className=" w-[80px] ">From:</span>
                          <Skeleton className="flex-1 h-4" />
                        </div>
                        <div className=" w-full flex items-start gap-2">
                          <span className=" w-[80px] ">Template:</span>
                          <Skeleton className="flex-1 h-4" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {(waitState.description as IWaitStateProperties).actions.length === 0 && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button className=" border-dashed text-blue-700 w-full flex items-center gap-2 " variant="outline">
                      <PlusIcon className="w-3 h-3" />
                      Add Action
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className=" w-[250px] ">
                    <DropdownMenuGroup>
                      <DropdownMenuItem onClick={handleAddEmail}>Email</DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
              {(waitState.description as IWaitStateProperties).actions.length > 0 && (
                <div
                  ref={refs.setFloating}
                  style={floatingStyles}
                  className={cn('w-[90%] text-center', visible ? 'opacity-100' : 'opacity-0')}
                >
                  <div className=" border-b-4 border-blue-300 relative ">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button className=" absolute -translate-x-1/2 -bottom-2 w-4 h-4 text-white " size="icon">
                          <PlusIcon className="w-3 h-3" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className=" w-[250px] ">
                        <DropdownMenuGroup>
                          <DropdownMenuItem onClick={handleAddEmail}>Email</DropdownMenuItem>
                        </DropdownMenuGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
        <Handle id="default" type="source" position={Position.Bottom} />
      </div>
    </>
  );
};

export default WaitBlock;
