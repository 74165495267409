import { Layout } from '@/components/shared/Layout';
import { Button } from '@/components/ui/button';
import Heading from '@/components/ui/heading';
import WorkflowList from '@/components/workflow/list/WorkflowsTable';
import { PlusIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

const WorkflowListPage = () => {
  return (
    <Layout>
      <Heading
        title="Workflows"
        description="Manage your workflows here"
        cta={
          <Link to="/workflow/create/define">
            <Button className=" flex items-center gap-2 ">
              <PlusIcon className=" w-4 h-4 " />
              Create Workflow
            </Button>
          </Link>
        }
      />
      <WorkflowList />
    </Layout>
  );
};

export default WorkflowListPage;
