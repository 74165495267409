import { dataAxiosInstance } from '@/lib/axios';
import { IApiResponse } from '@/types/common.types';
import {
  IApproveRejectResponse,
  IApproveWriteOffRequest,
  ICancelWriteOffRequest,
  ICreateWriteOffRequest,
  IGetAllWriteOffRequest,
  IGetWriteOffByInvoiceIdRequest,
  IRejectWriteOffRequest,
  WriteOffApprovalStatus,
} from '@/types/write-off.types';
import dayjs from 'dayjs';

export const createWriteOff = async (request: ICreateWriteOffRequest) => {
  const result = await dataAxiosInstance.post('/writeoff', request);
  return result.data;
};

export const approveWriteOff = async (request: IApproveWriteOffRequest) => {
  const result = await dataAxiosInstance.post('/writeoff/approve', request);
  return result.data;
};

export const cancelWriteOff = async (request: ICancelWriteOffRequest) => {
  const result = await dataAxiosInstance.post('/writeoff/cancel', request);
  return result.data;
};

export const rejectWriteOff = async (
  request: IRejectWriteOffRequest,
): Promise<IApiResponse<IApproveRejectResponse>> => {
  const result = await dataAxiosInstance.post('/writeoff/reject', request);
  return result.data;
};

export const getWriteOffByInvoiceId = async (request: IGetWriteOffByInvoiceIdRequest) => {
  const approvalListQuery: string = request.approvalStatusList.reduce(
    (acc: string, curr: WriteOffApprovalStatus, index: number) => {
      if (index === request.approvalStatusList.length - 1) return acc + curr;
      else return acc + curr + '&approvalStatus=';
    },
    '&approvalStatus=',
  );
  const result = await dataAxiosInstance.get(`/writeoff/invoice?invoiceId=${request.invoiceId}${approvalListQuery}`);
  return result.data;
};

export const getAllWriteOffs = async (request: IGetAllWriteOffRequest) => {
  const customerIdQuery: string =
    request.customerIdList && request.customerIdList?.length !== 0
      ? request.customerIdList.reduce((acc: string, curr: string, index: number) => {
          if (index === request.customerIdList!.length - 1) return acc + curr;
          else return acc + curr + '&customerId=';
        }, '&customerId=')
      : '';

  const approvalListQuery: string =
    request.approvalStatusList && request.approvalStatusList?.length !== 0
      ? request.approvalStatusList.reduce((acc: string, curr: WriteOffApprovalStatus, index: number) => {
          if (index === request.approvalStatusList!.length - 1) return acc + curr;
          else return acc + curr + '&approvalStatus=';
        }, '&approvalStatus=')
      : '';

  const requesterIdListQuery: string =
    request.requesterIdList && request.requesterIdList?.length !== 0
      ? request.requesterIdList.reduce((acc: string, curr: string, index: number) => {
          if (index === request.requesterIdList!.length - 1) return acc + curr;
          else return acc + curr + '&requesterIds=';
        }, '&requesterIds=')
      : '';

  const invoiceStartDate: string = request.invoiceStartDate
    ? `&invoiceStartDate=${dayjs(request.invoiceStartDate).format('YYYY-MM-DD')}`
    : '';
  const invoiceEndDate: string = request.invoiceEndDate
    ? `&invoiceEndDate=${dayjs(request.invoiceEndDate).format('YYYY-MM-DD')}`
    : '';
  const writeOffStartDate: string = request.writeOffStartDate
    ? `&writeOffStartDate=${dayjs(request.writeOffStartDate).format('YYYY-MM-DD')}`
    : '';
  const writeOffEndDate: string = request.writeOffEndDate
    ? `&writeOffEndDate=${dayjs(request.writeOffEndDate).format('YYYY-MM-DD')}`
    : '';
  const page: number = request.page;
  const pageSize: number = request.pageSize;

  const result = await dataAxiosInstance.get(
    `/writeoff/filter?page=${page}&pageSize=${pageSize}${invoiceStartDate}${invoiceEndDate}${writeOffStartDate}${writeOffEndDate}${customerIdQuery}${approvalListQuery}${requesterIdListQuery}`,
  );
  return result.data;
};

export const checkIfUserIsApprover = async () => {
  const result = await dataAxiosInstance.get('/writeoff/isApprover');
  return result.data;
};

export const getAllWriteOffsSummary = async (request: IGetAllWriteOffRequest) => {
  const customerIdQuery: string =
    request.customerIdList && request.customerIdList?.length !== 0
      ? request.customerIdList.reduce((acc: string, curr: string, index: number) => {
          if (index === request.customerIdList!.length - 1) return acc + curr;
          else return acc + curr + '&customerId=';
        }, '&customerId=')
      : '';

  const approvalListQuery: string =
    request.approvalStatusList && request.approvalStatusList?.length !== 0
      ? request.approvalStatusList.reduce((acc: string, curr: WriteOffApprovalStatus, index: number) => {
          if (index === request.approvalStatusList!.length - 1) return acc + curr;
          else return acc + curr + '&approvalStatus=';
        }, '&approvalStatus=')
      : '';

  const invoiceStartDate: string = request.invoiceStartDate
    ? `&invoiceStartDate=${dayjs(request.invoiceStartDate).format('YYYY-MM-DD')}`
    : '';
  const invoiceEndDate: string = request.invoiceEndDate
    ? `&invoiceEndDate=${dayjs(request.invoiceEndDate).format('YYYY-MM-DD')}`
    : '';
  const writeOffStartDate: string = request.writeOffStartDate
    ? `&writeOffStartDate=${dayjs(request.writeOffStartDate).format('YYYY-MM-DD')}`
    : '';
  const writeOffEndDate: string = request.writeOffEndDate
    ? `&writeOffEndDate=${dayjs(request.writeOffEndDate).format('YYYY-MM-DD')}`
    : '';

  const result = await dataAxiosInstance.get(
    `/writeoff/summary/filter?${invoiceStartDate}${invoiceEndDate}${writeOffStartDate}${writeOffEndDate}${customerIdQuery}${approvalListQuery}`,
  );
  return result.data;
};

export const sendMailForWriteOff = async (request: IGetAllWriteOffRequest) => {
  const customerIdQuery: string =
    request.customerIdList && request.customerIdList?.length !== 0
      ? request.customerIdList.reduce((acc: string, curr: string, index: number) => {
          if (index === request.customerIdList!.length - 1) return acc + curr;
          else return acc + curr + '&customerId=';
        }, '&customerId=')
      : '';

  const approvalListQuery: string =
    request.approvalStatusList && request.approvalStatusList?.length !== 0
      ? request.approvalStatusList.reduce((acc: string, curr: WriteOffApprovalStatus, index: number) => {
          if (index === request.approvalStatusList!.length - 1) return acc + curr;
          else return acc + curr + '&approvalStatus=';
        }, '&approvalStatus=')
      : '';

  const invoiceStartDate: string = request.invoiceStartDate
    ? `&invoiceStartDate=${dayjs(request.invoiceStartDate).format('YYYY-MM-DD')}`
    : '';
  const invoiceEndDate: string = request.invoiceEndDate
    ? `&invoiceEndDate=${dayjs(request.invoiceEndDate).format('YYYY-MM-DD')}`
    : '';
  const writeOffStartDate: string = request.writeOffStartDate
    ? `&writeOffStartDate=${dayjs(request.writeOffStartDate).format('YYYY-MM-DD')}`
    : '';
  const writeOffEndDate: string = request.writeOffEndDate
    ? `&writeOffEndDate=${dayjs(request.writeOffEndDate).format('YYYY-MM-DD')}`
    : '';

  const result = await dataAxiosInstance.post(
    `/email/writeOff?${invoiceStartDate}${invoiceEndDate}${writeOffStartDate}${writeOffEndDate}${customerIdQuery}${approvalListQuery}`,
  );
  return result.data.data;
};
export const getRequesterSuggestions = async (query: string) => {
  const result = await dataAxiosInstance.get(`/writeoff/requester/suggestions?nameContains=${query.trim()}`);
  return result.data;
};
