import CustomersTable from '@/components/customers/CustomersTable';
import CriteriaItem from '@/components/segments/CriteriaItem';
import OwnerName from '@/components/segments/OwnerName';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Section } from '@/components/ui/page-section-card';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import { usePostCreateSegment } from '@/hooks/api-hooks/useSegmentQuery';
import { cn } from '@/lib/utils';
import { ICustomerFilters } from '@/types/customer.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import dayjs from 'dayjs';
import { Loader2Icon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

const DisplaySearchQuery = ({ filters }: { filters: ICustomerFilters }) => {
  return (
    <div className=" py-2 flex-col flex gap-4 text-sm ">
      {filters.identifierValue && filters.identifierType && (
        <CriteriaItem
          label={`Customer ${
            filters.identifierType === 'CUSTOMER_NAME'
              ? 'Name'
              : filters.identifierType === 'BUYER_ID'
                ? 'Buyer Id'
                : filters.identifierType
          }`}
          value={filters.identifierValue}
          operator={filters.identifierType === 'CUSTOMER_NAME' ? 'Contains' : '='}
        />
      )}
      {filters.dateType && filters.dateRange?.startDate && filters.dateRange?.endDate && (
        <CriteriaItem
          label={filters.dateType.split('_').join(' ')}
          value={`${dayjs(filters.dateRange.startDate).format('DD MMM YYYY')} - ${dayjs(
            filters.dateRange.endDate,
          ).format('DD MMM YYYY')}`}
          operator={`Between`}
        />
      )}
      {filters.amountType &&
        filters.maxAmount !== undefined &&
        filters.minAmount !== undefined &&
        filters.maxAmount !== null &&
        filters.minAmount !== null && (
          <CriteriaItem
            label={filters.amountType.split('_').join(' ')}
            value={`${formatCurrencyByUnit(filters.minAmount, 'actual')} - ${formatCurrencyByUnit(
              filters.maxAmount,
              'actual',
            )}`}
            operator={`Between`}
          />
        )}
      {!!filters.invoiceStatus?.length && (
        <CriteriaItem
          label="Invoice Status"
          operator="="
          value={filters.invoiceStatus.map((status) => (
            <Badge
              key={status}
              className={cn(
                ' px-4 py-2 rounded-full capitalize  ',
                status === 'NO_DUES' &&
                  'bg-green-700 hover:bg-green-700 border border-green-700 hover:text-white text-white',
                status === 'OVERDUE' && 'bg-red-500 text-white hover:bg-red-500 border border-red-500 hover:text-white',
                status === 'DUE' && 'bg-yellow-400 border border-yellow-400 text-black hover:bg-yellow-400',
              )}
            >
              {status.split('_').join(' ')}
            </Badge>
          ))}
        />
      )}
      {!!filters.tags?.length && (
        <CriteriaItem
          label="Tags"
          operator="="
          value={filters.tags.map((tag) => (
            <Badge
              key={tag.tagName + tag.tagValue}
              className="flex items-center gap-2 bg-gray-300 hover:bg-gray-200 text-primary "
            >
              <span>{tag.tagName}</span>: <span>{tag.tagValue}</span>
            </Badge>
          ))}
        />
      )}
      {!!filters.paymentStatus?.length && (
        <CriteriaItem
          label="Payment Status"
          operator="="
          value={filters.paymentStatus.map((status) => (
            <Badge
              key={status}
              className={cn(
                ' px-4 py-2 rounded-full capitalize  ',
                status === 'PAID' &&
                  'bg-green-400/20 hover:bg-green-700 border border-green-700 hover:text-white text-black',
                status === 'UNPAID' &&
                  'bg-red-500/20 text-black hover:bg-red-500 border border-red-500 hover:text-white',
                status === 'PARTIALLY_PAID' &&
                  'bg-yellow-400/20 border border-yellow-400 text-black hover:bg-yellow-400',
              )}
            >
              {status.split('_').join(' ')}
            </Badge>
          ))}
        />
      )}
      {!!filters.ownerKeyType && !!filters.ownerValue && (
        <CriteriaItem
          label={`Internal Stakeholder ${filters.ownerKeyType === 'role' ? 'Role' : ''}`}
          value={filters.ownerKeyType === 'role' ? filters.ownerValue : <OwnerName ownerId={filters.ownerValue} />}
          operator={`=`}
        />
      )}
    </div>
  );
};

const SegmentNameForm = ({ filters }: { filters: ICustomerFilters }) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<{
    name: string;
    description: string;
  }>({});
  const { toast } = useToast();
  const navigate = useNavigate();

  const { mutate: createSegment, isPending } = usePostCreateSegment({
    customConfig: {
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.response?.data.message || 'Unable to create segment',
          variant: 'destructive',
        });
      },
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'Segment created successfully',
        });
        navigate('/segments');
      },
    },
  });

  const onSubmit = (data: { name: string; description: string }) => {
    createSegment({
      name: data.name,
      filters: filters,
      description: data.description,
    });
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className=" flex justify-between items-center ">
        <div className=" flex flex-1 items-center gap-2 font-semibold text-xl ">
          <h2 className=" flex-1 max-w-fit  ">Creating Customer Segment: </h2>
          <div>
            <Input
              {...register('name', {
                required: {
                  value: true,
                  message: 'Segment name is required',
                },
              })}
              placeholder="Enter Segment Name"
              className=" text-sm font-normal max-w-[300px] "
            />
            <p className=" h-4 absolute text-xs text-destructive font-normal ">{errors.name?.message}</p>
          </div>
        </div>
        <div className=" flex items-center gap-2 ">
          <Button onClick={onCancel} disabled={isPending} variant="outline">
            Cancel
          </Button>
          <Button disabled={isPending} type="submit" className=" flex items-center gap-2 ">
            Save
            {isPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
          </Button>
        </div>
      </div>
      <div className=" my-4 ">
        <Textarea
          placeholder="Enter a description for this segment"
          className="w-full text-sm"
          {...register('description', {
            required: {
              value: true,
              message: 'Description is required',
            },
          })}
        />
        <p className=" h-4 absolute text-xs text-destructive font-normal ">{errors.description?.message}</p>
      </div>
    </form>
  );
};

const CreateSegmentPage = () => {
  const location = useLocation();

  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8">
      <SegmentNameForm filters={location.state} />
      <Section title="Criteria">
        <DisplaySearchQuery filters={location.state} />
      </Section>
      <Section title="Customers">
        <CustomersTable hideTableFilters customerFilters={location.state} />
      </Section>
    </div>
  );
};

export default CreateSegmentPage;
