import { ChevronDown } from 'lucide-react';
import { Button } from '../ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu';

export type SplitButtonItem = {
  label: string;
  icon?: JSX.Element;
  handler: () => void;
};

// TODO: make a generic component without coupling it to dropdown.
export type SplitButtonProps = {
  buttonList: SplitButtonItem[];
};

function SplitButton(props: SplitButtonProps) {
  const { buttonList } = props;
  const primaryButton = buttonList[0];
  const dropdownButtonList = buttonList.slice(1);

  return (
    <div className="flex">
      <Button onClick={primaryButton.handler} variant="outline" className="flex gap-2 rounded-r-none">
        {primaryButton.icon}
        {primaryButton.label}
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button variant="outline" className="rounded-l-none">
            <ChevronDown className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {dropdownButtonList.map((btn, index) => (
            <DropdownMenuItem onClick={btn.handler} key={index} className="flex gap-2">
              {btn.icon} {btn.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

export default SplitButton;
