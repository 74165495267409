/* eslint-disable no-unused-vars */
import Pagination from '@/components/shared/Pagination';
import PlaceholderIdToName from '@/components/shared/PlacholderIdToName';
import TableSkeleton from '@/components/shared/TableSkeleton';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';

import { ColumnSelector } from '@/components/shared/ReactTableColumnSelector';
import { useToast } from '@/components/ui/use-toast';
import {
  useExportInvoices,
  useGetFilteredInvoicesForPosting,
  useGetInvoicesSummary,
  useGetPostedCreditsForInvoice,
  useGetPostingHistoryForInvoice,
} from '@/hooks/api-hooks/usePostingQuery';
import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import { cn } from '@/lib/utils';
import { IInvoice, IInvoicesFilter, IInvoicesFilterForPosting } from '@/types/invoices.types';
import { IPostedFieldUpdates, IPostedToInvoiceObject, IPostingHistory } from '@/types/posting.types';
import {
  INVOICES_LEDGER_TABLE_COLUMN_ORDER_KEY,
  INVOICES_LEDGER_TABLE_COLUMNS_VISIBILITY_KEY,
} from '@/utils/constants/local-storage-keys';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Table as ReactTable,
  Row,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ArrowDownUp, ChevronDownIcon, ChevronRightIcon, GripVerticalIcon, History, Pencil, XIcon } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from '../../../ui/badge';
import InvoicesFilters from '../../invoices/InvoicesFilters';
import { PostedToInvoiceItem } from './posted-items';

function getHistory(data: IPostedFieldUpdates[], field: string) {
  const entry = data.find((entry) => entry.field === field);
  return entry
    ? {
        newValue: entry.newValue,
        oldValue: entry.oldValue,
      }
    : {
        newValue: null,
        oldValue: null,
      };
}

const HistoryAnnotation = ({ posting }: { posting: IPostingHistory }) => {
  if (posting.event === 'CREATE') {
    return (
      <h2>
        {formatCurrencyByUnit(Number(getHistory(posting.fieldsUpdates, 'posted_amount').newValue), 'actual')} posted to{' '}
        <span className=" capitalize ">
          {posting.placeholderId ? (
            <PlaceholderIdToName placeholderId={posting.placeholderId} />
          ) : posting.bankAccountTransactionId ? (
            'Credit'
          ) : (
            <span className=" normal-case ">
              Credit Note {posting.creditNoteNumber ? `(${posting.creditNoteNumber})` : ''}
            </span>
          )}
        </span>
      </h2>
    );
  }

  if (posting.event === 'UPDATE') {
    return (
      <h2>
        {formatCurrencyByUnit(Number(getHistory(posting.fieldsUpdates, 'posted_amount').oldValue), 'actual')} was
        updated to {formatCurrencyByUnit(Number(getHistory(posting.fieldsUpdates, 'posted_amount').newValue), 'actual')}{' '}
        for{' '}
        <span className=" capitalize ">
          {posting.placeholderId ? (
            <PlaceholderIdToName placeholderId={posting.placeholderId} />
          ) : posting.bankAccountTransactionId ? (
            'Credit'
          ) : (
            <span className=" normal-case ">
              Credit Note {posting.creditNoteNumber ? `(${posting.creditNoteNumber})` : ''}
            </span>
          )}
        </span>
      </h2>
    );
  }

  if (posting.event === 'DELETE') {
    return (
      <h2>
        {formatCurrencyByUnit(Number(getHistory(posting.fieldsUpdates, 'posted_amount').oldValue), 'actual')} was
        unposted for{' '}
        <span className=" capitalize ">
          {posting.placeholderId ? (
            <PlaceholderIdToName placeholderId={posting.placeholderId} />
          ) : posting.bankAccountTransactionId ? (
            'Credit'
          ) : (
            <span className=" normal-case ">
              Credit Note {posting.creditNoteNumber ? `(${posting.creditNoteNumber})` : ''}
            </span>
          )}
        </span>
      </h2>
    );
  }

  return null;
};

const HistoryItemWrapper = ({ posting }: { posting: IPostingHistory }) => {
  const isUpdate = posting.event === 'UPDATE';
  const postingValues = getHistory(posting.fieldsUpdates, 'posted_amount');

  if (isUpdate && postingValues.newValue === postingValues.oldValue) {
    return null;
  }

  return (
    <div className="pl-4">
      <div className="flex gap-4 mt-2">
        <div className="pr-4 border-r-[2px] min-w-24 max-w-24">
          <h2>{dayjs(posting.timestamp).add(330, 'm').format('DD MMM YYYY')}</h2>
          <h2 className="font-extralight">{dayjs(posting.timestamp).add(330, 'm').format('HH:mm:ss a')}</h2>
        </div>
        <div className="flex-2 pr-4">
          <HistoryAnnotation posting={posting} />
          {!!posting.userName && <h2 className="font-extralight">By {posting.userName}</h2>}
        </div>
      </div>
    </div>
  );
};

const TransactionHistory = ({
  setIsOpen,
  invoiceId,
  invoiceNumber,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  invoiceId: string;
  invoiceNumber: string;
}) => {
  const { data: invoicePostingHistory, isLoading } = useGetPostingHistoryForInvoice({
    invoiceId: invoiceId,
  });

  if (isLoading) {
    return (
      <div className="m-auto text-sm overflow-auto">
        <div className="flex justify-between p-4">
          <div>
            <h1 className="text-xl font-bold">History</h1>
            <h1 className="font-extralight">{invoiceId}</h1>
          </div>
          <Button variant="ghost" onClick={() => setIsOpen(false)} size="icon">
            <XIcon className="w-4 h-4" />
          </Button>
        </div>
        {new Array(5).fill(0).map((_, index) => {
          return (
            <>
              <div className="pl-4" key={index}>
                <h2 className="font-extralight">{}</h2>

                <div className="flex gap-4 mt-2">
                  <div className="pr-4 border-r-[2px] flex flex-col gap-1">
                    <Skeleton className="w-24 h-5" />
                    <Skeleton className="w-24 h-5" />
                  </div>
                  <div className="flex-1 flex flex-col gap-1 pr-4">
                    <Skeleton className="w-full h-5" />
                    <Skeleton className="w-full h-5" />
                    <Skeleton className="w-full h-5" />
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    );
  }

  return (
    <div className="text-sm flex flex-col h-full overflow-hidden">
      <div className="flex justify-between p-4">
        <div>
          <h1 className="text-lg font-bold">History</h1>
          <h1 className="font-extralight">{invoiceNumber}</h1>
        </div>
        <Button variant="ghost" onClick={() => setIsOpen(false)} size="icon">
          <XIcon className="w-4 h-4" />
        </Button>
      </div>
      <div className=" flex flex-1 overflow-scroll flex-col gap-1 pb-8 ">
        {invoicePostingHistory?.data
          .toSorted((a, b) => dayjs(b.timestamp).valueOf() - dayjs(a.timestamp).valueOf())
          .map((posting, index) => <HistoryItemWrapper key={index} posting={posting} />)}
      </div>
    </div>
  );
};

const Postings = ({
  postedToInvoice,
  invoiceNumber,
}: {
  postedToInvoice: IPostedToInvoiceObject[];
  invoiceNumber: string;
}) => {
  return (
    <div className=" flex flex-col gap-4  ">
      {postedToInvoice.map((posting, index) => (
        <Card key={index}>
          <CardContent className="flex flex-col gap-4 ml-2 p-2">
            <PostedToInvoiceItem
              showDelete
              showNarration
              invoiceNumber={invoiceNumber}
              postedToInvoiceObject={posting}
            />
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

const PostingHistoryCard = ({ invoiceId, invoiceNumber }: { invoiceId: string; invoiceNumber: string }) => {
  const [transactionHistoryOpen, setTransactionHistoryOpen] = useState(false);

  const { data: postedCreditsData, isLoading } = useGetPostedCreditsForInvoice({
    invoiceId: invoiceId,
  });

  const postedCredits = useMemo(() => {
    return postedCreditsData?.data.filter((item) => !!item.credit.id) || [];
  }, [postedCreditsData]);

  const postedCreditNotes = useMemo(() => {
    return postedCreditsData?.data.filter((item) => !!item.creditNote.id) || [];
  }, [postedCreditsData]);

  const postedPlaceholders = useMemo(() => {
    return postedCreditsData?.data.filter((item) => !!item.placeholder.id) || [];
  }, [postedCreditsData]);

  const postedAdjustments = useMemo(() => {
    return [...postedCreditNotes, ...postedPlaceholders];
  }, [postedCreditNotes, postedPlaceholders]);

  if (isLoading) {
    return (
      <>
        <div className="ml-[52px] py-4 flex flex-col gap-1 text-sm">
          <div className=" flex justify-between items-center p-0">
            <span className=" flex font-semibold gap-2  ">
              Adjustments <Skeleton className=" w-12 h-5 " />
            </span>
            <div>
              <Button
                className="text-blue-700 underline disabled"
                variant="ghost"
                icon={<Pencil className="w-4 h-4" />}
                iconPosition="left"
                size={'md'}
              >
                Edit
              </Button>
              <Button
                className="text-blue-700 underline"
                variant="ghost"
                icon={<History className="w-4 h-4" />}
                iconPosition="left"
                size={'md'}
                onClick={() => setTransactionHistoryOpen(true)}
              >
                History
              </Button>
            </div>
          </div>
          <div className=" flex flex-col gap-4 ">
            {new Array(3).fill(0).map((postedCredit, index) => (
              <Card key={index}>
                <CardContent className="flex flex-col gap-4 ml-2 p-2">
                  <div className="flex justify-between items-center py-2  ">
                    <div className=" flex flex-col gap-1 ">
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    </div>
                    <div className=" flex flex-col gap-1 ">
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    </div>
                    <div className=" flex flex-col gap-1 ">
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    </div>
                    <div className=" flex flex-col gap-1 ">
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
        <div className="ml-[52px] py-4 flex flex-col gap-1 text-sm">
          <div className=" flex justify-between items-center p-0">
            <span className=" flex font-semibold gap-2  ">
              Postings <Skeleton className=" w-12 h-5 " />
            </span>
          </div>
          <div className=" flex flex-col gap-4 ">
            {new Array(3).fill(0).map((postedCredit, index) => (
              <Card key={index}>
                <CardContent className="flex flex-col gap-4 ml-2 p-2">
                  <div className="flex justify-between items-center py-2  ">
                    <div className=" flex flex-col gap-1 ">
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    </div>
                    <div className=" flex flex-col gap-1 ">
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    </div>
                    <div className=" flex flex-col gap-1 ">
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    </div>
                    <div className=" flex flex-col gap-1 ">
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                      <Skeleton className=" min-w-[160px] w-[160px] h-4" />
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {postedAdjustments.length > 0 && (
        <div className="ml-[52px] py-4 flex flex-col gap-1 text-sm">
          <div className=" flex justify-between items-center p-0">
            <CardTitle className=" font-semibold">Adjustments ({postedAdjustments.length})</CardTitle>
            <div>
              <Button
                className="text-blue-700 underline disabled"
                variant="ghost"
                icon={<Pencil className="w-4 h-4" />}
                iconPosition="left"
                onClick={() =>
                  window.open(`/posting/invoice/view/${invoiceId}?postingStatus=Posted&defaultListType=posted`)
                }
                size={'md'}
              >
                Edit
              </Button>
              <Button
                className="text-blue-700 underline"
                variant="ghost"
                icon={<History className="w-4 h-4" />}
                iconPosition="left"
                size={'md'}
                onClick={() => setTransactionHistoryOpen(true)}
              >
                History
              </Button>
            </div>
          </div>
          <Postings invoiceNumber={invoiceNumber} postedToInvoice={postedAdjustments} />
          <Sheet open={transactionHistoryOpen} onOpenChange={setTransactionHistoryOpen}>
            <SheetContent side="right" className="p-0 h-[90%] mt-24 border">
              <TransactionHistory
                invoiceNumber={invoiceNumber}
                setIsOpen={setTransactionHistoryOpen}
                invoiceId={invoiceId}
              />
            </SheetContent>
          </Sheet>
        </div>
      )}
      <div className="ml-[52px] py-4 flex flex-col gap-1 text-sm">
        <div className=" flex justify-between items-center p-0">
          <CardTitle className=" font-semibold">Postings ({postedCredits.length})</CardTitle>

          {postedAdjustments.length === 0 && (
            <div>
              <Button
                className="text-blue-700 underline disabled"
                variant="ghost"
                icon={<Pencil className="w-4 h-4" />}
                iconPosition="left"
                onClick={() =>
                  window.open(`/posting/invoice/view/${invoiceId}?postingStatus=Posted&defaultListType=posted`)
                }
                size={'md'}
              >
                Edit
              </Button>
              <Button
                className="text-blue-700 underline"
                variant="ghost"
                icon={<History className="w-4 h-4" />}
                iconPosition="left"
                size={'md'}
                onClick={() => setTransactionHistoryOpen(true)}
              >
                History
              </Button>
            </div>
          )}
        </div>
        <Postings invoiceNumber={invoiceNumber} postedToInvoice={postedCredits} />
        <Sheet open={transactionHistoryOpen} onOpenChange={setTransactionHistoryOpen}>
          <SheetContent side="right" className="p-0 h-[90%] mt-24 border">
            <TransactionHistory
              invoiceNumber={invoiceNumber}
              setIsOpen={setTransactionHistoryOpen}
              invoiceId={invoiceId}
            />
          </SheetContent>
        </Sheet>
      </div>
    </>
  );
};

const InvoicesTable = ({
  isLoading,
  table,
  isEmpty,
  handleExpand,
}: {
  isLoading: boolean;
  table: ReactTable<IInvoice>;
  isEmpty: boolean;
  handleExpand: (row: Row<IInvoice>) => () => void;
}) => {
  const sizes = table.getState().columnSizingInfo;

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    return headers.reduce(
      (acc, current) => {
        return {
          ...acc,
          [`--col-${current.column.id}-size`]: current.column.getSize(),
          [`--header-${current.id}-size`]: current.getSize(),
        };
      },
      {} as {
        [key: string]: number;
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes]);

  if (isLoading) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <div className=" overflow-auto ">
      <Table
        style={{
          ...columnSizeVars,
          width: table.getTotalSize(),
        }}
        className="flex-1"
      >
        {isEmpty && <TableCaption className="pb-10">No data found</TableCaption>}
        <TableHeader className=" bg-gray-100 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className="flex items-center" key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    style={{
                      width: `calc(var(--header-${header?.id}-size) * 1.4px)`,
                    }}
                    className="flex items-center justify-between"
                    key={header.id}
                  >
                    <div className="break-keep truncate text-ellipsis">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                    <div className="flex gap-2">
                      {['invoiceDate', 'invoiceDueDate', 'invoiceOutstandingAmount'].includes(header.id) && (
                        <ArrowDownUp
                          className="w-4 h-4 cursor-pointer"
                          onClick={header.column.getToggleSortingHandler()}
                        />
                      )}
                      {!['view'].includes(header.id) && (
                        <GripVerticalIcon
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: cn(
                              'w-4 h-4 cursor-ew-resize',
                              header.column.getIsResizing() ? 'opacity-100' : 'opacity-50',
                            ),
                          }}
                        />
                      )}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="py-4">
          {table.getRowModel().rows.map((row) => (
            <>
              <TableRow
                className={cn(
                  row.original.paymentStatus == 'PAID' || row.original.paymentStatus == 'PARTIALLY_PAID'
                    ? 'flex cursor-pointer'
                    : 'flex',
                )}
                key={row.id}
                onClick={handleExpand(row)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    style={{
                      width: `calc(var(--col-${cell.column.id}-size) * 1.4px)`,
                    }}
                    className="truncate whitespace-nowrap overflow-ellipsis"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
              {row.getIsExpanded() && (
                <PostingHistoryCard invoiceNumber={row.original.invoiceNumber} invoiceId={row.original.id} />
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const InvoicesDetails = ({
  filters,
  page,
  limit,
}: {
  filters: Partial<IInvoicesFilter>;
  page: number;
  limit: number;
}) => {
  const { data: invoicesSummary, isLoading } = useGetInvoicesSummary({
    ...filters,
    page: page,
    limit: limit,
    customConfig: {},
  });

  if (isLoading) {
    return (
      <>
        <h2 className=" ">
          <div className="flex gap-2">
            <span className=" text-lg font-semibold">Invoices</span>
            <Skeleton className=" h-4 w-[400px] mt-2" />
          </div>
        </h2>
        <p className=" text-sm text-muted-foreground mb-2 ">Ledger for posted invoices</p>
      </>
    );
  }

  return (
    <>
      <h2 className=" text-lg font-semibold   ">
        Invoices{' '}
        <span className=" text-sm font-normal ">
          (Count: {invoicesSummary?.data.count}, Due/Overdue:{' '}
          {formatCurrencyByUnit(invoicesSummary?.data.totalRemaining || 0, 'actual')}, Total:{' '}
          {formatCurrencyByUnit(invoicesSummary?.data.totalValue || 0, 'actual')})
        </span>
      </h2>
      <p className=" text-sm text-muted-foreground mb-2 ">Ledger for posted invoices</p>
    </>
  );
};

const InvoicesLedgerTable = () => {
  const [invoiceFilters, setInvoiceFilters] = useState<Partial<IInvoicesFilterForPosting>>({
    paymentStatus: ['PAID', 'PARTIALLY_PAID'],
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const handleSubmit = useCallback(
    (filters: Partial<IInvoicesFilter>) => {
      setInvoiceFilters(filters);
    },
    [setInvoiceFilters],
  );

  const { data: invoicesResponse, isLoading } = useGetFilteredInvoicesForPosting({
    page: page,
    limit: pageLimit,
    notInCredit: [],
    ...invoiceFilters,
    customConfig: {},
  });

  const columns = useMemo<ColumnDef<IInvoice>[]>(() => {
    return [
      {
        id: 'view',
        header: '',
        cell: ({ row }) => (
          <>
            {row.original.paymentStatus == 'PAID' || row.original.paymentStatus == 'PARTIALLY_PAID' ? (
              row.getIsExpanded() ? (
                <ChevronDownIcon className="h-4 w-4" />
              ) : (
                <ChevronRightIcon className="h-4 w-4" />
              )
            ) : (
              ''
            )}
          </>
        ),
        minSize: 50,
        size: 50,
      },
      {
        header: 'Invoice Date',
        id: 'invoiceDate',
        accessorKey: 'invoiceDate',
        minSize: 60,
        cell: ({ getValue }) => dayjs(getValue() as string).format('DD MMM YYYY'),
      },
      {
        header: 'Invoice Number',
        id: 'invoiceNumber',
        accessorKey: 'invoiceNumber',
        minSize: 30,
        cell: ({ row, getValue }) => {
          return (
            <div className=" ">
              <Link to={`/invoice/view/${row.original.id}`}>
                <ToolTipCell value={getValue() as string} additionalClasses="pointer underline text-blue-700 " />

                <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
                  {row.original.lineItems?.at(0)?.lineItemDetails?.name}
                </div>
              </Link>
            </div>
          );
        },
      },
      {
        header: 'Customer',
        id: 'customerName',
        minSize: 30,
        accessorKey: 'customerName',
        cell: ({ row, getValue }) => {
          return (
            <div className="truncate text-ellipsis">
              <Link to={`/customer/view/${row.original.customerId}`} className="truncate text-ellipsis">
                <ToolTipCell value={getValue() as string} additionalClasses="pointer underline text-blue-700 " />
              </Link>
            </div>
          );
        },
      },

      {
        header: 'Due Date',
        id: 'invoiceDueDate',
        minSize: 30,
        accessorKey: 'invoiceDueDate',
        cell: ({ getValue }) => (
          <div className="">
            <div className=" truncate text-ellipsis ">{dayjs(getValue() as string).format('DD MMM YYYY')}</div>
          </div>
        ),
      },
      {
        header: 'Due/Overdue Amount',
        id: 'invoiceOutstandingAmount',
        accessorKey: 'invoiceOutstandingAmount',
        cell: ({ getValue }) => formatCurrencyByUnit(getValue() as number, 'actual'),
        minSize: 30,
      },
      {
        header: 'Invoice Amount',
        id: 'invoiceTotal',
        accessorKey: 'invoiceTotal',
        cell: ({ getValue }) => formatCurrencyByUnit(getValue() as number, 'actual'),
        minSize: 30,
      },
      {
        header: 'Payment Status',
        id: 'paymentStatus',
        minSize: 30,
        accessorKey: 'paymentStatus',
        cell: ({ getValue }) => (
          <div className="  flex flex-col gap-1 ">
            <div className="text-xs text-muted-foreground  truncate text-ellipsis">
              <Badge
                className={cn(
                  getValue() === 'PAID' && 'bg-green-700 hover:bg-green-700 hover:text-white text-white',
                  getValue() === 'UNPAID' && 'bg-red-500 text-white hover:bg-red-500 hover:text-white',
                  getValue() === 'PARTIALLY_PAID' && 'bg-yellow-400 text-black hover:bg-yellow-400',
                  'capitalize',
                )}
              >
                {(getValue() as string).split('_').join(' ').toLowerCase()}
              </Badge>
            </div>
          </div>
        ),
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return invoicesResponse?.data.docs || [];
  }, [invoicesResponse]);
  const { toast } = useToast();

  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);
  const defaultColumnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const { storedValue: columnVisibility, setValue: setColumnVisibility } = useLocalStorage(
    INVOICES_LEDGER_TABLE_COLUMNS_VISIBILITY_KEY,
    defaultColumnVisibility,
  );

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    INVOICES_LEDGER_TABLE_COLUMN_ORDER_KEY,
    defaultColumnOrder,
  );

  const table = useReactTable({
    columns,
    data: tableData,
    columnResizeMode: 'onChange',
    state: {
      sorting: sorting,
      columnVisibility,
      columnOrder,
    },
    onSortingChange: setSorting,
    defaultColumn: {
      minSize: 150,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const tableColumns = useMemo(() => table.getAllColumns(), [table]);

  const handleExpand = (row: Row<IInvoice>) => () => {
    row.toggleExpanded();
  };
  const { mutate: exportInvoices } = useExportInvoices();

  const handleSubmitFilter = () => {
    const customerIds = invoiceFilters.customers?.map((customer) => customer.value).join(',') || '';
    const filtersWithCustomerIds = {
      ...invoiceFilters,
      customerIds,
    };

    exportInvoices(filtersWithCustomerIds, {
      //TODO: config should be in definition
      onSuccess: () => {
        toast({
          description: 'Invoice ledger export initiated.You will receive an email with the file shortly.',
        });
      },
      onError: () => {
        toast({
          description: 'Failed to export Invoice Ledger',
          variant: 'destructive',
        });
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between overflow-hidden items-center">
        <div>
          <InvoicesDetails filters={invoiceFilters} limit={pageLimit} page={page} />
        </div>
        <div className=" flex items-center gap-2 ">
          <ColumnSelector columns={tableColumns} columnOrder={columnOrder} setColumnOrder={setColumnOrder} />
          <Button className="" onClick={handleSubmitFilter}>
            Export
          </Button>
        </div>
      </div>
      <InvoicesFilters
        showInvoiceNumberSearch
        handleSubmit={handleSubmit}
        filters={invoiceFilters}
        listType={'ledger'}
      />
      <InvoicesTable handleExpand={handleExpand} isEmpty={tableData.length === 0} isLoading={isLoading} table={table} />
      <Pagination
        hasNext={!!invoicesResponse?.data.hasNext}
        hasPrev={!!invoicesResponse?.data.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setPageLimit}
        pageNumber={page}
        rowsPerPage={pageLimit}
        totalPages={invoicesResponse?.data.totalPages || 0}
      />
    </div>
  );
};

export default InvoicesLedgerTable;
