import { validateInvite } from '@/api-functions/auth.api';
import { IValidationResponse } from '@/types/invitation.types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const InvitationQueryKeys = {
  validateInvite: (inviteCode: string) => ['validateInvite', inviteCode],
};

export const useGetInviteValidation = ({
  invitationCode,
  customConfig,
}: {
  invitationCode: string;
  customConfig: Omit<UseQueryOptions<IValidationResponse, AxiosError<IValidationResponse>>, 'queryKey'>;
}) => {
  return useQuery<IValidationResponse, AxiosError<IValidationResponse>>({
    queryKey: InvitationQueryKeys.validateInvite(invitationCode),
    queryFn: () => validateInvite(invitationCode),
    retry: false,
    ...customConfig,
  });
};
