import { IExcelExportFormat } from '@/types/cashflow.types';
import { IContact } from '@/types/contacts.types';
import { ICustomer, ICustomerDataWithInvoicesData } from '@/types/customer.types';
import { useExportExcel } from '../utils/useExportExcel';

const formatContacts = ({ contactsData }: { contactsData: IContact }) => {
  const contactsElements = [];

  if (contactsData.primaryEmail) {
    contactsElements.push(contactsData.primaryEmail);
  }

  if (contactsData.name) {
    contactsElements.push(contactsData.name);
  }

  if (contactsData.primaryPhone) {
    contactsElements.push(contactsData.primaryPhone);
  }

  if (contactsData.role) {
    contactsElements.push(contactsData.role);
  }

  return contactsElements.join(', ');
};

const flattenArrayToObject = (arr: ICustomer[], selectedColumns?: Set<string>): IExcelExportFormat[] => {
  return arr.map((item) =>
    Object.entries(item).reduce((acc, [key, value]) => {
      const formatted = key.replace(/([A-Z])/g, ' $1');

      if (selectedColumns && !selectedColumns.has(key)) {
        return acc;
      }

      if (key === 'name') {
        return { ...acc, ['Customer Name']: value };
      }

      if (key === 'tags' && value) {
        return {
          ...acc,
          [formatted.charAt(0).toUpperCase() + formatted.slice(1)]: Object.entries(value)
            .map(([tagKey, tagValue]) => `${tagKey}:${tagValue}`)
            .join(', '),
        };
      }
      if (key === 'contactsData' && value) {
        return {
          ...acc,
          ['Customer contacts']: (value as IContact[])
            .map((item) =>
              formatContacts({
                contactsData: item,
              }),
            )
            .join('; '),
        };
      }

      if (key === 'ownersData' && value) {
        return {
          ...acc,
          ['Internal stakeholders']: (value as IContact[])
            .map((item) =>
              formatContacts({
                contactsData: item,
              }),
            )
            .join('; '),
        };
      }
      if (key !== 'contactsData' && key !== 'ownersData') {
        return { ...acc, [formatted.charAt(0).toUpperCase() + formatted.slice(1)]: value };
      }
      return acc;
    }, {}),
  );
};

export const useExportCustomerDetails = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (_: string) => void;
  onError?: () => void;
}) => {
  const { action, isLoading } = useExportExcel({
    onSuccess,
    onError,
  });

  return {
    exportExcel: (data: ICustomerDataWithInvoicesData[] | ICustomer[], selectedColumns?: Set<string>) =>
      action(flattenArrayToObject(data, selectedColumns)),
    isLoading,
  };
};
