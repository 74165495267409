import { getBankAccountsForCompany } from '@/api-functions/bank-account.api';
import { IBankAccountResponse } from '@/types/bank-account.types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

const BankAccountQuery = {
  getBankAccountQuery: (companyId: string) => ['getBankAccountQuery', companyId],
};

export const useGetBankAccountsForCompany = ({
  companyId,
  customConfig,
}: {
  companyId: string;
  customConfig?: Omit<UseQueryOptions<IBankAccountResponse>, 'queryKey'>;
}) => {
  return useQuery<IBankAccountResponse>({
    queryKey: BankAccountQuery.getBankAccountQuery(companyId),
    queryFn: () => getBankAccountsForCompany(),
    ...customConfig,
    staleTime: Infinity,
  });
};
