'use client';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { IPostingStatusOption, PostingStatus } from '@/types/posting.types';
import { ChevronDownIcon } from 'lucide-react';
import { useMemo, useState } from 'react';

export function PostingStatusDropDown({
  selected,
  handleSelect,
  listType,
  className = '',
  containerClassName = '',
  showExclude = false,
  showPosted = true,
}: {
  selected: IPostingStatusOption[];
  listType?: 'posted' | 'all' | 'ledger';
  containerClassName?: string;
  handleSelect: (_: IPostingStatusOption) => () => void;
  className?: string;
  showExclude?: boolean;
  showPosted?: boolean;
}) {
  const [open, setOpen] = useState(false);

  const additionalOptions = useMemo<IPostingStatusOption[]>(() => {
    if (showExclude) {
      return [
        {
          label: 'Reversed',
          value: 'EXCLUDED',
        },
      ];
    }
    return [];
  }, [showExclude]);

  const options = useMemo<
    {
      label: string;
      value: PostingStatus;
    }[]
  >(() => {
    const allOptions: { label: string; value: PostingStatus }[] = [
      { label: 'Partially posted', value: 'PARTIALLY_POSTED' },
      { label: 'Unposted', value: 'UNPOSTED' },
    ];

    if (showPosted) {
      allOptions.push({ label: 'Posted', value: 'POSTED' });
    }

    return listType === 'ledger' ? allOptions.filter((option) => option.value !== 'UNPOSTED') : allOptions;
  }, [listType, showPosted]);

  const isExcludedSelected = useMemo(() => {
    return !!selected.find((item) => item.value === 'EXCLUDED');
  }, [selected]);

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild>
        <Button
          className={cn('flex items-center gap-4 text-left', containerClassName)}
          onClick={() => setOpen((prev) => !prev)}
          variant="outline"
        >
          <span className={cn(' flex-1 truncate text-left w-[150px] capitalize overflow-ellipsis', className)}>
            {selected.length > 0 ? (
              selected.map((option) => option.label).join(', ')
            ) : (
              <span className=" transform-none normal-case text-muted-foreground ">Select posting status</span>
            )}
          </span>
          <ChevronDownIcon className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onInteractOutside={() => setOpen(false)}
        onEscapeKeyDown={() => setOpen(false)}
        onFocusOutside={() => setOpen(false)}
        onPointerDownOutside={() => setOpen(false)}
        className="w-56"
      >
        <DropdownMenuLabel>Posting status</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {additionalOptions.map((option) => (
          <DropdownMenuCheckboxItem
            className=" normal-case "
            key={option.value}
            checked={!!selected.find((item) => item.value === option.value)}
            onClick={handleSelect(option)}
          >
            {option.label}
          </DropdownMenuCheckboxItem>
        ))}
        <DropdownMenuSeparator />
        {options.map((option) => (
          <DropdownMenuCheckboxItem
            disabled={isExcludedSelected}
            className=" normal-case "
            key={option.value}
            checked={!!selected.find((item) => item.value === option.value)}
            onClick={handleSelect(option)}
          >
            {option.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
