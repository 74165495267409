import { Badge } from '@/components/ui/badge';
import { IOptions } from '@/types/common.types';
import { ICreditNotesFilters } from '@/types/credit-notes.types';
import { XIcon } from 'lucide-react';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';

const FilterList = ({
  filterList,
  setModalOpen,
  filterKeyToLabelMap,
  customBadge,
}: {
  filterList: IOptions[];
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFocusField?: React.Dispatch<React.SetStateAction<keyof Partial<ICreditNotesFilters> | ''>>;
  filterKeyToLabelMap: Record<string, string>;
  customBadge?: (
    _filter: IOptions,
    _setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    _searchParams: URLSearchParams,
    _setSearchParams: SetURLSearchParams,
  ) => JSX.Element;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return filterList.map((filter) =>
    customBadge ? (
      customBadge(filter, setModalOpen, searchParams, setSearchParams)
    ) : (
      <Badge
        key={filter.label}
        className="rounded-full min-w-fit  capitalize flex items-center gap-2 py-2 px-4 bg-muted hover:bg-muted text-primary shadow-md"
      >
        <span
          className="cursor-pointer"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          {filterKeyToLabelMap[filter.label as keyof typeof filterKeyToLabelMap]}:{' '}
          {(filter.label === 'customers'
            ? filter.value.split(',').length > 1
              ? `${filter.value.split(',')[0]}...+${filter.value.split(',').length - 1}`
              : filter.value.split(',')[0]
            : filter.value
          ).substring(0, 60)}
        </span>
        <span className=" cursor-pointer ">
          <XIcon
            className="w-4 h-4 text-primary"
            onClick={() => {
              if (filter.label === 'maxAmount' || filter.label === 'minAmount') {
                searchParams.delete(filter.label);
                searchParams.delete('amount');
                searchParams.delete('maxAmount');
                searchParams.delete('minAmount');
                setSearchParams(searchParams, {
                  replace: true,
                });
                return;
              }

              if (filter.label === 'fromDate' || filter.label === 'toDate') {
                searchParams.delete(filter.label);
                searchParams.delete('fromDate');
                searchParams.delete('toDate');
                setSearchParams(searchParams, {
                  replace: true,
                });
                return;
              }

              searchParams.delete(filter.label);
              setSearchParams(searchParams, {
                replace: true,
              });
            }}
          />
        </span>
      </Badge>
    ),
  );
};

export default FilterList;
