import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { usePaymentTermContext } from '@/contexts/CreditTermContext';
import { usePostCreateCreditTerm } from '@/hooks/api-hooks/useCreditTermsQuery';
import { cn } from '@/lib/utils';
import { ICreditTerms, IDueDatePaymentTerm, IEarlyDiscountPaymentTerm } from '@/types/credit-term.types';
import { validateAndReturnErrorsAsObject } from '@/utils/createTermObjectForRequest';
import { CopyIcon, PlusIcon, Trash2Icon } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EditDescription, EditTermTitle } from '../shared/EditComponents';
import {
  EditDiscountTerm,
  EditFrequency,
  EditIndividualDiscountTerm,
  EditPrePaymentTerm,
} from './PaymentTermEditComponents';

const PaymentTerm = ({
  termDetails,
  termIndex,
  showDelete,
}: {
  termDetails: ICreditTerms<'PAYMENT_TERM'> & {
    isFullUpfront?: boolean;
    errors?: {
      [key: string]: string;
    };
  };
  termIndex: number;
  showDelete: boolean;
}) => {
  const { dispatch } = usePaymentTermContext();

  const handleDeleteTerm = () => {
    dispatch({
      type: 'TERM_REMOVE',
      updateIndex: termIndex,
      payload: 0,
      termIndex,
      property: 'id',
    });
  };

  const handleCloneTerm = () => {
    const newTerm = JSON.parse(JSON.stringify(termDetails));
    dispatch({
      type: 'CREATE_NEW',
      updateIndex: termIndex,
      payload: { ...newTerm, id: '', title: `Copy of ${newTerm.title}` },
      termIndex,
      property: 'id',
    });
  };

  const handlePrepaidChange = (value: string | number, unit: 'Percentage' | 'Amount') => {
    dispatch({
      payload: false,
      property: 'isFullUpfront',
      type: 'ATTRIBUTE_UPDATE',
      termIndex,
    });

    if (unit === 'Percentage') {
      if (Number(value) === 100) {
        dispatch({
          type: 'SET_AS_FULL_UPFRONT',
          payload: true,
          property: 'prepaidPaymentTerm',
          termIndex,
        });
        return;
      }
      dispatch({
        type: 'TERM_UPDATE',
        property: 'prepaidPaymentTerm',
        subField: 'percentage',
        payload: value,
        termIndex,
      });
      return;
    }

    dispatch({
      type: 'TERM_UPDATE',
      property: 'prepaidPaymentTerm',
      subField: 'amount',
      payload: value,
      termIndex,
    });
  };

  const handleTitleChange = (value: string) => {
    dispatch({
      type: 'ATTRIBUTE_UPDATE',
      property: 'title',
      payload: value,
      termIndex,
    });
  };

  const handleIsDiscountChange = (value: string) => {
    if (value === 'yes') {
      dispatch({
        type: 'APPEND_EARLY_DISCOUNT',
        payload: {
          discountAmount: 0,
          discountPercentage: 0,
          number: 0,
          frequency: 'DAY',
        },
        property: 'earlyDiscountPaymentTerm',
        termIndex,
      });
    } else if (value === 'no') {
      dispatch({
        type: 'REMOVE_ALL_EARLY_DISCOUNT',
        payload: 0,
        property: 'earlyDiscountPaymentTerm',
        termIndex,
      });
    }
  };

  const handleAddDiscount = () => {
    dispatch({
      type: 'APPEND_EARLY_DISCOUNT',
      payload: {
        discountAmount: 0,
        discountPercentage: 0,
        number: 0,
        frequency: 'DAY',
      },
      property: 'earlyDiscountPaymentTerm',
      termIndex,
    });
  };

  const handleDiscountTermChange = (value: IEarlyDiscountPaymentTerm, index: number) => {
    dispatch({
      type: 'UPDATE_EARLY_DISCOUNT',
      property: 'earlyDiscountPaymentTerm',
      payload: { ...value },
      updateIndex: index,
      termIndex,
    });
  };

  const handleDuePaymentTermChange = (value: string | number, field: keyof IDueDatePaymentTerm) => {
    dispatch({
      type: 'TERM_UPDATE',
      property: 'dueDatePaymentTerm',
      subField: field,
      payload: value,
      termIndex,
    });
  };

  const handleRemoveDiscountTerm = (index: number) => {
    dispatch({
      type: 'REMOVE_EARLY_DISCOUNT',
      property: 'earlyDiscountPaymentTerm',
      updateIndex: index,
      payload: 0,
      termIndex,
    });
  };

  const handleDescriptionChange = (value: string) => {
    dispatch({
      type: 'ATTRIBUTE_UPDATE',
      property: 'description',
      payload: value,
      termIndex,
    });
  };

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div ref={divRef} className=" w-full flex items-start gap-2 ">
      <Card className={cn(' px-2 py-4 flex-1')}>
        <div className=" flex w-full justify-between ">
          <EditTermTitle
            onChange={handleTitleChange}
            className="text-xl font-semibold"
            defaultValue={termDetails.title}
            titleErrors={termDetails.errors?.['title']}
          />
        </div>
        <div className=" py-4 flex flex-col gap-4 text-sm ">
          <div className=" px-4 pt-4 pb-0.5 border border-secondary rounded-lg flex items-start gap-4 c ">
            <EditPrePaymentTerm
              className=" w-16 h-7 "
              onChange={handlePrepaidChange}
              value={
                termDetails.terms.prepaidPaymentTerm?.amount
                  ? termDetails.terms.prepaidPaymentTerm.amount
                  : termDetails.terms.prepaidPaymentTerm?.percentage
                    ? termDetails.terms.prepaidPaymentTerm.percentage
                    : '0'
              }
              unit={Number(termDetails.terms.prepaidPaymentTerm?.amount) ? 'Amount' : 'Percentage'}
            />
            <span className=" relative top-1 ">upfront payment required</span>
          </div>
          <div
            className={cn(
              ' px-4 py-2 border border-secondary justify-between rounded-lg flex items-center gap-4 ',
              termDetails?.isFullUpfront && ' bg-gray-100/90 opacity-50 pointer-events-none ',
            )}
          >
            <div className=" flex items-center gap-2 ">
              There{' '}
              <EditDiscountTerm
                className=" w-20 h-7"
                value={termDetails.terms.earlyDiscountPaymentTerm?.length ? 'yes' : 'no'}
                onChange={handleIsDiscountChange}
              />{' '}
              early payment discount
            </div>
          </div>
          {termDetails.terms.earlyDiscountPaymentTerm?.map((discountTerm, index) => (
            <EditIndividualDiscountTerm
              remove={handleRemoveDiscountTerm}
              addDiscount={handleAddDiscount}
              onChange={handleDiscountTermChange}
              details={discountTerm}
              index={index}
              unit={discountTerm.discountAmount ? 'Amount' : 'Percentage'}
              key={index}
              isLast={termDetails.terms.earlyDiscountPaymentTerm?.length === index + 1}
            />
          ))}
          <div
            className={cn(
              ' px-4 pt-4 pb-0.5 border border-secondary rounded-lg flex items-start gap-4 ',
              termDetails?.isFullUpfront && ' bg-gray-100/90 opacity-50 pointer-events-none ',
            )}
          >
            <span className=" relative top-1 ">Total payable is due by</span>
            <EditFrequency
              onChange={handleDuePaymentTermChange}
              frequency={termDetails.terms.dueDatePaymentTerm?.frequency || 'DAY'}
              value={termDetails.terms.dueDatePaymentTerm?.number ?? 0}
              className=" w-24 h-7 "
            />
            <span className=" relative top-1 ">from invoice date</span>
          </div>
          <div className=" px-4 py-2 border border-secondary rounded-lg bg-secondary ">
            <h2 className=" mb-1 ">Legal description</h2>
            <p>
              <EditDescription
                descriptionErrors={termDetails.errors?.['description']}
                onChange={handleDescriptionChange}
                value={termDetails.description}
              />
            </p>
          </div>
        </div>
      </Card>
      <div className=" flex flex-col gap-2 ">
        <Button onClick={handleCloneTerm} size="icon" variant="ghost">
          <CopyIcon className="w-4 h-4" />
        </Button>
        {showDelete && (
          <Button onClick={handleDeleteTerm} size="icon" variant="ghost">
            <Trash2Icon className="w-4 h-4" />
          </Button>
        )}
      </div>
    </div>
  );
};

const PaymentTerms = () => {
  const { terms, dispatch } = usePaymentTermContext();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { mutate: createCreditTerm, isPending } = usePostCreateCreditTerm({
    customConfig: {
      onError: (error) => {
        toast({
          description: error.response?.data.message || 'Unable to create term. Please try again.',
          variant: 'destructive',
        });
      },
      onSuccess: () => {
        toast({
          description: 'Terms created successfully',
        });
        navigate('/credit-terms');
      },
    },
  });

  const checkEarlyDiscountTerm = ({ term }: { term: IEarlyDiscountPaymentTerm }) => {
    if (!term.discountAmount && !term.discountPercentage) {
      return false;
    }

    if (term.discountAmount) {
      if (isNaN(Number(term.discountAmount))) {
        return false;
      }

      if (Number(term.discountAmount) < 0) {
        return false;
      }
    }

    if (term.discountPercentage) {
      if (isNaN(Number(term.discountPercentage))) {
        return false;
      }

      if (Number(term.discountPercentage) < 0) {
        return false;
      }

      if (Number(term.discountPercentage) > 100) {
        return false;
      }
    }

    return true;
  };

  const handleSave = () => {
    const errors = terms
      .map((term, index) => {
        const validated = validateAndReturnErrorsAsObject({ term });
        if (!validated.isValid) {
          return {
            index: index,
            errors: validated.errors,
          };
        }
        return null;
      })
      .filter((error) => !!error);

    if (errors.length > 0) {
      const errorMap = new Map<
        number,
        {
          [key: string]: string;
        }
      >();
      errors.forEach((error) => {
        if (error) {
          errorMap.set(error.index, error.errors);
        }
      });
      dispatch({
        type: 'SET_ERRORS',
        payload: errorMap,
        property: 'terms',
        termIndex: -1,
      });
      return;
    }

    const termRequestObject = terms.map((term) => ({
      ...term,
      terms: {
        ...term.terms,
        earlyDiscountPaymentTerm: term.terms.earlyDiscountPaymentTerm
          ?.filter((earlyDiscount) => checkEarlyDiscountTerm({ term: earlyDiscount }))
          .map((earlyDiscount) => ({
            ...earlyDiscount,
            discountAmount: earlyDiscount.discountPercentage ? 0 : earlyDiscount.discountAmount,
          })),
      },
    }));

    createCreditTerm(termRequestObject);
  };

  return (
    <div className=" flex flex-col w-full gap-8 ">
      <div className=" flex justify-end gap-4 ">
        <Link to={'/credit-term/templates/payment'} replace>
          <Button variant="outline" className=" flex items-center gap-2 ">
            Add term <PlusIcon className="w-4 h-4" />
          </Button>
        </Link>
        <Link to="/credit-terms">
          <Button variant="outline">Cancel</Button>
        </Link>
        <Button disabled={isPending} onClick={handleSave}>
          Save
        </Button>
      </div>
      {terms?.map((term, index) => (
        <PaymentTerm showDelete={terms.length > 1} key={index + term.id} termDetails={term} termIndex={index} />
      ))}
    </div>
  );
};

export default PaymentTerms;
