import { cn } from '@/lib/utils';
import { ChevronLeftIcon, ChevronRightIcon, ChevronsLeft, ChevronsRight } from 'lucide-react';
import { Button } from '../ui/button';
import SelectComponent from '../ui/select-component';

interface IPaginationProps {
  hasPrev: boolean | null;
  hasNext: boolean | null;
  pageNumber: number;
  totalPages: number;
  rowsPerPage: number;
  onPageChange: (_: number | ((__: number) => number)) => void;
  onRowsPerPageChange: (_: number) => void;
  containerClassName?: string;
}

const Pagination = ({
  hasNext,
  hasPrev,
  pageNumber,
  totalPages,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  containerClassName,
}: IPaginationProps) => {
  if (totalPages === 0) {
    return null;
  }

  return (
    <div className={cn('flex my-4 justify-between text-sm px-4 w-full', containerClassName)}>
      <div className="flex items-center gap-2">
        <Button disabled={!hasPrev} onClick={() => onPageChange(1)} variant="outline" size="icon">
          <ChevronsLeft className="w-4 h-4" />
        </Button>
        <Button disabled={!hasPrev} onClick={() => onPageChange((prev) => prev - 1)} variant="outline" size="icon">
          <ChevronLeftIcon className="w-4 h-4" />
        </Button>
      </div>
      <span className="flex items-center gap-8">
        <span>
          Page{' '}
          <strong>
            {pageNumber} of {Math.max(totalPages, 1)}
          </strong>{' '}
        </span>
        <span className="flex items-center gap-2">
          Rows per page{' '}
          <SelectComponent
            className="w-[80px] p-1"
            onChange={(value) => onRowsPerPageChange(Number(value))}
            value={String(rowsPerPage)}
            options={[
              {
                label: '10',
                value: '10',
              },
              {
                label: '20',
                value: '20',
              },
              {
                label: '50',
                value: '50',
              },
              {
                label: '100',
                value: '100',
              },
            ]}
          />
        </span>
      </span>
      <div className="flex items-center gap-2">
        <Button disabled={!hasNext} onClick={() => onPageChange((prev) => prev + 1)} variant="outline" size="icon">
          <ChevronRightIcon className="w-4 h-4" />
        </Button>
        <Button disabled={!hasNext} onClick={() => onPageChange(totalPages)} variant="outline" size="icon">
          <ChevronsRight className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
