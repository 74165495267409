import { Box, CircularProgress } from '@mui/material';
export const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        width: '100%',
        height: '100%',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};
