import LinkExtension from '@tiptap/extension-link';

import {
  Color,
  Column,
  Columns,
  Document,
  Dropcursor,
  Focus,
  FontSize,
  Heading,
  Highlight,
  HorizontalRule,
  Placeholder,
  Selection,
  SlashCommand,
  StarterKit,
  TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
} from '.';

import { SlashCommandGroup } from '@/types/email-templates.types';
import { TableOfContentsNode } from './TableOfContentsNode';

export const TableExtensionKit = ({ tableGroup }: { tableGroup: SlashCommandGroup[] }) => [
  StarterKit,
  SlashCommand.configure({
    groups: tableGroup,
  }),
];

export const ExtensionKit = ({ variables }: { variables: SlashCommandGroup[] }) => [
  Document,
  Columns,
  Column,
  Selection,
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  HorizontalRule,
  StarterKit.configure({
    document: false,
    dropcursor: false,
    heading: false,
    horizontalRule: false,
    blockquote: false,
    history: false,
    codeBlock: false,
  }),
  TextStyle,
  FontSize,
  Color,
  TrailingNode,
  LinkExtension.configure({
    openOnClick: false,
    autolink: false,
  }),
  Highlight.configure({ multicolor: true }),
  Underline,
  TableOfContentsNode,
  TextAlign.extend({
    addKeyboardShortcuts() {
      return {};
    },
  }).configure({
    types: ['heading', 'paragraph'],
  }),
  Typography,
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: false,
    placeholder: () => '',
  }),
  SlashCommand.configure({
    groups: variables,
  }),
  Focus,
  Dropcursor.configure({
    width: 2,
    class: 'ProseMirror-dropcursor border-black',
  }),
];

export default ExtensionKit;
