import { ICustomerFilters } from '@/types/customer.types';
import { ISegmentSearchRules } from '@/types/segment.types';
import dayjs from 'dayjs';
import { isTagsPresent } from './isTagsPresent';

export const convertSearchQueryToCustomerFilters = (searchQuery: ISegmentSearchRules): ICustomerFilters => {
  const result = {} as ICustomerFilters;

  if (searchQuery.ownerId) {
    result.ownerKeyType = 'name-email';
    result.ownerValue = searchQuery.ownerId;
    result.ownerName = searchQuery.ownerName ?? '';
  }

  if (searchQuery.ownerRole) {
    result.ownerKeyType = 'role';
    result.ownerValue = searchQuery.ownerRole;
  }

  if (
    searchQuery.customerFilter &&
    searchQuery.customerFilter.identifierType &&
    searchQuery.customerFilter.identifierValue
  ) {
    result.identifierType = searchQuery.customerFilter.identifierType;
    result.identifierValue = searchQuery.customerFilter.identifierValue;
  }

  if (
    searchQuery.amountFilter &&
    searchQuery.amountFilter.amountType &&
    searchQuery.amountFilter.minAmount !== undefined &&
    searchQuery.amountFilter.maxAmount !== undefined &&
    searchQuery.amountFilter.minAmount !== null &&
    searchQuery.amountFilter.maxAmount !== null
  ) {
    result.amountType = searchQuery.amountFilter.amountType;
    result.minAmount = searchQuery.amountFilter.minAmount;
    result.maxAmount = searchQuery.amountFilter.maxAmount;
  }

  if (searchQuery.dateFilter?.dateType && searchQuery.dateFilter?.startDate && searchQuery.dateFilter?.endDate) {
    result.dateType = searchQuery.dateFilter.dateType;
    result.dateRange = {
      startDate: dayjs(searchQuery.dateFilter.startDate).toDate(),
      endDate: dayjs(searchQuery.dateFilter.endDate).toDate(),
    };
  }

  if (searchQuery.invoiceStatus?.length) {
    result.invoiceStatus = searchQuery.invoiceStatus;
  }

  if (searchQuery.paymentStatus?.length) {
    result.paymentStatus = searchQuery.paymentStatus;
  }

  if (searchQuery.tags && isTagsPresent(searchQuery.tags)) {
    result.tags = Object.entries(searchQuery.tags).flatMap(([key, values]) =>
      values.map((value) => ({
        tagName: key,
        tagValue: value,
      })),
    );
  }

  return result;
};
