import emailIcon from '@/assets/email_icon.svg';
import whatsappIcon from '@/assets/whatsapp_icon.svg';
import { useUserContext } from '@/contexts/UserContext';
import { useGetCompanyLogo } from '@/hooks/api-hooks/useCompanyQuery';
import { useGetUpiPaymentLink, useGetUpiQRCodeMutation, useGetVPAListMutation } from '@/hooks/api-hooks/useUpiQuery';
import { useGetShortUrlMutation } from '@/hooks/api-hooks/useUrlShortenerQuery';
import { ICustomer } from '@/types/customer.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import debounce from 'lodash.debounce';
import { ChevronDown, Link, Loader2Icon, Share2, XIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { Dialog, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, ScrollableDialogContent } from '../ui/dialog';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { Input } from '../ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Switch } from '../ui/switch';
import { Textarea } from '../ui/textarea';
import { useToast } from '../ui/use-toast';
import UpiQRView from './UpiQRView';

interface ShareUpiQRProps {
  customer: ICustomer;
  dueAmount?: number;
  overdueAmount?: number;
}

type IAmountType = 'Total Overdue' | 'Total Due' | 'Custom Amount';

interface IAmount {
  type: IAmountType;
  value: number;
}

interface IUpiQRForm {
  message: string;
  vpa: string;
  isMentionAmount: boolean;
  amount: number;
  amountType: IAmountType;
}

const ShareUpiQR = ({ customer, dueAmount, overdueAmount }: ShareUpiQRProps) => {
  const [open, setOpen] = useState(false);
  const [debouncedMessage, setDebouncedMessage] = useState<string>('');
  const { toast } = useToast();
  const { companiesOfUser, activeCompanyIndex } = useUserContext();
  const activeCompanyName = companiesOfUser?.[activeCompanyIndex]?.name;

  const { data: companyLogoResponse } = useGetCompanyLogo();
  const companyLogo = companyLogoResponse?.data.logoUrl || '';

  const amountTypeList: IAmount[] = useMemo(() => {
    const list: IAmount[] = [];
    if (overdueAmount) list.push({ type: 'Total Overdue', value: overdueAmount });
    if (dueAmount) list.push({ type: 'Total Due', value: dueAmount });
    list.push({ type: 'Custom Amount', value: 0 });
    return list;
  }, [dueAmount, overdueAmount]);

  const {
    mutate: fetchUpiIdList,
    data: vpaListResponse,
    isPending: isVPAListFetching,
    isSuccess: isVPAListFetched,
  } = useGetVPAListMutation({
    customConfig: {
      onError: () => {
        toast({
          variant: 'destructive',
          description: 'This feature is not yet enabled for your company',
        });
      },
      onSuccess({ data: responseData }) {
        if (responseData.vpaList.length === 0) {
          toast({
            variant: 'destructive',
            description: 'This feature is not yet enabled for your company',
          });
        } else {
          setOpen(true);
        }
      },
    },
  });

  const {
    mutate: fetchUpiQRCode,
    data: upiQRCodeResponse,
    isPending: isUpiQRCodeFetching,
    isError: isUpiQRCodeError,
  } = useGetUpiQRCodeMutation({
    customConfig: {
      onError: () => {
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
      },
    },
  });

  const vpaList = vpaListResponse?.data.vpaList || [];
  const qrCode = upiQRCodeResponse?.data.qrCode || '';

  const {
    control,
    watch,
    setValue: setFormValue,
    formState: { errors },
    clearErrors: clearFormErrors,
  } = useForm<IUpiQRForm>({
    values: {
      message: `Hi ${customer?.name}, please pay using the QR code below.`,
      vpa: vpaList?.[0] || '',
      isMentionAmount: false,
      amount: amountTypeList[0].value,
      amountType: amountTypeList[0].type,
    },
    mode: 'onChange',
  });

  const message = watch('message');
  const vpa = watch('vpa');
  const isMentionAmount = watch('isMentionAmount');
  const amount = watch('amount');
  const amountType = watch('amountType');

  const { data: paymentLinkResponse, isError: isPaymentLinkError } = useGetUpiPaymentLink({
    request: {
      customerId: customer.id,
      upiVpa: vpa,
      message: debouncedMessage,
      amount: isMentionAmount ? amount : 0,
    },
    qrCode: qrCode,
    customConfig: {
      enabled: !!qrCode,
    },
  });

  const paymentLink = paymentLinkResponse?.data.link;

  const { mutate: shortenUrl, isPending: isUrlShortening } = useGetShortUrlMutation({
    customConfig: {
      onError: () => {
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
      },
      onSuccess({ data: responseData }) {
        const message = `Hi ${customer.name},%0A%0APlease click the below link to pay:%0A${responseData.shortUrl}%0A%0AThanks%0A${activeCompanyName}`;
        const whatsappUrl = `https://wa.me/?text=${message}`;
        window.open(whatsappUrl, '_blank');
      },
    },
  });

  const isActionButtonDisabled: boolean = useMemo(() => {
    return isUpiQRCodeFetching || isUpiQRCodeError || (isMentionAmount && (amount === 0 || !!errors.amount));
  }, [isUpiQRCodeFetching, isUpiQRCodeError, isMentionAmount, amount, errors.amount]);

  useEffect(() => {
    if (!vpa) return;
    if (isMentionAmount) {
      if (amount > 0) {
        fetchUpiQRCode({ customerId: customer.id, upiVpa: vpa, amount: amount });
      }
    } else {
      fetchUpiQRCode({ customerId: customer.id, upiVpa: vpa, amount: 0 });
    }
  }, [vpa, isMentionAmount, amount, customer.id, fetchUpiQRCode]);

  const debouncedMessageUpdater = useMemo(
    () =>
      debounce((message: string) => {
        setDebouncedMessage(message);
      }, 300),
    [],
  );

  useEffect(() => {
    debouncedMessageUpdater(message);
    return () => {
      debouncedMessageUpdater.cancel();
    };
  }, [message, debouncedMessageUpdater]);

  const handleAction = (actionCallback: () => void) => {
    if (!paymentLink || isPaymentLinkError) {
      toast({
        variant: 'destructive',
        description: 'Some error occurred',
      });
      return;
    }

    actionCallback();
  };

  const handleCopyLink = () => {
    handleAction(async () => {
      try {
        await navigator.clipboard.writeText(paymentLink!);
        toast({
          description: 'Link copied successfully',
        });
      } catch (error) {
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
      }
    });
  };

  const handleShareViaEmail = async () => {
    handleAction(async () => {
      const richText = `
      <p>Hi ${customer.name},</p>
      <p>Please click the below link to pay:</p>
      <a href="${paymentLink}">Click to Pay</a>
      <p>Thanks <br> ${activeCompanyName}</p>
    `;

      const blob = new Blob([richText], { type: 'text/html' });
      const data = [new ClipboardItem({ 'text/html': blob })];

      try {
        await navigator.clipboard.write(data);
        toast({
          description: 'Email text copied to clipboard! Paste it into your email client to send.',
        });
      } catch (error) {
        toast({
          variant: 'destructive',
          description: 'Some error occurred',
        });
      }
    });
  };

  const handleShareViaWhatsapp = async () => {
    handleAction(() => {
      shortenUrl({ url: paymentLink! });
    });
  };

  const handleAmountTypeChange = (value: IAmountType) => {
    clearFormErrors('amount');
    if (value === 'Total Overdue') {
      setFormValue('amount', overdueAmount!);
    } else if (value === 'Total Due') {
      setFormValue('amount', dueAmount!);
    } else {
      setFormValue('amount', amount);
    }
  };

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      <DialogTrigger>
        <Button
          variant="ghost"
          className="text-[#9E3DFB] gap-3"
          onClick={() => fetchUpiIdList()}
          disabled={isVPAListFetching}
        >
          <Share2 className="w-5 h-5" />
          Share UPI QR
          {isVPAListFetching && <Loader2Icon className="w-5 h-5 animate-spin" />}
        </Button>
      </DialogTrigger>
      {isVPAListFetched && vpaList.length > 0 && (
        <ScrollableDialogContent className="w-full min-h-[550px] top-[10%] translate-y-0 md:w-[900px] border-2 bg-white">
          <DialogHeader className="h-[50px] p-4 flex flex-row justify-between items-center border-b">
            <DialogTitle>Share UPI QR</DialogTitle>
            <XIcon className="w-[20px] h-[20px] cursor-pointer" onClick={() => setOpen(false)} />
          </DialogHeader>

          <Card className="border-none shadow-none px-4 py-0 flex gap-4">
            <div className="flex-grow">
              <form>
                <div className="flex justify-between items-end text-sm mb-1">
                  <label htmlFor="message">Message</label>
                  <span className="text-xs text-gray-500">{120 - message.length} characters remaining</span>
                </div>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => <Textarea {...field} maxLength={120} className="max-h-[100px] mb-5" />}
                />
                <div className="text-sm mb-1">
                  <label htmlFor="vpa">UPI ID</label>
                </div>
                <Controller
                  name="vpa"
                  control={control}
                  rules={{
                    required: 'UPI ID is required',
                  }}
                  render={({ field }) => (
                    <Select value={field.value} onValueChange={field.onChange}>
                      <SelectTrigger className="w-full mb-5">
                        <SelectValue placeholder="Select UPI ID" />
                      </SelectTrigger>
                      <SelectContent>
                        {vpaList?.map((vpa) => (
                          <SelectItem key={vpa} value={vpa}>
                            {vpa}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
                <div className="text-sm mb-2">
                  <label htmlFor="isMentionAmount" className="flex items-center gap-2">
                    <Controller
                      name="isMentionAmount"
                      control={control}
                      render={({ field }) => <Switch checked={field.value} onCheckedChange={field.onChange} />}
                    />
                    Mention Amount
                  </label>
                </div>
                {isMentionAmount && (
                  <div className="flex gap-2">
                    <Controller
                      name="amountType"
                      control={control}
                      render={({ field }) => (
                        <Select
                          value={field.value}
                          onValueChange={(value) => {
                            field.onChange(value);
                            handleAmountTypeChange(value as IAmountType);
                          }}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="" />
                          </SelectTrigger>
                          <SelectContent>
                            {amountTypeList.map((amount) => (
                              <SelectItem key={amount.type} value={amount.type}>
                                {amount.type}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    />
                    <div className="w-full">
                      {amountType === 'Custom Amount' ? (
                        <>
                          <Controller
                            name="amount"
                            control={control}
                            rules={{
                              required: 'Amount is required',
                              validate: (value) => value > 0 || 'Amount must be greater than 0',
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                type="number"
                                onChange={(e) => field.onChange(e.target.valueAsNumber)}
                              />
                            )}
                          />
                          {errors.amount && <p className="text-xs text-red-600">{errors.amount.message}</p>}
                        </>
                      ) : (
                        <Input type="text" disabled value={formatCurrencyByUnit(amount, 'actual')} />
                      )}
                    </div>
                  </div>
                )}
              </form>
            </div>
            <UpiQRView
              message={message}
              vpa={vpa}
              isQRLoading={isUpiQRCodeFetching}
              isQRError={isUpiQRCodeError}
              qrCode={qrCode}
              companyName={activeCompanyName}
              companyLogoUrl={companyLogo}
              amount={isMentionAmount ? amount : 0}
            />
          </Card>
          <DialogFooter className="p-4 pt-0 gap-4">
            <Button variant="secondary" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              className="gap-2 bg-[#FAF5FF] text-[#9E3DFB] hover:bg-[#FAF5FF]"
              disabled={isActionButtonDisabled}
              onClick={handleCopyLink}
            >
              <Link className="w-4 h-4" />
              Copy Link
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger disabled={isActionButtonDisabled || isUrlShortening}>
                <Button className="gap-2" disabled={isActionButtonDisabled || isUrlShortening}>
                  <Share2 className="w-4 h-4" />
                  Share
                  {isUrlShortening ? (
                    <Loader2Icon className="w-4 h-4 animate-spin" />
                  ) : (
                    <ChevronDown className="w-4 h-4" />
                  )}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem className="gap-2" onClick={handleShareViaEmail}>
                  <img alt="Copy Email" src={emailIcon} />
                  Copy Email
                </DropdownMenuItem>
                <DropdownMenuItem className="gap-2" onClick={handleShareViaWhatsapp}>
                  <img alt="whatsApp" src={whatsappIcon} />
                  WhatsApp
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </DialogFooter>
        </ScrollableDialogContent>
      )}
    </Dialog>
  );
};

export default ShareUpiQR;
