import { useUserContext } from '@/contexts/UserContext';
import { useCompany } from '@/hooks/api-hooks/useCompanyQuery';
import { Loader2Icon } from 'lucide-react';
import { useEffect } from 'react';
import AppBarComponent from '../shared/AppBar';

const UserWrapper = ({ children }: { children: React.ReactNode }) => {
  const { data, isLoading, error } = useCompany();
  const { setCompaniesOfUser } = useUserContext();

  useEffect(() => {
    if (data?.data.companies.length) {
      setCompaniesOfUser(data.data.companies);
    }
  }, [data, setCompaniesOfUser]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center overflow-hidden">
        <div className="flex items-center px-2 sm:px-4 md:px-8">
          <Loader2Icon className="w-10 h-10 animate-spin" />
        </div>
      </div>
    );
  }
  if (error || !data?.data?.companies?.length) {
    return (
      <>
        <AppBarComponent />
        <div className="flex flex-row justify-center items-center px-4" style={{ height: '90vh' }}>
          <div>
            <h3 className="font-bold text-2xl">Oops! &nbsp;</h3>
            <span className="text-gray-500 text-xl">It appears that you are not associated with any company.</span>
          </div>
        </div>
      </>
    );
  }

  return children;
};

export default UserWrapper;
