import { useGetPlaceholders } from '@/hooks/api-hooks/usePostingQuery';
import { useMemo } from 'react';
import { Skeleton } from '../ui/skeleton';

const PlaceholderIdToName = ({ placeholderId }: { placeholderId: string }) => {
  const { data: placeholdersData, isLoading: isLoadingPlaceholders } = useGetPlaceholders();

  const placeholderMap = useMemo(() => {
    return (
      placeholdersData?.data.reduce(
        (acc, placeholder) => {
          acc[placeholder.id] = placeholder.name;
          return acc;
        },
        {} as Record<string, string>,
      ) || {}
    );
  }, [placeholdersData]);

  if (isLoadingPlaceholders) {
    return <Skeleton className=" h-7 w-32 " />;
  }

  return placeholderMap[placeholderId].split('_').join(' ').toLowerCase() ?? placeholderId;
};

export default PlaceholderIdToName;
