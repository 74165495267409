import PlanningTable from '@/components/plan/PlanningTable';
import Heading from '@/components/ui/heading';
import { useParams } from 'react-router-dom';

const PlanningView = () => {
  const { planId } = useParams();

  if (planId === undefined) {
    return null;
  }

  return (
    <div className="sm:px-16 sm:py-8 px-4 py-8">
      <Heading title="Planning" description="Plan and track your budget here" />
      <PlanningTable showReceivables={false} planId={planId} isExpandable />
    </div>
  );
};

export default PlanningView;
