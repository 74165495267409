import { dataAxiosInstance } from '@/lib/axios';
import { ICustomerFilters } from '@/types/customer.types';
import { IGetCustomerCountAxiosResponse, ISegment } from '@/types/segment.types';
import { convertCustomerFiltersToSegmentSearchQuery } from '@/utils/convertCustomerFiltersToSegmentQuery';

export const evaluateSegment = async (segmentId: string) => {
  const result = await dataAxiosInstance.get(`/customer/segment/evaluate?segmentId=${segmentId}`);

  return result.data;
};
export const createSegment = async (filters: ICustomerFilters, name: string, description: string) => {
  const query = convertCustomerFiltersToSegmentSearchQuery(filters);

  const result = await dataAxiosInstance.post('/customer/segment', {
    name,
    description,
    rule: {
      type: 'SEARCH_RULE',
      search_query: {
        ...query,
        companyId: '',
      },
    },
  });

  await evaluateSegment(result.data.data.id);

  return result.data;
};

export const getSegmentById = async (segmentId: string) => {
  const result = await dataAxiosInstance.get(`/customer/segment?id=${segmentId}`);

  return result.data;
};

export const deleteSegmentById = async ({ segmentId }: { segmentId: string }) => {
  const result = await dataAxiosInstance.delete(`/customer/segment?segmentId=${segmentId}`);

  return result.data;
};

export const getSegments = async () => {
  const result = await dataAxiosInstance.get('/customer/segments');

  return result.data;
};

export const getCustomersForSegment = async (segmentId: string) => {
  const result = await dataAxiosInstance.get(`/customer/segment/customers?segmentId=${segmentId}`);
  return result.data;
};

export const getCustomerCountForSegments = async () => {
  const result = await dataAxiosInstance.get<IGetCustomerCountAxiosResponse>('/customer/segments/count');

  const customerCounts = result.data.data.customerCount.reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr.segmentId]: curr.count,
      };
    },
    {} as {
      [key: string]: number;
    },
  );

  return {
    ...result.data,
    data: {
      customerCounts: customerCounts,
    },
  };
};

export const updateSegment = async ({
  fields,
  values,
  segmentId,
}: {
  fields: (keyof ISegment)[];
  values: Partial<ISegment>;
  segmentId: string;
}) => {
  const result = await dataAxiosInstance.post(`/customer/segment/update`, {
    segmentId,
    updates: {
      fields,
      values,
    },
  });

  await evaluateSegment(segmentId);

  return result.data;
};

export const evaluateCustomerSegment = async (segmentId: string) => {
  const result = await dataAxiosInstance.get(`/customer/segment/evaluate?segmentId=${segmentId}`);
  return result.data;
};
