import DeleteAppliedCreditNotePostingDialog from '@/components/posting/ledger/credit-notes/DeleteAppliedCreditNoteDialog';
import ToolTipCell from '@/components/Table/ToolTipCell';
import { Button } from '@/components/ui/button';
import { IPostedToInvoiceObject } from '@/types/posting.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import dayjs from 'dayjs';
import { Trash2Icon } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DeletePosting from '../../shared/DeletePostingDialog';
import SubItem from '../SubItems';

export const PostedPlaceholder = ({
  postedBy,
  postedAmount,
  postingId,
  postedOn,
  invoiceId,
  placeholderName,
}: {
  invoiceId: string;
  postingId: string;
  postedOn: string;
  placeholderName: string;
  postedBy: string;
  postedAmount: number;
}) => {
  return (
    <div className="flex justify-between gap-2">
      <SubItem
        className=" min-w-[160px] capitalize"
        label="Label"
        value={placeholderName.split('_').join(' ').toLowerCase()}
      />
      <SubItem className=" min-w-[160px]" label="" value={''} />
      <SubItem className=" min-w-[160px]" label="" value={''} />
      <SubItem className=" min-w-[160px]" label="" value={''} />
      <SubItem
        className=" min-w-[160px]"
        label="Posted By"
        value={postedBy}
        description={dayjs(postedOn).add(330, 'minute').format('DD MMM YY, HH:mm A')}
      />
      <SubItem className=" min-w-[160px]" label="Amount" value={formatCurrencyByUnit(postedAmount, 'actual', 'INR')} />
      <DeletePosting
        type="Placeholder"
        descriptionTitle="Posted to"
        createdBy={postedBy}
        descriptionValue={placeholderName}
        postingId={postingId}
        timestamp={postedOn}
        invoiceId={invoiceId}
      />
    </div>
  );
};

export const PostedCredit = ({
  postedCredit,
  showNarration,
}: {
  postedCredit: IPostedToInvoiceObject;
  showNarration?: boolean;
}) => {
  if (!postedCredit.credit?.id) {
    return null;
  }

  return (
    <div className="flex justify-between gap-2 py-2">
      <SubItem
        className=" min-w-[160px]"
        label="Posted By"
        value={postedCredit.createdBy}
        description={dayjs(postedCredit.timestamp).add(330, 'minute').format('DD MMM YY, HH:mm A')}
      />
      <SubItem
        className=" min-w-[160px]"
        label="Transaction Date"
        value={dayjs(postedCredit.credit.transactionDate).format('DD MMM YYYY')}
      />
      <SubItem
        className=" min-w-[160px]"
        label="Total Credit"
        value={formatCurrencyByUnit(postedCredit.credit.creditAmount || 0, 'actual', 'INR')}
      />
      <SubItem
        className=" min-w-[160px] "
        label="Posted"
        value={formatCurrencyByUnit(postedCredit.credit.postedAmount || 0, 'actual', 'INR')}
      />
      {showNarration && (
        <SubItem
          className=" min-w-[160px] max-w-[160px] "
          label="Narration"
          value={<ToolTipCell value={postedCredit.credit.narration} />}
        />
      )}
      <DeletePosting
        type="Credit"
        descriptionTitle="Narration"
        createdBy={postedCredit.createdBy}
        descriptionValue={postedCredit.credit.narration || ''}
        postingId={postedCredit.postingId}
        timestamp={postedCredit.timestamp || ''}
        creditId={postedCredit.credit.id}
        invoiceId={postedCredit.invoiceId}
      />
    </div>
  );
};

export const PostedCreditNote = ({
  postedCredit,
  showDelete = true,
  invoiceNumber,
}: {
  postedCredit: IPostedToInvoiceObject;
  showDelete?: boolean;
  invoiceNumber: string;
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  if (!postedCredit.creditNote?.id) {
    return null;
  }

  return (
    <>
      <div className="flex justify-between gap-2 py-2">
        <SubItem
          className=" min-w-[160px]"
          label="Posted By"
          value={postedCredit.createdBy}
          description={dayjs(postedCredit.timestamp).add(330, 'minute').format('DD MMM YY, HH:mm A')}
        />
        <SubItem className=" min-w-[160px]" label="Type" value="Credit Note" />
        <SubItem
          className=" min-w-[160px]"
          label="Credit Note Date"
          value={dayjs(postedCredit.creditNote.creditNoteDate).format('DD MMM YYYY')}
        />
        <SubItem
          className=" min-w-[160px]"
          label="Credit Note Value"
          value={formatCurrencyByUnit(postedCredit.creditNote.creditNoteTotal || 0, 'actual', 'INR')}
        />
        <SubItem
          className=" min-w-[160px] "
          label="Posted"
          value={formatCurrencyByUnit(postedCredit.postedAmount, 'actual', 'INR')}
        />
        <SubItem
          className=" min-w-[160px] "
          label="Credit Note Number"
          value={
            <Link className=" text-blue-700 underline " to={`/credit-note/view/${postedCredit.creditNote.id}`}>
              {postedCredit.creditNote.creditNoteNumber}
            </Link>
          }
        />

        {showDelete && (
          <Button onClick={() => setIsDeleteDialogOpen(true)} size="icon" variant="ghost">
            <Trash2Icon className=" h-4 w-4 text-destructive" />
          </Button>
        )}
      </div>
      {showDelete && (
        <DeleteAppliedCreditNotePostingDialog
          invoiceNumber={invoiceNumber}
          isDeleteDialogOpen={isDeleteDialogOpen}
          postingObject={postedCredit}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        />
      )}
    </>
  );
};

export const PostedToInvoiceItem = ({
  postedToInvoiceObject,
  invoiceNumber,
  showDelete,
  showNarration,
}: {
  postedToInvoiceObject: IPostedToInvoiceObject;
  invoiceNumber: string;
  showDelete: boolean;
  showNarration: boolean;
}) => {
  if (postedToInvoiceObject?.credit?.id) {
    return <PostedCredit postedCredit={postedToInvoiceObject} showNarration={showNarration} />;
  }

  if (postedToInvoiceObject?.creditNote?.id) {
    return (
      <PostedCreditNote
        invoiceNumber={invoiceNumber || ''}
        showDelete={showDelete}
        postedCredit={postedToInvoiceObject}
      />
    );
  }

  if (postedToInvoiceObject?.placeholder.id) {
    return (
      <PostedPlaceholder
        invoiceId={postedToInvoiceObject.invoiceId}
        placeholderName={postedToInvoiceObject.placeholder.placeholderName}
        postedAmount={postedToInvoiceObject.postedAmount}
        postingId={postedToInvoiceObject.postingId}
        postedBy={postedToInvoiceObject.createdBy}
        postedOn={postedToInvoiceObject.timestamp}
      />
    );
  }

  return null;
};
