import { XIcon } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';

const FilterChip = ({
  filterType,
  filtervalue,
  filterLabel,
  handleReset,
  handleClick,
}: {
  filterType: string;
  filtervalue: string;
  filterLabel: string;
  handleReset: (_: string) => void;
  handleClick: (_: string) => void;
}) => {
  const handleFilterReset = () => {
    handleReset(filterType);
  };
  const handleChipClick = () => {
    handleClick(filterType);
  };

  return (
    <div className="flex gap-4 ">
      <div className="flex bg-[#F1F5F9]  border-2 border-blue-50 gap-2 p-[5px] pl-[10px] rounded-2xl">
        <div className="text-[#888888] text-ellipsis 	overflow-hidden whitespace-nowrap">{filterLabel}: </div>
        <div className="text-ellipsis w-[50px]	overflow-hidden whitespace-nowrap" onClick={handleChipClick}>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="cursor-pointer text-[14px] text-left w-[80px] text-ellipsis	overflow-hidden whitespace-nowrap	">
                {filtervalue}
              </TooltipTrigger>
              <TooltipContent>{filtervalue}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className=" ml-auto">
          <XIcon className="w-[20px] h-[20px] cursor-pointer " onClick={handleFilterReset} />
        </div>
      </div>
    </div>
  );
};

export default FilterChip;
