import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { usePostCreateCreditTerm, usePostUpdateTerm } from '@/hooks/api-hooks/useCreditTermsQuery';
import { cn } from '@/lib/utils';
import { ICreditTerms, IMinimumGuaranteeTermItem } from '@/types/credit-term.types';
import { createTermObjectForRequest } from '@/utils/createTermObjectForRequest';
import dayjs from 'dayjs';
import { Loader2Icon } from 'lucide-react';
import { useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditDescription, EditTermTitle } from '../shared/EditComponents';
import { EditMinimumGuranteeTerm } from './MiniGuranteeEditComponents';

const minimumGuranteeReducer = (
  state: ICreditTerms<'MIN_GUARANTEE_TERM'> & { isDirty?: boolean },
  action: {
    type: 'ATTRIBUTE_UPDATE' | 'TERM_APPEND' | 'TERM_REMOVE' | 'TERM_UPDATE';
    payload: string | number | IMinimumGuaranteeTermItem | boolean;
    property: keyof ICreditTerms<'MIN_GUARANTEE_TERM'> | keyof ICreditTerms<'MIN_GUARANTEE_TERM'>['terms'] | 'isDirty';
    updateIndex?: number;
  },
): ICreditTerms<'MIN_GUARANTEE_TERM'> & { isDirty?: boolean } => {
  state.isDirty = true;
  switch (action.type) {
    case 'ATTRIBUTE_UPDATE':
      return {
        ...state,
        [action.property]: action.payload,
      };

    case 'TERM_APPEND':
      return {
        ...state,
        terms: {
          minimumGuaranteeTerms: state.terms.minimumGuaranteeTerms?.length
            ? [...state.terms.minimumGuaranteeTerms, action.payload as IMinimumGuaranteeTermItem]
            : [action.payload as IMinimumGuaranteeTermItem],
        },
      };
    case 'TERM_REMOVE':
      return {
        ...state,
        terms: {
          ...state.terms,
          minimumGuaranteeTerms: state.terms?.minimumGuaranteeTerms?.filter(
            (_: IMinimumGuaranteeTermItem, index: number) => index !== action.updateIndex,
          ),
        },
      };

    case 'TERM_UPDATE':
      return {
        ...state,
        terms: {
          ...state.terms,
          minimumGuaranteeTerms: state.terms.minimumGuaranteeTerms?.map((term, index) => {
            if (index === action.updateIndex) {
              return action.payload as IMinimumGuaranteeTermItem;
            }
            return term;
          }),
        },
      };
  }

  return state;
};

const MinimumGuaranteeTerm = ({
  creditTermDetails,
  isNew,
  creditTermId,
  isModal,
  customerId,
  handleClose,
}: {
  creditTermDetails: ICreditTerms<'MIN_GUARANTEE_TERM'>;
  isNew?: boolean;
  creditTermId: string;
  isModal?: boolean;
  customerId?: string;
  handleClose?: () => void;
}) => {
  const [termDetails, setTermDetails] = useReducer(minimumGuranteeReducer, creditTermDetails);

  const { toast } = useToast();

  const navigate = useNavigate();

  const { mutate: createCreditTerm, isPending } = usePostCreateCreditTerm({
    customConfig: {
      onError: (error) => {
        toast({
          description: error.response?.data.message || 'Unable to create term. Please try again.',
          variant: 'destructive',
        });
      },
      onSuccess: () => {
        toast({
          description: 'Term created successfully',
        });
        handleClose?.();
        if (customerId) return;
        navigate(`/credit-terms`);
      },
    },
  });

  const { mutate: updateCreditTerm, isPending: isUpdatePending } = usePostUpdateTerm({
    customConfig: {
      onError: (error) => {
        toast({
          description: error.response?.data.message || 'Unable to create term. Please try again.',
          variant: 'destructive',
        });
      },
      onSuccess: () => {
        setTermDetails({ type: 'ATTRIBUTE_UPDATE', property: 'isDirty', payload: false });
        toast({
          description: 'Term updated successfully',
        });
      },
    },
  });

  const checkMinimumGuaranteeTerm = (term: IMinimumGuaranteeTermItem) => {
    if (term.number <= 0) {
      return false;
    }
    return true;
  };

  const handleSave = () => {
    const validated = createTermObjectForRequest({ term: termDetails });

    if (!validated.isValid) {
      toast({
        description: validated.errors[0].message,
        variant: 'destructive',
      });
      return;
    }

    if (isNew) {
      createCreditTerm([
        {
          customerId: customerId,
          ...termDetails,
          terms: {
            minimumGuaranteeTerms: termDetails.terms.minimumGuaranteeTerms?.filter((term) =>
              checkMinimumGuaranteeTerm(term),
            ),
          },
        },
      ]);
      return;
    }

    updateCreditTerm({
      creditTermId,
      term: {
        ...termDetails,
        terms: {
          minimumGuaranteeTerms: termDetails.terms.minimumGuaranteeTerms?.filter((term) =>
            checkMinimumGuaranteeTerm(term),
          ),
        },
      },
    });
  };

  const handleTitleChange = (title: string) => {
    setTermDetails({
      type: 'ATTRIBUTE_UPDATE',
      property: 'title',
      payload: title,
    });
  };

  const handleDescriptionChange = (description: string) => {
    setTermDetails({
      type: 'ATTRIBUTE_UPDATE',
      property: 'description',
      payload: description,
    });
  };

  const handleAddTerm = () => {
    setTermDetails({
      type: 'TERM_APPEND',
      payload: {
        frequency: 'MONTH',
        minGuaranteedAmount: 0,
        number: 1,
      },
      property: 'terms',
    });
  };

  const handleEditTerm = (term: IMinimumGuaranteeTermItem, index: number) => {
    setTermDetails({
      type: 'TERM_UPDATE',
      payload: term,
      updateIndex: index,
      property: 'terms',
    });
  };

  const handleRemoveTerm = (index: number) => {
    setTermDetails({
      type: 'TERM_REMOVE',
      updateIndex: index,
      property: 'terms',
      payload: index,
    });
  };

  const lastUpdatedAndBy = useMemo(() => {
    if (!creditTermDetails?.metaData?.edit_history) {
      if (creditTermDetails.createdAt && creditTermDetails.createdAt) {
        return {
          lastUpdated: dayjs(creditTermDetails.createdAt).add(330, 'minutes').toDate(),
          updatedBy: creditTermDetails.createdBy,
        };
      }

      return null;
    }
    const recentItem = creditTermDetails.metaData.edit_history.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    })[0];

    return {
      lastUpdated: dayjs(recentItem.updatedAt).add(330, 'minutes').toDate(),
      updatedBy: recentItem.userName,
    };
  }, [creditTermDetails]);

  return (
    <>
      {!isModal && (
        <div className=" flex justify-between items-center my-4 ">
          <div>
            {lastUpdatedAndBy && (
              <div className=" text-sm text-muted-foreground ">
                Last updated by <span className=" font-semibold text-primary ">{lastUpdatedAndBy.updatedBy}</span> on{' '}
                <span className=" text-primary font-semibold ">
                  {dayjs(lastUpdatedAndBy.lastUpdated).format('DD MMM YYYY, hh:mm A')}
                </span>
              </div>
            )}
          </div>
          <div className=" flex items-center gap-4 justify-end ">
            <Button variant={'outline'} className=" flex items-center gap-2 " onClick={() => navigate('/credit-terms')}>
              Cancel
            </Button>
            <Button
              disabled={isPending || isUpdatePending || !termDetails?.isDirty}
              onClick={handleSave}
              className=" flex items-center gap-2 "
            >
              Save
              {isPending || isUpdatePending ? <Loader2Icon size="w-4 h-4" /> : null}
            </Button>
          </div>
        </div>
      )}
      <Card className={cn(' px-2 py-4 ', isModal ? ' w-full max-w-[600px] max-h-[600px] overflow-scroll ' : '')}>
        <div className=" flex w-full justify-between ">
          <EditTermTitle
            onChange={handleTitleChange}
            className="text-xl font-semibold"
            defaultValue={termDetails.title}
          />
        </div>
        <div className=" py-4 flex flex-col gap-4 text-sm ">
          {termDetails.terms.minimumGuaranteeTerms?.map((minimumGuaranteeTerm, index) => (
            <EditMinimumGuranteeTerm
              remove={handleRemoveTerm}
              onChange={handleEditTerm}
              details={minimumGuaranteeTerm}
              index={index}
              key={index}
              length={termDetails.terms.minimumGuaranteeTerms?.length}
            />
          ))}
          <div className=" px-4 py-2  justify-end rounded-lg flex items-center gap-4 ">
            <div>
              <Button onClick={handleAddTerm} variant="outline" className=" h-7 ">
                Add term
              </Button>
            </div>
          </div>
          <div className=" px-4 py-2 border border-secondary rounded-lg bg-secondary ">
            <h2 className=" mb-1 ">Legal description</h2>
            <p>
              <EditDescription onChange={handleDescriptionChange} value={termDetails.description} />
            </p>
          </div>
        </div>
        {isModal && (
          <div className=" flex items-center gap-4 justify-end ">
            <Button variant="outline" onClick={handleClose} className=" flex items-center gap-2 ">
              Cancel
            </Button>
            <Button
              disabled={isPending || isUpdatePending || !termDetails?.isDirty}
              onClick={handleSave}
              className=" flex items-center gap-2 "
            >
              Save
              {isPending || (isUpdatePending && <Loader2Icon className=" w-4 h-4 animate-spin " />)}
            </Button>
          </div>
        )}
      </Card>
    </>
  );
};

export default MinimumGuaranteeTerm;
