import { useGetFilteredInvoices, useGetFilteredInvoicesSummary } from '@/hooks/api-hooks/useInvoiceQuery';
import { useInvoicesExport } from '@/hooks/excel-export/useInvociesExport';
import { cn } from '@/lib/utils';
import { IInvoice, IInvoiceStatus, IInvoicesFilter } from '@/types/invoices.types';
import { formatCurrencyByUnit } from '@/utils/formatNumberByUnit';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../components/ui/tooltip';

import { useLocalStorage } from '@/hooks/utils/useLocalStorage';
import {
  INVOICE_COLUMN_ORDER_STORAGE_KEY,
  INVOICE_COLUMN_VISIBILITY_STORAGE_KEY,
} from '@/utils/constants/local-storage-keys';
import {
  ColumnDef,
  RowSelectionState,
  Table,
  VisibilityState,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs, { extend } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { BookCheckIcon, CalendarRange, FileSpreadsheet, XIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ResizableTable } from '../Table/ResizableTable';
import ToolTipCell from '../Table/ToolTipCell';
import Pagination from '../shared/Pagination';
import { ColumnSelector } from '../shared/ReactTableColumnSelector';
import SplitButton, { SplitButtonItem } from '../shared/SplitButton';
import { InvoiceStatusDropdown } from '../shared/StatusDropDowns';
import TableSkeleton from '../shared/TableSkeleton';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import Empty from '../ui/empty';
import { Skeleton } from '../ui/skeleton';
import { TableUnitsTabs } from '../ui/table-unit-tabs';
import { useToast } from '../ui/use-toast';
import { EditPromiseToPayDateBulk } from './InvoiceEditComponents';
import RoundOffInvoicesDialog from './RoundOffDialog';

// TODO: a common type please du a cmd + shift + f and check for instances
interface IOption {
  label: string;
  value: IInvoiceStatus;
}

const FilterBadge = ({ label, reset }: { startDate?: Date; endDate?: Date; label: string; reset?: () => void }) => {
  const handleReset = () => {
    reset?.();
  };

  return (
    <Badge className="px-4 py-2 rounded-full border-black bg-gray-100 text-black hover:bg-gray-100 flex justify-between gap-4">
      {label}
      <XIcon onClick={handleReset} className=" h-4 w-4 cursor-pointer " />
    </Badge>
  );
};

const areSelectedInvoicesBelongsToSameCustomer = (selectedInvoice: IInvoice[]) => {
  const customerIdList = selectedInvoice.map((invoice) => invoice.customerId);
  const customerIdSet = new Set(customerIdList);
  return customerIdSet.size === 1 ? true : false;
};

const ActionButtons = ({ handleExport, table }: { handleExport: () => void; table: Table<IInvoice> }) => {
  const [isPromiseToPayUpdateOpen, setIsPromiseToPayUpdateOpen] = useState<boolean>(false);
  const [isRoundOffDialogOpen, setIsRoundOffDialogOpen] = useState<boolean>(false);

  const flatRows = table.getSelectedRowModel().flatRows;

  const selectedInvoices = useMemo(() => {
    return flatRows.map((row) => row.original);
  }, [flatRows]);

  const handlePromiseToPayDateUpdate = useCallback(() => {
    setIsPromiseToPayUpdateOpen(true);
  }, []);

  const ACTION_BUTTON_LIST: SplitButtonItem[] = useMemo(
    () => [
      {
        label: 'Update promise to pay date',
        icon: <CalendarRange className="h-4 w-4" />,
        handler: handlePromiseToPayDateUpdate,
      },
      {
        label: 'Export Excel',
        icon: <FileSpreadsheet className="h-4 w-4" />,
        handler: handleExport,
      },
      {
        label: 'Round Off',
        icon: <BookCheckIcon className="h-4 w-4" />,
        handler: () => {
          setIsRoundOffDialogOpen(true);
        },
      },
    ],
    [handleExport, handlePromiseToPayDateUpdate],
  );

  const actionButtonList = useMemo(() => {
    if (areSelectedInvoicesBelongsToSameCustomer(selectedInvoices)) {
      return ACTION_BUTTON_LIST;
    }
    return [...ACTION_BUTTON_LIST].slice(-2);
  }, [selectedInvoices, ACTION_BUTTON_LIST]);

  if (!selectedInvoices.length) {
    return null;
  }

  return (
    <>
      <SplitButton buttonList={actionButtonList} />
      {isPromiseToPayUpdateOpen && (
        <EditPromiseToPayDateBulk
          open={isPromiseToPayUpdateOpen}
          table={table}
          toggleDialog={setIsPromiseToPayUpdateOpen}
        />
      )}
      {
        <RoundOffInvoicesDialog
          open={isRoundOffDialogOpen}
          selectedInvoiceIds={selectedInvoices.map((invoice) => invoice.id)}
          toggleDialog={setIsRoundOffDialogOpen}
        />
      }
    </>
  );
};

const InvoicesTable = ({
  showInTableFilters,
  filters,
  resetFilters,
  filterLabel,
  showSummary = true,
}: {
  showInTableFilters?: boolean;
  resetFilters?: () => void;
  filterLabel?: string;
  filters: Partial<IInvoicesFilter>;
  showSummary?: boolean;
}) => {
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState<IOption[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const { toast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  extend(utc);
  extend(timezone);

  // Set the default timezone
  dayjs.tz.setDefault('Asia/Kolkata');

  // const { toast } = useToast();
  const [unit, setUnit] = useState<'k' | 'l' | 'cr' | 'actual'>('actual');

  const { data: invoicesResponse, isLoading } = useGetFilteredInvoices({
    page: page,
    limit: rowsPerPage,
    ...filters,
    invoiceStatus: filters?.invoiceStatus
      ? filters.invoiceStatus
      : selectedInvoiceStatus?.length
        ? selectedInvoiceStatus.map((item) => item.value)
        : [],
    customConfig: {},
  });

  useEffect(() => {
    filters && handleFirstPage();
  }, [filters]);

  const { data: invoicesSummaryResponse, isPending: isSummaryLoading } = useGetFilteredInvoicesSummary({
    page: page,
    limit: rowsPerPage,
    ...filters,
    invoiceStatus: filters?.invoiceStatus
      ? filters.invoiceStatus
      : selectedInvoiceStatus?.length
        ? selectedInvoiceStatus.map((item) => item.value)
        : [],
    customConfig: {},
  });

  useEffect(() => {
    if (showInTableFilters) {
      const invoiceStatusFromQuery = searchParams.get('invoiceStatus');

      if (invoiceStatusFromQuery) {
        const statuses = invoiceStatusFromQuery
          .split(',')
          .filter((item) => !!item && item.split(':').length === 2)
          .map((item) => ({
            value: item.split(':')[0] as IInvoiceStatus,
            label: item.split(':')[1],
          }));

        setSelectedInvoiceStatus(statuses);

        return;
      }

      const invoiceStatus = filters.invoiceStatus
        ? filters?.invoiceStatus
            ?.map((item) => {
              return item as IInvoiceStatus;
            })
            .map((item) => ({
              label: item,
              value: item,
            }))
        : [];
      setSelectedInvoiceStatus(invoiceStatus);
    }
  }, [filters, showInTableFilters, searchParams]);

  const { exportExcel } = useInvoicesExport({
    onSuccess: (url) => {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Invoices.xlsx');
      document.body.appendChild(link);
      link.click();
      toast({
        description: 'Invoices exported',
      });
    },
    onError() {
      toast({
        variant: 'destructive',
        description: 'Failed to export invoices',
      });
    },
  });

  const handleFirstPage = () => {
    setPage(1);
  };

  const handleUnitChange = (value: string) => {
    const newUnit = value as 'k' | 'l' | 'cr' | 'actual';
    setUnit(newUnit);
  };

  const columns: ColumnDef<IInvoice>[] = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || table.getIsSomePageRowsSelected()}
            className={cn(table.getIsSomePageRowsSelected() && 'opacity-50')}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
        minSize: 50,
        size: 50,
        showGrips: false,
      },
      {
        id: 'invoiceNumber',
        header: 'Number',
        accessorKey: 'invoiceNumber',
        cell: ({ row, getValue }) => {
          return (
            <div className=" w-full ">
              <Link to={`/invoice/view/${row.original.id}`}>
                <ToolTipCell value={getValue() as string} additionalClasses="pointer underline text-blue-700 " />

                <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
                  {row.original.lineItems?.at(0)?.lineItemDetails?.name}
                </div>
              </Link>
            </div>
          );
        },
      },
      {
        id: 'invoiceType',
        header: 'Type',
        accessorKey: 'invoiceType',
        cell: ({ getValue }) => (
          <div className=" w-full ">
            <div className="w-full truncate text-ellipsis ">{getValue() as string}</div>
          </div>
        ),
      },
      {
        id: 'customerName',
        header: 'Customer',
        accessorKey: 'customerName',
        cell: ({ row, getValue }) => {
          return (
            <div className=" w-full ">
              <Link to={`/customer/view/${row.original.customerId}`}>
                <ToolTipCell value={getValue() as string} additionalClasses="pointer underline text-blue-700 " />
              </Link>
            </div>
          );
        },
      },
      {
        id: 'invoiceDate',
        header: 'Invoice Date',
        accessorKey: 'invoiceDate',
        cell: ({ getValue }) => dayjs(getValue() as string).format('DD MMM YYYY'),
      },
      {
        id: 'invoiceDueDate',
        header: 'Due Date',
        accessorKey: 'invoiceDueDate',
        cell: ({ getValue, row }) => (
          <div className=" w-full ">
            <div className="w-full truncate text-ellipsis ">{dayjs(getValue() as string).format('DD MMM YYYY')}</div>
            {dayjs(getValue() as string)
              .endOf('day')
              .diff(dayjs().endOf('day'), 'days') == 0 ? (
              <div className="text-xs text-muted-foreground w-full text-yellow-400  font-semibold truncate text-ellipsis">
                {row.original.invoiceOutstandingAmount > 0 && `Due Today`}
              </div>
            ) : dayjs(getValue() as string)
                .endOf('day')
                .diff(dayjs().endOf('day'), 'days') < 0 ? (
              <div className="text-xs text-muted-foreground w-full text-red-500 font-semibold truncate text-ellipsis">
                {row.original.invoiceOutstandingAmount > 0 &&
                  `Overdue by ${Math.abs(
                    dayjs(getValue() as string)
                      .endOf('day')
                      .diff(dayjs().endOf('day'), 'days'),
                  )} days`}
              </div>
            ) : dayjs(getValue() as string)
                .endOf('day')
                .diff(dayjs().endOf('day'), 'days') > 0 && dayjs(getValue() as string).diff(dayjs(), 'days') <= 7 ? (
              <div className="text-xs text-muted-foreground w-full text-yellow-400  font-semibold truncate text-ellipsis">
                {row.original.invoiceOutstandingAmount > 0 &&
                  `Due in ${Math.abs(
                    dayjs(getValue() as string)
                      .endOf('day')
                      .diff(dayjs(), 'days'),
                  )} day${
                    Math.abs(
                      dayjs(getValue() as string)
                        .endOf('day')
                        .diff(dayjs(), 'days'),
                    ) > 1
                      ? 's'
                      : ''
                  }`}
              </div>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        id: 'promiseToPayDate',
        header: 'Promise To Pay Date',
        accessorKey: 'promiseToPayDate',
        cell: ({ getValue }) => (
          <div className=" w-full ">
            <div className="w-full truncate text-ellipsis ">{dayjs(getValue() as string).format('DD MMM YYYY')}</div>
          </div>
        ),
      },
      {
        size: 200,
        id: 'invoiceOutstandingAmount',
        header: 'Due/Overdue',
        accessorKey: 'invoiceOutstandingAmount',
        cell: ({ getValue, row }) => (
          <div className=" w-full flex flex-col gap-1 ">
            <div className="w-full truncate text-ellipsis ">
              {formatCurrencyByUnit(getValue() as number, unit, row.original.invoiceCurrency)}
            </div>
            <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
              Total post TDS: {formatCurrencyByUnit(row.original.invoiceTotal, unit, row.original.invoiceCurrency)}
            </div>
          </div>
        ),
      },
      {
        id: 'invoiceStatus',
        header: 'Invoice Status',
        accessorKey: 'invoiceStatus',
        cell: ({ getValue }) => (
          <div className=" w-full flex flex-col gap-1  capitalize ">
            {(getValue() as IInvoiceStatus).split('_').join(' ').toLowerCase()}
          </div>
        ),
      },
      {
        id: 'paymentStatus',
        header: 'Payment Status',
        accessorKey: 'paymentStatus',
        cell: ({ getValue }) => (
          <div className=" w-full flex flex-col gap-1 ">
            <div className="text-xs text-muted-foreground w-full truncate text-ellipsis">
              <Badge
                className={cn(
                  getValue() === 'PAID' && 'bg-green-700 hover:bg-green-700 hover:text-white text-white',
                  getValue() === 'UNPAID' && 'bg-red-500 text-white hover:bg-red-500 hover:text-white',
                  getValue() === 'PARTIALLY_PAID' && 'bg-yellow-400 text-black hover:bg-yellow-400',
                )}
              >
                {(getValue() as string).split('_').join(' ')}
              </Badge>
            </div>
          </div>
        ),
      },

      {
        id: 'actions',
        enableHiding: false,
        size: 70,
        minSize: 70,
        cell: ({ row }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem asChild>
                  <Link to={`/invoice/view/${row.original.id}`}>View invoice details</Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    [unit],
  );

  const handleInvoiceStatusSelect = (option: IOption) => () => {
    if (selectedInvoiceStatus.find((item) => item.value === option.value)) {
      setSelectedInvoiceStatus(selectedInvoiceStatus.filter((item) => item.value !== option.value));
      setSearchParams(
        (prev) => {
          prev.set(
            'invoiceStatus',
            selectedInvoiceStatus
              .filter((item) => item.value !== option.value)
              .map((item) => `${item.value}:${item.label}`)
              .join(','),
          );
          return prev;
        },
        {
          replace: true,
        },
      );
    } else {
      setSelectedInvoiceStatus([
        ...selectedInvoiceStatus,
        {
          label: option.label,
          value: option.value,
        },
      ]);
      setSearchParams(
        (prev) => {
          prev.set(
            'invoiceStatus',
            [
              ...selectedInvoiceStatus,
              {
                label: option.label,
                value: option.value,
              },
            ]
              .map((item) => `${item.value}:${item.label}`)
              .join(','),
          );

          return prev;
        },
        {
          replace: true,
        },
      );
    }
  };

  const tableData = useMemo(() => {
    return invoicesResponse?.data.docs || [];
  }, [invoicesResponse]);

  const defaultColumnOrder = useMemo(() => columns.map((column) => column.id || ''), [columns]);

  const { storedValue: columnOrder, setValue: setColumnOrder } = useLocalStorage(
    INVOICE_COLUMN_ORDER_STORAGE_KEY,
    defaultColumnOrder,
  );

  const defaultColumnVisibility = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.id || ''] = true;
      return acc;
    }, {} as VisibilityState);
  }, [columns]);

  const { storedValue: columnVisibility, setValue: setColumnVisibilityValue } = useLocalStorage(
    INVOICE_COLUMN_VISIBILITY_STORAGE_KEY,
    defaultColumnVisibility,
  );

  const table = useReactTable({
    data: tableData,
    columns,
    onRowSelectionChange: (value) => setRowSelection(value),
    getRowId: (row) => row.id,
    onColumnVisibilityChange: setColumnVisibilityValue,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 50,
    },
    state: {
      rowSelection,
      columnOrder,
      columnVisibility,
    },
  });

  const tableColumns = useMemo(() => table.getAllColumns(), [table]);

  const handleExport = useCallback(() => {
    const rowsToExport = invoicesResponse?.data.docs.filter((row) => !!rowSelection[row.id]);
    exportExcel(rowsToExport!, new Set(tableColumns.filter((col) => col.getIsVisible()).map((col) => col.id)));
  }, [exportExcel, invoicesResponse?.data.docs, rowSelection, tableColumns]);

  if (isLoading) {
    return <TableSkeleton columns={10} rows={10} />;
  }

  if (!invoicesResponse) {
    return <Empty title="No data available" />;
  }

  return (
    <div>
      <div className="flex items-center justify-between my-4 ">
        <div className=" flex items-center ">
          {showInTableFilters && (
            <div className=" flex items-center gap-2 ">
              <InvoiceStatusDropdown
                disabled={!!filterLabel?.trim()}
                handleSelect={handleInvoiceStatusSelect}
                selected={selectedInvoiceStatus}
              />
              {!!filterLabel?.trim() && <FilterBadge label={filterLabel ?? ''} reset={resetFilters} />}
            </div>
          )}
        </div>
        <div className="flex justify-between w-full ">
          <div className="flex items-center gap-4 truncate text-ellipsis ">
            {!!showSummary && (
              <>
                {!isSummaryLoading ? (
                  <h2 className=" text-lg font-semibold">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <div
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            className="w-[500px] text-left font-light	"
                          >
                            <span className="text-sm font-normal">
                              Count: {invoicesSummaryResponse?.data.invoiceCount}, Due/Overdue:{' '}
                              {formatCurrencyByUnit(invoicesSummaryResponse?.data.invoiceOutstandingAmount || 0, unit)},
                              Total: {formatCurrencyByUnit(invoicesSummaryResponse?.data.invoiceAmount || 0, unit)}
                            </span>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          {
                            <span className=" text-sm font-normal ">
                              Count: {invoicesSummaryResponse?.data.invoiceCount}, Due/Overdue:{' '}
                              {formatCurrencyByUnit(invoicesSummaryResponse?.data.invoiceOutstandingAmount || 0, unit)},
                              Total: {formatCurrencyByUnit(invoicesSummaryResponse?.data.invoiceAmount || 0, unit)}
                            </span>
                          }
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </h2>
                ) : (
                  <div className=" flex flex-col gap-1 mt-2 ">
                    <Skeleton className=" min-w-[500px] w-[160px] h-4" />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex justify-end items-center gap-4">
            <ActionButtons handleExport={handleExport} table={table} />
            <ColumnSelector setColumnOrder={setColumnOrder} columnOrder={columnOrder} columns={tableColumns} />
            <TableUnitsTabs value={unit} onValueChange={handleUnitChange} />
          </div>
        </div>
      </div>
      <div className=" overflow-auto ">
        <ResizableTable headersToExcludeResizing={['select']} table={table} />
      </div>
      <Pagination
        hasNext={!!invoicesResponse?.data?.hasNext}
        hasPrev={!!invoicesResponse?.data?.hasPrev}
        onPageChange={setPage}
        onRowsPerPageChange={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        pageNumber={page}
        totalPages={invoicesResponse?.data?.totalPages}
      />
    </div>
  );
};

export default InvoicesTable;
