import { dataAxiosInstance } from '@/lib/axios';
import { ICreateInvoiceToCreditNotePostingRequestObject, ICreditNotesFilters } from '@/types/credit-notes.types';
import dayjs from 'dayjs';

export const getCreditNotesByFilters = async (
  filters: Partial<ICreditNotesFilters> & { page: number; limit: number },
) => {
  let url = `/creditNote/search?page=${filters.page}&pageSize=${filters.limit}`;

  if (filters.creditNoteNumber?.length) {
    url += filters.creditNoteNumber.map((number) => `&creditNoteNumber=${number}`).join('');
  }

  if (filters.customers?.length) {
    url += filters.customers.map((customer) => `&customerId=${customer.value}`).join('');
  }

  if (filters.invoiceNumber?.length) {
    url += filters.invoiceNumber.map((number) => `&invoiceNumber=${number}`).join('');
  }

  if (filters.status?.length) {
    url += filters.status.map((status) => `&status=${status}`).join('');
  }

  if (filters.type?.length) {
    url += filters.type.map((type) => `&type=${type}`).join('');
  }

  if (filters.dateRange?.from && filters.dateRange?.to) {
    url += `&startDate=${dayjs(filters.dateRange.from).format('YYYY-MM-DD')}&endDate=${dayjs(
      filters.dateRange.to,
    ).format('YYYY-MM-DD')}`;
  }

  if (
    filters.amount &&
    filters.amount[0] !== undefined &&
    filters.amount[1] !== undefined &&
    filters.amount[0] !== null &&
    filters.amount[1] !== null
  ) {
    url += `&maxAmount=${filters.amount[1]}&minAmount=${filters.amount[0]}`;
  }

  const result = await dataAxiosInstance.get(url);

  return result.data;
};

export const getCreditNoteById = async ({ creditNoteId }: { creditNoteId: string }) => {
  const result = await dataAxiosInstance.get(`/creditNote/${creditNoteId}`);
  return result.data;
};

export const createBulkInvoicesToCreditNotesPosting = async ({
  createBulkInvoicesToCreditNotesPosting,
}: {
  createBulkInvoicesToCreditNotesPosting: ICreateInvoiceToCreditNotePostingRequestObject[];
}) => {
  const result = await dataAxiosInstance.post('/creditNote/bulkPostToInvoices', {
    createBulkCreditNotePosting: createBulkInvoicesToCreditNotesPosting,
  });
  return result.data;
};

export const deleteCreditNoteToInvoicePosting = async ({ postingId }: { postingId: string }) => {
  const result = await dataAxiosInstance.delete(`/creditNote/deleteAdjustment/${postingId}`);
  return result.data;
};
