import { BubbleMenu as BaseBubbleMenu, Editor } from '@tiptap/react';
import { useCallback, useState } from 'react';

import { LinkEditorPanel } from './panels/LinkEditorPanel';
import { LinkPreviewPanel } from './panels/LinkPreviewPanel';

export const LinkMenu = ({
  editor,
  appendTo,
}: {
  editor: Editor;
  appendTo?: React.RefObject<Element | 'parent' | ((_ref: Element) => Element) | undefined>;
  shouldHide?: boolean;
}): JSX.Element => {
  const [showEdit, setShowEdit] = useState(false);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('link');

    if (!editor.isFocused) {
      return false;
    }

    return isActive;
  }, [editor]);

  const { href: link, target } = editor.getAttributes('link');

  const handleEdit = useCallback(() => {
    setShowEdit(true);
  }, []);

  const onSetLink = useCallback(
    (url: string, openInNewTab?: boolean) => {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url, target: openInNewTab ? '_blank' : '' })
        .run();
      setShowEdit(false);
    },
    [editor],
  );

  const onUnsetLink = useCallback(() => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run();
    setShowEdit(false);
    return null;
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="textMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
          strategy: 'fixed',
        },
        appendTo: () => {
          return appendTo?.current as Element;
        },
        onHidden: () => {
          setShowEdit(false);
        },
      }}
    >
      {showEdit ? (
        <LinkEditorPanel initialUrl={link} initialOpenInNewTab={target === '_blank'} onSetLink={onSetLink} />
      ) : (
        <LinkPreviewPanel url={link} onClear={onUnsetLink} onEdit={handleEdit} />
      )}
    </BaseBubbleMenu>
  );
};

export default LinkMenu;
