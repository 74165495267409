'use client';

import { Section, SectionTypes } from '@/utils/email-builder/sections';
import { forwardRef } from 'react';

type SectionPreviewProps = React.HTMLAttributes<HTMLDivElement> & {
  section: Section<SectionTypes>;
};

const SectionPreview = forwardRef<HTMLDivElement, SectionPreviewProps>(({ section, ...props }, ref) => {
  return (
    <div ref={ref} {...props} suppressHydrationWarning>
      {section.label}
    </div>
  );
});

SectionPreview.displayName = 'SectionPreview';

export default SectionPreview;
