import { IExcelExportFormat } from '@/types/cashflow.types';
import {
  IWorkflowDryRunEmail,
  IWorkflowDryRunEmailAttachment,
  IWorkflowDryRunEmailCustomer,
  IWorkflowDryRunEmailInvoice,
  IWorkflowDryRunExportHeader,
} from '@/types/workflow.type';
import { useExportExcel } from '../utils/useExportExcel';
const getUniqueCustomerNames = ({ customers }: { customers: IWorkflowDryRunEmailCustomer[] }): string[] =>
  customers.reduce(
    (uniqueNames, { customerName }) =>
      uniqueNames.includes(customerName) ? uniqueNames : [...uniqueNames, customerName],
    [] as string[],
  );

const flattenArrayToObject = (
  arr: IWorkflowDryRunEmail[],
  selectedColumns: Set<string>,
  dryRunDetails: IWorkflowDryRunExportHeader,
): IExcelExportFormat[] => {
  return arr
    .map((item) =>
      Object.entries(item).reduce((acc, [key, value]) => {
        if (!selectedColumns.has(key)) {
          return acc;
        }

        const formatted = key.replace(/([A-Z])/g, ' $1');
        if (key === 'customers') {
          const customers = value as IWorkflowDryRunEmailCustomer[];
          const uniqueCustomerNames = getUniqueCustomerNames({ customers });
          return {
            ...acc,
            ['Customer Name']: uniqueCustomerNames.join(', ') ?? '',
            ['Buyer ID']: customers[0].buyerId ?? '',
          };
        } else if (key === 'invoices') {
          const invoices = value as IWorkflowDryRunEmailInvoice[];
          return {
            ...acc,
            ['Invoices']: invoices.map(({ invoiceNumber }) => invoiceNumber).join(', '),
          };
        } else if (key === 'attachments') {
          const attachments = value as IWorkflowDryRunEmailAttachment[];
          return {
            ...acc,
            ['Attachments']: attachments.map(({ attachmentName }) => attachmentName).join(', '),
          };
        } else if (['to', 'cc', 'bcc'].includes(key)) {
          return { ...acc, [key.toUpperCase()]: value.join(', ') };
        } else {
          return { ...acc, [formatted.charAt(0).toUpperCase() + formatted.slice(1)]: value };
        }
      }, {} as IExcelExportFormat),
    )
    .map((obj) => ({
      'Action Type': 'Email',
      ...Object.fromEntries(
        Object.entries(dryRunDetails).map(([key, value]) => [
          key === 'initiatedBy' ? 'By' : key === 'initiatedOn' ? 'On' : key == 'testRunname' ? 'Test Run' : '',
          value,
        ]),
      ),
      ...obj,
    }));
};
export const useDryRunExport = ({ onSuccess, onError }: { onSuccess?: (_: string) => void; onError?: () => void }) => {
  const { action, isLoading } = useExportExcel({
    onSuccess,
    onError,
  });

  return {
    exportExcel: (
      data: IWorkflowDryRunEmail[],
      selectedColumns: Set<string>,
      headerForExcel: IWorkflowDryRunExportHeader,
    ) => action(flattenArrayToObject(data, selectedColumns, headerForExcel)),
    isLoading,
  };
};
