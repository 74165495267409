import InvoicesFilters from '@/components/invoices/InvoicesFilters';
import InvoicesTable from '@/components/invoices/InvoicesTable';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import Dropzone from '@/components/ui/dropzone';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { useToast } from '@/components/ui/use-toast';
import { useInvoiceUpload } from '@/hooks/api-hooks/useInvoiceQuery';
import { IInvoicesFilter } from '@/types/invoices.types';
import { useQueryClient } from '@tanstack/react-query';
import { FileCheck2Icon, Loader2Icon, PlusIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SubmitPage from './FileHandlingPages/SubmitPage';

const UploadDialog = () => {
  const [uploadedfile, setUploadedFile] = useState<File | null>(); // this will store the uploaded file by user
  const [fileStatus, setFileStatus] = useState<string | null>('NotUploaded'); // this will controll status of file upload
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);
  const queryClient = useQueryClient();

  const { toast } = useToast();
  const defaultValues: { file: File | null } = {
    file: null,
  };

  const [open, setOpen] = useState(false);

  const handleFormSubmit = () => {
    if (uploadedfile) {
      invoiceUpload({ file: uploadedfile, isValidationOnly: false });
    }
  };

  const handleSubmitConfirmation = () => {
    queryClient.resetQueries({ queryKey: ['getInvoiceByFilters'] });
  };
  const { mutate: invoiceUpload, isPending } = useInvoiceUpload({
    customConfig: {
      onSuccess: () => {
        try {
          setFileStatus('Validated');
        } catch (error) {
          console.error('Error parsing JSON:', error);
          toast({
            title: 'Error',
            description: 'Error occured while parsing file',
            variant: 'destructive',
          });
          setFileStatus('NotUploaded');
        }
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'Error occured while uploading invoices',
          variant: 'destructive',
        });
        setFileStatus('NotUploaded');
        setOpen(true);
      },
    },
  });

  const methods = useForm({
    defaultValues,
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
  });

  useEffect(() => {
    if (!open) {
      setFileStatus('NotUploaded');
      methods.setValue('file', null);
      setNextButtonDisabled(true);
      setUploadedFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function handleOnDrop(acceptedFiles: FileList | null) {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const allowedTypes = [
        { name: 'csv', types: ['text/csv'] },
        {
          name: 'excel',
          types: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        },
      ];
      const fileType = allowedTypes.find((allowedType) =>
        allowedType.types.find((type) => type === acceptedFiles[0].type),
      );
      if (!fileType) {
        methods.setValue('file', null);
        methods.setError('file', {
          message: 'File type is not valid',
          type: 'typeError',
        });
      } else {
        methods.setValue('file', acceptedFiles[0]);
        methods.getValues('file') && setUploadedFile(methods.getValues('file'));
        setNextButtonDisabled(false);
        methods.clearErrors('file');
      }
    } else {
      methods.setValue('file', null);
      methods.setError('file', {
        message: 'File is required',
        type: 'typeError',
      });
    }
  }
  return (
    <Dialog
      open={open}
      onOpenChange={(isModalOpen) => {
        methods.clearErrors('file');
        methods.setValue('file', null);
        setOpen(isModalOpen);
      }}
    >
      <DialogTrigger>
        <Button className=" flex items-center gap-4 ">
          Add Invoices
          <PlusIcon className=" w-4 h-4 " />
        </Button>
      </DialogTrigger>
      {fileStatus == 'NotUploaded' ? (
        <DialogContent fileStatus={fileStatus} className="w-full md:w-[800px] border-2">
          <Card className="border-none shadow-none  p-2  ">
            <CardTitle className=" px-4 text-xl mb-4 ">Import Invoices - Select File</CardTitle>
            <CardContent className="px-0">
              <FormProvider {...methods}>
                <form
                  className="flex flex-col items-center justify-center w-full"
                  onSubmit={methods.handleSubmit(handleFormSubmit)}
                  noValidate
                  autoComplete="off"
                >
                  <div className=" bg-muted w-full pt-2 pb-4 px-4 ">
                    <FormField
                      control={methods.control}
                      name="file"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormControl>
                            <Dropzone
                              {...field}
                              dropMessage="Select an invoice import file or drag and drop here"
                              handleOnDrop={handleOnDrop}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className=" text-xs  mt-2 ">
                    Download a{' '}
                    <a href="/sample_invoice_upload_file.csv" target="_blank" className="text-blue-700 underline">
                      sample csv file
                    </a>{' '}
                    for importing invoices
                    <br />
                  </div>
                  <div className=" text-xs mt-2">
                    Here is a{' '}
                    <a
                      href="https://docs.google.com/spreadsheets/d/1T4k0AM1yIMWhZZWy0UE4pm5ZiZIf0z3aWx-N-1nNGmE/edit#gid=0"
                      target="_blank"
                      className="text-blue-700 underline"
                      rel="noreferrer"
                    >
                      reference
                    </a>{' '}
                    containing definitions for each column
                    <br />
                  </div>

                  {methods.watch('file') && (
                    <div
                      className="flex items-center justify-center gap-3 p-4 relative"
                      style={{ wordWrap: 'break-word' }}
                    >
                      <FileCheck2Icon className="h-4 w-4" />
                      <p className="text-sm font-medium  w-[260px]" style={{ wordWrap: 'break-word' }}>
                        {methods.watch('file')?.name}
                      </p>
                    </div>
                  )}

                  <div className="w-full px-4 flex justify-end gap-4 mt-4 ">
                    <Button onClick={() => setOpen(false)} variant="outline" type="button">
                      Cancel
                    </Button>
                    {!isPending ? (
                      <Button type="submit" disabled={nextButtonDisabled}>
                        Next
                      </Button>
                    ) : (
                      <Loader2Icon className="w-8 h-8 mt-[3px] animate-spin" />
                    )}
                  </div>
                </form>
              </FormProvider>
            </CardContent>
          </Card>
        </DialogContent>
      ) : fileStatus == 'Validated' ? (
        <DialogContent fileStatus={fileStatus} className="w-[783px] h-[500px]">
          <SubmitPage
            fileType={'Invoice'}
            uploadedFile={uploadedfile}
            setOpen={setOpen}
            setFileStatus={setFileStatus}
            handleSubmitConfirmation={handleSubmitConfirmation}
          />
        </DialogContent>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

const AddInvoice = () => {
  return (
    <div className=" py-2 mb-8 border-b flex justify-between">
      <h3 className="text-base ">Import invoices via upload</h3>
      <UploadDialog />
    </div>
  );
};

const InvoicePage = () => {
  const [invoiceFilters, setInvoiceFilters] = useState<Partial<IInvoicesFilter>>({});

  const handleSubmit = useCallback((filters: Partial<IInvoicesFilter>) => {
    setInvoiceFilters(filters);
  }, []);

  return (
    <div className="md:px-16 sm:px-8 px-4 flex-1 my-8">
      <AddInvoice />
      <InvoicesFilters handleSubmit={handleSubmit} isPostingRequest={false} />
      <InvoicesTable filters={invoiceFilters} />
    </div>
  );
};

export default InvoicePage;
