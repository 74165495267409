import { cn } from '@/lib/utils';
import { Skeleton } from '../ui/skeleton';

const LabelValueDescriptionLayout = ({
  label,
  value,
  description,
  className,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn(' w-full flex items-center gap-8 text-sm flex-wrap h-10 ', className)}>
      <div className=" min-w-[150px] max-w-[150px] font-semibold ">{label}</div>
      <div className=" min-w-[200px] max-w-[200px]">{value}</div>
      <div className=" flex-1 ">{description}</div>
    </div>
  );
};

const LoadingLabelValueDescriptionLayout = () => {
  return (
    <div className=" flex items-center gap-4 ">
      <Skeleton className=" w-64  h-4" />
      <Skeleton className=" w-64  h-4" />
    </div>
  );
};

export { LabelValueDescriptionLayout, LoadingLabelValueDescriptionLayout };
