import {
  getDateRangeForBankTransactions,
  getFilteredBankTransactions,
  updateCategoryForBankTransaction,
  updateCustomerSuggestion,
} from '@/api-functions/bank-transactions.api';
import {
  IBankAccountTransactionDateRangeResponse,
  IBankTransactionsEditOptions,
  IBankTransactionsResponse,
  ICustomerSuggestionUpdateRequest,
} from '@/types/bank-transactions.types';
import { IApiResponse } from '@/types/common.types';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PostingQueryKeys } from './usePostingQuery';

const BankTransactionsQueryKeys = {
  getFilteredBankTransactions: (
    page: number,
    limit: number,
    companyId: string,
    bankAccounts: string[],
    categories: string[],
    startDate?: Date,
    endDate?: Date,
    narration?: string,
  ) => [
    'getFilteredBankTransactions',
    page,
    limit,
    companyId,
    ...bankAccounts,
    ...categories,
    startDate,
    endDate,
    narration,
  ],
  getDateRangeForBankTransactions: (companyId: string) => ['getDateRangeForBankTransactions', companyId],
};

export const useGetFilteredBankTransactions = ({
  page,
  limit,
  companyId,
  customConfig,
  bankAccounts,
  categories,
  startDate,
  endDate,
  narration,
}: {
  page: number;
  limit: number;
  companyId: string;
  bankAccounts: string[];
  categories: string[];
  startDate?: Date;
  endDate?: Date;
  narration?: string;
  customConfig?: Omit<UseQueryOptions<IBankTransactionsResponse>, 'queryKey'>;
}) => {
  const getBankTransactionsByFiltersQuery = useQuery<IBankTransactionsResponse>({
    queryKey: BankTransactionsQueryKeys.getFilteredBankTransactions(
      page,
      limit,
      companyId,
      bankAccounts,
      categories,
      startDate,
      endDate,
      narration,
    ),
    queryFn: () =>
      getFilteredBankTransactions({
        page,
        limit,
        companyId,
        bankAccounts,
        categories,
        startDate,
        endDate,
        narration,
      }),
    ...customConfig,
    select(data) {
      return {
        ...data,
        data: {
          ...data.data,
          docs: data.data.docs.map((transaction) => ({
            ...transaction,
            amount: transaction.isCredit ? transaction.creditAmount : transaction.debitAmount,
          })),
        },
      };
    },
  });

  return getBankTransactionsByFiltersQuery;
};

export const usePostUpdateBankAccountTransactionCategory = ({
  customConfig,
}: {
  customConfig: UseMutationOptions<unknown, unknown, { transactionsToEdit: IBankTransactionsEditOptions[] }>;
}) => {
  const postUpdateBankAccountTransactionCategoryMutation = useMutation<
    unknown,
    unknown,
    { transactionsToEdit: IBankTransactionsEditOptions[] }
  >({
    mutationFn: ({ transactionsToEdit }: { transactionsToEdit: IBankTransactionsEditOptions[] }) =>
      updateCategoryForBankTransaction({ transactionsToEdit }),
    ...customConfig,
  });

  return postUpdateBankAccountTransactionCategoryMutation;
};

export const useGetBankAccountTransactionDateRange = ({
  companyId,
  customConfig,
}: {
  companyId: string;
  customConfig?: Omit<UseQueryOptions<IBankAccountTransactionDateRangeResponse>, 'queryKey'>;
}) => {
  const getBankAccountTransactionDateRangeQuery = useQuery({
    queryKey: BankTransactionsQueryKeys.getDateRangeForBankTransactions(companyId),
    queryFn: () => getDateRangeForBankTransactions(),
    ...customConfig,
  });

  return getBankAccountTransactionDateRangeQuery;
};

export const usePostUpdateCustomerSuggestions = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<unknown, AxiosError<IApiResponse<string>>, ICustomerSuggestionUpdateRequest>;
}) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError<IApiResponse<string>>, ICustomerSuggestionUpdateRequest>({
    mutationFn: (customerSuggestionUpdateRequest: ICustomerSuggestionUpdateRequest) =>
      updateCustomerSuggestion(customerSuggestionUpdateRequest),
    ...customConfig,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes('getCredits');
        },
      });
      queryClient.invalidateQueries({
        queryKey: PostingQueryKeys.getCreditById({
          creditId: variables.bankTransactionId,
        }),
      });
      customConfig?.onSuccess?.(data, variables, context);
    },
  });
};
