import { Button } from '@/components/ui/button';
import { useDeleteCreditNoteToInvoicePosting } from '@/hooks/api-hooks/useCreditNotesQuery';
import { IPostedToInvoiceObject } from '@/types/posting.types';
import { Loader2Icon } from 'lucide-react';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '../../../ui/dialog';
import { useToast } from '../../../ui/use-toast';
import PostedCreditNoteDescription from './PostedCreditNoteDescription';

const DeleteAppliedCreditNotePostingDialog = ({
  isDeleteDialogOpen,
  setIsDeleteDialogOpen,
  postingObject,
  invoiceNumber,
}: {
  isDeleteDialogOpen: boolean;
  setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  postingObject: IPostedToInvoiceObject;
  invoiceNumber: string;
}) => {
  const { toast } = useToast();

  const { mutate: deleteCredit, isPending } = useDeleteCreditNoteToInvoicePosting({
    creditNoteId: postingObject.creditNote?.id || '',
    invoiceId: postingObject.invoiceId || '',
    customConfig: {
      onError(error) {
        toast({
          title: 'Error',
          description: error.response?.data.message || 'Unable to credit note adjustment',
          variant: 'destructive',
        });
        setIsDeleteDialogOpen(false);
      },
      onSuccess() {
        toast({
          description: 'Credit Note adjustment deleted successfully',
        });
        setIsDeleteDialogOpen(false);
      },
    },
  });

  return (
    isDeleteDialogOpen && (
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
          <DialogHeader className=" font-semibold border-b py-4 ">Delete applied credit note</DialogHeader>
          <div className=" border rounded-lg p-4 ">
            <PostedCreditNoteDescription
              createdAt={postingObject.timestamp}
              createdBy={postingObject.createdBy}
              creditNoteId={postingObject.creditNote?.id || ''}
              creditNoteNumber={postingObject.creditNote?.creditNoteNumber || ''}
            />
          </div>
          <p className="text-sm">
            Are you sure you want to delete this posted credit note {invoiceNumber ? `from ${invoiceNumber}` : ''}?
            These changes cannot be reversed.
          </p>
          <DialogFooter className=" border-t py-4 ">
            <Button onClick={() => setIsDeleteDialogOpen(false)} variant="outline">
              Cancel
            </Button>
            <Button
              className=" flex items-center gap-2 "
              disabled={isPending}
              variant="destructive"
              onClick={() =>
                deleteCredit({
                  postingId: postingObject.postingId,
                })
              }
            >
              Delete
              {isPending && <Loader2Icon className="w-4 h-4 animate-spin" />}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  );
};

export default DeleteAppliedCreditNotePostingDialog;
