import { IOptions } from '@/types/common.types';
import { getClassNamesForSelectPurpose, getStylesForSelect } from '@/utils/getStylesForSelect';
import { validateEmail } from '@/utils/validateEmail';
import { useEffect, useMemo, useState } from 'react';
import { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';

const MultiEmailInput = ({
  value,
  onChange,
  placeholder,
}: {
  value: string[];
  onChange: (_: string[]) => void;
  placeholder?: string;
}) => {
  const [inputValue, setInputValue] = useState('');
  const [emailValue, setEmailValue] = useState<string[]>([]);

  const handleValueChange = (
    value: MultiValue<{
      label: string;
      value: string;
    }>,
  ) => {
    setEmailValue(value.map((v) => v.value));
    onChange(value.map((v) => v.value));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!inputValue) return;

    switch (e.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
      case ',':
        if (!validateEmail(inputValue)) {
          return;
        }

        setEmailValue([...emailValue, inputValue]);
        onChange([...emailValue, inputValue]);

        setInputValue('');
        e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    // @ts-expect-error event error
    if (e.target?.id === 'multi-email-input') {
      const emails = e.clipboardData
        .getData('text/plain')
        .split(',')
        .map((email) => {
          if (!validateEmail(email.trim())) {
            return '';
          }
          return email.trim();
        })
        .filter(Boolean);

      setEmailValue([...emailValue, ...emails]);
      onChange([...emailValue, ...emails]);
    }

    e.preventDefault();
  };

  const selectClasses = useMemo(() => {
    return getClassNamesForSelectPurpose();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<false | true, IOptions>();
  }, []);

  useEffect(() => {
    setEmailValue(value);
  }, [value]);

  return (
    <div onPaste={handlePaste}>
      <AsyncSelect
        id="multi-email-input"
        inputId="multi-email-input"
        className=" min-w-[150px] max-w-[360px] w-full text-sm shadow-sm"
        inputValue={inputValue}
        isMulti={true}
        onInputChange={(newValue, action) => {
          if (action.action === 'input-change') {
            setInputValue(newValue);
          }
        }}
        components={{
          DropdownIndicator: () => null,
        }}
        onChange={handleValueChange}
        menuIsOpen={false}
        onKeyDown={handleKeyDown}
        value={emailValue.map((v) => ({ label: v, value: v }))}
        placeholder={placeholder || 'Enter email ids'}
        styles={selectStyles}
        classNames={selectClasses}
      />
    </div>
  );
};

export default MultiEmailInput;
