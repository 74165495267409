import { Button } from '@/components/ui/button';
import { CardTitle } from '@/components/ui/card';

const SubmitPage = ({
  fileType,
  setOpen,
  setFileStatus,
  uploadedFile,
  handleSubmitConfirmation,
}: {
  fileType: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFileStatus: (_: string | null) => void;
  uploadedFile: File | null | undefined;
  handleSubmitConfirmation: () => void;
}) => {
  return (
    <div className="w-[783px] h-[530px] sm:rounded-lg border-2  bg-white   m-auto">
      <div className="h-[54px] border-b-2	black ">
        <div className="border-none shadow-none  p-2 ">
          <CardTitle className=" px-4 text-xl  mb-4 ">
            {fileType == 'Invoice' ? 'Import Invoices - Confirm' : 'Import Customers - Confirm'}
          </CardTitle>
        </div>
      </div>
      <div className="h-[392px] bg-gray-100 pt-[20px]">
        <div className="h-[360px] flex pb-[8px]">
          <div
            className={`flex-1 ml-[20px] text-sm  w-[400px]	font-normal leading-[46px]`}
            style={{ wordWrap: 'break-word' }}
          >
            <h3>{`Input File: ${uploadedFile && uploadedFile.name}`}</h3>
            <h3>{`You will be notified over email once the ${fileType}s are uploaded`} </h3>
          </div>
        </div>
      </div>

      <div className="mt-[8px] mr-[8px] p-[10px] pr-[1rem]" style={{ float: 'right' }}>
        <Button
          type="submit"
          onClick={() => {
            setOpen(false);
            setFileStatus('NotUploaded');
            handleSubmitConfirmation();
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default SubmitPage;
