import { cn } from '@/lib/utils';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { CheckIcon } from 'lucide-react';
import { useState } from 'react';
import { Button } from './button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

interface IOption {
  value: string;
  label: string;
}
const ComboboxComponent = ({
  options,
  onChange,
  placeholder,
  value,
  className,
}: {
  options: IOption[];
  onChange?: (_: string) => void;
  placeholder?: string;
  value?: {
    value: string;
    label: string;
  };
  className?: string;
  inputClassName?: string;
}) => {
  const [inputSearch, setInputSearch] = useState<string>('');

  const [open, setOpen] = useState(false);

  const handleSelect = (value: string) => () => {
    onChange?.(value);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          onClick={() => setOpen(true)}
          variant="outline"
          role="combobox"
          className={cn('justify-between', className)}
        >
          {value && value?.value ? value.label : placeholder}
          <CaretSortIcon className="ml-2 h-4 w-4  shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[250px]" align="start">
        <Command>
          <CommandInput
            value={inputSearch}
            onValueChange={setInputSearch}
            placeholder="Search value..."
            className="h-9"
          />
          <CommandEmpty>No option found.</CommandEmpty>
          <CommandGroup className="h-[300px] overflow-y-scroll text-sm">
            {options.map((option) => (
              <CommandItem key={option.value} value={option.label} onSelect={handleSelect(option.value)}>
                <div className="flex items-center gap-2">
                  {option.value === value?.value && <CheckIcon className="w-4 h-4" />}
                  {option.label}
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default ComboboxComponent;
