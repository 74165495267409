import { CopyIcon } from 'lucide-react';
import { useToast } from './use-toast';

export const CopyToClipboard = ({ text, copyText }: { text: string; copyText?: string }) => {
  const { toast } = useToast();

  const handleClick = () => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        description: copyText ?? 'Copied to clipboard',
      });
    });
  };

  return <CopyIcon onClick={handleClick} className=" w-3 h-3 flex-1 max-w-[12px] cursor-pointer " />;
};
