import { postShortenUrl } from '@/api-functions/url-shortener.api';
import { IShortUrlRequest, IShortUrlResponse } from '@/types/url-shortener.type';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useGetShortUrlMutation = ({
  customConfig,
}: {
  customConfig?: UseMutationOptions<IShortUrlResponse, AxiosError<IShortUrlResponse>, IShortUrlRequest>;
}) => {
  const getShortUrlMutation = useMutation<IShortUrlResponse, AxiosError<IShortUrlResponse>, IShortUrlRequest>({
    mutationFn: postShortenUrl,
    ...customConfig,
    onSettled(data, error, variables, context) {
      customConfig?.onSettled?.(data, error, variables, context);
    },
  });

  return getShortUrlMutation;
};
