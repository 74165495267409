import { getAgeingReportExport } from '@/api-functions/ageing.api';
import { AgeingReportFilter } from '@/types/ageing.types';
import { IApiResponse } from '@/types/common.types';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useAgeingReportExport = (): UseMutationResult<
  unknown,
  AxiosError<IApiResponse<string>>,
  Partial<AgeingReportFilter>
> => {
  return useMutation<unknown, AxiosError<IApiResponse<string>>, Partial<AgeingReportFilter>>({
    mutationFn: getAgeingReportExport,
  });
};
