import { Button } from '@/components/ui/button';
import { useDeleteTemplate } from '@/hooks/api-hooks/useEmailTemplatesQuery';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '../ui/dialog';
import { useToast } from '../ui/use-toast';

const DeleteEmailTemplateDialog = ({
  templateId,
  isDeleteDialogOpen,
  setIsDeleteDialogOpen,
}: {
  templateId: string;
  isDeleteDialogOpen: boolean;
  setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { toast } = useToast();

  const { mutate: deleteEmailTemplate } = useDeleteTemplate({
    customConfig: {
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: error.response?.data.message || 'Unable to delete template',
        });
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
      },
    },
  });

  return (
    isDeleteDialogOpen && (
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
          <DialogHeader className=" font-semibold ">Delete template</DialogHeader>
          <p className="text-sm">Are you sure you want to delete this template?</p>
          <DialogFooter>
            <Button onClick={() => setIsDeleteDialogOpen(false)} variant="outline">
              Cancel
            </Button>
            <Button
              onClick={() =>
                deleteEmailTemplate({
                  templateId,
                })
              }
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  );
};

export default DeleteEmailTemplateDialog;
