import { Button } from '@/components/ui/button';

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { useGetCustomerSuggestionsMutation } from '@/hooks/api-hooks/useCustomerQuery';
import { ActivityEventStatus, ActivityOriginType, ActivityType, IActivityFilters } from '@/types/activity.types';
import { IOptions } from '@/types/common.types';
import { getClassNamesForSelect, getStylesForSelect } from '@/utils/getStylesForSelect';
import dayjs from 'dayjs';
import { XIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Select, { MultiValue } from 'react-select';

import AsyncSelect from 'react-select/async';
import MultiEmailInput from '../shared/MultiEmailInput';
import MultiInvoiceNumberInput from '../shared/MultiInvoiceInput';
import { Badge } from '../ui/badge';
import { Input } from '../ui/input';
import { DatePickerWithRange } from '../ui/multi-datepicker';
import SelectComponent from '../ui/select-component';

interface IActivityFiltersSearchParams extends Record<keyof Omit<IActivityFilters, 'dateRange'>, string> {
  fromDate: string;
  toDate: string;
}

const FilterList = ({
  filterList,
  setModalOpen,
  setFocusField,
}: {
  filterList: IOptions[];
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFocusField: React.Dispatch<React.SetStateAction<keyof Partial<IActivityFilters> | ''>>;
}) => {
  const filterKeyToLabelMap = useMemo<Record<keyof IActivityFiltersSearchParams, string>>(() => {
    return {
      activityType: 'Activity Type',
      activityEventStatus: 'Status',
      fromDate: 'From Date',
      toDate: 'To Date',
      keywordSearch: 'Keyword Search',
      sender: 'Sender',
      fromEmail: 'From Email',
      email: 'Email',
      subject: 'Subject',
      hasAttachments: 'Has Attachments',
      activityOriginReferenceId: 'Reference Id',
      activityOriginType: 'Activity Origin Type',
      customers: 'Customer',
      invoiceNumber: 'Invoice Number',
      deliveredStatus: 'Delivered Status',
    };
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  return filterList.map((filter) => (
    <Badge
      key={filter.label}
      className="rounded-full min-w-fit  flex items-center gap-2 py-2 px-4 bg-muted hover:bg-muted text-primary shadow-md"
    >
      <span
        className="cursor-pointer"
        onClick={() => {
          setModalOpen(true);
          setFocusField(filter.label as keyof IActivityFilters);
        }}
      >
        {filterKeyToLabelMap[filter.label as keyof typeof filterKeyToLabelMap]}:{' '}
        {filter.label === 'customers'
          ? filter.value
              .split(',')
              .map((item) => item.split(':')[0])
              .join(', ')
          : filter.value}
      </span>
      <span className=" cursor-pointer ">
        <XIcon
          className="w-4 h-4 text-primary"
          onClick={() => {
            if (filter.label === 'fromDate' || filter.label === 'toDate') {
              searchParams.delete(filter.label);
              searchParams.delete('toDate');
              searchParams.delete('fromDate');
              searchParams.delete('dateRange');
              setSearchParams(searchParams);
              return;
            }

            searchParams.delete(filter.label);
            setSearchParams(searchParams);
          }}
        />
      </span>
    </Badge>
  ));
};

const ActivityFilters = ({
  handleSubmit,
  activityFilters,
}: {
  handleSubmit: (_: Partial<IActivityFilters>) => void;
  activityFilters: Partial<IActivityFilters>;
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [focusedField, setFocusedField] = useState<keyof Partial<IActivityFilters> | ''>('');
  const [selectedDeliveredStatus, setSelectedDeliveredStatus] = useState<IOptions[]>([]);

  const {
    register,
    control,
    setFocus,
    watch,
    handleSubmit: handleFormSubmit,
  } = useForm<Partial<IActivityFilters>>({
    values: {
      ...activityFilters,
    },
  });

  useEffect(() => {
    if (focusedField) {
      setFocus(String(focusedField) as keyof Partial<IActivityFilters>);
    }
  }, [focusedField, setFocus]);

  const handleModalOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setFocusedField('');
    }
    setIsFilterModalOpen(isOpen);
  };

  const handleReset = () => {
    setSearchParams(
      {},
      {
        replace: true,
      },
    );
    setIsFilterModalOpen(false);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const filtersAsSearchParams: IActivityFiltersSearchParams = {
      activityType: searchParams.get('activityType') || '',
      customers: searchParams.get('customers') || '',
      fromDate: searchParams.get('fromDate') || '',
      toDate: searchParams.get('toDate') || '',
      hasAttachments: (searchParams.get('hasAttachments') as 'YES' | 'NO') || undefined,
      subject: searchParams.get('subject') || '',
      email: searchParams.get('toEmail') || '',
      activityOriginReferenceId: searchParams.get('activityOriginReferenceId') || '',
      activityOriginType: searchParams.get('activityOriginType') || '',
      invoiceNumber: searchParams.get('invoiceNumber') || '',
      deliveredStatus: searchParams.get('deliveredStatus') || '',
    };

    handleSubmit({
      activityType: !filtersAsSearchParams.activityType
        ? undefined
        : (filtersAsSearchParams.activityType as ActivityType),
      customers: filtersAsSearchParams.customers
        ? filtersAsSearchParams.customers
            .split(',')
            .filter((v) => !!v.trim() && v.split(':').length === 2)
            .map((item) => ({
              label: item.split(':')[0],
              value: item.split(':')[1],
            }))
        : undefined,
      dateRange: {
        from: filtersAsSearchParams.fromDate ? dayjs(filtersAsSearchParams.fromDate).toDate() : undefined,
        to: filtersAsSearchParams.toDate ? dayjs(filtersAsSearchParams.toDate).toDate() : undefined,
      },
      hasAttachments:
        filtersAsSearchParams.hasAttachments === 'YES'
          ? 'YES'
          : filtersAsSearchParams.hasAttachments === 'NO'
            ? 'NO'
            : undefined,
      subject: filtersAsSearchParams.subject ? filtersAsSearchParams.subject : undefined,
      email: filtersAsSearchParams.email ? filtersAsSearchParams.email.split(',') : undefined,
      activityOriginReferenceId: filtersAsSearchParams.activityOriginReferenceId || undefined,
      activityOriginType: !filtersAsSearchParams.activityOriginType
        ? undefined
        : (filtersAsSearchParams.activityOriginType as ActivityOriginType),
      invoiceNumber: filtersAsSearchParams.invoiceNumber
        ? filtersAsSearchParams.invoiceNumber.split(',').map((v) => v.trim())
        : undefined,
      deliveredStatus: filtersAsSearchParams.deliveredStatus
        ? filtersAsSearchParams.deliveredStatus.split(',').map((status) => status.trim() as ActivityEventStatus)
        : undefined,
    });
  }, [searchParams, handleSubmit]);

  const { mutateAsync: fetchCustomers } = useGetCustomerSuggestionsMutation({});

  const onSubmit = (data: Partial<IActivityFilters>) => {
    // handleSubmit
    const filtersAsSearchParams: IActivityFiltersSearchParams = {
      activityType: data.activityType || '',
      customers: data.customers?.map((c) => `${c.label}:${c.value}`).join(',') || '',
      fromDate: data.dateRange?.from ? dayjs(data.dateRange.from).format('YYYY-MM-DD') : '',
      toDate: data.dateRange?.to ? dayjs(data.dateRange.to).format('YYYY-MM-DD') : '',
      hasAttachments: data.hasAttachments || '',
      subject: data.subject || '',
      email: data.email?.join(',') || '',
      activityOriginReferenceId: data.activityOriginReferenceId || '',
      activityOriginType: data.activityOriginType || '',
      invoiceNumber: data.invoiceNumber?.join(',') || '',
      deliveredStatus: selectedDeliveredStatus.map((deliveredStatus) => deliveredStatus.value).join(','),
    };

    setSearchParams(
      {
        ...filtersAsSearchParams,
      },
      {
        replace: true,
      },
    );
    setIsFilterModalOpen(false);
  };

  const selectClasses = useMemo(() => {
    return getClassNamesForSelect();
  }, []);

  const selectStyles = useMemo(() => {
    return getStylesForSelect<true, IOptions>();
  }, []);

  const loadSelectOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 3) {
        return [];
      }
      const result = await fetchCustomers({
        query: inputValue.trim(),
      });
      return result.data.suggestions.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },
    [fetchCustomers],
  );

  const filtersAsSearchParams: IActivityFiltersSearchParams = useMemo(
    () => ({
      activityType: searchParams.get('activityType') || '',
      customers: searchParams.get('customers') || '',
      fromDate: searchParams.get('fromDate') || '',
      toDate: searchParams.get('toDate') || '',
      hasAttachments: (searchParams.get('hasAttachments') as 'YES' | 'NO') || undefined,
      keywordSearch: searchParams.get('keywordSearch') || '',
      sender: searchParams.get('sender') || '',
      subject: searchParams.get('subject') || '',
      activityOriginReferenceId: searchParams.get('activityOriginReferenceId') || '',
      activityOriginType: searchParams.get('activityOriginType') || '',
      invoiceNumber: searchParams.get('invoiceNumber') || '',
      deliveredStatus: searchParams.get('deliveredStatus') || '',
      email: searchParams.get('email') || '',
    }),
    [searchParams],
  );

  const filterList = useMemo(() => {
    return Object.entries(filtersAsSearchParams)
      .map(([key, value]) => {
        return {
          label: key,
          value: value,
        };
      })
      .filter((filter) => !!filter.value);
  }, [filtersAsSearchParams]);

  const activityType = watch('activityType');
  const handleDeliveredStatusChange = (value: MultiValue<IOptions>) => {
    const newValue = value.map((account) => ({ value: account.value, label: account.label }));
    setSelectedDeliveredStatus(newValue);
  };

  return (
    <>
      <div className=" flex  gap-4 items-center mb-4 mt-2">
        <Button onClick={() => setIsFilterModalOpen(true)} variant="outline" className=" relative ">
          Filters
          {filterList.length > 0 && (
            <div className="w-4 h-4 bg-black text-white rounded-xl mr-[-22px] mb-[28px]">
              <div className="text-center mb-auto mt-auto text-xs">{filterList.length}</div>
            </div>
          )}
        </Button>
        {filterList.length > 0 && (
          <Button variant="outline" onClick={handleReset}>
            Reset
          </Button>
        )}
        <div className=" flex items-center gap-2 flex-1 overflow-scroll ">
          <FilterList setFocusField={setFocusedField} setModalOpen={setIsFilterModalOpen} filterList={filterList} />
        </div>
      </div>
      {isFilterModalOpen && (
        <Dialog open={isFilterModalOpen} onOpenChange={handleModalOpenChange}>
          <DialogContent>
            <form onSubmit={handleFormSubmit(onSubmit)} className="w-[620px] border-2 bg-white	">
              <div className="h-[52px] p-4 flex  justify-between border-b">
                <div>
                  <DialogHeader>
                    <DialogTitle>Filters</DialogTitle>
                  </DialogHeader>
                </div>
                <div>
                  <XIcon
                    className="w-[20px] h-[20px] cursor-pointer"
                    onClick={() => {
                      setIsFilterModalOpen(false);
                    }}
                  />
                </div>
              </div>
              <div className=" flex flex-col gap-4 py-4 ">
                <div className=" w-full flex gap-4  px-4 justify-between">
                  <div className="min-w-[240px]  flex-1">
                    <Label className="text-right">Type</Label>
                    <Controller
                      control={control}
                      name="activityType"
                      render={({ field: { value, onChange, ...fields } }) => (
                        <SelectComponent
                          {...fields}
                          onChange={onChange}
                          className="w-full"
                          options={[
                            {
                              label: 'Email',
                              value: 'EMAIL',
                            },
                            {
                              label: 'Email Reply',
                              value: 'EMAIL_REPLY',
                            },
                          ]}
                          placeholder="Select activity type"
                          value={value || ''}
                        />
                      )}
                    />
                  </div>
                  <div className=" min-w-[240px] flex-1 ">
                    <Label className="text-right">Activity Date</Label>
                    <Controller
                      control={control}
                      rules={{
                        validate: (value) => {
                          if (value === undefined) {
                            return true;
                          }

                          if (value.from && value.to) {
                            return true;
                          }

                          if (value.from === value.to) {
                            return true;
                          }

                          return 'Please select date range';
                        },
                      }}
                      name="dateRange"
                      render={({ field: { value, onChange } }) => (
                        <DatePickerWithRange buttonClass="w-full " value={value} onChange={onChange} />
                      )}
                    />
                  </div>
                </div>
                <div className="flex gap-4 items-center  px-4 justify-between">
                  <div className="min-w-[240px] flex-1">
                    <Label className="text-right">Activity origin type</Label>
                    <Controller
                      control={control}
                      name="activityOriginType"
                      render={({ field: { value, onChange, ...fields } }) => (
                        <SelectComponent
                          {...fields}
                          className=" w-full "
                          options={[
                            {
                              label: 'Workflow',
                              value: 'WORKFLOW',
                            },
                          ]}
                          placeholder="Select activity origin type"
                          onChange={onChange}
                          value={value || ''}
                        />
                      )}
                    />
                  </div>
                  <div className="min-w-[240px] flex-1">
                    <Label className="text-right">Customers</Label>
                    <Controller
                      control={control}
                      name="customers"
                      render={({ field: { value, onChange } }) => (
                        <AsyncSelect
                          isMulti
                          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                          onChange={onChange}
                          value={value}
                          loadOptions={loadSelectOptions}
                          className=" min-w-[200px] text-sm shadow-sm"
                          styles={selectStyles}
                          placeholder="Search by customer name"
                          classNames={selectClasses}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex gap-4 items-center  px-4 justify-between">
                  <div className="min-w-[240px] flex-1">
                    <Label className="text-right">Invoice Numbers</Label>
                    <Controller
                      control={control}
                      name="invoiceNumber"
                      render={({ field: { value, onChange } }) => (
                        <MultiInvoiceNumberInput
                          value={value || []}
                          onChange={onChange}
                          placeholder="Search by invoice number"
                        />
                      )}
                    />
                  </div>
                  {(activityType === 'EMAIL' || activityType === 'EMAIL_REPLY') && (
                    <div className="min-w-[240px] flex-1">
                      <Label className="text-right">Delivery Status</Label>
                      <Select
                        onChange={handleDeliveredStatusChange}
                        value={selectedDeliveredStatus}
                        className=" min-w-[200px] text-sm shadow-sm"
                        classNames={selectClasses}
                        isMulti={true}
                        name="deliveredStatus"
                        options={[
                          {
                            label: 'Delivered to all',
                            value: 'ALL',
                          },
                          {
                            label: 'Delivered to some',
                            value: 'PARTIAL',
                          },
                          {
                            label: 'Not Delivered',
                            value: 'NONE',
                          },
                        ]}
                        classNamePrefix="select"
                        placeholder="Select delivery status"
                        styles={selectStyles}
                      />
                    </div>
                  )}
                </div>
                <div className="flex gap-4 items-center  px-4 justify-between">
                  {activityType && (activityType === 'EMAIL' || activityType === 'EMAIL_REPLY') && (
                    <div className="min-w-[240px] flex-1">
                      <Label className="text-right">Subject</Label>
                      <Input {...register('subject')} placeholder="Enter keyword to search" />
                    </div>
                  )}
                  {activityType && (activityType === 'EMAIL' || activityType === 'EMAIL_REPLY') && (
                    <div className="min-w-[240px] flex-1">
                      <Label className="text-right">Email</Label>
                      <Controller
                        control={control}
                        name="email"
                        render={({ field: { value, onChange } }) => (
                          <MultiEmailInput value={value || []} onChange={onChange} />
                        )}
                      />
                    </div>
                  )}
                </div>
                <div className="flex gap-4 items-center  px-4 justify-between">
                  {activityType && (activityType === 'EMAIL' || activityType === 'EMAIL_REPLY') && (
                    <div className="min-w-[240px] flex-1">
                      <Label className="text-right">Has attachments</Label>
                      <Controller
                        control={control}
                        name="hasAttachments"
                        render={({ field: { value, onChange } }) => (
                          <SelectComponent
                            options={[
                              {
                                label: 'Yes',
                                value: 'YES',
                              },
                              {
                                label: 'No',
                                value: 'NO',
                              },
                            ]}
                            onChange={onChange}
                            className=" w-full "
                            placeholder="Select whether activity has attachments"
                            value={value || ''}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="h-[64px] p-4 border-t">
                <DialogFooter>
                  <Button onClick={handleReset} variant="outline">
                    Reset
                  </Button>
                  <Button type="submit" disabled={false} className="flex items-center gap-2">
                    Apply
                  </Button>
                </DialogFooter>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default ActivityFilters;
