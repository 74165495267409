import { usePostUpdateReceivableDays } from '@/hooks/api-hooks/useCategoryQuery';
import { cn } from '@/lib/utils';
import { IBudgets, ICategory } from '@/types/budget.types';
import { ChevronRightIcon, InfoIcon } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Collapsible, CollapsibleContent } from '../ui/collapsible';
import { Input } from '../ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { useToast } from '../ui/use-toast';
import BudgetCell from './BudgetCell';

const ReceivableDaysCell = ({
  isAllocateReduce,
  isEditAllowed,
  category,
}: {
  isAllocateReduce: boolean;
  isEditAllowed: boolean;
  category: {
    name: string;
    id: string;
  };
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [receivableDays, setReceivableDays] = useState(0);
  const { toast } = useToast();
  const { mutate: updateReceivableDays, isError } = usePostUpdateReceivableDays({
    categoryId: category.id,
    customConfig: {
      onSuccess: () => {
        setIsEditable(false);
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'Failed to update receivable days.',
          variant: 'destructive',
        });
      },
    },
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeReceivableDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReceivableDays(Number(e.target.value));
  };

  const handleClick = () => {
    if (!isEditAllowed) {
      toast({
        title: "Can't edit this category",
        description: `The value is populated based on the subcategories of ${category.name}`,
        variant: 'destructive',
      });
      return;
    }
    setIsEditable(true);
  };

  const handleBlur = () => {
    updateReceivableDays({
      receivableDays,
    });
  };

  const canEdit = useMemo(() => {
    return isEditable && !isAllocateReduce && isEditAllowed;
  }, [isEditable, isAllocateReduce, isEditAllowed]);

  useEffect(() => {
    if (canEdit) {
      inputRef.current?.focus();
    }
  }, [canEdit]);

  return (
    <div
      className={cn(
        'min-w-[180px] max-w-[180px] border-r text-center flex justify-center items-center py-3 px-4 border-b',
        isError && 'border border-red-400',
      )}
      onClick={handleClick}
    >
      {canEdit ? (
        <Input
          ref={inputRef}
          onBlur={handleBlur}
          onChange={handleChangeReceivableDays}
          type="number"
          className="rounded-none border-none hover:border-none hover:outline-none hover:ring-0 focus-visible:outline-none focus-visible:ring-0 h-5 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        />
      ) : isAllocateReduce ? (
        <span className="truncate text-ellipsis capitalize">{receivableDays === 0 ? '' : receivableDays}</span>
      ) : (
        <span className="truncate text-ellipsis">{receivableDays === 0 ? '-' : receivableDays}</span>
      )}
    </div>
  );
};

export function CollapsibleRows({
  categoryData,
  scope,
  indentation,
  unit,
  isExpandable,
  showReceivables,
  refetch,
  isReceivableEditable,
  yearlyData,
  year,
  parent,
  isDefaultExpanded = false,
}: {
  categoryData: ICategory;
  scope: 'monthly' | 'quarterly' | 'yearly';
  indentation: number;
  unit: 'k' | 'l' | 'cr' | 'actual';
  isExpandable: boolean;
  showReceivables: boolean;
  refetch: () => void;
  isReceivableEditable: boolean;
  yearlyData?: ICategory;
  year: number;
  parent: ICategory | null;
  isDefaultExpanded?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const budgetData = useMemo(() => {
    return categoryData.budget;
  }, [categoryData]);

  const handleExpandClick = () => {
    if (!isExpandable) return;
    setIsOpen((prev) => !prev);
  };

  const yearlyBudgetForCategory: IBudgets = useMemo(() => {
    return (
      yearlyData?.budget[0] || {
        id: '',
        value: 0,
        periodStartDate: '',
        periodEndDate: '',
        month: 0,
        quarter: 0,
      }
    );
  }, [yearlyData]);

  useEffect(() => {
    if (isDefaultExpanded) {
      setIsOpen(true);
    }
  }, [isDefaultExpanded]);

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className="my-8" asChild>
      <>
        <div className="flex gap-2" key={categoryData.categoryId}>
          <div className={cn('flex absolute z-50 left-0 top-auto border-r-2 border-slate-700 bg-white')}>
            <div className=" min-w-[340px] max-w-[340px] truncate text-ellipsis py-3 border-b">
              <div
                className={cn(
                  'flex items-center gap-2 w-full',
                  isExpandable && !!categoryData?.children?.length && 'cursor-pointer',
                )}
                style={{
                  paddingLeft: `${indentation * 24 + 16}px`,
                }}
                onClick={handleExpandClick}
              >
                <span>
                  {categoryData.categoryValue.includes('_diffnode') && (
                    <span className="flex items-center gap-1 ">
                      <span className=" text-green-600 ">Allocate</span>{' '}
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon className="w-3.5 relative top-[1px] h-3.5 cursor-pointer text-muted-foreground " />
                          </TooltipTrigger>
                          <TooltipContent className="w-60" asChild>
                            <div className="px-2 w-[200px] whitespace-break-spaces">
                              Allocate implies the FY {year} plan for the {parent?.categoryValue} sub-categories need to
                              be adjusted upwards to match the plan value for {parent?.categoryValue}
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>{' '}
                      / <span className="text-red-400">Reduce</span>{' '}
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <InfoIcon className="w-3.5 relative top-[1px] h-3.5 cursor-pointer text-muted-foreground " />
                          </TooltipTrigger>
                          <TooltipContent className="w-60" asChild>
                            <div className="px-2 w-[200px] whitespace-break-spaces">
                              Reduce implies the FY {year} plan for the {parent?.categoryValue} sub-categories need to
                              be adjusted downwards to match the plan value for {parent?.categoryValue}
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </span>
                  )}
                  {!categoryData.categoryValue.includes('_diffnode') && categoryData.categoryValue}
                </span>
                {!!isExpandable && !!categoryData?.children?.length && (
                  <ChevronRightIcon className={cn(isOpen && 'rotate-90', 'w-4 h-4')} />
                )}{' '}
              </div>
            </div>
          </div>
          <div className={cn('flex ')}>
            {budgetData.map((budget: IBudgets) => (
              <BudgetCell
                refetch={refetch}
                isAllocateReduce={categoryData.categoryValue.includes('_diffnode')}
                unit={unit}
                key={budget.id}
                budget={budget}
              />
            ))}
            {scope === 'monthly' && (
              <BudgetCell
                refetch={refetch}
                isAllocateReduce={true}
                unit={unit}
                budget={{
                  ...yearlyBudgetForCategory,
                  id: 'budget_category' + categoryData.categoryId,
                  value: categoryData.categoryValue.includes('_diffnode')
                    ? 0
                    : yearlyBudgetForCategory.value -
                      categoryData.budget.reduce((a: number, b: { value: number }) => a + b.value, 0),
                }}
              />
            )}
            {showReceivables && (
              <ReceivableDaysCell
                isEditAllowed={isReceivableEditable}
                isAllocateReduce={categoryData.categoryValue.includes('_diffnode')}
                category={{
                  name: categoryData.categoryValue,
                  id: categoryData.categoryId,
                }}
              />
            )}
          </div>
        </div>
        <CollapsibleContent className="space-y-2" asChild>
          <>
            {!!isExpandable &&
              !!categoryData?.children?.length &&
              categoryData.children.map((subCategory: ICategory) => (
                <CollapsibleRows
                  isDefaultExpanded={isDefaultExpanded}
                  refetch={refetch}
                  key={subCategory.categoryId}
                  categoryData={subCategory}
                  scope={scope}
                  indentation={indentation + 1}
                  unit={unit}
                  isExpandable={isExpandable}
                  showReceivables={showReceivables}
                  isReceivableEditable={true}
                  yearlyData={yearlyData?.children?.find(
                    (yearlyCategory) => yearlyCategory.categoryId === subCategory.categoryId,
                  )}
                  parent={categoryData}
                  year={year}
                />
              ))}
          </>
        </CollapsibleContent>
      </>
    </Collapsible>
  );
}
