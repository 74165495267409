import dayjs from 'dayjs';
import SubItem from './SubItems';

export const PostedItemDescriptionSmall = ({
  createdAt,
  createdBy,
  type,
  descriptionTitle,
  descriptionValue,
}: {
  createdAt?: string;
  createdBy: string;
  type: string;
  descriptionTitle: string;
  descriptionValue: React.ReactNode;
}) => {
  return (
    <div className="flex justify-between gap-2 py-2">
      <SubItem
        className=" min-w-[160px] "
        label="Posted By"
        value={
          <div>
            {createdBy} at {dayjs(createdAt).format('DD MMM YYYY')}
          </div>
        }
      />
      <SubItem className=" min-w-[160px] " label="Type" value={type} />
      <SubItem className=" min-w-[160px] " label={descriptionTitle} value={descriptionValue} />
    </div>
  );
};

export default PostedItemDescriptionSmall;
