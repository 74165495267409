'use client';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { IInvoiceOption, IInvoiceStatus, IPaymentStatus, IPaymentStatusOption } from '@/types/invoices.types';
import { ChevronDownIcon } from 'lucide-react';
import { useMemo, useState } from 'react';

export function InvoiceStatusDropdown({
  selected,
  handleSelect,
  className = '',
  disabled = false,
}: {
  selected: IInvoiceOption[];
  handleSelect: (_: IInvoiceOption) => () => void;
  className?: string;
  disabled?: boolean;
}) {
  const [open, setOpen] = useState(false);

  const options = useMemo<
    {
      label: string;
      value: IInvoiceStatus;
    }[]
  >(
    () => [
      {
        value: 'OVERDUE',
        label: 'Overdue',
      },
      {
        value: 'DUE',
        label: 'Due',
      },
      {
        value: 'NO_DUES',
        label: 'No dues',
      },
    ],
    [],
  );

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger disabled={disabled} asChild>
        <Button
          disabled={disabled}
          className="flex items-center flex-1 min-w-[120px] truncate text-left gap-4 "
          onClick={() => setOpen((prev) => !prev)}
          variant="outline"
        >
          <span className={cn('truncate text-left w-[150px] overflow-ellipsis capitalize', className)}>
            {selected.length > 0 ? (
              selected.map((option) => option.label).join(', ')
            ) : (
              <span className=" normal-case ">Select invoice status</span>
            )}
          </span>
          <ChevronDownIcon className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onInteractOutside={() => setOpen(false)}
        onEscapeKeyDown={() => setOpen(false)}
        onFocusOutside={() => setOpen(false)}
        onPointerDownOutside={() => setOpen(false)}
        className="w-56"
      >
        <DropdownMenuLabel>Invoice status</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {options.map((option) => (
          <DropdownMenuCheckboxItem
            className="  "
            key={option.value}
            checked={!!selected.find((item) => item.value === option.value)}
            onClick={handleSelect(option)}
          >
            {option.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function PaymentStatusDropDown({
  selected,
  handleSelect,
  listType,
  className = '',
  containerClass = '',
}: {
  selected: IPaymentStatusOption[];
  listType?: 'posted' | 'all' | 'ledger';
  handleSelect: (_: IPaymentStatusOption) => () => void;
  className?: string;
  containerClass?: string;
}) {
  const [open, setOpen] = useState(false);

  const options = useMemo<
    {
      label: string;
      value: IPaymentStatus;
    }[]
  >(() => {
    const allOptions: { label: string; value: IPaymentStatus }[] = [
      { label: 'Paid', value: 'PAID' },
      { label: 'Partially paid', value: 'PARTIALLY_PAID' },
      { label: 'Unpaid', value: 'UNPAID' },
    ];
    return listType === 'ledger' ? allOptions.filter((option) => option.value !== 'UNPAID') : allOptions;
  }, [listType]);

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild>
        <Button
          className={cn('flex items-center gap-4 text-left ', containerClass)}
          onClick={() => setOpen((prev) => !prev)}
          variant="outline"
        >
          <span className={cn(' flex-1 truncate text-left w-[150px] capitalize overflow-ellipsis', className)}>
            {selected.length > 0 ? (
              selected.map((option) => option.label).join(', ')
            ) : (
              <span className=" transform-none normal-case ">Select payment status</span>
            )}
          </span>
          <ChevronDownIcon className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onInteractOutside={() => setOpen(false)}
        onEscapeKeyDown={() => setOpen(false)}
        onFocusOutside={() => setOpen(false)}
        onPointerDownOutside={() => setOpen(false)}
        className="w-56"
      >
        <DropdownMenuLabel>Payment status</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {options.map((option) => (
          <DropdownMenuCheckboxItem
            className=" normal-case "
            key={option.value}
            checked={!!selected.find((item) => item.value === option.value)}
            onClick={handleSelect(option)}
          >
            {option.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
