import { Button } from '@/components/ui/button';
import { useRunWorkflow } from '@/hooks/api-hooks/useWorkflowQuery';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '../../ui/dialog';
import { useToast } from '../../ui/use-toast';

const RunWorkflowDialog = ({
  workflowId,
  isRunWorkflowDialogOpen,
  setIsRunWorkflowDialogOpen,
}: {
  workflowId: string;
  isRunWorkflowDialogOpen: boolean;
  setIsRunWorkflowDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { toast } = useToast();

  const { mutate: runWorkflow } = useRunWorkflow({
    workflowId: workflowId,
    customConfig: {
      onError(error) {
        toast({
          description: error.response?.data.message ?? 'Error occured in running the workflow',
          variant: 'destructive',
        });
      },
      onSuccess() {
        toast({
          description: 'Workflow executed successfully',
        });
        setIsRunWorkflowDialogOpen(false);
      },
    },
  });

  return (
    isRunWorkflowDialogOpen && (
      <Dialog open={isRunWorkflowDialogOpen} onOpenChange={setIsRunWorkflowDialogOpen}>
        <DialogContent className="min-w-[400px] bg-white p-4 border-2 rounded-md">
          <DialogHeader className=" font-semibold ">Run workflow</DialogHeader>
          <p className="text-sm">
            Are you sure you want to run this workflow? <br />
            This might send mails to your customers
          </p>
          <DialogFooter>
            <Button onClick={() => setIsRunWorkflowDialogOpen(false)} variant="outline">
              Cancel
            </Button>
            <Button onClick={() => runWorkflow()}>Run</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  );
};

export default RunWorkflowDialog;
