import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useDeleteCustomerTag } from '@/hooks/api-hooks/useTagsQuery';
import { useQueryClient } from '@tanstack/react-query';
import { EditIcon, PlusIcon, Trash2Icon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TagDetailsRow = ({
  customerId,
  tag,
  handleDelete,
  allowEdit = true,
}: {
  tag: {
    id: string;
    name: string;
    value: string;
  };
  handleDelete: (_: string) => void;
  customerId: string;
  allowEdit?: boolean;
}) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/customer/view/${customerId}?scrollTo=tags&tagKey=${tag.name}`);
  };

  return (
    <div className=" flex items-center gap-2 justify-between ">
      <Badge className="flex items-center gap-2 bg-gray-300 hover:bg-gray-200 text-primary ">
        <span>{tag.name}</span>: <span>{tag.value}</span>
      </Badge>
      {!!allowEdit && (
        <div className=" flex items-center gap-2 ">
          <Button
            onClick={handleEdit}
            variant="outline"
            size="icon"
            className="w-6 h-6 flex-1 min-w-[24px] max-w-[24px]"
          >
            <EditIcon className=" w-4 h-4 " />
          </Button>
          <Button
            onClick={() => handleDelete(tag.name)}
            variant="outline"
            size="icon"
            className="w-6 h-6 flex-1 min-w-[24px] max-w-[24px]"
          >
            <Trash2Icon className=" w-4 h-4 " />
          </Button>
        </div>
      )}
    </div>
  );
};

const TagsDetails = ({
  tagDetails,
  customerId,
  allowCreate = true,
}: {
  tagDetails: { id: string; name: string; value: string }[];
  customerId: string;
  allowCreate?: boolean;
}) => {
  const [tagsArray, setTagsArray] = useState(tagDetails);

  useEffect(() => {
    setTagsArray(tagDetails);
  }, [tagDetails]);

  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { mutate: deleteTag } = useDeleteCustomerTag({
    customerId,
    customConfig: {
      onSuccess() {
        queryClient.refetchQueries({
          predicate: (query) => query.queryKey.includes('getCustomerByFilters'),
        });
      },
      onError() {
        // TODO: fill error here
        toast({
          variant: 'destructive',
          description: 'Unable to delete tag',
        });
      },
    },
  });

  const handleDelete = (id: string) => {
    deleteTag({ tagKey: id });
  };
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate(`/customer/view/${customerId}?scrollTo=tags&tagKey=create_new`);
  };

  return (
    <div className=" flex flex-col gap-2 max-h-24 overflow-y-scroll ">
      {!allowCreate && tagsArray.length === 0 && <span className="text-sm text-muted-foreground">No tags found</span>}
      {tagsArray.map((tag) => (
        <TagDetailsRow
          allowEdit={!!allowCreate}
          customerId={customerId}
          handleDelete={handleDelete}
          key={tag.id}
          tag={tag}
        />
      ))}

      {!!allowCreate && (
        <Button
          onClick={handleCreate}
          variant="outline"
          size="icon"
          className="w-6 h-6 flex-1 min-w-[24px] max-w-[24px]"
        >
          <PlusIcon className=" w-4 h-4 " />
        </Button>
      )}
    </div>
  );
};

export default TagsDetails;
