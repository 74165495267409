import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import { CheckIcon, XIcon } from 'lucide-react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { Input } from '@/components/ui/input';

export const EditTermTitle = ({
  defaultValue,
  className,
  onChange,
  titleErrors,
}: {
  defaultValue: string;
  onChange: (_: string) => void;
  className?: string;
  titleErrors?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (titleErrors) {
      setErrors(titleErrors);
      setIsEditing(true);
    }
  }, [titleErrors]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors('');
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (value.length < 3) {
      setErrors('Term title must be more than 3 characters');
      return;
    }

    if (value === defaultValue) {
      setIsEditing(false);
      return;
    }

    onChange(value);

    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  };

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
    setValue(defaultValue);
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    } else {
      setErrors('');
    }
  }, [isEditing]);

  return isEditing ? (
    <div className=" flex items-start gap-4 ">
      <div>
        <Input
          className="my-0 text-xl px-4 py-2 font-semibold "
          onKeyDown={handleKeyDown}
          ref={inputRef}
          value={value}
          onChange={handleChange}
        />
        <p className=" text-xs text-red-500 h-4 ">{!!errors.trim() && errors}</p>
      </div>
      <Button onClick={handleSave} variant="outline" size="icon">
        <CheckIcon className=" h-4 w-4 " />
      </Button>
      <Button onClick={handleCancel} variant="outline" size="icon">
        <XIcon className=" h-4 w-4 " />
      </Button>
    </div>
  ) : (
    <div
      onClick={handleClick}
      className={cn(
        ' hover:ring-1 ring-primary cursor-pointer px-4 rounded-md py-1 flex items-center gap-4 ',
        className,
      )}
    >
      {value ? value : 'Click to add term title'}
    </div>
  );
};
export const EditDescription = ({
  value,
  className,
  onChange,
  descriptionErrors,
}: {
  value: string;
  className?: string;
  onChange: (_: string) => void;
  descriptionErrors?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [errors, setErrors] = useState('');

  useEffect(() => {
    if (descriptionErrors) {
      setErrors(descriptionErrors);
      setIsEditing(true);
    }
  }, [descriptionErrors]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setErrors('');
    onChange(e.target.value);
  };

  const handleSave = () => {
    if (value.length < 3) {
      setErrors('Term description must be more than 10 characters');
      return;
    }

    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === 'Enter') {
      handleSave();
    }
  };

  const handleCancel = () => {
    setErrors('');
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    } else {
      setErrors('');
    }
  }, [isEditing]);

  return isEditing ? (
    <div className=" flex items-start gap-4 w-full text-sm ">
      <div className=" w-full ">
        <Textarea
          className="my-0   w-full "
          onKeyDown={handleKeyDown}
          ref={inputRef}
          value={value}
          onChange={handleChange}
        />
        <p className=" text-xs text-red-500 h-4 ">{!!errors.trim() && errors}</p>
      </div>
      <Button onClick={handleSave} variant="outline" size="icon">
        <CheckIcon className=" h-4 w-4 " />
      </Button>
      <Button onClick={handleCancel} variant="outline" size="icon">
        <XIcon className=" h-4 w-4 " />
      </Button>
    </div>
  ) : (
    <div
      onClick={handleClick}
      className={cn(
        ' hover:ring-1 ring-primary cursor-pointer rounded-md px-2 py-1 flex items-center gap-4 ',
        className,
      )}
    >
      {value ? value : 'Click to add term description'}
    </div>
  );
};
