import { createPlan, finalizePlan, getActivePlan, getPlansByCompanyId } from '@/api-functions/plan.api';
import { IApiResponse } from '@/types/common.types';
import { IGetActivePlanResponse, IPlan } from '@/types/plan.types';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

const PlanningQueryKeys = {
  getPlansByCompany: (companyId: string) => ['getPlansByCompany', companyId],
  getActivePlanByCompany: (companyId: string, financialYear: number) => [
    'getActivePlanByCompany',
    companyId,
    financialYear,
  ],
};

export const useGetPlansByCompanyId = ({
  companyId,
  customConfig,
}: {
  companyId: string;
  customConfig?: Omit<
    UseQueryOptions<{
      error: string | null;
      message: string;
      data: {
        plans: IPlan[];
      };
    }>,
    'queryKey'
  >;
}) => {
  const getPlansByCompanyIdQuery = useQuery<{
    error: string | null;
    message: string;
    data: {
      plans: IPlan[];
    };
  }>({
    queryKey: PlanningQueryKeys.getPlansByCompany(companyId),
    queryFn: () => getPlansByCompanyId(),
    ...customConfig,
  });

  return getPlansByCompanyIdQuery;
};

export const usePostFinalizePlan = ({
  planId,
  customConfig,
}: {
  planId: string;
  customConfig?: UseMutationOptions;
}) => {
  const postFinalizePlan = useMutation({
    mutationFn: () =>
      finalizePlan({
        planId,
      }),
    ...customConfig,
  });

  return postFinalizePlan;
};

export const useCreatePlan = ({
  companyId,
  customConfig,
}: {
  companyId: string;
  customConfig?: UseMutationOptions<
    IApiResponse<{
      id: string;
    }>,
    unknown,
    {
      name: string;
      financialYear: number;
    }
  >;
}) => {
  const postCreatePlan = useMutation({
    mutationFn: ({ name, financialYear }: { name: string; financialYear: number }) =>
      createPlan({
        companyId,
        name,
        financialYear,
      }),
    ...customConfig,
  });

  return postCreatePlan;
};

export const useGetActivePlanByCompanyId = ({
  companyId,
  financialYear,
  customConfig,
}: {
  companyId: string;
  financialYear: number;
  customConfig?: Omit<UseQueryOptions<IGetActivePlanResponse>, 'queryKey'>;
}) => {
  const getActivePlanByCompanyIdQuery = useQuery<IGetActivePlanResponse>({
    queryKey: PlanningQueryKeys.getActivePlanByCompany(companyId, financialYear),
    queryFn: () => getActivePlan({ financialYear }),
    ...customConfig,
  });

  return getActivePlanByCompanyIdQuery;
};
