import { Toolbar } from '@/components/ui/email-builder/toolbar';
import { Icon } from '@/components/ui/icon';
import { Surface } from '@/components/ui/surface';
import Tooltip from '../tooltip';

export type LinkPreviewPanelProps = {
  url: string;
  onEdit: () => void;
  onClear: () => void;
};

export const LinkPreviewPanel = ({ onClear, onEdit, url }: LinkPreviewPanelProps) => {
  return (
    <Surface className="p-2 fixed min-w-[250px]">
      <div className=" flex items-center gap-2 ">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm flex-1 text-primary  underline break-all"
        >
          {url}
        </a>
        <Toolbar.Divider />
        <Tooltip title="Edit link">
          <Toolbar.Button className=" text-primary " onClick={onEdit}>
            <Icon name="Pen" />
          </Toolbar.Button>
        </Tooltip>
        <Tooltip title="Remove link">
          <Toolbar.Button className=" text-primary " onClick={onClear}>
            <Icon name="Trash2" />
          </Toolbar.Button>
        </Tooltip>
      </div>
    </Surface>
  );
};
