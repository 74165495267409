import * as Sentry from '@sentry/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../ui/button';

const FallBack = ({ resetErrorBoundary }: { resetErrorBoundary: () => void }) => {
  return (
    <div className="w-full flex justify-center items-center px-4 md:px-12 py-12">
      <div className="w-full h-full flex flex-col items-start ">
        <h1 className=" font-semibold text-2xl ">Oops!</h1>
        <h1 className="text-xl">Something went wrong</h1>
        <p>Please retry once if the issue persist please contact us</p>
        <Button onClick={resetErrorBoundary} className="my-4">
          Retry
        </Button>
      </div>
    </div>
  );
};

const ErrorWrapper = ({ children }: { children: React.ReactNode }) => {
  const pathname = useLocation().pathname;

  return (
    <Sentry.ErrorBoundary key={pathname} fallback={({ resetError }) => <FallBack resetErrorBoundary={resetError} />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorWrapper;
